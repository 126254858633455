import IconButton from '@material-ui/core/IconButton';
import { withStyles } from '@material-ui/core/styles';
import Hidden from '@material-ui/core/Hidden';
import Typography from '@material-ui/core/Typography';
import Menu from '@material-ui/core/Menu';
import FileDocument from 'mdi-material-ui/FileDocument';
import FileExcel from 'mdi-material-ui/FileExcel';
import PropTypes from 'prop-types';
import React from 'react';
import { CircularProgress, Grid } from '@material-ui/core';
import { connect } from 'react-redux';

// Icons
import Delete from '@material-ui/icons/Delete';
import FileDownload from '@material-ui/icons/GetApp';
import FlashOn from '@material-ui/icons/FlashOn';
import MoreVert from '@material-ui/icons/MoreVert';
import PlayCircleOutline from '@material-ui/icons/PlayCircleOutline';

// fontawesome icons
import { faEye } from '@fortawesome/free-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

// custom component
import Table from '../../../components/Table/Table';
import TableBody from '../../../components/Table/TableBody';
import TableCell from '../../../components/Table/TableCell';
import TableRow from '../../../components/Table/TableRow';
import AlertModal from '../../../components/AlertModal';
import ExecuteScriptModal from './ExecuteScriptModal';
import VideoPreviewModal from './VideoPreviewModal';
import ActionMenu from '../../../components/Menu/ActionMenu';
import Loader from '../../../components/Loader';
import {
    checkArrayLength,
    checkKeyInObject,
    getStepsData,
    sort,
    getFormatedDate,
    resizeAllCols,
    getRelativeTime,
    getParamValues,
} from '../../../utils/utils'; // utils/utils
import { EnhancedTableHead } from '../../../components/EnhancedTable';
import NoDataToShow from '../../../components/NoDataToShow';
import ImgSrc from '../../../components/NoDataToShow/assests/file-alt-solid.svg';
import { MUI_ACTION_MENU_ICON, THREE_DOT_MENU_CSS, TABLES_CSS } from '../../../common/cssConstants';
import DeleteAlertModal from '../../modal/Delete/DeleteAlertModal';
import Tooltip from '../../../components/Tooltip';
import TableFooter from '../../../common/TableFooter';

const styles = (theme) => ({
    root: {
        width: '100%',
    },
    cellPadding: {
        // paddingTop: '24px',
        // paddingBottom: '24px',
    },
    actionCell: {
        paddingTop: '24px',
        paddingBottom: '24px',
        width: '220px',
        maxWidth: '220px',
    },
    cellText: {
        maxWidth: '200px',
    },
    moreIconsBtn: {
        color: THREE_DOT_MENU_CSS.iconColor,
        fontSize: 22,
    },
    moreIcons: MUI_ACTION_MENU_ICON,
    moreIconsBtnDisabled: {
        color: THREE_DOT_MENU_CSS.iconDisableColor,
        fontSize: 22,
    },
    eyeIconBtn: {
        color: 'rgb(96, 148, 255)',
        fontSize: '1.25rem',
    },
    shareButton: {
        color: 'rgb(240, 182, 55)',
    },
    tableRow: {
        '& td': {
            padding: '4px 0px 0px 24px',
        },
    },
    borders: {
        background: 'rgba(0, 0, 0, 0.04) !important',
    },
    noPadding: {
        paddingRight: '5px !important',
        backgroundColor: '#f4f6f7 !important',
    },
    noPaddingBoth: {
        paddingRight: '0px !important',
        paddingLeft: '0px !important',
    },
    actionButtonsDiv: {
        width: '30px',
        [theme.breakpoints.only('xs')]: {
            width: 'auto !important',
        },
    },
    actionButtonsWidth: {
        width: '22px',
        height: '22px',
    },
    actionButtonsTableCell: {
        paddingLeft: '16px',
    },
    noWrap: {
        overflow: 'hidden',
        whiteSpace: 'nowrap',
        textOverflow: 'ellipsis',
        maxWidth: '100%',
    },
    minWidth: {
        minWidth: '60px !important',
        [theme.breakpoints.only('xs')]: {
            minWidth: '200px !important',
            maxWidth: '200px !important',
        },
    },
    cell: {
        textAlign: 'left',
        flexDirection: 'row',
        padding: 0,
        width: '20%',
        '&>:first-child': {
            paddingLeft: '20px !important',
        },
    },
    minHeight: {
        minHeight: 'calc(100vh - 279px)',
    },
    minHeight264: {
        minHeight: 'calc(100vh - 264px)',
    },
    tableContainer: {
        backgroundColor: '#fff',
        boxShadow: '0px 1px 3px 0px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 2px 1px -1px rgba(0, 0, 0, 0.12)',
        borderBottomLeftRadius: '5px',
        borderBottomRightRadius: '5px',
        overflow: 'hidden',
    },
});

// Table Header Array
const headers = [
    { id: 'scriptName', numeric: false, sortable: true, disablePadding: false, label: 'Script', styles: { paddingLeft: 10 }, width: '30%' },
    {
        id: 'scriptGeneratedAt',
        numeric: false,
        sortable: true,
        disablePadding: false,
        label: 'Created',
        styles: { paddingLeft: 10 },
        width: 'calc(70% / 3)',
    },
    // { id: 'Name', numeric: false, sortable: false, disablePadding: false, label: 'Case', styles: { paddingLeft: 10 },width: "calc(70% / 4)",  },
    {
        id: 'caseLastRun',
        numeric: false,
        sortable: true,
        disablePadding: false,
        label: 'Last Run',
        styles: { paddingLeft: 10 },
        width: 'calc(70% / 3)',
    },
    { id: 'Actions', numeric: false, sortable: false, disablePadding: false, label: 'Actions', styles: { paddingLeft: 10 }, width: 'calc(70% / 3)' },
];
export class ScriptTableBase extends React.Component {
    constructor(props, context) {
        super(props, context);
        this.colsWidth = {};

        this.state = {
            order: 'asc',
            orderBy: 'scriptName',
            openVideoModal: false,
            anchorEl: null,
            videoUrl: '',
            caseName: '',
            executeScriptSystemId: null,
            testScriptData: props.testScripts,
            index: -1,
            showLoader: this.props.showLoader,
            rowsPerPage: 20,
            page: 0,
            defaultSort: true,
            deleteScriptRow: false,
            deleteTestScript: null,
        };
    }

    UNSAFE_componentWillMount() {
        this.showModal = true;
        this.handleRequestSort('scriptGeneratedAt', this.props.testScripts);
    }

    componentDidMount() {
        const caseSettingRows = localStorage.getItem('rowsCasePerPage');
        if (caseSettingRows) {
            this.updateState({
                rowsPerPage: parseInt(caseSettingRows, 10),
            });
        }
    }

    UNSAFE_componentWillReceiveProps(nextProps) {
        const { testScripts } = nextProps;
        if (nextProps.showLoader !== this.props.showLoader) {
            this.setState({ showLoader: nextProps.showLoader });
        }
        this.handleRequestSort(this.state.orderBy, testScripts, true);
        if (getParamValues(3) && getParamValues(4) && checkArrayLength(testScripts) && this.showModal) {
            const testScriptId = getParamValues(3);
            const testScriptIndex = testScripts.findIndex((script) => `${script.testScriptId}` === `${testScriptId}`);
            if (testScriptIndex !== -1 && testScripts[testScriptIndex]) {
                this.showModal = false;
                switch (getParamValues(4)) {
                    case 'execute':
                        this.getExecutionScript(Number(testScriptId));
                        break;
                    case 'preview':
                        this.props.previewTestScript(
                            testScriptId,
                            testScripts[testScriptIndex].scriptName,
                            () => {
                                this.replaceURL(testScriptId, 'preview');
                            },
                            () => {
                                this.replaceURL();
                            },
                        );
                        break;
                    case 'case': {
                        const testCase = checkKeyInObject(testScripts[testScriptIndex], 'testCase', 'value', false);
                        if (testCase) {
                            getStepsData(testCase, 2, (_testCase) => {
                                this.props.previewTestCase(_testCase);
                            });
                        } else {
                            this.replaceURL();
                        }
                        break;
                    }
                    default:
                        this.replaceURL();
                }
            } else {
                this.replaceURL();
            }
        }
    }

    componentDidUpdate() {
        resizeAllCols(this.colsWidth);
    }

    componentWillUnmount() {
        this.props.queryRemove();
    }

    getExecutionScript = (executeScriptSystemId) => {
        this.setState({ executeScriptSystemId });
        this.handleClose();
    };

    handleRequestSort = (property, testScriptData = this.state.testScriptData, forceSameOrder = false) => {
        const orderBy = property;
        let order = 'desc';
        if (forceSameOrder) {
            order = this.state.order;
        } else if (this.state.orderBy === property && this.state.order === 'desc') {
            order = 'asc';
        }

        const __testScriptData = sort(testScriptData, order, orderBy, orderBy === 'scriptGeneratedAt' || orderBy === 'caseLastRun' ? 'time' : '');

        this.setState({ testScriptData: __testScriptData, order, orderBy, defaultSort: false });
    };

    updateState = (obj) => {
        this.setState(obj);
    };

    handleExeScriptClose = () => {
        this.replaceURL();
        this.setState({ executeScriptSystemId: null });
    };

    handleVideoClose = () => {
        this.setState({ openVideoModal: false });
    };

    handleClick = (event, i) => {
        this.setState({ anchorEl: event.currentTarget, index: i });
    };

    handleClose = () => {
        this.setState({ anchorEl: null });
    };

    checkExecutionVideo = (executionVideoUrl, isReady) =>
        isReady && executionVideoUrl !== '' ? (
            <PlayCircleOutline aria-label="playCircleOutlineIcon" id="playCircleOutlineIcon" />
        ) : (
            <PlayCircleOutline disabled aria-label="playCircleOutlineIcon" id="playCircleOutlineIcon" />
        );

    checkScriptUrl = (scriptURL) =>
        scriptURL ? (
            <FontAwesomeIcon icon={faEye} aria-label="faEye" id="faEye" />
        ) : (
            <FontAwesomeIcon aria-label="faEye" id="faEye" icon={faEye} disabled />
        );

    previewAction(fileLocation) {
        const viewerUrl = 'https://docs.google.com/viewer?url=';
        const encodedUrl = encodeURIComponent(fileLocation);
        const finalViewerLocation = viewerUrl + encodedUrl;
        window.open(finalViewerLocation);
        this.handleClose();
    }

    checkExecute = (testScript) => {
        let executionIcon = null;
        /* if aiqExecution and isExecInprogress or testCase isGenerating false and it should enable 
            for selenium, if script exist, it should be enabled always */
        // removed disable execution button condition (Requested by puneet)
        executionIcon = (
            <Tooltip data="Execute">
                <IconButton
                    onClick={(e) => {
                        e.stopPropagation();
                        this.replaceURL(testScript.testScriptId, 'execute');
                        this.getExecutionScript(testScript.testScriptId);
                    }}
                    data-testid={`execute-${testScript.testScriptId}`}
                    className={this.props.classes.actionButtonsWidth}
                >
                    <FlashOn
                        className={[this.props.classes.moreIconsBtn, this.props.classes.shareButton].join(' ')}
                        aria-label="flashOnIcon"
                        id="flashOnIcon"
                    />
                </IconButton>
            </Tooltip>
        );
        return executionIcon;
    };

    checkDelete = (isReady) =>
        isReady ? <Delete aria-label="deleteIcon" id="deleteIcon" /> : <Delete disabled aria-label="deleteIcon" id="deleteIcon" />;

    handleDelete = (testCase, scriptSystemId) => {
        if (this.props.handleDeleteTestScript(scriptSystemId, testCase)) {
            const newTestScriptData = this.state.testScriptData.filter((testScript) => testScript.testScriptId !== scriptSystemId);
            this.setState({ testScriptData: newTestScriptData });
        }
        this.handleClose();
    };

    hadleCloseDeleteAlertModal = (isConfirm) => {
        if (isConfirm) {
            const { deleteTestScript } = this.state;
            this.handleDelete(deleteTestScript.testCase, deleteTestScript.testScriptId);
        }
        this.setState({ deleteScriptRow: false, deleteTestScript: null });
    };

    previewExecutionVideo = async (execId, caseName) => {
        const onComplete = (executionVideosURL) => {
            const { _execId, url } = executionVideosURL.pop();
            this.showExecutionVideo(url, _execId, caseName);
        };
        this.props.getExecutionVideo(execId, onComplete);
    };

    showExecutionVideo = async (videoUrl, execId, caseName) => {
        this.handleClose();
        let currentVideoUrl = videoUrl;
        if (!currentVideoUrl) {
            const { videoStore } = this.props;
            const { videos } = await videoStore.fetchVideoData(execId);
            if (videos.length) {
                currentVideoUrl = videos[0].url;
            }
        }
        if (currentVideoUrl) {
            this.setState({ videoUrl: currentVideoUrl.replace('../', '/'), caseName, openVideoModal: true });
            return true;
        }
        return false;
    };

    replaceURL = (scriptId, modalName) => {
        if (scriptId && modalName) {
            this.props.history.replace(`/details/${getParamValues(1)}/scripts/${scriptId}/${modalName}`);
        } else {
            this.props.history.replace(`/details/${getParamValues(1)}/scripts`);
        }
    };
    // CONFIRMATION ALERT DIALOG END

    /**
    |--------------------------------------------------
    | Preview File Case | download steps
    |--------------------------------------------------
    */

    handleRecursiveTestSteps = (element, _testSteps) => {
        const { subInstructions } = element;
        const subInstr = !(!subInstructions || !subInstructions.length);
        const testStep = { ...element };
        testStep.hasChild = subInstr;
        // push in _testSteps because array refrance is same in handleClickOpen function
        _testSteps.push(testStep);
        if (subInstr) {
            subInstructions.forEach((teststep) => {
                this.handleRecursiveTestSteps(teststep, _testSteps);
            });
        }
    };

    handleChangePage = (page) => {
        this.setState({ page });
    };

    handleChangeRowsPerPage = (event) => {
        localStorage.setItem('rowsCasePerPage', event.target.value);
        this.setState({ rowsPerPage: event.target.value });
    };

    renderItems(testScript, testData, extend) {
        const { classes } = this.props;
        let flag = false;
        let dataId = '';
        let dataName = '';
        let options = [];
        const currentTestCaseId = testScript && testScript.testCase ? testScript.testCase.testCaseId : '';

        testData.some((testDatum) => {
            const testCaseIds = checkArrayLength(testDatum.testCasesIds) ? testDatum.testCasesIds : [];
            if (testCaseIds.length) {
                testCaseIds.some((id) => {
                    if (parseInt(id, 10) === currentTestCaseId) {
                        dataId = testDatum.testDataId;
                        dataName = testDatum.testDataFileName;
                        flag = true;
                        return flag;
                    }
                    return flag;
                });
                return flag;
            }
            return flag;
        });
        let items = [
            {
                action: (e) => {
                    e.stopPropagation();
                    this.setState({ anchorEl: null });
                    this.previewExecutionVideo(testScript.executionId, testScript.caseName);
                },
                icon: (
                    <PlayCircleOutline
                        className={[classes.moreIcons, classes[!testScript.executionId ? 'moreIconsBtnDisabled' : 'moreIconsBtn']].join(' ')}
                        aria-label="playCircleOutlineIcon"
                        id="playCircleOutlineIcon"
                    />
                ),
                isDisabled: !testScript.executionId,
                name: ' Play Video',
            },
            {
                action: (e) => {
                    e.stopPropagation();
                    this.setState({ deleteScriptRow: true, deleteTestScript: testScript, anchorEl: null });
                },
                icon: (
                    <Delete
                        className={[classes.moreIcons, classes[!testScript.testScriptId ? 'moreIconsBtnDisabled' : 'moreIconsBtn']].join(' ')}
                        aria-label="deleteIcon"
                        id="deleteIcon"
                    />
                ),
                isDisabled: !testScript.testScriptId,
                name: ' Delete',
            },
            {
                action: (e) => {
                    e.stopPropagation();
                    this.replaceURL(testScript.testScriptId, 'case');
                    getStepsData(testScript.testCase, 2, (_testCase) => {
                        this.props.previewTestCase(_testCase);
                    });
                    this.handleClose();
                },
                icon: (
                    <FileExcel
                        className={[classes.moreIcons, classes[!testScript.testCase.testCaseId ? 'moreIconsBtnDisabled' : 'moreIconsBtn']].join(' ')}
                    />
                ),
                isDisabled: !testScript.testCase.testCaseId,
                name: ' Case',
            },
            {
                action: (e) => {
                    e.stopPropagation();
                    this.replaceURL(testScript.testScriptId, 'preview');
                    this.props.previewTestScript(testScript.testScriptId, testScript.scriptName);
                    this.handleClose();
                },
                icon: (
                    <FontAwesomeIcon
                        style={{ fontSize: THREE_DOT_MENU_CSS.faIconFontSize, padding: '1px' }}
                        icon={faEye}
                        aria-label="faEye"
                        id="faEye"
                        className={[classes.moreIcons, classes.moreIconsBtn].join(' ')}
                    />
                ),
                isDisabled: false,
                name: ' Preview',
            },
            {
                action: (e) => {
                    e.stopPropagation();
                    this.handleClose();
                    this.props.previewTestData(dataId, dataName);
                },
                icon: <FileDocument className={[classes.moreIcons, classes[!dataId ? 'moreIconsBtnDisabled' : 'moreIconsBtn']].join(' ')} />,
                isDisabled: !dataId,
                name: ' Data',
            },
            {
                action: (e) => {
                    e.stopPropagation();
                    this.handleClose();
                    this.props.downloadGradle(testScript.testScriptId, testScript.scriptName);
                },
                icon: (
                    <FileDownload
                        className={[classes.moreIcons, classes[!testScript.testScriptId ? 'moreIconsBtnDisabled' : 'moreIconsBtn']].join(' ')}
                        aria-label="fileDownloadIcon"
                        id="fileDownloadIcon"
                    />
                ),
                isDisabled: !testScript.testScriptId,
                name: ' Gradle project',
            },
        ];
        if (extend) {
            /* 
            if aiqExecution and isExecInprogress false and it should enable 
            for selenium, if script exist, it should be enabled always */
            options = [
                {
                    action: (e) => {
                        e.stopPropagation();
                        this.replaceURL(testScript.testScriptId, 'execute');
                        this.getExecutionScript(testScript.testScriptId);
                    },
                    icon: <FlashOn className={[classes.moreIcons, classes.moreIconsBtn].join(' ')} aria-label="flashOnIcon" id="flashOnIcon" />,
                    isDisabled: false,
                    name: ' Execute',
                },
            ];
            items = [...options, ...items];
        }
        return <ActionMenu items={items} />;
    }

    render() {
        const { classes, query, showSnackBar, testData, isLoadingCases, isLoadingTestData, aiqExecution, user } = this.props;
        const {
            testScriptData,
            order,
            orderBy,
            executeScriptSystemId,
            openAlertDialog,
            openVideoModal,
            videoUrl,
            caseName,
            anchorEl,
            index,
            showLoader,
            rowsPerPage,
            page,
            defaultSort,
            deleteScriptRow,
            deleteTestScript,
        } = this.state;
        const activeExecuteScript = executeScriptSystemId
            ? testScriptData.find((testScript) => testScript.testScriptId === executeScriptSystemId)
            : null;
        // const { classes,  testData , previewTestScript} = this.props;
        // const { testScriptData, order, orderBy, error, executeScriptSystemId, openVideoModal, videoUrl, anchorEl, index } = this.state;
        // const activeExecuteScript = executeScriptSystemId ? testScriptData.find((testScript) => testScript.testScriptId === executeScriptSystemId) : null;

        // to enable multiple execution in 1 go. we would better using checkbox on each testScript for this.
        const scripts = [];
        let testCaseId = null;
        if (activeExecuteScript) {
            scripts.push(activeExecuteScript.testScriptId);
            testCaseId = activeExecuteScript.testCase.testCaseId;
        } else {
            scripts.length = 0;
        }
        let sortedScriptData = defaultSort ? this.handleRequestSort('scriptGeneratedAt', 'return') : testScriptData;
        if (query && checkArrayLength(testScriptData)) {
            sortedScriptData = sortedScriptData.filter(
                (script) => checkKeyInObject(script, 'scriptName') && script.scriptName.toLowerCase().includes(query.toLowerCase()),
            );
        }
        const currentPageCases = sort(
            sortedScriptData,
            order,
            orderBy,
            orderBy === 'scriptGeneratedAt' || orderBy === 'caseLastRun' ? 'time' : '',
        ).slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage);

        return (
            <div className={classes.root}>
                {isLoadingCases || isLoadingTestData ? (
                    <div className={classes.minHeight264}>
                        <CircularProgress style={{ margin: '100px 50% 0px', color: '#4885ed' }} size={60} />
                    </div>
                ) : checkArrayLength(currentPageCases) ? (
                    <div className={classes.minHeight}>
                        <div className={classes.tableContainer}>
                            <Table aria-labelledby="tableTitle">
                                <Hidden only={['xs']}>
                                    <EnhancedTableHead
                                        headers={headers}
                                        order={order}
                                        orderBy={orderBy}
                                        onRequestSort={this.handleRequestSort}
                                        colsWidth={this.colsWidth}
                                        resizeable
                                    />
                                </Hidden>
                                <Hidden smUp>
                                    <EnhancedTableHead
                                        headers={headers}
                                        order={order}
                                        orderBy={orderBy}
                                        onRequestSort={this.handleRequestSort}
                                        colsWidth={this.colsWidth}
                                    />
                                </Hidden>
                                <TableBody style={{ maxHeight: 'calc(100vh - 325px)' }}>
                                    {currentPageCases.map((testScript, i) => (
                                        <TableRow
                                            style={{ height: '40px' }}
                                            hover
                                            tabIndex={-1}
                                            key={testScript.testScriptId}
                                            className={classes.tableRow}
                                        >
                                            <TableCell
                                                className={[classes.borders, classes.noPadding, classes.cell, classes.noWrap, classes.minWidth].join(
                                                    ' ',
                                                )}
                                                width={headers[0].width}
                                                data-resize={`element${0}_c`}
                                            >
                                                <div style={{ paddingLeft: '10px', width: '100%' }}>
                                                    <div style={{ display: 'flex', alignItems: 'center' }}>
                                                        <Typography
                                                            noWrap
                                                            className={`${classes.cellText} ${classes.noWrap}`}
                                                            style={{ fontSize: TABLES_CSS.normalFontSize, color: TABLES_CSS.normalFontColor }}
                                                        >
                                                            <Tooltip data={testScript.scriptName}>{testScript.scriptName}</Tooltip>
                                                        </Typography>
                                                    </div>
                                                </div>
                                            </TableCell>
                                            <TableCell
                                                className={[classes.borders, classes.noPadding, classes.cell, classes.noWrap, classes.minWidth].join(
                                                    ' ',
                                                )}
                                                width={headers[1].width}
                                                data-resize={`element${1}_c`}
                                            >
                                                <div style={{ paddingLeft: '10px', width: '100%' }}>
                                                    <div style={{ display: 'flex', alignItems: 'center' }}>
                                                        <Typography
                                                            noWrap
                                                            className={`${classes.cellText} ${classes.noWrap}`}
                                                            style={{ fontSize: TABLES_CSS.normalFontSize, color: TABLES_CSS.normalFontColor }}
                                                        >
                                                            {testScript.scriptGeneratedAt
                                                                ? checkKeyInObject(user, 'isRelativeDateFormat')
                                                                    ? getRelativeTime(testScript.scriptGeneratedAt)
                                                                    : getFormatedDate(testScript.scriptGeneratedAt, 'DD MMM YYYY', '')
                                                                : ''}
                                                        </Typography>
                                                    </div>
                                                </div>
                                            </TableCell>
                                            {/* <TableCell className={classes.borders}>                                                        
	                                                    <div style={{paddingLeft: '10px', display: 'flex', alignItems: 'center'}}>
	                                                            <Typography noWrap className={`${classes.cellText} ${classes.noWrap}`}>
	                                                                {testScript.caseName}
	                                                            </Typography>
	                                                        </div>
	                                                    </TableCell> */}
                                            <TableCell
                                                className={[classes.borders, classes.noPadding, classes.cell, classes.noWrap, classes.minWidth].join(
                                                    ' ',
                                                )}
                                                width={headers[2].width}
                                                data-resize={`element${2}_c`}
                                            >
                                                <div style={{ paddingLeft: '10px', width: '100%' }}>
                                                    <div style={{ display: 'flex', alignItems: 'center' }}>
                                                        {testScript.caseLastRun && (
                                                            <Typography
                                                                noWrap
                                                                className={`${classes.cellText} ${classes.noWrap}`}
                                                                style={{ fontSize: TABLES_CSS.normalFontSize, color: TABLES_CSS.normalFontColor }}
                                                            >
                                                                {checkKeyInObject(user, 'isRelativeDateFormat')
                                                                    ? getRelativeTime(testScript.caseLastRun)
                                                                    : getFormatedDate(testScript.caseLastRun, 'MMM D, YYYY', '')}
                                                            </Typography>
                                                        )}
                                                    </div>
                                                </div>
                                            </TableCell>
                                            <TableCell
                                                className={[classes.borders, classes.noPadding, classes.cell, classes.noWrap, classes.minWidth].join(
                                                    ' ',
                                                )}
                                                width={headers[3].width}
                                                data-resize={`element${3}_c`}
                                            >
                                                <div style={{ paddingLeft: '10px', width: '100%' }}>
                                                    <div style={{ display: 'flex', alignItems: 'center' }}>
                                                        <Grid container spacing={1}>
                                                            <Hidden only={['xs', 'sm', 'md']}>
                                                                <div className={classes.actionButtonsDiv}>{this.checkExecute(testScript)}</div>

                                                                {/* <div className={classes.actionButtonsDiv}>
	                                                                    <IconButton
	                                                                            onClick={(e) => {
	                                                                                e.stopPropagation();
	                                                                                previewTestScript(
	                                                                                    testScript.testScriptId,
	                                                                                    testScript.scriptName,
	                                                                                )
	                                                                            }}
	                                                                            className={classes.actionButtonsWidth}
	                                                                        >
	                                                                            <FontAwesomeIcon icon={faEye} aria-label="faEye" id="faEye" className={[classes.moreIconsBtn, classes.eyeIconBtn].join(" ")} />
	                                                                        </IconButton>
                                                                        </div> */}

                                                                <div className={classes.actionButtonsDiv}>
                                                                    <div>
                                                                        <IconButton
                                                                            aria-label="More"
                                                                            aria-owns={anchorEl ? 'long-menu' : null}
                                                                            aria-haspopup="true"
                                                                            onClick={(e) => {
                                                                                e.stopPropagation();
                                                                                this.handleClick(e, i);
                                                                            }}
                                                                            className={classes.actionButtonsWidth}
                                                                        >
                                                                            <MoreVert
                                                                                className={classes.moreIconsBtn}
                                                                                aria-label="moreVertIcon"
                                                                                id="moreVertIcon"
                                                                            />
                                                                        </IconButton>
                                                                        {Boolean(anchorEl) && index === i && (
                                                                            <Menu
                                                                                id="simple-menu"
                                                                                anchorEl={anchorEl}
                                                                                open={Boolean(anchorEl) && index === i}
                                                                                onClose={this.handleClose}
                                                                            >
                                                                                {this.renderItems(testScript, testData, false)}
                                                                            </Menu>
                                                                        )}
                                                                    </div>
                                                                </div>
                                                            </Hidden>
                                                            <Hidden only={['lg', 'xl']}>
                                                                <div className={classes.actionButtonsDiv}>
                                                                    <div>
                                                                        <IconButton
                                                                            aria-label="More"
                                                                            aria-owns={anchorEl ? 'long-menu' : null}
                                                                            aria-haspopup="true"
                                                                            onClick={(e) => {
                                                                                e.stopPropagation();
                                                                                this.handleClick(e, i);
                                                                            }}
                                                                        >
                                                                            <MoreVert
                                                                                className={classes.moreIconsBtn}
                                                                                aria-label="moreVertIcon"
                                                                                id="moreVertIcon"
                                                                            />
                                                                        </IconButton>
                                                                        {Boolean(anchorEl) && index === i && (
                                                                            <Menu
                                                                                id="simple-menu"
                                                                                anchorEl={anchorEl}
                                                                                open={Boolean(anchorEl) && index === i}
                                                                                onClose={this.handleClose}
                                                                            >
                                                                                {this.renderItems(testScript, testData, true)}
                                                                            </Menu>
                                                                        )}
                                                                    </div>
                                                                </div>
                                                            </Hidden>
                                                        </Grid>
                                                    </div>
                                                </div>
                                            </TableCell>
                                        </TableRow>
                                    ))}
                                </TableBody>
                            </Table>
                        </div>
                        {deleteScriptRow ? (
                            <DeleteAlertModal
                                deleteButton="Danger"
                                handleClose={this.hadleCloseDeleteAlertModal}
                                open={deleteScriptRow}
                                modalfor="Script"
                                title="Script File"
                                name={deleteTestScript && deleteTestScript.scriptName ? deleteTestScript.scriptName : ''}
                            />
                        ) : null}
                    </div>
                ) : (
                    <div className={classes.minHeight}>
                        <NoDataToShow imgSrc={ImgSrc} messageText="No scripts found." selectedTab="script" />
                    </div>
                )}
                {openVideoModal ? (
                    videoUrl ? (
                        <VideoPreviewModal videoUrl={videoUrl} caseName={caseName} modalState handleClose={this.handleVideoClose} />
                    ) : (
                        showSnackBar('There is no video available yet')
                    )
                ) : null}
                {activeExecuteScript ? (
                    <ExecuteScriptModal
                        scriptIds={scripts}
                        projectId={activeExecuteScript.projectId}
                        name={activeExecuteScript.caseName}
                        type="script"
                        modalState
                        handleClose={this.handleExeScriptClose}
                        testCaseId={testCaseId}
                        aiqExecution={aiqExecution}
                    />
                ) : null}
                {showLoader ? <Loader /> : null}
                {openAlertDialog ? <AlertModal open={openAlertDialog} onClickAlertDialogClose={this.hadleCloseDeleteAlertModal} /> : null}
                <TableFooter
                    count={sortedScriptData.length}
                    rowsPerPage={rowsPerPage}
                    rowsPerPageOptions={[5, 10, 20, 50]}
                    page={page}
                    handleChangePage={this.handleChangePage}
                    handleChangeRowsPerPage={this.handleChangeRowsPerPage}
                    applyCustomClasses
                    labelRowsPerPage="Scripts per page:"
                    containerStyle={{ marginLeft: '-20px', marginRight: '-20px' }}
                />
            </div>
        );
    }
}

ScriptTableBase.propTypes = {
    classes: PropTypes.shape({}).isRequired,
    testScripts: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
    testData: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
    handleDeleteTestScript: PropTypes.func.isRequired,
    downloadGradle: PropTypes.func.isRequired,
    query: PropTypes.string,
    queryRemove: PropTypes.func.isRequired,
    showSnackBar: PropTypes.func.isRequired,
};

ScriptTableBase.defaultProps = {
    query: '',
};

const mapStateToProps = (state) => {
    return {
        isLoadingTestData: state.projectReducer.isLoadingTestData,
        isLoadingCases: state.projectReducer.isLoadingCases,
    };
};

export default connect(mapStateToProps, null)(withStyles(styles)(ScriptTableBase));
