import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import { connect } from 'react-redux';

import Typography from '@material-ui/core/Typography';
import Paper from '@material-ui/core/Paper';
import AccessTime from '@material-ui/icons/AccessTime';
import StarIcon from '@material-ui/icons/Star';
import { Link } from 'react-router-dom';
import Table from '../Table/Table';
import TableBody from '../Table/TableBody';
import TableCell from '../Table/TableCell';
import TableRow from '../Table/TableRow';
import { getProgressBarColor, getFormatedDate, resizeAllCols, checkArrayLength, checkKeyInObject } from '../../utils/utils';
import { EnhancedTableHead } from '../EnhancedTable/index';
import ProjectCardActions from '../ProjectHeader/ProjectCardActions';
import { TABLES_CSS, BLACK_FONT, NO_DECORATION, DISPLAY_BLOCK, WIDTH_100_PER } from '../../common/cssConstants';
import { ModalActions, ProjectActions } from '../../store/actions';

import Tooltip from '../Tooltip';
import ContentLoader from '../ContentLoader';
import { ProjectUtils } from '../../utils/ProjectUtils';

let headers = [
    { id: 'projectName', sortable: false, numeric: false, disablePadding: true, label: 'Project Name', width: '30%', styles: { paddingLeft: 10 } },
    { id: 'numberOfCases', sortable: false, numeric: false, disablePadding: true, label: 'Cases', width: 'calc(70% / 6)' },
    { id: 'totalTestsPassedCount', sortable: false, numeric: false, disablePadding: true, label: 'Passed', width: 'calc(70% / 6)' },
    { id: 'totalTestsFailedCount', sortable: false, numeric: false, disablePadding: true, label: 'Failed', width: 'calc(70% / 6)' },
    { id: 'lastActivityDate', sortable: false, numeric: false, disablePadding: true, label: 'Last Activity', width: 'calc(70% / 6)' },
    // { id: 'lastUsedBy', sortable: false, numeric: false, disablePadding: true, label: 'Last Accessed', },
    { id: 'funcs', sortable: false, numeric: false, disablePadding: true, label: 'Actions', width: 'calc(70% / 6)' },
    { id: 'percentage', sortable: false, numeric: false, disablePadding: true, label: 'Success %', width: 'calc(70% / 6)' },
];

const styles = (theme) => ({
    tableWrapper: {
        overflowX: 'hidden',
        overflowY: 'auto',
    },
    root: {
        width: 'calc(100% - 40px)',
        marginTop: '10px',
        margin: '0 20px',
        borderRadius: '6px',
        marginBottom: '10px',
        overflow: 'hidden',
        boxShadow: '0px 1px 3px 0px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 2px 1px -1px rgba(0, 0, 0, 0.12)',
    },
    cell: {
        textAlign: 'left',
        flexDirection: 'row',
        padding: 0,
        // width: 'calc(100% / 7)',

        paddingLeft: '20px',
    },
    noWrap: {
        overflow: 'hidden',
        whiteSpace: 'nowrap',
        textOverflow: 'ellipsis',
        maxWidth: '100%',
    },
    minWidth: {
        minWidth: '60px !important',
        [theme.breakpoints.only('xs')]: {
            minWidth: '200px !important',
            maxWidth: '200px !important',
        },
    },
    tableRowsFixed: {
        display: 'flex !important',
        height: '70px',
        backgroundColor: 'white',
        cursor: 'default',
    },
    tableRows: {
        display: 'flex !important',
        height: '60px',
        backgroundColor: '#fff',
    },
    tblLink: {
        ...NO_DECORATION,
        ...WIDTH_100_PER,
        display: 'flex',
        height: '100%',
        alignItems: 'center',
    },
    cellLoader: {
        borderBottom: '1px solid #ced6de',
        paddingLeft: '10px',
    },
    cellFirstLoader: {
        borderBottom: '1px solid #ced6de',
        paddingLeft: '20px',
    },
    borderBottom: {
        borderBottom: '1px solid #ced6de',
    },
    container: {
        display: 'flex',
        alignItems: 'center',
        flexDirection: 'row',
        justifyContent: 'space-between',
    },
    typo: {
        color: BLACK_FONT,
        fontSize: TABLES_CSS.normalFontSize,
        fontWeight: '500',
        display: 'inline-block',
    },
    urlStyle: {
        color: '#3B91DF',
        fontSize: TABLES_CSS.normalFontSize,
    },
    flexCenter: {
        display: 'flex',
        alignItems: 'center',
    },
    successTypo: {
        color: BLACK_FONT,
        fontSize: TABLES_CSS.normalFontSize,
        display: 'inline-block',
        width: '120px',
    },
    bold: {
        fontWeight: 500,
    },
    progressBarContainer: {
        backgroundColor: '#D9E0E7',
        width: 'calc(100% - 10px)',
        height: '9px',
        borderRadius: '5px',
    },
    projectCardActionsWrapper: {
        color: BLACK_FONT,
        fontSize: TABLES_CSS.normalFontSize,
        display: 'inline-block',
    },
    whiteBackground: {
        backgroundColor: '#fff',
    },
    dateWrapper: {
        color: BLACK_FONT,
        fontSize: TABLES_CSS.normalFontSize,
        fontWeight: '500',
        // display: 'inline-block',
    },
    greyedStar: {
        color: 'rgba(74, 74, 74,0.34)',
        fontSize: '21px',
    },
    coloredStar: {
        color: 'rgb(243, 155, 49)',
        fontSize: '21px',
    },
    wrapperStartIcon: {
        marginRight: 10,
    },
    fakeLink: {
        margin: 0,
        cursor: 'pointer',
    },
    link: NO_DECORATION,
    block: DISPLAY_BLOCK,
    width100: WIDTH_100_PER,
});

class ProjectsTable extends Component {
    constructor(props) {
        super(props);
        this.state = {
            project: [],
        };
        this.colsWidth = {};
    }


getDataResizeKey = (cellName) => {
    return `element${headers.findIndex((elm) => elm.id === cellName)}_c`;
};

getCellWidth = (cellName) => {
   
    const headIndex = headers.findIndex((elm) => elm.id === cellName);
    return headIndex > -1 ? headers[headIndex].width : '0';
};

    componentDidMount() {
        this.updateState({ project: this.props.project });
    }

    UNSAFE_componentWillReceiveProps(nextProps) {
        const { listView, project } = nextProps;
        if (listView && checkArrayLength(project)) {
            headers = headers.map((header) => {
                if (checkKeyInObject(header, 'id', 'value', false) !== 'funcs') {
                    header.sortable = true;
                }
                return header;
            });
        }
        this.setState({
            project: nextProps.project,
        });
    }

    componentDidUpdate() {
        resizeAllCols(this.colsWidth);
    }

    // handleUpdateProject = async (project) => {
    //     const modalData = { project, user: this.props.user, updateProject: true, projectId: project.projectId };
    //     this.props.toggleModal('updateProject', null, null, modalData);
    // };

    handleProjectNameEdit = (project) => {
        const modalData = { project, user: this.props.user };
        this.props.toggleModal('renameProject', null, null, modalData);
    };

    handleFavourite = (project) => {
        const { removeFavourite, addFavourite, rowsPerPage, page } = this.props;
        if (project.isFavourite) {
            return removeFavourite(project.projectId, rowsPerPage, page);
        }
        return addFavourite(project.projectId, rowsPerPage, page);
    };

    handleDelete = (project) => {
        const { doDelete, page, rowsPerPage } = this.props;
        const obj = {
            projectId: project.projectId,
            page,
            rowsPerPage,
        };
        return doDelete(obj);
    };

    handleClick = (project) => {
        const { userId } = this.props.user;
        if (userId) {
            this.props.setCurrentProject(project);
        }
    };

    updateState = (obj) => {
        this.setState(obj);
    };

    render() {
        const {
            classes,
            listView,
            toggleModal,
            page,
            order,
            orderBy,
            rowsPerPage,
            handleRequestSort,
            showDisabledProjects,
            showFavouriteProjects,
            searchedProject,
            /* isDisableProjectsLoading, */
            /* isFavouriteProjectsLoading, */
            isLoading,
        } = this.props;
        const { project } = this.state;

        const projects = isLoading ? [] : project;
        let currentPageProjects = [];

        if (checkArrayLength(projects)) {
            if (showDisabledProjects || showFavouriteProjects || checkArrayLength(searchedProject)) {
                currentPageProjects = projects.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage);
            } else {
                currentPageProjects = projects;
            }
        }

        return (
            <Paper className={classes.root}>
                <div /*  className={classes.tableWrapper} */>
                    {listView && checkArrayLength(projects) ? (
                        <Table aria-labelledby="tableProject">
                            <EnhancedTableHead
                                order={order}
                                orderBy={orderBy}
                                onRequestSort={handleRequestSort}
                                headers={headers}
                                currentPageData={currentPageProjects}
                                colsWidth={this.colsWidth}
                                rowCount={currentPageProjects.length}
                                calledFrom="projectsTable"
                                style={{ paddingRight: 0 }}
                                
                            />
                            <TableBody style={{ maxHeight: 'calc(100vh - 212px)' }}>
                                {currentPageProjects.map((tableProject) => {
                                    const date = getFormatedDate(tableProject.lastActivityDate, 'DD MMM YYYY', 'N/A');
                                    const time = getFormatedDate(tableProject.lastActivityDate, 'HH:mm:ss', 'Last Activity');
                                    const progress = `${tableProject.percentage}%`;
                                    const appurl = tableProject ? tableProject.appUrl : null;
                                    const favourite =
                                        checkKeyInObject(tableProject, 'isFavourite', 'bool') && checkKeyInObject(tableProject, 'isFavourite');
                                    const disabledColor = { color: showDisabledProjects ? '#9b9b9b' : 'rgb(59, 145, 223)' };
                                    const starredSign = {
                                        starred: favourite ? classes.coloredStar : classes.greyedStar,
                                    };
                                    return (
                                        <TableRow
                                            hover
                                            tabIndex={-1}
                                            key={tableProject.projectId}
                                            className={classes.tableRows}
                                            style={{ cursor: !showDisabledProjects ? 'pointer' : 'default' }}
                                            onClick={!showDisabledProjects ? () => this.handleClick(tableProject) : null}
                                        >
                                            <TableCell
                                                className={[classes.cell, classes.minWidth, classes.cellFirstLoader].join(' ')}
                                                // width={headers[0].width}
                                                // data-resize={`element${0}_c`}
                                                width={this.getCellWidth('projectName')}
                                                data-resize={this.getDataResizeKey('projectName')}
                                            >
                                                <div className={classes.width100}>
                                                    <div className={classes.container}>
                                                        <div>
                                                            <Link
                                                                className={`${classes.link} ${classes.block} ${classes.width100}`}
                                                                to={`/details/${tableProject.projectId}/cases`}
                                                            >
                                                                <Typography className={`${classes.typo}`}>
                                                                    <Tooltip data={tableProject.projectName ? tableProject.projectName : null}>
                                                                        {tableProject.projectName}
                                                                    </Tooltip>
                                                                </Typography>
                                                            </Link>
                                                            <Typography className={`${classes.urlStyle}`}>
                                                                <Tooltip data={appurl}>
                                                                    {showDisabledProjects ? (
                                                                        <p style={disabledColor} className={`${classes.link} ${classes.fakeLink}`}>
                                                                            {appurl}
                                                                        </p>
                                                                    ) : (
                                                                        <a
                                                                            className={classes.link}
                                                                            style={disabledColor}
                                                                            target="_blank"
                                                                            rel="noopener noreferrer"
                                                                            href={appurl}
                                                                            onClick={(e) => {
                                                                                e.stopPropagation();
                                                                            }}
                                                                        >
                                                                            {appurl}
                                                                        </a>
                                                                    )}
                                                                </Tooltip>
                                                            </Typography>
                                                        </div>

                                                        <div className={classes.wrapperStartIcon}>
                                                            <StarIcon className={starredSign.starred} aria-label="starIcon" id="starIcon" />
                                                        </div>
                                                    </div>
                                                </div>
                                            </TableCell>
                                            <TableCell
                                                className={[classes.cell, classes.noWrap, classes.minWidth, classes.borderBottom].join(' ')}
                                                width={headers[1].width}
                                                data-resize={`element${1}_c`}
                                            >
                                                <Link className={classes.tblLink} to={`/details/${tableProject.projectId}/cases`}>
                                                    <div className={classes.width100}>
                                                        <div className={classes.flexCenter}>
                                                            <Typography noWrap className={`${classes.noWrap} ${classes.typo}`}>
                                                                {tableProject.numberOfCases}
                                                            </Typography>
                                                        </div>
                                                    </div>
                                                </Link>
                                            </TableCell>
                                            <TableCell
                                                className={[classes.cell, classes.noWrap, classes.minWidth, classes.borderBottom].join(' ')}
                                                width={headers[2].width}
                                                data-resize={`element${2}_c`}
                                            >
                                                <Link className={classes.tblLink} to={`/details/${tableProject.projectId}/cases`}>
                                                    <div className={classes.width100}>
                                                        <div className={classes.flexCenter}>
                                                            <Typography noWrap className={`${classes.noWrap} ${classes.typo}`}>
                                                                {tableProject.totalTestsPassedCount}
                                                            </Typography>
                                                        </div>
                                                    </div>
                                                </Link>
                                            </TableCell>
                                            <TableCell
                                                className={[classes.cell, classes.noWrap, classes.minWidth, classes.borderBottom].join(' ')}
                                                width={headers[3].width}
                                                data-resize={`element${3}_c`}
                                            >
                                                <Link className={classes.tblLink} to={`/details/${tableProject.projectId}/cases`}>
                                                    <div className={classes.width100}>
                                                        <div className={classes.flexCenter}>
                                                            <Typography noWrap className={`${classes.noWrap} ${classes.typo}`}>
                                                                {tableProject.totalTestsFailedCount}
                                                            </Typography>
                                                        </div>
                                                    </div>
                                                </Link>
                                            </TableCell>
                                            <TableCell
                                                className={[classes.cell, classes.noWrap, classes.minWidth, classes.borderBottom].join(' ')}
                                                width={headers[4].width}
                                                data-resize={`element${4}_c`}
                                            >
                                                <Link className={classes.tblLink} to={`/details/${tableProject.projectId}/cases`}>
                                                    <div style={{ width: '100%' }}>
                                                        <div className={classes.flexCenter}>
                                                            <AccessTime
                                                                style={{
                                                                    fontSize: '18px',
                                                                    marginRight: '5px',
                                                                    color: showDisabledProjects ? '#4A4A4A' : BLACK_FONT,
                                                                }}
                                                                aria-label="accessTimeIcon"
                                                                id="accessTimeIcon"
                                                            />
                                                            <Typography className={`${classes.noWrap} ${classes.dateWrapper}`}>
                                                                <Tooltip data={time}>{date}</Tooltip>
                                                            </Typography>
                                                        </div>
                                                    </div>
                                                </Link>
                                            </TableCell>
                                            <TableCell
                                                className={[classes.cell, classes.noWrap, classes.minWidth, classes.borderBottom].join(' ')}
                                                width={headers[5].width}
                                                data-resize={`element${5}_c`}
                                            >
                                                <div className={classes.width100}>
                                                    <div className={classes.flexCenter}>
                                                        <span className={classes.projectCardActionsWrapper}>
                                                            <ProjectCardActions
                                                                showDisabledProjects={showDisabledProjects}
                                                                handleUpdateProject={() => {
                                                                    ProjectUtils.toggleUpdateModal(true, tableProject.projectId, tableProject);
                                                                }}
                                                                handleProjectNameEdit={() => this.handleProjectNameEdit(tableProject)}
                                                                projectId={tableProject.projectId}
                                                                project={tableProject}
                                                                onDelete={() => {
                                                                    this.handleDelete(tableProject);
                                                                }}
                                                                toggleModal={toggleModal}
                                                                handleFavourite={() => {
                                                                    this.handleFavourite(tableProject);
                                                                }}
                                                                isFavourite={tableProject.isFavourite}
                                                            />
                                                        </span>
                                                    </div>
                                                </div>
                                            </TableCell>
                                            <TableCell
                                                className={[
                                                    classes.cell,
                                                    classes.noWrap,
                                                    classes.minWidth,
                                                    classes.borderBottom,
                                                    classes.whiteBackground,
                                                ].join(' ')}
                                                width={headers[6].width}
                                                data-resize={`element${6}_c`}
                                            >
                                                <Link className={classes.tblLink} to={`/details/${tableProject.projectId}/cases`}>
                                                    <div style={{ width: 'calc(100% - 10px)' }}>
                                                        <div className={classes.flexCenter}>
                                                            <Typography noWrap className={`${classes.noWrap} ${classes.successTypo}`}>
                                                                Success <span className={classes.bold}>{progress}</span>
                                                            </Typography>
                                                        </div>
                                                        <div className={classes.progressBarContainer}>
                                                            <div
                                                                style={{
                                                                    backgroundColor: showDisabledProjects
                                                                        ? '#9B9B9B'
                                                                        : getProgressBarColor(tableProject.percentage),
                                                                    width: `${progress}`,
                                                                    height: '9px',
                                                                    borderRadius: '5px',
                                                                }}
                                                            ></div>
                                                        </div>
                                                    </div>
                                                </Link>
                                            </TableCell>
                                        </TableRow>
                                    );
                                })}
                            </TableBody>
                        </Table>
                    ) : (
                        <Table aria-labelledby="">
                            <EnhancedTableHead headers={headers} colsWidth={this.colsWidth} resizeable={false} rowCount={5} />
                            <TableBody style={{ maxHeight: 'calc(100vh - 219px)' }}>
                                {new Array(5).fill(0).map((_, index) => {
                                    // For generating fixed 5 loading rows
                                    return (
                                        <TableRow hover tabIndex={-1} key={index} className={classes.tableRowsFixed}>
                                            <TableCell width={headers[0].width} className={`${classes.cell} ${classes.cellFirstLoader}`}>
                                                <ContentLoader height={35} width={120} />
                                            </TableCell>
                                            <TableCell className={`${classes.cell} ${classes.cellLoader}`} width={headers[1].width}>
                                                <ContentLoader height={20} width={120} />
                                            </TableCell>
                                            <TableCell className={`${classes.cell} ${classes.cellLoader}`} width={headers[2].width}>
                                                <ContentLoader height={20} width={120} />
                                            </TableCell>
                                            <TableCell className={`${classes.cell} ${classes.cellLoader}`} width={headers[3].width}>
                                                <ContentLoader height={20} width={120} />
                                            </TableCell>
                                            <TableCell className={`${classes.cell} ${classes.cellLoader}`} width={headers[4].width}>
                                                <ContentLoader height={20} width={120} />
                                            </TableCell>
                                            <TableCell className={`${classes.cell} ${classes.cellLoader}`} width={headers[5].width}>
                                                <ContentLoader height={20} width={120} />
                                            </TableCell>
                                            <TableCell className={`${classes.cell} ${classes.cellLoader}`} width={headers[6].width}>
                                                <ContentLoader height={20} width={120} />
                                            </TableCell>
                                        </TableRow>
                                    );
                                })}
                            </TableBody>
                        </Table>
                    )}
                </div>
            </Paper>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        query: state.generalReducer.queryValue,
        user: state.authReducer.user,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        doDelete: (obj) => dispatch(ProjectActions.deleteProjects(obj)),
        setCurrentProject: (...args) => dispatch(ProjectActions.setCurrentProject(...args)),
        toggleModal: (...args) => dispatch(ModalActions.toggleModal(...args)),
        handleFavourite: (...args) => dispatch(ProjectActions.handleFavourite(...args)),
        addFavourite: (...args) => dispatch(ProjectActions.addFavourite(...args)),
        removeFavourite: (...args) => dispatch(ProjectActions.removeFavourite(...args)),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(ProjectsTable));
