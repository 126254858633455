import React, { useEffect, useState /* , { useEffect } */ } from 'react';
import _ from 'lodash';
import { Link } from 'react-router-dom';
import { IconButton } from '@material-ui/core';
import CircularProgress from '@material-ui/core/CircularProgress';
// icons
import Tag from 'mdi-material-ui/Tag';
import Delete from '@material-ui/icons/Delete';
import FlashOn from '@material-ui/icons/FlashOn';
import PlayCircleOutlineRoundedIcon from '@material-ui/icons/PlayCircleOutlineRounded';
import FileCopyOutlinedIcon from '@material-ui/icons/FileCopyOutlined';

// custom components
import CaseStatus from '../../Case/CaseStatus';
import { checkArrayLength, checkKeyInObject, getFormatedDate, getRelativeTime, getParamValues } from '../../../../utils/utils';
import { TABLES_CSS } from '../../../../common/cssConstants';
import Tooltip from '../../../../components/Tooltip';
import { TestCaseMenuOptions } from '../CaseMenuOptions_v2';
import TableCell from '../../../../components/Table/TableCell';
import TableRow from '../../../../components/Table/TableRow';
import DeleteTestCaseDialog from '../../../plan/case/DeleteTestCase';
import ExecuteScriptModal from '../../Script/ExecuteScriptModal';
import { useStyles } from './styles';
import useContainer from './container';

import { track } from '../../../../services/Segment';
import { SEGMENT_EVENT } from '../../../../common/constants';

const CaseTableRow = (props) => {
    const classes = useStyles();
    const {
        testCase,
        index,
        heads,
        showTagArea,
        /* getPreferences,
        cols, */
        match,
        previewTestCase,
        previewTestData,
        previewTestScript,
        isChecked,
        isHovered,
        setHovered,
    } = props;
    const {
        actions,
        redux,
        handleClickRow,
        showTags,
        getCell,
        getDataResizeKey,
        setOpenDeleteCaseDialog,
        openDeleteCaseDialog,
        hadleCloseDeleteAlertModal,
    } = useContainer(props);
    const [testScript, setTestScript] = useState({});
    const [disableTestCaseModalFlag, setDisableTestCaseModalFlag] = useState(false);
    const [activeExecuteScript, setActiveExecuteScript] = useState(0);
    const [scripts, setScripts] = useState('');
    const [callingFrom,setCallingFrom] = useState('Generate');
    const [openGenerateModal,setOpenGenerateModal] = useState(false);

    const getExecutionScript = (activeExecuteScript) => {
        const scripts = [];
        if (activeExecuteScript) {
            scripts.push(activeExecuteScript);
        } else {
            scripts.length = 0;
        }
        setActiveExecuteScript(activeExecuteScript)
        setScripts(scripts);
        handleClose();
    };

    const handleExeScriptClose = () => {
        replaceURL();
        setActiveExecuteScript(null);
        setOpenGenerateModal(false)
    };


    const handleClose = () => {
    };

    const replaceURL = (scriptId, modalName) => {
        if (scriptId && modalName) {
            props.history.replace(`/details/${getParamValues(1)}/cases/${scriptId}/${modalName}`);
        } else {
            props.history.replace(`/details/${getParamValues(1)}/cases`);
        }
    };

    const trackSegmentEvent = (testCase={}) => {

        const eventName =  SEGMENT_EVENT.TEST_CASE_EXECUTION_INITIATED
        const { testCaseName ,testCaseId,projectName} = testCase

        const segmentData = {
                page:'Project Details',
                testcase_name : testCaseName,
                testcase_id: testCaseId,
                project_name : projectName
            }
            
        track(eventName,segmentData)
    }


    const disableGenerateButton = () =>{
        return (
            redux.isGenerateBtnClicked ||
            openGenerateModal ||
            Boolean(redux.isWsRunning) || 
            testCase.isGenerating || 
            redux.wsRunningCaseData
            );
    }
    return (
       <TableRow
            className={`${classes.tableRow} `}
            focusCustom={classes.focused}
            hover
            isTableRowStyle={true}
            // style={redux.selectedExecuteTask === testCase.testCaseId ? { background: '#F9F9F9', height: '40px' } : { height: '40px' }}
            tabIndex={-1}
            key={index}
            data-testid={`row-${index}`}
            focused={isChecked}
            onMouseEnter={() => setHovered(testCase.testCaseId)}
            // onMouseOver={() => !isHovered && setHovered(true)}
            onMouseLeave={() => disableTestCaseModalFlag ? setHovered(testCase.testCaseId) : setHovered(null)}

        >
            {/***<TableCell nowrap width={getCell('cb').width} data-resize={getDataResizeKey('cb')} style={getCell('cb').tableCellStyles}>
                <Checkbox
                    onClick={(e) => {
                        e.preventDefault();
                        e.stopPropagation();
                        handleSelect(testCase.testCaseId);
                    }}
                    checked={isChecked}
                    style={{
                        color: isChecked ? '#3b91df' : '#bac4ce',
                        marginLeft: 5,
                    }}
                /> 
            </TableCell>
            <TableCell width={getCell('del').width} data-resize={getDataResizeKey('del')} style={getCell('del').tableCellStyles}>
                <span data-testid={`del-${index}`}>
                    {showTagArea && (
                        <div style={heads.length === 2 ? { marginLeft: -10, width: 18 } : { width: 25 }}>
                            {checkArrayLength(testCase.tags) && (
                                <div>
                                    <Tooltip data={showTags(testCase.tags)}>
                                        <Tag className={`${classes.moreIcons} ${classes.tagIcon}`} />
                                    </Tooltip>
                                </div>
                            )}
                        </div>
                    )}
                </span>
            </TableCell>***/}
            <TableCell isTableCellClass={true} width={getCell('testCaseName').width} data-resize={getDataResizeKey('testCaseName')} style={getCell('testCaseName').tableCellStyles}>
                    <span data-testid={`testCaseName-${index}`} className={`${classes.caseName} ${checkArrayLength(testCase.tags) ? classes.showTagIcon : ''}`}>
                            <div style={{overflow:"hidden", textOverflow:"ellipsis", whiteSpace:"nowrap"}}>
                                <Tooltip data={` ${testCase.testCaseName}`}>
                                    <Link to={match.params.projectId ? `/details/${match.params.projectId}/${testCase.testCaseId}` : null}>
                                        {testCase.testCaseName}
                                    </Link>
                               </Tooltip>
                            </div>
                    </span>
             
            </TableCell>
            <TableCell isTableCellClass={true} nowrap width={getCell('createdTime').width} data-resize={getDataResizeKey('createdTime')}>
                <span data-testid={`createdTime-${index}`}>
                    {testCase.createdTime
                        ? checkKeyInObject(redux.user, 'isRelativeDateFormat')
                            ? getRelativeTime(testCase.createdTime)
                            : getFormatedDate(testCase.createdTime, 'DD MMM YYYY', '')
                        : ''}
                </span>
            </TableCell>
            <TableCell isTableCellClass={true} nowrap width={getCell('sortingStatus').width} data-resize={getDataResizeKey('sortingStatus')}>
                <span className={classes.wrapperTextContent} data-testid={`sortingStatus-${index}`}>
                    <CaseStatus
                        testCase={testCase}
                        customStyles={{
                            fontSize: TABLES_CSS.normalFontSize,
                            color: TABLES_CSS.normalFontColor,
                        }}
                        is_nowrap
                    />
                </span>
            </TableCell>
            <TableCell isTableCellClass={true} nowrap width={getCell('lastRun').width} data-resize={getDataResizeKey('lastRun')}>
                <span data-testid={`lastRun-${index}`}>
                    {testCase.lastRun && !testCase.lastRun.includes('0001') && testCase.lastRun !== testCase.createdTime
                        ? checkKeyInObject(redux.user, 'isRelativeDateFormat')
                            ? getRelativeTime(testCase.lastRun)
                            : getFormatedDate(testCase.lastRun, 'DD MMM YYYY', '')
                        : ''}
                </span>
            </TableCell>

            <TableCell
                centerV
                isTableCellClass={true} 
                width={getCell('Generate').width}
                data-resize={getDataResizeKey('Generate')}
                style={getCell('Generate').tableCellStyles}
            >
                <span data-testid={`Generate-${index}`}>
                    {(isHovered === testCase.testCaseId || isChecked) && (
                        <Tooltip data="Generate">
                            <IconButton
                                className={classes.IconButton}
                                onClick={(e) => {
                                    e.stopPropagation();
                                    replaceURL(testCase.testScriptId, 'execute');
                                    setOpenGenerateModal(true)
                                    setHovered(null);
                                    setCallingFrom('Generate')
                                }}
                                // onClick={() => {
                                //     if (!(Boolean(redux.isWsRunning) || testCase.isGenerating || redux.wsRunningCaseData)) {
                                //         runTestSteps(testCase);
                                //     }
                                // }}
                                disabled={ disableGenerateButton() }

                            >
                                    <PlayCircleOutlineRoundedIcon
                                    className={[
                                        classes.moreIcons,
                                        classes.shareButton,
                                        `${ disableGenerateButton() ? classes.moreIconsBtnDisabled : classes.moreIconsBtnEnabled}`,
                                    ].join(' ')}
                                    aria-label="generateIcon"
                                    id="generateIcon"
                                />
                            </IconButton>
                        </Tooltip>)

                    }

                </span>
            </TableCell>
            <TableCell isTableCellClass={true} centerV width={getCell('Execute').width} data-resize={getDataResizeKey('Execute')} style={getCell('Execute').tableCellStyles}>
                <span data-testid={`Execute-${index}`}>
                    {(isHovered === testCase.testCaseId || isChecked) && (
                        <Tooltip data="Execute">
                            <IconButton className={classes.IconButton} onClick={(e) => {
                                trackSegmentEvent(testCase)
                                e.stopPropagation();
                                replaceURL(testCase.testScriptId, 'execute');
                                getExecutionScript(testCase.testScriptId);
                                setHovered(null);
                                setCallingFrom('Execute');
                            }}
                                disabled={testCase.testScriptId==0}
                            >
                                    <FlashOn
                                    className={[
                                        classes.moreIcons,
                                        classes.shareButton,
                                        `${(testCase.testScriptId) ? classes.moreIconsBtnEnabled : classes.moreIconsBtnDisabled}`
                                    ].join(' ')}
                                    aria-label="flashOnIcon"
                                    id="flashOnIcon"
                                    />
                            </IconButton>
                        </Tooltip>)}
                    {openGenerateModal || activeExecuteScript ? (
                        <ExecuteScriptModal
                            scriptIds={scripts}
                            projectId={testCase.discoveryId}
                            testCase={testCase}
                            callingFrom={callingFrom}
                            name={testCase.testCaseName}
                            type="script"
                            modalState
                            handleClose={handleExeScriptClose}
                            testCaseId={testCase.testCaseId}
                            aiqExecution={redux.project.aiqExecution}
                        />
                    ) : null}
                </span>
            </TableCell>
            <TableCell isTableCellClass={true} centerV width={getCell('Clone').width} data-resize={getDataResizeKey('Clone')} style={getCell('Clone').tableCellStyles}>
                <span data-testid={`Clone-${index}`}>
                    {(isHovered === testCase.testCaseId || isChecked) && (
                        <Tooltip data="Clone">
                            <IconButton className={classes.IconButton} onClick={() => {
                                actions.cloneTestCase(redux.user?.userId, testCase.testCaseId, 'projectDetails')
                                const eventName = SEGMENT_EVENT.TEST_CASE_EVENT.TEST_CASE_CLONED;
                                const segmentData = {
                                    testcase_id : testCase.testCaseId,
                                    testcase_name : testCase.testCaseName,
                                    project_name : testCase.projectName
                                }
                                track(eventName,segmentData);
                                
                                }}>
                                <FileCopyOutlinedIcon
                                    className={[classes.moreIconsBtn, classes.shareButton].join(' ')}
                                    aria-label="Clone"
                                    id="Clone"
                                />
                            </IconButton>
                        </Tooltip>)

                    }

                </span>
            </TableCell>
            <TableCell isTableCellClass={true} centerV width={getCell('Delete').width} data-resize={getDataResizeKey('Delete')} style={getCell('Delete').tableCellStyles}>
                <span data-testid={`/Delete-${index}`}>
                    {(isHovered === testCase.testCaseId || isChecked) && (
                        <Tooltip data="Delete">
                            <IconButton
                                className={classes.IconButton}
                                onClick={() => {
                                    setHovered(null);
                                    setOpenDeleteCaseDialog(true)
                                }}>
                                <Delete
                                    className={[classes.moreIconsBtn, classes.shareButton].join(' ')}
                                    aria-label="Delete"
                                    id="Delete"
                                />
                            </IconButton>
                        </Tooltip>
                    )

                    }
                    {openDeleteCaseDialog ? (
                        <DeleteTestCaseDialog
                            deleteButton="Danger"
                            handleClose={(flag) => {
                                hadleCloseDeleteAlertModal(flag);
                            }}
                            open={openDeleteCaseDialog}
                            isDisableCase={0}
                            deleteTestCaseData={testCase}
                        />
                    ) : null}
                </span>
            </TableCell>
            <TableCell isTableCellClass={true} centerV width={getCell('More').width} data-resize={getDataResizeKey('More')} style={getCell('More').tableCellStyles}>
                <span data-testid={`More-${index}`}>
                    <div className={classes.actionButtonsDiv}>
                        {(isHovered === testCase.testCaseId || isChecked) && (
                            <TestCaseMenuOptions
                                callingFrom="projectDetails"
                                testCase={testCase}
                                previewTestCase={previewTestCase}
                                previewTestData={previewTestData}
                                previewTestScript={previewTestScript}
                                handleClickRow={handleClickRow}
                                // runTestSteps={runTestSteps}
                                match={match}
                                extend={false}
                                setDisableTestCaseModalFlag={setDisableTestCaseModalFlag}
                            />)}
                    </div>
                </span>
            </TableCell>
        </TableRow>
    );
};

const shouldComponentUpdate = (prevProps, nextProps) => {
    return _.isEqual(_.omit(nextProps, _.functions(nextProps)), _.omit(prevProps, _.functions(prevProps)));
};
export default React.memo(CaseTableRow, shouldComponentUpdate);