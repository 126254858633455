import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import { Popper, Fade, Paper } from '@material-ui/core';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import { BLACK_FONT,WHITE_FONT } from '../../common/cssConstants';

const styles = () => ({
    maxWidth: {
        maxWidth: '100%',
    },
    paper: {
        padding: 0,
        maxWidth: '320px',
        height: 'auto',
        wordBreak: 'break-word',
        zIndex: '10000',
        borderRadius: 8,
        marginTop: '8px',
    },
    pEvents:{
        pointerEvents: 'none'
    },
    popperPaperChild: {
        boxShadow: 'none !important',
        right: '-3px',
        top: '-3px',
        background: 'transparent',
    },
    paperBody: {
        boxShadow: '0 2px 7px 0 rgba(0, 0, 0, 0.8)',
        backgroundColor: '#fff',
        borderRadius: '3px',
        padding: '6px',
        position: 'relative',
    },
    tootTipLabel: {
        fontSize: '12px',
        color: BLACK_FONT,
        margin: '0px',
        fontWeight: 400,
    },
    tooltipArrow: {
        position: 'absolute',
        left: '50% !important',
        transform: 'translate(-50%, -50%) rotate(224deg)',
        width: '10px',
        height: '10px',
        zIndex: '1',
        top: '-1px',
        '&::after': {
            content: '""',
            position: 'absolute',
            width: '10px',
            height: '10px',
            background: 'white',
            borderBottom: 'solid 1px #b1b1b0',
            borderRight: 'solid 1px #b1b1b0',
        },
    },
    noWrap: {
        overflow: 'hidden',
        whiteSpace: 'nowrap',
        textOverflow: 'ellipsis',
        maxWidth: '100%',
    },
});

class Tooltip extends React.Component {
    state = {
        anchorEl: null,
        showPopUp: false,
        isOpen: false,
    };

    componentDidMount() {
        this.isComponentMount = true;
    }

    UNSAFE_componentWillReceiveProps(nextProps) {
        const { forceClose } = nextProps;
        if (forceClose) {
            this.handlePopoverTimer();
        }
    }

    componentWillUnmount() {
        this.isComponentMount = false;
    }

    dataRef = React.createRef();

    isComponentMount = false;

    _setState = (obj, cb = () => {}) => {
        if (this.isComponentMount) {
            this.setState(obj, cb);
        }
    };

    handlePopoverOpen = (event) => {
        if (!this.state.showPopUp) {
            const { currentTarget } = event;
            let currElem = currentTarget;
            let isChanged = false;
            if (currElem && currElem.offsetWidth && currElem.parentElement.parentElement.offsetWidth < currElem.offsetWidth) {
                isChanged = true;
                currElem = currElem.parentElement.parentElement;
            }
            let doUpdate = false;
            if (this.props.data && this.dataRef && this.dataRef.current && this.dataRef.current.parentElement) {
                if (
                    /* this.dataRef.current.parentElement.nodeName.toLowerCase() == "p" &&  */
                    this.dataRef.current.parentElement.textContent.toLowerCase() === `${this.props.data}`.toLowerCase()
                ) {
                    if (this.dataRef.current.parentElement.scrollWidth > this.dataRef.current.parentElement.clientWidth) {
                        doUpdate = true;
                    }
                } else {
                    doUpdate = true;
                }
            }
            if ((!currElem.offsetWidth || !currElem.offsetHeight) && !isChanged) {
                currElem = currElem.parentElement;
            }
            if (doUpdate) {
                this._setState({
                    showPopUp: true,
                    anchorEl: this.state.anchorEl ? null : currElem,
                    isOpen: true,
                });
            }
        }
    };

    handlePopoverTimer = () => {
        if (this.state.showPopUp && !this.props.persistTooltip) {
            this._setState({
                showPopUp: false,
                anchorEl: null,
                isOpen: false,
                arrowRef: null,
            });
        } else if (this.props.persistTooltip) {
            setTimeout(() => {
                if(!this.state.keepTooltipOpen) {
                    this._setState({
                        showPopUp: false,
                        anchorEl: null,
                        isOpen: false,
                        arrowRef: null,
                    });
                }
            }, 1500);
        }
    };

    handleArrowRef = (node) => {
        this._setState({ arrowRef: node });
    };

    render() {
        const {
            children,
            classes,
            data,
            showArrow,
            isElement,
            position = 'bottom',
            isOnClick,
            allowTooltipHover = false,
            closeOnSecondClick = true,
            closeOnElementClick = false,
            isPointerEvents = true,
            paperBodyStyle,
            toolTipAreaStyle,
            paperBody,
            tootTipLabel,
            testid,
            tooltipArrow,
            persistTooltip = false,
            popperStyle = {}
        } = this.props;
        const { anchorEl, arrowRef, isOpen } = this.state;
        const _popper =
            Boolean(anchorEl) && (anchorEl.offsetWidth || anchorEl.clientWidth) ? (
                <Popper
                    anchorEl={anchorEl}
                    className={`${classes.paper} ${isPointerEvents ? classes.pEvents : ''}`}
                    style={popperStyle}
                    modifiers={{ arrow: { enabled: showArrow, element: arrowRef } }}
                    placement={position}
                    open={Boolean(anchorEl) && isOpen}
                >
                    {({ TransitionProps, placement }) => (
                        <Fade {...TransitionProps} timeout={350} style={{ opacity: 1, marginTop: placement.includes('top') ? '-8px' : 0 }} in>
                            <div>
                                {showArrow && (
                                    <span
                                        style={
                                            placement.includes('top')
                                                ? {
                                                      transform: 'translate(-50%, -50%) rotate(45deg)',
                                                      top: 'calc(100% + 1px)',
                                                  }
                                                : {}
                                        }
                                        className={tooltipArrow || classes.tooltipArrow}
                                        ref={this.handleArrowRef}
                                    />
                                )}
                                <Paper className={classes.popperPaperChild}>
                                    <ClickAwayListener
                                        onClickAway={() => {
                                            if (closeOnSecondClick) {
                                                setTimeout(this.handlePopoverTimer, 0);
                                            } 
                                            // else {
                                            //     this.handlePopoverTimer();
                                            // }
                                        }}
                                    >
                                        <div 

                                        onMouseOver = {()=>{
                                            if(persistTooltip) {
                                                this.setState({
                                                    keepTooltipOpen : true
    
                                                })
                                            }
                                        }}
                                        onMouseLeave = {()=>{

                                            setTimeout(() => {
                                                    this._setState({
                                                        keepTooltipOpen:false,
                                                        showPopUp: false,
                                                        anchorEl: null,
                                                        isOpen: false,
                                                        arrowRef: null,
                                                    });
                                            }, 1500);

                                        }}
                                         
                                         className={paperBody || classes.paperBody} style={paperBodyStyle || {}}>
                                            <span
                                                style={{ display: 'flex' }}
                                                onClick={() => {
                                                    if (closeOnElementClick) {
                                                        this.handlePopoverTimer();
                                                    }
                                                }}
                                                aria-hidden
                                            >
                                                {isElement ? data : <div className={ tootTipLabel|| classes.tootTipLabel}>{data}</div>}
                                            </span>
                                        </div>
                                    </ClickAwayListener>
                                </Paper>
                            </div>
                        </Fade>
                    )}
                </Popper>
            ) : null;

        return data ? (
            <span className={classes.maxWidth} data-tooltip={isElement ? 'Dom Element' : data} ref={this.dataRef} style={toolTipAreaStyle || {}}>
                <span
                    className={classes.maxWidth}
                    data-testid={testid}
                    onClick={
                        isOnClick ? (Boolean(anchorEl) && isOpen && closeOnSecondClick ? this.handlePopoverTimer : this.handlePopoverOpen) : null
                    }
                    onMouseOver={isOnClick ? null : this.handlePopoverOpen}
                    onMouseOut={isOnClick ? null : persistTooltip ? null : this.handlePopoverTimer}
                    onMouseLeave={!persistTooltip ? null : this.handlePopoverTimer}
                    aria-hidden
                >
                    {children}
                    {allowTooltipHover ? _popper : null}
                </span>
                {!allowTooltipHover ? _popper : null}
            </span>
        ) : (
            children || ''
        );
    }
}

Tooltip.propTypes = {
    classes: PropTypes.shape({}).isRequired,
    data: PropTypes.any,
    showArrow: PropTypes.bool,
    testid: PropTypes.string,
};

Tooltip.defaultProps = {
    data: null,
    showArrow: false,
    testid: '',
};

export default withStyles(styles)(Tooltip);
