/* eslint-disable no-underscore-dangle */
import { withStyles } from '@material-ui/core/styles';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import Typography from '@material-ui/core/Typography';
import Dialog from '@material-ui/core/Dialog';
import CheckBox from '@material-ui/icons/CheckBox';
import CheckBoxBlank from '@material-ui/icons/CheckBoxOutlineBlank';
import Close from '@material-ui/icons/Close';
import Button from '@material-ui/core/Button';
import CloseOnEscape from 'react-close-on-escape'; // Keyboard input comprehension node modules
import { connect } from 'react-redux';
import React from 'react';
import PropTypes from 'prop-types';
import { faFolder } from '@fortawesome/free-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import CheckCircle from '@material-ui/icons/CheckCircle';
import Cancel from '@material-ui/icons/Cancel';

// icon
import Done from '@material-ui/icons/Done';
import Clear from '@material-ui/icons/Clear';

import Filedrop from '../../../components/Filedrop/Filedrop';
import Loader from '../../../components/Loader';
import { pluralize } from '../../../common/presentation';
import WSService from '../../../services/WSService';
import { UploadProgressActions, ProjectActions, TestSuiteActions } from '../../../store/actions';
import { styles } from './uploadWizardStyle';
import { checkArrayLength, checkKeyInObject } from '../../../utils/utils';
import './uploadWizardScroll.css';
import { TestCaseUtils } from '../../../utils/TestCaseUtils';
import Tooltip from '../../../components/Tooltip';
import { SEGMENT_EVENT } from '../../../common/constants';
import { track } from '../../../services/Segment';

let isMounted = false;

class UploadWizard extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            activeStep: 0,
            artifacts: false,
            audio: false,
            artifactChecked: true,
            audioChecked: true,
            autoNext: true,
            casefile: false,
            completed: {},
            currentFiles: [],
            datafile: false,
            ignoredFiles: [],
            isButtonDisabled: false,
            loader: false,
            selectedProject: '',
            totalFiles: 0,
            testDataChecked: true,
            uploadedFiles: [],
            // states to manage after upload api responded
            isResponded: false,
            succeedFilesArray: [],
            failedFilesArray: [],
            currentFile: '',
        };
        this.refForEnterPress = React.createRef();
    }

    componentDidMount() {
        isMounted = true;
    }

    componentDidUpdate() {
        this.focusRefElement();
    }

    componentWillUnmount() {
        isMounted = false;
    }

    onFileDropCallback = ([...files], step) => {
        const { currentFiles, uploadedFiles } = this.state;
        const { selectedProjectId, user, showSnackBar } = this.props;
        this.setStateIfComponentMounted({ autoNext: true });
        if (step === 'artifacts') {
            const filesArray = files.map((file) => {
                this.setStateIfComponentMounted({
                    totalFiles: files.length + this.state.totalFiles,
                });
                return {
                    systemId: selectedProjectId,
                    projectId: selectedProjectId,
                    key: `${step}Id`,
                    [step]: file,
                    accountId: this.props.user && this.props.user.accountId ? this.props.user.accountId : 1,
                };
            });
            this.setStateIfComponentMounted({
                uploadedFiles: [...uploadedFiles, ...filesArray],
                currentFiles: [...currentFiles, ...files],
                [step]: true,
            });
        } else {
            let IgnoredFileTimeout = null;
            const ignoredFiles = [...this.state.ignoredFiles];
            const filesArray = [];
            files.forEach((file) => {
                const fileExtension = `.${file.name.split('.').pop()}`;
                if (!(this.checkFileType(file.type, step) || this.checkFileExtension(fileExtension, step))) {
                    ignoredFiles.push({
                        file: { name: file.name, tab: this.state.activeStep === 0 ? 'Case' : this.state.activeStep === 3 ? 'Audio' : 'Data' },
                        key: `${step}Id`,
                        status: 'ignored',
                    });
                    IgnoredFileTimeout = setTimeout(() => {
                        clearTimeout(IgnoredFileTimeout);
                        showSnackBar(
                            this.state.activeStep === 3
                                ? 'Only wav file formats are acceptable'
                                : 'Only xls, xlsx, or csv file formats are acceptable',
                        );
                        this.setStateIfComponentMounted({
                            ignoredFiles,
                        });
                    }, 1000);
                }
                if (this.checkFileType(file.type, step) || this.checkFileExtension(fileExtension, step)) {
                    if (step === 'datafile' || step === 'audio') {
                        if (!checkArrayLength(filesArray)) {
                            filesArray.push({
                                systemId: selectedProjectId,
                                projectId: selectedProjectId,
                                key: `${step}Id`,
                                [step]: file,
                                accountId: user && user.accountId ? user.accountId : 1,
                            });
                        }
                    } else {
                        filesArray.push({
                            systemId: selectedProjectId,
                            projectId: selectedProjectId,
                            key: `${step}Id`,
                            [step]: file,
                            accountId: user && user.accountId ? user.accountId : 1,
                        });
                    }
                    // this.handleNext();
                }
            });

            this.setStateIfComponentMounted(
                {
                    uploadedFiles: [...uploadedFiles, ...filesArray],
                    currentFiles: [...currentFiles, ...files],
                    totalFiles: this.state.totalFiles + files.length,
                },
                () => {
                    this.setStateIfComponentMounted({ [step]: true });
                },
            );
        }
    };

    setStateIfComponentMounted = (obj, callback = () => {}) => {
        if (isMounted) this.setState(obj, callback);
    };

    // eslint-disable-next-line class-methods-use-this
    get getSteps() {
        return ['Test Case', 'Test Data', 'Artifacts', 'Audio'];
    }

    /**
    |--------------------------------------------------
    | SUITE METHODS
    |--------------------------------------------------
    */

    // Don't need project, app and suite

    //    getSuitesData( suites ) {
    //         if( suites && suites.length ){
    //             return suites.map((suite) => ({
    //                 value: suite.testSuiteId,
    //                 label: suite.testSuiteName,
    //             }));
    //         }
    //         return []
    //     }

    fileUpload = async (/* selectedProject */) => {
        this.setStateIfComponentMounted({ isButtonDisabled: true, loader: true });
        const { toggleModal, uploadProgressFile, saveSessionId } = this.props;
        const { uploadedFiles, testDataChecked, artifactChecked, audioChecked } = this.state;
        const casefilesArray = [];
        let multipleTestCase = false;
        let multiTestCaseTestData = false;
        const datafilesArray = [];
        const artifactsfilesArray = [];
        const audiofilesArray = [];
        uploadedFiles.forEach((file) => {
            if (file.key === 'casefileId') {
                casefilesArray.push(file.casefile);
            }
            if (file.key === 'datafileId' && testDataChecked) {
                datafilesArray.push(file.datafile);
            }
            if (file.key === 'artifactsId' && artifactChecked) {
                artifactsfilesArray.push(file.artifacts);
            }
            if (file.key === 'audioId' && audioChecked) {
                audiofilesArray.push(file.audio);
            }
        });
        const ws = await WSService.getWebSocketInstance();
        const newSessionId = await WSService.getNewSessionId(WSService.getAccountId());
        const formData = new FormData();
        formData.append(
            'json',
            JSON.stringify({
                sessionId: newSessionId,
            }),
        );
        formData.append(
            'json',
            JSON.stringify({
                accountId: WSService.getAccountId(),
            }),
        );

        if (casefilesArray.length) {
            multipleTestCase = casefilesArray.length > 1;
            for (let i = 0; i < casefilesArray.length; i += 1) {
                formData.append('casefile', casefilesArray[i]);
            }
        }
        if (datafilesArray.length) {
            multiTestCaseTestData = casefilesArray.length + datafilesArray.length > 1;
            for (let i = 0; i < datafilesArray.length; i += 1) {
                formData.append('datafile', datafilesArray[0]);
            }
        }
        if (artifactsfilesArray.length) {
            for (let i = 0; i < artifactsfilesArray.length; i += 1) {
                formData.append('artifacts', artifactsfilesArray[i]);
            }
        }
        if (audiofilesArray.length) {
            for (let i = 0; i < audiofilesArray.length; i += 1) {
                formData.append('audio', audiofilesArray[i]);
            }
        }
        const response = await uploadProgressFile(uploadedFiles[0], formData, multipleTestCase);
        let succeedFilesArray = [];
        let failedFilesArray = [];
        let isResponded = false;
        let _testCaseId = null;
        if (checkKeyInObject(response, 'success') && checkArrayLength(response.success) && response.success[0].test_case_id) {
            _testCaseId = response.success[0].test_case_id;
        }
        if ((multipleTestCase || multiTestCaseTestData) && (checkKeyInObject(response, 'failed') || checkKeyInObject(response, 'success'))) {
            isResponded = true;
            if (checkArrayLength(response.failed)) {
                failedFilesArray = response.failed;
            }
            if (checkArrayLength(response.success)) {
                succeedFilesArray = response.success;
            }
        } else {
            toggleModal();
        }
        this.setStateIfComponentMounted({ loader: false, isButtonDisabled: false, isResponded, failedFilesArray, succeedFilesArray });
        if (_testCaseId && !multipleTestCase) {
            saveSessionId(newSessionId, `${_testCaseId}`, 'UploadWizard');
            this.props.history.push(`/details/${this.props.selectedProjectId}/${_testCaseId}`);

            const actions = {
                // use this object to pass any action to webSocket receiver
            };
            const data = {
                callingFrom: 'UploadWizard',
            };
            // Invoking web socket receiver
            TestCaseUtils.receiveWSEvents(ws, actions, data);
        }

        const eventName = SEGMENT_EVENT.CREATE_TEST_UPLOAD
        track(eventName,{});
    };

    handleCheck = (name) => {
        this.setStateIfComponentMounted({ [name]: !this.state[name] });
    };

    checkFileType = (type, step) => {
        const allowedFileTypes = [];
        if (step !== 'audio') {
            allowedFileTypes.push('application/vnd.openxmlformats-officedocument.spreadsheetml.sheet', 'application/vnd.ms-excel', 'text/csv');
        }
        return allowedFileTypes.indexOf(type) > -1;
    };

    checkFileExtension = (ext, step) => {
        const allowedFileExtensions = [];
        if (step === 'audio') {
            allowedFileExtensions.push('.wav');
        } else {
            allowedFileExtensions.push('.xls', '.xlsx', '.csv');
        }
        return allowedFileExtensions.indexOf(ext) > -1;
    };

    removeFile = (step, ind) => {
        const { uploadedFiles, currentFiles } = this.state;
        const currentfiles = currentFiles.filter((...index) => index[1] !== ind);
        let uploadedfiles = [...uploadedFiles];
        const i = uploadedfiles
            .map((file, index) => {
                if (file.key === `${step}Id`) return index;
                return undefined;
            })
            .filter((data) => data !== undefined);
        uploadedfiles = uploadedFiles.filter((...index) => index[1] !== i[ind]);
        this.setStateIfComponentMounted({
            currentFiles: currentfiles,
            uploadedFiles: uploadedfiles,
            currentFile: '',
            [step]: i.length > 1,
        });
    };

    removeIgnoredFile = (step, ind) => {
        const { ignoredFiles } = this.state;
        let ignoredfiles = [...ignoredFiles];
        const i = ignoredfiles
            .map((file, index) => {
                if (file.key === `${step}Id`) return index;
                return undefined;
            })
            .filter((data) => data !== undefined);
        ignoredfiles = ignoredFiles.filter((...index) => index[1] !== i[ind]);
        this.setStateIfComponentMounted({
            ignoredFiles: ignoredfiles,
        });
    };

    camelize = (value) => value.charAt(0).toUpperCase() + value.slice(1);

    showName = (step, file) => {
        if (file[step]) return file[step].name;
        if (file.file) return file.file.name;
        return file.name;
    };

    fileCard = (files, panelName, step) => {
        const { classes } = this.props;
        return (
            files &&
            files.map((file, index) => (
                <div
                    key={index}
                    className={classes.fileCard}
                    onMouseOver={() => {
                        if (panelName !== 'ignored') {
                            this.setStateIfComponentMounted({ currentFile: `${panelName}_${index}` });
                        }
                    }}
                    onMouseLeave={() => {
                        if (panelName !== 'ignored') {
                            this.setStateIfComponentMounted({ currentFile: '' });
                        }
                    }}
                >
                    <FontAwesomeIcon icon={faFolder} aria-label="faFolder" id="faFolder" className={classes.folderIcon} />
                    <Typography noWrap className={`${classes.text} ${classes.fullWidth}`}>
                        <Tooltip data={step && this.showName(step, file)}>{step && this.showName(step, file)}</Tooltip>
                    </Typography>
                    {panelName === 'ignored' ? (
                        <Tooltip data="Remove">
                            <Cancel
                                className={classes.cancelIcon}
                                onClick={() => {
                                    this.removeIgnoredFile(step, index);
                                }}
                                aria-label="cancelIcon"
                                id="cancelIcon"
                            />
                        </Tooltip>
                    ) : `${this.state.currentFile}` === `${panelName}_${index}` ? (
                        <Tooltip data="Remove">
                            <Cancel
                                className={classes.cancelIcon}
                                onClick={() => {
                                    this.removeFile(step, index);
                                }}
                                aria-label="cancelIcon"
                                id="cancelIcon"
                            />
                        </Tooltip>
                    ) : (
                        <CheckCircle className={classes.checkIcon} aria-label="checkCircleIcon" id="checkCircleIcon" />
                    )}
                </div>
            ))
        );
    };

    handleEscape = () => {
        this.props.toggleModal();
    };

    handleNext = () => {
        let activeStep;

        if (this.isLastStep() && !this.allStepsCompleted()) {
            const steps = this.getSteps;
            activeStep = steps.findIndex((step, i) => !(i in this.state.completed));
        } else {
            activeStep = this.state.activeStep + 1;
        }
        this.setStateIfComponentMounted({
            activeStep,
            currentFiles: [],
        });
    };

    handleSteps = (activeStep) => {
        this.setStateIfComponentMounted({ activeStep, autoNext: false });
    };

    totalSteps = () => this.getSteps.length;

    completedSteps() {
        return Object.keys(this.state.completed).length;
    }

    isLastStep() {
        return this.state.activeStep === this.totalSteps() - 1;
    }

    allStepsCompleted() {
        return this.completedSteps() === this.totalSteps();
    }

    handleKeyPress = (event, steps) => {
        if (event.key === 'Enter') {
            const { uploadedFiles, activeStep, isButtonDisabled } = this.state;
            if (!(isButtonDisabled || !uploadedFiles.filter((file) => file.key === 'casefileId').length)) {
                if (activeStep === steps.length - 1) {
                    this.fileUpload();
                } else {
                    this.handleNext();
                }
            }
        }
    };

    focusRefElement() {
        setTimeout(() => {
            if (this.refForEnterPress && this.refForEnterPress.current) {
                this.refForEnterPress.current.focus();
            }
        }, 200);
    }

    toggleResponseMode = () => {
        this.setStateIfComponentMounted({ isResponded: false });
    };

    render() {
        const { classes, open, toggleModal } = this.props;
        const steps = this.getSteps;
        const {
            activeStep,
            failedFilesArray,
            ignoredFiles,
            isResponded,
            succeedFilesArray,
            uploadedFiles,
            // currentFiles,
            loader,
        } = this.state;

        const caseUploadedFiles = uploadedFiles.filter((file) => file.key === 'casefileId');
        const caseIgnoredFiles = ignoredFiles.filter((file) => file.key === 'casefileId');
        const dataUploadedFiles = uploadedFiles.filter((file) => file.key === 'datafileId');
        const dataIgnoredFiles = ignoredFiles.filter((file) => file.key === 'datafileId');
        const audioIgnoredFiles = ignoredFiles.filter((file) => file.key === 'audioId');
        const artifactsUploadedFiles = uploadedFiles.filter((file) => file.key === 'artifactsId');
        const audioUploadedFiles = uploadedFiles.filter((file) => file.key === 'audioId');
        return (
            <div aria-hidden onKeyPress={(e) => this.handleKeyPress(e, steps)}>
                <Dialog
                    disableRestoreFocus
                    open={open}
                    aria-labelledby="form-dialog-title"
                    maxWidth="md"
                    style={{ paddingBottom: 7 }}
                    className={classes.dialogRoot}
                >
                    <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                        <DialogTitle id="form-dialog-title" classes={{ root: classes.dialogTitleStyle }}>
                            <p ref={this.refForEnterPress} style={{ margin: 0, display: 'inline-block' }}>
                                {isResponded ? 'Uploaded Files' : 'Upload Test Case'}
                            </p>
                            {activeStep === steps.length - 1 && !caseUploadedFiles.length ? (
                                <div className={classes.errorMsg} style={{ display: 'inline-block' }}>
                                    <span>Please upload file in Test Case!</span>
                                </div>
                            ) : null}
                        </DialogTitle>
                        <div className={classes.closeIconCont}>
                            <Close className={classes.closeIcon} onClick={() => toggleModal()} aria-label="closeIcon" id="closeIcon" />
                        </div>
                    </div>
                    <DialogContent className={[classes.mainDialog, 'scrollContainer'].join(' ')}>
                        <div className={classes.root}>
                            {isResponded ? (
                                <div className={classes.responseContainer}>
                                    {checkArrayLength(succeedFilesArray) && (
                                        <div className={classes.succeedFilesContainer}>
                                            <div className={classes.succeedFilesHead}>
                                                <Done style={{ marginRight: 5, color: 'green' }} aria-label="doneIcon" id="doneIcon" />
                                                <Typography style={{ fontSize: 20, color: 'green' }}>
                                                    {`${succeedFilesArray && succeedFilesArray.length} File(s) successfully uploaded`}
                                                </Typography>
                                            </div>
                                            <div className={[classes.succeedFilesBody, 'scrollContainer'].join(' ')}>
                                                {succeedFilesArray.map((succeedFile, index) => (
                                                    <div key={index} className={classes.succeedFileSection}>
                                                        <Typography style={{ width: 'max-content', maxWidth: '98%' }}>
                                                            <Tooltip data={succeedFile.name}> {succeedFile.name} </Tooltip>
                                                        </Typography>
                                                    </div>
                                                ))}
                                            </div>
                                        </div>
                                    )}
                                    {checkArrayLength(failedFilesArray) && (
                                        <div className={classes.failedFilesContainer}>
                                            <div className={classes.failedFilesHead}>
                                                <Clear style={{ marginRight: 5, color: 'red' }} aria-label="clearIcon" id="clearIcon" />
                                                <Typography style={{ fontSize: 20, color: 'red' }}>
                                                    {`${failedFilesArray && failedFilesArray.length} File(s) failed to upload`}
                                                </Typography>
                                            </div>
                                            <div className={[classes.failedFilesBody, 'scrollContainer'].join(' ')}>
                                                {failedFilesArray.map((failedFile, index) => (
                                                    <div key={index} className={classes.failedFileSection}>
                                                        <Typography noWrap style={{ maxWidth: failedFile.errMsg ? '25%' : '98%' }}>
                                                            <Tooltip data={failedFile.name}>{failedFile.name}</Tooltip>
                                                        </Typography>
                                                        {failedFile.errMsg && <span style={{ marginLeft: 10, marginRight: 10 }}>-</span>}
                                                        {failedFile.errMsg && (
                                                            <Typography noWrap style={{ maxWidth: '70%', color: 'red' }}>
                                                                <Tooltip data={failedFile.errMsg}>{failedFile.errMsg}</Tooltip>
                                                            </Typography>
                                                        )}
                                                    </div>
                                                ))}
                                            </div>
                                        </div>
                                    )}
                                </div>
                            ) : (
                                <div>
                                    {caseUploadedFiles.length < 2 ? (
                                        <div className={classes.stepper}>
                                            {steps.map((label, index) => (
                                                <div
                                                    aria-hidden
                                                    className={classes.stepContainer}
                                                    key={label}
                                                    onClick={() => this.handleSteps(index)}
                                                >
                                                    <span className={`${classes.stepVal} ${index === activeStep ? classes.activeStepVal : ''}`}>
                                                        {label}
                                                    </span>
                                                </div>
                                            ))}
                                        </div>
                                    ) : (
                                        <div className={classes.stepper}>
                                            <div className={classes.stepContainer}>
                                                <span className={`${classes.stepVal} ${classes.activeStepVal}`}>Test Case</span>
                                            </div>
                                        </div>
                                    )}
                                    <div className={classes.bodyContainer}>
                                        {activeStep === 0 ? (
                                            <div
                                                style={{
                                                    width: '100%',
                                                    display: 'flex',
                                                }}
                                            >
                                                <div
                                                    style={{
                                                        width: 'calc(50% - 15px)',
                                                        marginRight: 15,
                                                    }}
                                                >
                                                    <Filedrop
                                                        config={{
                                                            dropCallback: this.onFileDropCallback,
                                                            step: 'casefile',
                                                            text: 'Select xls, xlsx, or csv File',
                                                        }}
                                                        innerStyle={{
                                                            margin: '40px 0 25px 0',
                                                        }}
                                                    />
                                                    <div className={`${classes.countWrapper}`}>
                                                        <Typography className={classes.testCountStyle}>
                                                            {pluralize(caseUploadedFiles.length, 'Total Accepted Case')}:{' '}
                                                            {caseUploadedFiles && caseUploadedFiles.length}
                                                        </Typography>
                                                    </div>
                                                </div>
                                                <div
                                                    style={{
                                                        width: 'calc(50% - 15px)',
                                                        display: 'flex',
                                                        alignItems: 'center',
                                                        flexDirection:
                                                            !checkArrayLength(caseUploadedFiles) && !checkArrayLength(caseIgnoredFiles)
                                                                ? ''
                                                                : 'column',
                                                        justifyContent:
                                                            !checkArrayLength(caseUploadedFiles) && !checkArrayLength(caseIgnoredFiles)
                                                                ? 'center'
                                                                : '',
                                                        marginLeft: 15,
                                                        marginTop: 10,
                                                    }}
                                                >
                                                    {caseUploadedFiles.length > 0 && this.fileCard(caseUploadedFiles, 'accepted', 'casefile')}
                                                    {caseIgnoredFiles.length > 0 && this.fileCard(caseIgnoredFiles, 'ignored', 'casefile')}
                                                    {!checkArrayLength(caseUploadedFiles) && !checkArrayLength(caseIgnoredFiles) && (
                                                        <div>
                                                            <Typography className={`${classes.text} ${classes.textCenter}`}>
                                                                No files are selected!
                                                            </Typography>
                                                        </div>
                                                    )}
                                                </div>
                                            </div>
                                        ) : null}
                                        {activeStep === 1 ? (
                                            <div style={{ width: '100%' }}>
                                                {this.state.testDataChecked ? (
                                                    <div
                                                        style={{
                                                            width: '100%',
                                                            display: 'flex',
                                                        }}
                                                    >
                                                        <div
                                                            style={{
                                                                width: 'calc(50% - 15px)',
                                                                marginRight: 15,
                                                            }}
                                                        >
                                                            <Filedrop
                                                                config={{
                                                                    dropCallback: this.onFileDropCallback,
                                                                    disabled: checkArrayLength(dataUploadedFiles),
                                                                    step: 'datafile',
                                                                    text: 'Select xls, xlsx, or csv File',
                                                                }}
                                                                innerStyle={{
                                                                    margin: '40px 0 25px 0',
                                                                }}
                                                            />
                                                            <div className={classes.countWrapper}>
                                                                <Typography className={classes.testCountStyle}>
                                                                    Total Accepted Test Data : {dataUploadedFiles && dataUploadedFiles.length}
                                                                </Typography>
                                                            </div>
                                                        </div>
                                                        <div
                                                            style={{
                                                                width: 'calc(50% - 15px)',
                                                                display: 'flex',
                                                                alignItems: 'center',
                                                                flexDirection:
                                                                    !checkArrayLength(dataUploadedFiles) && !checkArrayLength(dataIgnoredFiles)
                                                                        ? ''
                                                                        : 'column',
                                                                justifyContent:
                                                                    !checkArrayLength(dataUploadedFiles) && !checkArrayLength(dataIgnoredFiles)
                                                                        ? 'center'
                                                                        : '',
                                                                marginLeft: 15,
                                                                marginTop: 10,
                                                            }}
                                                        >
                                                            {dataUploadedFiles.length > 0 && this.fileCard(dataUploadedFiles, 'accepted', 'datafile')}
                                                            {dataIgnoredFiles.length > 0 && this.fileCard(dataIgnoredFiles, 'ignored', 'datafile')}
                                                            {!checkArrayLength(dataUploadedFiles) && !checkArrayLength(dataIgnoredFiles) && (
                                                                <div>
                                                                    <Typography className={`${classes.text} ${classes.textCenter}`}>
                                                                        No files are selected!
                                                                    </Typography>
                                                                </div>
                                                            )}
                                                        </div>
                                                    </div>
                                                ) : null}
                                                <div
                                                    style={{
                                                        width: '100%',
                                                        display: 'flex',
                                                    }}
                                                >
                                                    <div
                                                        style={{
                                                            width: 'calc(50% - 15px)',
                                                            marginRight: 15,
                                                        }}
                                                    ></div>
                                                    <div
                                                        style={{
                                                            width: 'calc(50% - 15px)',
                                                            display: 'flex',
                                                            alignItems: 'center',
                                                            marginLeft: 15,
                                                        }}
                                                    >
                                                        <div style={{ display: 'flex' }}>
                                                            <span
                                                                aria-hidden
                                                                onClick={() => {
                                                                    this.handleCheck('testDataChecked');
                                                                }}
                                                            >
                                                                {this.state.testDataChecked ? (
                                                                    <CheckBox
                                                                        className={classes.checkBox}
                                                                        aria-label="checkBoxIcon"
                                                                        id="checkBoxIcon"
                                                                    />
                                                                ) : (
                                                                    <CheckBoxBlank
                                                                        className={classes.checkBox}
                                                                        aria-label="checkBoxBlankIcon"
                                                                        id="checkBoxBlankIcon"
                                                                    />
                                                                )}
                                                            </span>
                                                            <Typography className={classes.text}>
                                                                Attach test data to the uploaded test case file (optional)
                                                            </Typography>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        ) : null}
                                        {activeStep === 2 ? (
                                            <div style={{ width: '100%' }}>
                                                {this.state.artifactChecked ? (
                                                    <div
                                                        style={{
                                                            width: '100%',
                                                            display: 'flex',
                                                        }}
                                                    >
                                                        <div
                                                            style={{
                                                                width: 'calc(50% - 15px)',
                                                                marginRight: 15,
                                                            }}
                                                        >
                                                            <Filedrop
                                                                config={{
                                                                    dropCallback: this.onFileDropCallback,
                                                                    step: 'artifacts',
                                                                    text: 'Select Any File',
                                                                }}
                                                                innerStyle={{
                                                                    margin: '40px 0 25px 0',
                                                                }}
                                                            />
                                                            <div className={classes.countWrapper}>
                                                                <Typography className={classes.testCountStyle}>
                                                                    Total Artifacts: {artifactsUploadedFiles.length}
                                                                </Typography>
                                                            </div>
                                                        </div>
                                                        <div
                                                            style={{
                                                                width: 'calc(50% - 15px)',
                                                                display: 'flex',
                                                                alignItems: 'center',
                                                                flexDirection: !checkArrayLength(artifactsUploadedFiles) ? '' : 'column',
                                                                justifyContent: !checkArrayLength(artifactsUploadedFiles) ? 'center' : '',
                                                                marginLeft: 15,
                                                                marginTop: 10,
                                                            }}
                                                        >
                                                            {artifactsUploadedFiles.length > 0 &&
                                                                this.fileCard(artifactsUploadedFiles, 'accepted', 'artifacts')}
                                                            {!checkArrayLength(artifactsUploadedFiles) && (
                                                                <div>
                                                                    <Typography className={`${classes.text} ${classes.textCenter}`}>
                                                                        No files are selected!
                                                                    </Typography>
                                                                </div>
                                                            )}
                                                        </div>
                                                    </div>
                                                ) : null}
                                                <div
                                                    style={{
                                                        width: '100%',
                                                        display: 'flex',
                                                    }}
                                                >
                                                    <div
                                                        style={{
                                                            width: 'calc(50% - 15px)',
                                                            marginRight: 15,
                                                        }}
                                                    ></div>
                                                    <div
                                                        style={{
                                                            width: 'calc(50% - 15px)',
                                                            display: 'flex',
                                                            alignItems: 'center',
                                                            marginLeft: 15,
                                                        }}
                                                    >
                                                        <div style={{ display: 'flex' }}>
                                                            <span
                                                                aria-hidden
                                                                onClick={() => {
                                                                    this.handleCheck('artifactChecked');
                                                                }}
                                                            >
                                                                {this.state.artifactChecked ? (
                                                                    <CheckBox
                                                                        className={classes.checkBox}
                                                                        aria-label="checkBoxIcon"
                                                                        id="checkBoxIcon"
                                                                    />
                                                                ) : (
                                                                    <CheckBoxBlank
                                                                        className={classes.checkBox}
                                                                        aria-label="checkBoxBlankIcon"
                                                                        id="checkBoxBlankIcon"
                                                                    />
                                                                )}
                                                            </span>
                                                            <Typography className={classes.text}>Upload Artifacts (optional)</Typography>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        ) : null}
                                        {activeStep === 3 ? (
                                            <div style={{ width: '100%' }}>
                                                {this.state.audioChecked ? (
                                                    <div
                                                        style={{
                                                            width: '100%',
                                                            display: 'flex',
                                                        }}
                                                    >
                                                        <div
                                                            style={{
                                                                width: 'calc(50% - 15px)',
                                                                marginRight: 15,
                                                            }}
                                                        >
                                                            <Filedrop
                                                                config={{
                                                                    dropCallback: this.onFileDropCallback,
                                                                    disabled: checkArrayLength(audioUploadedFiles),
                                                                    step: 'audio',
                                                                    text: 'Select any wav File',
                                                                }}
                                                                innerStyle={{
                                                                    margin: '40px 0 25px 0',
                                                                }}
                                                            />
                                                            <div className={classes.countWrapper}>
                                                                <Typography className={classes.testCountStyle}>
                                                                    Total accepted audio: {audioUploadedFiles.length}
                                                                </Typography>
                                                            </div>
                                                        </div>
                                                        <div
                                                            style={{
                                                                width: 'calc(50% - 15px)',
                                                                display: 'flex',
                                                                alignItems: 'center',
                                                                flexDirection:
                                                                    !checkArrayLength(audioUploadedFiles) && !checkArrayLength(audioIgnoredFiles)
                                                                        ? ''
                                                                        : 'column',
                                                                justifyContent:
                                                                    !checkArrayLength(audioUploadedFiles) && !checkArrayLength(audioIgnoredFiles)
                                                                        ? 'center'
                                                                        : '',
                                                                marginLeft: 15,
                                                                marginTop: 10,
                                                            }}
                                                        >
                                                            {audioUploadedFiles.length > 0 && this.fileCard(audioUploadedFiles, 'accepted', 'audio')}
                                                            {audioIgnoredFiles.length > 0 && this.fileCard(audioIgnoredFiles, 'ignored', 'audio')}
                                                            {!checkArrayLength(audioUploadedFiles) && !checkArrayLength(audioIgnoredFiles) && (
                                                                <div>
                                                                    <Typography className={`${classes.text} ${classes.textCenter}`}>
                                                                        No files are selected!
                                                                    </Typography>
                                                                </div>
                                                            )}
                                                        </div>
                                                    </div>
                                                ) : null}
                                                <div
                                                    style={{
                                                        width: '100%',
                                                        display: 'flex',
                                                    }}
                                                >
                                                    <div
                                                        style={{
                                                            width: 'calc(50% - 15px)',
                                                            marginRight: 15,
                                                        }}
                                                    ></div>
                                                    <div
                                                        style={{
                                                            width: 'calc(50% - 15px)',
                                                            display: 'flex',
                                                            alignItems: 'center',
                                                            marginLeft: 15,
                                                        }}
                                                    >
                                                        <div style={{ display: 'flex' }}>
                                                            <span
                                                                aria-hidden
                                                                onClick={() => {
                                                                    this.handleCheck('audioChecked');
                                                                }}
                                                            >
                                                                {this.state.audioChecked ? (
                                                                    <CheckBox
                                                                        className={classes.checkBox}
                                                                        aria-label="checkBoxIcon"
                                                                        id="checkBoxIcon"
                                                                    />
                                                                ) : (
                                                                    <CheckBoxBlank
                                                                        className={classes.checkBox}
                                                                        aria-label="checkBoxBlankIcon"
                                                                        id="checkBoxBlankIcon"
                                                                    />
                                                                )}
                                                            </span>
                                                            <Typography className={classes.text}>Upload Audio (optional)</Typography>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        ) : null}
                                    </div>
                                </div>
                            )}
                        </div>
                    </DialogContent>
                    <DialogActions
                        style={{ display: 'flex', justifyContent: 'flex-start', margin: '10px 0 0 0', padding: '30px', paddingTop: '0px' }}
                    >
                        {!isResponded && (
                            <Button
                                variant="contained"
                                onClick={() =>
                                    activeStep === steps.length - 1
                                        ? this.fileUpload()
                                        : caseUploadedFiles && caseUploadedFiles.length < 2
                                        ? this.handleNext()
                                        : this.fileUpload()
                                }
                                className={classes.buttonStyle}
                                color="secondary"
                                disabled={!isResponded && (this.state.isButtonDisabled || !caseUploadedFiles.length)}
                            >
                                {activeStep === steps.length - 1 ? 'Submit' : caseUploadedFiles && caseUploadedFiles.length < 2 ? 'Next' : 'Submit'}
                            </Button>
                        )}
                        <CloseOnEscape onEscape={this.handleEscape}>
                            {(activeStep === 0 || isResponded) && (
                                <Button variant="outlined" onClick={() => toggleModal()} className={classes.cancelButton}>
                                    {isResponded ? 'Close' : 'Cancel'}
                                </Button>
                            )}
                        </CloseOnEscape>
                        {activeStep !== 0 && !isResponded && (
                            <Button
                                variant="outlined"
                                color="secondary"
                                onClick={() => this.handleSteps(activeStep - 1)}
                                className={classes.cancelButton}
                            >
                                Back
                            </Button>
                        )}
                    </DialogActions>
                    {loader ? <Loader loadingText="" /> : null}
                </Dialog>
            </div>
        );
    }
}

UploadWizard.propTypes = {
    classes: PropTypes.shape({}).isRequired,
    //     open: PropTypes.bool.isRequired,
    //     selectedProjectId: PropTypes.string,
    //     uploadProgressStore: PropTypes.shape({}).isRequired,
    //     toggleModal: PropTypes.func.isRequired,
    //     showSnackBar: PropTypes.func.isRequired,
};

UploadWizard.defaultProps = {
    //     selectedProjectId: '',
};

const mapStateToProps = (state) => {
    return {
        isLoading: state.projectReducer.isLoading,
        projects: state.projectReducer.projects,
        suites: state.projectReducer.testSuites,
        user: state.authReducer.user,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        uploadProgressFile: (...args) => dispatch(UploadProgressActions.uploadProgressFile(...args)),
        getSuite: (project, accountId) => dispatch(TestSuiteActions.getTestSuites(project, accountId)),
        updateTestCase: (...args) => dispatch(ProjectActions.updateTestCaseSteps(...args)),
        updateTestCaseStatus: (testCaseId, caseData) => dispatch(ProjectActions.updateTestCaseStepsStatus(testCaseId, caseData)),
        toggleWsRunning: (testCaseId) => dispatch(ProjectActions.toggleWsRunning(testCaseId)),
        getAllNotification: (accountId) => dispatch(ProjectActions.getNonDeletedNotification(accountId)),
        getSingleTestCase: (...args) => dispatch(ProjectActions.getSingleTestCase(...args)),
        getSingleTestData: (testDataId) => dispatch(ProjectActions.getSingleTestData(testDataId)),
        setWsStatus: (data) => dispatch(ProjectActions.setWsStatus(data)),
        saveSessionId: (...args) => dispatch(ProjectActions.saveSessionId(...args)),
    };
};
export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(UploadWizard));
