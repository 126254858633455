// materials
import { withStyles } from '@material-ui/core/styles';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import CheckBox from '@material-ui/core/Checkbox';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';

// packages
import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import _ from 'lodash';
import { DIALOG_ROOT, MODAL_FOOTER } from '../../common/cssConstants';
import { DialogTop } from '../../components/Modal/ModalTop';
import GeneralModalFooter from '../modal/GeneralModal/GeneralModalFooter';
import { CANCEL_BUTTON, NEXT_SUBMIT_BUTTON } from '../../components/Button/Btn';
import { checkArrayLength } from '../../utils/utils';
import { DashboardActions } from '../../store/actions';
import { CustomSwitch } from '../../common/miniComponents';

// global variables/constants
const styles = (theme) => ({
    dialogRoot: DIALOG_ROOT,
    root: {
        width: '100%',
        maxHeight: 'calc(100vh - 270px)',
        overflowY: 'auto',
        overflowX: 'hidden',
        [theme.breakpoints.only('xs')]: {
            maxHeight: 'calc(100vh - 250px)',
        },
    },
    checkBoxButton: {
        '&>span>svg': {
            fontSize: 20,
        },
    },
    dialogContent: {
        width: 600,
        paddingLeft: 30,
    },
    wrapperSwitchContainer: {
        backgroundColor: '#F3F3F3',
        marginLeft: -30,
        marginRight: -24,
    },
    wrapperSwitch: {
        marginTop: 15,
        marginBottom: 15,
    },
    configurationText: {
        fontWeight: 500,
        marginRight: 25,
        marginLeft: 30,
    },
    wrapperCheckBoxRoot: {
        width: '100%',
    },
    chooseText: {
        fontWeight: 500,
    },
    wrapperCheckBoxContainer: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        flex: 1,
        flexWrap: 'wrap',
    },
    wrapperCheckBox: {
        display: 'flex',
        flexDirection: 'row',
        marginTop: 10,
        flexBasis: '33.333333%',
    },
    label: {
        marginLeft: 10,
        display: 'inline-block',
    },
    horizontalLine: {
        backgroundColor: '#F3F3F3',
        marginTop: 20,
        height: 1,
        marginBottom: 20,
        marginLeft: -30,
        marginRight: -24,
    },
    timeSettingText: {
        display: 'block',
        marginBottom: 15,
    },
    durationText: {
        fontWeight: 500,
    },
    radioGroup: {
        width: '70%',
        justifyContent: 'space-between',
        marginBottom: -15,
    },
});

class DashboardSettingsModal extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            getCloneRowData: JSON.parse(JSON.stringify(props.headers)),
            timeSpan: localStorage.getItem('StickyHeader') ? localStorage.getItem('StickyHeader') : 'Week',
        };
    }

    componentDidMount() {
        const { getCloneRowData } = this.state;
        const _getCloneRowData = window.screen.availWidth < 600 ? getCloneRowData.filter((c) => c.allowedForXS) : getCloneRowData;
        this.updateState({
            getCloneRowData: _getCloneRowData,
        });
    }

    onTimeSpanChanged = (timeSpan) => {
        this.setState({ timeSpan });
    };

    statusChange = (status, index) => {
        const updatedState = JSON.parse(JSON.stringify(this.state.getCloneRowData));
        updatedState[index].status = status === 'Active' ? 'InActive' : 'Active';
        this.setState({ getCloneRowData: updatedState });
    };

    saveHeaders = () => {
        const { timeSpan, getCloneRowData } = this.state;
        if (!_.isEqual(this.props.headers, getCloneRowData)) {
            this.props.changeHeaderStatus(getCloneRowData);
        }
        localStorage.setItem('StickyHeader', timeSpan);
        this.props.dashboardTickerTimeIndicator(timeSpan);
        this.props.handleClose(true);
    };

    updateState = (obj) => {
        this.setState(obj);
    };

    render() {
        const { classes, handleClose, open } = this.props;
        const { getCloneRowData } = this.state;
        const FilteredRowData = getCloneRowData.slice(0, getCloneRowData.length - 1);
        const configurationStatus = getCloneRowData[getCloneRowData.length - 1];

        return (
            <div className={classes.root}>
                <Dialog disableRestoreFocus open={open} aria-labelledby="form-dialog-title" maxWidth="md" className={classes.dialogRoot}>
                    <DialogTop title="Dashboard Management" closeIconAction={() => handleClose(false)} />
                    <DialogContent className={classes.dialogContent}>
                        <span>Configuration</span>

                        <div className={classes.wrapperSwitchContainer}>
                            <div className={classes.wrapperSwitch}>
                                <span className={classes.configurationText}>Dashboard Configuration</span>
                                <CustomSwitch
                                    checked={configurationStatus.status === 'Active'}
                                    onClick={() => this.statusChange(configurationStatus.status, getCloneRowData.length - 1)}
                                    inputProps={{ 'aria-label': 'secondary checkbox' }}
                                    w={30}
                                />
                            </div>
                        </div>
                        <div className={classes.wrapperCheckBoxRoot}>
                            <span className={classes.chooseText}>Choose a widget</span>
                            <div className={classes.wrapperCheckBoxContainer}>
                                {checkArrayLength(FilteredRowData) &&
                                    FilteredRowData.map((item, index) => {
                                        return (
                                            <div className={classes.wrapperCheckBox} key={index}>
                                                <CheckBox
                                                    className={classes.checkBoxButton}
                                                    checked={item.status === 'Active'}
                                                    onClick={() => this.statusChange(item.status, index)}
                                                    disabled={configurationStatus.status === 'InActive'}
                                                    style={{
                                                        height: '20px',
                                                        width: '20px',
                                                    }}
                                                />
                                                <span className={classes.label}>{item.label}</span>
                                            </div>
                                        );
                                    })}
                            </div>
                        </div>
                        <div className={classes.horizontalLine} />
                        <div>
                            <span className={classes.timeSettingText}>Time Setting</span>
                            <span className={classes.durationText}>Choose time duration.</span>
                            <div>
                                <RadioGroup row defaultValue="Week" className={classes.radioGroup}>
                                    <FormControlLabel
                                        value="Week"
                                        control={
                                            <Radio
                                                color="secondary"
                                                checked={this.state.timeSpan === 'Week'}
                                                name="timeSpan"
                                                onChange={() => this.onTimeSpanChanged('Week')}
                                            />
                                        }
                                        label="Week"
                                    />
                                    <FormControlLabel
                                        value="Month"
                                        control={
                                            <Radio
                                                color="secondary"
                                                checked={this.state.timeSpan === 'Month'}
                                                name="timeSpan"
                                                onChange={() => this.onTimeSpanChanged('Month')}
                                            />
                                        }
                                        label="Month"
                                    />
                                    <FormControlLabel
                                        value="Year"
                                        control={
                                            <Radio
                                                color="secondary"
                                                checked={this.state.timeSpan === 'Year'}
                                                name="timeSpan"
                                                onChange={() => this.onTimeSpanChanged('Year')}
                                            />
                                        }
                                        label="Year"
                                    />
                                </RadioGroup>
                            </div>
                        </div>
                    </DialogContent>
                    <DialogActions style={MODAL_FOOTER}>
                        <GeneralModalFooter
                            buttons={[
                                NEXT_SUBMIT_BUTTON({
                                    name: 'Save',
                                    action: () => {
                                        this.saveHeaders();
                                    },
                                }),
                                CANCEL_BUTTON({
                                    name: 'Cancel',
                                    action: () => {
                                        handleClose(false);
                                    },
                                }),
                            ]}
                        />
                    </DialogActions>
                </Dialog>
            </div>
        );
    }
}

DashboardSettingsModal.propTypes = {
    classes: PropTypes.shape({}).isRequired,
    headers: PropTypes.instanceOf(Array).isRequired,
    open: PropTypes.bool.isRequired,
    // functions
    handleClose: PropTypes.func.isRequired,
    changeHeaderStatus: PropTypes.func.isRequired,
};

const mapDispatchToProps = (dispatch) => {
    return {
        dashboardTickerTimeIndicator: (...args) => dispatch(DashboardActions.dashboardTickerTimeIndicator(...args)),
    };
};

export default connect(
    null,
    /* mapStateToProps, */
    mapDispatchToProps,
)(withStyles(styles)(DashboardSettingsModal));
