/* eslint-disable no-nested-ternary */
import { withStyles } from '@material-ui/core/styles';
import Hidden from '@material-ui/core/Hidden';
import PropTypes from 'prop-types';
import React from 'react';
import Delete from '@material-ui/icons/Delete';
import Download from 'mdi-material-ui/Download';
import FileDocument from 'mdi-material-ui/FileDocument';
import FilePowerpoint from 'mdi-material-ui/FilePowerpoint';
import { faFileAudio } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { CircularProgress, IconButton } from '@material-ui/core';
import { connect } from 'react-redux';
import Table from '../../../components/Table/Table';
import TableBody from '../../../components/Table/TableBody';
import TableCell from '../../../components/Table/TableCell';
import TableRow from '../../../components/Table/TableRow';
import { Link } from 'react-router-dom';

// custom components
import ProjectDate from '../../../components/ProjectHeader/ProjectDate';
import ProjectTime from '../../../components/ProjectHeader/ProjectTime';

//import { EnhancedTableHead } from '../../../components/EnhancedTable';
import EnhancedTableHead from '../../../components/EnhancedTable/EnhancedTableHead_v2';
import {
    checkArrayLength,
    checkKeyInObject,
    sort,
    getFormatedDate,
    checkObject,
    resizeAllCols,
    getRelativeTime,
    getParamValues,
    capitalizeString,
} from '../../../utils/utils';
import NoDataToShow from '../../../components/NoDataToShow';
import ImgSrc from '../../../components/NoDataToShow/assests/database.svg';
import { MUI_ACTION_MENU_ICON, THREE_DOT_MENU_CSS, TABLES_CSS, TITLE } from '../../../common/cssConstants';
import DeleteAlertModal from '../../modal/Delete/DeleteAlertModal';
import Tooltip from '../../../components/Tooltip';
import singleFileCreationService from '../../../services/singleFileCreationService';
import TableFooter from '../../../common/TableFooter';
import { DATA_FILE_TYPE } from '../../../common/constants';
import { ModalActions } from '../../../store/actions';

// TODO: from theme!!
const styles = (theme) => ({
    avatar: {
        minWidth: '20px',
        height: '20px',
        borderRadius: '20px',
        backgroundColor: '#fff',
        color: '#3768b3',
        textAlign: 'center',
        fontWeight: 500,
        fontSize: '13px',
        lineHeight: 1.7,
        padding: '0 4px',
        display: 'inline-block',
        overflow: 'hidden',
        boxSizing: 'border-box',
        marginTop: '-4px',
        marginRight: '12px',
        border: '0.01em solid #979797',
    },
    cellText: {
        // TODO: calc this to adjust for task panel closed
        maxWidth: '200px',
    },
    downloadAtrifactStyle: {
        marginRight: 8,
        fontSize: 18,
    },
    moreIcons: MUI_ACTION_MENU_ICON,
    moreIconsBtnDisabled: {
        color: THREE_DOT_MENU_CSS.iconDisableColor,
    },
    audioIcons: {
        color: '#5F7B96',
        fontSize: '20px',
        marginLeft: '3px',
        marginRight: '5px',
    },
    progress: {
        margin: theme.spacing(2),
    },
    root: {
        width: '100%',
    },
    textField: {
        marginLeft: theme.spacing(1),
        marginRight: theme.spacing(1),
        maxWidth: 550,
        width: '100%',
    },
    ToolboxBtn: {
        marginLeft: 10,
    },
    eyeIconBtn: {
        color: 'rgb(96, 148, 255)',
        fontSize: '1.25rem',
        width: '36px !important',
        height: '36px !important',
    },
    shareButton: {
        color: 'rgb(240, 182, 55)',
    },
    borders: {
        backgroundColor: 'rgba(0, 0, 0, 0.04)',
    },
    button: {
        width: 22,
        height: 22,
        '&:hover': {
            backgroundColor: 'rgba(0, 0, 0, 0.08)',
            borderRadius: 100,
            cursor: 'pointer',
        },
    },
    buttoneye: {
        marginLeft: 10,
        marginTop: 2,
        width: '32px !important',
        height: 32,
        padding: 5,
        '&:hover': {
            backgroundColor: 'rgba(0, 0, 0, 0.08)',
            borderRadius: 100,
            cursor: 'pointer',
        },
    },
    buttoneyeDisabled: {
        marginLeft: 10,
        marginTop: 2,
        width: '32px !important',
        height: 32,
        padding: 5,
        color: 'gray',
    },
    NoPadding: {
        paddingRight: '5px !important',
    },
    noPaddingBoth: {
        paddingRight: '0px !important',
        paddingLeft: '0px !important',
    },
    actionButtonsDiv: {
        width: '30px',
        height: '22px',
    },
    iconButton: {
        height: 22,
        width: 22,
    },
    actionButtonsWidth: {
        width: '30px',
        height: '30px',
    },
    actionButtonsTableCell: {
        paddingLeft: '16px',
    },
    noWrap: {
        overflow: 'hidden',
        whiteSpace: 'nowrap',
        textOverflow: 'ellipsis',
        maxWidth: '100%',
    },
    cell: {
        textAlign: 'left',
        flexDirection: 'row',
        padding: 0,
        width: '20%',
        '&>:first-child': {
            paddingLeft: '20px !important',
        },
    },
    minWidth: {
        minWidth: '60px !important',
        [theme.breakpoints.only('xs')]: {
            minWidth: '200px !important',
            maxWidth: '200px !important',
        },
    },
    minHeight: {
        minHeight: 'calc(100vh - 279px)',
    },
    minHeight264: {
        minHeight: 'calc(100vh - 264px)',
    },
    tableContainer: {
        backgroundColor: '#fff',
        boxShadow: '0px 1px 3px 0px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 2px 1px -1px rgba(0, 0, 0, 0.12)',
        borderBottomLeftRadius: '5px',
        borderBottomRightRadius: '5px',
        overflow: 'hidden',
    },
    uploadedTime: {
        fontSize: 12,
        display: 'flex',
    },
    checkBox: {
        color: '#b8c4cf',
    },
    checkBoxChecked: {
        color: '#3B91DF !important',
    },
    bitmapImage:{
        width: '17px',
        height: '18px',
        margin: '0.5px 6px 0px 0',
    },
    moreIconsBtn: {
        color: THREE_DOT_MENU_CSS.iconColor,
        fontSize: 22,
    },
    btnColor: {
        color: '#ff9700',
    },
    disableImg:{
        display:'none',
    },
    hover: {
        minHeight: '62px', 
        backgroundColor: TABLES_CSS.rowColor,
        '&:hover': {
            fontWeight: 'bold',
            backgroundColor: TABLES_CSS.focusRowColor,
        },
    },
    previewColor:{
        color: '#3768b3',
    },
    boldRowData: {
        fontWeight: 'bold',
    },
    tableBody: {
        '& .TableRow': {
            '& .TableCell': {
                fontSize: 12,
            },
        },
    },
    tableRow: {
        backgroundColor: '#f5f5f5',
        borderTop: 'solid 0.5px rgb(151 151 151 / 69%)',
        height: '40px',
        '&:hover': {
            '& .TableCell': {
              //  fontWeight: 'bold',
                backgroundColor: '#fff',
                '& .IconCell': {
                    // '& .iconContainer': {
                        // '& .IconButton': {
                            backgroundColor: 'red'
                        // }
                    // }
                },
            },
            backgroundColor: '#fff',
        },
    },
    focused: {
        background: '#fff',
        '& .TableCell': {
           // fontWeight: 'bold',
        },
    },
});

// Table Header Array
const headers = [
    { id: 'attachData',width: '40px',tableCellStyles: { minWidth: 'unset' }, },
    { id: 'testDataFileName', sortable: true, numeric: false, disablePadding: false, label: 'DATA', tableCellStyles: { color : '#3768b3' }, styles: { paddingLeft: 10 }, width: '20%' },
    {
        id: 'generatedAt',
        sortable: true,
        numeric: false,
        disablePadding: false,
        label: 'LAST USED',
        styles: { paddingLeft: 10 },
        width: 'calc(70% / 4)',
    },
    {
        id: 'uploadedTime',
        sortable: true,
        numeric: false,
        disablePadding: false,
        label: 'UPLOADED',
        styles: { paddingLeft: 10 },
        width: 'calc(70% / 4)',
    },
    { id: 'associated', sortable: false, tooltipAllowed:true, numeric: false, disablePadding: false, label: 'ASSOCIATED TEST CASES', tableCellStyles: { color : '#3768b3' }, styles: { paddingLeft: 10 }, width: '20%' },
   // { id: 'clone', sortable: false, numeric: false, disablePadding: false, label: 'CLONE', styles: { paddingLeft: 10 }, color: 'rgba(56, 55, 55, 0.58)', width: '10%)' },
    { id: 'delete', sortable: false, numeric: false, disablePadding: false, label: 'DELETE', styles: { paddingLeft: 10 }, color: 'rgba(56, 55, 55, 0.58)', width: '13%' },
    { id: 'download', sortable: false, numeric: false, disablePadding: false, label: 'DOWNLOAD', styles: { paddingLeft: 10 }, color: 'rgba(56, 55, 55, 0.58)', width: '10%' },
];
class Datalist extends React.Component {
    constructor(props, context) {
        super(props, context);
        this.colsWidth = {};
        this.state = {
            order: 'asc',
            orderBy: 'testDataFileName',
            testData: props.testData,
            anchorEl: null,
            index: -1,
            page: 0,
            rowsPerPage: 20,
            deleteDataRow: false,
            deleteTestData: null,
            showMultilineTestCaseName: {},
            hoverKey:'',
            isHovered: '',
            selectArr: [],
            selectedAll: false,
        };
    }

    UNSAFE_componentWillMount() {
        this.showModal = true;
        this.handleRequestSort('generatedAt', this.props.testData);
    }

    /* Component life cycle start */
    componentDidMount() {
        const caseSettingRows = localStorage.getItem('rowsCasePerPage');
        if (caseSettingRows) {
            this.updateState({
                rowsPerPage: parseInt(caseSettingRows, 10),
            });
        }
    }

    UNSAFE_componentWillReceiveProps(nextProps) {
        const { testData, previewTestData, location } = nextProps;
        const { pageNo } = this.state
        let prevPageNo = parseInt(pageNo || 0)
        if(!nextProps.query) {
            this.setState({
                page : prevPageNo
            })
        }
        this.handleRequestSort(this.state.orderBy, testData, true);
        const params = getParamValues(-1, location);
        if (params[3] && params[4] && checkArrayLength(testData) && this.showModal) {
            const testDataId = params[3];
            const testDataIndex = testData.findIndex((data) => `${data.testDataId}` === `${testDataId}`);
            if (testDataIndex !== -1 && testData[testDataIndex]) {
                const data = testData[testDataIndex];
                this.showModal = false;
                switch (params[4]) {
                    case 'associatedTestCaseModal':
                        this.handleShare(testDataId,'associatedTestCaseModal');
                        break;
                    case 'preview':
                        previewTestData(testDataId, data.testDataFileName);
                        break;
                    case 'associatedTestCaseModalView':
                        this.handleShare(testDataId,'associatedTestCaseModalView');
                        break;
                    default:
                        this.replaceURL();
                }
            } else {
                this.replaceURL();
            }
        }
    }

    componentDidUpdate() {
        resizeAllCols(this.colsWidth);
    }

    componentWillUnmount() {
        this.props.queryRemove();
    }

    /* Component life cycle end */

    replaceURL = (testDataId, modalName) => {
        if (testDataId && modalName) {
            this.showModal = true;
            this.props.history.replace(`/details/${getParamValues(1)}/data/${testDataId}/${modalName}`);
        } else {
            this.props.history.replace(`/details/${getParamValues(1)}/data`);
        }
    };

    updateState = (obj) => {
        this.setState(obj);
    };

    handleChangePage = (page) => {
        this.setState({ page });
        if(!this.props.query) {
            this.setState({
                pageNo:page
            })
        }
    };

    handleChangeRowsPerPage = (event) => {
        localStorage.setItem('rowsCasePerPage', event.target.value);
        this.setState({ rowsPerPage: event.target.value });
    };

    handleRequestSort = (property, testData = this.state.testData, forceSameOrder = false) => {
        const orderBy = property;
        let order = 'desc';
        if (forceSameOrder) {
            order = this.state.order;
        } else if (this.state.orderBy === property && this.state.order === 'desc') {
            order = 'asc';
        }
        this.setState({ testData, order, orderBy });
    };


    onSelectAllClick = (e, key, con = true) => {
        const { selectArr } = this.state;
        const updated = e.target.checked ? [...selectArr, key] : selectArr.filter((val) => val !== key);
        this.setState({ selectArr: updated, selectedAll: con });
    };

    handleDelete = (projectId, testDataSystemId) => {
        // event.stopPropagation();
        if (this.props.handleDeleteTestData(testDataSystemId, projectId)) {
            const newTestData = this.state.testData.filter((testDatum) => testDataSystemId !== testDatum.systemId);
            this.setState({ testData: newTestData });
        }
    };

    hadleCloseDeleteAlertModal = (isConfirm) => {
        if (isConfirm) {
            const { deleteTestData } = this.state;
            const { projectId } = this.props;
            this.handleDelete(projectId, deleteTestData.testDataId);
        }
        this.setState({ deleteDataRow: false, deleteTestData: null });
    };

    // handleVisibility = (event) =>{}
    handleShare = (testDataSystemId,modalType) => {
        this.props.toggleModal(`${testDataSystemId}`,modalType);
    };

    handleMenuClick = (event, i) => {
        // 'i' is current row index
        // It is set to open only one menu
        this.setState({ anchorEl: event.currentTarget, index: i });
    };

    handleClose = (e) => {
        e.stopPropagation();
        this.setState({ anchorEl: null });
    };

    toggleMultilineTestCaseName = (index) => {
        let showMultilineTestCaseName = checkObject(this.state.showMultilineTestCaseName)
            ? JSON.parse(JSON.stringify(this.state.showMultilineTestCaseName))
            : {};
        if ([index] in showMultilineTestCaseName) {
            delete showMultilineTestCaseName[index];
        } else {
            showMultilineTestCaseName = { ...showMultilineTestCaseName, [index]: true };
        }
        this.setState({ showMultilineTestCaseName });
    };

    previewData = (data) => {
        const {showSnackBar} = this.props;
        if(!data.testDataId){
            return false;
        } else if ([DATA_FILE_TYPE.arti, DATA_FILE_TYPE.audio].includes(data.type)) {
            showSnackBar(`You cannot preview ${data.type === 'arti' ? 'artifacts' : 'audio'}`)
        } else {
            this.props.history.push({ 
                pathname: `/details/${getParamValues(1)}/data/datasheet/${data.testDataId}`, 
                state:{...data}
            });;
        }

    }

    SelectAll = (e, currentPageVariables) => {
        const select = e.target.checked ? currentPageVariables.map((variable) => variable.testDataId) : [];
        this.setState({ selectArr: select, selectedAll: true });
    };

    onDeleteClick = () => {
        const { selectArr, testData } = this.state;
        let _testData = testData;

        if(checkArrayLength(selectArr)){
            _testData = _testData.filter((v) => v.testDataId === selectArr);
        }

        this.setState({ deleteDataRow: true, deleteTestData: _testData, anchorEl: null });
    }


    render() {
        const { order, orderBy, index, rowsPerPage, page, deleteDataRow, deleteTestData } = this.state;
        let { testData, hoverKey, selectArr, isHovered } = this.state;


        const { accountId, classes, projectId, query, isLoadingCases, isLoadingTestData, toggleUploadDataModal, updateNewData, user,match } = this.props;
        if (query) {
            testData = checkArrayLength(testData)
                ? testData.filter(
                      (data) =>
                          checkKeyInObject(data, 'testDataName', 'value', '').toLowerCase().includes(query.toLowerCase()) ||
                          (!data.testDataName && checkKeyInObject(data, 'testDataFileName', 'value', '').toLowerCase().includes(query.toLowerCase())),
                  )
                : [];
        }
        let currentPageData = sort(testData, order, orderBy, orderBy === 'generatedAt' || orderBy === 'uploadedTime' ? 'date' : '').slice(
            page * rowsPerPage,
            page * rowsPerPage + rowsPerPage,
        );

        if (checkArrayLength(currentPageData)) {
            currentPageData = JSON.parse(JSON.stringify(currentPageData)).filter((datum) => datum.discoveryId === projectId);
        }

        return (
            <div className={classes.root}>
                {isLoadingTestData || isLoadingCases ? (
                    <div className={classes.minHeight264}>
                        <CircularProgress style={{ margin: '100px 50% 0px', color: '#4885ed' }} size={60} />
                    </div>
                ) : checkArrayLength(testData) ? (
                    <div className={classes.minHeight}>
                        <div className={classes.tableContainer}>
                            <Table aria-labelledby="tableTitle">
                                <Hidden only={['xs']}>
                                    <EnhancedTableHead
                                        //showSelectBtn={true}
                                        showDeleteBtn={ selectArr.length === 1 ? true : false }
                                        deletebuttonColIndex={1}
                                        onDeleteClick = {(e) => {
                                            e.stopPropagation();
                                            this.setState({ 
                                                deleteDataRow: true,
                                                deleteTestData: testData.filter((v) => v.testDataId === selectArr)[0], 
                                                anchorEl: null 
                                            });
                                            
                                        }}
                                        headers={headers}
                                        order={order}
                                        numSelected={selectArr.length}
                                        rowCount={testData.length}
                                        //onSelectAllClick={(e) => this.SelectAll(e, testData)}
                                        orderBy={orderBy}
                                        onRequestSort={this.handleRequestSort}
                                        colsWidth={this.colsWidth}
                                        noResizeColsIndex={[0, 6, 7, 8, 9, 10, 11]}
                                    />
                                </Hidden>
                                <Hidden smUp>
                                    <EnhancedTableHead
                                        //showSelectBtn={true}
                                        showDeleteBtn={selectArr.length === 1 ? true : false}
                                        deletebuttonColIndex={1}
                                        onDeleteClick = {(e) => {
                                            e.stopPropagation();
                                            this.onDeleteClick();
                                        }}
                                        headers={headers}
                                        order={order}
                                        numSelected={selectArr.length}
                                        rowCount={testData.length}
                                        //onSelectAllClick={(e) => this.SelectAll(e, testData)}
                                        orderBy={orderBy}
                                        onRequestSort={this.handleRequestSort}
                                        colsWidth={this.colsWidth}
                                        noResizeColsIndex={[0, 6, 7, 8, 9, 10, 11]}
                                    />
                                </Hidden>
                                <TableBody height={50} style={{ maxHeight: 'calc(100vh - 325px)' }} className={classes.tableBody}>
                                    {currentPageData.map((data, i) => {
                                        const isChecked = Boolean(selectArr.includes(data.testDataId));

                                        return (
                                            <TableRow
                                                className={classes.tableRow}
                                                focusCustom={classes.focused}
                                                style={{ minHeight: '50px', cursor: 'default' }}
                                                hover
                                                tabIndex={-1}
                                                key={i}
                                                focused={Boolean(selectArr.includes(data.testDataId))}
                                                onMouseEnter = { () => 
                                                    this.setState({isHovered: data.testDataId})
                                                }
                                                onMouseLeave = { () => 
                                                    this.setState({isHovered: null})
                                                }
                                            >
                                                {/***<TableCell
                                                    style={headers[0].tableCellStyles}
                                                    width={headers[0].width}
                                                    data-resize={`element${0}_c`}
                                                >
                                                <Checkbox
                                                    checked={Boolean(selectArr.includes(data.testDataId))}
                                                    onChange={(e) => this.onSelectAllClick(e, data.testDataId, false)}
                                                    classes={{
                                                        root: classes.checkBox,
                                                        checked: classes.checkBoxChecked,
                                                    }}
                                                    style={{ height: '19px', width: '20px', color: true ? '#bac5d0' : '#bac4ce', marginLeft: 10 }}
                                                /> 
                                                </TableCell>
                                                <TableCell
                                                    style={headers[1].styles}
                                                    width={headers[1].width}
                                                    data-resize={`element${1}_c`}
                                                >
                                                </TableCell>***/}
                                                <TableCell
                                                    style={headers[0].tableCellStyles}
                                                    width={headers[0].width}
                                                    data-resize={`element${0}_c`}
                                                >
                                                <span data-testid={`Image-${index}`}>
                                                    <Tooltip data={data.type === DATA_FILE_TYPE.arti ? 'Artifact' : capitalizeString(data.type)}>
                                                        <IconButton className={classes.IconButton} style = {{ marginLeft:10 }}>
                                                        {  [DATA_FILE_TYPE.arti, DATA_FILE_TYPE.audio].includes(data.type) ?  data.type === DATA_FILE_TYPE.arti ? 
                                                            ( <FilePowerpoint 
                                                                    className={[classes.moreIconsBtn, classes.shareButton].join(' ')}
                                                                    aria-label="artifacts"
                                                                    id="artifacts"
                                                                />
                                                            ) : ( <FontAwesomeIcon
                                                                style={{ fontSize: '21px', padding: '1px' }}
                                                                icon={faFileAudio}
                                                                aria-label="faFileAudioAlt"
                                                                id="ffaFileAudioAlt"
                                                                className={classes.audioIcons}
                                                            />)
                                                             : ( <FileDocument
                                                                    className={[classes.moreIconsBtn, classes.shareButton].join(' ')}
                                                                    aria-label="Data"
                                                                    id="Data" 

                                                                  />
                                                            )

                                                        }
                                                        </IconButton>
                                                    </Tooltip>
                                                </span>    
                                                </TableCell>
                                                <TableCell
                                                    className={[
                                                        classes.NoPadding,
                                                        classes.cell,
                                                        classes.noWrap,
                                                        classes.minWidth,
                                                    ].join(' ')}
                                                    style = {headers[1].tableCellStyles}
                                                    width={headers[1].width}
                                                    data-resize={`element${1}_c`}
                                                >
                                                     <div style={{ paddingLeft: '0px', width: '100%', cursor:'pointer' }}>
                                                        <div style={{ display: 'flex', alignItems: 'center' }}>
                                                            <span data-testid={`dataFile${index}`} onClick = {(e) => this.previewData(data)}>
                                                                <Tooltip data={data.testDataFileName}>
                                                                        {data.testDataName ? data.testDataName : data.testDataFileName}
                                                                </Tooltip>
                                                            </span>
                                                        </div>
                                                    </div>    
                                                </TableCell>
                                                <TableCell
                                                    className={[
                                                        classes.NoPadding,
                                                        classes.cell,
                                                        classes.noWrap,
                                                        classes.minWidth,
                                                    ].join(' ')}
                                                    width={headers[2].width}
                                                    data-resize={`element${2}_c`}
                                                >
                                                    <div style={{ paddingLeft: '10px', width: '100%' }}>
                                                        <ProjectDate
                                                            customStyles={{ fontSize: TABLES_CSS.normalFontSize, color: TABLES_CSS.normalFontColor }}
                                                            date={data.associatedTestCases && data.associatedTestCases[0].generatedAt}
                                                            user={user}
                                                        />
                                                    </div>
                                                </TableCell>
                                                <TableCell
                                                    className={[
                                                        classes.NoPadding,
                                                        classes.cell,
                                                        classes.noWrap,
                                                        classes.minWidth,
                                                    ].join(' ')}
                                                    width={headers[3].width}
                                                    data-resize={`element${3}_c`}
                                                >
                                                    <div style={{ paddingLeft: '10px', width: '100%' }}>
                                                        <ProjectTime
                                                            customStyles={{ fontSize: TABLES_CSS.normalFontSize, color: TABLES_CSS.normalFontColor, fontWeight: `${hoverKey === data.testDataId ? TITLE.fontWeight : TITLE.fontWeight }` }}
                                                            time={
                                                                checkKeyInObject(user, 'isRelativeDateFormat')
                                                                    ? getRelativeTime(data.uploadedTime)
                                                                    : getFormatedDate(data.uploadedTime, 'DD MMM YYYY', '')
                                                            }
                                                            is_nowrap
                                                            callFrom="data"
                                                        />
                                                    </div>
                                                </TableCell>
                                                
                                                <TableCell
                                                    className={[
                                                        classes.NoPadding,
                                                        classes.noWrap,
                                                        classes.minWidth,
                                                    ].join(' ')}
                                                    style = {headers[4].tableCellStyles}
                                                    width={headers[4].width}
                                                    data-resize={`element${4}_c`}
                                                >
                                                    <div style={{ paddingLeft: '21px', width: '100%', cursor:'pointer' }}>
                                                        <div style={{ display: 'flex', alignItems: 'center' }}>
                                                        <span className={classes.avatar}>
                                                            <Tooltip data="Associated Test Case(s)">
                                                                {checkArrayLength(data.associatedTestCases) &&
                                                                !data.associatedTestCases[0].name.includes('No Associated Test Case')
                                                                    ? data.associatedTestCases.length
                                                                    : 0}
                                                            </Tooltip>
                                                        </span>
                                                        <span onClick={(e)=> this.replaceURL(`${data.testDataId}`, 'associatedTestCaseModal')}>View Details</span>
                                                        {/***<Typography 
                                                        style={{ fontSize: TABLES_CSS.normalFontSize }} noWrap className={[
                                                            `${hoverKey == data.testDataId ? classes.boldRowData : ''}`, 
                                                            classes.cellText, 
                                                            classes.noWrap, 
                                                            classes.previewColor].join(' ')
                                                        }>
                                                            <span onClick = {(e)=> this.replaceURL(`${data.testDataId}`, 'associatedTestCaseModal')}>View Details</span>
                                                        </Typography>***/}
                                                        </div>
                                                    </div>
                                                </TableCell>
                                                {/***<TableCell
                                                    className={[
                                                        classes.cellWithoutPadding,
                                                        classes.noWrap,
                                                        classes.minWidth,
                                                    ].join(' ')}
                                                    width={headers[7].width}
                                                    data-resize={`element${7}_c`}
                                                >
                                                    <div style={{ paddingLeft: '21px', width: '100%' }}>
                                                        <span data-testid={`Clone-${index}`}>
                                                        { (isHovered == data.testDataId || isChecked) && (
                                                            <Tooltip data="Clone">
                                                                <IconButton title="Clone" className={classes.IconButton} onClick={() =>{}}>
                                                                    <img src={clone} alt="Main" className={`${classes.bitmapImage} ${classes.moreIconsBtn}`} style={{ width: '16px' }} alt="" />
                                                                </IconButton>
                                                            </Tooltip>
                                                        )}
                                                        </span>    
                                                    </div>    
                                                </TableCell>***/}
                                                <TableCell
                                                    className={[
                                                        classes.cellWithoutPadding,
                                                        classes.noWrap,
                                                        classes.minWidth,
                                                    ].join(' ')}
                                                    width={headers[5].width}
                                                    data-resize={`element${5}_c`}
                                                >
                                                    <div style={{ paddingLeft: '26px', width: '100%' }}>
                                                        <span data-testid={`Delete-${index}`}>
                                                        { 
                                                            (isHovered === data.testDataId || isChecked) && (
                                                            <Tooltip data="Delete">
                                                                <IconButton className={classes.IconButton} onClick={(e) => {
                                                                    e.stopPropagation();
                                                                    this.setState({ deleteDataRow: true, deleteTestData: data, anchorEl: null });
                                                                }}>
                                                                    <Delete 
                                                                        className={[classes.moreIconsBtn, classes.shareButton].join(' ')}
                                                                        aria-label="Delete"
                                                                        id="Delete"  
                                                                    />
                                                                </IconButton>
                                                            </Tooltip>
                                                            )
                                                        }
                                                        </span>
                                                    </div>
                                                </TableCell>
                                                <TableCell
                                                    className={[
                                                        classes.cellWithoutPadding,
                                                        classes.noWrap,
                                                        classes.minWidth,
                                                    ].join(' ')}
                                                    width={headers[6].width}
                                                    data-resize={`element${6}_c`}
                                                >
                                                    <div style={{ paddingLeft: '26px', width: '100%' }}>
                                                        <span data-testid={`Download-${index}`}>
                                                        {
                                                            (isHovered === data.testDataId || isChecked) && (                                                    
                                                            <Tooltip data="Download">
                                                                <IconButton className={classes.IconButton} onClick={() =>{singleFileCreationService.downloadArtifact(data);}}>
                                                                    <Download 
                                                                        className={[classes.moreIconsBtn, classes.shareButton].join(' ')}
                                                                        aria-label="Download"
                                                                        id="Download"  
                                                                    />
                                                                </IconButton>
                                                            </Tooltip>
                                                            )
                                                        }
                                                        </span>
                                                    </div>
                                                </TableCell>
                                            </TableRow>
                                        );
                                    })}
                                </TableBody>
                            </Table>
                        </div>
                        {deleteDataRow ? (
                            <DeleteAlertModal
                                deleteButton="Danger"
                                handleClose={this.hadleCloseDeleteAlertModal}
                                open={deleteDataRow}
                                modalfor="Data"
                                title="Data File"
                                name={deleteTestData && deleteTestData.testDataFileName ? deleteTestData.testDataFileName : ''}
                            />
                        ) : null}
                    </div>
                ) : (
                    <div className={classes.minHeight}>
                        <NoDataToShow
                            imgSrc={ImgSrc}
                            selectedTab="data"
                            buttonTitle = "UPLOAD NEW DATA"
                            onClick={() => {
                                toggleUploadDataModal('uploadData', null, '', { projectId, updateNewData, accountId });
                            }}
                        />
                    </div>
                )}
                <TableFooter
                    count={testData.length}
                    rowsPerPage={rowsPerPage}
                    rowsPerPageOptions={[5, 10, 20, 50]}
                    page={page}
                    handleChangePage={this.handleChangePage}
                    handleChangeRowsPerPage={this.handleChangeRowsPerPage}
                    applyCustomClasses
                    labelRowsPerPage="Data per page:"
                    containerStyle={{ marginLeft: '-20px', marginRight: '-20px' }}
                />
            </div>
        );
    }
}

Datalist.propTypes = {
    classes: PropTypes.shape({}).isRequired,
    handleDeleteTestData: PropTypes.func.isRequired,
    toggleModal: PropTypes.func.isRequired,
    toggleUploadDataModal: PropTypes.func.isRequired,
    query: PropTypes.string,
    queryRemove: PropTypes.func.isRequired,
    location: PropTypes.object.isRequired,
};

Datalist.defaultProps = {
    query: '',
};

const mapStateToProps = (state) => {
    return {
        isLoadingTestData: state.projectReducer.isLoadingTestData,
        isLoadingCases: state.projectReducer.isLoadingCases,
        user: state.authReducer.user,
    };
};
const mapDispatchToProps = (dispatch) => {
    return {
        showSnackBar: (...args) => dispatch(ModalActions.toggleSnackBar(...args)),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(Datalist));
