import React from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { withStyles } from '@material-ui/core/styles';
import CheckBox from '@material-ui/icons/CheckBox';
import ChevronRight from '@material-ui/icons/ChevronRight';
import CheckBoxBlank from '@material-ui/icons/CheckBoxOutlineBlank';
import CheckBoxIndeterminate from '@material-ui/icons/IndeterminateCheckBox';
import { IconButton } from '@material-ui/core';
import { BLACK_FONT } from '../../../../common/cssConstants';
import { generalModalActions } from '../../../../store/actions';
import { checkArrayLength, checkKeyInObject, checkObject } from '../../../../utils/utils';
import SearchInput from '../../../modal/GeneralModal/SearchInput';
import Tooltip from '../../../../components/Tooltip'

let isMounted = false;

const styles = () => ({
    rootDialogContent: {
        width: '600px',
        overflow: 'hidden',
    },
    checkBoxIcon: {
        color: '#0092e5',
        fontSize: '20px',
    },
    checkBoxIconUnCheck: {
        color: '#bac5d0',
        fontSize: '20px',
    },
    checkBox: {
        height: 25,
        width: 25,
        marginRight: 5,
        '& > span':{
            justifyContent:"normal"
        }
    },
    disableRow:{
        PointerEvent: 'none !important',
        color:"#bbbbbb"
    },
    listItem: {
        display: ' flex',
        borderBottom: '1px solid  #cad3db',
        padding: '8px 30px 6px 30px',
        color: '#b8c4cf',
        '&:nth-of-type(odd)': {
            backgroundColor: '#fafafa',
        },
    },
    listItemText: {
        marginTop: '4px',
        cursor: 'pointer',
        fontSize: '12px',
        color: BLACK_FONT,
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
    },
    caseContainer: {
        maxHeight: 'calc(100vh - 403px)',
        overflowY: 'auto',
        overflowX: 'hidden',
    },
    testSuiteName: {
        color: BLACK_FONT,
        fontSize: 16,
        paddingLeft: 30,
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        paddingRight: '30px',
    },
    horizontalRow: {
        width: 'calc(100% + 55px)',
        marginLeft: '-55px',
        border: 0,
        borderTop: '1px solid #DCDCDC',
    },
    headerStyle: {
        display: 'flex',
        justifyContent: 'space-between',
        padding: '0px 30px',
        height: 25,
    },
    searchInput: {
        marginRight: '40px',
        width: 250,
    },
});

class SuiteAssociation extends React.Component {
    state = {
        selectedTestCaseIds: {},
        selectedTestCaseIdsForDelete: {},
        testSuiteName: '',
        testCaseIds: {},
        querry: '',
        isSubmitButtonPressed: false,
        isFirst: false,
        didChange: false,
    };

    componentDidMount() {
        isMounted = true;
        const { associateTestSuiteData } = this.props;
        if (!checkKeyInObject(this.props.tabsData.association, 'state')) {
            const _selectedTestCaseIds = {};
            if (checkObject(associateTestSuiteData) && checkArrayLength(associateTestSuiteData.testCases)) {
                associateTestSuiteData.testCases.forEach((testCase) => {
                    _selectedTestCaseIds[testCase.testCaseId] = true;
                });
            }
            this.setStateIfComponentMounted({
                testSuiteName: checkKeyInObject(associateTestSuiteData, 'testSuiteName') ? associateTestSuiteData.testSuiteName : '',
                selectedTestCaseIds: _selectedTestCaseIds,
                testCaseIds: _selectedTestCaseIds,
                isFirst: true,
            });
        } else {
            this.setStateIfComponentMounted({
                ...this.props.tabsData.association.state,
                isFirst: true,
                didChange: this.props.tabsData.association.state.didChange,
            });
        }
    }

    componentWillUnmount() {
        this.onUnmount();
    }

    onUnmount = () => {
        isMounted = false;
        this.props.updateTabData('association', { state: { ...this.state } });
    };

    setStateIfComponentMounted = (obj, callback = () => {}) => {
        if (isMounted)
            this.setState({ didChange: this.state.isFirst, ...obj }, () => {
                callback();
                this.props.updateTabData('association', { state: { ...this.state } });
            });
    };

    toggleAlreadySavedTestCase = (caseIds, flag = 'add', callback = () => {}) => {
        const { associateTestSuiteData } = this.props;
        const _selectedTestCaseIdsForDelete = JSON.parse(JSON.stringify(this.state.selectedTestCaseIdsForDelete));
        if (
            checkKeyInObject(associateTestSuiteData, 'testCases') &&
            checkArrayLength(associateTestSuiteData.testCases) &&
            checkArrayLength(caseIds)
        ) {
            caseIds.forEach((caseId) => {
                associateTestSuiteData.testCases.forEach((testCase) => {
                    if (Number(caseId) === Number(testCase.testCaseId)) {
                        if (flag === 'add') {
                            _selectedTestCaseIdsForDelete[caseId] = true;
                        } else {
                            delete _selectedTestCaseIdsForDelete[caseId];
                        }
                    }
                });
            });
        }
        this.setStateIfComponentMounted({ selectedTestCaseIdsForDelete: _selectedTestCaseIdsForDelete }, () => {
            callback();
        });
    };

    handelSelectCase = (caseId) => {
        const { selectedTestCaseIds } = this.state;
        const _selectedTestCaseIds = JSON.parse(JSON.stringify(selectedTestCaseIds));
        if (_selectedTestCaseIds[caseId]) {
            delete _selectedTestCaseIds[caseId];
            this.toggleAlreadySavedTestCase([caseId], 'add');
        } else {
            _selectedTestCaseIds[caseId] = true;
            this.toggleAlreadySavedTestCase([caseId], 'remove');
        }
        this.setStateIfComponentMounted({ selectedTestCaseIds: _selectedTestCaseIds }, () => {
            this.checkIsChanged();
        });
    };

    select_deSelectAllCurrentPage = (cases, isSelect) => {
        const { selectedTestCaseIds } = this.state;
        const _selectedTestCaseIds = JSON.parse(JSON.stringify(selectedTestCaseIds));
        const caseIdstoAdd = []; // ids to add from delete cases array
        const caseIdstoRemove = []; // ids to remove from delete cases array
        cases.forEach((_case) => {
            if (!isSelect && _selectedTestCaseIds[_case.testCaseId]) {
                delete _selectedTestCaseIds[_case.testCaseId];
                caseIdstoAdd.push(_case.testCaseId);
            } else if (isSelect) {
                if(_case.testScriptId!==0){
                    _selectedTestCaseIds[_case.testCaseId] = true;
                    caseIdstoRemove.push(_case.testCaseId);
                }
            }
        });
        this.toggleAlreadySavedTestCase(caseIdstoAdd, 'add', () => this.toggleAlreadySavedTestCase(caseIdstoRemove, 'remove'));
        this.setStateIfComponentMounted({ selectedTestCaseIds: _selectedTestCaseIds }, () => {
            this.checkIsChanged();
        });
    };

    checkIsChanged = () => {
        const { selectedTestCaseIds, testCaseIds } = this.state;

        const ids1 = Object.keys(selectedTestCaseIds).map((item) => parseInt(item, 10));
        const ids2 = Object.keys(testCaseIds).map((item) => parseInt(item, 10));
        if (ids1.length === ids2.length) {
            for (let i = 0; i < ids2.length; i++) {
                let isMatch = false;
                for (let j = 0; j < ids1.length; j++) {
                    if (ids1[j] === ids2[i]) {
                        isMatch = true;
                        break;
                    }
                }
                if (!isMatch) {
                    break;
                }
            }
        }
    };

    handleChange = (e) => {
        const _querry = e.target.value;
        this.setStateIfComponentMounted({ querry: _querry });
    };

    clearSearch = () => {
        this.setStateIfComponentMounted({ querry: '' });
    };

    render() {
        const { classes, testCaseDropDownData,checkIfNameExist } = this.props;
        checkIfNameExist(true)
        const { selectedTestCaseIds, querry } = this.state;
        const filterTestCaseData = checkArrayLength(testCaseDropDownData)
            ? testCaseDropDownData.filter((testCase) => {
                  return testCase && testCase.testCaseName && testCase.testCaseName.toLowerCase().includes(querry.toLowerCase().trim());
              })
            : [];

        let isAllSelected = filterTestCaseData.length > 0;

        const _filterTestCaseData = filterTestCaseData.length ? filterTestCaseData.filter(testCase => testCase.testScriptId!==0) : []
        
        if(!_filterTestCaseData.length){
            isAllSelected = false;
        }        

        for (let i = 0; i < _filterTestCaseData.length; i++) {
            const _case = _filterTestCaseData[i];
            if (!selectedTestCaseIds[_case.testCaseId]) {
                isAllSelected = false;
                break;
            }
        }

        return (
            <div className={classes.rootDialogContent}>
                <div className={classes.testSuiteName}>
                    <Tooltip data={` ${checkKeyInObject(this.props.tabsData, 'suiteInfo.state.testSuiteName', 'value', '')}`}>
                        {checkKeyInObject(this.props.tabsData, 'suiteInfo.state.testSuiteName', 'value', '')}
                    </Tooltip>
                </div>
                <hr className={classes.horizontalRow} />
                <div className={classes.headerStyle}>
                    <IconButton
                        onClick={() => {
                            this.select_deSelectAllCurrentPage(filterTestCaseData, !isAllSelected);
                        }}
                        variant="contained"
                        color="primary"
                        className={classes.checkBox}
                        tabIndex={-1}
                    >
                        {isAllSelected ? (
                            <CheckBox className={classes.checkBoxIcon} aria-label="checkBoxIcon" id="checkBoxIcon" />
                        ) : checkArrayLength(Object.keys(selectedTestCaseIds)) ? (
                            <CheckBoxIndeterminate className={classes.checkBoxIconUnCheck} aria-label="checkBoxBlankIcon" id="checkBoxBlankIcon" />
                        ) : (
                            <CheckBoxBlank className={classes.checkBoxIconUnCheck} aria-label="checkBoxBlankIcon" id="checkBoxBlankIcon" />
                        )}
                    </IconButton>
                    <SearchInput
                        id="SuiteAssociation"
                        placeholder="Search"
                        onChange={this.handleChange}
                        value={querry}
                        style={{ marginRight: '40px', width: 250 }}
                        clearSearch={this.clearSearch}
                    />
                </div>
                <hr className={classes.horizontalRow} />
                <div className={classes.caseContainer}>
                    {filterTestCaseData &&
                        filterTestCaseData.map((testCase) => {
                            return (
                            <div key={testCase.testCaseId} className={classes.listItem}>
                                <IconButton
                                    disabled={testCase.testScriptId == 0}
                                    onClick={() => {
                                        this.handelSelectCase(testCase.testCaseId);
                                    }}
                                    variant="contained"
                                    color="primary"
                                    className={classes.checkBox}
                                    tabIndex={-1}
                                >
                                    {selectedTestCaseIds[testCase.testCaseId] ? (
                                        <CheckBox className={classes.checkBoxIcon} aria-label="checkBoxIcon" id="checkBoxIcon" />
                                    ) : (
                                        <CheckBoxBlank
                                            className={classes.checkBoxIconUnCheck}
                                            aria-label="checkBoxBlankIcon"
                                            id="checkBoxBlankIcon"
                                        />
                                    )}
                                </IconButton>
                                <div
                                    aria-hidden
                                    className={`${classes.listItemText}`}
                                    style={testCase.testScriptId == 0 ? {pointerEvents: "none", color:"#bbbbbb", flexGrow: 1} : {}}
                                    onClick={() => {
                                        this.handelSelectCase(testCase.testCaseId);
                                    }}
                                >
                                    <Tooltip data={` ${testCase.testCaseName}` }>
                                        {`${testCase.testCaseName} ${testCase.testScriptId==0 ? '(Ready for generation)' : ''}`}
                                    </Tooltip>
                                </div>
                                {testCase.testScriptId == 0 && <div>
                                    <Tooltip data={'Go to Step Editor'}>
                                        <Link to={`/details/${testCase.discoveryId}/${testCase.testCaseId}`} style={{color: '#1168CD'}}>
                                            <ChevronRight
                                            aria-label="chevronRightIcon"
                                            id="chevronRightIcon"
                                            style={{fontSize: 20}}
                                            />
                                        </Link>
                                    </Tooltip>

                                </div>
                                }
                            </div>
                        )})}
                </div>
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        tabsData: state.generalModalReducer.tabsData,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        updateTabData: (...args) => dispatch(generalModalActions.updateTabData(...args)),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(SuiteAssociation));
