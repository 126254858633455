// import materials
import { withStyles } from '@material-ui/core/styles';

// import packages
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import React, { Component } from 'react';

// import Custom components
import { DashboardActions, AccountActions, GeneralActions } from '../../store/actions';
import CircularChart from './CircularChart';
import BarChart from './BarChart';
import WaveChart from './LineChart';
import DashboardSettingsModal from './DashboardSettingModal';

// Styles
import { styles } from './styles';
import './index.css';
import { checkKeyInObject, sort, checkArrayLength, getFormatedDate } from '../../utils/utils';

class Dashboard extends Component {
    constructor(props) {
        super(props);
        this.state = {
            failureProjects: [],
            activeProjects: [],
            activeUsers: [],
            activeTestCases: [],
            projectCircularChartItems: [],
            testCaseCircularChartItems: [],
            testSuiteCircularChartItems: [],
            testExecution: [],
            autonomousReport: [],
            settingDialogOpen: false,
            headers: [
                {
                    label: 'Project',
                    value: 'Project',
                    status: 'Active',
                },
                {
                    label: 'Test Suite',
                    value: 'TestSuite',
                    status: 'Active',
                },
                {
                    label: 'Test Cases',
                    value: 'TestCases',
                    status: 'Active',
                },
                {
                    label: 'Autonomous Report',
                    value: 'AutonomousReport',
                    status: 'Active',
                },
                {
                    label: 'Test Execution',
                    value: 'TestExecution',
                    status: 'Active',
                },
                {
                    label: 'Top Fail',
                    value: 'TopFail',
                    status: 'Active',
                },
                {
                    label: 'Active Users',
                    value: 'ActiveUsers',
                    status: 'Active',
                },
                {
                    label: 'Active Project',
                    value: 'ActiveProject',
                    status: 'Active',
                },
                {
                    label: 'Active TestCase',
                    value: 'ActiveTestCase',
                    status: 'Active',
                },
                {
                    label: 'Dashboard Configration',
                    value: 'DashboardConfigration',
                    status: 'Active',
                },
            ],
        };
        this.props.updateTitle('Dashboard');
    }

    componentDidMount() {
        const {
            getDashboardInfo,
            getAccountsPrefrences,
            user: { accountId },
            tickerIndicatorValue,
            getPermissions,
        } = this.props;
        getDashboardInfo(tickerIndicatorValue);
        getAccountsPrefrences(accountId);
        getPermissions();
    }

    UNSAFE_componentWillReceiveProps(nextProps) {
        const { prefences } = nextProps;

        const tickerIndicatorValue = nextProps.tickerIndicatorValue.toLowerCase();

        /* Project Circular Chart Data - Start */
        const projectsData = checkKeyInObject(nextProps.dashboardData, 'project_stats', 'value', {});
        const projectCircularChartItems = [];
        if (projectsData) {
            projectCircularChartItems.push({
                projectId: 0,
                name: 'All',
                progress: projectsData.cumulative_stats ? projectsData.cumulative_stats : 0,
            });
            if (projectsData.prj_stats) {
                projectsData.prj_stats.forEach((item) => {
                    projectCircularChartItems.push({
                        projectId: item.project_id,
                        name: item.project_name ? item.project_name : 'N/A',
                        progress: item.success_ratio ? item.success_ratio : 0,
                        fail: item.total_tests_failed_count,
                        pass: item.total_tests_passed_count,
                        skip: item.total_tests_skipped_count,
                    });
                });
            }
        }
        /* Project Circular Chart Data - End */

        /* Test Suite Circular Chart Data - Start */
        const testSuiteData = checkKeyInObject(nextProps.dashboardData, 'suite_stats', 'value', {});
        const testSuiteCircularChartItems = [];
        if (testSuiteData) {
            testSuiteCircularChartItems.push({
                projectId: 0,
                name: 'All',
                fail: checkKeyInObject(testSuiteData, 'total_failed', 'value', 0),
                pass: checkKeyInObject(testSuiteData, 'total_passed', 'value', 0),
                progress: checkKeyInObject(testSuiteData, 'cummulative_stats', 'value', 0),
            });
            testSuiteCircularChartItems.push({
                projectId: 0,
                name: 'N/A',
                fail: checkKeyInObject(testSuiteData, 'total_failed', 'value', 0),
                pass: checkKeyInObject(testSuiteData, 'total_passed', 'value', 0),
                progress: 0,
            });
        }
        /* Test Suite Circular Chart Data - End */

        /* Top 5 Failures Chart Data - Start */
        const failureProjects = [];
        if (checkKeyInObject(projectsData, 'prj_stats')) {
            const failureData = sort(projectsData.prj_stats, 'desc', 'total_tests_failed_count');
            if (checkArrayLength(failureData)) {
                failureData.some((data, index) => {
                    if (index < 5 && data.total_tests_failed_count > 0) {
                        failureProjects.push({
                            id: data.project_id,
                            name: data.project_name,
                            count: data.total_tests_failed_count,
                        });
                        return false;
                    }
                    return true;
                });
            }
        }
        for (let i = failureProjects.length; i < 5; i++) {
            failureProjects.push({ id: -1, name: '', count: 0 });
        }
        /* Top 5 Failures Chart Data - End */

        /* Bottom 3 Bar Charts - Start */
        const activeChartsSelectedData = checkKeyInObject(nextProps.dashboardData, 'changes.periodCounts', 'value', []);
        // let activeChartsSelectedData = activeChartsData.find(element => {
        //     return element.period === `1-${tickerIndicatorValue}` ? element : null
        // });

        let activeProjects = [];
        let activeUsers = [];
        let activeTestCases = [];

        if (checkKeyInObject(activeChartsSelectedData, 'projects')) {
            activeProjects = activeChartsSelectedData.projects;
        }
        for (let i = activeProjects.length; i < 5; i++) {
            activeProjects.push({ id: -1, name: '', count: 0 });
        }
        if (checkKeyInObject(activeChartsSelectedData, 'users')) {
            activeUsers = activeChartsSelectedData.users;
        }
        for (let i = activeUsers.length; i < 5; i++) {
            activeUsers.push({ id: -1, name: '', count: 0 });
        }
        if (checkKeyInObject(activeChartsSelectedData, 'testcases')) {
            activeTestCases = activeChartsSelectedData.testcases;
        }
        for (let i = activeTestCases.length; i < 5; i++) {
            activeTestCases.push({ id: -1, name: '', count: 0 });
        }
        /* Bottom 3 Bar Charts - End */

        /* Test Execution Chart - Start  */
        const testExecution = [];
        const dailyExecutions = checkKeyInObject(nextProps.dashboardData, 'daily_executions', 'value', []);
        if (tickerIndicatorValue === 'week') {
            for (let i = 0; i < 7; i++) {
                const date = new Date();
                date.setDate(date.getDate() - i);
                testExecution.push({
                    name: getFormatedDate(date, 'DD MMM'),
                    date: getFormatedDate(date, 'MMM-DD-YYYY'),
                    Total: 0,
                    Pass: 0,
                    Fail: 0,
                });
            }
            dailyExecutions.forEach((exec) => {
                const date = exec.started_time;
                testExecution.some((data) => {
                    if (data.date === getFormatedDate(date, 'MMM-DD-YYYY')) {
                        data.Total++;
                        if (`${exec.status}` === '1') {
                            data.Pass++;
                        } else {
                            data.Fail++;
                        }
                        return true;
                    }
                    return false;
                });
            });
        } else if (tickerIndicatorValue === 'month') {
            for (let i = 0; i < 5; i++) {
                const date = new Date();
                date.setDate(date.getDate() - i * 7);
                const startDate = new Date();
                startDate.setDate(startDate.getDate() - (i + 1) * 7 + 1);
                testExecution.push({
                    name: getFormatedDate(date, 'DD MMM'),
                    startDate: getFormatedDate(startDate, 'MMM-DD-YYYY'),
                    endDate: getFormatedDate(date, 'MMM-DD-YYYY'),
                    Total: 0,
                    Pass: 0,
                    Fail: 0,
                });
            }
            dailyExecutions.forEach((exec) => {
                const date = exec.started_time;
                testExecution.some((data) => {
                    if (
                        new Date(data.endDate).valueOf() >= new Date(getFormatedDate(date, 'MMM-DD-YYYY')).valueOf() &&
                        new Date(data.startDate).valueOf() <= new Date(getFormatedDate(date, 'MMM-DD-YYYY')).valueOf()
                    ) {
                        data.Total++;
                        if (`${exec.status}` === '1') {
                            data.Pass++;
                        } else {
                            data.Fail++;
                        }
                        return true;
                    }
                    return false;
                });
            });
        } else if (tickerIndicatorValue === 'year') {
            for (let i = 0; i < 12; i++) {
                const date = new Date();
                date.setMonth(date.getMonth() - i);
                const firstDay = new Date(date.getFullYear(), date.getMonth(), 1);
                const lastDay = new Date(date.getFullYear(), date.getMonth() + 1, 0);
                testExecution.push({
                    name: getFormatedDate(date, 'MMM-YY'),
                    startDate: getFormatedDate(firstDay, 'MMM-DD-YYYY'),
                    endDate: getFormatedDate(lastDay, 'MMM-DD-YYYY'),
                    Total: 0,
                    Pass: 0,
                    Fail: 0,
                });
            }
            dailyExecutions.forEach((exec) => {
                const date = exec.started_time;
                testExecution.some((data) => {
                    if (
                        new Date(data.endDate).valueOf() >= new Date(getFormatedDate(date, 'MMM-DD-YYYY')).valueOf() &&
                        new Date(data.startDate).valueOf() <= new Date(getFormatedDate(date, 'MMM-DD-YYYY')).valueOf()
                    ) {
                        data.Total++;
                        if (`${exec.status}` === '1') {
                            data.Pass++;
                        } else {
                            data.Fail++;
                        }
                        return true;
                    }
                    return false;
                });
            });
        }
        /* Test Execution Chart - End  */

        /* Autonomous Report Chart - Start  */
        const autonomousReport = [];
        const daily_generations_data = checkKeyInObject(nextProps.dashboardData, 'daily_generations_data', 'value', []);
        const daily_warnings_data = checkKeyInObject(nextProps.dashboardData, 'daily_warnings_data', 'value', []);
        if (tickerIndicatorValue === 'week') {
            for (let i = 0; i < 7; i++) {
                const date = new Date();
                date.setDate(date.getDate() - i);
                autonomousReport.push({
                    name: getFormatedDate(date, 'DD MMM'),
                    date: getFormatedDate(date, 'MMM-DD-YYYY'),
                    Cases: 0,
                    AutoHeal: 0,
                });
            }
            daily_generations_data.forEach((gen) => {
                const date = new Date(gen.day);
                autonomousReport.some((data) => {
                    if (data.date === getFormatedDate(date, 'MMM-DD-YYYY')) {
                        data.Cases += gen.generations;
                        return true;
                    }
                    return false;
                });
            });
            daily_warnings_data.forEach((warn) => {
                const date = new Date(warn.day);
                autonomousReport.some((data) => {
                    if (data.date === getFormatedDate(date, 'MMM-DD-YYYY')) {
                        data.AutoHeal += warn.err_count && warn.err_count > 0 ? warn.err_count : 0;
                        return true;
                    }
                    return false;
                });
            });
        } else if (tickerIndicatorValue === 'month') {
            for (let i = 0; i < 5; i++) {
                const date = new Date();
                date.setDate(date.getDate() - i * 7);
                const startDate = new Date();
                startDate.setDate(startDate.getDate() - (i + 1) * 7 + 1);
                autonomousReport.push({
                    name: getFormatedDate(date, 'DD MMM'),
                    startDate: getFormatedDate(startDate, 'MMM-DD-YYYY'),
                    endDate: getFormatedDate(date, 'MMM-DD-YYYY'),
                    Cases: 0,
                    AutoHeal: 0,
                });
            }
            daily_generations_data.forEach((gen) => {
                const date = new Date(gen.day);
                autonomousReport.some((data) => {
                    if (
                        new Date(data.endDate).valueOf() >= new Date(getFormatedDate(date, 'MMM-DD-YYYY')).valueOf() &&
                        new Date(data.startDate).valueOf() <= new Date(getFormatedDate(date, 'MMM-DD-YYYY')).valueOf()
                    ) {
                        data.Cases += gen.generations;
                        return true;
                    }
                    return false;
                });
            });
            daily_warnings_data.forEach((warn) => {
                const date = new Date(warn.day);
                autonomousReport.some((data) => {
                    if (
                        new Date(data.endDate).valueOf() >= new Date(getFormatedDate(date, 'MMM-DD-YYYY')).valueOf() &&
                        new Date(data.startDate).valueOf() <= new Date(getFormatedDate(date, 'MMM-DD-YYYY')).valueOf()
                    ) {
                        data.AutoHeal += warn.err_count && warn.err_count > 0 ? warn.err_count : 0;
                        return true;
                    }
                    return false;
                });
            });
        } else if (tickerIndicatorValue === 'year') {
            for (let i = 0; i < 12; i++) {
                const date = new Date();
                date.setMonth(date.getMonth() - i);
                const firstDay = new Date(date.getFullYear(), date.getMonth(), 1);
                const lastDay = new Date(date.getFullYear(), date.getMonth() + 1, 0);
                autonomousReport.push({
                    name: getFormatedDate(date, 'MMM-YY'),
                    startDate: getFormatedDate(firstDay, 'MMM-DD-YYYY'),
                    endDate: getFormatedDate(lastDay, 'MMM-DD-YYYY'),
                    Cases: 0,
                    AutoHeal: 0,
                });
            }
            daily_generations_data.forEach((gen) => {
                const date = new Date(gen.day);
                autonomousReport.some((data) => {
                    if (
                        new Date(data.endDate).valueOf() >= new Date(getFormatedDate(date, 'MMM-DD-YYYY')).valueOf() &&
                        new Date(data.startDate).valueOf() <= new Date(getFormatedDate(date, 'MMM-DD-YYYY')).valueOf()
                    ) {
                        data.Cases += gen.generations;
                        return true;
                    }
                    return false;
                });
            });
            daily_warnings_data.forEach((warn) => {
                const date = new Date(warn.day);
                autonomousReport.some((data) => {
                    if (
                        new Date(data.endDate).valueOf() >= new Date(getFormatedDate(date, 'MMM-DD-YYYY')).valueOf() &&
                        new Date(data.startDate).valueOf() <= new Date(getFormatedDate(date, 'MMM-DD-YYYY')).valueOf()
                    ) {
                        data.AutoHeal += warn.err_count && warn.err_count > 0 ? warn.err_count : 0;
                        return true;
                    }
                    return false;
                });
            });
        }
        /* Autonomous Report Chart - End  */

        this.setState({
            failureProjects,
            activeProjects,
            activeUsers,
            activeTestCases,
            projectCircularChartItems,
            testSuiteCircularChartItems,
            testExecution: testExecution.reverse(),
            autonomousReport: autonomousReport.reverse(),
            testCaseCircularChartItems: projectCircularChartItems,
            settingDialogOpen: nextProps.settingDialogOpen,
        });

        if (prefences && prefences.accounts) {
            const { accounts } = prefences;
            const getCard = typeof accounts.dashboardCards === 'string' && accounts.dashboardCards !== '' ? JSON.parse(accounts.dashboardCards) : [];
            const _headers = JSON.parse(JSON.stringify(this.state.headers));
            _headers.forEach((filterFind) => {
                if (getCard.includes(filterFind.value)) {
                    filterFind.status = 'Active';
                } else {
                    filterFind.status = 'InActive';
                }
            });
            this.setState({
                headers: _headers,
            });
        }
    }

    handleCloseSettingDialog = () => {
        this.props.toggleDashboardSettingGear(false);
        this.setState({
            settingDialogOpen: false,
        });
    };

    changeHeaderStatus = (headers) => {
        this.setState({ headers });
        const id = this.props.user.accountId;
        const arryDta = headers.filter((filterFind) => filterFind.status === 'Active').map((filterFind) => filterFind.value);
        const FTD = arryDta.filter((RUNI) => RUNI);
        const sendData = JSON.stringify(FTD);
        this.props.UpdateDashboardCards(id, sendData);
    };

    render() {
        const { classes, tickerIndicatorValue, isLoading, history } = this.props;
        const {
            activeTestCases,
            activeUsers,
            activeProjects,
            failureProjects,
            projectCircularChartItems,
            testSuiteCircularChartItems,
            testCaseCircularChartItems,
            testExecution,
            autonomousReport,
            settingDialogOpen,
            headers,
        } = this.state;
        const _cardsStatus = {};
        headers.forEach((item) => {
            _cardsStatus[item.value] = item.status === 'Active';
        });
        return (
            <div className={classes.rootContainer}>
                <div>
                    <div className={`${classes.body} ${classes.maxHeight}`}>
                        <div className={classes.col_1}>
                            {_cardsStatus.Project && (
                                <CircularChart
                                    marginRight
                                    tickerIndicatorValue={tickerIndicatorValue}
                                    redirectURL="/plan/projects"
                                    history={history}
                                    title="Projects"
                                    redirectMessage="Go to Projects"
                                    isLoading={isLoading}
                                    circularChartItems={projectCircularChartItems}
                                />
                            )}
                            {_cardsStatus.TestSuite && (
                                <CircularChart
                                    marginRight
                                    tickerIndicatorValue={tickerIndicatorValue}
                                    redirectURL="/plan/testsuites"
                                    history={history}
                                    title="Test Suites"
                                    redirectMessage="Go to Test Suites"
                                    isLoading={isLoading}
                                    circularChartItems={testSuiteCircularChartItems}
                                />
                            )}
                            {_cardsStatus.TestCases && (
                                <CircularChart
                                    tickerIndicatorValue={tickerIndicatorValue}
                                    redirectURL="/plan/testcases"
                                    history={history}
                                    title="Test Cases"
                                    redirectMessage="Go to Test Cases"
                                    isLoading={isLoading}
                                    circularChartItems={testCaseCircularChartItems}
                                />
                            )}
                            {_cardsStatus.TestExecution && (
                                <WaveChart
                                    tickerIndicatorValue={tickerIndicatorValue}
                                    isLoading={isLoading}
                                    title="Test Executions"
                                    axisData={{
                                        YKey: null,
                                        YType: 'number',
                                        XKey: 'name',
                                        XType: 'category',
                                        dataFormat: [
                                            {
                                                dataKey: 'Total',
                                                dataText: 'Total',
                                                stroke: '#3B91DF',
                                            },
                                            {
                                                dataKey: 'Pass',
                                                dataText: 'Pass',
                                                stroke: '#00B330',
                                            },
                                            {
                                                dataKey: 'Fail',
                                                dataText: 'Fail',
                                                stroke: '#E8001C',
                                            },
                                        ],
                                    }}
                                    chartData={testExecution}
                                />
                            )}
                            {_cardsStatus.ActiveUsers && (
                                <BarChart
                                    style={{
                                        width: 'calc(50% - 10px)',
                                        marginRight: '20px',
                                    }}
                                    tickerIndicatorValue={tickerIndicatorValue}
                                    redirectURL="/admin/users"
                                    history={history}
                                    title="Active Users"
                                    noDataMessage="No User Data Available"
                                    redirectMessage="Go to Users"
                                    axisData={{
                                        YKey: 'name',
                                        XKey: 'count',
                                        YType: 'category',
                                        XType: 'number',
                                        dataKey: 'count',
                                        barColor: '#3B91DF',
                                    }}
                                    tooltipData={[
                                        { key: 'User', valueKey: 'originalName' },
                                        { key: 'Counts', valueKey: 'count' },
                                    ]}
                                    isLoading={isLoading}
                                    chartData={activeUsers}
                                />
                            )}
                            {_cardsStatus.ActiveProject && (
                                <BarChart
                                    style={{
                                        width: 'calc(50% - 10px)',
                                    }}
                                    tickerIndicatorValue={tickerIndicatorValue}
                                    redirectURL="/plan/projects"
                                    history={history}
                                    title="Active Projects"
                                    noDataMessage="No Project Data Available"
                                    redirectMessage="Go to Projects"
                                    axisData={{
                                        YKey: 'name',
                                        XKey: 'count',
                                        YType: 'category',
                                        XType: 'number',
                                        dataKey: 'count',
                                        barColor: '#00B330',
                                    }}
                                    tooltipData={[
                                        { key: 'Project', valueKey: 'originalName' },
                                        { key: 'Count', valueKey: 'count' },
                                    ]}
                                    chartData={activeProjects}
                                    isLoading={isLoading}
                                />
                            )}
                        </div>
                        <div className={classes.col_2}>
                            {_cardsStatus.AutonomousReport && (
                                <WaveChart
                                    tickerIndicatorValue={tickerIndicatorValue}
                                    redirectURL="/executions/reports"
                                    history={history}
                                    isLoading={isLoading}
                                    title="Autonomous Report"
                                    redirectMessage="Go to Autonomous Report"
                                    axisData={{
                                        YKey: null,
                                        YType: 'number',
                                        XKey: 'name',
                                        XType: 'category',
                                        dataFormat: [
                                            {
                                                dataKey: 'Cases',
                                                dataText: 'Cases',
                                                stroke: '#3B91DF',
                                            },
                                            {
                                                dataKey: 'AutoHeal',
                                                dataText: 'Auto heal',
                                                stroke: '#F39B31',
                                            },
                                        ],
                                    }}
                                    chartData={autonomousReport}
                                />
                            )}
                            {_cardsStatus.TopFail && (
                                <BarChart
                                    tickerIndicatorValue={tickerIndicatorValue}
                                    redirectURL="/analyze/alertdetails/"
                                    history={history}
                                    isLoading={isLoading}
                                    title="Top 5 Failures"
                                    noDataMessage="No Failure Data Available"
                                    redirectMessage="Go to Alerts"
                                    axisData={{
                                        YKey: 'name',
                                        XKey: 'count',
                                        YType: 'category',
                                        XType: 'number',
                                        dataKey: 'count',
                                        barColor: '#E8001C',
                                    }}
                                    tooltipData={[
                                        { key: 'Project', valueKey: 'originalName' },
                                        { key: 'Count', valueKey: 'count' },
                                    ]}
                                    chartData={failureProjects}
                                    className={classes.width50sm}
                                />
                            )}
                            {_cardsStatus.ActiveTestCase && (
                                <BarChart
                                    tickerIndicatorValue={tickerIndicatorValue}
                                    redirectURL="/plan/testcases"
                                    history={history}
                                    title="Active Test Cases"
                                    noDataMessage="No TestCase Data Available"
                                    redirectMessage="Go to Test Cases"
                                    axisData={{
                                        YKey: 'name',
                                        XKey: 'count',
                                        YType: 'category',
                                        XType: 'number',
                                        dataKey: 'count',
                                        barColor: '#F39B31',
                                    }}
                                    tooltipData={[
                                        { key: 'Test Case', valueKey: 'originalName' },
                                        { key: 'Count', valueKey: 'count' },
                                    ]}
                                    isLoading={isLoading}
                                    chartData={activeTestCases}
                                    className={classes.width50smLast}
                                />
                            )}
                        </div>
                    </div>
                </div>
                {settingDialogOpen ? (
                    <DashboardSettingsModal
                        handleClose={this.handleCloseSettingDialog}
                        changeHeaderStatus={this.changeHeaderStatus}
                        open={settingDialogOpen}
                        headers={headers}
                    />
                ) : null}
            </div>
        );
    }
}

Dashboard.propTypes = {
    classes: PropTypes.shape({}).isRequired,
    user: PropTypes.shape({}),
};

Dashboard.defaultProps = {
    user: {},
};

const mapStateToProps = (state) => {
    return {
        user: state.authReducer.user,
        tickerIndicatorValue: state.dashboardReducer.tickerIndicatorValue,
        dashboardData: state.dashboardReducer.dashboardData,
        isLoading: state.dashboardReducer.isLoading,
        settingDialogOpen: state.dashboardReducer.settingDialogOpen,
        prefences: state.accountReducer.prefences,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        getDashboardInfo: (tickerIndicatorValue) => dispatch(DashboardActions.getDashboardInfo(tickerIndicatorValue)),
        toggleDashboardSettingGear: (...args) => dispatch(DashboardActions.toggleDashboardSettingGear(...args)),
        UpdateDashboardCards: (id, data) => dispatch(AccountActions.UpdateDashboardCards(id, data)),
        getAccountsPrefrences: (id) => dispatch(AccountActions.getAccountsPrefrences(id)),
        getPermissions: () => dispatch(AccountActions.getPermissions()),
        updateTitle: (...args) => dispatch(GeneralActions.updateTitle(...args)),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(Dashboard));
