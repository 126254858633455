import { Typography, CircularProgress } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import React from 'react';
import { saveAs } from 'file-saver/FileSaver';
import { connect } from 'react-redux';

import { checkObject, checkKeyInObject, getParamValues, projectDetailTabsOrder, renderFileModal } from '../../utils/utils';
import { GeneralActions, ModalActions, ProjectActions, TaskAction, TagActions } from '../../store/actions';
import { ProjectDetailsFlow } from './Flow';
import CaseTable from './Case';
import ProjectDetailTopBar from './ProjectDetailTopBar_v2';
import ProjectScriptList from './Script';
import projectService from '../../services/projectServices';
import { styles } from './styles';
import SuiteList from './Suite';
import { TestDataDetails } from './Data';
import VariableList from './Variable';
import JSONTOCsv from '../../services/JSONToCsv';
import ProjectDetailsHelper from './projectDetailsHelper';
import NoProject from './NoProject';
import { ProjectUtils } from '../../utils/ProjectUtils';
import singleFileCreationService from '../../services/singleFileCreationService';

class ProjectDetails extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            selectedTab: 0,
            dropZoneError: '',
            showLoader: false,
            isClearData: false,
        };

        this.projectId = null;
        this.testCaseOrDataIsLoading = false;
        this.isProjectChanged = true; // if its true only then we call testCases and testData
        this.update_child_testCaseOrDataIsLoading = () => {};
        this.props.updateTitle('Project Details');
    }

    UNSAFE_componentWillMount() {
        if (checkObject(this.props.projectDetailsState)) {
            this.setState((prevState) => ({
                ...prevState,
                ...this.props.projectDetailsState,
            }));
        }
    }

    componentDidMount() {
        this.onDidMount();
    }

    UNSAFE_componentWillReceiveProps(nextProps) {
        const {
            match: { params },
        } = nextProps;
        this.gotoTab();
        if (params) {
            const { projectId } = params;
            if (`${this.projectId}` !== `${projectId}`) {
                this.projectId = projectId;
                this.isProjectChanged = true;
            }
        }

        if (!checkObject(this.props.projectDetailsState) && !this.state.isClearData) {
            this.setState({ isClearData: true });
        }
    }

    componentWillUnmount() {
        this.isProjectChanged = true;
        this.props.updateProjectId(null);
        this.props.updateTestCaseId(null);
        this.props.clearSnackBar();
    }

    onDidMount = () => {
        const {
            project,
            match: { params },
            updateProjectId,
            getExecutionTasks,
        } = this.props;
        if (params) {
            const { projectId } = params;
            if (`${this.projectId}` !== `${projectId}`) {
                this.isProjectChanged = true;
            }
            this.projectId = projectId;
            const tabName = getParamValues(2);
            if (!tabName) {
                this.redirectToCase();
            }
            if (this.projectId) {
                this.props.updateOpenProject(this.projectId);
                this.props.getProjectDetails(this.projectId);
                this.props.getTags(this.projectId);
            }
            if (project && Object.keys(project).length > 0 && `${this.projectId}` === `${project.projectId}` && this.isProjectChanged) {
                // If projectId in url is same as selectedProject id
                this.testCaseOrDataIsLoading = true;
                this.isProjectChanged = false;
                this.update_child_testCaseOrDataIsLoading(true);
                this.getTestCasesAndData(this.projectId, true);
            }
            setTimeout(() => {
                updateProjectId(this.projectId);
                getExecutionTasks(this.projectId, 0, -1, 1, false, null, false);
            }, 110);
        }
    };

    getTestCasesAndData = async (projectId, projectChange = false) => {
        const { getTestCases, getTestData } = this.props;
        if (projectId && projectChange) {
            await getTestCases(projectId, () => getTestData(projectId, false));
        }
    };

    redirectToCase = () => {
        const {
            match: { params },
            history,
        } = this.props;
        if (params) {
            const { projectId } = params;
            history.push(`/details/${projectId}/cases`);
        }
    };

    handleHelperCallback = (cb) => {
        this.update_child_testCaseOrDataIsLoading = cb;
    };

    update_testCaseOrDataIsLoading = (v) => {
        this.testCaseOrDataIsLoading = v;
    };

    update_isProjectChanged = (v) => {
        this.isProjectChanged = v;
    };

    gotoTab = () => {
        const tabName = getParamValues(2);
        if (!tabName) {
            this.redirectToCase();
        } else {
            const selectedTab = projectDetailTabsOrder[tabName] ? projectDetailTabsOrder[tabName] : 0;
            this.setState({ selectedTab });
        }
    };

    handleError = () => {
        this.setState({ dropZoneError: false });
    };

    previewTestCase = async (testCase) => {
        const { testCaseId, testCaseName } = testCase;
        const { toggleModal } = this.props;
        const testCasePreview = await projectService.previewTestCase(testCaseId, this.props.showSnackBar);
        const downloadData = testCase;

        if (testCasePreview) {
            const obj = {
                id: testCaseId,
                data: testCasePreview,
                name: testCaseName,
                APIData: testCaseName,
                fileName: downloadData,
                modalName: 'CaseModal',
                handleDownload: this.handleDownloadTestCase,
            };
            renderFileModal(toggleModal, obj);
        }
    };

    previewTestData = async (testDataId, testDataName, modalType = 'view') => {
        const { toggleModal, project: { projectId } } = this.props;
        let allowFiles = ['xls', 'xlsx', 'csv'];
        // if testData is zip file download
        let fileExtension = testDataName && testDataName.split('.').pop().toLowerCase();
        if (allowFiles.indexOf(fileExtension) === -1 && modalType === 'view') {
            this.handleDownloadTestData(testDataId, testDataName);
            return;
        }
        allowFiles = ['xls', 'csv'];
        const testDataNameForDownloadFile = allowFiles.indexOf(fileExtension) >= 0 ? `${testDataName}.xlsx` : testDataName;
        const testDataPreview = await projectService.previewTestData(testDataId, this.props.showSnackBar, modalType);
        let keys = [];
        let data = {};
        if (checkKeyInObject(testDataPreview, 'data') && checkKeyInObject(testDataPreview, 'order') && testDataPreview.data && testDataPreview.order) {
            data = typeof testDataPreview.data === "object" ? testDataPreview.data : JSON.parse(testDataPreview.data);
            keys = typeof testDataPreview.order === "object" ? testDataPreview.order : JSON.parse(testDataPreview.order);
        }
        for (let val = 0; val < keys.length; val++){
            for (let strVal = 0; strVal < data[keys[val]].length; strVal++){
                data[keys[val]][strVal] = data[keys[val]][strVal].replace(/\r?\n|\r/g,' ')
            }
        }
        if (testDataPreview) {
            if (modalType === 'view' || modalType === 'edit') {
                toggleModal('filePreviewModal', null, null, {
                    id: testDataId,
                    APIData: testDataId,
                    data: {
                        data: JSON.stringify(data),
                        order: JSON.stringify(keys)
                    },
                    name: testDataName,
                    projectId: projectId,
                    modalName: modalType === 'view' ? 'DataModal' : 'DataModalEdit',
                    fileName: testDataNameForDownloadFile,
                    handleDownload: modalType === 'view' ? this.handleDownloadTestData : this.handleUpdateData,
                    handleUpdate: this.handleUpdateData,
                    modalType: modalType,
                });
            }
        }
    };

    previewTestScript = async (testScriptId, testScriptName, cb = () => {}, cbForClearURL = () => {}) => {
        const { toggleModal } = this.props;
        this.setState({ showLoader: true });
        const testScriptPreview = await projectService.previewTestScript(testScriptId, this.props.showSnackBar);
        this.setState({ showLoader: false });
        if (testScriptPreview) {
            toggleModal('filePreviewModal', null, null, {
                id: testScriptId,
                APIData: testScriptId,
                data: testScriptPreview,
                name: testScriptName,
                modalName: 'ScriptModal',
                fileName: testScriptName,
                handleDownload: this.handleDownloadTestScript,
            });
            cb();
        } else {
            cbForClearURL();
        }
    };

    handleDownloadTestCase = async (fileName, Steps, Type, fileType = 'xlsx') => {
        if (fileType === 'csv') {
            try {
                const testSteps = Type === 'Live Steps' ? singleFileCreationService.sort(Steps) : Steps;
                const header = {
                    'Test Steps': 'Test Steps',
                    Data: 'Data',
                    'Expected Result': 'Expected Result',
                };
                const data = testSteps.map((step) => {
                    return {
                        'Test Steps': `"${step.instr.replace(/["]{1}/g, '""')}"`,
                        Data: `"${step.data.replace(/["]{1}/g, '""')}"`,
                        'Expected Result': `"${(step.expectedResults || '').replace(/["]{1}/g, '""')}"`,
                    };
                });
                JSONTOCsv.CSVFile(header, data, `${fileName}`);
            } catch (error) {
                console.error(error);
            }
        } else {
            const data = await singleFileCreationService.getCaseFile(Steps, Type, fileName);
            if (data) {
                const blob = new Blob([data], {
                    type: 'application/octet-stream',
                });
                saveAs(blob, `${fileName}.xlsx`);
            } else if (!data) {
                console.error('FAILED TO DOWNLOAD XLSX FILE');
            } else {
                console.error('FAILED TO DOWNLOAD XLSX FILE');
            }
        }
    };

    handleStoreState = () => {
        this.props.storeProjectDetailsState({ selectedTab: this.state.selectedTab });
    };

    handleDownloadTestData = (testDataId, testDataName) => {
        singleFileCreationService.downloadArtifact({ testDataId, testDataName });
    };

    handleUpdateData = (projectId, testDataId, updatedData, onComplete) => {
        const { toggleModal } = this.props;
        const newData = [];
        const _updatedData = { data: [], order: [] };
        try {
            if (checkKeyInObject(updatedData, 'data', 'bool')) {
                _updatedData.data = JSON.parse(updatedData.data);
            }
            if (checkKeyInObject(updatedData, 'order', 'bool')) {
                _updatedData.order = JSON.parse(updatedData.order);
            }
            if (checkKeyInObject(_updatedData, 'order', 'value', []).length) {
                // find length of all arrays
                const lengths = Object.values(_updatedData.data).map((a) => a.length);
                // gives max array length
                const maxLength = Math.max(...lengths);

                // add empty string "" if any array length is less than max length
                _updatedData.order.forEach((key) => {
                    if (_updatedData.data[key].length < maxLength) {
                        for (let i = _updatedData.data[key].length; i < maxLength; i++) {
                            _updatedData.data[key][i] = '';
                        }
                    }
                    let enterData = JSON.parse(JSON.stringify(_updatedData))
                    while (enterData.data[key].pop() === '' ) {
                        _updatedData.data[key].pop()
                    }
                    newData.push({ key, values: _updatedData.data[key] });
                });
            }
            this.props.handleUpdateData(projectId, testDataId, newData, onComplete || toggleModal);
        } catch (error) {
            console.error(error);
        }
    };

    handleDownloadTestScript = async (testScriptId, testScriptName) => {
        const url = `/testScripts/${testScriptId}/downloadTestScript`;
        const testScriptPreview = await projectService.previewTestScript(testScriptId, this.props.showSnackBar);
        const encodeURL = `data:text/plain;charset=utf-8,${encodeURIComponent(testScriptPreview)}`;
        projectService.downloadTest(url, testScriptName, encodeURL, this.props.showSnackBar);
    };

    downloadGradle = (testScriptId, testScriptName) => {

    };

    gotoDashboard = () => {
        this.props.history.push('/dashboard');
    };

    gotoProject = () => {
        this.props.history.push('/plan/projects');
    };

    // handleNewProjectOpen = () => {
    //     const modalData = { user: this.props.user, updateProject: false };
    //     this.props.toggleModal('updateProject', null, null, modalData);
    // }

    // MODAL FUNCTIONS STARTED

    toggleModal = (modalName, route, videoUrl, modalData = null) => {
        this.props.toggleModal(modalName, route, videoUrl, modalData);
    };
    // MODAL FUNCTIONS ENDED

    renderTab = () => {
        const { selectedTab, showLoader } = this.state;
        const { classes, project, history, location } = this.props;

        if (project.projectId) {
            switch (selectedTab) {
                case 0:
                    return (
                        <CaseTable
                            history={this.props.history}
                            match={this.props.match}
                            previewTestCase={this.previewTestCase}
                            previewTestData={this.previewTestData}
                            previewTestScript={this.previewTestScript}
                            handleDownloadTestCase={this.handleDownloadTestCase}
                            showLoader={showLoader}
                        />
                    );
                case 1:
                    return (
                        <Typography component="div" className={classes.typographyStyle}>
                            <SuiteList
                                appUrl={project.appUrl || ''}
                                date={new Date()}
                                history={this.props.history}
                                project={project}
                                showLoader={showLoader}
                                // @params modalName:string, route:string, url:string, modalData:any
                                toggleModal={this.toggleModal}
                                handleStoreState={this.handleStoreState}
                                isClearData={this.state.isClearData}
                            />
                        </Typography>
                    );
                case 2:
                    return (
                        <div className={classes.typographyStyle}>
                            <TestDataDetails
                                appUrl={project.appUrl || ''}
                                badgeName="Data File"
                                match={this.props.match}
                                date={new Date()}
                                project={project}
                                toggleModal={this.toggleModal}
                                previewTestData={this.previewTestData}
                                history={history}
                                location={location}
                            />
                        </div>
                    );
                case 3:
                    return (
                        <div className={classes.typographyStyle}>
                            <ProjectScriptList
                                // ///////////////open commit after implement or get Testcases Data//////////////////
                                appUrl={project.appUrl || ''}
                                showLoader={showLoader}
                                badgeName="Script"
                                date={new Date()}
                                project={project}
                                previewTestCase={this.previewTestCase}
                                previewTestData={this.previewTestData}
                                previewTestScript={this.previewTestScript}
                                downloadGradle={this.downloadGradle}
                                showSnackBar={this.props.showSnackBar}
                                history={history}
                            />
                        </div>
                    );
                case 4:
                    return (
                        <div className={classes.typographyStyle}>
                            <VariableList
                                appUrl={project.appUrl || ''}
                                badgeName="Variables"
                                project={project}
                                date={new Date()}
                                toggleModal={this.props.toggleModal}
                            />
                        </div>
                    );
                case 5:
                    return (
                        <div className={classes.typographyStyle}>
                            <ProjectDetailsFlow
                                project={project}
                                appUrl={project.appUrl || ''}
                                showLoader={showLoader}
                                badgeName="Flows"
                                date={new Date(project.creationTime)}
                            />
                        </div>
                    );
                default:
                    return null;
            }
        }
        return null;
    };

    render() {
        const { classes, isLoading, project } = this.props;
        let content = null;
        if (isLoading && !checkObject(project)) {
            content = (
                <div className={classes.noProjectContainer}>
                    <CircularProgress style={{ margin: '100px 50% 0px', color: '#4885ed' }} size={60} />
                </div>
            );
        } else if (checkObject(project)) {
            content = (
                <div>
                    <ProjectDetailTopBar history={this.props.history} nextPrevFunc={this.onDidMount}  project={project} />
                    <div className={classes.tabWrapper}>
                        
                        {this.renderTab()}
                    </div>
                </div>
            );
        } else {
            content = (
                <div>
                    <div className={classes.piImage}>
                        <svg width="75px" height="75px" viewBox="0 0 75 75" version="1.1">
                            <g
                                id="Plan:-Projects"
                                stroke="none"
                                strokeWidth="1"
                                fill="none"
                                fillRule="evenodd"
                                fontFamily="simple-line-icons"
                                fontSize="72"
                                fontWeight="normal"
                                className="noProjectFont"
                            >
                                <g id="Plan:-Projects-no-Project" transform="translate(-680.000000, -159.000000)" fill="#8898A8">
                                    <g id="Main-Panel" transform="translate(240.000000, 45.000000)">
                                        <g id="Project-does-not-exist-" transform="translate(310.000000, 114.000000)">
                                            <text id="pie-chart---simple-line-icons">
                                                <tspan x="130.5" y="68" className="noProjectFont">
                                                    
                                                </tspan>
                                            </text>
                                        </g>
                                    </g>
                                </g>
                            </g>
                        </svg>
                    </div>
                    <NoProject
                        handleNewProjectOpen={() => ProjectUtils.toggleUpdateModal(false, null, null, this.props.history)}
                        gotoDashboard={this.gotoDashboard}
                        gotoProject={this.gotoProject}
                    />
                </div>
            );
        }

        return (
            <div className={classes.gridContainer}>
                {content}
                <ProjectDetailsHelper
                    match={this.props.match}
                    thisProjectId={this.projectId}
                    thisTestCaseOrDataIsLoading={this.testCaseOrDataIsLoading}
                    update_testCaseOrDataIsLoading={this.update_testCaseOrDataIsLoading}
                    update_isProjectChanged={this.update_isProjectChanged}
                    handleHelperCallback={this.handleHelperCallback}
                    getTestCasesAndData={this.getTestCasesAndData}
                    isProjectChanged={this.isProjectChanged}
                />
            </div>
        );
    }
}

ProjectDetails.propTypes = {
    classes: PropTypes.shape({}).isRequired,
    toggleModal: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => {
    return {
        // project Reducer
        projectDetailsState: state.projectReducer.projectDetailsState,
        isLoading: state.projectReducer.isLoading,
        project: state.projectReducer.selectedProject,
        // auth Reducer
        user: state.authReducer.user,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        toggleModal: (...args) => dispatch(ModalActions.toggleModal(...args)),
        showSnackBar: (...args) => dispatch(ModalActions.toggleSnackBar(...args)),
        clearSnackBar: () => dispatch(ModalActions.clearSnackbar()),
        // Need to remove this when api provided by platform
        getExecutionTasks: (...args) => dispatch(ProjectActions.getExecutionTasks(...args)),
        handleUpdateData: (...args) => dispatch(ProjectActions.handleUpdateData(...args)),
        updateOpenProject: (projectId) => dispatch(ProjectActions.updateOpenProject(projectId)),
        getProjectDetails: (projectId) => dispatch(ProjectActions.getProjectDetails(projectId)),
        getTestCases: (...args) => dispatch(ProjectActions.getTestCases(...args)),
        getTestData: (...args) => dispatch(ProjectActions.getTestData(...args)),
        updateProjectId: (...args) => dispatch(TaskAction.updateProjectId(...args)),
        updateTestCaseId: (...args) => dispatch(TaskAction.updateTestCaseId(...args)),
        getTags: () => dispatch(TagActions.getTags()),
        storeProjectDetailsState: (states) => dispatch(ProjectActions.storeProjectDetailsState(states)),
        updateTitle: (...args) => dispatch(GeneralActions.updateTitle(...args)),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(ProjectDetails));
