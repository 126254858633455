import { MODAL_HEADER_TITLE, DIALOG_ROOT, BLACK_FONT,THREE_DOT_MENU_CSS } from '../cssConstants';

  export const styles = (theme) => ({
    
    
    tabsContainer: {
        width: 700,
        zIndex: 1300,
    },
    tabs:{
        '& > div > span':{
            backgroundColor: "#3B91DF"
        }
    },
    textFieldLabelCustom: {
        fontSize: 12,
        fontWeight:'500',
        color: '#000000',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap',
    },
    textFieldInner: {
        fontSize: 12,
        color: 'rgba(56, 55, 55, 0.58)',
        fontWeight: 500,
        margin: '5px 0px',
    },
    textFieldCustom: {
        width: 'calc(100% - 30px)',
        borderBottom: '1px solid #979797',
    },
    textFieldBody: {
        display: 'flex',
        alignItems: 'center',
        cursor: 'pointer',
        justifyContent: 'space-between',
        width: 'calc(100% - 30px)',
        borderBottom: '1px solid #979797',
    },
    sauceExecution:{
        overflow: "hidden",
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap',
    },
    textFieldIcon: {
        color: '#3B91DF',
        fontSize: 20,
        marginLeft: 10,
    },
    textFieldLabel: {
        color: BLACK_FONT,
        fontWeight: '400',
        fontSize: 12,
        marginTop: 1,
    },
    URLLabel: {
        color: 'rgba(56, 55, 55, 0.58)',
        fontWeight: '400',
        fontSize: 12,
        paddingTop: '10px',
        width: '14%',
    },
    URLInput: {
        marginTop: '0px',
        marginBottom: '14px',
        '& > p':{
            color: 'red',
            fontSize: '10px',
            marginLeft: 0
        },
        '&>:first-child': {
            border: 'solid 0.5px #e9e9e9',
            backgroundColor: '#f8f8f8',
            padding: '2px',
            fontSize: '12px',
            fontWeight: 500,
            width: 545,
        },
    },
    versionListContainer: {
        display: 'flex',
        flexWrap: 'wrap',
        flexDirection: 'row',
        maxHeight: '145px',
        overflowY: 'auto',
        width: '100%',
    },
    versionListText: {
        borderRadius: 7,
        cursor: 'pointer',
        margin: '5px',
        maxHeight: 40,
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        padding: '10px 8px',
        whiteSpace: 'nowrap',
        '&:hover': {
            backgroundColor: '#bbbbbb',
        },
    },
    versionListTextForBrowser: {
        width: '14%',
        textAlign: 'center',
    },
    versionListTextForPlatform: {
        width: '18%',
        textAlign: 'left',
    },
    width100P: {
        width: '100%',
    },
    width50P: {
        width: '50%',
    },
    wrapper: {
        display: 'flex',
        flexDirection: 'row',
        '&>svg': {
            marginRight: 4
        }
    },
    actionIconsContainer: {
        display: 'flex',
        alignItems: 'flex-end',
        justifyContent: 'flex-end',
        width: 50,
        marginRight: 30,
    },
    iconButtonRoot: {
        fontSize: '1.0rem',
        height: 25,
        width: 25,
        marginLeft: 20
    },
    actionIcons: {
        fontSize: 16,
        color: '#4a4a4a',
        cursor: 'pointer',
    },
    actionButtonsContainer : {
        display: 'flex',
        alignItems: 'flex-end',
        justifyContent: 'flex-end',
        marginRight: 30
    },
    removeActionButton: {
        color:'rgb(255, 66, 50)',
        marginTop:'12px',
        textTransform:'none'
    },
    addActionButton: {
        color:'rgb(45, 166, 234)',
        marginTop:'12px',
        textTransform:'none'
    },
    environmentSelect: {
        width: '45%',
        '&>div': {
            '&>select': {
                color: 'rgba(56, 55, 55, 0.58)',
                fontSize: 12,
                fontWeight: '500'
            },
        }
    },
    environmentPopper: {
        display: 'flex',
        // width: '45%',
        borderBottom: '1px solid #979797',
        alignItems: 'center',
        justifyContent: 'space-between',
    },
    disabledEnvironmentPopper: {
        display: 'flex',
        opacity: '0.8',
        pointerEvents: 'none',
        borderBottom: '1px solid #979797',
        alignItems: 'center',
        justifyContent: 'space-between',
        '&>p': {
            whiteSpace: 'nowrap',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
        }
    },
    radioGroup: {
        marginLeft: 10,
        display: 'flex',
        flexDirection: 'row',
        flexWrap: 'noWrap',
        "& svg": {
            width: "20px",
            height: "20px"
          }
    },
    radioLabel: {
        width: '50%',
        '&>:nth-child(2)': {
            fontSize: 12,
            color: '#636363',
            marginLeft: 5
        }
    },
    headingDetails:{
        //paddingLeft: '20px',
        fontWeight: '500',
        color: '#494949',
        fontSize: '12px',
    },
    addIcon: {
        fontSize: 21,
        color: '#1168cd',
        cursor: 'pointer',
    },
    disableAddBtn:{
        fontSize: 21,
        color: '#9B9B9B',
        pointerEvents: 'none',
    },
    stepContainer: {
        position: 'relative',
        marginRight: 50,
        cursor: 'pointer'
    },
    stepContainerDisabled: {
        position: 'relative',
        marginRight: 50,
        cursor: 'not-allowed',
    },
    visibleTab:{
        display:'none',
    },
    disabled: {
        color: 'rgba(56, 55, 55, 0.58)',
        opacity: '0.8',
        margin: '5px 0px',
        fontSize: '12px',
        fontWeight: '500',
    },
    button:{
        backgroundColor: '#1168cd',
        color: '#FFF',
        '&:hover':{
            backgroundColor: 'rgb(11, 72, 143)'
        }
    },
    headingOverview: {
        fontSize: 18,
        marginTop: 7
    },
    headingStyle: {
        fontWeight: 'bold',
        fontSize: 12,
        padding: '7px 0',
    },
    noSelectionStyle: {
        fontSize: 12,
        color: '#9B9B9B',
        lineHeight: '20px',
    },
    selectionStyle: {
        fontSize: 12,
        lineHeight: '20px',
    },
    platformBrowserselection: {
        margin: '8px 0',
        display: 'flex',
        alignItems: 'center',
    },
    content: {
        // padding: '10px 10px 25px 10px',
        overflowY: 'auto',
        overflowX: 'hidden',
        '&::-webkit-scrollbar': {
            width: '10px'
          },
          '&::-webkit-scrollbar-thumb': {
            backgroundColor: 'rgba(193,193,193)',
          }
    }, 
    rightContainerStyle: {
        marginRight: '-25px',
        marginTop: '-91px',
        overflow: 'hidden',
    },
    btnContainer: {
        borderLeft: '2px solid #ECECEC',
        height: 125,
    },
    moreIconsBtn: {
        color: THREE_DOT_MENU_CSS.iconColor,
        marginRight: '4px',
    },
    faIcons: {
        fontSize: 17,
        marginTop: -3,
    },
    noDetail: {
        textAlign: 'center',
        fontSize: 12,
        backgroundColor: '#F5F5F5',
        padding: 25,
        marginRight: 30,
    },
    sauceTypePlatform: {
        width: '22%', 
        marginBottom: 20,
        [theme.breakpoints.down('md')]: {
            width: '26%', 
        },
    },
    sauceTypeNotPlatform: {
        width: '22%', 
        marginBottom: 20, 
        marginLeft: 60,
        [theme.breakpoints.down('md')]: {
            width: '26%', 
            marginLeft: 25,
        },
    },
    device_selector_root: {
        display:'flex',
        flexDirection:'column',
        width:'100%'
    },
    device_selector_body:{
        position: "absolute", 
        top: 178, 
        zIndex: 1 
    },
    device_selector_options_container :{

      //  display: 'none',
        width:'fit-content',
        flexDirection: 'column',
        alignItems: 'flex-start',
        padding:'0px 18px 4px 18px', 
        background: '#FFFFFF',
        boxShadow: '0px 2px 6px rgba(0, 0, 0, 0.14)',
        borderRadius: '6px'
    },
    
    device_name: {
        fontFamily: 'Roboto',
        fontWeight: 400,
        fontSize: 14,
        color: '#9B9B9B',
        marginTop:12
    },
    beta:{
        color: '#FFFFFFDE',
        backgroundColor: "#FF980E",
        borderRadius: '15px',
        marginLeft: 3,
        width: 40,
        height: 20,
        display: 'flex',
        justifyContent: "center",
        fontSize: 12,
        fontWeight: 600
    },
    browser_details_name_contianer: {

        fontFamily: 'Proxima Nova',
        fontWeight: 700,
        fontSize: 14,
        color: '#605F5F',
        display:'flex'
        
    },

    browser_name: {
        marginLeft:8
    },


    browser_details_root: {
        marginTop: 10,
        lineHeight:'30px'
    },
    device_details_root: {
        marginTop: 22,
        marginLeft: -10
    },

    browser_versions_root: {
        display:'flex', 
    },
    device_list_root: {
        display:'flex', 
        flexDirection:'column',
    },

    browser_version_container: {
        cursor:'pointer',
        paddingLeft: 6,
        paddingRight: 6,
        marginRight: 12
    },

    browser_version_container: {
        cursor:'pointer',
        paddingLeft: 8,
        paddingRight: 8,
        marginRight: 14

    },

    

    browser_version_name: {

        fontFamily: 'Roboto',
        fontWeight: 400,
        fontSize: 12,
        borderRadius:'2px',
        padding:'0px 8px 0px 8px',
        textAlign: 'center',
        color: 'rgba(0, 0, 0, 0.87)',
        '&:hover': {
            backgroundColor: '#F4F3F4',
        },
    },

    device_details_name_container: {

        fontFamily: 'Proxima Nova',
        fontWeight: 700,
        fontSize: 14,
        color: '#605F5F',
        display:'flex'
    },

    device_list_container: {
        display:'flex'
    },

    device_list_name: {
        display: "flex",
        alignItems: "center",
        marginBottom: 11,
        fontFamily: 'Roboto',
        fontWeight: 400,
        fontSize: 12,
        borderRadius:'2px',
        padding:'0px 10px 0px 10px',
        color: 'rgba(0, 0, 0, 0.87)',
        cursor:'pointer',
        '&:hover': {
            backgroundColor: '#F4F3F4',
        },
    },
    selected_device_list_name:{
        display: "flex",
        alignItems: "center",
        marginBottom: 11,
        fontFamily: 'Roboto',
        display:'flex',
        fontWeight: 400,
        fontSize: 12,
        borderRadius:'2px',
        padding:'0px 10px 0px 10px',
        color: 'rgba(0, 0, 0, 0.87)',
        backgroundColor: '#F4F3F4',
        cursor:'pointer'
    },
    selected_browser_version_name : {
        fontFamily: 'Roboto',
        fontWeight: 400,
        fontSize: 12,
        borderRadius:'2px',
        padding:'0px 8px 0px 8px',
        textAlign: 'center',
        color: 'rgba(0, 0, 0, 0.87)',
        backgroundColor: '#F4F3F4'
    },
    device_name_container: {
        fontWeight:400,
        display:'flex',
        fontSize:14,
        alignItems:'center'
    }
});

export default styles