import React from 'react';

// import materials
import { withStyles } from '@material-ui/core/styles';
import ArrowForward from '@material-ui/icons/ArrowForward';
import History from '@material-ui/icons/History';
import { BLACK_FONT, NO_DECORATION } from '../../../common/cssConstants';

const CardFooter = ({ classes, tickerIndicatorValue, redirectMessage }) => {
    return (
        <span className={classes.timespanIndicator}>
            <span className={classes.redirectContainer}>
                <History className={classes.historyIcon} aria-label="historyIcon" id="historyIcon" />
                <span className={classes.footerText}>{tickerIndicatorValue}</span>
            </span>
            <span>
                {redirectMessage ? (
                    <span className={classes.redirectContainer}>
                        <span className={classes.footerText}>{redirectMessage}</span>
                        <ArrowForward className={classes.arrowIcon} aria-label="arrowForwardIcon" id="arrowForwardIcon" />
                    </span>
                ) : null}
            </span>
        </span>
    );
};

const styles = () => ({
    timespanIndicator: {
        minHeight: '30px',
        backgroundColor: '#F4F6F7',
        borderBottomLeftRadius: '6px',
        borderBottomRightRadius: '6px',
        color: '#9B9B9B',
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        paddingLeft: 13,
    },
    redirectContainer: {
        display: 'flex',
        alignItems: 'center',
        cursor: 'pointer',
    },
    historyIcon: {
        fontSize: '18px',
        marginRight: '5px',
        color: '#4a4a4a',
    },
    arrowIcon: {
        fontSize: '18px',
        marginRight: '5px',
        marginLeft: '5px',
        color: '#4a4a4a',
    },
    footerText: {
        fontSize: '10px',
        color: BLACK_FONT,
    },
    noDecoration: NO_DECORATION,
});

export default withStyles(styles)(CardFooter);
