import PropTypes from 'prop-types';
import React , { useState } from 'react';
import { withStyles } from '@material-ui/core/styles';
import { Box } from '@material-ui/core';
import { styles } from './Styles';
import PlatformBrowserSelectorHeader from './PlatformBrowserSelectorHeader';
import PlatformBrowserSelectorBody from './PlatformBrowserSelectorBody';

const PlatformBrowserSelector = (props) => {
    const {
        callingFrom,
        classes,
        isPlatformBrowserSelectorOpen,
        title,
        selected,
        data,
        onChange
    } = props;

    const [isDeviceSelectorOpen, setIsDeviceSelectorOpen] = useState(false);

    const handleClickAway = () => {
        setIsDeviceSelectorOpen(false);
    }

    return (
        <Box className={classes.device_selector_root}>
            <PlatformBrowserSelectorHeader callingFrom={callingFrom} title={title} selected={selected} isDeviceSelectorOpen={isDeviceSelectorOpen} onClick={()=>{setIsDeviceSelectorOpen(!isDeviceSelectorOpen)}}/>
            <PlatformBrowserSelectorBody callingFrom={callingFrom} selected={selected} data={data} handleClickAway={handleClickAway} isDeviceSelectorOpen={isDeviceSelectorOpen} onDeviceSelected={(data)=>{setIsDeviceSelectorOpen(false);onChange(data)}}/>
        </Box>  
    );
};

PlatformBrowserSelector.propTypes = {
    classes: PropTypes.shape({}).isRequired,
    title: PropTypes.string.isRequired,
    selected:PropTypes.object,
    onClickDeivceSelector:PropTypes.func,
    data:PropTypes.shape([]).isRequired,
    onChange:PropTypes.func,
    isPlatformBrowserSelectorOpen:PropTypes.bool
    
};

PlatformBrowserSelector.defaultProps = {
    title:'Select Device',
    selected:{
        id:'',
        label:''
    },
    onClickDeivceSelector:()=>{},
    data:[],
    onChange:()=>{},
    isPlatformBrowserSelectorOpen:false
}


export default withStyles(styles)(PlatformBrowserSelector);