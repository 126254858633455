/* eslint-disable import/no-dynamic-require */
import LOGO from '../assets/images/favicon-96x96.png'
import LOGO_LARGE from '../assets/images/Auto_Big_Q.svg';
import DASHBOARD_LOGO from '../assets/images/AIQ_SL.png';
import VIDEO_IMAGE from '../assets/images/Autonomiq_videoImage.png';
import LOGIN_PAGE_BRAND_LOGO from '../assets/images/Login_Page_Autonomiq_Logo.svg';
const staticConfig = window.__PRELOADED_STATE__ || {};
delete window.__PRELOADED_STATE__;
let logoUrl = ''; let videoImageUrl = ''; let dashboardImageUrl = ''; let logoLarge = '';
const loginPageLogoUrl = require('../assets/images/Login_Page_Logo.svg')

let loginPageBrandLogoUrl = ''

switch (window._env_.REACT_APP_AIQ_COMPANY_NAME.toLowerCase()) {
    case 'hexaware':        
        logoUrl = require('../assets/images/Hexaware_logo_onSignIn.png')
        videoImageUrl = require('../assets/images/Hexaware_videoImage.png')
        dashboardImageUrl = require('../assets/images/Hexaware_logo_onSignIn.png')
        logoLarge = require('../assets/images/Hexaware_logo_onSignIn.png')
        loginPageBrandLogoUrl = require('../assets/images/Hexaware_logo_onSignIn.png')
        break;
    case 'snowflake':
        logoUrl = require('../assets/images/SF_AIQ_logo.png')
        videoImageUrl = require('../assets/images/SF_AIQ_WebsiteLogo.png')
        dashboardImageUrl = require('../assets/images/SF_AIQ_logo.png')
        logoLarge = require('../assets/images/SF_AIQ_logo.png')
        loginPageBrandLogoUrl = require('../assets/images/SF_AIQ_logo.png')
        break;
    default:
        logoUrl = require('../assets/images/Autonomiq_logo_onSignIn.png')
        videoImageUrl = require('../assets/images/Autonomiq_videoImage.png')
        dashboardImageUrl = require('../assets/images/Autonomiq_logo_onSignIn.png')
        logoLarge = require('../assets/images/Auto_Big_Q.svg')
        loginPageBrandLogoUrl = require('../assets/images/Login_Page_Autonomiq_Logo.svg')
        break;
}

    if(window._env_?.REACT_APP_AIQ_LOGO_URL?.includes('http')){
        logoUrl = window._env_.REACT_APP_AIQ_LOGO_URL;
        loginPageBrandLogoUrl = window._env_.REACT_APP_AIQ_LOGO_URL
    } 
    if(window._env_?.REACT_APP_AIQ_DASHBOARD_IMAGE_URL?.includes('http')){
        dashboardImageUrl =window._env_.REACT_APP_AIQ_DASHBOARD_IMAGE_URL;
    } 
    if(window._env_?.REACT_APP_AIQ_IMAGE_URL?.includes('http')){
        videoImageUrl =window._env_.REACT_APP_AIQ_IMAGE_URL;
    }
    if(window._env_?.REACT_APP_AIQ_LOGO_LARGE?.includes('http')){
        logoLarge = window._env_.REACT_APP_AIQ_LOGO_LARGE
    }


const SuaceDevices = {
    Windows: {
        Windows_10: {
            Chrome: ['91.0', '92.0'],
            Firefox: ['89.0', '90.0'],
            MSEdge: ['90.0', '91.0'],
        },
    },
    MacOS: {
        macOS_11: {
            Chrome: ['91.0', '92.0'],
            Safari: ['11.1', '13.1'],
        }
    },
    Android: {
        Android_Emulator: {
            Chrome: ['91.0', '92.0'],
        },
        Android_Real_Device: {
            Chrome: ['91.0', '92.0'],
        }
    },
    iOS: {
        iOS_Emulator: {
            Safari: ['11.1', '13.1'],
        },
        iOS_Real_Device: {
            Safari: ['11.1', '13.1'],
        }
    },
}

const config = {
    // ...staticConfig,
    // override as required with env vars...
    api: {
        endpoint: window._env_.REACT_APP_AIQ_API_ENDPOINT || (staticConfig.AIQ_API_ENDPOINT || '/platform'),
        source: window._env_.REACT_APP_AIQ_API_SOURCE || (staticConfig.AIQ_API_SOURCE || 'api'),
    },
    usrmgmt: {
        endpoint: window._env_.REACT_APP_AIQ_USRMGMT_ENDPOINT || (staticConfig.AIQ_USRMGMT_ENDPOINT || '/user'),
        source: window._env_.REACT_APP_AIQ_USRMGMT_SOURCE || (staticConfig.AIQ_USRMGMT_SOURCE || 'api'),
    },
    schedule: {
        endpoint: window._env_.REACT_APP_AIQ_SCHEDULE_ENDPOINT || (staticConfig.AIQ_SCHEDULE_ENDPOINT || '/schedules'),
        source: window._env_.REACT_APP_AIQ_SCHEDULE_SOURCE || (staticConfig.AIQ_SCHEDULE_ENDPOINT || 'api'),
    },
    recorder: {
        endpoint: window._env_.REACT_APP_AIQ_RECORDER_ENDPOINT || (staticConfig.AIQ_RECORD_ENDPOINT || '/record'),
        source: window._env_.REACT_APP_AIQ_RECORDER_SOURCE || (staticConfig.AIQ_RECORD_ENDPOINT || 'api'),
    },
    scim: {
        endpoint: window._env_.REACT_APP_AIQ_SCIM_ENDPOINT || (staticConfig.AIQ_SCIM_ENDPOINT || '/aiqscim'),
        source: window._env_.REACT_APP_AIQ_SCIM_SOURCE || (staticConfig.AIQ_SCIM_ENDPOINT || 'api'),
    },
    internal: {
        endpoint: window._env_.REACT_APP_AIQ_INTERNAL_ENDPOINT || (staticConfig.AIQ_INTERNAL_ENDPOINT || '/internal'),
        source: window._env_.REACT_APP_AIQ_INTERNAL_SOURCE || (staticConfig.AIQ_INTERNAL_ENDPOINT || 'api'),
    },
    eventSource: {
        endpoint: window._env_.REACT_APP_AIQ_TWOWAY_ENDPOINT || staticConfig.REACT_APP_AIQ_TWOWAY_ENDPOINT,
        source: window._env_.REACT_APP_AIQ_EVENT_SOURCE || (staticConfig.AIQ_EVENT_SOURCE || 'api'),
    },
    executionEngine: localStorage.getItem('executionEngine')|| window._env_.REACT_APP_AIQ_EXECUTION_ENGINE || 'zalenium',
    customerDeployment: window._env_.REACT_APP_AIQ_CUSTOMER_DEPLOYMENT || 'false',
    windowsBroswer: localStorage.getItem('windowsBrowser') || window._env_.REACT_APP_AIQ_WINDOWS_BROWSER || '',
    companyName: window._env_.REACT_APP_AIQ_COMPANY_NAME || 'AutonomIQ',
    logoUrl: logoUrl || LOGO,
    favicon: logoUrl || 'favicon.ico',
    logoLarge: logoLarge || LOGO_LARGE,
    dashboardImageUrl: dashboardImageUrl || DASHBOARD_LOGO,
    videoImageUrl: videoImageUrl || VIDEO_IMAGE,
    website: 'https://autonomiq.io/',
    logoLayout: window._env_.REACT_APP_AIQ_LOGO_LAYOUT || 'rectangle',
    releaseVersion: window._env_.REACT_APP_AIQ_RELEASE_VERSION || '',
    pluginId: window._env_.REACT_APP_AIQ_PLUGIN_ID || 'gbpjhipoimcbdgocmigniagbdbniikab',
    pluginIdVersion: window._env_.REACT_APP_AIQ_PLUGIN_ID_VERSION || 'N/A',
    sauceDevices: SuaceDevices,
    loginPageLogoUrl,
    loginPageBrandLogoUrl: loginPageBrandLogoUrl || LOGIN_PAGE_BRAND_LOGO
};

export default config;
