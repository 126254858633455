// import packages
import React, { Component } from 'react';
import { connect } from 'react-redux';

// import materials
import { withStyles } from '@material-ui/core/styles';

// import Custom components
import _ from 'lodash';
import CardDashboard from '../Card/CardDashboard';
import ChartFooter from '../../../components/dashboard/ChartFooter';
import CircularCard from '../../../components/ProgressCard/CircularCard';
import ContentLoader from '../../../components/ContentLoader';
import { checkArrayLength, getUserPreference, addUserPreference } from '../../../utils/utils';

class CircularChart extends Component {
    state = {
        progress: 0,
        selectedValues: null,
        fail: 0,
        pass: 0,
    };

    componentDidMount() {
        this.updateProgressStates(this.props);
    }

    UNSAFE_componentWillReceiveProps(nextProps) {
        this.updateProgressStates(nextProps);
    }

    getStrokeColor = (progress) => {
        if (progress <= 25) {
            return '#E8001C';
        }
        if (progress < 75) {
            return '#F39B31';
        }
        return '#00B330';
    };

    updateProgressStates = (nextProps) => {
        if (nextProps.title === 'Test Suites' || nextProps.title === 'Test Cases') {
            this.updateTestCounts(nextProps);
        } else if (nextProps.title === 'Projects') {
            if (checkArrayLength(nextProps.circularChartItems) && nextProps.circularChartItems.length > 1) {
                const selectedProjectsFromPreferences = getUserPreference('selectedDashboardProjects');
                const filteredProjects =
                    checkArrayLength(selectedProjectsFromPreferences) &&
                    selectedProjectsFromPreferences.filter((project) => {
                        return nextProps.circularChartItems.find((_project) => _project.projectId === project.value);
                    });
                this.setState(
                    {
                        selectedValues: filteredProjects,
                    },
                    () => {
                        this.updateProjectCounts(nextProps, filteredProjects);
                    },
                );
            }
        } else {
            this.setState({
                progress: nextProps.progress,
                fail: nextProps.fail,
                pass: nextProps.pass,
            });
        }
    };

    updateTestCounts = (nextProps) => {
        let fail = 0;
        let pass = 0;
        const progress = nextProps.circularChartItems && nextProps.circularChartItems.length ? nextProps.circularChartItems[0].progress : 0;
        nextProps.circularChartItems.forEach((element) => {
            if (element.name.toLowerCase() !== 'all') {
                fail += element.fail ? element.fail : 0;
                pass += element.pass ? element.pass : 0;
            }
        });
        this.setState({ progress, fail, pass });
    };

    updateProjectCounts = (nextProps, selectedProjects) => {
        const selectedValues = checkArrayLength(selectedProjects)
            ? selectedProjects
            : checkArrayLength(nextProps.circularChartItems)
            ? [this.convertToSelectValues(nextProps.circularChartItems)[0]]
            : [];
        const { pass, fail, progress } = this.calcPassFaillProgress(
            selectedValues.filter((element) => element.label !== 'All'),
            nextProps,
        );
        this.setState({ progress, selectedValues, fail, pass });
    };

    convertToSelectValues = (inputArray = []) => {
        let outputArray = [];
        outputArray = inputArray.map((element) => {
            return {
                value: element.projectId,
                label: element.name,
                fail: element.fail ? element.fail : 0,
                pass: element.pass ? element.pass : 0,
                progress: element.progress ? element.progress : 0,
            };
        });
        return outputArray;
    };

    calcPassFaillProgress = (selectedValues, props = this.props) => {
        let progress = 0;
        let fail = 0;
        let pass = 0;
        if (selectedValues && selectedValues.length > 0) {
            fail = selectedValues.map((element) => element.fail).reduce((a, b) => a + b, 0);
            pass = selectedValues.map((element) => element.pass).reduce((a, b) => a + b, 0);
            // let progress = 0;
            let validCases = 0;
            selectedValues.forEach((element) => {
                if (element.fail > 0 || element.pass > 0) {
                    validCases++;
                }
                // progress += element.progress;
            });
            if (validCases > 0) {
                // progress = Math.round(progress / validCases);
                progress = Math.floor((pass / (pass + fail)) * 100);
            }
        } else {
            progress = props.circularChartItems && props.circularChartItems.length ? props.circularChartItems[0].progress : 0;
            // selectedValues = props.circularChartItems && props.circularChartItems.length ? [this.convertToSelectValues(props.circularChartItems)[0]] : null;
            props.circularChartItems.forEach((element) => {
                if (element.name.toLowerCase() !== 'all') {
                    fail += element.fail ? element.fail : 0;
                    pass += element.pass ? element.pass : 0;
                }
            });
        }
        return { progress, fail, pass };
    };

    changeSelection = ($event) => {
        const selectedValues = $event.filter((element) => element.label !== 'All');
        const { pass, fail, progress } = this.calcPassFaillProgress(selectedValues);
        if (
            !_.isEqual(selectedValues, this.state.selectedValues) ||
            !_.isEqual(pass, this.state.pass) ||
            !_.isEqual(fail, this.state.fail) ||
            !_.isEqual(progress, this.state.progress)
        ) {
            this.setState({ progress, selectedValues, fail, pass }, () => {
                addUserPreference('selectedDashboardProjects', [...selectedValues]);
            });
        }
    };

    render() {
        const {
            classes,
            tickerIndicatorValue,
            redirectURL,
            history,
            marginRight,
            title,
            redirectMessage,
            circularChartItems,
            isLoading,
        } = this.props;
        const { selectedValues, pass, fail, progress } = this.state;
        return (
            <CardDashboard
                headerButtons={false}
                title={title}
                changeSelection={this.changeSelection}
                convertedItems={this.convertToSelectValues(circularChartItems)}
                selectedValues={checkArrayLength(selectedValues) ? selectedValues.reverse() : selectedValues}
                tickerIndicatorValue={tickerIndicatorValue}
                redirectMessage={redirectMessage}
                redirectURL={redirectURL}
                history={history}
                style={{
                    width: `calc(${100 / 3}% - ${40 / 3}px)`,
                    marginRight: marginRight ? '20px' : '0',
                }}
            >
                <div className={classes.progressCardupperCont}>
                    <div
                        ref={(element) => {
                            this.CircularHolder = element;
                        }}
                        className={classes.progressCardContainer}
                    >
                        {isLoading ? (
                            <ContentLoader shape="circle" size={134} />
                        ) : (
                            <CircularCard
                                progress={progress}
                                colors={[this.getStrokeColor(progress), '#D8D8D8']}
                                size={134}
                                strokeWidth={15}
                                data={
                                    progress === 0
                                        ? [{ name: 'default', value: 1 }]
                                        : [
                                              { name: 'Passed', value: pass },
                                              { name: 'Failed', value: fail },
                                          ]
                                }
                            />
                        )}
                    </div>
                </div>
                <ChartFooter successCount={pass} failCount={fail} isLoading={isLoading} />
            </CardDashboard>
        );
    }
}

const styles = () => ({
    progressCardContainer: {
        maxWidth: '250px',
        alignItems: 'center',
    },
    progressCardupperCont: {
        display: 'flex',
        justifyContent: 'center',
        marginTop: 20,
    },
});

const mapStateToProps = (state) => {
    return {
        user: state.authReducer.user,
        tickerIndicatorValue: state.dashboardReducer.tickerIndicatorValue,
    };
};

export default connect(mapStateToProps, null)(withStyles(styles)(CircularChart));
