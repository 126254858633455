import { withStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import LeftArrow from '@material-ui/icons/KeyboardArrowLeft';
import RightArrow from '@material-ui/icons/KeyboardArrowRight';
import { faPencilAlt } from '@fortawesome/free-solid-svg-icons';
import Disabled from '@material-ui/icons/NotInterested';
import Export from '@material-ui/icons/Launch';
import { Typography, Button, CircularProgress } from '@material-ui/core';

import { styles } from './styles';
import { getFormatedDate, getParamValues } from '../../utils/utils';
import { THREE_DOT_MENU_CSS, MODAL_CANCEL_BUTTON, MODAL_SUBMIT_BUTTON } from '../../common/cssConstants';
import projectServices from '../../services/projectServices';
import { FlowActions, ModalActions, ProjectActions } from '../../store/actions';
import DeleteAlertModal from '../modal/Delete/DeleteAlertModal';
import { ProjectUtils } from '../../utils/ProjectUtils';
import Tooltip from '../../components/Tooltip';
import { MenuOptions } from '../../components/Button/MenuOptions';

let isNextPrev = '';

class ProjectDetailTopBar extends React.Component {
    constructor() {
        super();
        this.state = {
            prevProject: true,
            nextProject: true,
            deleteDialogOpen: false,
            alertMessage: '',
        };
        this.interval = null;
    }

    componentDidMount() {
        const { projectsByIdAndName, getProjectByIdAndName, selectedProject } = this.props;
        if (projectsByIdAndName && projectsByIdAndName.length && selectedProject) {
            this.setNextPrevProjects(projectsByIdAndName, selectedProject);
        } else {
            getProjectByIdAndName();
        }
    }

    UNSAFE_componentWillReceiveProps(nextProps) {
        const { projectsByIdAndName, selectedProject } = nextProps;
        if (projectsByIdAndName && projectsByIdAndName.length && selectedProject) {
            this.setNextPrevProjects(projectsByIdAndName, selectedProject);
        }
        if (this.props.isLoadingTestData === true && nextProps.isLoadingTestData === false) {
            isNextPrev = '';
        }
    }

    setNextPrevProjects = (projects, selectedProject) => {
        let prev = null;
        let next = null;
        for (let i = 0; i < projects.length; i++) {
            if (`${selectedProject.projectId}` === `${projects[i].projectId}`) {
                prev = i > 0 ? projects[i - 1] : null;
                next = i < projects.length - 1 ? projects[i + 1] : null;
                break;
            }
        }
        this.setState({
            prevProject: prev,
            nextProject: next,
        });
    };

    // handleUpdateProject = async (projectId) => {
    //     const modalData = { project: this.props.project, user: this.props.user, updateProject: true, projectId };
    //     this.props.toggleModal('updateProject', null, null, modalData);
    // };

    handleNewProjectOpen = (event, projectId) => {
        event.stopPropagation();
        ProjectUtils.toggleUpdateModal(true, projectId, this.props.selectedProject);
    };

    confirmDelete = (isConfirm) => {
        if (isConfirm) {
            this.handleDelete();
        } else {
            this.setState({ deleteDialogOpen: false });
        }
    };

    handleOnDelete = (event) => {
        event.stopPropagation();
        this.setState({
            deleteDialogOpen: true,
        });
    };

    handleDelete = async () => {
        const { doDelete, selectedProject } = this.props;
        // If project has any test artefacts then prompt user for confirmation
        // Otherwise just delete it
        if (selectedProject.hasArtifacts && !this.state.alertMessage) {
            // TODO: remove this and replace with the ConfirmationDialog - needs to be a top-level managed instance via a store.
            /* eslint-disable no-alert */
            this.setState({ alertMessage: 'This project is not empty. Please confirm you wish to disable' });
        } else {
            this.setState({ deleteDialogOpen: false });

            const result = await doDelete({ projectId: selectedProject.projectId });
            if (result === true) {
                this.props.history.push('/plan/projects');
            }
        }
    };

    renderItems(selectedProject, classes) {
        const items = [
            {
                action: ($event) => this.handleNewProjectOpen($event, selectedProject.projectId),
                icon: (
                    <FontAwesomeIcon
                        style={{ fontSize: THREE_DOT_MENU_CSS.faIconFontSize, padding: '1px' }}
                        icon={faPencilAlt}
                        aria-label="faPencilAlt"
                        id="faPencilAlt"
                        className={classes.moreIcons}
                    />
                ),
                isDisabled: false,
                name: ' Project Properties',
            },
            {
                action: (e) => {
                    e.stopPropagation();
                    this.props.toggleModal('GeneralModal', null, null, {
                        title: 'Download Project',
                        closeIconAction: () => {
                            this.props.toggleModal();
                        },
                        component: [
                            {
                                content: (
                                    <div
                                        style={{
                                            width: '100%',
                                            padding: '0 30px',
                                            minWidth: '300px',
                                        }}
                                    >
                                        <Typography className={classes.message}>
                                            Are you sure you want to download{' '}
                                            <span className={classes.boldFont}>
                                                {selectedProject && selectedProject.projectName ? selectedProject.projectName : ''}
                                            </span>
                                            ?
                                        </Typography>
                                    </div>
                                ),
                                buttons: [
                                    {
                                        name: 'Download',
                                        variant: 'contained',
                                        buttonClass: '',
                                        style: { ...MODAL_SUBMIT_BUTTON },
                                        action: () => {
                                            const url = `/v1/${selectedProject.projectId}/download_project`;
                                            projectServices.downloadGradle(
                                                url,
                                                { fileName: selectedProject.projectName, id: selectedProject.projectId, type: 'project' },
                                                this.props.showSnackBar,
                                                'Unable to Export Project',
                                            );
                                            this.props.toggleModal();
                                        },
                                        isDisabled: false,
                                    },
                                    {
                                        name: 'Cancel',
                                        variant: 'outlined',
                                        style: { ...MODAL_CANCEL_BUTTON },
                                        action: () => {
                                            this.props.toggleModal();
                                        },
                                        isDisabled: false,
                                    },
                                ],
                            },
                        ],
                    });
                },
                icon: <Export className={classes.moreIcons} aria-label="exportIcon" id="exportIcon" />,
                isDisabled: false,
                name: ' Download Project',
            },
            {
                action: (e) => this.handleOnDelete(e),
                icon: <Disabled className={classes.moreIcons} aria-label="disabledIcon" id="disabledIcon" />,
                isDisabled: false,
                name: ' Disable Project',
            },
        ];
        return items;
    }

    render() {
        const { classes, history, nextPrevFunc, clearSelectedCaseDataAndProject, clearFlows, callFromStepEditor, selectedProject } = this.props;
        const { prevProject, nextProject, deleteDialogOpen, alertMessage } = this.state;
        return (
            <div className={`${classes.headerContainer} ${callFromStepEditor ? classes.stepEditorStyle : ''}`}>
                <div className={classes.buttonsContainer}>
                    {!callFromStepEditor && (
                        <>
                            <span
                                style={{
                                    display: 'flex',
                                    alignItems: 'center',
                                    marginRight: 5,
                                }}
                            >
                                {prevProject && prevProject !== true && !isNextPrev ? (
                                    <Tooltip data={prevProject.projectName}>
                                        <Button
                                            className={classes.navigationFor}
                                            onClick={() => {
                                                const tabName = getParamValues(2);
                                                history.replace(`/details/${prevProject.projectId}/${tabName || 'cases'}`);
                                                clearSelectedCaseDataAndProject();
                                                clearFlows();
                                                isNextPrev = 'prev';
                                                setTimeout(() => {
                                                    nextPrevFunc();
                                                }, 10);
                                            }}
                                            variant="contained"
                                            color="primary"
                                        >
                                            <LeftArrow
                                                style={{ fontSize: '20px', marginLeft: '4px' }}
                                                aria-label="leftArrowIcon"
                                                id="leftArrowIcon"
                                            />
                                            <Typography style={{ color: '#fff' }}>Prev</Typography>
                                        </Button>
                                    </Tooltip>
                                ) : (
                                    <Button
                                        className={classes.navigationForDisabled}
                                        disabled
                                        style={isNextPrev === 'prev' ? { justifyContent: 'center' } : {}}
                                    >
                                        {isNextPrev === 'prev' ? (
                                            <CircularProgress size={24} />
                                        ) : (
                                            [
                                                <LeftArrow
                                                    key="1"
                                                    style={{ fontSize: '20px', marginLeft: '4px', color: '#fff' }}
                                                    aria-label="leftArrowIcon"
                                                    id="leftArrowIcon"
                                                />,
                                                <Typography key="2" style={{ color: '#fff' }}>
                                                    Prev
                                                </Typography>,
                                            ]
                                        )}
                                    </Button>
                                )}
                            </span>
                            <span
                                style={{
                                    display: 'flex',
                                    alignItems: 'center',
                                }}
                            >
                                {nextProject && nextProject !== true && !isNextPrev ? (
                                    <Tooltip data={nextProject.projectName}>
                                        <Button
                                            className={classes.navigationBack}
                                            onClick={() => {
                                                const tabName = getParamValues(2);
                                                history.replace(`/details/${nextProject.projectId}/${tabName || 'cases'}`);
                                                clearSelectedCaseDataAndProject();
                                                clearFlows();
                                                isNextPrev = 'next';
                                                setTimeout(() => {
                                                    nextPrevFunc();
                                                }, 10);
                                            }}
                                            variant="contained"
                                            color="primary"
                                        >
                                            <Typography style={{ color: '#fff' }}>Next</Typography>
                                            <RightArrow
                                                style={{ fontSize: '20px', marginRight: '4px' }}
                                                aria-label="rightArrowIcon"
                                                id="rightArrowIcon"
                                            />
                                        </Button>
                                    </Tooltip>
                                ) : (
                                    <Button
                                        className={classes.navigationBackDisabled}
                                        disabled
                                        style={isNextPrev === 'next' ? { justifyContent: 'center' } : {}}
                                    >
                                        {isNextPrev === 'next' ? (
                                            <CircularProgress size={24} />
                                        ) : (
                                            [
                                                <Typography key="1" style={{ color: '#fff' }}>
                                                    Next
                                                </Typography>,
                                                <RightArrow key="2" style={{ fontSize: '20px', marginRight: '4px', color: '#fff' }} />,
                                            ]
                                        )}
                                    </Button>
                                )}
                            </span>
                        </>
                    )}
                    <div style={callFromStepEditor ? { marginLeft: 0, maxWidth: '100%' } : { marginLeft: 15, maxWidth: 'calc(100% - 147px)' }}>
                        <Typography noWrap className={classes.typography}>
                            {selectedProject.projectName}
                        </Typography>
                        <Typography noWrap className={classes.url}>
                            <a
                                style={{ textDecoration: 'none', color: '#3B91DF' }}
                                target="_blank"
                                rel="noopener noreferrer"
                                href={selectedProject.appUrl}
                                onClick={(e) => {
                                    e.stopPropagation();
                                }}
                            >
                                {selectedProject.appUrl}
                            </a>
                        </Typography>
                    </div>
                </div>
                <div className={classes.projectDetailsContainer}>
                    <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-evenly', width: '100%' }}>
                        {!callFromStepEditor && (
                            <>
                                <span className={classes.subtContainer}>
                                    <Typography className={classes.textBold} noWrap>
                                        Created:
                                    </Typography>
                                    <span className={classes.typography_pdhnormalt}>
                                        {getFormatedDate(selectedProject.creationTime, 'DD MMM YYYY', 'N/A')}
                                    </span>
                                </span>
                                <span className={classes.pipe} />
                                <span className={classes.subtContainer}>
                                    <Typography className={classes.textBold} noWrap>
                                        Last Updated:
                                    </Typography>
                                    <span className={classes.typography_pdhnormalt}>
                                        {getFormatedDate(selectedProject.lastActivityDate, 'DD MMM YYYY', 'N/A')}
                                    </span>
                                </span>
                                <span className={classes.pipe} />
                                <span className={classes.subtContainer}>
                                    <Typography className={classes.textBold} noWrap>
                                        Execution (
                                        {Number.isNaN(Math.ceil((100 * selectedProject.totalTestsPassedCount) / selectedProject.totalTestsCount))
                                            ? 0
                                            : Math.ceil((100 * selectedProject.totalTestsPassedCount) / selectedProject.totalTestsCount)}
                                        %):
                                    </Typography>
                                    <span className={classes.typography_pdhnormalt}>
                                        {selectedProject.totalTestsPassedCount}
                                        {'(Pass)'}
                                        {' / '}
                                        {selectedProject.totalTestsCount}
                                        {'(total)'}
                                    </span>
                                </span>
                            </>
                        )}
                        {!callFromStepEditor && (
                            <div className={classes.threeDotsButton}>
                                {/* start dotted menu */}
                                <MenuOptions items={this.renderItems(selectedProject, classes)} keepMounted />
                                {/* end dotted menu */}
                                {deleteDialogOpen ? (
                                    <DeleteAlertModal
                                        msg={alertMessage !== '' ? alertMessage : 'Are you sure you want to disable'}
                                        deleteButton="Danger"
                                        handleClose={this.confirmDelete}
                                        open={deleteDialogOpen}
                                        modalfor="Project"
                                        title="Project Name"
                                        name={
                                            this.props.selectedProject && this.props.selectedProject.projectName
                                                ? this.props.selectedProject.projectName
                                                : ''
                                        }
                                    />
                                ) : null}
                            </div>
                        )}
                    </div>
                </div>
            </div>
        );
    }
}

ProjectDetailTopBar.propTypes = {
    classes: PropTypes.shape({}).isRequired,
    history: PropTypes.shape({}),
};

ProjectDetailTopBar.defaultProps = {
    history: {},
};

const mapStateToProps = (state) => {
    return {
        projectsByIdAndName: state.projectReducer.projectsByIdAndName,
        user: state.authReducer.user,
        isLoadingTestData: state.projectReducer.isLoadingTestData,
        selectedProject: state.projectReducer.selectedProject,
    };
};
const mapDispatchToProps = (dispatch) => {
    return {
        doDelete: (obj) => dispatch(ProjectActions.deleteProjects(obj)),
        getProjectByIdAndName: (...args) => dispatch(ProjectActions.getProjectByIdAndName(...args)),
        clearSelectedCaseDataAndProject: () => dispatch(ProjectActions.clearSelectedCaseDataAndProject()),
        toggleModal: (...args) => dispatch(ModalActions.toggleModal(...args)),
        showSnackBar: (...args) => dispatch(ModalActions.toggleSnackBar(...args)),
        clearFlows: () => dispatch(FlowActions.clearFlows()),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(ProjectDetailTopBar));
