import React, { Component } from 'react';
import 'react-datepicker/dist/react-datepicker.css';
import { withStyles } from '@material-ui/core/styles';
import { connect } from 'react-redux';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import { List } from 'react-virtualized';
import ResetPassword from '@material-ui/icons/LockOpen';
import Associate from '@material-ui/icons/PersonAdd';
import Disable from '@material-ui/icons/NotInterested';
import { BLACK_FONT, WIDTH_100_PER } from '../../../common/cssConstants';
import { generalModalActions, ModalActions, AuthActions, UserActions } from '../../../store/actions';
import { checkKeyInObject, checkArrayLength, arrayAwait, makeLocalDate } from '../../../utils';
import { EnhancedTableHead } from '../../../components/EnhancedTable/index';
import Table from '../../../components/Table/Table';
import TableBody from '../../../components/Table/TableBody';
import AlertPopUp from './AlertPopUp';
import { USER_NAME, defaultStartDate, defaultEndDate } from '../../../common/constants';
import { isEmail,PASSWORD_REGEX } from '../../../common/regexConstants';
import Tooltip from '../../../components/Tooltip';
import AddIcon from '@material-ui/icons/AddCircle';
import { IconButton } from '@material-ui/core';
import SearchInput from '../../modal/GeneralModal/SearchInput';
import AccountUserRow from './AccountUserRow';
import TablePagination from '../../../components/Pagination/TablePagination';

const styles = () => ({
    root: {
        height: '100%',
        maxHeight: 'calc(100vh - 321px)',
        width: '100%',
    },
    font: {
        fontSize: 12,
        fontWeight: 500,
        color: BLACK_FONT,
    },
    accountText: {
        width: '35%',
    },
    labelColor: {
        '&>label': {
            color: BLACK_FONT,
            fontSize: '12px',
            fontWeight: 500,
            transform: 'translate(0, 1.5px)',
        },
        '&>div': {
            '&:before': {
                borderColor: '#979797 !important',
            },
            '&:after': {
                borderColor: '#979797 !important',
            },
        },
    },
    accountNameWrapper: {
        padding: '0px 30px',
        marginBottom: 5,
    },
    tableRows: {
        display: 'flex !important',
    },
    cell: {
        paddingLeft: '20px',
    },
    padding8: {
        paddingLeft: '8px',
    },
    padding10: {
        paddingLeft: '10px',
    },
    padding30: {
        paddingLeft: '33px',
    },
    padding40: {
        paddingLeft: '40px',
    },
    textField: {
        backgroundColor: '#fff',
        border: '1px solid #ececec',
        borderRadius: '3px',
        '&>div>input': {
            height: 18,
            width: 150,
            padding: '6px 4px 7px',
            fontSize: 12,
            fontWeight: 500,
        },
    },
    errorTextField: {
        border: '1px solid red !important',
    },
    calendarClass: {
        textTransform: 'capitalize',
        top: -10,
        '&>.react-datepicker__triangle': {
            display: 'none',
        },
    },
    customDatePicker: {
        background: 'transparent',
        border: 'none',
        outline: 'none',
        fontWeight: 500,
        color: BLACK_FONT,
        fontSize: 12,
        padding: '9px 10px 7px 5px',
        width: '100%',
    },
    select: {
        backgroundColor: 'white',
        borderRadius: 3,
        ...WIDTH_100_PER,
        '&>div': {
            borderBottom: 'none !important',
            transition: 'none !important',
        },
    },
    actionIcons: {
        fontSize: 16,
        color: '#4a4a4a',
        cursor: 'pointer'
    },
    addUserIcon: {
        fontSize: 25,
        color: '#1168cd',
        cursor: 'pointer',
    },
    datePickerWrapper: {
        '&>div': {
            backgroundColor: '#fff',
            border: '1px solid #ececec',
            borderRadius: '3px',
        },
    },
    actionIconsContainer: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        width: 60,
    },
    iconButtonRoot: {
        height: 25,
        width: 25,
    },
    disabledIcon: {
        color: '#BCBCBC',
        cursor: 'not-allowed',
    },
    checkBoxRoot: {
        width: 15,
        height: 15,
        color: '#b8c4cf',
    },
    checkBox: {
        color: '#b8c4cf',
    },
    checkBoxChecked: {
        color: '#3B91DF !important',
    },
    fontUpdate: {
        fontSize: 12,
        color: BLACK_FONT,
        fontWeight: 500,
    },
    fontPassword: {
        fontSize: 12,
        color: '#A4A4A4',
        fontWeight: 500,
    },
    oval: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        height: 22,
        width: 22,
        marginLeft: 25,
        marginBottom: 5,
        borderRadius: '50%',
        backgroundColor: '#1BABAB',
        cursor: 'pointer',
    },
    passwordContainer: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
    },
    lockIcon: {
        height: 16,
        width: 16,
        color: '#FFFFFF',
    },
    modalIconStyle: {
        color: '#F39B31',
        fontSize: 20,
        marginRight: 10,
    },
    actionMenuButton: {
        height: 24,
        width: 24,
        backgroundColor: '#FFFFFF',
        // boxShadow: '0 0 2px 1px rgba(0,0,0,0.12), 0 2px 7px 0 rgba(0,0,0,0.7)',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        borderRadius: '50%',
        cursor: 'pointer',
    },
    tableWrapper: {
        width: '1252px',
    },
    actionMenuWrapper: {
        display: 'flex',
        width: '100%',
        justifyContent: 'flex-end',
        marginTop: -32,
        marginBottom: 8,
    },
    _actionIcons: {
        fontSize: 18,
        color: '#5F7B96',
    },
    _actionDisableIcon: {
        fontSize: 23,
        color: '#1168cd',
        marginTop: 5,
    },
    actionContainer: {
        display: 'flex',
        justifyContent: 'space-between',
        width: 60,
        marginRight: 22
    },
    testError: {
        color: 'red',
        fontSize: 11,
        margin: 0,
    },
    errorContainer: {
        marginLeft: 30,
        '&>div': {
            marginBottom: 5,
        },
    },
    wrapperNameSearchBox: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'flex-end',
    },
    wrapperSearchInput: {
        display: 'flex',
        alignItems: 'center',
    },
    addBtn: {
        marginLeft: '60px'
    },
    tableBody: {
        maxHeight: 'unset !important',
        overflow: 'hidden !important',
        '&>div': {
            '&>div': {
                maxWidth: 'unset !important',
                width: '100% !important',
                overflow: 'hidden',
            },
        },
    },
    pageWrapper: {
        display: 'flex',
        width: '100%',
        justifyContent: 'flex-end',
    },
    pageContainer: {
        width: 450,
    },
});

const headers = [
    { id: 'userName', sortable: false, numeric: false, disablePadding: true, label: 'Username', width: '20%', styles: { paddingLeft: 20 } },
    { id: 'email', sortable: false, numeric: false, disablePadding: true, label: 'Email', width: '13%' },
    { id: 'password', sortable: false, numeric: false, disablePadding: true, label: 'Password', width: '13%' },
    { id: 'startDate', sortable: false, numeric: false, disablePadding: true, label: 'Start Date (Optional)', width: '13%' },
    { id: 'endDate', sortable: false, numeric: false, disablePadding: true, label: 'End Date (Optional)', width: '13%' },
    { id: 'role', sortable: false, numeric: false, disablePadding: true, label: 'Role', width: '10%' },
    {
        id: 'activeInactive',
        sortable: false,
        numeric: false,
        disablePadding: true,
        label: 'Active/Inactive',
        width: '10%',
        styles: { paddingLeft: 10 },
    },
    { id: 'funcs', sortable: false, numeric: false, disablePadding: true, label: '', width: '8%' },
];

let isMounted = false;
class AccountUser extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isFirst: false,
            didChange: false,
            addRowData: [
                {
                    email: '',
                    userName: '',
                    password: '',
                    userDateStart: defaultStartDate,
                    userDateEnd: defaultEndDate,
                    selectedItem: '',
                    isUserActive: false,
                    customCodeLocation: '',
                    language: 'EN',
                    timeZone: 'UTC',
                    CustomCodeLocation: '',
                    SshPort: '',
                    isTunnelOn: false,
                    isRelativeDateFormat: true,
                    LogoLocation: '',
                    id: Math.random(),
                },
            ],
            selectedUsers: [],
            name: '',
            prevName: '',
            isDuplicateName: false,
            selectedDateStart: defaultStartDate,
            selectedDateEnd: defaultEndDate,
            isShowDeleteUserModal: false,
            isShowAssociateModal: false,
            isShowPasswordModal: false,
            isUsernameValid: true,
            isEmailDisabled: true,
            isEmailValid: true,
            isDateValid: true,
            isPasswordValid: true,
            duplicateUserOnDiffAccount: [],
            duplicateUser: [],
            invalidUsersDate: [],
            isDuplicateEmailOnDiffAcc: [],
            duplicateEmail: [],
            query: '',
            rowsPerPage: 20,
            page: 0,
            disableRowOnUpdate: false,
            isPastedDateSame: false,
            disableAddBtn: false
        };
    }

    handleDateChange = (key, ind) => (date) => {
        let _date = date;
        if (!_date || typeof _date.getTime !== 'function') {
            _date = new Date(key === 'userDateStart' ? defaultStartDate : defaultEndDate);
        }
        const { addRowData } = this.state;
        let _addRowData = JSON.parse(JSON.stringify(addRowData));
        _addRowData[ind][key] = _date.getTime();
        this._setState(
            {
                addRowData: _addRowData,
                isDateValid: true,
            },
            () => {
                this.state.addRowData.find((row) => {
                    if (!(new Date(row.userDateStart) < new Date(row.userDateEnd))) {
                        this._setState({ isDateValid: false });
                        return true;
                    }
                    return false;
                });
                return false;
            },
        );
        return false;
    };

    getSelectedItems = (selectedItem, id) => {
        
        const { addRowData } = this.state;

        let _addRowData = JSON.parse(JSON.stringify(addRowData));

        _addRowData  = _addRowData.map((r)=>{
            
            if(r.id === id) {
                r.selectedItem = selectedItem
            }

            return r
        })

        this._setState({ addRowData: _addRowData });
    };

    changeStatus = (id) => (event) => {
        const { addRowData } = this.state;
        let _addRowData = JSON.parse(JSON.stringify(addRowData));
        const index = _addRowData.findIndex(x => x.id === id);
        _addRowData[index]['isUserActive'] = event.target.checked;

        this._setState({
            addRowData: _addRowData,
        });
    };

    handleChangeTextField = (event, ind) => {
        const { addRowData, isEmailValid, isUsernameValid, isPasswordValid } = this.state;
        let obj = {};
        let _addRowData = JSON.parse(JSON.stringify(addRowData));
        _addRowData[ind][event.target.name] = event.target.value;

        if (event.target.name === 'email' && !isEmailValid) {
            obj = { isEmailValid: true };
        } else if (event.target.name === 'userName' && !isUsernameValid) {
            obj = { isUsernameValid: true };
        } else if (event.target.name === 'password' && !isPasswordValid) {
            obj = { isPasswordValid: true };
        }

        this._setState({
            addRowData: _addRowData,
            ...obj,
        });
    };

    componentDidMount() {
        isMounted = true;
        const { isUpdate, account, tabsData } = this.props;
        let obj = {};
        if (
            isUpdate &&
            account &&
            account.filteredUsers &&
            checkArrayLength(account.filteredUsers) &&
            !checkKeyInObject(tabsData, 'accountUser', 'bool', false)
        ) {
            let _addRowData = []
            account.filteredUsers.forEach((user) => {
                const dummy = {
                    email: checkKeyInObject(user, 'email', 'value', ''),
                    uid: checkKeyInObject(user, 'id', 'value', ''),
                    userName: checkKeyInObject(user, 'name', 'value', ''),
                    password: checkKeyInObject(user, 'password', 'value', '123456'),
                    userDateStart: checkKeyInObject(user, 'startDate', 'bool', false)
                        ? new Date(makeLocalDate(user.startDate)).getTime()
                        : new Date(new Date().setHours(0, 0, 0, 0)).getTime(),
                    userDateEnd: checkKeyInObject(user, 'endDate', 'bool', false)
                        ? new Date(makeLocalDate(user.endDate)).getTime()
                        : new Date(new Date().setHours(23, 59, 0, 0)).getTime(),
                    selectedItem: checkKeyInObject(user, 'role', 'value', ''),
                    isUserActive: !checkKeyInObject(user, 'deleted', 'value', true),
                    customCodeLocation: '',
                    language: 'EN',
                    timeZone: 'UTC',
                    CustomCodeLocation: '',
                    SshPort: '',
                    isTunnelOn: false,
                    isRelativeDateFormat: true,
                    LogoLocation: '',
                    id: checkKeyInObject(user, 'id', 'value', Math.random()),
                    date: null
                };
                _addRowData = [..._addRowData, dummy];
            });
            obj = {
                ...obj,
                addRowData: [..._addRowData],
            };
        } else if (isUpdate && !account?.filteredUsers?.length ) {
            obj = {
                ...obj,
                addRowData: [],
            }
        }
        if (checkKeyInObject(tabsData, 'accountUser.state', 'bool', false)) {
            obj = { ...obj, ...tabsData.accountUser.state };
        }
        if (checkKeyInObject(tabsData, 'adminAccountInfo.state.name', 'value', '').trim()) {
            obj = {
                ...obj,
                name: tabsData.adminAccountInfo.state.name,
                prevName: tabsData.adminAccountInfo.state.name,
                isDuplicateName: tabsData.adminAccountInfo.state.isDuplicateName,
            };
        }
        if (checkKeyInObject(tabsData, 'adminAccountInfo.state.selectedDateStart')) {
            obj = {
                ...obj,
                selectedDateStart: tabsData.adminAccountInfo.state.selectedDateStart,
                selectedDateEnd: tabsData.adminAccountInfo.state.selectedDateEnd,
            };
        }
        this._setState({
            ...obj,
            isFirst: true,
            didChange: checkKeyInObject(obj, 'didChange', 'value', false),
        });
    }

    componentWillUnmount() {
        isMounted = false;
        const { updateTabData } = this.props;
        updateTabData('accountUser', { state: { ...this.state } });
    }

    // getSelectedItems = (selectedItem, ind) => {
    //     const { addRowData } = this.state;

    //     const _addRowData = JSON.parse(JSON.stringify(addRowData));
    //     _addRowData[ind].selectedItem = selectedItem;

    //     this._setState({ addRowData: _addRowData });
    // };

    handleCalendarClose = () => {
          this._setState({ isPastedDateSame: false });
    }
    
    handleChangeRaw = (e, ind) => {
        const { addRowData } = this.state;
        if (addRowData[ind]['userDateStart'] >= (new Date(e.target.value)).getTime()) {
               this._setState({ isPastedDateSame: true });
        }
        else{
            this._setState({ isPastedDateSame: false });
       }
    }
    handleDateChange = (key, ind,userName) => (date) => {
        const { addRowData } = this.state;
        let _date = date;
        if (!_date || typeof _date.getTime !== 'function') {
            _date = new Date(key === 'userDateStart' ? defaultStartDate : defaultEndDate);
        }
        const _addRowData = JSON.parse(JSON.stringify(addRowData));
        const __addRowData = JSON.parse(JSON.stringify(addRowData));
        let myIndex = __addRowData.findIndex(user => user.userName === userName)
        _addRowData[myIndex][key] = _date.getTime();
        let _invalidDateUsers = this.isInvalidDate(_addRowData)

        this._setState(
            {
                addRowData: _addRowData,
                isDateValid: true,
                invalidUsersDate: _invalidDateUsers
            },
            () => {
                this.state.addRowData.find((row) => {
                    if (new Date(row.userDateStart) >= new Date(row.userDateEnd)) {
                        if(this.state.invalidUsersDate.length > 0){
                            this._setState({ isDateValid: true });
                        } else {
                            this._setState({ isDateValid: false });
                        }
                        return true;
                    }
                    return false;
                });
            },
        );
    };

    changeStatus = (ind) => (event) => {
        const { addRowData } = this.state;

        const _addRowData = JSON.parse(JSON.stringify(addRowData));
        _addRowData.forEach((elem, i) => {
             if (elem.id === ind) {
                _addRowData[i].isUserActive = event.target.checked;
            }
        });
        this._setState({
            addRowData: _addRowData,
        });
    };

    isUnique = (userArray,key) => {
        let checkDuplicateUser = [];
        for(let obj in userArray) {
            if(checkDuplicateUser.indexOf(userArray[obj][key]) < 0){ 
            checkDuplicateUser.push(userArray[obj][key]);
            } else {
            return [false,obj];
            }
        }
        return [true];
    }

    isInvalidDate = (userArray) => {
        let checkInvalidUsers = []
        for (let obj in userArray) {
            if (!(new Date(userArray[obj].userDateStart) < new Date(userArray[obj].userDateEnd))) {
                checkInvalidUsers.push(userArray[obj])
            }
        }
        return checkInvalidUsers
    }

    isInvalidUsers = (userArray,key) => {
        let checkUsers = []
        let existingArray = [...userArray]
        let sortedArrays = existingArray.sort((user,nextUser) => user[key] < nextUser[key] ? -1 : 1)
        for (let index = 0; index < sortedArrays.length - 1; index++) {
            if(sortedArrays[index + 1][key] === sortedArrays[index][key]){
                checkUsers.push(sortedArrays[index])
            }
        }
        return checkUsers
    }

    handleChangeTextField = (event, ind) => {
        const { addRowData, isEmailValid, isUsernameValid, isPasswordValid } = this.state;
        let obj = {}, _duplicateUserIndex = [], _invalidDateUsers = [], _invalidUsersName = [], _duplicateEmailIndex = [], _invalidUsersEmail = [];
        const _addRowData = JSON.parse(JSON.stringify(addRowData));
        _addRowData[ind][event.target.name] = event.target.value;
        if(event.target.name === 'userName'){
            _duplicateUserIndex = this.isUnique(_addRowData,'userName')
            _invalidDateUsers = this.isInvalidDate(_addRowData)
            _invalidUsersName = this.isInvalidUsers(_addRowData,'userName')
        } else if (event.target.name === 'email') {
            _duplicateEmailIndex = this.isUnique(_addRowData,'email')
            _invalidUsersEmail = this.isInvalidUsers(_addRowData,'email')
        }
        if (_duplicateEmailIndex[0] === false) {
            obj = { duplicateEmail: _invalidUsersEmail,isEmailDisabled: false }
        } else if (event.target.name === 'email' && !isEmailValid) {
            obj = { isEmailValid: true, duplicateEmail: [], isDuplicateEmailOnDiffAcc: []  };
        } else if(_duplicateEmailIndex[0] === true) {
            obj = {duplicateEmail: [],isDuplicateEmailOnDiffAcc:[],isEmailDisabled:true}
        } else if (_duplicateUserIndex[0] === false) {
            obj = { isUsernameValid: false, duplicateUser: _invalidUsersName }
        } else if (event.target.name === 'userName' && !isUsernameValid) {
            obj = { isUsernameValid: true, duplicateUserOnDiffAccount:[],duplicateUser:[] };
        } else if (event.target.name === 'password' && PASSWORD_REGEX.test(event.target.value)) {
            obj = { isPasswordValid: true, disableAddBtn: false };
        } else if (event.target.name === 'password' && !PASSWORD_REGEX.test(event.target.value)) {
            obj = { isPasswordValid: false, disableAddBtn: true };
        }

        this._setState({
            addRowData: _addRowData,
            invalidUsersDate: _invalidDateUsers,
            ...obj,
        });
    };

    addTabRow = (id, arr) => {
        const val = {
            email: '',
            userName: '',
            password: '',
            userDateStart: defaultStartDate,
            userDateEnd: defaultEndDate,
            selectedItem: '',
            isUserActive: false,
            id: Math.random(),
        };
        // let bool = false;
        // for (let i = 0; i < arr.length; i++) {
        //     if (arr[i].email.trim() && arr[i].password && arr[i].userName.trim() && arr[i].selectedItem) {
        //         bool = true;
        //     } else {
        //         bool = false;
        //         break;
        //     }
        // }
        //if (bool) {
            this._setState({
                addRowData: [val, ...this.state.addRowData],
                disableRowOnUpdate: true
            });
       // }
    };

    deleteTabRow = (id) => {
        const { addRowData, isPasswordValid } = this.state;
        let _addRowData = JSON.parse(JSON.stringify(addRowData))
        let _isPasswordValid = false
        if (addRowData.length > 1) {
            this._setState({
                addRowData: [...this.state.addRowData.filter((val) => `${val.id}` !== `${id}`)],
            });
            _addRowData = _addRowData.filter((val) => `${val.id}` !== `${id}`)
        }
        _isPasswordValid = _addRowData.some(row => {return !PASSWORD_REGEX.test(row.password)})
        let _invalidDateUsers = this.isInvalidDate(_addRowData)
        this.setState({disableRowOnUpdate: false,invalidUsersDate: _invalidDateUsers,duplicateUserOnDiffAccount:[],isDuplicateEmailOnDiffAcc: [], isPasswordValid: this.props.isUpdate ? true : !_isPasswordValid, disableAddBtn: false })
    };

    checkAccountName = () => {
        if (this.state.name !== this.state.prevName) {
            const { accounts, updateTabData, tabsData, account } = this.props;
            let isNameValid;
            if (checkArrayLength(accounts)) {
                isNameValid = accounts.find(({ accountName }) => `${accountName}`.toLowerCase().trim() === `${this.state.name}`.toLowerCase().trim());
                if (isNameValid && account && isNameValid.accountId === account.accountId) {
                    isNameValid = null;
                }
                if (isNameValid) {
                    this._setState({ isDuplicateName: true, prevName: this.state.name }, () => {
                        if (checkKeyInObject(tabsData, 'adminAcccountInfo.state', 'bool', false)) {
                            updateTabData('adminAccountInfo', { state: { ...tabsData.adminAccountInfo.state, isDuplicateName: true } });
                        }
                    });
                } else {
                    this._setState({ isDuplicateName: false, prevName: this.state.name }, () => {
                        if (checkKeyInObject(tabsData, 'adminAcccountInfo.state', 'bool', false)) {
                            updateTabData('adminAccountInfo', { state: { ...tabsData.adminAccountInfo.state, isDuplicateName: false } });
                        }
                    });
                }
            }
        }
    };

    togglePasswordModal = (user) => {
        const { classes } = this.props;
        this.passwordModalContent = (
            <AlertPopUp
                icon={<ResetPassword className={classes.modalIconStyle} />}
                title="Reset Password"
                user={user}
                submitButtonText="Send"
                handleSubmit={async (_user) => {
                    const { toggleSnackBar } = this.props;
                    const { email } = _user;
                    const res = await this.props.resetPasswordMail(email);
                    if (res && res.status) {
                        toggleSnackBar(res.message, '', true);
                    } else if (res && res.message) {
                        toggleSnackBar(res.message);
                    }
                    this._setState({ isShowPasswordModal: false });
                }}
                handleClose={() => {
                    this._setState({ isShowPasswordModal: false });
                }}
                modalFor="resetPassword"
            />
        );
        this._setState({ isShowPasswordModal: true });
    };

    toggleAssociateModal = () => {
        const { classes } = this.props;
        this.associateUserModalContent = (
            <AlertPopUp
                icon={<Associate className={classes.modalIconStyle} />}
                title="Associate Users and Groups"
                stylesRoot={{ width: 500, height: 210 }}
                submitButtonText="Add"
                handleSubmit={async (group) => {
                    const {
                        getUsers,
                        getUserByAccountId,
                        associateUsersWithGroup,
                        currentUser: { accountId, name },
                    } = this.props;
                    const { selectedUsers } = this.state;
                    const _users = [];
                    if (selectedUsers) {
                        selectedUsers.forEach((user) => {
                            _users.push(user.id);
                        });
                    }
                    await associateUsersWithGroup({ group_id: group, user_ids: _users });
                    if (`${accountId}` === '1' && name === USER_NAME) {
                        getUsers();
                    } else {
                        getUserByAccountId(accountId);
                    }
                    this._setState({ isShowAssociateModal: false });
                }}
                handleClose={() => {
                    this._setState({ isShowAssociateModal: false });
                }}
                modalFor="associate"
            />
        );
        this._setState({ isShowAssociateModal: true });
    };

    toggleDeleteUsersModal = () => {
        const {
            classes,
            deleteUser,
            getUsers,
            getUserByAccountId,
            currentUser: { accountId, name },
            toggleSnackBar,
        } = this.props;
        const { selectedUsers, addRowData } = this.state;
        this.deleteUserModalContent = (
            <AlertPopUp
                icon={<Disable className={classes.modalIconStyle} />}
                title={`Delete ${selectedUsers.length > 1 ? 'User' : 'Users'}`}
                selectedUsers={selectedUsers}
                submitButtonText="Delete"
                handleSubmit={() => {
                   // if(selectedUsers.length > 1){
                        arrayAwait(
                            selectedUsers,
                            async (user) => {
                                let isDeleted = false;
                                isDeleted = await deleteUser(user.id);
                                return isDeleted;
                            },
                            (data) => {
                                if (!data.some((x) => !x)) {
                                    const _addRowData = [];
                                    addRowData.forEach((user) => {
                                        let temp = false;
                                        selectedUsers.forEach((selectedUser) => {
                                            if (user.id === selectedUser.id) {
                                                temp = true;
                                            }
                                        });
                                        if (!temp) {
                                            _addRowData.push(user);
                                        }
                                    });
                                    toggleSnackBar('User(s) deleted successfully', '', true);
                                    this._setState({ addRowData: _addRowData, selectedUsers: [] });
                                    if (`${accountId}` === '1' && name === USER_NAME) {
                                        getUsers();
                                    } else {
                                        getUserByAccountId(accountId);
                                    }
                                } else if (!data.some((x) => x)) {
                                    toggleSnackBar('Unable to delete user(s)');
                                } else {
                                    toggleSnackBar('Unable to delete some user(s)');
                                }
                                this._setState({ isShowDeleteUserModal: false });
                            },
                        );
                    //}else{
                      //  toggleSnackBar('Last user cannot be deleted');
                    ///}
                }}
                handleClose={() => {
                    this._setState({ isShowDeleteUserModal: false });
                }}
                modalFor="deleteUser"
            />
        );
        this._setState({ isShowDeleteUserModal: true });
    };

    handleSelectUser = (user) => {
        const { selectedUsers } = this.state;
        const _selectedUsers = JSON.parse(JSON.stringify(selectedUsers));

        let index = -1;
        for (let i = 0; i < _selectedUsers.length; i++) {
            if (_selectedUsers[i].userName === user.userName) {
                index = i;
                break;
            }
        }

        if (index === -1) {
            _selectedUsers.push(user);
        } else {
            _selectedUsers.splice(index, 1);
        }

        this._setState({ selectedUsers: _selectedUsers });
    };

    handleSelectAll = () => {
        const { addRowData, selectedUsers } = this.state;
        const _selectedUsers = [];
        if (!checkArrayLength(selectedUsers)) {
            addRowData.forEach((user, index) => {
                if (user.id === parseInt(user.id, 10)) {
                    _selectedUsers.push(user);
                }
            });
        }

        this._setState({ selectedUsers: _selectedUsers });
    };

    _setState = (obj, cb = () => {}) => {
        if (isMounted)
            this.setState({ didChange: this.state.isFirst, ...obj }, () => {
                cb();
                this.props.updateTabData('accountUser', { state: { ...this.state } });
            });
    };

    handleChange = (e) => {
        const query = e.target.value;
        this.setState({ query });
    };

    clearSearch = () => {
        this.setState({ query: '' });
    };

    handleChangePage = (page) => {
        this.setState({ page });
    };

    handleChangeRowsPerPage = (event) => {
        this.setState({ rowsPerPage: event.target.value });
    };

    render() {
        const {
            name,
            addRowData,
            isShowPasswordModal,
            selectedUsers,
            isShowDeleteUserModal,
            isShowAssociateModal,
            isDuplicateName,
            selectedDateStart,
            selectedDateEnd,
            isEmailValid,
            isDateValid,
            isPasswordValid,
            isUsernameValid,
            query,
            rowsPerPage,
            page,
            isPastedDateSame,
            duplicateUserOnDiffAccount,
            duplicateUser,
            invalidUsersDate,
            isDuplicateEmailOnDiffAcc,
            duplicateEmail,
            isEmailDisabled,
            disableAddBtn,
        } = this.state;
        const { classes, items, isUpdate } = this.props;

        let isDisableBtnAddRow = false;
        if (checkArrayLength(addRowData)) {
            if (
                !(
                    addRowData[0].userName.trim() &&
                    isUsernameValid && isEmailDisabled &&
                    addRowData[0].email.trim() &&
                    isEmail.test(String(addRowData[0].email).toLowerCase()) &&
                    addRowData[0].password &&
                    (PASSWORD_REGEX.test(addRowData[0].password) || isUpdate) &&
                    addRowData[0].selectedItem &&
                    new Date(addRowData[0].userDateStart) < new Date(addRowData[0].userDateEnd)
                )
            ) {
                isDisableBtnAddRow = true;
            }
        }

        const queryUsers = checkArrayLength(addRowData) && addRowData.filter((user, index) => {
            return query !== '' ? ( user && user.userName && user.userName.toLowerCase().includes(query.toLowerCase().trim())) : [];
        })

        const currentPageUsers = queryUsers.length ? [...queryUsers?.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)] : [];

        const rows = [];
        currentPageUsers.forEach((data, index, arr) => {
            rows.push(
                <AccountUserRow
                    classes={classes}
                    headers={headers}
                    data={data}
                    disableRowOnUpdate={this.state.disableRowOnUpdate}
                    index={index}
                    isUpdate={isUpdate}
                    selectedUsers={selectedUsers}
                    items={items}
                    duplicateUserOnDiffAccount={duplicateUserOnDiffAccount}
                    duplicateUser={duplicateUser}
                    handleChangeRaw={this.handleChangeRaw}
                    handleCalendarClose={this.handleCalendarClose}
                    addRowData={addRowData}
                    isDisableBtnAddRow={isDisableBtnAddRow}
                    users={this.props.users}
                    handleSelectUser={this.handleSelectUser}
                    handleChangeTextField={this.handleChangeTextField}
                    handleDateChange={this.handleDateChange}
                    addTabRow={(id) => {
                        this.addTabRow(id, arr);
                    }}
                    deleteTabRow={this.deleteTabRow}
                    togglePasswordModal={this.togglePasswordModal}
                    getSelectedItems={this.getSelectedItems}
                    changeStatus={this.changeStatus}
                    onEmailBlur={(event) => {
                        const { users } = this.props;
                        if (Boolean(event.target.value.trim()) && checkArrayLength(users)) {
                            const _users = users.filter((_user) => _user.email === event.target.value);
                            if (
                                (isUpdate &&
                                    checkArrayLength(_users) &&
                                    checkKeyInObject(_users[0], 'id', 'bool', false) &&
                                    _users[0].id !== data.id) ||
                                _users.length
                            ) {
                                this._setState({isEmailDisabled: false, isDuplicateEmailOnDiffAcc: [..._users],duplicateEmail: [] });
                            }
                        }
                        addRowData.find((row) => {
                            if (Boolean(row.email.trim()) && !isEmail.test(String(row.email).toLowerCase())) {
                                this._setState({ isEmailValid: false, didChange: this.state.didChange });
                                return true;
                            }
                            return false;
                        });
                    }}
                    onPasswordBlur={() => {
                        addRowData.find((row) => {
                            if (!this.props.isUpdate && !PASSWORD_REGEX.test(row.password)) {
                                this._setState({ isPasswordValid: false, didChange: this.state.didChange });
                                return true;
                            }
                            return false;
                        });
                    }}
                    setState={this._setState}
                />,
            );
        });

        return (
            <div className={classes.root}>
                {isShowPasswordModal && this.passwordModalContent}
                {isShowDeleteUserModal && this.deleteUserModalContent}
                {isShowAssociateModal && this.associateUserModalContent}
                <div className={classes.accountNameWrapper}>
                    <div className={classes.wrapperNameSearchBox}>
                        <TextField
                            id="AccountNameTextField"
                            aria-label="Account Name TextField"
                            value={name}
                            InputLabelProps={{ shrink: true }}
                            label="Account Name"
                            InputProps={{
                                classes: {
                                    input: classes.font,
                                },
                                autoComplete: 'off',
                            }}
                            fullWidth
                            className={`${classes.labelColor} ${classes.accountText}`}
                            onChange={(e) => {
                                this._setState({ name: e.target.value, prevName: this.state.name }, () => {
                                    const { tabsData, updateTabData } = this.props;
                                    if (checkKeyInObject(tabsData, 'adminAccountInfo.state', 'bool', false)) {
                                        updateTabData('adminAccountInfo', { state: { ...tabsData.adminAccountInfo.state, name: this.state.name } });
                                    }
                                    this.checkAccountName();
                                });
                            }}
                            autoFocus
                        />
                        <div className={classes.wrapperSearchInput}>
                            <SearchInput
                                id="AccountUser"
                                placeholder="Search"
                                onChange={this.handleChange}
                                value={query}
                                style={{ width: 300 }}
                                clearSearch={this.clearSearch}
                            />
                            <div className={classes.addBtn}>
                                <Tooltip data='Add Another User'>
                                    <IconButton className={classes.iconButtonRoot} onClick={() => this.addTabRow('',currentPageUsers)} disabled={isDisableBtnAddRow || disableAddBtn}>
                                        <AddIcon className={`${classes.addUserIcon} ${isDisableBtnAddRow || disableAddBtn ? classes.disabledIcon : ''}`} />
                                    </IconButton>
                                </Tooltip>
                            </div>
                        </div>
                    </div>
                </div>
                <div className={classes.tableWrapper}>
                    {checkArrayLength(selectedUsers) && (
                        <div className={classes.actionMenuWrapper}>
                            <div className={classes.actionContainer}>
                                {/* <div className={classes.actionMenuButton} onClick={this.toggleAssociateModal}>
                                <Tooltip data="Add to Group">
                                    <Associate className={classes._actionIcons} />
                                </Tooltip>
                            </div> */}
                                <div aria-hidden className={classes.actionMenuButton} onClick={this.toggleDeleteUsersModal}>
                                    <Tooltip data="Delete Users">
                                        <Disable className={classes._actionDisableIcon} />
                                    </Tooltip>
                                </div>
                            </div>
                        </div>
                    )}
                    <Table id="myTbl">
                        <EnhancedTableHead
                            headers={headers}
                            onSelectAllClick={() => {
                                this.handleSelectAll();
                            }}
                            numSelected={selectedUsers.length}
                            rowCount={
                                currentPageUsers.length -
                                currentPageUsers.filter((data, index) => !(isUpdate && index !== 0 && data.id === parseInt(data.id, 10))).length
                            }
                            showSelectBtn={isUpdate}
                            tableRowStyle={{ borderTop: '1px solid rgb(224, 224, 224)', boxSizing: 'border-box' }}
                        />
                        <TableBody className={classes.tableBody}>
                            {queryUsers.length ?
                            <List
                                className="scrollTable"
                                rowCount={rows.length}
                                width={1}
                                height={150}
                                rowHeight={50}
                                style={{ width: 1252, maxHeight: 'unset', willChange: 'unset' }}
                                rowRenderer={({ index, style }) => {
                                    return (
                                        <span key={`item-${index}`} data-key={`item-${index}`} style={{ ...style, cursor: 'default' }}>
                                            {rows[index]}
                                        </span>
                                    );
                                }}
                            />
                            :
                            <Typography style={{textAlign: 'center', fontSize: 12, fontWeight: 'bold', marginTop: 20}}>No User Found</Typography>
                            }
                            <span className={classes.pageWrapper}>
                                <div className={classes.pageContainer}>
                                    <TablePagination
                                        count={queryUsers.length - 1}
                                        rowsPerPage={rowsPerPage}
                                        rowsPerPageOptions={[5, 10, 20, 50]}
                                        page={page}
                                        handleChangePage={this.handleChangePage}
                                        handleChangeRowsPerPage={this.handleChangeRowsPerPage}
                                        labelRowsPerPage="Users per page:"
                                        applyCustomClasses
                                    />
                                </div>
                            </span>
                        </TableBody>
                    </Table>
                </div>

                {isDuplicateName || !name || !name.trim() ? (
                        <div className={classes.errorContainer}>
                            {isDuplicateName && <div className={classes.testError}>Account with same name already exists</div>}
                            {(!name || !name.trim()) && <div className={classes.testError}>Account name must not be empty</div>}
                        </div>
                    ) : null}
                    {new Date(selectedDateStart) >= new Date(selectedDateEnd) ? (
                        <div className={classes.errorContainer}>
                            <div className={classes.testError}>End time must be greater than Start time.</div>
                        </div>
                    ) : null}

                {
                    !isEmailValid 
                    || !isUsernameValid 
                    || !isPasswordValid 
                    || !isDateValid 
                    || isPastedDateSame 
                    || invalidUsersDate.length > 0 
                    || duplicateUserOnDiffAccount.length > 0 
                    || duplicateUser.length > 0 
                    || isDuplicateEmailOnDiffAcc.length > 0 
                    || duplicateEmail.length > 0
                    || !addRowData[0]?.userName?.trim()
                    || !addRowData[0]?.email?.trim()
                    || !addRowData[0]?.selectedItem?.trim() 
                    ? (
                        <div className={classes.errorContainer}>
                            {duplicateUserOnDiffAccount.length > 0 && <div className={classes.testError}>{`Username ${duplicateUserOnDiffAccount[0].name} already exist`} </div>}
                            {duplicateUser.length > 0 && <div className={classes.testError}>{`Username ${duplicateUser[0].userName} already exists`} </div>}
                            {isDuplicateEmailOnDiffAcc.length > 0 && <div className={classes.testError}>{`Email ${isDuplicateEmailOnDiffAcc[0].email} already exists`} </div>}
                            {duplicateEmail.length > 0 && <div className={classes.testError}>{`Email ${duplicateEmail[0].email} already exists`} </div>}
                            {!isEmailValid && <div className={classes.testError}>Email is invalid</div>}
                            {!isPasswordValid && <div className={classes.testError}>Password should contain atleast 8 characters, 1 special, 1 small and upper case character, 1 numeric character</div>}
                            {(!isDateValid || isPastedDateSame) && <div className={classes.testError}>"End date" must be greater than "Start date" for all users.</div>}
                            {invalidUsersDate.length > 0 && <div className={classes.testError}>{`Incorrect date ranges for users:  ${invalidUsersDate.map((users)=>('"'+users.userName +'" ' ))} `} </div>}
                            {!addRowData[0]?.userName?.trim() && <div className={classes.testError}>Username must not be empty.</div>}
                            {!addRowData[0]?.email?.trim() && <div className={classes.testError}>Email must not be empty.</div>}
                            {!addRowData[0]?.password?.trim() && <div className={classes.testError}>Password must not be empty.</div>}
                            {!addRowData[0]?.selectedItem?.trim() && <div className={classes.testError}>Role must not be empty.</div>}
                        </div>
                    ) : null}
                    
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        tabsData: state.generalModalReducer.tabsData,
        users: state.userReducer.users,
        accounts: state.accountReducer.accounts,
        currentUser: state.authReducer.user,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        updateTabData: (...args) => dispatch(generalModalActions.updateTabData(...args)),
        toggleSnackBar: (...args) => dispatch(ModalActions.toggleSnackBar(...args)),
        resetPasswordMail: (email) => dispatch(AuthActions.resetPasswordMail(email)),
        associateUsersWithGroup: (data) => dispatch(UserActions.associateUsersWithGroup(data)),
        getUsers: () => dispatch(UserActions.getUsers()),
        getUserByAccountId: (accountId) => dispatch(UserActions.getUserByAccountId(accountId)),
        deleteUser: (id) => dispatch(UserActions.deleteUser(id)),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(AccountUser));
