/* eslint-disable no-underscore-dangle */
import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import { connect } from 'react-redux';
// Keyboard input comprehension node modules
import { saveAs } from 'file-saver/FileSaver';
// custom
import projectService from '../../../services/projectServices';
import { TestSuiteMiddleware } from '../../../store/middleware';
import { checkArrayLength, checkKeyInObject, replaceCharsForStepsCSV, getParamValues ,checkIsArray } from '../../../utils/utils';
import EnhanceTableTestCaseModal from '../GeneralModal/v2/EnhanceTableTestCaseModal';
import { DialogTop } from '../../../components/Modal/ModalTop';
import GeneralModalFooter from '../GeneralModal/GeneralModalFooter';
import { testDataUtils } from '../../../utils/TestDataUtils';
import { CANCEL_BUTTON, NEXT_SUBMIT_BUTTON } from '../../../components/Button/Btn';

import { DIALOG_ROOT, MODAL_FOOTER, BLACK_FONT } from '../../../common/cssConstants';
import { ProjectActions } from '../../../store/actions';
import singleFileCreationService from '../../../services/singleFileCreationService';
import JSONTOCsv from '../../../services/JSONToCsv';
import { DATA_FILE_TYPE } from '../../../common/constants';

const styles = (theme) => ({
    root: {
        width: '100%',
        backgroundColor: theme.palette.background.paper,
    },
    dialogRoot: DIALOG_ROOT,
    dataName: {
        color: BLACK_FONT,
        fontSize: 16,
    },
});

let btnDisabled = false;

class AttachDataShow extends React.Component {
    state = {
        allChecked: false,
        isButtonDisabled: !checkKeyInObject(this.props.tempCheckedCaseIds, this.props.testDataValues.testDataId),
        testCases: [...this.props.testDataValues.testCases],
        query: '',
    };

    componentDidMount() {
        const {
            testDataValues: { associatedTestCaseIds, testDataId },
            tempCheckedCaseIds,
        } = this.props;
        const testCases = [...this.state.testCases];
        associatedTestCaseIds.forEach((id) => {
            testCases.find((singleCase, index) => {
                if (`${singleCase.testCaseId}` === id.toString()) testCases[index] = { ...testCases[index], checked: true };
                return null;
            });
        });
        const allChecked = !testCases.some((testCase) => !testCase.checked);
        this.updateState({ testCases, allChecked }, () => {
            // To handle return from Preview Test Case modal
            if (checkKeyInObject(tempCheckedCaseIds, testDataId)) {
                const udpatedTestCases = [...this.state.testCases];
                tempCheckedCaseIds[testDataId].forEach((id) => {
                    udpatedTestCases.find((singleCase, index) => {
                        if (`${singleCase.testCaseId}` === id.toString())
                            udpatedTestCases[index] = { ...udpatedTestCases[index], checked: true, newAssociation: true };
                        return null;
                    });
                });
                const _allChecked = !udpatedTestCases.some((testCase) => !testCase.checked);
                this.setState({ testCases: udpatedTestCases, allChecked: _allChecked });
            }
        });
    }

    componentWillUnmount() {
        const newlyAssociatedIds = this.filterNewlyAssociatedTestCaseIds(this.state.testCases);
        const {
            testDataValues: { testDataId },
        } = this.props;
        let __tempCheckedCaseIds = {};
        if (checkArrayLength(newlyAssociatedIds)) {
            __tempCheckedCaseIds = { [testDataId]: newlyAssociatedIds };
        }
        this.props.saveTempCheckedCaseIds(__tempCheckedCaseIds);
    }

    testCasesIds = [];

    handleDownloadTestCase = async (fileName, Steps, Type, fileType = 'xlsx') => {
        if (fileType === 'csv') {
            try {
                const testSteps = Type === 'Live Steps' ? singleFileCreationService.sort(Steps) : Steps;
                const header = {
                    'Test Steps': 'Test Steps',
                    Data: 'Data',
                };
                const data = testSteps.map((step) => {
                    return {
                        'Test Steps': `"${replaceCharsForStepsCSV(step.instr).replace(/["]{1}/g, '""')}"`,
                        Data: `"${step.data.replace(/["]{1}/g, '""')}"`,
                    };
                });
                JSONTOCsv.CSVFile(header, data, `${fileName}`);
            } catch (error) {
                // eslint-disable-next-line no-console
                console.log(error);
            }
        } else {
            const data = await singleFileCreationService.getCaseFile(Steps, Type, fileName);
            if (data) {
                const blob = new Blob([data], {
                    type: 'application/octet-stream',
                });
                saveAs(blob, `${fileName}.xlsx`);
            } else if (!data) {
                // eslint-disable-next-line no-console
                console.log('FAILED TO DOWNLOAD XLSX FILE');
            } else {
                // eslint-disable-next-line no-console
                console.log('FAILED TO DOWNLOAD XLSX FILE');
            }
        }
    };

    previewTestCase = async (testCaseId, testCaseName) => {
        btnDisabled = true;
        const { toggleModal, testDataValues } = this.props;
        const testCasePreview = await projectService.previewTestCase(testCaseId);
        /* set forceOpenModal true to open new modal forcefully | Need testDataValues to return in this modal */
        if (testCasePreview && btnDisabled) {
            toggleModal('filePreviewModal', null, null, {
                id: testCaseId,
                data: testCasePreview,
                name: testCaseName,
                APIData: testCaseName,
                modalName: 'CaseModal',
                handleDownload: this.handleDownloadTestCase,
                forceOpenModal: true,
                ...testDataValues,
            });
        }
        btnDisabled = false;
    };

    filterNewlyAssociatedTestCaseIds = (testCases) => {
        const filteredCases = [];
        testCases.forEach((testCase) => {
            if (testCase.checked === true && testCase.newAssociation === true) filteredCases.push(Number(testCase.testCaseId));
        });
        return filteredCases;
    };

    filterTestCases = (testCases) => {
        const filteredCases = [];
        testCases.forEach((testCase) => {
            if (testCase.checked === true) filteredCases.push(Number(testCase.testCaseId));
        });
        return filteredCases;
    };

    // To check if association changed or not
    ifAssociationChanged(testCases) {
        const {
            testDataValues: { associatedTestCaseIds },
        } = this.props;
        const checkedIds = [];
        testCases.forEach((testCase) => {
            if (testCase.checked) {
                checkedIds.push(testCase.testCaseId);
            }
        });
        return checkedIds.length === associatedTestCaseIds.length && associatedTestCaseIds.every((val) => checkedIds.includes(val));
    }

    updateState = (obj) => {
        this.setState(obj);
    };

    handleToggle = (testCaseId, checked) => {
        const {
            testDataValues: { associatedTestCaseIds },
        } = this.props;
        const { testCases } = this.state;
        const index = testCases.map((_case) => `${_case.testCaseId}`).indexOf(`${testCaseId}`);
        const newAssociation = checked ? !associatedTestCaseIds.some((id) => `${id}` === `${testCases[index].testCaseId}`) : false;
        testCases[index] = { ...testCases[index], checked, newAssociation };
        const allChecked = !testCases.some((testCase) => !testCase.checked);
        // const isAnyCheck = testCases.some((testCase) => testCase.checked);
        const flag = this.ifAssociationChanged(testCases);
        this.setState({ testCases, allChecked, isButtonDisabled: flag /* || !isAnyCheck */ });
    };

    handleChange = (ev) => {
        const { value } = ev.target;
        this.setState({ query: value });
    };

    clearSearch = () => {
        this.setState({ query: '' });
    };

    handleToggleAll = (isAllChecked) => {
        const testCases = [...this.state.testCases];
        const _testCasesIds = testCases
            .filter((_case) => {
                return checkKeyInObject(_case, 'testCaseName') && _case.testCaseName.toLowerCase().includes(this.state.query.toLowerCase());
            })
            .map((_case) => {
                return _case.testCaseId;
            });
        let newTestCases = [];
        if (isAllChecked) {
            newTestCases = testCases.map((testCase) => {
                if (!_testCasesIds.includes(testCase.testCaseId)) {
                    return testCase;
                }
                const newTestCase = { ...testCase, checked: true };
                return newTestCase;
            });
        } else {
            newTestCases = testCases.map((testCase) => {
                if (!_testCasesIds.includes(testCase.testCaseId)) {
                    return testCase;
                }
                const newTestCase = { ...testCase, checked: false };
                return newTestCase;
            });
        }

        const flag = this.ifAssociationChanged(newTestCases);
        this.setState({ testCases: newTestCases, allChecked: isAllChecked, isButtonDisabled: flag /* || !isAllChecked */ });
    };

    handleEscape = () => {
        this.props.toggleModal();
    };

    replaceURL = () => {
        this.props.history.replace(`/details/${getParamValues(1)}/data`);
    };

    handleButtonClick = async () => {
        this.replaceURL();
        const {
            toggleModal,
            accountId,
            associateDataToTestCase,
            selectedProject,
            showSnackBar,
            testDataValues: { testDataId },
        } = this.props;
        const projectId = checkKeyInObject(selectedProject, 'id')
            ? selectedProject.id
            : checkKeyInObject(selectedProject, 'projectId')
            ? selectedProject.projectId
            : null;
        if (projectId) {
            this.setState({ isButtonDisabled: true });
            toggleModal('GeneralModal', null, null, null, false, true);
            const suite = await TestSuiteMiddleware.getTestSuites(accountId, projectId);
            const suiteId = checkArrayLength(suite) ? suite[0].testSuiteId : 0;
            const discoveryId = projectId;
            const checkedTestCaseId = this.filterTestCases(this.state.testCases);
            const formData = new FormData();
            formData.append('testcaseIds', checkedTestCaseId);
            const { message, status } = await associateDataToTestCase(accountId, projectId, discoveryId, suiteId, testDataId, formData);
            if (status === 200) {
                if (checkArrayLength(this.testCasesIds) || checkArrayLength(checkedTestCaseId)) {
                    this.props.updateTestCaseDataAssociation(this.testCasesIds, checkedTestCaseId, testDataId);
                    this.testCasesIds = [];
                }
                showSnackBar(message, '', true);
            } else {
                showSnackBar(message);
            }
            this.props.toggleModal();
        } else {
            showSnackBar('somthing is wrong please refresh the page and try again');
            this.props.toggleModal();
        }
    };

    /* Confirmation modal start */
    toggleConfirmationModal = () => {
        const {
            testDataValues: { testDataId, type, name, allData },
        } = this.props;
        const testCases = checkArrayLength(this.state.testCases) ? this.state.testCases : [];
        const oldDataToChange = [];
        const newData = [];
        testCases.forEach((testCase) => {
            // // To show all selected testcase
            // if (testCase.checked) {
            //     newData.push({ case: testCase.testCaseName, data: name });
            // }

            testCase._testData = [];

            if(checkArrayLength(allData)) {
                allData.forEach((testData)=>{
                    if(testData.testCasesIds.includes(testCase.testCaseId)) {
                        testCase._testData.push(testData.testDataId)
                    }
                })
            }
            
            if (checkArrayLength(testCase._testData)) {
                let associatedData = {};
                if (checkArrayLength(allData)) {
                    associatedData = allData.find(
                        (datum) => `${datum.testDataId}` === `${testCase._testData[0]}` && `${testDataId}` !== `${datum.testDataId}`,
                    );
                }
                if (
                    associatedData &&
                    testCase.checked &&
                    testCase._testData[0] !== testDataId &&
                    type !== DATA_FILE_TYPE.arti &&
                    type !== DATA_FILE_TYPE.audio &&
                    checkKeyInObject(associatedData, 'type') !== DATA_FILE_TYPE.arti &&
                    checkKeyInObject(associatedData, 'type') !== DATA_FILE_TYPE.audio
                ) {
                    newData.push({ case: testCase.testCaseName, data: name });
                    oldDataToChange.push({ case: testCase.testCaseName, data: [associatedData.testDataFileName] });
                    this.testCasesIds.push(testCase.testCaseId);
                }
            }
        });
        if (checkArrayLength(oldDataToChange) && checkArrayLength(newData)) {
            testDataUtils.toggleConfirmationModal({
                data: { oldDataToChange, newData },
                dataName: name,
                handleButtonClick: this.handleButtonClick,
            });
        } else {
            this.handleButtonClick();
        }
    };
    /* Confirmation modal end */

    render() {
        const { classes, open, toggleModal, testDataValues, editable } = this.props;
        const { allChecked, testCases, query } = this.state;
       
        const _testCases = checkArrayLength(testCases)
            ? testCases.filter((_case) => {
                  return checkKeyInObject(_case, 'testCaseName') && _case.testCaseName.toLowerCase().includes(query.toLowerCase().trim());
              })
            : [];

        const headers = [
            {
                id: 'All',
                sortable: false,
                numeric: false,
                disablePadding: false,
                label: 'SELECT ALL',
                styles: { paddingLeft: 10, color: BLACK_FONT, fontWeight: 'Bold',fontSize: '12px' },
                width: '80%',
            },
            { id: 'func', sortable: false, numeric: false, disablePadding: false, label: '', styles: { paddingLeft: 10 }, width: '20%' },
        ];
        let _selected = 0;
        for (let i = 0; i < _testCases.length; i++) {
            const testCase = _testCases[i];
            if (testCase.checked) {
                _selected++;
            }
        }
        let testDataName = testDataValues.name;

        if(testDataValues && testDataValues.allData && checkIsArray(testDataValues.allData)) {
            let dataFound = testDataValues.allData.find((data)=>{
                return data.testDataId == testDataValues.testDataId
            });

            if(dataFound && dataFound.testDataName && dataFound.testDataFileName) {
                testDataName = `${dataFound.testDataName}.${dataFound.testDataFileName.split('.')[1]}`
            }
        }
        return (
            <div className={classes.root}>
                <Dialog disableRestoreFocus open={open} aria-labelledby="form-dialog-title" maxWidth="md" className={classes.dialogRoot}>
                    <DialogTop
                        title="Attach Case(s) to Data"
                        closeIconAction={() => {
                            this.replaceURL();
                            toggleModal();
                        }}
                    />
                    <DialogContent style={{ padding: '0 30px 5px 30px' }}>
                        <EnhanceTableTestCaseModal
                            headers={headers}
                            onSelectAllClick={() => {
                                this.handleToggleAll(!allChecked);
                            }}
                            numSelected={_selected}
                            showSelectBtn
                            testCases={_testCases}
                            handleToggle={this.handleToggle}
                            previewTestCase={this.previewTestCase}
                            query={query}
                            clearSearch={this.clearSearch}
                            handleSearch={this.handleChange}
                            editable = {editable}
                            dataName={testDataName}
                        />
                    </DialogContent>
                    <DialogActions style={MODAL_FOOTER}>
                        <GeneralModalFooter
                            buttons={[
                                NEXT_SUBMIT_BUTTON({
                                    name: 'OK',
                                    action: () => {
                                        this.toggleConfirmationModal();
                                    },
                                    isDisabled: this.state.isButtonDisabled,
                                }),
                                CANCEL_BUTTON({
                                    name: 'Close',
                                    action: () => {
                                        this.replaceURL();
                                        toggleModal();
                                    },
                                }),
                            ]}
                        />
                    </DialogActions>
                </Dialog>
            </div>
        );
    }
}

AttachDataShow.propTypes = {
    classes: PropTypes.shape({}).isRequired,
    testDataValues: PropTypes.instanceOf(Object).isRequired,
    open: PropTypes.bool.isRequired,
    accountId: PropTypes.number.isRequired,
    toggleModal: PropTypes.func.isRequired,
    associateDataToTestCase: PropTypes.func.isRequired,
    selectedProject: PropTypes.instanceOf(Object).isRequired,
};

const mapStateToProps = (state) => {
    return {
        reducerTestCases: state.projectReducer.testCases,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        updateTestCaseDataAssociation: (...args) => dispatch(ProjectActions.updateTestCaseDataAssociation(...args)),
        associateDataToTestCase: (...args) => dispatch(ProjectActions.associateDataToTestCase(...args)),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(AttachDataShow));
