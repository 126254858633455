import { BLACK_FONT } from '../../common/cssConstants';

// Theme-dependent styles
export const styles = (theme) => ({
    body: {
        padding: '0 20px',
        margin: '20px 0 0 0',
    },
    maxHeight: {
        overflowY: 'auto',
        height: 'calc(100vh - 112px)',
        // [theme.breakpoints.only('sm')]: {
        //     height: 'calc(100vh - 80px)',
        // },
        // [theme.breakpoints.only('xs')]: {
        //     height: 'calc(100vh - 53px)',
        // }
    },
    rootContainer: {
        backgroundColor: '#ececec',
    },
    rows: {
        display: 'flex',
        justifyContent: 'space-between',
        marginBottom: 20,
        [theme.breakpoints.down('sm')]: {
            flexWrap: 'wrap',
        },
    },
    notifyingBoxContainer: {
        [theme.breakpoints.down('sm')]: {
            marginTop: -14,
        },
    },
    notifyingBox: {
        color: '#fff',
        '-webkit-transition': 'width 1s' /* For Safari 3.1 to 6.0 */,
        transition: 'width 1s',
        [theme.breakpoints.down('sm')]: {
            width: '49%',
            marginBottom: 2,
        },
    },
    large: {
        justifyContent: 'space-between',
        width: '66%',
        display: 'block',
        float: 'left',
        margin: '0 1% 0 0',
    },
    small: {
        width: '32%',
        display: 'block',
        float: 'left',
        margin: '0 0 0 1%',
    },
    titleContainer: {
        display: 'flex',
        width: '100%',
        padding: '20px 20px 0 20px',
    },
    titleStyle: {
        fontSize: '25px',
        fontWeight: 'normal',
        color: BLACK_FONT,
    },
    contentContainer: {
        display: 'flex',
        width: '100%',
    },
    col_1: {
        float: 'left',
        justifyContent: 'space-between',
        width: 'calc(calc(calc(calc(100% / 3) * 2) - 20px) + calc(40px / 3))',
        marginRight: '20px',
        [theme.breakpoints.only('sm')]: {
            width: '100% !important',
        },
        [theme.breakpoints.only('xs')]: {
            width: '100% !important',
        },
    },
    col_2: {
        float: 'left',
        width: 'calc(calc(100% / 3) - calc(40px / 3))',
        [theme.breakpoints.only('sm')]: {
            width: '100% !important',
        },
        [theme.breakpoints.only('xs')]: {
            width: '100% !important',
        },
    },
    width50sm: {
        [theme.breakpoints.only('sm')]: {
            width: 'calc(50% - 10px) !important',
            marginRight: '20px !important',
        },
    },
    width50smLast: {
        [theme.breakpoints.only('sm')]: {
            width: 'calc(50% - 10px) !important',
        },
    },
});
