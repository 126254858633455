// import materials
import { withStyles } from '@material-ui/core/styles';

// import packages
import PropTypes from 'prop-types';
import React from 'react';

// import icons
import Fullscreen from 'mdi-material-ui/Fullscreen';
import FullscreenExit from 'mdi-material-ui/FullscreenExit';
import Refresh from 'mdi-material-ui/Refresh';
import Minus from 'mdi-material-ui/Minus';
import Plus from 'mdi-material-ui/Plus';
import Close from 'mdi-material-ui/Close';

const stopDefaultBehaviour = (e) => {
    e.preventDefault();
    e.stopPropagation();
};
function ChartHeader({ classes, title, maximise, refresh, minimise, close, open, handleClick, isEnlarged }) {
    return (
        <div
            aria-hidden
            onClick={
                handleClick
                    ? (e) => {
                          stopDefaultBehaviour(e);
                          handleClick();
                      }
                    : null
            }
            style={handleClick ? { cursor: 'pointer' } : {}}
            className={classes.rootContainer}
        >
            {title}
            <div className={classes.actionsContainer}>
                {maximise ? (
                    <span
                        aria-hidden
                        onClick={(e) => {
                            stopDefaultBehaviour(e);
                            maximise();
                        }}
                        className={`${classes.boxShadow} ${classes.fullScreenSpan}`}
                    >
                        {isEnlarged ? <FullscreenExit className={classes.colorA4} /> : <Fullscreen className={classes.colorA4} />}
                    </span>
                ) : null}
                {refresh ? (
                    <span
                        aria-hidden
                        onClick={(e) => {
                            stopDefaultBehaviour(e);
                            refresh();
                        }}
                        className={`${classes.boxShadow} ${classes.refreshSpan}`}
                    >
                        <Refresh className={classes.transform} />
                    </span>
                ) : null}
                {minimise && !isEnlarged ? (
                    <span
                        aria-hidden
                        onClick={(e) => {
                            stopDefaultBehaviour(e);
                            minimise();
                        }}
                        className={`${classes.boxShadow} ${classes.minusPlusBackground}`}
                    >
                        {open ? <Minus className={classes.colorWhite} /> : <Plus className={classes.colorWhite} />}
                    </span>
                ) : null}
                {close ? (
                    <span
                        aria-hidden
                        onClick={(e) => {
                            stopDefaultBehaviour(e);
                            close();
                        }}
                        className={`${classes.boxShadow} ${classes.closeBackground}`}
                    >
                        <Close className={classes.colorWhite} />
                    </span>
                ) : null}
            </div>
        </div>
    );
}

// Styles
const styles = () => ({
    rootContainer: {
        width: '100%',
        padding: '15px 15px 5px 15px !important',
        backgroundColor: '#ffffff',
        display: 'flex',
        justifyContent: 'space-between',
    },
    actionsContainer: {
        height: 20,
        overflow: 'hidden',
        marginTop: '-4px',
        '&>span': {
            borderRadius: '100%',
            backgroundColor: '#fff',
            height: 15,
            width: 15,
            padding: 1,
            display: 'inline-block',
            margin: '1px 4px',
            cursor: 'pointer',
            '&>svg': {
                width: 13,
                height: 13,
                marginBottom: 10,
            },
        },
    },
    colorWhite: {
        color: '#fff',
    },
    boxShadow: {
        boxShadow: '0 2px 4px 0 rgba(0,0,0,0.35)',
    },
    closeBackground: {
        backgroundColor: '#ff4d55',
    },
    minusPlusBackground: {
        backgroundColor: '#8898a8 !important',
    },
    fullScreenSpan: {
        backgroundColor: '#ffffff',
    },
    colorA4: {
        color: '#4a4a4a',
    },
    refreshSpan: {
        backgroundColor: '#00aeac',
    },
    transform: {
        transform: 'rotate(47deg) scaleX(-1)',
    },
});

ChartHeader.propTypes = {
    classes: PropTypes.shape({}).isRequired,
    title: PropTypes.any.isRequired,
    maximise: PropTypes.func,
    refresh: PropTypes.func,
    minimise: PropTypes.func,
    close: PropTypes.func,
    open: PropTypes.bool,
};

ChartHeader.defaultProps = {
    open: true,
    maximise: null,
    refresh: null,
    minimise: null,
    close: null,
};

export default withStyles(styles)(ChartHeader);
