// material-ui component
import { withStyles } from '@material-ui/core/styles';
import { TableCell, TableHead, TableRow, TableSortLabel, Typography } from '@material-ui/core';

// lib import
import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
// import icons
import { faChevronDown, faChevronUp } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Checkbox from '@material-ui/core/Checkbox';
import ColumnResizer from 'column-resizer';
import { TABLES_CSS } from '../../common/cssConstants';
import { checkArrayLength } from '../../utils/utils';

// constants and variables
const styles = () => ({
    actionStyle: {
        width: '200px !important',
    },
    cell: {
        flexDirection: 'row',
        fontSize: TABLES_CSS.headingFontSize,
        fontWeight: TABLES_CSS.headingFontWeight,
        color: TABLES_CSS.headingFontColor,
        textAlign: 'left',
    },
    checkboxSpacing: {
        padding: '0px 0px 0px 20px',
        width: '8%',
    },
    lockCell: {
        width: 48,
    },
    nonSortableContainer: {
        cursor: 'default',
        display: 'inline-flex',
        alignItems: 'center',
        flexDirection: 'inherit',
        justifyContent: 'flex-start',
    },
    sortIcon: {
        fontSize: 10,
        marginLeft: 10,
        color: '#9B9B9B',
    },
    tableHeadContainer: {
        backgroundColor: '#ffffff',
        color: '#000',
    },
    borders: {},
    extraPadding: {
        paddingLeft: '10px !important',
        borderBottom: '2px solid rgba(224, 224, 224, 1)',
    },
    extraPaddingTwo: {
        paddingLeft: '20px !important',
    },
});

class EnhancedTableHeadBase extends React.Component {
    constructor() {
        super();
        this.state = {
            allowRemoveWidth: true,
        };
    }

    componentDidMount() {
        this.enableResize();
    }

    UNSAFE_componentWillReceiveProps(nextProps) {
        const { isRightPanelOpened, isSideBarOpened } = this.props;
        if (isRightPanelOpened !== nextProps.isRightPanelOpened || isSideBarOpened !== nextProps.isSideBarOpened) {
            this.disableResize(true); // sent true for hard reset invoke function only under ths condition
        }
    }

    componentDidUpdate() {
        this.enableResize();
        this.disableResize();
        this.enableResize();
    }

    componentWillUnmount() {
        this.disableResize();
    }

    createSortHandler = (property) => () => {
        this.props.onRequestSort(property);
    };

    removeLastColumnWidth = () => {
        this.setState({ allowRemoveWidth: false });
        const { getTable } = this.props;
        const myTbl = getTable();
        const tr = myTbl && myTbl.children[0] && myTbl.children[0].children[0];
        const classes = tr.lastElementChild.classList;
        const classesArray = classes.value.split(' ');
        const myClass = checkArrayLength(classesArray) && classesArray.find((cl) => cl.includes('actionStyle'));
        classes.remove(myClass);
    };

    // Start Table Resize
    enableResize = () => {
        const { getTable } = this.props;
        if (getTable) {
            const myTbl = getTable();
            if (myTbl) {
                const options = {
                    liveDrag: true,
                    minWidth: 40,
                    draggingClass: 'rangeDrag',
                    gripInnerHtml:
                        "<div class='rangeGrip'><svg aria-hidden='true' data-prefix='fas' data-icon='sort-down' class='svg-inline--fa fa-sort-down fa-w-10' role='img' xmlns='http://www.w3.org/2000/svg' viewBox='0 0 320 512' style='color: rgb(155, 155, 155); position: absolute; top: -9px; right: -5px;'><path fill='currentColor' d='M41 288h238c21.4 0 32.1 25.9 17 41L177 448c-9.4 9.4-24.6 9.4-33.9 0L24 329c-15.1-15.1-4.4-41 17-41z'></path></svg></div>",
                    resizeMode: 'fit',
                    // headerOnly: true,
                    onDrag: () => {
                        if (this.state.allowRemoveWidth) this.removeLastColumnWidth();
                    },
                };
                this.resizer = new ColumnResizer(myTbl, options);
            }
        }
    };

    disableResize = (hardReset = false) => {
        const { getTable } = this.props;
        if (getTable && hardReset) {
            // Remove th inline width forcefully to reset table width
            const myTbl = getTable();
            const tr = myTbl && myTbl.children[0] && myTbl.children[0].children[0];
            const count = tr ? tr.childElementCount : 0;
            for (let i = 0; i < count; i++) {
                tr.children[i].style.width = '';
            }
        }
        if (this.resizer) {
            this.resizer.reset({ disable: true });
        }
    };
    // End Table Resize

    render() {
        const { classes, headers, order, orderBy, showSelectBtn, rowCount, numSelected, inActiveCount, isColSpan, onSelectAllClick } = this.props;
        return (
            <TableHead>
                <TableRow style={{ height: '40px' }}>
                    {/* {checkArrayLength(headers) && headers.length === 2 && (
                        <TableCell
                            className={[classes.borders, classes.extraPadding].join(" ")}
                            style={{ overflow: 'hidden', width: 180 }}
                        ></TableCell>
                    )} */}
                    {headers.map((column, index) =>
                        (column.status && column.status === 'InActive') || (column.checked && column.checked === true) ? null : (
                            <TableCell
                                colSpan={isColSpan ? (column.colSpan ? column.colSpan : 1) + (inActiveCount && index === 0 ? inActiveCount : 0) : 1}
                                key={column.id}
                                numeric={column.numeric}
                                sortDirection={orderBy === column.id ? order : false}
                                className={[classes.borders, classes.extraPadding, column.id === 'Actions' ? classes.actionStyle : ''].join(' ')}
                                style={{ overflow: 'hidden' }}
                            >
                                <span style={column.styles ? { ...column.styles, display: 'flex' } : { display: 'flex' }}>
                                    {index === 0 && showSelectBtn ? (
                                        <Checkbox
                                            indeterminate={numSelected > 0 && numSelected < rowCount}
                                            checked={numSelected === rowCount}
                                            onChange={(e) => {
                                                onSelectAllClick(e);
                                            }}
                                            style={{
                                                height: '40px',
                                                width: '20px',
                                                marginRight: '10px',
                                                color: numSelected === rowCount ? '#3b91df' : '#bac4ce',
                                            }}
                                        />
                                    ) : null}
                                    {column.sortable ? (
                                        <TableSortLabel hideSortIcon onClick={this.createSortHandler(column.id)}>
                                            <Typography
                                                variant="body2"
                                                style={{
                                                    fontSize: TABLES_CSS.headingFontSize,
                                                    color: TABLES_CSS.headingFontColor,
                                                    fontWeight: TABLES_CSS.headingFontWeight,
                                                }}
                                                noWrap
                                            >
                                                {column.label}
                                                {orderBy === column.id ? (
                                                    order === 'desc' ? (
                                                        <FontAwesomeIcon
                                                            icon={faChevronDown}
                                                            aria-label="faChevronDown"
                                                            id="faChevronDown"
                                                            className={classes.sortIcon}
                                                        />
                                                    ) : (
                                                        <FontAwesomeIcon
                                                            icon={faChevronUp}
                                                            aria-label="faChevronUp"
                                                            id="faChevronUp"
                                                            className={classes.sortIcon}
                                                        />
                                                    )
                                                ) : null}
                                            </Typography>
                                        </TableSortLabel>
                                    ) : (
                                        <div className={classes.nonSortableContainer}>
                                            <Typography
                                                variant="body2"
                                                style={{
                                                    fontSize: TABLES_CSS.headingFontSize,
                                                    color: TABLES_CSS.headingFontColor,
                                                    fontWeight: TABLES_CSS.headingFontWeight,
                                                }}
                                                noWrap
                                            >
                                                {column.label}
                                            </Typography>
                                        </div>
                                    )}
                                </span>
                            </TableCell>
                        ),
                    )}
                </TableRow>
            </TableHead>
        );
    }
}

EnhancedTableHeadBase.propTypes = {
    classes: PropTypes.object.isRequired,
    onRequestSort: PropTypes.func,
    order: PropTypes.string,
    orderBy: PropTypes.string,
    headers: PropTypes.array.isRequired,
};

EnhancedTableHeadBase.defaultProps = {
    order: 'asc',
    orderBy: '',
    onRequestSort: () => {},
};

const mapStateToProps = (state) => {
    return {
        isRightPanelOpened: state.projectReducer.isRightPanelOpened,
        isSideBarOpened: state.projectReducer.isSideBarOpened,
    };
};

export const EnhancedTableHead = connect(mapStateToProps, null)(withStyles(styles)(EnhancedTableHeadBase));
