import React, { Component } from 'react';
import 'react-datepicker/dist/react-datepicker.css';
import { withStyles } from '@material-ui/core/styles';
import { connect } from 'react-redux';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import Typography from '@material-ui/core/Typography';
import TextField from '@material-ui/core/TextField';
import { BLACK_FONT, FLEX_CENTRE } from '../../../common/cssConstants';
import { generalModalActions } from '../../../store/actions';
import GeneralModalFooter from '../../modal/GeneralModal/GeneralModalFooter';
import { CANCEL_BUTTON, NEXT_SUBMIT_BUTTON } from '../../../components/Button/Btn';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faLinux, faWindows, faApple, faAndroid } from '@fortawesome/free-brands-svg-icons';
import { styles } from './styles';

//Platfroms Icons
import windows_Icon from '../../../assets/icons/platforms/windows.svg';
import linux_Icon from '../../../assets/icons/platforms/linux.svg';
import android_Icon from '../../../assets/icons/platforms/android.svg';
import macos_Icon from '../../../assets/icons/platforms/macos.svg';
import default_platform_icon from '../../../assets/icons/platforms/default.svg';



class OverviewPanel extends Component {
    constructor() {
        super();
            this.state = {
            localContent: [{
                environmentType: 'Local',
                browser: 'Chrome (headless)',
                platformVersion: '',
                browserVersion: '',
                platform: 'Linux',
                browserEl: null,
                platformEl: null,
            }],
            sauceContent: [{
                environmentType: 'Saucelabs',
                browser: 'Chrome',
                platform: 'Windows',
                platformEl: null,
                browserEl: null,
                idEl: null,
                browserVersion: '92.0',
                platformVersion: '',
                tunnelID: '',
            }],
            tabState: 'Saucelabs',
        }
    }

    componentDidMount() {
        const { sauceContentData, localContentData, tabStateData } = this.props;
        const { sauceContent, localContent,tabState } = this.state;
        this.setState({
            sauceContent: sauceContentData,
            localContent: localContentData,
            tabState: tabStateData
        })
    }

    getIconToDisplay = (platformName='') => {
        const { classes } = this.props;

        platformName = platformName.split(' ')[0];
        switch (platformName?.toLowerCase()) {
            case 'windows':
                return <img src={windows_Icon} alt="Windows" className={classes.platformIcon} />;
            case 'linux':
                return <img src={linux_Icon} alt="Linux" className={classes.platformIcon} />;
            case 'android':
                return <img src={android_Icon} alt="Android" className={classes.platformIcon} />;
            case 'macos':
                return <img src={macos_Icon} alt="MacOS" className={classes.platformIcon} />;
    
            default:
                return <img src={default_platform_icon} alt="Default" className={classes.platformIcon} />;

        }

    }

    render() {
        const { classes, localContent, sauceContent, externalGrid, sauceCredentials, errorTestCases, callingFrom, handleExecution, handleGenerateCase, tabState, configurationSetting, tag, type, sauceConfigurationNotFound, localConfigurationNotFound, handleClose, executeBy} = this.props;



        return (
                <DialogContent className={classes.rightContainerStyle}>
                    <div className={classes.overviewContainer}>
                        <Typography className={classes.headingOverview}>Overview</Typography>
                    </div>
                    <div className={classes.contentContainer}>
                        <Typography className={classes.headingStyle}>Sauce Labs</Typography>
                        {sauceContent.length && sauceCredentials?.sauceCredentialId && sauceContent[0].platform && (sauceContent[0].environmentType.toLowerCase() === 'saucelabs' && sauceContent[0].browser || sauceContent[0].device) && sauceConfigurationNotFound && ((callingFrom?.toLowerCase() === 'generate' && tabState?.toLowerCase() === 'saucelabs') || (callingFrom?.toLowerCase() === 'execute')) ? sauceContent.map((val, ind) => {
                            if (val.platform && (val.environmentType.toLowerCase() === 'saucelabs' && val.browser || val.device)) {
                                return (
                                    <div key={`${val.platform}-${val.environmentType.toLowerCase() === 'saucelabs' && val.browser || val.device}-${val.environmentType.toLowerCase() === 'saucelabs' && val.browserVersion || val.deviceVersion}-${ind}`} className={classes.platformBrowserselection}>
                                        {this.getIconToDisplay(val.platform || '')}
                                        <Typography className={classes.selectionStyle}>{val.label}</Typography>
                                    </div>
                                )
                            } else {
                                return null
                            }
                        }) : (
                            <div className={classes.platformBrowserselection}>
                                <Typography className={classes.noSelectionStyle}>You have no selection</Typography>
                            </div>
                        )}
                        <Typography className={classes.headingStyle}>Local</Typography>
                        {localContent.length && localContent[0].platform && localContent[0].browser && localConfigurationNotFound && ((callingFrom?.toLowerCase() === 'generate' && tabState?.toLowerCase() === 'local') || (callingFrom?.toLowerCase() === 'execute')) ? localContent.map((val, ind) => {
                            if (val.platform && val.platform.toLowerCase() !== 'select' && val.browser && val.browser.toLowerCase() !== 'select') {
                                return (
                                    <div key={`${val.platform}-${val.browser}-${val.browserVersion}-${ind}`} className={classes.platformBrowserselection}>
                                        {this.getIconToDisplay(val.platform)}
                                        <Typography className={classes.selectionStyle}>
                                            {configurationSetting.toLowerCase() === 'newconfiguration' ? `${val.platform.toLowerCase() !== 'select' ? val.platform : ''} . ${val.browser.toLowerCase() !== 'select' ? val.browser : ''}`
                                            :
                                            `${val.platform.toLowerCase() !== 'select' ? val.platform : ''} . ${val.browser.toLowerCase() !== 'select' ? val.browser : ''} ${val.environmentType.toLowerCase() === 'local' ? '(headless)' : '(headful)'}`
                                            }
                                        </Typography>
                                    </div>
                                )
                            } else {
                                return null
                            }
                        }) : (
                            <div className={classes.platformBrowserselection}>
                                <Typography className={classes.noSelectionStyle}>You have no selection</Typography>
                            </div>
                        )}
                        <Typography className={classes.headingStyle}>Remote</Typography>
                        {externalGrid.length && externalGrid[0].platform && externalGrid[0].browser && ((callingFrom?.toLowerCase() === 'generate' && tabState?.toLowerCase() === 'externalgrid') || (callingFrom?.toLowerCase() === 'execute')) ? externalGrid.map((val, ind) => {
                            if (val.platform && val.platform.toLowerCase() !== 'select' && val.browser && val.browser.toLowerCase() !== 'select') {
                                return (
                                    <div key={`${val.platform}-${val.browser}-${val.browserVersion}-${ind}`} className={classes.platformBrowserselection}>
                                        {this.getIconToDisplay(val.platform)}
                                        <Typography className={classes.selectionStyle}>{`${val.platform} . ${val.browser} ${val.browserVersion}`}</Typography>
                                    </div>
                                )
                            } else {
                                return null
                            }
                        }) : (
                            <div className={classes.platformBrowserselection}>
                                <Typography className={classes.noSelectionStyle}>You have no selection</Typography>
                            </div>
                        )}
                    </div>
                    <div className={classes.btnContainer}>
                        <GeneralModalFooter
                            buttons={[
                                NEXT_SUBMIT_BUTTON({
                                    name: (callingFrom === 'Execute' || !callingFrom) ? 'Execute' : 'Generate',
                                    action: () => {
                                        if ((callingFrom === 'Execute' || !callingFrom)) {
                                            if (errorTestCases.length) {
                                                this.props._setState({ openModal: true });
                                            } else {
                                                handleExecution();
                                            }
                                        } else if (callingFrom === 'Generate') {
                                            handleGenerateCase()
                                        }
                                    },
                                    isDisabled: (executeBy === 'tags' && !tag),
                                    style: { border: '1px solid #1067cd', margin: `${type !== 'suite' ? '25px 11px 0px 20px' : '42px 11px 0px 20px'}` },
                                }),
                                CANCEL_BUTTON({ 
                                        action: handleClose,
                                        style: { marginTop: `${type !== 'suite' ? '25px' : '42px' }` },
                                    }),
                                ]}
                            />
                    </div>
                </DialogContent>
            
        );
    }
}

const mapStateToProps = (state) => {
    return {
        tabsData: state.generalModalReducer.tabsData,
        users: state.userReducer.users,
        activeUser: state.authReducer.user,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        updateTabData: (...args) => dispatch(generalModalActions.updateTabData(...args)),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(OverviewPanel));
