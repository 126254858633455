// import materials
import { CircularProgress, Typography } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
// import packages
import { connect } from 'react-redux';
import React, { Component } from 'react';
import PropTypes from 'prop-types';

// import custom components
import { checkObject, getUserPreference, sleep } from '../../../utils/utils';
import {
    ModalActions,
    ProjectActions,
    TaskAction,
    AccountActions,
    FlowActions,
    GeneralActions,
    SelectedTestCaseActions,
} from '../../../store/actions';
import Header from './Header/Header';
import LiveTestSteps from './LiveSteps/LiveTestSteps';
import OriginalTestStepsTable from './OriginalTestStepsTable';
import TestStepsModals from './TestStepsModals';
import projectService from '../../../services/projectServices';
// import WSService from '../../../services/WSService';
import { TestCaseUtils } from '../../../utils';
import NoStepContainer from './LiveSteps/NoStepContainer';

// Styles
const styles = () => ({
    width: {
        width: '100%',
    },
    container: {
        display: 'flex',
        height: 'calc(100vh - 185px)',
        justifyContent: 'center',
        minHeight: 100,
        overflow: 'visible',
    },
    circularProgress: {
        margin: '70px 45% 26px',
        color: '#4885ed',
    },
    noStepMessage: {
        fontSize: 17,
        textAlign: 'center',
        paddingTop: 24,
    },
    root: {
        width: 'calc(100% - 40px)',
        backgroundColor: '#ececec',
        margin: '0 20px',
    },
    notExistColor: {
        color: '#78909c',
        textAlign: 'center',
        marginBottom: 12,
        paddingTop: '20%',
        fontSize: '20px',
    },
});

// Global functions
let onClick_nextPrevTestStepModal = () => {};
let isReRendered = false;
let isNextPrev = '';
const initialState = {
    selectedTab: 0, // To toggle b/w steps (enum: 0/1)
};

class TestSteps extends Component {
    constructor(props) {
        super(props);
        this.listContainer = React.createRef(); // List container refrence
        this.projectId = null; // Get from URL Param
        this.testCaseId = null; // Get from URL Param
        this.is_mounted = false;
        this.state = initialState;
        this.props.updateTitle('Step Editor');
    }

    /* Component life cycle start */

    componentDidMount() {
        this.onDidMount();
    }

    UNSAFE_componentWillReceiveProps(nextProps) {
        if (this.is_mounted) {
            const {
                match: { params },
            } = nextProps;
            this.projectId = checkObject(params) ? params.projectId : 0;
            this.testCaseId = checkObject(params) ? params.testCaseId : 0;
            if (!nextProps.stepsModalDataLoading && this.props.stepsModalDataLoading) {
                isNextPrev = '';
            }
        }
    }

    componentDidUpdate() {
        if (this.is_mounted) {
            this.scrollToBottom();
        }
    }

    componentWillUnmount() {
        isNextPrev = '';
        this.onWillUnmount();
    }

    onDidMount = () => {
        this.is_mounted = true;
        const {
            match: { params },
        } = this.props;
        const obj = {};

        this.projectId = checkObject(params) ? params.projectId : 0;
        this.testCaseId = checkObject(params) ? params.testCaseId : 0;

        setTimeout(() => {
            this.props.updateProjectId(this.projectId);
            this.props.updateTestCaseId(this.testCaseId);
            //  this.props.getMarket();
            this.props.getFlowDataByProject(this.projectId);
        }, 210);

        // Prevent page reloading if testCaseId is equals to -1
        const testCaseId = window.location && window.location.pathname && window.location.pathname.split('/').pop();
        if (`${testCaseId}` === '-1') {
            TestCaseUtils.allowRetryTestCase(testCaseId, true);
            this.updateReRenderPermission(true);
            const _projectId = checkObject(params) ? params.projectId : 0;
            window.addEventListener('beforeunload', this.preventPageReloading);
            window.addEventListener('load', () => {
                // redirect to project screen
                window.location.href = `${window.location.origin}/details/${_projectId}/cases`;
            });
        }

        // this.ws = await WSService.getWebSocketInstance(); // Need to verify (Tahir)

        this.props.toggleListView(getUserPreference('listview'));
        this.setStateIfComponentMounted(obj);
    };

    onWillUnmount = () => {
        // remove both listeners on unmount
        window.removeEventListener('beforeunload', this.preventPageReloading);
        window.removeEventListener('load', () => {});
        window.onbeforeunload = undefined;
        this.props.clearSelectedTestCaseData();
        this.props.clearTestCase();
        this.setStateIfComponentMounted(initialState);
        this.props.updateProjectId(null);
        this.props.updateTestCaseId(null);
        this.props.clearTestData();
        TestCaseUtils.allowRetryTestCase(this.testCaseId, true);
        this.updateReRenderPermission(false);
        this.is_mounted = false;
    };
    /* Component life cycle end */

    /* Teststep modal misc functions start */
    onClick_nextPrev = async (status, callback) => {
        onClick_nextPrevTestStepModal();
        isNextPrev = status;
        callback();
        this.onWillUnmount();
        await sleep(200);
        this.onDidMount();
    };

    /* Retry test step start */

    setStateIfComponentMounted = (obj, callback = () => {}) => {
        if (this.is_mounted) this.setState(obj, callback);
    };

    handleonClick_nextPrevTestStepModal = (cb) => {
        onClick_nextPrevTestStepModal = cb;
    };
    /* Retry test step end */

    handleTabs = (value) => {
        this.setStateIfComponentMounted({ selectedTab: value });
    };

    preventPageReloading = (e) => {
        // Cancel the event
        e.preventDefault();
        // Chrome requires returnValue to be set
        e.returnValue = 'Do You really want to go back? All your work will be lost.';
    };

    scrollToBottom = () => {
        // TODO: get working!
        if (this.listContainer.elem) {
            this.listContainer.elem.scrollTop = this.listContainer.elem.scrollHeight;
        }
    };
    /* Teststep modal misc functions end */

    /* Empty steps message start */
    showEmptyMessageOrLoader = (msg) => {
        const { classes, stepsModalDataLoading } = this.props;
        return stepsModalDataLoading || (!isReRendered && `${this.testCaseId}` !== '-1') ? (
            <div className={classes.width}>
                <CircularProgress className={classes.circularProgress} size={60} />
            </div>
        ) : (
            <NoStepContainer text={msg || 'Step does not exist!'} />
        );
    };
    /* Empty steps message end */

    updateReRenderPermission = (flag) => {
        isReRendered = flag;
    };

    handleDownloadMarketPlace = async (marketData, fileName) => {
        const encodeURL = `data:text/plain;charset=utf-8,${encodeURIComponent(marketData)}`;
        projectService.downloadTest(null, fileName, encodeURL, this.props.showSnackBar);
    };

    previewMarketPlace = async (marketType) => {
        const { toggleModal } = this.props;
        const filename = `YAML MARKET_${marketType}.yaml`;
        const marketData = await projectService.getMarket(marketType);
        if (marketData)
            toggleModal('filePreviewModal', null, null, {
                data: marketData,
                name: `YAML MARKET_${marketType}`,
                modalName: 'MarketModal',
                fileName: filename,
                handleDownload: () => {
                    this.handleDownloadMarketPlace(marketData, filename);
                },
            });
    };

    renderLoader = () => {
        const { testcaseError, classes } = this.props;
        if (testcaseError) {
            return (
                <Typography variant="h1" className={classes.notExistColor}>
                    Testcase does not exist!
                </Typography>
            );
        }
        return <CircularProgress className={classes.circularProgress} size={60} />;
    };

    renderLoader = () => {
        const { testcaseError, classes } = this.props;
        if (testcaseError) {
            return (
                <Typography variant="headline" className={classes.notExistColor}>
                    Testcase does not exist!
                </Typography>
            );
        }
        return <CircularProgress className={classes.circularProgress} size={60} />;
    };

    render() {
        const { classes, history, match, stepsModalDataLoading } = this.props;
        const { selectedTab } = this.state;

        const liveStepsContent = (
            <LiveTestSteps
                projectId={this.projectId}
                showEmptyMessageOrLoader={this.showEmptyMessageOrLoader}
                startTestUpdater={/* startTestUpdater */ () => {}}
            />
        );
        /* First Tab Content End */

        /* Second Tab Content Start */
        const originalStepsContent = <OriginalTestStepsTable showEmptyMessageOrLoader={this.showEmptyMessageOrLoader} />;
        /* Second Tab Content End */

        return (
            <div className={classes.root}>
                <Header
                    headerMiddleActions={{
                        onClick_nextPrev: this.onClick_nextPrev,
                        handleTabs: this.handleTabs,
                        previewMarketPlace: this.previewMarketPlace,
                    }}
                    history={history}
                    isNextPrev={isNextPrev}
                    paramProjectId={this.projectId}
                    selectedTab={selectedTab}
                />
                <div className={classes.container}>
                    {stepsModalDataLoading || (!isReRendered && `${this.testCaseId}` !== '-1') ? (
                        <div className={classes.width}>{this.renderLoader()}</div>
                    ) : selectedTab === 0 ? (
                        liveStepsContent
                    ) : selectedTab === 1 ? (
                        originalStepsContent
                    ) : (
                        <div className={classes.width}>
                            {' '}
                            <NoStepContainer text="Step does not exist!" />{' '}
                        </div>
                    )}
                </div>
                <TestStepsModals
                    handleonClick_nextPrevTestStepModal={this.handleonClick_nextPrevTestStepModal}
                    match={match}
                    selectedTab={selectedTab}
                    updateReRenderPermission={this.updateReRenderPermission}
                />
            </div>
        );
    }
}

TestSteps.propTypes = {
    classes: PropTypes.shape({}).isRequired,
};

const mapStateToProps = (state) => {
    return {
        // Auth Reducer
        user: state.authReducer.user,

        // Project Reducers
        stepsModalDataLoading: state.projectReducer.stepsModalDataLoading,
        testcaseError: state.projectReducer.invalidUrl,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        clearTestCase: () => dispatch(ProjectActions.clearTestCase()),
        clearTestData: () => dispatch(ProjectActions.clearTestData()),
        // modal actions
        toggleModal: (...args) => dispatch(ModalActions.toggleModal(...args)),
        showSnackBar: (...args) => dispatch(ModalActions.toggleSnackBar(...args)),
        // Task actions
        updateProjectId: (...args) => dispatch(TaskAction.updateProjectId(...args)),
        updateTestCaseId: (...args) => dispatch(TaskAction.updateTestCaseId(...args)),

        // Account actions
        getMarket: () => dispatch(AccountActions.getMarket()),

        // Flow actions
        getFlowDataByProject: (...args) => dispatch(FlowActions.getFlowDataByProject(...args)),

        // General Actions
        updateTitle: (...args) => dispatch(GeneralActions.updateTitle(...args)),
        toggleListView: (...args) => dispatch(SelectedTestCaseActions.toggleListView(...args)),
        // Selected Test Case Actions
        clearSelectedTestCaseData: (...args) => dispatch(SelectedTestCaseActions.clearSelectedTestCaseData(...args)),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(TestSteps));
