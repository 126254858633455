import React, { useMemo, useState } from 'react';
import { withStyles } from '@material-ui/core/styles';
import { connect } from 'react-redux';
import Typography from '@material-ui/core/Typography';

import PlatformBrowserSelector from '../../common/PlatformBrowserSelector/PlatformBrowserSelector';
import Radio from '@material-ui/core/Radio';
import { IconButton,  CircularProgress } from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import CloseIcon from '@material-ui/icons/Close';

import styles from './ExecutionSettingStyles';

const SaucelabsExecutionEnvironmentSettings = ({selectedDevicesForSauce,selectedBrowsersForSauce,defaultSelect,handleDefault,addRowSaucelabs,deleteRowSaucelabs,data, onSelectPlatformBrowser,onSelectSauceDevice, addRowForSaucelabsDevice, deleteRowForSaucelabsDevice,classes}) => {

    const [isDeviceSelectorExpanded,setisDeviceSelectorExpanded] = useState(false);

    const [activeTab,setActiveTab] = useState("CROSS_BROWSERS");
    const getPermisions = localStorage.getItem('accountPermission');

    const render_crossbrowsers = useMemo(() => {

            const rowsForSauce = [];
            selectedBrowsersForSauce.forEach((selectedBrowser,i)=>{

                let selectedBrowserLabel = '';
                if(selectedBrowser.label) {
                    selectedBrowserLabel = selectedBrowser.label
                } else if(data && data.browsers_capabilities_list) {
                    
                    let found = data.browsers_capabilities_list.find((browser)=>{
                         return browser.id === selectedBrowser.id
                     })
                     if(found) {
                        selectedBrowserLabel = found.label     
                     }
                }

    
                rowsForSauce.push(
                    <div style={{paddingLeft:'6px'}} className={classes.selectionContainer} key={i}>
                        <div className={classes.radioButtonGroupContainer}>
                            <Radio
                                checked={defaultSelect === `${i}_saucelabsExpanded`}
                                onChange={(e) => handleDefault(i,e,'saucelabsExpanded')}
                                value= {`${i}_saucelabsExpanded`}
                                name="radio-button-demo"
                                inputProps={{ 'aria-label': 'A' }}
                            />
                        </div>
                        <PlatformBrowserSelector data={data.browsers_capabilities_data_map} onChange={(data)=>{
                            onSelectPlatformBrowser(i,data)
                        }} onClickDeivceSelector={()=>{
                            setisDeviceSelectorExpanded(true)
                        }} isPlatformBrowserSelectorOpen={isDeviceSelectorExpanded} selected={{id:selectedBrowser.id,label:selectedBrowserLabel}} title={'Select Platform'}/>
                        
                            <div  className={classes.actionButtonsContainer}>
                                {selectedBrowsersForSauce.length > 1 ? (
                                    <IconButton className={classes.iconButtonRoot} onClick={() => deleteRowSaucelabs(selectedBrowser.id,i)}>
                                        <CloseIcon className={classes.actionIcons} />
                                    </IconButton>
                                ) : null}
                                { (i === selectedBrowsersForSauce.length -1)  ? (
                                    <IconButton className={classes.iconButtonRoot} onClick={() => addRowSaucelabs(selectedBrowser.id)}>
                                        <AddIcon className={`${classes.actionIcons}`} />
                                    </IconButton>
                                ) :(
                                    <div className={classes.iconButtonRoot} >
                                </div>
    
                                ) }
                            </div> 
                    </div>
                );
    
    
            });
    
            return rowsForSauce;
        },[selectedBrowsersForSauce,defaultSelect])
    
    const render_realdevices= useMemo(() => {

            const rowsForSauceDevices = [];
            selectedDevicesForSauce.forEach((selectedDevice,i)=>{
    
                rowsForSauceDevices.push(
                    <div style={{paddingLeft:'6px'}} className={classes.selectionContainer} key={i}>
                        <div className={classes.radioButtonGroupContainer}>
                            <Radio
                                checked={defaultSelect === `${i}_saucelabsDeviceExpanded`}
                                onChange={(e) => handleDefault(i,e,'saucelabsDeviceExpanded')}
                                value= {`${i}_saucelabsExpanded`}
                                name="radio-button-demo"
                                inputProps={{ 'aria-label': 'A' }}
                            />
                        </div>
                        <PlatformBrowserSelector data={data.devices_capablities_data_map} onChange={(data)=>{
                            onSelectSauceDevice(i,data)
                        }} onClickDeivceSelector={()=>{
                            setisDeviceSelectorExpanded(true)
                        }} isPlatformBrowserSelectorOpen={isDeviceSelectorExpanded} selected={{id:selectedDevice.id,label:selectedDevice.label}} title={'Select Device'}/>
                        
                            <div  className={classes.actionButtonsContainer}>
                                {selectedDevicesForSauce.length > 1 ? (
                                    <IconButton className={classes.iconButtonRoot} onClick={() => deleteRowForSaucelabsDevice(selectedDevice.id,i)}>
                                        <CloseIcon className={classes.actionIcons} />
                                    </IconButton>
                                ) : null}
                                { (i === selectedDevicesForSauce.length -1)  ? (
                                    <IconButton className={classes.iconButtonRoot} onClick={() => addRowForSaucelabsDevice(selectedDevice.id)}>
                                        <AddIcon className={`${classes.actionIcons}`} />
                                    </IconButton>
                                ) :(
                                    <div className={classes.iconButtonRoot} >
                                </div>
    
                                ) }
                            </div> 
                    </div>
                );
    
    
            });
    
            return rowsForSauceDevices;
        },[selectedDevicesForSauce,defaultSelect])



    return (
        <div style={{paddingLeft:'3%',paddingRight:'3%'}}>
            <Typography gutterBottom className={classes.headingDet}>
                Add/Remove Platforms
            </Typography>
            <div className={classes.stepper}>
                <div className={classes.step}>
                    <span onClick={()=>{
                        setActiveTab('CROSS_BROWSERS')
                    }}  className={activeTab==='CROSS_BROWSERS'?classes.activeStepVal:classes.stepVal}>Cross Browser</span>
                </div>
               { JSON.parse(getPermisions)?.permission?.realDevices && <div>
                    <span onClick={()=>{
                        setActiveTab('REAL_DEVICES')
                    }} className={activeTab==='REAL_DEVICES'?classes.activeStepVal:classes.stepVal}>Mobile</span>
                </div>

               } 
            </div>

            { activeTab==='CROSS_BROWSERS' &&  render_crossbrowsers}
            { activeTab==='REAL_DEVICES' &&  render_realdevices}

        </div>
    )

}

export default (withStyles(styles)(SaucelabsExecutionEnvironmentSettings));