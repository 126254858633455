import React from 'react';
import Tooltip from '../../../components/Tooltip/index';

import { Typography } from '@material-ui/core';
import TableRow from '../../../components/Table/TableRow';
import TableCell from '../../../components/Table/TableCell';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFileDownload } from '@fortawesome/free-solid-svg-icons';
import { faSafari, faLinux, faFirefox, faChrome, faWindows, faApple, faInternetExplorer,faEdge, faAndroid } from '@fortawesome/free-brands-svg-icons';

import ErrorIcon from '../../../assets/images/errorLogoIcon.png';
import SuccessIcon from '../../../assets/images/successIcon.png';
import LoadingIcon from '../../../assets/images/loadingIcon.png';
import singleFileCreationService from '../../../services/singleFileCreationService';
import Video from '../../../components/Video/Video';
import StopIcon from '@material-ui/icons/Stop';
import AccessTime from '@material-ui/icons/AccessTime';
import { getFormatedDate } from '../../../utils/utils';
import { Link } from 'react-router-dom';

// Devices/Browsers Icons
import firefox_Icon from '../../../assets/icons/browsers/firefox.svg';
import chrome_Icon from '../../../assets/icons/browsers/chrome.svg';
import microsoftedge_Icon from '../../../assets/icons/browsers/microsoftedge.svg';
import ie_Icon from '../../../assets/icons/browsers/ie.svg';
import safari_Icon from '../../../assets/icons/browsers/safari.svg';
import default_browser_icon from '../../../assets/icons/browsers/default.svg';

// Platforms Icons
import windows_Icon from '../../../assets/icons/platforms/windows.svg';
import linux_Icon from '../../../assets/icons/platforms/linux.svg';
import macos_Icon from '../../../assets/icons/platforms/macos.svg';
import android_Icon from '../../../assets/icons/platforms/android.svg';
import default_platform_icon from '../../../assets/icons/platforms/default.svg';
import real_deviceIcon from '../../../assets/icons/devices/real.svg';
import emulator_deviceIcon from '../../../assets/icons/devices/emulator.svg';




const ExecutionRow = (props) => {
    const { classes, headers, index, execution, showExecutionVideo, toggleSwipableTaskPanel } = props;
    const caseName = execution.executionName
        ? execution.executionName.replace(new RegExp('_exec$'), '')
        : execution.label
        ? execution.label.replace(new RegExp('_exec$'), '')
        : '';

    const getExecutionStatusIcon = (status) => {

        switch (status) {
            case 'ERROR':
                return (
                    <Tooltip data={execution.statusMessage}>
                        <img src={ErrorIcon} className={classes.statusIcon} alt="" />
                    </Tooltip>
                )
            case 'SUCCESS':
               
                return (
                    <Tooltip data={execution.statusMessage}>
                        <img src={SuccessIcon} className={classes.statusIcon} alt="" />
                    </Tooltip>
                )

                
            case 'INPROGRESS':
                return (
                    <Tooltip data={'Execution INPROGRESS'}>
                        <img style={{width:"28px", height:"13px"}}  src={LoadingIcon} className={classes.statusIcon} alt="" />
                    </Tooltip>
                )
            case 'STOPPED':
                return (
                <Tooltip data={execution.statusMessage || 'Execution STOPPED'}>
                    <StopIcon className={[classes.progressIconStyle, classes.statusInActive].join(' ')} ></StopIcon>
                </Tooltip>

                )
            case 'QUEUED':
                return (
                <Tooltip data={execution.statusMessage || 'Execution QUEUED'}>
                    <AccessTime className={[classes.progressIconStyle, classes.statusInQue].join(' ')} ></AccessTime>
                </Tooltip>
                )
            default:
                return null;
        }
    };

    const downloadReport = async (reportUrl, reportName,testCaseId,executionId) => {
        if (reportUrl) {
            await singleFileCreationService.getDownloadAiqReport(reportUrl, `${reportName}.html`, true, false, testCaseId, executionId);
        }
    };

    const getBrowserIcon = (status='') => {
        switch (status?.toLowerCase()) {
            case 'firefox':
                return <img src={firefox_Icon} alt="Firefox" className={classes.statusIcon} />;
            case 'chrome':
                return <img src={chrome_Icon} alt="Chrome" className={classes.statusIcon} />;                ;
            case 'internet explorer':
                return <img src={ie_Icon} alt="Internet Explorer" className={classes.statusIcon} />;
            case 'safari':
                return <img src={safari_Icon} alt="Firefox" className={classes.statusIcon} />;
            case 'microsoftedge':
                return <img src={microsoftedge_Icon} alt="Firefox" className={classes.statusIcon} />;
            case 'edge':
                return <img src={microsoftedge_Icon} alt="Firefox" className={classes.statusIcon} />;
    
            default:
                return <img src={default_browser_icon} alt="Default" className={classes.statusIcon} />;

        }
    };

    const getPlatformIcon = (platform='') => {
        platform = platform.split(' ')[0];
    
        switch (platform?.toLowerCase()) {
            case 'windows':
                return <img src={windows_Icon} alt="Windows" className={classes.statusIcon} />;
            case 'linux':
                return <img src={linux_Icon} alt="Linux" className={classes.statusIcon} />;                ;
            case 'macos':
                return <img src={macos_Icon} alt="macOs" className={classes.statusIcon} />;
            case 'ios':
                return <img src={macos_Icon} alt="macOs" className={classes.statusIcon} />;
            case 'android':
                return <img src={android_Icon} alt="Android" className={classes.statusIcon} />;
            default:
                return null

        }  
    };

    const getDeviceIcon = (deviceType='',deviceName='') => {
        switch (deviceType?.toLowerCase()) {
            case 'real':
                return <Tooltip data={'Real Device'} key={deviceName}><img src={real_deviceIcon} alt="Real" className={classes.statusIcon} /></Tooltip>
            case 'emulator':
                return <Tooltip data={'Emulator'} key={deviceName}><img src={emulator_deviceIcon} alt="Emulator" className={classes.statusIcon} /></Tooltip>
            default:
                return null;

        } 
    }


    return (
        <TableRow showBorder={'none'} isTableRowStyle={true} focusCustom={classes.focused} className={classes.tableRow} hover key={index} tabIndex={-1}>
            <TableCell isTableCellClass={true} width={headers[0].width} style={{ justifyContent: 'center' }} nowrap data-resize={`element${0}_c`}>
                <div style={{ width: '100%' }}>
                    <Typography noWrap className={classes.executionName}>
                       {getExecutionStatusIcon(execution?.executionStatus)}
                    </Typography>
                </div>
            </TableCell>
            <TableCell
                isTableCellClass={true} 
                className={[classes.borders, classes.noPadding, classes.cell, classes.noWrap, classes.minWidth].join(' ')}
                width={headers[1].width}
                data-resize={`element${1}_c`}
            >
                <div style={{ paddingLeft: '10px !important', width: '100%' }}>
                    <Typography
                        noWrap
                        className={classes.name}
                        onClick={() => {
                            toggleSwipableTaskPanel(false);
                        }}
                    >
                        <Tooltip data={` ${execution.executionName ? execution.executionName : execution.label}`}>
                        <Link
                            className={classes.link}
                            to={`/details/${execution.projectId}/${execution.testcaseId}/executions/${execution.executionId || execution.value}`}
                        >
                            {execution.executionName ? execution.executionName : execution.label}
                        </Link>
                        </Tooltip>
                    </Typography>
                </div>
            </TableCell>
            <TableCell
                isTableCellClass={true} 
                className={[classes.borders, classes.noPadding, classes.cell, classes.noWrap, classes.minWidth].join(' ')}
                width={headers[2].width}
                data-resize={`element${2}_c`}
            >
                <div style={{ paddingLeft: '10px !important', width: '100%' }}>
                    <Typography noWrap className={classes.dateAndStatus}>
                        <Tooltip data={getFormatedDate(execution.initiatedOn, 'HH:mm:ss', null)}>
                        {getFormatedDate(execution.initiatedOn, 'MMM, DD, YYYY HH:mm:ss', null)}
                        </Tooltip>
                    </Typography>
                </div>
            </TableCell>
            <TableCell
                isTableCellClass={true} 
                className={[classes.borders, classes.noPadding, classes.cell, classes.noWrap, classes.minWidth].join(' ')}
                width={headers[3].width}
                data-resize={`element${3}_c`}
            >
                <div style={{ paddingLeft: '10px !important', width: '100%' }}>
                    <Typography style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }} noWrap className={classes.executionName}>
                        {/* <img src={YoutubeIcon} className={classes.imgIcon} alt="" /> */}
                        {execution.executionVideoUrl && (execution.executionStatus === 'ERROR' || execution.executionStatus === 'SUCCESS') ? (
                            <Video
                                alignVideoIcon={true}
                                key="btn3"
                                videoUrl={execution.executionVideoUrl}
                                caseName={caseName}
                                showExecutionVideo={showExecutionVideo}
                                disabled={false}
                            />
                        ) : null}
                    </Typography>
                </div>
            </TableCell>
            <TableCell
                isTableCellClass={true} 
                className={[classes.borders, classes.noPadding, classes.cell, classes.noWrap, classes.minWidth].join(' ')}
                width={headers[4].width}
                data-resize={`element${4}_c`}
            >
                {execution.executionStatus === "INPROGRESS" ? null : (
                <div style={{ paddingLeft: '10px !important', width: '100%' }}>
                    <Typography style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }} noWrap className={classes.executionName}>
                        <Tooltip data="download" key="btn1">
                            <FontAwesomeIcon
                                className={classes.faIcon}
                                icon={faFileDownload}
                                aria-label="faFileDownload"
                                id="faFileDownload"
                                onClick={() =>
                                downloadReport(
                                        execution.reportUrl,
                                        `${execution.executionName ? execution.executionName : execution.label}_Report`,
                                        execution.testcaseId,
                                        execution.executionId
                                    )
                                }
                            />
                        </Tooltip>
                    </Typography>
                </div>
                )
             }
            </TableCell>
            <TableCell
                isTableCellClass={true} 
                className={[classes.borders, classes.noPadding, classes.cell, classes.noWrap, classes.minWidth].join(' ')}
                width={headers[5].width}
                data-resize={`element${5}_c`}
            >
                
                <div style={{ paddingLeft: '10px !important', width: '100%' }}>
                    <Typography style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-around' }} noWrap className={classes.executionName}>
                        
                        {   execution.deviceType && 
                            getDeviceIcon(execution.deviceType,execution.deviceName)
                        }
                        <Tooltip data="platform" key="btn2">

                            {getPlatformIcon(execution.platform)}
                        </Tooltip>
                    </Typography>
                </div>
            </TableCell>
            <TableCell
                isTableCellClass={true} 
                className={[classes.borders, classes.noPadding, classes.cell, classes.noWrap, classes.minWidth].join(' ')}
                width={headers[6].width}
                data-resize={`element${6}_c`}
            >
                <div style={{ paddingLeft: '10px !important', width: '100%' }}>
                    <Typography style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }} noWrap className={classes.executionName}>
                        <Tooltip data="browser" key="btn3">
                            {getBrowserIcon(execution.browser)}
                        </Tooltip>
                    </Typography>
                </div>
            </TableCell>
        </TableRow>
    );
};

export default ExecutionRow;
