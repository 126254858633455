// material
import { Card, CardContent, Paper, Popper, Accordion, AccordionDetails, AccordionSummary } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import Fade from '@material-ui/core/Fade';
import Grid from '@material-ui/core/Grid';
// packages
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import React from 'react';
// custom
import StarIcon from '@material-ui/icons/Star';
import { Link } from 'react-router-dom';
import ProjectHeader from '../ProjectHeader/ProjectHeader';
import { ModalActions, ProjectActions } from '../../store/actions';
import ChartHeader from '../../features/dashboard/ChartHeader';
import LineChartComponent from './LineChart';
import { getProjectCardProgressColor, checkArrayLength, getFormatedDate, checkKeyInObject } from '../../utils/utils';
import {
    BLACK_FONT,
    NO_DECORATION,
    DISPLAY_BLOCK,
    DISPLAY_INLINE_BLOCK,
    DISPLAY_INLINE,
    MAX_WIDTH_100_PER,
    MAX_WIDTH_50_PER,
    WIDTH_100_PER,
    WIDTH_50_PER,
} from '../../common/cssConstants';
import Tooltip from '../../components/Tooltip'
import { ProjectUtils } from '../../utils/ProjectUtils';

const styles = () => ({
    cardWrapper: {
        minWidth: 240,
        borderRadius: '7px',
        width: '100%',
        boxShadow: '0 2px 4px 0 rgba(0,0,0,0.5)',
    },
    container: {
        width: '100%',
        flexWrap: 'wrap',
        boxSizing: 'border-box',
        padding: '5px 10px 0px 10px',
        display: 'flex',
    },
    containerInner: {
        flexWrap: 'wrap',
        boxSizing: 'border-box',
        display: 'flex',
        width: '50%',
        padding: 0,
    },
    cardContent: {
        paddingTop: 0,
        paddingLeft: 0,
        paddingRight: 0,
        paddingBottom: '0 !important',
        width: '100%',
    },
    expansionPanel: {
        margin: '0px !important',
        padding: '0px !important',
        height: 'auto !important',
        minHeight: 'auto !important',
        maxHeight: '100% !important',
        cursor: 'auto !important',
        boxShadow: 'none',
        width: '100%',
    },
    paper: {
        padding: 0,
        maxWidth: '200px',
        height: 'auto',
        wordBreak: 'break-word',
        zIndex: '10000',
        borderRadius: 8,
    },
    popperPaperChild: {
        boxShadow: 'none !important',
        right: '-3px',
        top: '-3px',
        background: 'transparent',
    },
    noWrap: {
        overflow: 'hidden',
        whiteSpace: 'nowrap',
        textOverflow: 'ellipsis',
    },
    title: {
        margin: 0,
        color: BLACK_FONT,
        fontSize: 16,
    },
    url: {
        margin: 0,
        color: '#3B91DF',
        fontSize: 12,
        display: 'inline-block',
        maxWidth: '100%',
    },
    testCasePercentage: {
        display: 'flex',
        fontSize: '50px',
        justifyContent: 'center',
        lineHeight: '66px',
        marginLeft: 5,
    },
    lineChart: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    },
    underText: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        flexDirection: 'column',
    },
    line1: {
        color: BLACK_FONT,
        fontSize: '14px',
        lineHeight: '19px',
        fontWeight: 400,
        margin: 0,
    },
    line2: {
        color: BLACK_FONT,
        fontSize: '12px',
        margin: 0,
        paddingBottom: '10px',
    },
    paperBody: {
        boxShadow: '0 2px 7px 0 rgba(0, 0, 0, 0.8)',
        backgroundColor: '#fff',
        borderRadius: '3px',
        padding: '10px 15px',
        position: 'relative',
    },
    tootTipLabel: {
        fontSize: '12px',
        color: BLACK_FONT,
        margin: '0px 5px 0px 0px',
        fontWeight: 400,
    },
    tootTipValue: {
        margin: 0,
        fontSize: '12px',
        color: BLACK_FONT,
        fontWeight: 500,
    },
    total: {
        margin: 0,
        fontSize: '14px',
        display: 'flex',
        justifyContent: 'center',
        color: BLACK_FONT,
    },
    addFavouriteStar: {
        margin: '19px',
    },
    greyedStar: {
        color: 'rgba(74, 74, 74,0.34)',
        fontSize: '21px',
        margin: '4px',
    },
    coloredStar: {
        color: 'rgb(243, 155, 49)',
        fontSize: '21px',
        margin: '4px',
    },
    fakeLink: {
        margin: 0,
        cursor: 'pointer',
    },
    link: NO_DECORATION,
    block: DISPLAY_BLOCK,
    inlineBlock: DISPLAY_INLINE_BLOCK,
    inline: DISPLAY_INLINE,
    maxWidth100: MAX_WIDTH_100_PER,
    maxWidth50: MAX_WIDTH_50_PER,
    width100: WIDTH_100_PER,
    width50: WIDTH_50_PER,
    tooltipText: {
        '&>div': {
            margin: '7px',
            fontSize: '12px',
            '&>span': {
                fontWeight: '500',
            }
        }
    }
});

class ProjectCard extends React.Component {
    constructor(props) {
        super(props);
        this.state = { isOpenComparison: true, showPopUp: false, anchorEl: null, isOpen: false, arrowRef: null };
    }

    getLineChartData = (jobs = []) => {
        let lineChartData = [{ success: 0 }, { success: 0 }, { success: 0 }];
        if (checkArrayLength(jobs)) {
            lineChartData = [];
            jobs.forEach((job, index) => {
                let perc = 0;
                perc = Math.round((job.passed / (job.inProgress + job.passed + job.failed)) * 100);
                lineChartData.unshift({ success: perc || 0 });
                if (index === jobs.length - 1) {
                    if (jobs.length === 1) {
                        lineChartData.unshift({ success: 0 }, { success: 0 });
                    } else if (jobs.length === 2) {
                        lineChartData.unshift({ success: 0 });
                    }
                }
            });
        }
        return lineChartData;
    };

    handleClick = async () => {
        const { userId } = this.props.user;
        // let projectId = this.props.project.projectId;
        if (userId) {
            this.props.setCurrentProject(this.props.project);
            // this.props.history.push(`/details/${projectId}/cases`);
        }
    };

    handleProjectNameEdit = () => {
        const modalData = { project: this.props.project, user: this.props.user };
        this.props.toggleModal('renameProject', null, null, modalData);
    };

    // handleUpdateProject = async (projectId) => {
    //     const modalData = { project: this.props.project, user: this.props.user, updateProject: true, projectId };
    //     this.props.toggleModal('updateProject', null, null, modalData);
    // };

    totalTestPercentage = (project) => {
        return Math.ceil(project.totalTestsCount ? (100 * project.totalTestsPassedCount) / project.totalTestsCount : 0);
    };

    handleFavourite = () => {
        const { removeFavourite, addFavourite, project, rowsPerPage, page } = this.props;
        if (project.isFavourite) {
            return removeFavourite(project.projectId, rowsPerPage, page);
        }
        return addFavourite(project.projectId, rowsPerPage, page);
    };

    handleDelete = () => {
        const { doDelete, project, rowsPerPage, page } = this.props;
        const obj = {
            projectId: project.projectId,
            page,
            rowsPerPage,
        };
        return doDelete(obj);
    };

    handlePopoverOpen = (event) => {
        const { currentTarget } = event;
        if (!this.state.showPopUp) this.setState({ showPopUp: true, anchorEl: this.state.anchorEl ? null : currentTarget, isOpen: true });
    };

    handlePopoverTimer = () => {
        if (this.state.showPopUp) this.setState({ showPopUp: false, anchorEl: null, isOpen: false });
    };

    handleArrowRef = (node) => {
        this.setState({ arrowRef: node });
    };

    viewCount = (name, value) => {
        return (
            <span style={{ display: 'flex', lineHeight: 1.5 }}>
                <p className={this.props.classes.tootTipLabel}>{name}</p>
                <p className={this.props.classes.tootTipValue}>{value}</p>
            </span>
        );
    };

    render() {
        const { classes, project, toggleModal, showDisabledProjects, user } = this.props;
        const { isOpenComparison, anchorEl, arrowRef, isOpen /* , starred */ } = this.state;
        const caseCountForToolTip = checkKeyInObject(project, 'numberOfCases'); // this return testCases value
        const suiteCountForToolTip = checkKeyInObject(project, 'noOfEnvironments'); // this return noOfEnvironments value
        const appUrl = checkKeyInObject(project, 'appUrl'); // this return appurl value
        const jobs = checkKeyInObject(project, 'jobs'); // this return jobs value
        const favourite = checkKeyInObject(project, 'isFavourite', 'bool') && checkKeyInObject(project, 'isFavourite');
        const header = {
            name: project.projectName,
            date: project.lastActivityDate,
            // time: project.creationTime,
            projectId: project.projectId,
            person: project.noOfUsers,
            badge_value: project.noOfEnvironments,
            badge_name: 'ENVs',
            onDelete: this.handleDelete,
            handleFavourite: this.handleFavourite,
            toggleModal,
        };
        const starredSign = {
            starred: favourite ? classes.coloredStar : classes.greyedStar,
        };
        const _progressVal = this.totalTestPercentage(project);
        let dateForToolTip = 'N/A';
        const lineChartData = this.getLineChartData(jobs); // lineChart Data | initialize with default data
        if (checkKeyInObject(project, 'lastActivityDate')) {
            dateForToolTip = getFormatedDate(project.lastActivityDate, 'MMM DD YYYY', 'N/A');
        }

        return (
            <Card className={classes.cardWrapper}>
                <Accordion expanded={isOpenComparison} className={classes.expansionPanel}>
                    <AccordionSummary className={classes.expansionPanel} classes={{ content: classes.expansionPanel }}>
                        <ChartHeader
                            title={
                                <div style={{ width: '85%' }}>
                                    <Link
                                        className={`${classes.link} ${classes.block} ${classes.maxWidth100}`}
                                        to={`/details/${project.projectId}/cases`}
                                    >
                                        <p
                                            className={[classes.title, classes.noWrap].join(' ')}
                                            style={showDisabledProjects ? { color: '#4a4a4a' } : {}}
                                        >
                                            {header.name}
                                        </p>
                                    </Link>
                                    {showDisabledProjects ? (
                                        <p className={`${classes.link} ${classes.inlineBlock} ${classes.maxWidth100} ${classes.fakeLink}`}>
                                            <p className={[classes.url, classes.noWrap].join(' ')} style={{ color: '#9b9b9b' }}>
                                                {appUrl}
                                            </p>
                                        </p>
                                    ) : (
                                        <Link
                                            className={`${classes.link} ${classes.block} ${classes.maxWidth100}`}
                                            to={`/details/${project.projectId}/cases`}
                                        >
                                            <p className={[classes.url, classes.noWrap].join(' ')}>{appUrl}</p>
                                        </Link>
                                    )}
                                </div>
                            }
                            maximise={null}
                            minimise={() => {
                                this.setState({ isOpenComparison: !isOpenComparison });
                            }}
                            refresh={null}
                            close={null}
                            open={isOpenComparison}
                            handleClick={!showDisabledProjects ? this.handleClick : null}
                            project={project}
                        />
                    </AccordionSummary>
                    <AccordionDetails className={classes.expansionPanel}>
                        <CardContent
                            style={{ cursor: !showDisabledProjects ? 'pointer' : 'default' }}
                            className={classes.cardContent}
                            onClick={!showDisabledProjects ? this.handleClick : null}
                        >
                            <div className={classes.container}>
                                <div className={classes.containerInner} onMouseOver={this.handlePopoverOpen} onMouseLeave={this.handlePopoverTimer}>
                                    <Link
                                        className={`${classes.link} ${classes.block} ${classes.width100}`}
                                        to={`/details/${project.projectId}/cases`}
                                    >
                                        <div
                                            className={classes.testCasePercentage}
                                            style={{ color: showDisabledProjects ? '#9b9b9b' : getProjectCardProgressColor(_progressVal) }}
                                        >
                                            <Tooltip
                                                position='top'
                                                data={<div className={classes.tooltipText}>
                                                    <div>Test Cases: <span>{project.numberOfCases}</span></div>
                                                    <div>Test Suites: <span>{project.noOfEnvironments}</span></div>
                                                    <div>Last Modified: <span>{getFormatedDate(project.lastActivityDate, 'MMM DD YYYY', 'N/A')}</span></div></div>}
                                            >
                                            {`${_progressVal}%`}
                                            </Tooltip>
                                        </div>
                                        <p className={classes.total} style={showDisabledProjects ? { color: '#4a4a4a' } : {}}>
                                            (Total)
                                        </p>
                                        <span className={classes.addFavouriteStar}>
                                            <StarIcon className={starredSign.starred} aria-label="starIcon" id="starIcon" />
                                        </span>
                                    </Link>
                                    {anchorEl ? (
                                        <Popper
                                            anchorEl={anchorEl}
                                            className={classes.paper}
                                            modifiers={{ arrow: { enabled: true, element: arrowRef } }}
                                            placement="right"
                                            open={Boolean(anchorEl) && isOpen}
                                        >
                                            {({ TransitionProps, placement }) => (
                                                <Fade
                                                    {...TransitionProps}
                                                    timeout={350}
                                                    style={{
                                                        opacity: 1,
                                                        marginTop: -25,
                                                        marginBottom: placement.includes('top') ? 6 : 0,
                                                        marginLeft: placement === 'right' ? -150 : 0,
                                                    }}
                                                >
                                                    <Paper className={classes.popperPaperChild}>
                                                        <Link
                                                            className={`${classes.link} ${classes.inline} ${classes.width100}`}
                                                            to={`/details/${project.projectId}/cases`}
                                                        >
                                                            <div className={classes.paperBody}>
                                                                {this.viewCount('Test Cases:', caseCountForToolTip)}
                                                                {this.viewCount('Test Suites:', suiteCountForToolTip)}
                                                                {this.viewCount('Last Modified:', dateForToolTip)}
                                                            </div>
                                                        </Link>
                                                    </Paper>
                                                </Fade>
                                            )}
                                        </Popper>
                                    ) : null}
                                </div>
                                <Link className={`${classes.link} ${classes.block} ${classes.width50}`} to={`/details/${project.projectId}/cases`}>
                                    <div className={classes.lineChart}>
                                        <div>
                                            <LineChartComponent data={lineChartData} showDisabledProjects={showDisabledProjects} />
                                        </div>
                                        <div className={classes.underText}>
                                            <p className={classes.line1} style={showDisabledProjects ? { color: '#4a4a4a' } : {}}>
                                                Quality Trend
                                            </p>
                                            <p className={classes.line2} style={showDisabledProjects ? { color: '#4a4a4a' } : {}}>
                                                (Last 3 test executions)
                                            </p>
                                        </div>
                                    </div>
                                </Link>
                            </div>
                            <Grid>
                                <Grid item xs>
                                    <ProjectHeader
                                        user={user}
                                        showDisabledProjects={showDisabledProjects}
                                        project={project}
                                        header={header}
                                        handleProjectNameEdit={this.handleProjectNameEdit}
                                        handleUpdateProject={(projectId) => {
                                            ProjectUtils.toggleUpdateModal(true, projectId, this.props.project);
                                        }}
                                    />
                                </Grid>
                            </Grid>
                        </CardContent>
                    </AccordionDetails>
                </Accordion>
            </Card>
        );
    }
}

ProjectCard.propTypes = {
    classes: PropTypes.shape({}).isRequired,
    project: PropTypes.shape({}).isRequired,
    toggleModal: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => {
    return {
        user: state.authReducer.user,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        doDelete: (obj) => dispatch(ProjectActions.deleteProjects(obj)),
        setCurrentProject: (...args) => dispatch(ProjectActions.setCurrentProject(...args)),
        toggleModal: (...args) => dispatch(ModalActions.toggleModal(...args)),
        addFavourite: (...args) => dispatch(ProjectActions.addFavourite(...args)),
        removeFavourite: (...args) => dispatch(ProjectActions.removeFavourite(...args)),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(ProjectCard));
