// materials
import { withStyles } from '@material-ui/core/styles';
// packages
import { connect } from 'react-redux';
import React from 'react';
import PropTypes from 'prop-types';
// icons
// custom
import CircularProgress from '@material-ui/core/CircularProgress';
import { checkObject, checkKeyInObject, checkArrayLength, resizeAllCols } from '../../utils/utils';
import { GeneralActions, SuiteReportAction, TestSuiteActions, ProjectActions } from '../../store/actions';
import SuiteCaseReportHeader from './SuiteReportDetailHeader';
import SuiteReportDetailCaseTable from './SuiteReportDetailCaseTable';
import NoDataToShow from '../../components/NoDataToShow';
import ImageSrc from '../../components/NoDataToShow/assests/file-document-box-outline.svg';
// style
const styles = () => ({
    container: {
        // backgroundColor: '#fff',
        margin: '12px 0px 0px 22px',
        width: 'calc(100% - 47px)',
    },
    emptyBox: {
        marginTop: 200,
    },
    root: {
        width: '100%',
    },
});

let isExecutionFetched = false;
let isExecutionTaskFetched = false;

class SuiteReportDetail extends React.Component {
    constructor(props) {
        super(props);
        this.colsWidth = {};
        this.testSuiteId = -1;
        this.currentSuiteIndex = -1;
        this.executionFound = false;
        this.state = {
            suite: {},
            isNextDisabled: true,
            isPrevDisabled: true,
            testSuiteReports: [],
        };
        this.props.updateTitle('Execution History');
    }

    componentDidMount() {
        const { getReports, executionReports } = this.props;
        this.testSuiteId = window.location.pathname ? window.location.pathname.split('/').pop() : '';
        if (!checkArrayLength(executionReports)) {
            getReports();
        }
        this.setComponentData(this.props, this.testSuiteId);
    }

    UNSAFE_componentWillReceiveProps(nextProps) {
        this.setComponentData(nextProps, this.testSuiteId);
    }

    componentDidUpdate() {
        resizeAllCols(this.colsWidth);
    }

    componentWillUnmount() {
        isExecutionFetched = false;
        isExecutionTaskFetched = false;
    }

    setComponentData = (nextProps, newTestSuiteId) => {
        const { testSuiteReports } = this.state;
        const { executions, executionReports, getExecutions } = nextProps;
        let suite = {};
        let obj = {};
        if (newTestSuiteId !== this.testSuiteId) {
            this.testSuiteId = newTestSuiteId;
            isExecutionFetched = false;
            isExecutionTaskFetched = false;
            this.currentSuiteIndex = -1;
            this.executionFound = false;
        }
        if (!checkArrayLength(testSuiteReports) && checkArrayLength(executionReports)) {
            const _testSuiteReports = [];
            executionReports.forEach((_executions) => {
                _testSuiteReports.push({
                    ..._executions,
                    // projectId: _executions.project_id,
                    // projectName: _executions.project_name,
                });
            });
            suite = checkArrayLength(_testSuiteReports) ? _testSuiteReports.find((_suite) => `${_suite.suite_id}` === `${newTestSuiteId}`) : {};
            obj = { testSuiteReports: _testSuiteReports };
        }
        if (!checkObject(suite) && checkArrayLength(testSuiteReports)) {
            suite = testSuiteReports.find((_suite) => `${_suite.suite_id}` === `${newTestSuiteId}`);
        }
        if (this.currentSuiteIndex === -1 && checkArrayLength(testSuiteReports)) {
            this.currentSuiteIndex = testSuiteReports.findIndex((_suite) => `${_suite.suite_id}` === `${newTestSuiteId}`);
        }

        let callback = () => {};
        obj = { ...obj, isPrevDisabled: this.currentSuiteIndex === 0, isNextDisabled: this.currentSuiteIndex === testSuiteReports.length - 1 };
        if (checkObject(suite)) {
            obj = { ...obj, suite };
            callback = () => {
                if (!this.executionFound && (!isExecutionFetched || !checkObject(executions))) {
                    this.executionFound = true;
                    getExecutions({ id: Number(newTestSuiteId), type: 2 });
                    isExecutionFetched = true;
                }
                if (!isExecutionTaskFetched) {
                    isExecutionTaskFetched = true;
                    setTimeout(() => {
                        this.props.getExecutionTasks(suite.project_id, -1, -1, 1, false, null, false);
                    }, 100);
                }
            };
        }
        this.setState(obj, callback);
    };

    getSuiteStatusByScriptExecution = (jobs) => {
        let totalTestsFailedCount = 0;
        let totalTestsPassedCount = 0;

        if (
            checkArrayLength(jobs) &&
            checkKeyInObject(jobs[0], 'etrs') &&
            checkKeyInObject(jobs[0].etrs, 'tasks') &&
            checkArrayLength(jobs[0].etrs.tasks)
        ) {
            jobs[0].etrs.tasks.forEach((task) => {
                if (task.executionStatus === 'SUCCESS') {
                    totalTestsPassedCount++;
                } else if (task.executionStatus === 'ERROR') {
                    totalTestsFailedCount++;
                } else if(task.executionStatus === 'STOPPED') {
                }
            });
        }
        const executionStatusCode = {0: "In progress", 1: "Success", 2: "Failed", 3: "Partial", 4: "NA", 5: "Unknown", 6: "Maintenance", 7: "Stopped", 8:"Queued"}
        const updatedExecutionStatus = typeof jobs[0].jobExecutionStatus === 'number' ? executionStatusCode[jobs[0].jobExecutionStatus] : 'N/A';

        return [totalTestsPassedCount, totalTestsFailedCount, updatedExecutionStatus];
    }

    traverseSuiteExecutionHistory = (action) => {
        const { testSuiteReports } = this.state;
        let newTestsuiteId;
        if (checkArrayLength(testSuiteReports) && this.currentSuiteIndex !== -1) {
            if (action === 'next') {
                newTestsuiteId = checkObject(testSuiteReports[this.currentSuiteIndex + 1])
                    ? testSuiteReports[this.currentSuiteIndex + 1].suite_id
                    : null;
            } else {
                newTestsuiteId = checkObject(testSuiteReports[this.currentSuiteIndex - 1])
                    ? testSuiteReports[this.currentSuiteIndex - 1].suite_id
                    : null;
            }
            if (newTestsuiteId) {
                this.props.history.replace(`/executions/reports/suite/${newTestsuiteId}`);
                this.setComponentData(this.props, newTestsuiteId);
            }
        }
    };

    render() {
        const { classes, executions, isLoading,getExecutions } = this.props;
        const { suite, isNextDisabled, isPrevDisabled, testSuiteReports } = this.state;
        let updatedExecutionStatus = 'N/A';
        let totalTestsPassedCount = 0;
        let totalTestsFailedCount = 0;
        if (checkKeyInObject(executions, 'jobs') && checkArrayLength(executions.jobs)) {
            [totalTestsPassedCount, totalTestsFailedCount, updatedExecutionStatus] = this.getSuiteStatusByScriptExecution(executions.jobs);
        }
        // if (checkArrayLength(testSuiteReports) && this.currentSuiteIndex !== -1 && checkObject(testSuiteReports[this.currentSuiteIndex])) {
        //     suiteStatus = testSuiteReports[this.currentSuiteIndex].job_status;
        // }
        
        return (
            <div className={classes.root}>
                <span key="head">
                    <SuiteCaseReportHeader
                        actions={{
                            handleSuiteChange: this.traverseSuiteExecutionHistory,
                        }}
                        data={{
                            suiteName: suite.suite_name ? suite.suite_name : '',
                            updatedExecutionStatus,
                            totalTestsPassedCount,
                            totalTestsFailedCount,
                            isNextDisabled,
                            isPrevDisabled,
                            nextIndex: this.currentSuiteIndex + 1,
                            prevIndex: this.currentSuiteIndex - 1,
                            testSuiteReports,
                        }}
                        isLoading = {isLoading}
                        reload = {()=>{
                            getExecutions({ id: Number(this.testSuiteId), type: 2 });

                        }}
                    />
                </span>
                {isLoading ? (
                    <div
                        className={classes.emptyBox}
                        style={{
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                        }}
                    >
                        <CircularProgress style={{ position: 'absolute', color: '#4885ed' }} size={50} thickness={3} />
                    </div>
                ) : checkObject(suite) ? (
                    <div key="cont" className={classes.container}>
                        <SuiteReportDetailCaseTable
                            executions={executions}
                            suite={suite}
                            data={{
                                suiteName: suite.suite_name,
                                totalTestsPassedCount,
                                totalTestsFailedCount,
                            }}
                            history={this.props.history}
                            colsWidth={this.colsWidth}
                        />
                    </div>
                ) : (
                    <div key="err1" className={classes.emptyBox}>
                        <NoDataToShow imgSrc={ImageSrc} messageText="No suite available against this id." selectedTab="suiteReport" />
                    </div>
                )}
            </div>
        );
    }
}
SuiteReportDetail.propTypes = {
    classes: PropTypes.shape({}).isRequired,
};
SuiteReportDetail.defaultProps = {};

const mapStateToProps = (state) => {
    return {
        // auth reducer
        user: state.authReducer.user,
        //  project reducer
        executionReports: state.projectReducer.executionReports,
        // suite report reducer
        executions: state.suiteReportReducer.executions,
        isLoading: state.suiteReportReducer.isLoading,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        // Suite Report Action
        getExecutionTasks: (...args) => dispatch(ProjectActions.getExecutionTasks(...args)),
        getExecutions: (...args) => dispatch(SuiteReportAction.getExecutions(...args)),
        updateTitle: (...args) => dispatch(GeneralActions.updateTitle(...args)),
        getReports: () => dispatch(TestSuiteActions.getReports()),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(SuiteReportDetail));
