import React from 'react';
import PropTypes from 'prop-types';

import { LineChart, Line, YAxis, CartesianGrid, Tooltip } from 'recharts';
import { withStyles } from '@material-ui/core/styles';
import { BLACK_FONT } from '../../common/cssConstants';

const styles = (/* theme */) => ({
    toolTipRoot: {
        backgroundColor: '#fff',
        width: '110px',
        height: '34px',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        boxShadow: '0 2px 7px 0 rgba(0,0,0,0.8)',
        borderRadius: '3px',
    },
    bullet: {
        width: 12,
        height: 12,
        backgroundColor: '#1BABAB',
        borderRadius: '100%',
        border: '1px solid #979797',
        marginRight: 5,
    },
    content: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
    },
    success: {
        fontSize: '12px',
        color: BLACK_FONT,
        fontWeight: 400,
        margin: 0,
    },
    perc: {
        fontSize: '12px',
        color: BLACK_FONT,
        fontWeight: 500,
        margin: '0px 0px 0px 5px',
    },
});

let CustomTooltip = (props) => {
    const { classes, active, payload /* , label */ } = props;
    if (active) {
        return (
            <div className={classes.toolTipRoot}>
                <span className={classes.bullet} />
                <span className={classes.content}>
                    <p className={classes.success}>Success</p>
                    <p className={classes.perc}>{`${payload[0].value}%`}</p>
                </span>
            </div>
        );
    }
    return null;
};

CustomTooltip = withStyles(styles)(CustomTooltip);

function LineChartComponent(props) {
    const { data, showDisabledProjects } = props;
    const lineColor = showDisabledProjects ? '#9b9b9b' : '#8884d8';
    return (
        <div>
            <LineChart width={130} height={90} data={data}>
                <CartesianGrid strokeDasharray="1 0" horizontal={false} />
                <YAxis ticks={[10, 20, 30, 40, 50, 60, 70, 80, 90, 100]} hide />
                <Tooltip content={<CustomTooltip />} />
                <Line
                    activeDot={{ r: 6 }}
                    strokeWidth={2}
                    dot={{ strokeWidth: 1, fill: lineColor, stroke: lineColor }}
                    type="linear"
                    dataKey="success"
                    stroke={lineColor}
                    isAnimationActive={false}
                />
            </LineChart>
        </div>
    );
}

LineChartComponent.propTypes = {
    data: PropTypes.arrayOf(PropTypes.shape({}).isRequired).isRequired,
};

export default withStyles(styles)(LineChartComponent);
