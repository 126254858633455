// import packages
import React, { Component } from 'react';
import { connect } from 'react-redux';

// import materials
import { withStyles } from '@material-ui/core/styles';

// import Custom components
import { BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, Cell } from 'recharts';
import ContentLoader from 'react-content-loader';
import CardDashboard from '../Card/CardDashboard';
import { checkArrayLength } from '../../../utils/utils';
import { BLACK_FONT } from '../../../common/cssConstants';

let isComponentMount = false;
class HorBarChart extends Component {
    state = {
        componentHeight: { 0: 0, 1: 0 },
        componentWidth: { 0: 0, 1: 0 },
        isFullScreen: false,
        chartData: [],
    };

    componentDidMount() {
        const { chartData } = this.props;
        isComponentMount = true;
        if (this.sizeIndicator && (this.sizeIndicator[0] || this.sizeIndicator[1])) {
            this.updateDimensions();
        }
        window.addEventListener('resize', this.updateDimensions);
        this.setData(chartData);
    }

    UNSAFE_componentWillReceiveProps(nextProps) {
        const { chartData, openTaskPanel } = nextProps;
        if (this.props.openTaskPanel !== openTaskPanel) {
            this.isSizeUpdated = true;
        }
        this.setData(chartData);
    }

    componentDidUpdate() {
        if (this.isSizeUpdated) {
            this.isSizeUpdated = false;
            this.updateDimensions();
        }
    }

    getData = (isFullScreen) => {
        const {
            classes,
            tickerIndicatorValue,
            redirectURL,
            history,
            isLoading,
            title,
            redirectMessage,
            axisData,
            tooltipData,
            style,
            className,
            noDataMessage,
        } = this.props;
        const { chartData } = this.state;
        const height = this.state.componentHeight[isFullScreen ? 1 : 0];
        const width = this.state.componentWidth[isFullScreen ? 1 : 0];
        const barLoaderSpace = (height - 18 * 5) / 6;
        const noDataAvailable = noDataMessage || 'No Failure Data Available';
        return (
            <CardDashboard
                headerButtons
                title={title}
                tickerIndicatorValue={tickerIndicatorValue}
                redirectMessage={redirectMessage}
                redirectURL={redirectURL}
                history={history}
                toggleFullScreen={this.toggleFullScreen}
                isFullScreen={isFullScreen}
                className={className || ''}
                style={
                    isFullScreen
                        ? {
                              ...style,
                              width: '100%',
                          }
                        : {
                              width: '100%',
                              ...style, // dont change its position - this is here because width can be overwrite
                          }
                }
            >
                <div
                    ref={(element) => {
                        this.sizeIndicator[isFullScreen ? 1 : 0] = element;
                    }}
                    style={{
                        width: '100%',
                        height: isFullScreen ? 'calc(500px - 76px)' : 'calc(320px - 76px)',
                        marginLeft: '0',
                        position: 'relative',
                    }}
                >
                    <div
                        style={{
                            width: '100%',
                            position: 'absolute',
                            bottom: '-4px',
                            right: '15px',
                        }}
                    >
                        {isLoading ? (
                            <div style={{ height, width: width + 10 }}>
                                <ContentLoader height={height > 0 ? height : 0} width={width + 10 > 0 ? width + 10 : 0} speed={2}>
                                    <rect x="75" y={barLoaderSpace} rx="4" ry="4" width={width - 65 > 0 ? width - 65 : 0} height="18" />
                                    <rect
                                        x="75"
                                        y={barLoaderSpace + (barLoaderSpace + 18)}
                                        rx="4"
                                        ry="4"
                                        width={width - 65 > 0 ? width - 65 : 0}
                                        height="18"
                                    />
                                    <rect
                                        x="75"
                                        y={barLoaderSpace + (barLoaderSpace + 18) * 2}
                                        rx="4"
                                        ry="4"
                                        width={width - 65 > 0 ? width - 65 : 0}
                                        height="18"
                                    />
                                    <rect
                                        x="75"
                                        y={barLoaderSpace + (barLoaderSpace + 18) * 3}
                                        rx="4"
                                        ry="4"
                                        width={width - 65 > 0 ? width - 65 : 0}
                                        height="18"
                                    />
                                    <rect
                                        x="75"
                                        y={barLoaderSpace + (barLoaderSpace + 18) * 4}
                                        rx="4"
                                        ry="4"
                                        width={width - 65 > 0 ? width - 65 : 0}
                                        height="18"
                                    />
                                </ContentLoader>
                            </div>
                        ) : (
                            <span>
                                <BarChart
                                    layout="vertical"
                                    width={width}
                                    height={height}
                                    data={chartData}
                                    style={{ margin: '0px auto' }}
                                    className="barChart"
                                >
                                    <CartesianGrid strokeDasharray="2 1" vertical={false} />
                                    <YAxis
                                        dataKey={axisData.YKey}
                                        type={axisData.YType ? axisData.YType : 'category'}
                                        style={{ fontSize: '12px', fill: BLACK_FONT }}
                                        tickLine={false}
                                        axisLine={false}
                                        width={70}
                                    />
                                    <XAxis
                                        dataKey={axisData.XKey}
                                        type={axisData.XType ? axisData.XType : 'number'}
                                        style={{ fontSize: '0' }}
                                        stroke="#979797"
                                        tickLine={false}
                                    />
                                    {checkArrayLength(tooltipData) && (
                                        <Tooltip
                                            cursor={{ height: 0 }}
                                            content={({ payload }) => (
                                                <div className={classes.toolTipRoot}>
                                                    {payload && payload[0] && payload[0].payload ? (
                                                        <span className={classes.toolTipCont}>
                                                            {tooltipData.map((data, index) => {
                                                                return (
                                                                    <span key={index} style={{ display: 'flex' }}>
                                                                        <p className={classes.toolTipMsg} key={index}>
                                                                            {data.key}:
                                                                        </p>
                                                                        <p className={classes.toolTipMsgDet}>{payload[0].payload[data.valueKey]}</p>
                                                                    </span>
                                                                );
                                                            })}
                                                        </span>
                                                    ) : (
                                                        ''
                                                    )}
                                                </div>
                                            )}
                                        />
                                    )}
                                    <Bar dataKey={axisData.dataKey} barSize={18} radius={[0, 2, 2, 0]}>
                                        {chartData.map((entry, index) => {
                                            return <Cell key={index} fill={axisData.barColor} color="" />;
                                        })}
                                    </Bar>
                                </BarChart>
                                {!this.isDataAvailable(chartData) && (
                                    <div className={classes.noDataCont} style={{ height: `${height}px` }}>
                                        <p className={[classes.noData, classes.noWrap].join(' ')}>{noDataAvailable}</p>
                                    </div>
                                )}
                            </span>
                        )}
                    </div>
                </div>
            </CardDashboard>
        );
    };

    setData = (chartData) => {
        const duplicate = JSON.parse(JSON.stringify(chartData));
        const temp = duplicate.map((item) => {
            item.originalName = item.name;
            if (item.name.length > 7) {
                item.name = `${item.name.slice(0, 6)}...`;
            }
            return item;
        });
        this.setState({ chartData: temp });
    };
    componentWillUnomount() {
        isComponentMount = false;
        window.removeEventListener('resize', this.updateDimensions);
    }

    updateDimensions = () => {
        if (isComponentMount && this.props && this.sizeIndicator && (this.sizeIndicator[0] || this.sizeIndicator[1])) {
            const componentHeight = { 0: 0, 1: 0 };
            const componentWidth = { 0: 0, 1: 0 };
            if (this.sizeIndicator[0]) {
                componentHeight[0] = this.sizeIndicator[0].clientHeight - 10;
                componentWidth[0] = this.sizeIndicator[0].clientWidth - 17;
            }
            if (this.sizeIndicator[1]) {
                componentHeight[1] = this.sizeIndicator[1].clientHeight - 10;
                componentWidth[1] = this.sizeIndicator[1].clientWidth - 17;
            }
            this.setState({ componentHeight, componentWidth });
        }
    };

    toggleFullScreen = (isFullScreen) => {
        this.isSizeUpdated = true;
        this.setState({ isFullScreen });
    };

    isDataAvailable = (data) => {
        return data.some((value) => {
            return value.id > -1;
        });
    };

    isSizeUpdated = false;

    sizeIndicator = {};

    render() {
        const { isFullScreen } = this.state;
        const { classes } = this.props;
        return (
            <>
                {this.getData(false)}
                {isFullScreen && (
                    <div className={classes.modalParent}>
                        <div className={classes.modalChild}>{this.getData(true)}</div>
                    </div>
                )}
            </>
        );
    }
}

const styles = () => ({
    progressCardContainer: {
        width: '60%',
        maxWidth: '250px',
        alignItems: 'center',
    },
    progressCardupperCont: {
        display: 'flex',
        justifyContent: 'center',
        marginTop: 20,
    },
    noWrap: {
        overflow: 'hidden',
        whiteSpace: 'nowrap',
        textOverflow: 'ellipsis',
    },
    noData: {
        margin: 0,
        color: '#909090',
        fontSize: '18px',
        fontWeight: '500',
        marginBottom: '30px',
    },
    modalParent: {
        position: 'fixed',
        top: 0,
        left: 0,
        width: '100vw',
        height: '100vh',
        zIndex: 1300,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        backgroundColor: 'transparent',
    },
    noDataCont: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        position: 'absolute',
        width: '100%',
        top: '0px',
        left: '20px',
    },
    modalChild: {
        width: '93%',
        marginLeft: '55px',
        height: '500px',
        maxHeight: 'calc(100vh - 50px)',
        boxShadow: '0 0 80px 0 rgba(0,0,0,0.8)',
        borderRadius: '15px',
        overflow: 'hidden',
    },
    toolTipRoot: {
        backgroundColor: '#fff',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        boxShadow: '0 2px 7px 0 rgba(0,0,0,0.8)',
        borderRadius: '3px',
    },
    toolTipCont: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'flex-start',
        flexDirection: 'column',
        padding: '7px 15px',
        maxWidth: '380px',
    },
    toolTipMsg: {
        fontSize: '12px',
        color: BLACK_FONT,
        fontWeight: 400,
        margin: 0,
        marginRight: 5,
        whiteSpace: 'nowrap',
    },
    toolTipMsgDet: {
        fontSize: '12px',
        color: BLACK_FONT,
        fontWeight: 500,
        margin: 0,
    },
});

const mapStateToProps = (state) => {
    return {
        openTaskPanel: state.generalReducer.openTaskPanel,
    };
};

export default connect(mapStateToProps, null)(withStyles(styles)(HorBarChart));
