import React, { Component } from 'react';
import 'react-datepicker/dist/react-datepicker.css';
import { withStyles } from '@material-ui/core/styles';
import { connect } from 'react-redux';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import Typography from '@material-ui/core/Typography';
import TextField from '@material-ui/core/TextField';
import { BLACK_FONT, FLEX_CENTRE } from '../../../common/cssConstants';
import { generalModalActions } from '../../../store/actions';
import GeneralModalFooter from '../../modal/GeneralModal/GeneralModalFooter';
import { CANCEL_BUTTON, NEXT_SUBMIT_BUTTON } from '../../../components/Button/Btn';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faLinux, faWindows, faApple, faAndroid } from '@fortawesome/free-brands-svg-icons';
import { styles } from './styles';


class ExecutionErrorDetails extends Component {
    constructor() {
        super();
    }



    render() {
        const { tabState, sauceIDDisable, externalGridEmpty, sauceContentEmpty, localContentEmpty, saucePlatformDisable, sauceBrowserDisable, localBrowserDisable, localPlatformDisable, extPlatform, extBrowser, SauceCredentialLoad, sauceCredentials, extPlatformVersion, noConfigFound, duplicateSauceObjects, duplicateLocalObjects, configurationSetting, calledFrom, deviceNotAvailable } = this.props;

        return (
            <span>
                {tabState.toLowerCase() !== 'local' && localContentEmpty   && <Typography style={{ color: 'red', fontSize: 10 }}> * (mandatory fields are required) in Local tab </Typography>}
                {tabState.toLowerCase() !== 'saucelabs' && sauceContentEmpty   && <Typography style={{ color: 'red', fontSize: 10 }}> * (mandatory fields are required) in Saucelabs tab </Typography>}
                {tabState.toLowerCase() !== 'externalgrid' && externalGridEmpty   && <Typography style={{ color: 'red', fontSize: 10 }}> * (mandatory fields are required) in Remote tab</Typography>}
                {tabState.toLowerCase() !== 'saucelabs' && sauceIDDisable && <Typography style={{ color: 'red', fontSize: 10 }}>Tunnel ID is missing in Saucelabs tab</Typography>}
                {tabState.toLowerCase() !== 'saucelabs' && saucePlatformDisable && <Typography style={{ color: 'red', fontSize: 10 }}>Platform is missing in Saucelabs tab</Typography>}
                {tabState.toLowerCase() !== 'saucelabs' && sauceBrowserDisable && <Typography style={{ color: 'red', fontSize: 10 }}>Browser is missing in Saucelabs tab</Typography>}
                {tabState.toLowerCase() !== 'local' && localBrowserDisable && <Typography style={{ color: 'red', fontSize: 10 }}>Browser is missing in Local tab</Typography>}
                {tabState.toLowerCase() !== 'local' && localPlatformDisable && <Typography style={{ color: 'red', fontSize: 10 }}>Platform is missing in Local tab</Typography>}
                {tabState.toLowerCase() !== 'externalgrid' && extPlatform && <Typography style={{ color: 'red', fontSize: 10 }}>Platform is missing in remote tab</Typography>}
                {tabState.toLowerCase() !== 'externalgrid' && extBrowser && <Typography style={{ color: 'red', fontSize: 10 }}>Browser is missing in remote tab</Typography>}
                {tabState.toLowerCase() == 'saucelabs' && calledFrom == "executionScriptModal" && SauceCredentialLoad && !sauceCredentials?.sauceCredentialId && <Typography style={{ color: 'red', fontSize: 10 }}>Your Sauce Labs credentials have not been entered. Before continuing, save your credentials on the Profile page.</Typography>}
                { calledFrom == "executionScriptModal" && SauceCredentialLoad &&  !sauceCredentials?.sauceCredentialId && configurationSetting == 'lastRunConfiguration' && <Typography style={{ color: 'red', fontSize: 10 }}>Your Sauce Labs credentials have not been entered. Before continuing, save your credentials on the Profile page.</Typography>}
                {tabState.toLowerCase() !== 'externalgrid' && extPlatformVersion && <Typography style={{ color: 'red', fontSize: 10 }}>Platform version is missing in remote tab</Typography>}
                {noConfigFound && <Typography style={{ color: 'red', fontSize: 10 }}>Configuration not found</Typography>}
                {deviceNotAvailable?.length > 0 && <Typography style={{ color: 'red', fontSize: 10 }}> {` The blank field is ${deviceNotAvailable[0].platform} and ${deviceNotAvailable[0].environmentType.toLowerCase() === 'saucelabs' && deviceNotAvailable[0].browser || deviceNotAvailable[0].environmentType.toLowerCase() === 'saucelab_devices' && deviceNotAvailable[0].deviceName } , which does not exist in Execution Environment ,Kindly remove the it to enable Update.`}</Typography> }
                {Object.keys(duplicateSauceObjects).length > 0 && duplicateSauceObjects.environmentType.toLowerCase() === 'saucelabs' && <Typography style={{ color: 'red', fontSize: 10 }}><span style={{fontWeight: '500'}}>Sauce Labs:</span> {` Multiple combinations found for ${duplicateSauceObjects.platform} and ${duplicateSauceObjects.environmentType.toLowerCase() === 'saucelabs' && duplicateSauceObjects.browser || duplicateSauceObjects.device} ${duplicateSauceObjects.environmentType.toLowerCase() === 'saucelabs' && duplicateSauceObjects.browserVersion || duplicateSauceObjects.deviceVersion}`}</Typography>}
                {Object.keys(duplicateSauceObjects).length > 0 && duplicateSauceObjects.environmentType.toLowerCase() === 'saucelab_devices' && <Typography style={{ color: 'red', fontSize: 10 }}><span style={{fontWeight: '500'}}>Sauce Labs:</span> {` Multiple combinations found for ${duplicateSauceObjects.platform} and ${duplicateSauceObjects.environmentType.toLowerCase() === 'saucelab_devices' &&  duplicateSauceObjects.device || duplicateSauceObjects.deviceName}`}</Typography>}
                {Object.keys(duplicateLocalObjects).length > 0 && <Typography style={{ color: 'red', fontSize: 10 }}><span style={{fontWeight: '500'}}>Local:</span> {` Multiple combination found for ${duplicateLocalObjects.platform} and ${duplicateLocalObjects.browser}`}</Typography>}
                {tabState.toLowerCase() == 'saucelabs' && calledFrom == "scheduleModal" && !sauceCredentials?.sauceCredentialId && <Typography style={{ color: 'red', fontSize: 10 }}> Sauce Credentials are not saved ,kindly save sauce credentials under Profile Section.</Typography>}
                {!sauceCredentials?.sauceCredentialId  && calledFrom == "scheduleModal" && configurationSetting == 'lastRunConfiguration' && <Typography style={{ color: 'red', fontSize: 10 }}> Sauce Credentials are not saved ,kindly save sauce credentials under Profile Section.</Typography>}
            </span>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        tabsData: state.generalModalReducer.tabsData,
        users: state.userReducer.users,
        activeUser: state.authReducer.user,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        updateTabData: (...args) => dispatch(generalModalActions.updateTabData(...args)),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(ExecutionErrorDetails));
