// import packages
import React, { Component } from 'react';
import { connect } from 'react-redux';

// import materials
import { withStyles } from '@material-ui/core/styles';

// import Custom components
import { LineChart, Line, XAxis, YAxis, CartesianGrid } from 'recharts';
import CardDashboard from '../Card/CardDashboard';
import { checkArrayLength } from '../../../utils/utils';
import ContentLoader from '../../../components/ContentLoader';
import { BLACK_FONT } from '../../../common/cssConstants';

let isComponentMount = false;
class WaveChart extends Component {
    state = {
        componentHeight: { 0: 0, 1: 0 },
        componentWidth: { 0: 0, 1: 0 },
        isFullScreen: false,
    };

    componentDidMount() {
        isComponentMount = true;
        if (this.sizeIndicator && (this.sizeIndicator[0] || this.sizeIndicator[1])) {
            this.updateDimensions();
        }
        window.addEventListener('resize', this.updateDimensions);
    }

    UNSAFE_componentWillReceiveProps(nextProps) {
        if (this.props.openTaskPanel !== nextProps.openTaskPanel) {
            this.isSizeUpdated = true;
        }
    }

    componentDidUpdate() {
        if (this.isSizeUpdated) {
            this.isSizeUpdated = false;
            this.updateDimensions();
        }
    }

    getData = (isFullScreen) => {
        const { classes, tickerIndicatorValue, redirectURL, history, title, redirectMessage, chartData, axisData, isLoading } = this.props;
        const height = this.state.componentHeight[isFullScreen ? 1 : 0];
        const width = this.state.componentWidth[isFullScreen ? 1 : 0];
        return (
            <CardDashboard
                headerButtons
                title={title}
                tickerIndicatorValue={tickerIndicatorValue}
                redirectMessage={redirectMessage}
                redirectURL={redirectURL}
                history={history}
                updateDimensions={this.updateDimensions}
                toggleFullScreen={this.toggleFullScreen}
                isFullScreen={isFullScreen}
                style={{ width: '100%' }}
            >
                <div
                    ref={(element) => {
                        this.sizeIndicator[isFullScreen ? 1 : 0] = element;
                    }}
                    style={{
                        width: '100%',
                        height: isFullScreen ? 'calc(500px - 76px)' : 'calc(320px - 76px)',
                        marginLeft: '0',
                        position: 'relative',
                    }}
                >
                    <div
                        style={{
                            width: '100%',
                            position: 'absolute',
                            bottom: '-4px',
                            right: '15px',
                        }}
                    >
                        <div className={classes.headerParent}>
                            {isLoading ? (
                                <ContentLoader height={20} width={235} />
                            ) : (
                                checkArrayLength(axisData.dataFormat) &&
                                axisData.dataFormat.map((data, index) => {
                                    return (
                                        <div
                                            key={index}
                                            className={classes.headerChild}
                                            onMouseOver={() => {
                                                this.updateLine(index, true);
                                            }}
                                            onMouseLeave={() => {
                                                this.updateLine(index, false);
                                            }}
                                        >
                                            <div className={classes.colouredLine} style={{ backgroundColor: data.stroke }}></div>
                                            <p className={[classes.indecatorText, classes.noWrap].join(' ')}>{data.dataText}</p>
                                        </div>
                                    );
                                })
                            )}
                        </div>
                        {isLoading ? (
                            <ContentLoader height={height - 29} width={width + 16} x={34} ye={15} />
                        ) : (
                            <div style={{ height: height - 29, marginBottom: 5 }}>
                                <LineChart width={width} height={height - 34} data={chartData} style={{ margin: '0px auto' }} className="lineChart">
                                    <CartesianGrid strokeDasharray="2 1" vertical={false} />
                                    <YAxis
                                        dataKey={axisData.YKey}
                                        type={axisData.YType ? axisData.YType : 'category'}
                                        style={{ fontSize: '12px', fill: BLACK_FONT }}
                                        stroke="#979797"
                                        tickLine={false}
                                        axisLine={false}
                                        width={70}
                                    />
                                    <XAxis
                                        dataKey={axisData.XKey}
                                        type={axisData.XType ? axisData.XType : 'number'}
                                        style={{ fontSize: '12px', fill: BLACK_FONT }}
                                        stroke="#979797"
                                        tickLine={false}
                                        angle={45}
                                        dx={10}
                                        dy={10}
                                    />
                                    {checkArrayLength(chartData) &&
                                        checkArrayLength(axisData.dataFormat) &&
                                        axisData.dataFormat.map((data, index) => {
                                            return (
                                                <Line
                                                    ref={(element) => {
                                                        if (element) {
                                                            this.linesRef[index] = element.mainCurve;
                                                        }
                                                    }}
                                                    key={index}
                                                    type="linear"
                                                    onClick={(d, elem) => {
                                                        this.lines.forEach((element) => {
                                                            element.setAttribute('stroke-width', '1');
                                                        });
                                                        this.lines[index] = elem.target;
                                                        elem.target.setAttribute('stroke-width', '2');
                                                    }}
                                                    dataKey={data.dataKey}
                                                    stroke={data.stroke}
                                                    dot={{ strokeWidth: 1, fill: data.stroke, stroke: 'white', r: 5 }}
                                                    strokeWidth={1}
                                                />
                                            );
                                        })}
                                </LineChart>
                            </div>
                        )}
                    </div>
                </div>
            </CardDashboard>
        );
    };

    sizeIndicator = {};

    isSizeUpdated = false;

    lines = [];

    linesRef = [];

    componentWillUnomount() {
        isComponentMount = false;
        window.removeEventListener('resize', this.updateDimensions);
    }

    updateDimensions = () => {
        if (isComponentMount && this.props && this.sizeIndicator && (this.sizeIndicator[0] || this.sizeIndicator[1])) {
            const componentHeight = { 0: 0, 1: 0 };
            const componentWidth = { 0: 0, 1: 0 };
            if (this.sizeIndicator[0]) {
                componentHeight[0] = this.sizeIndicator[0].clientHeight - 10;
                componentWidth[0] = this.sizeIndicator[0].clientWidth - 17;
            }
            if (this.sizeIndicator[1]) {
                componentHeight[1] = this.sizeIndicator[1].clientHeight - 10;
                componentWidth[1] = this.sizeIndicator[1].clientWidth - 17;
            }
            this.setState({ componentHeight, componentWidth });
        }
    };

    toggleFullScreen = (isFullScreen) => {
        this.isSizeUpdated = true;
        this.setState({ isFullScreen });
    };

    updateLine = (index, isOver) => {
        if (isOver) {
            this.linesRef[index].setAttribute('stroke-width', '2');
        } else {
            this.linesRef[index].setAttribute('stroke-width', '1');
        }
    };

    render() {
        const { isFullScreen } = this.state;
        const { classes } = this.props;
        return (
            <>
                {this.getData(false)}
                {isFullScreen && (
                    <div className={classes.modalParent}>
                        <div className={classes.modalChild}>{this.getData(true)}</div>
                    </div>
                )}
            </>
        );
    }
}

const styles = () => ({
    progressCardContainer: {
        width: '60%',
        maxWidth: '250px',
        alignItems: 'center',
    },
    progressCardupperCont: {
        display: 'flex',
        justifyContent: 'center',
        marginTop: 20,
    },
    headerParent: {
        display: 'flex',
        justifyContent: 'flex-end',
        alignItems: 'center',
        width: '100%',
        height: 16,
        marginBottom: 13,
    },
    headerChild: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        marginRight: 30,
        cursor: 'default',
        '&:last-of-type': {
            marginRight: 13,
        },
    },
    colouredBox: {
        height: '10px',
        width: '10px',
        marginRight: '6px',
    },
    colouredLine: {
        height: '2px',
        width: '24px',
        marginRight: '6px',
    },
    indecatorText: {
        margin: 0,
        color: BLACK_FONT,
        fontSize: '12px',
        lineHeight: '1',
    },
    title: {
        margin: 0,
        color: BLACK_FONT,
        fontSize: '12px',
        lineHeight: '26px',
    },
    modalParent: {
        position: 'fixed',
        top: 0,
        left: 0,
        width: '100vw',
        height: '100vh',
        zIndex: 1300,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        backgroundColor: 'transparent',
    },
    modalChild: {
        width: '93%',
        marginLeft: '55px',
        height: '500px',
        maxHeight: 'calc(100vh - 50px)',
        boxShadow: '0 0 80px 0 rgba(0,0,0,0.8)',
        borderRadius: '15px',
        overflow: 'hidden',
    },
    noWrap: {
        overflow: 'hidden',
        whiteSpace: 'nowrap',
        textOverflow: 'ellipsis',
    },
});

const mapStateToProps = (state) => {
    return {
        openTaskPanel: state.generalReducer.openTaskPanel,
    };
};

export default connect(mapStateToProps, null)(withStyles(styles)(WaveChart));
