/* eslint-disable no-throw-literal */
import HttpBase from '../../services/HttpBase';

class TestStep extends HttpBase {
    async updateLiveAndRecoverSteps(obj, params) {
        try {
            const url = `/v1/projects/${params.projectId}/testcases/${params.testCaseId}/updateLveAndRecoverSteps`;
            const result = await this.httpClient.post(url, obj);
            if (result.status === 200) {
                return result;
            }
            throw result;
        } catch (error) {
            return error;
        }
    }

    async updateSteps(params, obj) {
        try {
            const url = `/v1/projects/${params.projectId}/testcases/${params.testCaseId}/updateSteps`;
            const result = await this.httpClient.post(url, obj);
            if (result.status === 200) {
                return result;
            }
            throw result;
        } catch (error) {
            return error;
        }
    }

    async EmailLogs(obj) {
        // isEmail = 0 -> send, 1 => receive
        try {
            const { userId, projectId, testCaseId, isEmail } = obj;
            const publicUrl = new URL(process.env.PUBLIC_URL, window.location);
            let sendData = {
                project_url: `${publicUrl.origin}/details/${projectId}/cases`,
                tc_url: `${publicUrl.origin}/details/${projectId}/${testCaseId}`,
            };
            const link = `/logs/${userId}/${projectId}/${testCaseId}/${isEmail}`;
            let resHeaders = {};
            if (isEmail === 0) {
                sendData = {
                    ...sendData,
                    screen_shot_url: obj.screen_shot_url,
                    usr_comments: obj.usr_comments,
                };
            } else {
                resHeaders = { responseType: 'arraybuffer' };
            }
            const { data, status } = await this.httpClient.post(link, sendData, resHeaders);
            if (status === 200 && data && isEmail === 1) {
                return data;
            }
            if (status === 200 && isEmail === 0) {
                return true;
            }
            throw 'Failed to Perform operation';
        } catch (err) {
            return false;
        }
    }

    async downloadTestStepsFile(id, isExecution, single) {
        let url = `/v1/${isExecution ? 'testexecutions' : 'testcases'}/${id}/getdownloadedfiles`;
        if (single) {
            url = id;
        }
        const resHeaders = { responseType: 'arraybuffer' };
        const result = await this.httpClient.get(url, resHeaders);
        if (result.status === 200) {
            return result;
        }
        throw 'Unable to download';
    }
}

const TestStepMiddleware = new TestStep();
export { TestStepMiddleware };
