import { ActionTypes } from '../constants';
import AlertMiddleWare from '../middleware/alertMiddleware';

class AlertActions {
    static clearAlertError() {
        return {
            type: ActionTypes.CLEAR_ERROR,
        };
    }

    static clearAlertSuccess() {
        return {
            type: ActionTypes.CLEAR_SUCCESS,
        };
    }

    static getAlerts() {
        return (dispatch) => {
            dispatch({ type: ActionTypes.GET_ALERTS });
            AlertMiddleWare.getAlerts()
                .then((data) =>
                    dispatch({
                        type: ActionTypes.GET_ALERTS_SUCCESS,
                        payload: data,
                    }),
                )
                .catch((error) =>
                    dispatch({
                        type: ActionTypes.GET_ALERTS_FAILED,
                        payload: {
                            error,
                            message: 'Failed to fetch alerts',
                        },
                    }),
                );
        };
    }

    static removeAlerts(alert_ids, onCompelete = () => {}) {
        return (dispatch) => {
            dispatch({ type: ActionTypes.REMOVE_ALERTS });
            AlertMiddleWare.removeAlerts(alert_ids)
                .then(() => {
                    onCompelete();
                    return dispatch({
                        type: ActionTypes.REMOVE_ALERTS_SUCCESS,
                        payload: { alert_ids, message: 'Alert successfully deleted' },
                    });
                })
                .catch((error) => {
                    onCompelete();
                    dispatch({
                        type: ActionTypes.REMOVE_ALERTS_FAILED,
                        payload: {
                            error,
                            message: 'Failed',
                        },
                    });
                });
        };
    }

    static getAlertDetail(bug_id) {
        return (dispatch) => {
            dispatch({ type: ActionTypes.GET_ALERT_DETAIL });
            AlertMiddleWare.getAlertDetail(bug_id)
                .then((data) =>
                    dispatch({
                        type: ActionTypes.GET_ALERT_DETAIL_SUCCESS,
                        payload: data,
                    }),
                )
                .catch((error) =>
                    dispatch({
                        type: ActionTypes.GET_ALERT_DETAIL_FAILED,
                        payload: {
                            error,
                            message: 'Failed to fetch alert detail',
                        },
                    }),
                );
        };
    }

    static clearAlertDetail() {
        return (dispatch) => {
            dispatch({ type: ActionTypes.CLEAR_ALERT_DETAIL });
        };
    }
}

export default AlertActions;
