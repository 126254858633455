import { withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';

import React from 'react';
import PropTypes from 'prop-types';

// custom components
import CloseOnEscape from 'react-close-on-escape';
import Close from '@material-ui/icons/Close';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPencilAlt } from '@fortawesome/free-solid-svg-icons';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import PreviewTestCase from '../../../components/Preview/PreviewTestCase';
import PreviewTestScript from '../../../components/Preview/PreviewTestScript';
import PreviewTestData from '../../../components/Preview/PreviewTestData';
import PreviewDownload from '../../../components/Preview/PreviewDownload';
import Tooltip from '../../../components/Tooltip';
// custome services
import SingleFileCreationService from '../../../services/singleFileCreationService'
import { saveAs } from 'file-saver/FileSaver';
// Keyboard input comprehension node modules
import { getExcelDownloadBtn, checkKeyInObject, checkObject, getParamValues } from '../../../utils/utils';
import JSONTOCsv from '../../../services/JSONToCsv';
import {
    DIALOG_ROOT,
    MODAL_CANCEL_BUTTON,
    MODAL_SUBMIT_BUTTON,
    MODAL_FOOTER,
    MODAL_HEADER_TITLE,
    MODAL_HEADER_CLOSE_ICON_CONT,
    MODAL_HEADER_CLOSE_ICON,
} from '../../../common/cssConstants';

const styles = (theme) => ({
    root: {
        width: '100%',
        backgroundColor: theme.palette.background.paper,
    },
    content: {
        padding: '0px !important',
    },
    contentBox: {
        '&>pre': {
            overflow: 'visible',
            overflowX: 'visible !important',
            marginTop: '0px !important',
        },
    },
    dialog: {
        textAlign: 'center',
    },
    dialogContent: {
        padding: '0px 30px 5px 30px',
        overflowY: 'auto',
        maxHeight: 'calc(100vh - 300px)',
        width: 800,
    },
    heading: {
        fontSize: 14,
        fontWeight: 400,
        color: '#3B91DF',
        margin: '0px',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
    },
    dialogRoot: DIALOG_ROOT,
    dialogTitleStyle: {
        ...MODAL_HEADER_TITLE,
        maxWidth: 800,
    },
});

class FilePreviewModal extends React.Component {
    state = {
        dropZoneError: '',
        isButtonDisabled: false,
        scriptDataWidth: '800px',
        isDownloadClicked: false,
        isUpdateClicked: false,
        variableDataExcel: [{ columns: [], data: [] }],
        variableDownloadType: 'vertical',
        variableDataCSV: { columns: {}, data: [] },
        dataValue: null,
        isWidthShort: false,
        modalName: '',
        modalType: '',
        pasteEvent: false,
        prevEditValue:'',
    };

    componentDidMount() {
        const that = this;
        const width = window.innerWidth;
        this.updateState({
            dataValue: this.props.testDataValues.data,
            modalName: this.props.testDataValues.modalName,
            modalType: this.props.testDataValues.modalType,
        });
        setTimeout(() => {
            const scriptDataWidth =
                that.scriptDataWidthRef.current &&
                that.scriptDataWidthRef.current.childNodes.length > 0 &&
                that.scriptDataWidthRef.current.childNodes[0].childNodes.length > 0
                    ? that.scriptDataWidthRef.current.childNodes[0].childNodes[0].scrollWidth
                    : '800px';
            that.setState({
                scriptDataWidth,
            });
            if (that.props.testDataValues && that.props.testDataValues.modalName === 'VariableModal') {
                that.updateVariableData(that.state.variableDownloadType);
            }
        }, 300);

        if (width < 600) {
            this.updateState({ isWidthShort: true });
        }

        window.addEventListener('keydown',(evt)=>{

                if (evt.keyCode === 9) evt.preventDefault();
            


        })
    }

    UNSAFE_componentWillReceiveProps(nextProps) {
        this.setState({ modalName: nextProps.testDataValues.modalName, modalType: nextProps.testDataValues.modalType });
    }

    scriptDataWidthRef = React.createRef();

    handleError = () => {
        this.setState({ dropZoneError: false });
    };

    updateState = (obj) => {
        this.setState(obj);
    };

    handleButtonClick = async () => {
        const { testDataValues, toggleModal, history } = this.props;
        const { modalName } = this.state;

        // If open forcefully from other modal
        // return to that modal otherwise close
        if (testDataValues.forceOpenModal) {
            toggleModal('filePreviewModal', null, null, null, null, true); // Close this modal
        } else {
            if ((modalName === 'ScriptModal' || modalName === 'CaseModal') && getParamValues(2) === 'scripts') {
                this.clearURL();
            }
            if (getParamValues(2) === 'data' && getParamValues(4) === 'preview') {
                history.replace(`/details/${getParamValues(1)}/${getParamValues(2)}`);
            }
            toggleModal();
        }
    };

    handleEscape = () => {
        this.handleButtonClick();
    };

    clearURL = () => {
        const { history } = this.props;
        history.replace(`/details/${getParamValues(1)}/scripts`);
    };

    handleDownload = async (fileType = '') => {
        const { testDataValues, toggleModal, history } = this.props;
        const { modalName, variableDownloadType } = this.state;
        if (modalName === 'VariableModal') {
            if (fileType.toLowerCase() === 'csv') {
                const { variableDataCSV } = this.state;
                JSONTOCsv.CSVFile(variableDataCSV.columns, variableDataCSV.data, testDataValues.fileName);
                toggleModal()
            } else if (fileType.toLowerCase() === 'xlsx') {
                const { variableDataExcel } = this.state;
                let _variableDataExcel = JSON.parse(JSON.stringify(variableDataExcel))
                _variableDataExcel[0].columns.unshift('Variable')
                _variableDataExcel[0].data[0].unshift({style:{},value:'Value'})
                const data = await SingleFileCreationService.getHorizontalVariableFile(_variableDataExcel, testDataValues.fileName);
                if (data) {
                    const blob = new Blob([data],{
                        type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',}
                    );
                    saveAs(blob, `${testDataValues.fileName}.xlsx`);
                    this.props.showSnackBar(`${testDataValues.fileName}.xlsx Downloading`, '', true);
                } else {
                    this.props.showSnackBar(`Error in downloading excel ${testDataValues.fileName}.xlsx`, '', false);
                }
                toggleModal()
            }
        } else {
            const LIVE_STEP = 'Live Steps';
            try {
                this.setState({ isDownloadClicked: true });
                if (fileType !== '') {
                    if (modalName === 'MarketModal') {
                        await testDataValues.handleDownload();
                    } else {
                        await testDataValues.handleDownload(testDataValues.APIData, testDataValues.data, LIVE_STEP, fileType);
                    }
                } else if (modalName === 'DataModal') {
                    await testDataValues.handleDownload(testDataValues.APIData, testDataValues.fileName);
                } else {
                    await testDataValues.handleDownload(testDataValues.APIData, testDataValues.fileName, LIVE_STEP);
                }
                this.setState({ isDownloadClicked: false });
                if (getParamValues(2) === 'data' && getParamValues(4) === 'preview') {
                    history.replace(`/details/${getParamValues(1)}/${getParamValues(2)}`);
                } else if (getParamValues(2) === 'scripts' && getParamValues(4) === 'case' && modalName === 'CaseModal') {
                    this.clearURL();
                }
                toggleModal('filePreviewModal', null, null, null, null, true);
            } catch (e) {
                console.error(e);
            }
        }
    };

    handleChangeDownloadType = (event) => {
        this.updateVariableData(event.target.value);
    };

    toggleModalType = (modalType, modalName,change = '') => {
        if(modalType === 'edit'){
            this.setState({
                prevEditValue : this.state.dataValue
            })
        }
        console.log("prevEditValue",this.state.prevEditValue);
        if (modalName === 'DataModal' && modalType === 'view') {
            if(change === 'Cancel'){
                this.props.testDataValues.data = this.state.prevEditValue;
                this.setState({
                    dataValue : this.state.prevEditValue
                })
            }else{
                this.props.testDataValues.data = this.state.dataValue;
            }
        }
        this.setState({ modalName, modalType });
    };

    updateVariableData = (variableDownloadType) => {
        const { testDataValues } = this.props;
        let variableDataExcel = [{ columns: [], data: [] }];
        const variableDataCSV = { columns: {}, data: [] };
        if (testDataValues.data) {
            const { data } = testDataValues;
            if (variableDownloadType === 'vertical') {
                variableDataExcel = data;
                if (data[0] && data[0].columns) {
                    data[0].columns.forEach((col) => {
                        variableDataCSV.columns[col] = `"${col.replace(/["]{1}/g, '""')}"`;
                    });
                    data[0].data.forEach((val) => {
                        const obj = {};
                        data[0].columns.forEach((col, ind) => {
                            obj[col] = `"${val[ind].value.replace(/["]{1}/g, '""')}"`;
                        });
                        variableDataCSV.data.push(obj);
                    });
                }
            } else if (data[0] && data[0].columns) {
                variableDataExcel[0].data[0] = [];
                const obj = {};
                // putting titles to csv file
                variableDataCSV.columns[data[0]?.columns[0]] = `"${data[0]?.columns[0]?.replace(/["]{1}/g, '""')}"`;
                obj[data[0]?.columns[0]] = `"${data[0]?.columns[1]?.replace(/["]{1}/g, '""')}"`;
                data[0].data.forEach((item) => {
                    const col = item[0] && item[0].value ? item[0].value : '';
                    variableDataExcel[0].columns.push(col);
                    variableDataExcel[0].data[0].push(item[1]);
                    variableDataCSV.columns[col] = `"${col.replace(/["]{1}/g, '""')}"`;
                    obj[col] = `"${item[1].value.replace(/["]{1}/g, '""')}"`;
                });
                variableDataCSV.data.push(obj);
            }
        }

        this.setState({ variableDownloadType, variableDataExcel, variableDataCSV });
    };

    handleKeyUp = (e) => {
        this.setState({pasteEvent: false})
    }

    handlePasteEvent = (e) => {
        this.setState({pasteEvent: true})
    }

    filterDataonPaste = (arr,key) => {
        let oldArrayInput = JSON.parse(this.state.dataValue.data)[key]
        let newArrayInput = arr.split('\n')
        let newValue = [...newArrayInput]
        let correctIndex = -1
        for (let i = 0; i < oldArrayInput.length; i++){
            if (newArrayInput[i] !== oldArrayInput[i]){
                correctIndex = i
                break;
            }   
        }
        let correctValue = newValue.slice(correctIndex,newArrayInput.length - oldArrayInput.length + 1 + correctIndex).join(' ')
        newArrayInput.splice(correctIndex, newArrayInput.length - oldArrayInput.length + 1, correctValue)
        return newArrayInput
    }

    handleEdit = (key, val) => {
        console.log(val)
        let value = val;
        if(this.state.pasteEvent){
            value = this.filterDataonPaste(val,key)
        } else {
            value = value && value.length ? value.split('\n') : [''];
        }
        let testData = JSON.parse(JSON.stringify(this.state.dataValue));
        if (checkKeyInObject(testData, 'data') && testData.data) {
            let data = JSON.parse(testData.data);
            data = { ...data, [key]: value };
            testData = {
                data: JSON.stringify(data),
                order: testData.order,
            };
            this.setState({ dataValue: testData });
        }    
    };

    isContentChanged = () => {
        const { testDataValues } = this.props;
        const { dataValue } = this.state;
        let temp = false;
        if (checkObject(dataValue) && checkKeyInObject(testDataValues, 'data')) {
            Object.keys(dataValue).some((key) => {
                if (checkKeyInObject(testDataValues.data, key)) {
                    temp = JSON.stringify(dataValue[key]) === JSON.stringify(testDataValues.data[key]);
                } else {
                    temp = false;
                }
                if (!temp) {
                    return true;
                }
                return false;
            });
        }
        return !temp;
    };

    render() {
        const { classes, open, showSnackBar, testDataValues, toggleModal } = this.props;
        const { dropZoneError, modalName, modalType, variableDataExcel, dataValue, isWidthShort,variableDownloadType } = this.state;
        const content = {
            CaseModal: <PreviewTestCase testSteps={testDataValues.data} />,
            AlertsMsgs: <PreviewTestData testData={testDataValues.data} />,
            DataModal: <PreviewTestData testData={testDataValues.data} />,
            DataModalEdit: <PreviewTestData handlePasteEvent={this.handlePasteEvent} handleEdit={this.handleEdit} handleKeyUp={this.handleKeyUp} testData={dataValue} modalType={modalType} />,
            ScriptModal: <PreviewTestScript language="java">{testDataValues.data}</PreviewTestScript>,
            MarketModal: <PreviewTestScript language="yaml">{testDataValues.data}</PreviewTestScript>,
            VariableModal: <PreviewDownload type="variable" downloadData={variableDataExcel[0]} />,
        };

        return (
            <div className={classes.root}>
                <Dialog disableRestoreFocus open={open} aria-labelledby="form-dialog-title" maxWidth="md" className={classes.dialogRoot}>
                    <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                        <DialogTitle id="form-dialog-title" classes={{ root: classes.dialogTitleStyle }}>
                            {modalName && modalName === 'DataModalEdit'
                                ? 'Update Test Data'
                                : modalName && modalName === 'DataModal'
                                ? 'Preview Test Data'
                                : modalName && modalName === 'AlertsMsgs'
                                ? 'Alerts'
                                : testDataValues.name
                                ? testDataValues.name
                                : 'File Preview'}
                        </DialogTitle>
                        <div style={MODAL_HEADER_CLOSE_ICON_CONT}>
                            <Close style={MODAL_HEADER_CLOSE_ICON} onClick={this.handleButtonClick} aria-label="closeIcon" id="closeIcon" />
                        </div>
                    </div>
                    <DialogContent style={{ padding: 0, overflow: 'hidden' }}>
                        {testDataValues && modalName === 'VariableModal' ? (
                            <div style={{ display: 'flex', justifyContent: 'flex-end', padding: '0 30px' }}>
                                <RadioGroup
                                    aria-label="position"
                                    name="position"
                                    value={this.state.variableDownloadType}
                                    onChange={this.handleChangeDownloadType}
                                    row
                                >
                                    <FormControlLabel value="vertical" control={<Radio color="secondary" />} label="Vertical" />
                                    <FormControlLabel value="horizontal" control={<Radio color="secondary" />} label="Horizontal" />
                                </RadioGroup>
                            </div>
                        ) : null}
                        {testDataValues && (modalName === 'ScriptModal' || modalName === 'MarketModal') ? (
                            <Card>
                                <CardContent className={classes.content}>
                                    <div className={classes.dialogContent} ref={this.scriptDataWidthRef}>
                                        <div className={classes.contentBox} style={{ width: this.state.scriptDataWidth }}>
                                            {content[modalName]}
                                        </div>
                                    </div>
                                </CardContent>
                            </Card>
                        ) : (
                            <div>
                                {modalName === 'DataModalEdit' || modalName === 'DataModal' || modalName === 'AlertsMsgs' ? (
                                    <div
                                        style={{
                                            display: 'flex',
                                            justifyContent: 'space-between',
                                            alignItems: 'center',
                                            marginBottom: '4px',
                                            padding: '0px 30px',
                                        }}
                                    >
                                        <Tooltip data={testDataValues.name}>
                                            <p className={classes.heading}> {testDataValues.name}</p>
                                        </Tooltip>
                                        {modalName === 'DataModal' && (
                                            <FontAwesomeIcon
                                                icon={faPencilAlt}
                                                aria-label="faPencilAlt"
                                                id="faPencilAlt"
                                                style={{ color: '#3B91DF' }}
                                                onClick={() => {
                                                    this.toggleModalType('edit', 'DataModalEdit');
                                                    this.setState({ isUpdateClicked: false });
                                                }}
                                            />
                                        )}
                                    </div>
                                ) : null}
                                <div className={classes.dialogContent}>{content[modalName]}</div>
                            </div>
                        )}
                    </DialogContent>
                    <DialogActions style={MODAL_FOOTER}>
                        {testDataValues && (modalName === 'CaseModal' || modalName === 'VariableModal') ? (
                            <Button
                                variant="contained"
                                onClick={() => {
                                    this.handleDownload('csv');
                                }}
                                style={MODAL_SUBMIT_BUTTON}
                                color="secondary"
                                disabled={this.state.isDownloadClicked}
                            >
                                {isWidthShort ? 'CSV' : 'Download CSV'}
                            </Button>
                        ) : null}
                        {testDataValues && modalName === 'CaseModal' ? (
                            <Button
                                variant="contained"
                                onClick={() => {
                                    this.handleDownload('xlsx');
                                }}
                                style={MODAL_SUBMIT_BUTTON}
                                color="secondary"
                                disabled={this.state.isDownloadClicked}
                            >
                                {isWidthShort ? 'XLSX' : 'Download XLSX'}
                            </Button>
                        ) : null}
                        {testDataValues && modalName === 'VariableModal'
                            ? variableDownloadType.toLowerCase() === 'vertical' ? 
                                getExcelDownloadBtn(
                                    <Button variant="contained" style={MODAL_SUBMIT_BUTTON} onClick={()=>toggleModal()} color="secondary" disabled={this.state.isDownloadClicked}>
                                        {isWidthShort ? 'XLSX' : 'Download XLSX'}
                                    </Button>,
                                    variableDataExcel,
                                    testDataValues.fileName,
                                    'Variables',
                                ) : 
                                <Button
                                    variant="contained"
                                    onClick={() => {
                                        this.handleDownload('xlsx');
                                    }}
                                    style={MODAL_SUBMIT_BUTTON}
                                    color="secondary"
                                    disabled={this.state.isDownloadClicked}
                                >
                                    {isWidthShort ? 'XLSX' : 'Download XLSX'}
                                </Button>
                            : null}
                        {testDataValues && modalName === 'DataModal' ? (
                            <Button
                                variant="contained"
                                onClick={() => {
                                    this.handleDownload();
                                }}
                                style={MODAL_SUBMIT_BUTTON}
                                color="secondary"
                                disabled={this.state.isDownloadClicked}
                            >
                                Download
                            </Button>
                        ) : null}
                        {testDataValues && (modalName === 'ScriptModal' || modalName === 'MarketModal') ? (
                            <span>
                                <CopyToClipboard
                                    text={
                                        content[modalName] && content[modalName].props && content[modalName].props.children
                                            ? content[modalName].props.children
                                            : ''
                                    }
                                    onCopy={() => this.props.showSnackBar('Script Copied Succesfully.', '', true)}
                                >
                                    <Button variant="contained" color="secondary" style={MODAL_SUBMIT_BUTTON} type="button">
                                        Copy to clipboard
                                    </Button>
                                </CopyToClipboard>
                                <Button
                                    variant="contained"
                                    color="secondary"
                                    onClick={() => {
                                        if (modalName === 'ScriptModal' && getParamValues(2) === 'scripts') {
                                            this.clearURL();
                                        }
                                        this.handleDownload();
                                    }}
                                    style={MODAL_SUBMIT_BUTTON}
                                    disabled={this.state.isDownloadClicked}
                                >
                                    {testDataValues && modalName === 'ScriptModal'
                                        ? 'Download JAVA'
                                        : modalName === 'MarketModal'
                                        ? 'Download YAML'
                                        : ''}
                                </Button>
                            </span>
                        ) : null}
                        {testDataValues && modalName === 'DataModalEdit' ? (
                            <Button
                                variant="contained"
                                onClick={() => {
                                    if (modalType === 'edit' && modalName === 'DataModalEdit') {
                                        if (checkKeyInObject(testDataValues, 'handleUpdate')) {
                                            this.setState({ isUpdateClicked: true });
                                            testDataValues.handleUpdate(testDataValues.projectId, testDataValues.id, dataValue, () => {
                                                this.toggleModalType('view', 'DataModal');
                                            });
                                        }
                                    } else if (checkKeyInObject(testDataValues, 'handleDownload')) {
                                        this.setState({ isUpdateClicked: true });
                                        testDataValues.handleDownload(testDataValues.projectId, testDataValues.id, dataValue);
                                    }
                                }}
                                style={MODAL_SUBMIT_BUTTON}
                                color="secondary"
                                disabled={this.state.isUpdateClicked || !this.isContentChanged()}
                            >
                                {' '}
                                Update{' '}
                            </Button>
                        ) : null}

                        <CloseOnEscape onEscape={this.handleEscape}>
                            <Button
                                variant="outlined"
                                onClick={() => {
                                    if (modalName && modalName === 'DataModalEdit') {
                                        this.toggleModalType('view', 'DataModal','Cancel');
                                    } else {
                                        this.handleButtonClick();
                                    }
                                }}
                                style={MODAL_CANCEL_BUTTON}
                                disabled={this.state.isButtonDisabled}
                            >
                                {modalName && modalName === 'DataModalEdit' ? 'Cancel' : 'Close'}
                            </Button>
                        </CloseOnEscape>
                    </DialogActions>
                </Dialog>
                {dropZoneError ? (showSnackBar(dropZoneError), this.handleError()) : null}
            </div>
        );
    }
}

FilePreviewModal.propTypes = {
    classes: PropTypes.shape({}).isRequired,
    open: PropTypes.bool.isRequired,
    showSnackBar: PropTypes.func.isRequired,
    testDataValues: PropTypes.instanceOf(Object),
    toggleModal: PropTypes.func.isRequired,
};

FilePreviewModal.defaultProps = {
    testDataValues: {},
};

export default withStyles(styles)(FilePreviewModal);
