import { THREE_DOT_MENU_CSS, TABLES_CSS, TABLE_PAGE_HEADING, BLACK_FONT } from '../../../common/cssConstants';

// import packages
export const styles = (theme) => ({
    alertHeadIcon: {
        color: '#fff',
        padding: '0px 5px 0px 7px',
        '& > svg': {
            fontSize: 35,
        },
    },
    alertHeaderWrapper: {
        alignItems: 'center',
        display: 'flex',
        paddingRight: 8,
        '& p, & h1': {
            color: '#fff',
            marginRight: 5,
        },
        '& h1': {
            fontWeight: '600',
        },
    },
    alertHeadText: {
        textAlign: 'end',
        fontSize: TABLES_CSS.headingFontSize,
        color: TABLES_CSS.headingFontColor,
        fontWeight: TABLES_CSS.headingFontWeight,
    },
    hide: {
        display: 'none',
    },
    avatar: {
        minWidth: '20px',
        height: '20px',
        borderRadius: '20px',
        backgroundColor: '#3b91df',
        color: '#fff',
        textAlign: 'center',
        marginRight: '8px',
        fontSize: '13px',
        lineHeight: 1.7,
        padding: '0 4px'
    },
    tab_avatar: {
        minWidth: '22px',
        height: '22px',
        borderRadius: '22px',
        backgroundColor: '#c4c4c4',
        color: BLACK_FONT,
        textAlign: 'center',
        marginRight: '8px',
        fontSize: '13px',
        lineHeight: 1.9,
        padding: '0 4px',
        display: 'inline-block',
        overflow: 'hidden',
        marginLeft: '10px',
    },
    selectedText: {
        color: BLACK_FONT,
        fontSize: '12px',
        fontWeight: 'bold',
        marginTop: '3px',
    },
    multiCloseIcon: {
        color: '#4a4a4a',
        cursor: 'pointer',
        fontSize: 22,
        marginLeft: 10,
    },
    associationButton: {
        background: '#fff',
        color: 'rgb(96, 148, 255)',
        border: '2px solid rgb(96, 148, 255)',
        borderRadius: '100%',
        minWidth: 0,
        width: 'auto',
        padding: '7px',
        marginLeft: '25px',
    },
    checkboxSpacing: {
        padding: '0px 0px 0px 20px',
        width: '8%',
    },
    filterBtn: {
        border: '1px dashed gray',
        borderRadius: '50px',
        color: '#4a4a49',
        minHeight: 'auto',
        marginLeft: '20px',
        minWidth: 'auto',
        padding: '2px 15px',
    },
    footer: {
        display: 'flex',
        justifyContent: 'space-between',
        width: '96%',
    },
    moreIconsBtn: {
        color: THREE_DOT_MENU_CSS.iconColor,
    },
    heading: {
        width: '96%',
        //marginTop: '20px',
        //margin: '20px auto 0px',
        marginTop: '5px',
        marginLeft: '20px',
        color: TABLE_PAGE_HEADING.fontColor,
        fontSize: TABLE_PAGE_HEADING.fontSize,
        fontWeight: TABLE_PAGE_HEADING.fontWeight,
    },
    headingContainer: {
        marginTop: '15px',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-start',
    },
    row: {
        height: '40px',
        backgroundColor: 'rgba(0, 0, 0, 0.04)'
    },
    searchInputIcon: {
        color: '#003d9d',
    },
    table: {
        // minWidth: 1020,
    },
    TableFilterBar: {
        borderBottom: '1px solid rgba(224, 224, 224, 1)',
        borderTop: '1px solid rgba(224, 224, 224, 1)',
    },
    tableWrapper: {
        overflowX: 'hidden',
        overflowY: 'auto',
        [theme.breakpoints.only('xs')]: {
            overflowX: 'auto !important',
        },
    },
    multiIcon: {
        color: '#3b91df',
        fontSize: 22,
        cursor: 'pointer',
    },
    tabRoot: {
        borderBottom: '0.5px solid #e9e9e9',
        color: '#aaaaaa',
        fontSize: 12,
        lineHeight: '15px',
        opacity: 1,
        borderTop: '3px solid #494949',
    },
    firstTabSelected: {
        backgroundColor: '#ffffff',
        borderLeft: '0px solid transparent',
        borderRight: '0.5px solid #e9e9e9',
        borderBottom: '0px solid transparent',
        borderTop: '3px solid #F39B31',
        borderRadius: '5px',
        color: BLACK_FONT,
        fontSize: 12,
        lineHeight: '15px',
    },
    tabSelected: {
        backgroundColor: '#ffffff',
        borderLeft: '0.5px solid #e9e9e9',
        borderRight: '0.5px solid #e9e9e9',
        borderBottom: '0px solid transparent',
        borderTop: '3px solid #F39B31',
        borderRadius: '5px',
        color: BLACK_FONT,
        fontSize: 12,
        lineHeight: '15px',
    },
    wrapper: {
        flexDirection: 'row',
        fontSize: '10px',
        justifyContent: 'flex-start',
        paddingLeft: '15px',
    },
    labelContainer: {
        paddingLeft: '5px',
        paddingRight: '5px',
        paddingBottom: '29px',
    },
    lastTabSelected: {
        backgroundColor: '#ffffff',
        borderLeft: '0.5px solid #e9e9e9',
        borderRight: '0px solid transparent',
        borderBottom: '0px solid transparent',
        borderTop: '3px solid #F39B31',
        borderRadius: '5px',
        color: BLACK_FONT,
        fontSize: 12,
        lineHeight: '15px',
    },
    tabsRoot: {
        backgroundColor: '#494949',
        height: 50,
        width: '100%',
        borderTopLeftRadius: 5,
        borderTopRightRadius: 5,
    },
    critical: {
        background: '#ef4f68',
        borderLeft: '0px solid #f3f3f3',
    },
    warning: {
        background: '#f8b421',
        borderRight: '0px solid #f3f3f3',
    },
    info: {
        background: '#0068d4',
        borderRight: '0px solid #f3f3f3',
    },
    cellWidth: {
        fontSize: TABLES_CSS.normalFontSize,
        color: TABLES_CSS.normalFontColor
    },
    title: {
        flex: '0 0 auto',
    },
    titleText: {
        marginLeft: '20px',
    },
    root: {
        width: 'calc(100% - 40px)',
        margin: '0px 20px',
        marginTop: '10px',
        borderRadius: 0,
        boxShadow: 'none',
    },
    cell: {
        textAlign: 'left',
        flexDirection: 'row',
        padding: 0,
        width: 'calc(100% / 6)',
        '&>:first-child': {
            paddingLeft: '20px !important',
        }
    },
    currentCell: {
        textAlign: 'left',
        flexDirection: 'row',
        padding: 0,
        paddingLeft: '0px !important',
        width: 'calc(100% / 6)',
    },
    tableRows: {
        cursor: 'pointer',
        height: '40px',
        "&:hover": {
            backgroundColor: "rgba(0, 0, 0, 0.07) !important"
        },
        backgroundColor: 'rgba(0, 0, 0, 0.04)'
    },
    tableRows1: {
        minHeight: '40px',
        backgroundColor: "#F0FFFF"
    },
    pointer: {
        cursor: 'pointer',
    },
    editIcon: {
        height: '30px',
        width: '30px',
        color: '#5F7B96',
        marginLeft: 5,
    },
    body: {
        boxShadow: '0 2px 4px 0 rgba(0,0,0,0.5)',
        borderRadius: 5,
        overflow: 'hidden',
        backgroundColor: '#fff',
    },
    subRow: {
        backgroundColor: '#f4f9f0',
    },
    minWidth: {
        minWidth: '60px !important',
        [theme.breakpoints.only('xs')]: {
            minWidth: '200px !important',
            maxWidth: '200px !important',
        }
    },
    noWrap: {
        overflow: 'hidden',
        whiteSpace: 'nowrap',
        textOverflow: 'ellipsis',
        maxWidth: '100%',
    },
    sortIcon: {
        fontSize: 10,
        marginLeft: 10,
        color: '#9B9B9B',
    },
    noDataText: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        height: '88px',
    },
    center: {
        display: 'flex',
        placeContent: 'center',
    },
    moreIcons: {
        color: '#5f94fe',
        cursor: 'pointer',
        fontSize: 16,
    },
    iconButton: {
        width: '30px',
        height: '30px',
    },
    wrapperAccountNameItems: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        width: '100%'
    },
    wrapperAccountLength: {
        backgroundColor: '#AAD3F7',
        minWidth: '20px',
        height: '20px',
        borderRadius: '10px',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
    },
    accountLength: {
        fontSize: '12px',
        color: '#4A4A4A',
        paddingTop: '2px',
        fontWeight: 'bold',
        margin: 0,
    },
    accountNameText: {
        fontSize: TABLES_CSS.normalFontSize,
        color: TABLES_CSS.normalFontColor,
        overflow: 'hidden',
        whiteSpace: 'nowrap',
        paddingLeft: '5px',
    },
    WrapperPaddingLeft: {
        paddingLeft: '10px',
        width: '100%',
    },
    width100per: {
        paddingLeft: '10px',
        width: '100%',
    },
    typography: {
        fontSize: TABLES_CSS.normalFontSize,
        color: TABLES_CSS.normalFontColor,
        display: 'inline-block',
    },
    userTableRow: {
        height: "40px",
        background: TABLES_CSS.subHeaderRowColor,
    },
    userTypography: {
        fontSize: TABLES_CSS.headingFontSize,
        color: TABLES_CSS.headingFontColor,
        fontWeight: TABLES_CSS.headingFontWeight,
        overflow: 'hidden',
        whiteSpace: 'nowrap',
    },
    flexCenter: {
        display: 'flex',
        alignItems: 'center',
    },
    wrapperAvatarUsername: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        maxWidth: 'calc(100% - 70px)',
        overflow: 'hidden',
    },
    username: {
        fontSize: TABLES_CSS.normalFontSize,
        color: TABLES_CSS.normalFontColor,
        display: 'inline-block',
    },
    activeStatus: {
        height: '19px',
        width: '55px',
        minWidth: '55px',
        fontFamily: 'Roboto',
        borderRadius: '8px',
        fontSize: '12px',
        lineHeight: '16px',
        textAlign: 'center',
        color: 'rgb(255, 255, 255)',
        paddingTop: 2,
        marginRight: '10px',
    },
    userActiveColor: {
        backgroundColor: '#1babab',
    },
    accountActiveColor: {
        backgroundColor: '#3B91DF',
    },
    dateTypography: {
        fontSize: TABLES_CSS.normalFontSize,
        maxHeight: 18,
        color: TABLES_CSS.normalFontColor,
    },
    dateDiv: {
        display: 'inline-block',
        paddingTop: 4,
    },
    userRole: {
        color: '#000000',
    },
    wrapperEditUserIcon: {
        display: 'flex',
        alignItems: 'left',
    },
    noUser: {
        textAlign: 'center',
    },
    noUserText: {
        fontSize: 14,
        textAlign: 'center'
    },
    currentRowIndicatorStyle: {
        backgroundColor: '#F4F9F0',
        display: 'inline-block',
        width: '4px',
        minWidth: '4px',
        marginLeft: '-20px',
    },
    currentRowIndicatorColor: {
        backgroundColor: '#00aead',
    },
    container: {
        minHeight: 'calc(100vh - 212px)',
    },
    dialogContentStyle: {
        maxWidth: 'unset'
    },
    rowIndicator: {
        display: 'inline-block',
        width: '4px',
        marginRight: '16px',
        height: '40px',
        backgroundColor: '#00aead',
    },
    disabledBackgroundColor: {
        backgroundColor: '#BAC4CE',
    },
    changedColor: {
        color: '#fff'
    },






    headerContainer: {
        display: 'flex',
        placeContent: 'center',
        margin: '0px 20px',
    },
    filterContainer: {
        height: '50px',
        marginTop: '10px',
        width: '100%',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        backgroundColor: '#fff',
        borderRadius: '5px',
        boxShadow: '0 2px 4px 0 rgba(0,0,0,0.5)',
        paddingLeft: '15px',
    },
    leftContainer: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
    },
    select: {
        fontSize: '12px',
        maxWidth: '100%',
    },
    pipe: {
        borderLeft: '1px solid #cdcdcd',
        height: '35px', width: '1px',
        margin: '0px 20px 0px 20px'
    },
});
