/* eslint-disable no-underscore-dangle */
import { withStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import React from 'react';

import Minus from 'mdi-material-ui/Minus';
import MoreHoriz from '@material-ui/icons/MoreHoriz';
import ContentLoader from 'react-content-loader';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faVideo } from '@fortawesome/free-solid-svg-icons';

const styles = () => ({
    videoCam: {
        color: '#c6c7c7',
        marginRight: '5px',
    },
    icon: {
        color: '#c6c7c7',
        marginRight: '5px',
    },
    miusSpan: {
        height: 15,
        width: 15,
        padding: 1,
        margin: '11px 19px 0 0',
        backgroundColor: '#c6c7c7',
        boxShadow: '0 2px 4px 0 rgba(0,0,0,0.20)',
    },
    minusSVG: {
        width: 13,
        height: 13,
        marginBottom: '2px',
        color: '#dbdbdb',
    },
    root: {
        margin: '10px 0 0 20px',
        height: 'auto',
        boxShadow: '0 2px 4px 0 rgba(0,0,0,0.5)',
        borderRadius: '7px',
        backgroundColor: '#fff',
        overflow: 'hidden',
    },
    greyArea: {
        height: 50,
        width: '100%',
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
    },
});

class ProjectCardLoader extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            isFirst: true,
            width: props._width,
            widthNum: 10,
        };
    }

    componentDidMount() {
        this.resetSize();
    }

    UNSAFE_componentWillReceiveProps() {
        this.resetSize();
    }

    componentDidUpdate() {
        this.resetSize(true);
    }

    resetSize = (fromUpdate) => {
        const { isFirst, width } = this.state;
        const { _width } = this.props;
        if (width !== _width && !fromUpdate) {
            this.setState({ width: _width, isFirst: true });
        }
        if (isFirst && this.root && this.root.clientWidth) {
            this.setState({ widthNum: this.root.clientWidth, isFirst: false });
        }
    };

    render() {
        const { classes } = this.props;
        const { width, widthNum, isFirst } = this.state;
        const width1 = widthNum - 70;
        const width2 = widthNum - 30;
        const isValid = width1 > 0 && width2 > 0 && width2 / 2 > 0 && width2 / 2 - 10 > 0;
        return (
            <div
                className={classes.root}
                ref={(element) => {
                    this.root = element;
                }}
                style={{ width }}
            >
                <div className={classes.greyArea} style={{ alignItems: 'flex-start' }}>
                    <div style={{ height: 50, width: width1, paddingLeft: 15 }}>
                        {!isFirst && isValid && (
                            <ContentLoader height={45} width={width1} speed={2}>
                                <rect x="0" y="15" rx="5" ry="5" width={width1} height="30" />
                            </ContentLoader>
                        )}
                    </div>
                    <span className={classes.miusSpan} style={{ borderRadius: '100%' }}>
                        <Minus className={classes.minusSVG} style={{ color: '#fff' }} />
                    </span>
                </div>
                <div style={{ padding: '0 15px' }}>
                    {!isFirst && isValid && (
                        <ContentLoader height={135} width={width2} speed={2}>
                            <rect x="0" y="5" rx="5" ry="5" width={width2 / 2 - 10} height="75" />
                            <rect x={width2 / 2} y="5" rx="5" ry="5" width={width2 / 2} height="75" />
                            <rect x={width2 / 2} y="90" rx="5" ry="5" width={width2 / 2} height="40" />
                        </ContentLoader>
                    )}
                </div>
                <div className={classes.greyArea} style={{ backgroundColor: '#f4f6f7', justifyContent: 'flex-end', height: 30 }}>
                    <FontAwesomeIcon icon={faVideo} aria-label="faVideo" id="faVideo" className={classes.videoCam} />
                    <MoreHoriz className={classes.icon} aria-label="moreHorizIcon" id="moreHorizIcon" />
                </div>
            </div>
        );
    }
}
ProjectCardLoader.propTypes = {
    classes: PropTypes.shape({}).isRequired,
};

export default withStyles(styles)(ProjectCardLoader);
