// import packages
import React, { Component } from 'react';
// import materials
import { withStyles } from '@material-ui/core/styles';
import { Card, Accordion, AccordionDetails, AccordionSummary } from '@material-ui/core';

import { Link } from 'react-router-dom';
import ChartHeader from '../ChartHeader/index';
import CardFooter from './CardFooter';
import Select from '../../modal/GeneralModal/Select';
import { BLACK_FONT, NO_DECORATION } from '../../../common/cssConstants';
import { checkArrayLength, sort } from '../../../utils';

class CardDashboard extends Component {
    state = {
        isOpenCircular: true,
        isEnlarged: false,
    };

    render() {
        const { isEnlarged, isOpenCircular } = this.state;
        const {
            classes,
            headerButtons,
            title,
            style,
            className,
            redirectMessage,
            toggleFullScreen,
            tickerIndicatorValue,
            changeSelection,
            convertedItems,
            selectedValues,
            isFullScreen,
            redirectURL,
        } = this.props;
        const sortedAlphabetically = sort(convertedItems, 'asc', 'label');
        const customStyles = {
            title:
                title === 'Projects'
                    ? {
                          marginRight: '5px',
                          minWidth: '80px',
                          maxWidth: '80px',
                      }
                    : {},
            titleCont:
                title === 'Projects'
                    ? {
                          justifyContent: 'space-between',
                          display: 'flex',
                          alignItems: 'center',
                          width: headerButtons ? '82%' : '100%',
                      }
                    : {
                          width: headerButtons ? '82%' : '100%',
                      },
        };

        const cardContent = (
            <Accordion expanded={isOpenCircular} className={classes.expansionPanel}>
                <AccordionSummary className={classes.expansionPanel} classes={{ content: classes.expansionPanel }}>
                    <ChartHeader
                        title={
                            <div style={customStyles.titleCont}>
                                <p style={customStyles.title} className={[classes.title, classes.noWrap].join(' ')}>
                                    {title}
                                </p>
                                {title === 'Projects' && (
                                    <div
                                        aria-hidden
                                        style={{ width: 'calc(100% - 80px)' }}
                                        onClick={(e) => {
                                            e.preventDefault();
                                            e.stopPropagation();
                                        }}
                                    >
                                        <Select
                                            value={checkArrayLength(selectedValues) ? selectedValues.filter((v) => v.label !== 'All') : []}
                                            options={sortedAlphabetically}
                                            onChange={changeSelection}
                                            className={classes.select}
                                            isBorder
                                            isMultiSelect
                                            isShowSelectedInList
                                            defaultItemValue={0}
                                            callingFrom={'CardDashboard'}
                                            selectName={`Project${selectedValues && selectedValues.length > 1 ? 's' : ''}`}
                                        />
                                    </div>
                                )}
                            </div>
                        }
                        maximise={
                            headerButtons
                                ? () => {
                                      if (isFullScreen !== undefined) {
                                          if (toggleFullScreen) {
                                              toggleFullScreen(!isFullScreen);
                                          }
                                          this.setState({
                                              isOpenCircular: true,
                                          });
                                      } else {
                                          this.setState({
                                              isEnlarged: !isEnlarged,
                                              isOpenCircular: true,
                                          });
                                      }
                                  }
                                : null
                        }
                        refresh={null}
                        minimise={
                            headerButtons
                                ? () => {
                                      this.setState({
                                          isOpenCircular: !isOpenCircular,
                                      });
                                  }
                                : null
                        }
                        isEnlarged={isFullScreen !== undefined ? isFullScreen : isEnlarged}
                        open={isOpenCircular}
                    />
                </AccordionSummary>
                <AccordionDetails className={classes.expansionPanelPie}>
                    <div
                        className={classes.contentContainer}
                        style={
                            isFullScreen
                                ? {
                                      minHeight: 'calc(500px - 76px)',
                                      maxHeight: 'calc(500px - 76px)',
                                  }
                                : {}
                        }
                    >
                        {this.props.children}
                    </div>
                    <CardFooter tickerIndicatorValue={tickerIndicatorValue} redirectMessage={redirectMessage} />
                </AccordionDetails>
            </Accordion>
        );

        return (
            <div
                className={`${classes.rootContainer} ${className || ''}`}
                style={
                    isFullScreen
                        ? {
                              ...style,
                              maxHeight: '500px',
                          }
                        : style
                }
            >
                <Card className={classes.cardRoot}>
                    {redirectURL ? (
                        <Link to={redirectURL} className={classes.noDecoration}>
                            {cardContent}
                        </Link>
                    ) : (
                        cardContent
                    )}
                </Card>
            </div>
        );
    }
}

// Styles
const styles = (theme) => ({
    rootContainer: {
        borderRadius: '7px',
        boxShadow: '0 2px 4px 0 rgba(0,0,0,0.15)',
        float: 'left',
        marginBottom: '20px',
        // minHeight: 320,
        maxHeight: 320,
        overflow: 'hidden',
        [theme.breakpoints.only('xs')]: {
            width: '100% !important',
            marginLeft: '0 !important',
            marginRight: '0 !important',
        },
    },
    cardRoot: {
        '& div': {
            cursor: 'pointer',
        },
        '& svg': {
            cursor: 'pointer',
        },
    },
    cardEnlarged: {
        width: '96vw',
        height: '100vh',
        position: 'fixed',
        marginTop: '46px',
        zIndex: 1,
        top: 0,
        left: '4vw',
    },
    noWrap: {
        overflow: 'hidden',
        whiteSpace: 'nowrap',
        textOverflow: 'ellipsis',
    },

    expansionPanel: {
        margin: '0px !important',
        padding: '0px !important',
        height: 'auto !important',
        minHeight: 'auto !important',
        maxHeight: '100% !important',
        cursor: 'auto !important',
        boxShadow: 'none',
        width: '100%',
    },
    expansionPanelPie: {
        margin: '0px !important',
        padding: '0px !important',
        height: 'auto !important',
        minHeight: 'auto !important',
        maxHeight: '100% !important',
        cursor: 'auto !important',
        boxShadow: 'none',
        display: 'block !important',
    },

    contentContainer: {
        width: '100%',
        minHeight: 'calc(320px - 76px)',
        maxHeight: 'calc(320px - 76px)',
        overflow: 'hidden',
    },

    title: {
        margin: 0,
        color: BLACK_FONT,
        fontSize: '20px',
        lineHeight: '26px',
        fontWeight: '300',
    },
    select: {
        display: 'block',
        position: 'unset',
        flexDirection: 'unset',
    },
    timespanIndicator: {
        minHeight: '30px',
        backgroundColor: '#F4F6F7',
        borderBottomLeftRadius: '6px',
        borderBottomRightRadius: '6px',
        color: '#9B9B9B',
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        paddingLeft: 13,
    },
    historyIcon: {
        fontSize: '18px',
        marginRight: '5px',
    },
    arrowIcon: {
        fontSize: '18px',
        marginRight: '5px',
        marginLeft: '5px',
    },
    footerText: {
        fontSize: '12px',
        color: '#9B9B9B',
    },
    closeIcon: {
        // color: '#9B9A9A',
        // fontSize: 20,
    },
    closeIconCont: {
        // width: '10px',

        position: 'absolute',
        right: '16px',
        top: '10px',
    },
    noDecoration: NO_DECORATION,
});

export default withStyles(styles)(CardDashboard);
