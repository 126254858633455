import Button from '@material-ui/core/Button';
import FormControl from '@material-ui/core/FormControl';
import InputAdornment from '@material-ui/core/InputAdornment';
import Typography from '@material-ui/core/Typography';
import { withStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import zxcvbn from 'zxcvbn';

// icons
import InfoOutline from '@material-ui/icons/InfoOutlined';

import { List } from '@material-ui/core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faLock } from '@fortawesome/free-solid-svg-icons';
import { styles } from '../userRegistration/style';
import { SIGNUP_PAGE_CSS, BLACK_FONT } from '../../common/cssConstants';
// import background from './Autonomiq-Forgot-BG.svg';
import { AuthActions } from '../../store/actions';
import Loader from '../../components/Loader';
import config from '../../config';
import Tooltip from '../../components/Tooltip';
import { AuthListItem } from './../../components/auth/AuthBtnRight';
import CustomTextField from '../../components/auth/CustomTextField';
import { PASSWORD_REGEX, ATLEAST_ONE_CAPITAL_REGEX, ATLEAST_ONE_SMALL_REGEX, ATLEAST_ONE_SPECIAL_CHAR_REGEX, ATLEAST_ONE_NUMBER_REGEX , PASSWORD_REGEX_APP_USER  } from '../../common/regexConstants';
import InstructionAlertModal from '../../components/InstructionAlertModal';
const { companyName, logoUrl, logoLarge , loginPageLogoUrl } = config;
const strengthText = ['weak', 'weak', 'medium', 'medium', 'strong'];

const passwordValidationErrors = [
    {
        text:isAppUser() ? 'At least 15 characters': 'At least 8 characters',
        valid:false,
        len: isAppUser()?15:8,
        tester: function(val) {

            let boolean = val.length<this.len?false:true;
            return boolean;
    
        }
        
    },
    {
        text:'At least one capital letter',
        valid:false,
        regex:ATLEAST_ONE_CAPITAL_REGEX

    },
    {
        text:'At least one small letter',
        valid:false,
        regex:ATLEAST_ONE_SMALL_REGEX

    },
    {
        text:'At least one special character',
        valid:false,
        regex:ATLEAST_ONE_SPECIAL_CHAR_REGEX

    },
    {
        text:'At least one number',
        valid:false,
        regex:ATLEAST_ONE_NUMBER_REGEX

    },
    {
        text:'Maximum 64 characters',
        valid:false,
        tester:(val)=>{
            let boolean =  val && val.length>64?false:true;
            return boolean;
        }

    },
    {
        text:'No spaces',
        valid:false,
        tester:(val) =>{
            return val.indexOf(' ') < 0;
        }

    },
]

function isAppUser () {
    const id = getParameterByName('id')
    if(id == '1') {
        return true
    }

    return false
}

function getParameterByName(name, url) {
    if (!url) url = window.location.href;
    name = name.replace(/[[\]]/g, '\\$&');
    const regex = new RegExp(`[?&]${name}(=([^&#]*)|&|#|$)`);
    const results = regex.exec(url);
    if (!results) return null;
    if (!results[2]) return '';
    return decodeURIComponent(results[2].replace(/\+/g, ' '));
}

class ResetPasswordBase extends React.Component {
    state = {
        allowResubmit: true,
        password: '',
        confirmPassword: '',
        passwordError: false,
        score: null,
        response: null,
    };

    componentDidMount() {
        this.setState({
            isAppUser:getParameterByName('id') == '1' ? true :false
        })
    }

    UNSAFE_componentWillReceiveProps(nextProps) {
        if (nextProps.success || nextProps.error) {
            this.setState({
                response: nextProps.success === 'OK' ? 'Your password has been successfully updated' : null,
            });
        }
    }

    get isSubmitDisabled() {
        const { allowResubmit, confirmPassword, password, passwordError } = this.state;
        const passwordMatched = password === confirmPassword;
        return !password || !passwordMatched || !allowResubmit || passwordError;
    }

    handleConfirmPassword = (event) => {
        const { value, name } = event.currentTarget;
        let obj = {};
        obj = { ...obj, [name]: value };
        this.setState(obj);
    }

    handlePassword = (event) => {
        const { value, name } = event.currentTarget;
        let obj = {};
        obj = { ...obj, [name]: value };
        this.setState(obj);

        let _score = null;
        // Validate password if any
        _score = value ? zxcvbn(value).score : null;
        
        const vaildPassword = this.state.isAppUser ? PASSWORD_REGEX_APP_USER.test(value) : PASSWORD_REGEX.test(value);
       // const hasSpace = /\s/.test(value)

        let _passwordError = false;
        if (!vaildPassword) {
            _passwordError = true;
        }

        let errors = [];

        for(let i=0; i<passwordValidationErrors.length;i++) {
            
            let vaildationObject = null;


            if(passwordValidationErrors[i].tester) {

                if(passwordValidationErrors[i].tester(value)) {
                    vaildationObject = {
                        text:passwordValidationErrors[i].text,
                        valid:true
                    }

                } else {
                    vaildationObject = {
                        text:passwordValidationErrors[i].text,
                        valid:false
                    }    

                }
            } else 

            if(passwordValidationErrors[i].regex && !passwordValidationErrors[i].regex.test(value)) {
                vaildationObject = {
                    text:passwordValidationErrors[i].text,
                    valid:false
                }

            } else {
                vaildationObject = {
                    text:passwordValidationErrors[i].text,
                    valid:true
                }
            }

            errors.push(vaildationObject)
        }
        this.setState({ validationErrors: errors,score: _score, passwordError: _passwordError });
    };

    validatePassword = (event) => {
        const { value } = event.target;
        let _score = null;
        // Validate password if any
        _score = value ? zxcvbn(value).score : null;
        const vaildPassword = PASSWORD_REGEX.test(value);
        let _passwordError = false;
        if ( !vaildPassword) {
            _passwordError = true;
        }

        this.setState({ score: _score, passwordError: _passwordError });
    };

    handleSubmit = async (event) => {
        const { clearAuthError, resetPassword } = this.props;
        const { password } = this.state;
        const id = getParameterByName('id');
        const token = getParameterByName('token');
        let _allowResubmit = false;

        event.preventDefault();

        this.setState({ allowResubmit: false });
        try {

            let data =  await resetPassword(password, token, id);
            sessionStorage.removeItem('reset_password_link_sent');
            sessionStorage.removeItem('reset_password_link_sent_username');


            if(data && data.status === 200) {
                this.setState({
                    showSavePasswordInstruction:true
                });
            } else if (data && data.status === 400) {
                this.setState({
                    showLinkExpiredInstruction:true
                });

            }


        } catch(err) {
            console.log("Error",err);
        }
        
        finally {
            _allowResubmit = true;
        }
        this.setState({
            allowResubmit: _allowResubmit,
        });
        clearAuthError();
    };

    checkPasswordMatched = (passwordMatched) => {
        if (this.state.confirmPassword.length) {
            if (passwordMatched) {
                if (!this.state.allowResubmit) {
                    this.setState({ allowResubmit: true });
                }
                return 'Password match';
            }
            return 'Password does not match';
        }
        return '';
    };

    goToLogin = () => {
        const { clearAuthError, clearAuthSuccess, history } = this.props;
        clearAuthError();
        clearAuthSuccess();
        history.push('/signin');
    };

    handleLogin = () => {
        this.props.history.push('signin');
    };

    render() {
        const { classes, success, isLoading } = this.props;

        const { confirmPassword, password, response, score } = this.state;
        const strength = strengthText[score];
        const passwordMatched = password === confirmPassword;
        const passwordMatchedText = this.checkPasswordMatched(passwordMatched);


        // Redirect to login if reset is successful.
        if (success) setTimeout(() => this.goToLogin(), 3000);
        // TODO: Set it, if there'll be linkExpiry functionality
        // this.linkExpiryError = (
        //     <Typography className={classes.errorText}>Your password reset link has expired or already been used. Please start your password reset process again.</Typography>
        // );
        return (
            <div className={classes.root}>
                {
                    this.state.showSavePasswordInstruction &&
                    <InstructionAlertModal onConfirm= {()=>{
                        this.setState({
                            showSavePasswordInstruction:false
                        });
                        this.props.history.push('/signin');

                    }}   instructionTitle={'Your password has been reset successfully'} instructionMessage={'Plase save your password to 1Password vault'}  open={true}/>
                }
                 {
                    this.state.showLinkExpiredInstruction &&
                    <InstructionAlertModal onConfirm= {()=>{
                        this.setState({
                            showLinkExpiredInstruction:false
                        });
                        this.props.history.push('/signin');

                    }}   instructionTitle={'Your password reset link has expired'} instructionMessage={'Please note that your password has not been reset. Please request a new link'} instructionType={'default'} instructionButtonText={'Go back to Login'}   open={true}/>
                }
                <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                    <List className={classes.listItems}>
                        <AuthListItem onClick={this.handleLogin} text="Go Back to Login" listItemRoot={classes.listItemRoot} />
                    </List>
                </div>
                <div className={classes.whiteCard}>
                    <div className={classes.container}>
                        <div className={classes.whiteCardLeft}>
                            <Typography component="h1" variant="h1" className={classes.trialText}>
                                Reset Your Password
                            </Typography>
                            <Typography variant="body2" className={classes.instructionTextForgot}>
                                Please enter your new password.
                                <Tooltip
                                    data={
                                        <div style={{ margin: '10px 7px' }}>
                                            <p
                                                style={{
                                                    fontSize: 12,
                                                    margin: 0,
                                                    color: '#4d4d4d',
                                                }}
                                            >
                                                Password must include:
                                            </p>
                                            <ul
                                                style={{
                                                    paddingLeft: 17,
                                                    fontSize: 12,
                                                    color: BLACK_FONT,
                                                    marginBottom: 5,
                                                }}
                                            >
                                                {
                                                    this.state.isAppUser ?
                                                    <li style={{ marginBottom: 3 }}>At least 15 chatacters</li> :
                                                    <li style={{ marginBottom: 3 }}>At least 8 chatacters</li> 
                                                
                                                }

                                                <li style={{ marginBottom: 3 }}>At least one capital letter</li>
                                                <li style={{ marginBottom: 3 }}>At least one small letter</li>
                                                <li style={{ marginBottom: 3 }}>At least one special character</li>
                                                <li style={{ marginBottom: 3 }}>At least one number</li>
                                                <li style={{ marginBottom: 3 }}>Maximum 64 characters</li>
                                                <li style={{ marginBottom: 3 }}>No spaces</li>
                                            </ul>
                                        </div>
                                    }
                                    isOnClick
                                    isElement
                                    position="bottom-start"
                                >
                                    <InfoOutline
                                        style={{
                                            fontSize: 18,
                                            marginLeft: 10,
                                            marginTop: 6,
                                        }}
                                        aria-label="infoOutlineIcon"
                                        id="infoOutlineIcon"
                                    />
                                </Tooltip>
                            </Typography>
                            <div className={[classes.formWrapper, response && classes.formWrapperWithError].join(' ')}>
                                {response && <Typography className={[classes.responseText, classes.colorError].join(' ')}>{response}</Typography>}
                            </div>
                            <FormControl style={{ width: '70%' }}>
                                <div
                                    style={{
                                        backgroundColor: 'white',
                                        width: '100%',
                                    }}
                                >
                                    <CustomTextField
                                        autoFocus
                                        id="passwordId"
                                        aria-label="Password Id"
                                        name="password"
                                        placeholder="Password"
                                        type="password"
                                        onChange={this.handlePassword}
                                       // onKeyPress={(event) => this.validatePassword(event)}
                                        className={`${classes.formInput} ${classes.placeholder}`}
                                        required
                                        InputProps={{
                                            startAdornment: (
                                                <InputAdornment
                                                    style={{
                                                        marginLeft: '10px',
                                                        marginBottom: '2px',
                                                        color: '#c4c4c4',
                                                    }}
                                                    position="start"
                                                    className={classes.inputAdornment}
                                                >
                                                    <FontAwesomeIcon icon={faLock} aria-label="faLock" id="faLock" />
                                                </InputAdornment>
                                            ),
                                        }}
                                    />
                                </div>
                                <div
                                    style={{
                                        backgroundColor: 'white',
                                        width: '100%',
                                        marginBottom: 3,
                                    }}
                                >
                                    <CustomTextField
                                        id="confirmPasswordId"
                                        aria-label="Confirm Password Id"
                                        name="confirmPassword"
                                        placeholder="Confirm Password"
                                        type="password"
                                        onChange={this.handleConfirmPassword}
                                        style={{ paddingTop: '8px' }}
                                        className={`${classes.formInput} ${classes.placeholder}`}
                                        required
                                        InputProps={{
                                            startAdornment: (
                                                <InputAdornment
                                                    style={{
                                                        marginLeft: '10px',
                                                        marginBottom: '2px',
                                                        color: '#c4c4c4',
                                                    }}
                                                    position="start"
                                                    className={classes.inputAdornment}
                                                >
                                                    <FontAwesomeIcon icon={faLock} aria-label="faLock" id="faLock" />
                                                </InputAdornment>
                                            ),
                                        }}
                                    />
                                </div>
                                {
                                   !this.state.passwordError && 
                                   <div>
                                   <div className={strength ? `${classes[`strength-${strength}`]} ${classes.strength}` : ''} style={{ height: 8 }}>
                                    <Typography>
                                       {""}
                                       &nbsp;
                                   </ Typography>

                                     </div>
                                    <div>
                               <Typography style={{textAlign:'end'}}className={strength ? `${classes[`strength_color-${strength}`]}` :'' }>
                                       {strength}
                                       &nbsp;
                                   </Typography>

                                 </div>  
                               </div>



                                }
                                {
                                 !this.state.passwordError &&
                                
                                <div style={{ marginTop: 2 }}>
                                    
                                    <Typography
                                        style={{
                                            fontSize: 12,
                                            marginBottom: 5,
                                            color: passwordMatched ? '#579609' : '#e30000',
                                        }}
                                    >
                                        {
                                         passwordMatchedText
                                        }
                                        &nbsp;
                                    </Typography>
                                    
                                </div>
                                }

                                {
                                    this.state.passwordError &&
                                    <div style={{
                                        padding:'6px'
                                    }}
>
                                    <p
                                        style={{
                                            fontSize: 12,
                                            margin: 0,
                                            color: '#4d4d4d',
                                        }}
                                    >
                                        Password must include:
                                    </p>
                                    <ul
                                        style={{
                                            paddingLeft: 17,
                                            fontSize: 12,
                                            marginBottom: 5,
                                        }}
                                    >
                                        {
                                            this.state.validationErrors && 
                                            this.state.validationErrors.map((error,index)=>{
                                                return (
                                                    <li style={{color:error.valid?'green':'red',marginBottom: 3}} key = {index} >{error.text}</li>

                                                )
                                            })
                                        }
                                    </ul>
                                </div>

                                }

                                
                                
                            </FormControl>
                            <div
                                style={{
                                    width: '70%',
                                    marginRight: '10px',
                                    paddingBottom: '5px',
                                    height: SIGNUP_PAGE_CSS.submitButtonHeight,
                                }}
                            >
                                <Button
                                    variant="contained"
                                    color="primary"
                                    className={classes.raisedBtn}
                                    style={{ width: '100%' }}
                                    onClick={this.handleSubmit}
                                    disabled={this.isSubmitDisabled}
                                    classes={{
                                        disabled: classes.loginDisabled,
                                    }}
                                >
                                    Reset
                                </Button>
                            </div>
                            {isLoading ? <Loader loadingText="Reseting Password" /> : null}
                        </div>
                        <div className={classes.whiteCardRight} style={{backgroundColor:'#0F5286'}}>
                            {companyName.toLocaleLowerCase() === 'autonomiq' ? (
                                <div>
                                    <img src={loginPageLogoUrl} alt=""  />
                                    {/* <img src={logoUrl} alt="" className={classes.autonomiqLogo} /> */}
                                </div>
                            ) : (
                                <img src={loginPageLogoUrl} alt="" />
                            )}
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

ResetPasswordBase.propTypes = {
    classes: PropTypes.shape({}).isRequired,
};

function mapStateToProps(state) {
    return {
        error: state.authReducer.error,
        success: state.authReducer.success,
        isLoading: state.authReducer.isLoading,
    };
}

function mapDispathToProps(dispatch) {
    return {
        resetPassword: (password, token, id) => dispatch(AuthActions.resetPassword(password, token, id)),
        clearAuthError: () => dispatch(AuthActions.clearAuthError()),
        clearAuthSuccess: () => dispatch(AuthActions.clearAuthSuccess()),
    };
}

export const ResetPassword = connect(mapStateToProps, mapDispathToProps)(withStyles(styles)(ResetPasswordBase));
