import React from 'react';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import FormControl from '@material-ui/core/FormControl';
import Typography from '@material-ui/core/Typography';
import Close from '@material-ui/icons/Close';
import CloseOnEscape from 'react-close-on-escape';
import { TextField } from '@material-ui/core';

import {
    DIALOG_ROOT,
    MODAL_HEADER_TITLE,
    MODAL_HEADER_CLOSE_ICON,
    MODAL_HEADER_CLOSE_ICON_CONT,
    MODAL_DELETE_BUTTON,
    MODAL_FOOTER,
    BLACK_FONT,
} from '../../../common/cssConstants';
import Tooltip from '../../../components/Tooltip';
import GeneralModalFooter from '../GeneralModal/GeneralModalFooter';
import { CANCEL_BUTTON, DANGER_OUTLINE_BUTTON } from '../../../components/Button/Btn';
import Recorder from './Recorder.png';
import config from '../../../config'

const styles = (theme) => ({
    dialogTitleStyle: MODAL_HEADER_TITLE,
    dialog: {
        textAlign: 'center',
        backgroundColor: '#e9effd',
        borderBottom: '1px solid #dcdcdc',
    },
    noWrap: {
        overflow: 'hidden',
        whiteSpace: 'nowrap',
        textOverflow: 'ellipsis',
        maxWidth: '100%',
    },
    formControl: {
        width: '100%',
    },
    dialogModal: {
        '&>div': {
            borderRadius: '0px',
        },
        ...DIALOG_ROOT,
    },
    dialogModalForRecorder: {
        '&>div': {
            '&>div':{
                width:"691px",
            }
        },
    },
    dataName: {
        width: 'calc( 100% - 30px )',
        float: 'left',
        fontSize: 12,
        fontWeight: 'bold',
        color: BLACK_FONT,
    },
    message: {
        float: 'left',
        fontSize: 12,
        color: BLACK_FONT,
    },
    boldFont: {
        color: BLACK_FONT,
        fontWeight: 500,
        marginLeft: 3,
    },
    dataValue: {
        float: 'left',
        fontSize: 12,
        fontWeight: 'normal',
    },
    dialogContent: {
        overflow: 'hidden',
        minWidth: 370,
        padding: '0 40px 0 30px !important',
        maxHeight: 'calc(100vh - 290px)',
        overflowY: 'auto',
        [theme.breakpoints.only('xs')]: {
            width: 'calc(100vw - 100px)',
        },
    },
    deleteButtonDanger: MODAL_DELETE_BUTTON,
    resetBtn: {
        backgroundColor: '#fff !important',
        color: '#d82301 !important',
        border: '1px solid #d82301 !important',
        '&:hover': {
            backgroundColor: '#d82301 !important',
            color: '#fff !important',
        },
    },
    installBtn: {
        padding: '9px 18px',
        minWidth: '75px',
        minHeight: '0px',
        borderColor: 'rgb(196, 196, 196)',
        borderRadius: '6px',
        fontSize: '12px',
        boxShadow: 'none',
        textTransform: 'capitalize',
        margin: '0px 10px 0px 0px',
        color: '#fff',
        backgroundColor: '#1168cd',
        '&:hover': {
            backgroundColor: 'rgb(11, 72, 143)',
        },
    },
});

const DeleteAlertModal = ({
    classes,
    handleClose,
    open,
    name,
    title,
    modalfor,
    details,
    list,
    count = 1,
    msg,
    submitBtnText,
    cancelBtnText,
    deleteButton,
    showRecorderInstalltion,
    handleChangeId,
    pluginId,
    resetBtn,
    handleReset,
    successMsg,
    showMsg,
}) => {
    let buttons = [CANCEL_BUTTON({ name: cancelBtnText || 'Cancel', action: () => handleClose(false) })];

    const getTitleAgainstModalFor = (_modalfor) => {
        switch (_modalfor) {
            case 'organizeDelete':
                return 'Delete Cases';
            case 'organizeDisable':
                return 'Disable Cases';
            case 'disableCases':
                return 'Disable Cases';
            case 'enableCases':
                return 'Enable Cases';
            case 'liveTestStep':
                return 'Delete Step';
            case 'liveTestSteps':
                return 'Delete Steps';
            case 'disableSuites':
                return 'Disable Suites';
            case 'clearVariables':
                return 'Clear Variables';
            case 'associate':
                return 'Replace Association';
            case 'enableSuites':
                return 'Enable Suites';
            case 'Project':
                return 'Disable Project';
            case 'multiDelete':
                return `Delete ${name}`;
            default:
                return `Delete ${modalfor}`;
        }
    };

    if (modalfor !== 'executeScript') {
        buttons.unshift(
            DANGER_OUTLINE_BUTTON({
                name:
                    modalfor === 'organizeDisable' || modalfor === 'disableSuites' || modalfor === 'disableCases' || modalfor === 'Project'
                        ? 'Disable'
                        : modalfor === 'enableSuites' || modalfor === 'enableCases'
                        ? 'Enable'
                        : modalfor === 'clearVariables'
                        ? 'Clear'
                        : submitBtnText || 'Delete',
                action: () => handleClose(true),
                buttonClass: deleteButton === 'Danger' ? classes.deleteButtonDanger : showRecorderInstalltion ? classes.installBtn : '',
            }),
        );
    }
    if(modalfor === 'Recorder'){
        let _buttons = [...buttons];
        _buttons.pop();
        const style = {
            ..._buttons[0].style,
            color: "#FFFFFF",
            backgroundColor:"#0E75DD"
        }
        _buttons = [{..._buttons[0],style}]
        buttons = _buttons
    }

    if (resetBtn) {
        buttons.splice(
            1,
            0,
            DANGER_OUTLINE_BUTTON({
                name: 'Reset',
                action: () => handleReset(),
            }),
        );
    }

    return (
        <div
            aria-hidden
            onClick={(e) => {
                e.stopPropagation();
            }}
        >
            <Dialog disableRestoreFocus open={open} aria-labelledby="form-dialog-title" className={`${classes.dialogModal} ${modalfor === 'Recorder' ? classes.dialogModalForRecorder : ''}`}>
                {modalfor === 'Recorder' && (
                    <div style={{ padding: '52px 30px 0px 15px', display: 'flex', flexDirection:"column", alignItems:"center" }}>
                        <div style={{ color: BLACK_FONT }}>
                            <div style={{ fontSize: 16, textAlign:"center", fontSize:"24px" }}>AutonomIQ AI Recorder</div>
                            <div style={{ fontSize: 12, margin: '20px 0', textAlign:"center", fontSize:"14px", lineHeight:'24px' }}> <span>{`Install the Recorder plugin v${config.pluginIdVersion} for Chrome and start recording your test steps with ease! After installing, launch the recording session from your Project’s page in AutonomIQ app, by selecting the `}</span><span><b>Add Test Case - Record.</b></span> </div>
                        </div>
                        <div>
                            <img src={Recorder} alt="" style={{ maxWidth: 440, maxHeight: 200, height: '100%', width:'100%' }} />
                        </div>
                    </div>
                )}
                <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                    {modalfor === 'VariableDataWarning' || modalfor === 'EditStep' || modalfor === 'Recorder' || modalfor === 'executeScript' ? (
                        <div style={modalfor === 'Recorder' ? {padding: '2px 0'} :{ padding: '30px 0px 0px 0px' }}></div>
                    ) : (
                        <DialogTitle id="form-dialog-title" classes={{ root: classes.dialogTitleStyle }}>
                            {getTitleAgainstModalFor(modalfor)}
                        </DialogTitle>
                    )}
                    <CloseOnEscape onEscape={() => handleClose(false)}>
                        <div style={MODAL_HEADER_CLOSE_ICON_CONT}>
                            <Close style={MODAL_HEADER_CLOSE_ICON} onClick={() => handleClose(false)} aria-label="closeIcon" id="closeIcon" />
                        </div>
                    </CloseOnEscape>
                </div>
                {modalfor === 'multiDelete' ||
                modalfor === 'associate' ||
                modalfor === 'executeScript' ||
                modalfor === 'disableSuites' ||
                modalfor === 'enableSuites' ||
                modalfor === 'disableCases' ||
                modalfor === 'enableCases' ? (
                    <div>
                        <DialogContent className={classes.dialogContent}>
                            {(modalfor === 'associate' || modalfor === 'executeScript') && msg ? (
                                <FormControl className={classes.formControl}>
                                    <div
                                        style={{
                                            width: '100%',
                                        }}
                                    >
                                        <Typography className={classes.message}>{msg}</Typography>
                                    </div>
                                </FormControl>
                            ) : null}
                            <FormControl className={classes.formControl}>
                                <div
                                    style={{
                                        width: '100%',
                                    }}
                                >
                                    <Typography className={classes.dataName}>{title}</Typography>
                                </div>
                            </FormControl>
                            {list.map((value, index) => (
                                <FormControl className={classes.formControl} key={`${index}-${value}`}>
                                    <div
                                        style={{
                                            width: '100%',
                                        }}
                                    >
                                        <Typography className={[classes.dataValue, classes.noWrap].join(' ')}>{value}</Typography>
                                    </div>
                                </FormControl>
                            ))}
                        </DialogContent>
                    </div>
                ) : (
                    <div>
                        {modalfor === 'Alert' && !count > 1 ? (
                            <DialogContent className={classes.dialogContent}>
                                <FormControl className={classes.formControl}>
                                    <div
                                        style={{
                                            width: '100%',
                                        }}
                                    >
                                        <Typography className={classes.dataName}>Project Name</Typography>
                                        <Typography className={classes.dataValue}>{title}</Typography>
                                    </div>
                                </FormControl>
                                <FormControl className={classes.formControl}>
                                    <div
                                        style={{
                                            width: '100%',
                                        }}
                                    >
                                        <Typography className={classes.dataName}>Details</Typography>
                                        <Typography className={classes.dataValue} noWrap style={{ maxWidth: '250px' }}>
                                            <Tooltip data={details}> {details} </Tooltip>
                                        </Typography>
                                    </div>
                                </FormControl>
                            </DialogContent>
                        ) : (
                            <DialogContent className={classes.dialogContent}>
                                <div
                                    style={{
                                        width: '100%',
                                    }}
                                >
                                    <Typography className={classes.message}>
                                        {modalfor === 'VariableDataWarning' || modalfor === 'EditStep' || modalfor === 'Project'
                                            ? msg
                                            : modalfor === 'Recorder'
                                            ? ''
                                            : `Are you sure you want to ${
                                                  modalfor === 'organizeDisable'
                                                      ? 'disable cases'
                                                      : modalfor === 'organizeDelete'
                                                      ? 'delete cases'
                                                      : modalfor === 'clearVariables'
                                                      ? 'clear all variables'
                                                      : modalfor === 'disableSuites' || modalfor === 'disableCases'
                                                      ? 'disable'
                                                      : modalfor === 'enableSuites' || modalfor === 'enableCases'
                                                      ? 'enable'
                                                      : 'delete'
                                              }`}
                                        {details || name ? <span className={classes.boldFont}>{details || name}</span> : ''}
                                        {modalfor === 'Recorder' ? '' : '?'}
                                    </Typography>
                                </div>
                                {modalfor === 'Recorder' ? (
                                    showRecorderInstalltion ? (
                                        <div>
                                            <TextField
                                                autoFocus
                                                margin="normal"
                                                InputLabelProps={{ shrink: true }}
                                                id="pluginId"
                                                label="Enter plugin id"
                                                type="text"
                                                fullWidth
                                                onChange={handleChangeId}
                                                value={pluginId}
                                                required
                                            />
                                            <div>{showMsg ? <Typography className={classes.message}>{successMsg}</Typography> : null}</div>
                                        </div>
                                    ) : null
                                ) : null}
                            </DialogContent>
                        )}
                    </div>
                )}
                <DialogActions style={modalfor === 'Recorder' ? {...MODAL_FOOTER, marginTop: 7, justifyContent:"center"}  : MODAL_FOOTER}>
                    <GeneralModalFooter buttons={buttons} />
                </DialogActions>
            </Dialog>
        </div>
    );
};
DeleteAlertModal.propTypes = {
    classes: PropTypes.shape({}).isRequired,
    open: PropTypes.bool.isRequired,
    handleClose: PropTypes.func.isRequired,
    modalfor: PropTypes.string.isRequired,
    showRecorderInstalltion: PropTypes.bool,
    handleChangeId: PropTypes.func,
    pluginId: PropTypes.string,
    resetBtn: PropTypes.any,
    handleReset: PropTypes.func,
    successMsg: PropTypes.string,
    showMsg: PropTypes.bool,
    name: PropTypes.string,
    list: PropTypes.instanceOf(Array),
    title: PropTypes.string,
};

DeleteAlertModal.defaultProps = {
    showRecorderInstalltion: false,
    handleChangeId: () => {},
    pluginId: '',
    resetBtn: false,
    handleReset: () => {},
    successMsg: '',
    showMsg: false,
    name: '',
    list: [],
    title: '',
};

export default withStyles(styles)(DeleteAlertModal);
