// import materials
import { withStyles } from '@material-ui/core/styles';
import { IconButton, Typography, Hidden, CircularProgress } from '@material-ui/core';
import ArrowCollapseVertical from 'mdi-material-ui/ArrowCollapseVertical';
import { faChevronDown, faChevronUp } from '@fortawesome/free-solid-svg-icons';

// import packages
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import React from 'react';

// import icons
import Export from 'mdi-material-ui/Export';
import { faTrashAlt } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

// import Custom components
import { AlertActions, GeneralActions, ModalActions } from './../../../store/actions';
import { EnhancedTableHead } from '../../../components/EnhancedTable';
import { deepArrayCompare, sort, checkArrayLength, checkKeyInObject, getFormatedDate, resizeAllCols, getMessageTooltip, isFormattedTooltip } from './../../../utils/utils';
import { styles } from './style';
import DeleteAlertModal from '../../modal/Delete/DeleteAlertModal';
import Tooltip from '../../../components/Tooltip';
import Table from '../../../components/Table/Table';
import TableBody from '../../../components/Table/TableBody';
import TableCell from '../../../components/Table/TableCell';
import TableRow from '../../../components/Table/TableRow';
import TableFooter from '../../../common/TableFooter';
import { GOTO_STEP_EDITOR } from '../../../common/constants';
import ExpandBtn from '../../../components/Button/ExpandBtn';
import Select from '../../modal/GeneralModal/Select';
// global varibale
let noLoader = false;

const headers = [
    { id: 'project_name', numeric: false, sortable: true, disablePadding: true, label: 'Project Name', styles: { paddingLeft: 10 }, width: "calc(100% / 4)", },
    { id: 'criticalCount', numeric: false, sortable: true, disablePadding: true, label: 'Criticals', styles: { paddingLeft: 10 }, width: "calc(100% / 4)", },
    { id: 'warningCount', numeric: false, sortable: true, disablePadding: true, label: 'Warnings', styles: { paddingLeft: 10 }, width: "calc(100% / 4)", },
    { id: 'actions', numeric: false, sortable: false, disablePadding: true, label: `Actions`, styles: { paddingLeft: 10 }, width: "calc(100% / 4)", },
];
// headers for cases
const subHeaders = [
    { id: 'test_case_name', sortable: true, parentIndex: 0, numeric: false, disablePadding: true, label: 'Test Case', styles: { paddingLeft: 10 }, },
    { id: 'creation_time', sortable: true, parentIndex: 1, numeric: false, disablePadding: true, label: 'Alert Date', styles: { paddingLeft: 10 }, },
    { id: 'message', sortable: true, parentIndex: 2, numeric: false, disablePadding: true, label: 'Details', styles: { paddingLeft: 10 }, },
    { id: 'actions', sortable: false, parentIndex: 3, numeric: false, disablePadding: true, label: 'Actions', styles: { paddingLeft: 10 }, },
];

const alertTypeOptions = [
    { value: -1, label: 'All Alerts' },
    { value: 0, label: 'Error' },
    { value: 1, label: 'Warning' },
];
const defaultAllProject = { value: -1, label: 'All Projects' }

class AlertDetails extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            expandedRow: null,
            criticalAlerts: [],
            warningAlerts: [],
            data: [],
            isMultiple: false,
            order: 'desc',
            orderBy: 'recentAlertDate',
            caseOrder: 'asc',
            caseOrderBy: 'name',
            page: 0,
            rowsPerPage: 20,
            selectedAlertType: alertTypeOptions[0],
            selectedProjects: [],
            search: '',
            selectedAtricalIds: [],
            openDelete: false,
            deleteData: null,
            selectedAlertNames: [],
            rowExpendStatus: [0, false],
        };

        this.colsWidth = {};
        props.updateTitle("Alert Details")
    }


    componentDidMount() {
        for (let i = 0, j = 0; i < headers.length && j < subHeaders.length; i++) {
            const header = headers[i];
            if (header.colSpan && header.colSpan > 1) {
                for (let k = j; k < j + header.colSpan; k++) {
                    const subHeader = subHeaders[k];
                    subHeader.width = `calc(${header.width} / ${header.colSpan})`;
                    subHeader.parentIndex = i;
                }
                j += header.colSpan;
            } else {
                header.colSpan = 1;
                const subHeader = subHeaders[j];
                subHeader.width = header.width;
                subHeader.parentIndex = i;
                j++;
            }
        }


        const {
            user: { accountId },
        } = this.props;
        if (accountId) {
            this.props.getAlerts(accountId);
        }
        noLoader = false;

        const alertTypeKey = this.props &&
            this.props.location &&
            this.props.location.state &&
            this.props.location.state.alertType;
        const alertType = { 'warning': 1, 'error': 0, 'all': -1 };
        if (alertTypeKey === 'warning' || alertTypeKey === 'error' || alertTypeKey === 'all') {
            this.setState({ selectedAlertType: alertTypeOptions[alertType[alertTypeKey]] });
        }
    }

    componentDidUpdate() {
        resizeAllCols(this.colsWidth);
    }

    arrangeAlerts = (data) => {
        // alertStatus 1,2 is warning
        // alertStatus 3 is critical
        const filter = (status) => {
            let _data = JSON.parse(JSON.stringify(data))
            let projects = [];
            _data.forEach((project) => {
                const testCases = []
                project.testCases.forEach((c) => {
                    const alerts = c.alerts.filter((a) => status.includes(a.status));
                    if (alerts.length) {
                        c.alerts = alerts;
                        testCases.push(c);
                    }
                })
                if (testCases.length) {
                    project.testCases = testCases;
                    projects.push(project);
                }
            });
            return projects;
        }
        const criticalAlerts = filter(["3"])
        const warningAlerts = filter(["1", "2"])
        return [criticalAlerts, warningAlerts];
    };

    arrangeReceivedData(alerts) {
        const alertsCopy = JSON.parse(JSON.stringify(alerts));
        let tempAlerts = {};
        alertsCopy.forEach((alert) => {
            const alertObj = {
                alert_id: alert.alert_id,
                status: alert.status,
                creation_time: alert.creation_time,
                disabled_status: alert.disabled_status,
                message: alert.message,
            };
            const testCaseObj = {
                testcase_id: alert.testcase_id,
                test_case_name: alert.test_case_name,
                recentAlertDate: alert.creation_time,
                firstAlertDate: alert.creation_time,
                alerts: [alertObj]
            };
            const updateDates = (obj) => {
                if (obj.recentAlertDate && alert.creation_time && new Date(obj.recentAlertDate) < new Date(alert.creation_time)) {
                    obj.recentAlertDate = alert.creation_time
                }
                if (obj.firstAlertDate && alert.creation_time && new Date(obj.firstAlertDate) > new Date(alert.creation_time)) {
                    obj.firstAlertDate = alert.creation_time
                }
                return obj;
            }
            if (tempAlerts[alert.project_id]) {
                tempAlerts[alert.project_id] = updateDates(tempAlerts[alert.project_id]);
                let testCases = tempAlerts[alert.project_id].testCases;
                let caseIndex = testCases.findIndex((c) => c.testcase_id === alert.testcase_id);
                if (caseIndex === -1) {
                    testCases.push(testCaseObj)
                } else {
                    testCases[caseIndex] = updateDates(testCases[caseIndex]);
                    testCases[caseIndex].alerts.push(alertObj)
                }
                tempAlerts[alert.project_id].testCases = testCases;
                tempAlerts[alert.project_id].criticalCount += alert.status === "3" ? 1 : 0;
                tempAlerts[alert.project_id].warningCount += alert.status === "1" || alert.status === "2" ? 1 : 0;
            } else {
                tempAlerts[alert.project_id] = {
                    project_name: alert.project_name,
                    project_id: alert.project_id,
                    recentAlertDate: alert.creation_time,
                    firstAlertDate: alert.creation_time,
                    criticalCount: alert.status === "3" ? 1 : 0,
                    warningCount: alert.status === "1" || alert.status === "2" ? 1 : 0,
                    testCases: [testCaseObj]
                }
            }
        })
        const [criticalAlerts, warningAlerts] = this.arrangeAlerts(Object.values(tempAlerts));
        this.setState({
            criticalAlerts,
            warningAlerts,
            data: alerts,
        });
    }

    UNSAFE_componentWillReceiveProps(nextProps) {
        const prevState = this.state;
        const flag =
            nextProps.alerts &&
            prevState.data &&
            Array.isArray(nextProps.alerts) &&
            Array.isArray(prevState.data);
        if (
            flag &&
            (
                nextProps.alerts.length !== prevState.data.length ||
                deepArrayCompare(nextProps.alerts, prevState.data)
            )
        ) {
            this.readyReopen = true;
            this.arrangeReceivedData(nextProps.alerts);
        }
    }

    componentWillUnmount() {
        const { queryRemove, clearSnackBar } = this.props;
        queryRemove();
        clearSnackBar();
    }

    activeTabData(criticalAlerts, warningAlerts) {
        let { selectedAlertType } = this.state;
        switch (checkKeyInObject(selectedAlertType, "value", "value", -1)) {
            case 0:
                return criticalAlerts
            case 1:
                return warningAlerts
            default:
                const merged = {};
                JSON.parse(JSON.stringify(criticalAlerts)).forEach(project => {
                    merged[project.project_id] = project;
                });
                JSON.parse(JSON.stringify(warningAlerts)).forEach(project => {
                    if (merged[project.project_id]) {
                        const cases = merged[project.project_id].testCases;
                        project.testCases.forEach((_case) => {
                            let caseIndex = cases.findIndex((c) => c.testcase_id === _case.testcase_id);
                            if (caseIndex === -1) {
                                cases.push(_case);
                            } else {
                                cases[caseIndex].alerts = [...cases[caseIndex].alerts, ..._case.alerts]
                            }
                        })
                        merged[project.project_id].testCases = cases
                    } else {
                        merged[project.project_id] = project;
                    }
                });
                return Object.values(merged);
        }
    }

    filterAlerts = (alerts) => {
        if (checkArrayLength(alerts)) {
            const {
                query,
            } = this.props;
            const {
                selectedProjects,
            } = this.state;
            alerts = alerts.filter((p) => (
                (
                    selectedProjects.length <= 0 ||
                    selectedProjects.some(sp => checkKeyInObject(p, 'project_id', 'value', -1) === sp.value)
                ) &&
                (
                    !query ||
                    checkKeyInObject(p, 'project_name', 'value', '').toLowerCase().includes(query.toLowerCase()) ||
                    checkKeyInObject(p, 'testCases', 'value', []).some((c) => checkKeyInObject(c, 'test_case_name', 'value', '').toLowerCase().includes(query.toLowerCase()))
                )
            ));
        }
        return alerts
    };

    // filterCounts = (alerts) => {
    //     let count = 0;
    //     if (checkArrayLength(alerts)) {
    //         alerts = alerts.forEach(//-----------------------
    //             (d) => {
    //                 if (d.checked)
    //                     count++;
    //             });
    //     }
    //     return count;
    // };

    handleRequestSort = (property) => {
        const orderBy = property;
        let order = 'desc';

        if (this.state.orderBy === property && this.state.order === 'desc') {
            order = 'asc';
        }

        this.setState({ order, orderBy });
    };

    // handleClick = (checked, row) => {
    //     let { selectedAlertType, criticalAlerts, warningAlerts } = this.state;
    //     [criticalAlerts, warningAlerts, row] = [[...criticalAlerts], [...warningAlerts], { ...row }];

    //     const tabData = this.activeTabData(criticalAlerts, warningAlerts);

    //     const index = tabData.map((alert) => alert.alert_id).indexOf(row.alert_id);//-----------------------
    //     row.checked = checked;
    //     tabData[index] = row;

    //     let obj = {};
    //     if (checked) {
    //         obj = {
    //             selectedAtricalIds: this.state.selectedAtricalIds.push(row.alert_id)
    //         }
    //     } else {
    //         let array = [...this.state.selectedAtricalIds];
    //         let index = array.indexOf(row.alert_id);
    //         if (index !== -1) {
    //             array.splice(index, 1);
    //             obj = {
    //                 selectedAtricalIds: array
    //             }
    //         }
    //     }
    //     switch (checkKeyInObject(selectedAlertType, "value", "value", -1)) {
    //         case 0:
    //             obj = {
    //                 ...obj,
    //                 criticalAlerts: tabData
    //             }
    //             break;
    //         case 1:
    //             obj = {
    //                 ...obj,
    //                 warningAlerts: tabData
    //             }
    //             break;
    //         default:
    //             const [_criticalAlerts, _warningAlerts] = this.arrangeAlerts(tabData);
    //             obj = {
    //                 ...obj,
    //                 criticalAlerts: _criticalAlerts,
    //                 warningAlerts: _warningAlerts
    //             }
    //             break;
    //     }
    //     this.setState(obj);
    // };

    handleChangePage = (page) => {
        this.setState({ page });
    };

    handleChangeRowsPerPage = (event) => {
        this.setState({ rowsPerPage: event.target.value });
    };

    // handleSelectAllClick = (event, currentPageAlerts) => {
    //     const checked = event.target.checked;
    //     this.selectDeselectAll(checked, currentPageAlerts);
    // };

    // selectDeselectAll = (checked, currentPageAlerts) => {
    //     let { selectedAlertType, criticalAlerts, warningAlerts, selectedAtricalIds } = this.state;
    //     selectedAtricalIds = [...selectedAtricalIds];
    //     const tabData = [...this.activeTabData(criticalAlerts, warningAlerts)];

    //     currentPageAlerts.map((n) => {
    //         let index = tabData.map((alert) => alert.alert_id).indexOf(n.alert_id); //-----------------------
    //         n.checked = checked;
    //         tabData[index] = n;
    //         index = selectedAtricalIds.indexOf(n.alert_id);
    //         if (checked && index === -1) {
    //             selectedAtricalIds.push(n.alert_id)
    //         } else if (!checked && index !== -1) {
    //             selectedAtricalIds.splice(index, 1);
    //         }
    //         return n
    //     });

    //     switch (checkKeyInObject(selectedAlertType, "value", "value", -1)) {
    //         case 0:
    //             this.setState({ criticalAlerts: tabData, selectedAtricalIds });
    //             break;
    //         case 1:
    //             this.setState({ warningAlerts: tabData, selectedAtricalIds });
    //             break;
    //         default:
    //             const [_criticalAlerts, _warningAlerts] = this.arrangeAlerts(tabData);
    //             this.setState({ criticalAlerts: _criticalAlerts, warningAlerts: _warningAlerts, selectedAtricalIds });
    //             break;
    //     }
    // }

    handleRemoveAlert = (item, isConfirm) => {
        if (isConfirm) {
            noLoader = true;
            const onCompelete = (data) => {
                noLoader = false;
                this.readyReopen = false;
                let interv = setInterval(() => {
                    if (this.readyReopen) {
                        this.readyReopen = false;
                        clearInterval(interv);
                        let filteredAlerts = data.sortedAlerts.filter(al => al.alert_id !== data.alert.alert_id)
                        if (filteredAlerts.length) {
                            this.toggleMultilineAlertName(data.project, data.case, filteredAlerts, true)
                        } else {
                            this.props.toggleModal();
                        }
                    }
                }, 10);
            }
            this.props.removeAlerts([item.alert.alert_id], onCompelete(item));
        }
        this.setState({
            openDelete: false,
            deleteData: null,
        });
    };

    avatarText = (row) => {
        return row && row.project_name && row.project_name.length
            ? row.project_name[0].toUpperCase()
            : '';
    };

    // handleRemoveSelected = (selected_articles, isConfirm) => {
    //     if (isConfirm) {
    //         const { getAlerts, user: { accountId }, } = this.props;
    //         noLoader = true;
    //         const onCompelete = () => {
    //             noLoader = false;
    //             getAlerts(accountId);
    //         }
    //         this.props.removeAlerts(selected_articles, onCompelete);
    //         selected_articles = [];
    //         this.setState({ selectedAtricalIds: [] })
    //     }
    //     this.setState({
    //         openDelete: false,
    //         deleteData: null,
    //         isMultiple: false,
    //         selectedAlertNames: [],
    //     });
    // };

    routeTestCase = (project_id, testCaseId) => {
        const { history } = this.props;
        history.push({
            pathname: `/details/${project_id}/${testCaseId}`
        });
    };

    currentRowIndicator = () => {
        const { classes } = this.props;
        return (<span className={classes.rowIndicator}></span>);
    }

    handleExpend = (id) => {
        this.setState((state) => ({
            rowExpendStatus: [
                id,
                id === state.rowExpendStatus[0] ? !state.rowExpendStatus[1] : true,
            ],
        }));
    }

    handleRequestCaseSort = (property) => {
        const caseOrderBy = property;
        let caseOrder = 'desc';
        if (this.state.caseOrderBy === property && this.state.caseOrder === 'desc') {
            caseOrder = 'asc';
        }
        this.setState({ caseOrder, caseOrderBy });
    };

    getStatusMsg = (alert) => {
        const {classes} = this.props;
        const TooltipMessage = getMessageTooltip(`${alert.status}`);
        return (<Typography className={`${classes.dateTypography} ${classes.noWrap}`}>
            <TooltipMessage
                data={alert.message}
                allowTooltipHover={true}
            >
                {isFormattedTooltip(alert.status) ?
                    <span dangerouslySetInnerHTML={{ __html: alert.message }} /> :
                    alert.message}
            </TooltipMessage>
        </Typography>);
    }

    toggleMultilineAlertName = (row, _case, sortedAlerts, reOpen = false) => {
        const { classes } = this.props;
        const alertDate = [];
        const type = [];
        const message = [];
        const actions = [];
        sortedAlerts.forEach((al) => {
            alertDate.push(getFormatedDate(al.creation_time, 'MMM D, YYYY', '-'))
            type.push(al.status === "3" ? "Critical" : "Warning")
            message.push(this.getStatusMsg(al));
            actions.push(<>
                <IconButton
                    className={classes.iconButton}
                    style={{ marginLeft: '10px' }}
                    aria-label="faTrashAltIcon"
                    onClick={() => {
                        this.setState({
                            openDelete: true,
                            deleteData: { project: row, case: _case, sortedAlerts, alert: al },
                        })
                    }}
                >
                    <FontAwesomeIcon
                        className={classes.moreIcons}
                        icon={faTrashAlt}
                        aria-label="faTrashAlt"
                        id="faTrashAlt"
                    />
                </IconButton>
            </>)
        })
        if (reOpen) {
            this.props.toggleModal();
        }
        this.props.toggleModal('filePreviewModal', null, null, {
            data: {
                data: {
                    "Alert Date": alertDate,
                    "Type": type,
                    "Message": message,
                    "Actions": actions
                },
                order: ["Alert Date", "Type", "Message", "Actions"]
            },
            name: _case.test_case_name,
            modalName: 'AlertsMsgs',
        });
    }

    getSortedAlerts = (alerts) => {
        if (checkArrayLength(alerts)) {
            return sort(alerts, "desc", "alert_id");
        }
        return [];
    }

    render() {
        const {
            classes,
            isLoading,
            // user: { name },
        } = this.props;
        const {
            order,
            orderBy,
            rowsPerPage,
            page,
            selectedAlertType,
            selectedProjects,
            criticalAlerts,
            warningAlerts,
            // selectedAtricalIds,
            openDelete,
            deleteData,
            isMultiple,
            rowExpendStatus,
            caseOrderBy,
            caseOrder,
            // data,
            // search,
        } = this.state;
        const tabData = this.activeTabData(criticalAlerts, warningAlerts);
        const projectOptions = [defaultAllProject];
        tabData.forEach((p) => {
            projectOptions.push({
                value: p.project_id,
                label: p.project_name
            })
        })
        const filteredData = this.filterAlerts(tabData);
        const currentPageAlerts = sort(filteredData, order, orderBy, orderBy === "recentAlertDate" || orderBy === "firstAlertDate" || orderBy === "creation_time" ? "time" : "").slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage);
        let currentSelectedAlerts = [];
        // filteredData.forEach(alert => {
        //     let index = selectedAtricalIds.indexOf(alert.alert_id);
        //     if (index !== -1) {
        //         currentSelectedAlerts.push(alert)
        //     }
        // });
        // const count = this.filterCounts(currentPageAlerts);
        return (
            <div>
                <div className={classes.headerContainer}>
                    <div className={classes.filterContainer} >
                        <div className={classes.leftContainer}>
                            <div style={{ width: 200 }}>
                                <Select
                                    value={selectedProjects}
                                    defaultItemValue={-1}
                                    onChange={(items) => { this.setState({ selectedProjects: items }) }}
                                    options={projectOptions}
                                    className={classes.select}
                                    isMultiSelect={true}
                                    isRoundBorder={true}
                                />
                            </div>
                            <span className={classes.pipe} />
                            <div style={{ width: 200 }}>
                                <Select
                                    value={selectedAlertType.value}
                                    onChange={(item) => { this.setState({ selectedAlertType: item }) }}
                                    options={alertTypeOptions}
                                    className={classes.select}
                                    readOnly={true}
                                    noClearIcon={true}
                                    isRoundBorder={true}
                                />
                            </div>
                        </div>
                    </div>
                </div>
                <div className={classes.root}>
                    <div className={classes.container}>
                        {/* {currentSelectedAlerts.length > 0 ?
                            <div style={{ display: 'flex', alignItems: 'center', backgroundColor: '#f0f7ea', padding: '0 10px', height: 45, justifyContent: 'space-between', }}>
                                <div style={{ display: 'flex', alignItems: 'center', }}>
                                    <span className={classes.avatar}> {currentSelectedAlerts.length} </span>
                                    <Typography className={classes.selectedText}>
                                        {currentSelectedAlerts.length == 1 ? 'Project is Selected' : 'Projects are Selected'}
                                    </Typography>
                                    <Close
                                        className={classes.multiCloseIcon}
                                        onClick={() => this.selectDeselectAll(false, filteredData)}
                                        aria-label="closeIcon"
                                        id="closeIcon"
                                    />
                                </div>
                                <FontAwesomeIcon
                                    className={classes.multiIcon}
                                    style={{ fontSize: 17 }}
                                    icon={faTrashAlt}
                                    aria-label="faTrashAlt"
                                    id="faTrashAlt"
                                    onClick={() => {
                                        let temp = [];
                                        data.map((alert) => {
                                            if (selectedAtricalIds.includes(alert.alert_id))
                                                temp.push(alert.message)
                                        })
                                        this.setState({
                                            openDelete: true,
                                            deleteData: selectedAtricalIds,
                                            isMultiple: true,
                                            selectedAlertNames: temp,
                                        })
                                    }}
                                />
                            </div> : null
                        } */}
                        <div className={classes.body}>
                            {checkArrayLength(currentPageAlerts) ?
                                <Table>
                                    <Hidden only={['xs']}>
                                        <EnhancedTableHead
                                            headers={headers}
                                            // numSelected={count}
                                            order={order}
                                            orderBy={orderBy}
                                            // onSelectAllClick={(e) => { this.handleSelectAllClick(e, currentPageAlerts) }}
                                            onRequestSort={this.handleRequestSort}
                                            currentPageAlerts={currentPageAlerts}
                                            // selectedAtricalIds={selectedAtricalIds}
                                            rowCount={currentPageAlerts.length}
                                            // showSelectBtn={true}
                                            colsWidth={this.colsWidth}
                                            resizeable={true}
                                        />
                                    </Hidden>
                                    <Hidden smUp>
                                        <EnhancedTableHead
                                            headers={headers}
                                            // numSelected={count}
                                            order={order}
                                            orderBy={orderBy}
                                            // onSelectAllClick={(e) => { this.handleSelectAllClick(e, currentPageAlerts) }}
                                            onRequestSort={this.handleRequestSort}
                                            currentPageAlerts={currentPageAlerts}
                                            // selectedAtricalIds={selectedAtricalIds}
                                            rowCount={currentPageAlerts.length}
                                            // showSelectBtn={true}
                                            colsWidth={this.colsWidth}
                                        />
                                    </Hidden>
                                    <TableBody style={{ maxHeight: `calc(100vh - ${(currentSelectedAlerts.length > 0 ? 45 : 0) + 257}px)` }}>
                                        {
                                            currentPageAlerts.map((row, ind) => {
                                                const clickCallback = () => this.handleExpend(row.project_id);
                                                const rows = [
                                                    <TableRow
                                                        data-testid={`row-${row.project_id}`}
                                                        hover
                                                        tabIndex={-1}
                                                        key={row.project_id}
                                                        className={classes.tableRows}
                                                        style={{cursor: 'auto'}}
                                                    >
                                                        <TableCell
                                                            className={`${classes.noWrap} ${classes.minWidth} ${rowExpendStatus[0] === row.project_id && rowExpendStatus[1] ? classes.currentCell : classes.cell}`}
                                                            width={headers[0].width}
                                                            data-resize={`element${0}_c`}
                                                        >
                                                            <div className={classes.width100per} style={{ paddingLeft: '0px' }}>
                                                                <div className={classes.flexCenter}>
                                                                    <div className={classes.wrapperAccountNameItems}>
                                                                        <div style={{ display: 'flex', alignItems: 'center', justifyContext: 'flex-start', maxWidth: '100%' }}>
                                                                            {rowExpendStatus[0] === row.project_id && rowExpendStatus[1] ? this.currentRowIndicator() : null}
                                                                            {/* <Checkbox onClick={(event) => this.handleClick(event.target.checked, row)} checked={row.checked || false} style={{ height: '40px', width: '20px', marginRight: '10px', color: row.checked ? '#3b91df' : '#bac4ce', }} /> */}
                                                                            <span className={`${classes.wrapperAccountLength}`}>
                                                                                <Typography className={`${classes.noWrap} ${classes.accountLength}`}>
                                                                                    {row.testCases.length}
                                                                                </Typography>
                                                                            </span>
                                                                            <span className={`${classes.noWrap} ${classes.accountNameText}`}>
                                                                                <Tooltip data={row.project_name ? row.project_name : null}>
                                                                                    {row.project_name}
                                                                                </Tooltip>
                                                                            </span>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </TableCell>
                                                        <TableCell
                                                            className={`${classes.noWrap} ${classes.minWidth} ${classes.cell}`}
                                                            width={headers[1].width}
                                                            data-resize={`element${1}_c`}
                                                        >
                                                            <div className={classes.width100per} style={{ paddingLeft: '0px' }}>
                                                                <div className={classes.flexCenter}>
                                                                    <div className={classes.wrapperAccountNameItems}>
                                                                        <div style={{ display: 'flex', alignItems: 'center', justifyContext: 'flex-start', maxWidth: '100%' }}>
                                                                            <span className={`${classes.noWrap} ${classes.accountNameText}`}>
                                                                                {row.criticalCount}
                                                                            </span>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </TableCell>
                                                        <TableCell
                                                            className={`${classes.noWrap} ${classes.minWidth} ${classes.cell}`}
                                                            width={headers[2].width}
                                                            data-resize={`element${2}_c`}
                                                        >
                                                            <div className={classes.width100per} style={{ paddingLeft: '0px' }}>
                                                                <div className={classes.flexCenter}>
                                                                    <div className={classes.wrapperAccountNameItems}>
                                                                        <div style={{ display: 'flex', alignItems: 'center', justifyContext: 'flex-start', maxWidth: '100%' }}>
                                                                            <span className={`${classes.noWrap} ${classes.accountNameText}`}>
                                                                                {row.warningCount}
                                                                            </span>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </TableCell>
                                                        <TableCell
                                                            className={`${classes.noWrap} ${classes.minWidth} ${classes.cell}`}
                                                            width={headers[3].width}
                                                            data-resize={`element${3}_c`}
                                                        >
                                                            <div className={`${classes.width100per} ${classes.WrapperPaddingLeft}`} style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', }}>
                                                                <ExpandBtn
                                                                    flag={rowExpendStatus[0] === row.project_id && rowExpendStatus[1]}
                                                                    action={() => { clickCallback() }}
                                                                />
                                                            </div>
                                                        </TableCell>
                                                    </TableRow>
                                                ];
                                                if (rowExpendStatus[0] === row.project_id && rowExpendStatus[1]) {
                                                    rows.push(
                                                        <TableRow className={classes.userTableRow} key={`${row.project_id}-subHeader`}>
                                                            {subHeaders.map((column, ind) => (
                                                                <TableCell width={column.width} data-sub-header={'subHeader'} data-child-resize={`${ind}_${column.parentIndex}_${headers[column.parentIndex].colSpan}`} className={`${classes.minWidth} ${classes.cell}`} key={`user-${column.id}`}>
                                                                    {column.sortable ?
                                                                        <div
                                                                            onClick={() => this.handleRequestCaseSort(column.id)}
                                                                            className={`${classes.width100per} ${classes.pointer}`}
                                                                        >
                                                                            <Typography
                                                                                variant="body2"
                                                                                className={classes.userTypography}
                                                                            >
                                                                                {column.label}
                                                                                {caseOrderBy === column.id
                                                                                    ? caseOrder === 'desc'
                                                                                        ? <FontAwesomeIcon icon={faChevronDown} aria-label="faChevronDown" id="faChevronDown" className={classes.sortIcon} />
                                                                                        : <FontAwesomeIcon icon={faChevronUp} aria-label="faChevronUp" id="faChevronUp" className={classes.sortIcon} />
                                                                                    : null
                                                                                }
                                                                            </Typography>
                                                                        </div> :
                                                                        <div className={classes.nonSortableContainer} key={column.id}>
                                                                            <Typography
                                                                                variant="body2"
                                                                                className={classes.userTypography}
                                                                            >
                                                                                {column.label}
                                                                            </Typography>
                                                                        </div>}
                                                                </TableCell>
                                                            ))}
                                                        </TableRow>
                                                    )
                                                }
                                                if (rowExpendStatus[0] === row.project_id && rowExpendStatus[1]) {
                                                    if (checkArrayLength(row.testCases)) {
                                                        let sortedCases = sort(row.testCases, caseOrder, caseOrderBy, caseOrderBy === "recentAlertDate" || caseOrderBy === "firstAlertDate" || caseOrderBy === "creation_time" ? "time" : "");
                                                        sortedCases.forEach((_case, index) => {
                                                            let sortedAlerts = this.getSortedAlerts(_case.alerts);
                                                            rows.push(<TableRow
                                                                hover
                                                                tabIndex={-1}
                                                                style={{
                                                                    display: rowExpendStatus[0] === row.project_id && rowExpendStatus[1] ? 'table-row' : 'none'
                                                                }}
                                                                className={classes.tableRows1}
                                                                key={`row-expanded-${_case.testcase_id}-${index}`}
                                                            >
                                                                <TableCell
                                                                    padding="none"
                                                                    className={`${classes.noWrap} ${classes.minWidth} ${classes.cell}`}
                                                                    width={subHeaders[0].width}
                                                                    data-child-resize={`${0}_${subHeaders[0].parentIndex}_${headers[subHeaders[0].parentIndex].colSpan}`}
                                                                >
                                                                    <div className={classes.width100per} style={{ paddingLeft: '0px' }}>
                                                                        <div className={classes.flexCenter}>
                                                                            <div className={classes.wrapperAccountNameItems}>
                                                                                <div style={{ display: 'flex', alignItems: 'center', justifyContext: 'flex-start', maxWidth: '100%' }}>
                                                                                    <span className={`${classes.wrapperAccountLength}`}>
                                                                                        <Typography className={`${classes.noWrap} ${classes.accountLength}`}>
                                                                                            {_case.alerts.length}
                                                                                        </Typography>
                                                                                    </span>
                                                                                    <span className={`${classes.noWrap} ${classes.accountNameText}`}>
                                                                                        <Tooltip data={_case.test_case_name ? _case.test_case_name : null}>
                                                                                            {_case.test_case_name}
                                                                                        </Tooltip>
                                                                                    </span>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </TableCell>
                                                                <TableCell
                                                                    className={`${classes.noWrap} ${classes.minWidth} ${classes.cell}`}
                                                                    width={subHeaders[1].width}
                                                                    data-child-resize={`${1}_${subHeaders[1].parentIndex}_${headers[subHeaders[1].parentIndex].colSpan}`}
                                                                >
                                                                    <div className={classes.width100per}>
                                                                        <div className={`${classes.dateDiv} ${classes.noWrap}`}>
                                                                            <Typography className={`${classes.dateTypography} ${classes.noWrap}`}>
                                                                                {getFormatedDate(sortedAlerts[0].creation_time, 'MMM D, YYYY', '-')}
                                                                            </Typography>
                                                                        </div>
                                                                    </div>
                                                                </TableCell>
                                                                <TableCell
                                                                    className={`${classes.noWrap} ${classes.minWidth} ${classes.cell}`}
                                                                    width={subHeaders[2].width}
                                                                    data-child-resize={`${2}_${subHeaders[2].parentIndex}_${headers[subHeaders[2].parentIndex].colSpan}`}
                                                                >
                                                                    <div className={classes.width100per}>
                                                                        <div className={`${classes.dateDiv} ${classes.noWrap}`}>
                                                                                {/* {this.getStatusMsg(sortedAlerts[0])} */}
                                                                        </div>
                                                                    </div>
                                                                </TableCell>
                                                                <TableCell
                                                                    className={`${classes.noWrap} ${classes.minWidth} ${classes.cell}`}
                                                                    width={subHeaders[3].width}
                                                                    data-child-resize={`${3}_${subHeaders[3].parentIndex}_${headers[subHeaders[3].parentIndex].colSpan}`}
                                                                >
                                                                    <div className={classes.width100per}>
                                                                        <Tooltip data={GOTO_STEP_EDITOR}>
                                                                            <IconButton
                                                                                className={classes.iconButton}
                                                                                style={{ marginLeft: '10px' }}
                                                                                aria-label="ExportIcon"
                                                                                onClick={() => this.routeTestCase(row.project_id, _case.testcase_id)}
                                                                                disabled={!_case.testcase_id}
                                                                            >
                                                                                <Export className={classes.moreIcons} />
                                                                            </IconButton>
                                                                        </Tooltip>
                                                                        <Tooltip data={'Show All'}>
                                                                            <IconButton
                                                                                className={classes.iconButton}
                                                                                style={{ marginLeft: '10px' }}
                                                                                aria-label="ArrowCollapseVerticalIcon"
                                                                                onClick={() => this.toggleMultilineAlertName(row, _case, sortedAlerts)}
                                                                                disabled={!_case.testcase_id}
                                                                            >
                                                                                <ArrowCollapseVertical className={classes.moreIcons} />
                                                                            </IconButton>
                                                                        </Tooltip>
                                                                    </div>
                                                                </TableCell>
                                                            </TableRow>)
                                                        })
                                                    } else {
                                                        rows.push(<TableRow
                                                            hover
                                                            tabIndex={-1}
                                                            key={`row-expanded-${row.project_id}-empty`}
                                                            style={{
                                                                display: rowExpendStatus[0] === row.project_id && rowExpendStatus[1] ? 'table-row' : 'none',
                                                            }}
                                                            className={`${classes.tableRows1} ${classes.center}`}
                                                        >
                                                            <TableCell className={classes.cell} colSpan={6} >
                                                                <Typography className={classes.noUserText}>
                                                                    {`No cases available`}
                                                                </Typography>
                                                            </TableCell>
                                                        </TableRow>)
                                                    }
                                                }
                                                return rows;
                                            })
                                        }
                                    </TableBody>
                                </Table>
                                :
                                isLoading && !noLoader ?
                                    <div className={classes.noDataText} data-testid={"loading"}>
                                        <CircularProgress className={classes.circularProgressIcon} size={50} thickness={3} />
                                    </div>
                                    :
                                    <Typography
                                        className={classes.noDataText}
                                        data-testid={"notFound"}
                                    >
                                        No Alerts Available!
                                    </Typography>
                            }
                        </div>
                        {openDelete ? (
                            <DeleteAlertModal
                                deleteButton={'Danger'}
                                handleClose={(isConfirm) => {
                                    if (!isMultiple) {
                                        this.handleRemoveAlert(deleteData, isConfirm)
                                    } /* else {
                                        this.handleRemoveSelected(deleteData, isConfirm)
                                    } */
                                }}
                                count={currentSelectedAlerts.length}
                                open={openDelete}
                                modalfor={isMultiple ? "multiDelete" : "Alert"}
                                title={isMultiple ? 'Alerts' : deleteData.project.project_name}
                                name={isMultiple ? 'Alert Names' : 'alert'}
                                details={deleteData.alert.message}
                                list={this.state.selectedAlertNames}
                            />
                        ) : null}
                    </div>
                </div>
                <TableFooter
                    count={filteredData.length}
                    rowsPerPage={rowsPerPage}
                    rowsPerPageOptions={[5, 10, 20, 50]}
                    page={page}
                    handleChangePage={this.handleChangePage}
                    handleChangeRowsPerPage={this.handleChangeRowsPerPage}
                    applyCustomClasses={true}
                    labelRowsPerPage={"Alerts per page:"}
                />
            </div>
        );
    }
}

AlertDetails.propTypes = {
    classes: PropTypes.shape({}).isRequired,
    query: PropTypes.string,
    queryRemove: PropTypes.func.isRequired,
    toggleModal: PropTypes.func.isRequired,
    user: PropTypes.instanceOf(Object),
};

AlertDetails.defaultProps = {
    query: '',
    user: {},
};

const mapStateToProps = (state) => {
    return {
        user: state.authReducer.user,
        alerts: state.alertReducer.alerts,
        isLoading: state.alertReducer.isLoading,
        query: state.generalReducer.queryValue,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        getAlerts: () =>
            dispatch(AlertActions.getAlerts()),
        removeAlerts: (...args) =>
            dispatch(AlertActions.removeAlerts(...args)),
        toggleModal: (...args) => dispatch(ModalActions.toggleModal(...args)),
        // General Action
        queryRemove: () => dispatch(GeneralActions.queryRemove()),
        clearSnackBar: () => dispatch(ModalActions.clearSnackbar()),
        updateTitle: (...args) => dispatch(GeneralActions.updateTitle(...args)),

    };
};

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(withStyles(styles)(AlertDetails));
