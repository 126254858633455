import { withStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import React from 'react';
import { connect, } from 'react-redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Chip from '@material-ui/core/Chip';
import { faPencilAlt, faFileCode, faLayerGroup, faDownload, faInfoCircle } from '@fortawesome/free-solid-svg-icons';
import Disabled from '@material-ui/icons/NotInterested';
import FileOutline from 'mdi-material-ui/FileOutline';
import DatabaseIcon from 'mdi-material-ui/Database';
import { faFolderOpen } from '@fortawesome/free-regular-svg-icons';
import { Typography} from '@material-ui/core';
import Grid from '@material-ui/core/Grid';

import { styles } from './styles_v2';
import { getFormatedDate, getCountAvatar } from '../../utils/utils';
import { THREE_DOT_MENU_CSS, MODAL_CANCEL_BUTTON, MODAL_SUBMIT_BUTTON } from '../../common/cssConstants';
import projectServices from '../../services/projectServices';
import { FlowActions, ModalActions, ProjectActions } from '../../store/actions';
import DeleteAlertModal from '../modal/Delete/DeleteAlertModal';
import { ProjectUtils } from '../../utils/ProjectUtils';
import FormattedTooltip from '../../components/FormattedTooltip';
import { MenuOptions } from '../../components/Button/MenuOptions';
import { NavLink } from 'react-router-dom';
import Tooltip from '../../components/Tooltip';

// const defaultTheme = createMuiTheme();
// const theme = createMuiTheme({
//       overrides: {
//         MuiTooltip: {
//           tooltip: {
//             fontSize: "2em",
//             color: "yellow",
//             backgroundColor: "red"
//           }
//         }
//       }
// });

const headers = ['TEST CASES', 'DATA', 'VARIABLES', 'FLOWS', 'TEST SUITES']

class ProjectDetailTopBar extends React.Component {
    constructor() {
        super();
        this.state = {
            prevProject: true,
            nextProject: true,
            deleteDialogOpen: false,
            alertMessage: '',
        };
        this.interval = null;
    }

    componentDidMount() {
        const { projectsByIdAndName, getProjectByIdAndName, selectedProject } = this.props;
        if (projectsByIdAndName && projectsByIdAndName.length && selectedProject) {
            this.setNextPrevProjects(projectsByIdAndName, selectedProject);
        } else {
            getProjectByIdAndName();
        }
    }

    UNSAFE_componentWillReceiveProps(nextProps) {
        const { projectsByIdAndName, selectedProject } = nextProps;
        if (projectsByIdAndName && projectsByIdAndName.length && selectedProject) {
            this.setNextPrevProjects(projectsByIdAndName, selectedProject);
        }
        if (this.props.isLoadingTestData === true && nextProps.isLoadingTestData === false) {
        }
    }

    setNextPrevProjects = (projects, selectedProject) => {
        let prev = null;
        let next = null;
        for (let i = 0; i < projects.length; i++) {
            if (`${selectedProject.projectId}` === `${projects[i].projectId}`) {
                prev = i > 0 ? projects[i - 1] : null;
                next = i < projects.length - 1 ? projects[i + 1] : null;
                break;
            }
        }
        this.setState({
            prevProject: prev,
            nextProject: next,
        });
    };

    // handleUpdateProject = async (projectId) => {
    //     const modalData = { project: this.props.project, user: this.props.user, updateProject: true, projectId };
    //     this.props.toggleModal('updateProject', null, null, modalData);
    // };

    handleNewProjectOpen = (event, projectId) => {
        event.stopPropagation();
        ProjectUtils.toggleUpdateModal(true, projectId, this.props.selectedProject);
    };

    confirmDelete = (isConfirm) => {
        if (isConfirm) {
            this.handleDelete();
        } else {
            this.setState({ deleteDialogOpen: false });
        }
    };

    handleOnDelete = (event) => {
        event.stopPropagation();
        this.setState({
            deleteDialogOpen: true,
        });
    };



    handleDelete = async () => {
        const { doDelete, selectedProject } = this.props;
        // If project has any test artefacts then prompt user for confirmation
        // Otherwise just delete it
        if (selectedProject.hasArtifacts && !this.state.alertMessage) {
            // TODO: remove this and replace with the ConfirmationDialog - needs to be a top-level managed instance via a store.
            /* eslint-disable no-alert */
            this.setState({ alertMessage: 'This project is not empty. Please confirm you wish to disable' });
        } else {
            this.setState({ deleteDialogOpen: false });

            const result = await doDelete({ projectId: selectedProject.projectId });
            if (result === true) {
                this.props.history.push('/plan/projects');
            }
        }
    };

    renderItems(selectedProject, classes) {
        const items = [
            {
                action: ($event) => this.handleNewProjectOpen($event, selectedProject.projectId),
                icon: (
                    <FontAwesomeIcon
                        style={{ fontSize: THREE_DOT_MENU_CSS.faIconFontSize, padding: '1px' }}
                        icon={faPencilAlt}
                        aria-label="faPencilAlt"
                        id="faPencilAlt"
                        className={classes.moreIcons}
                    />
                ),
                isDisabled: false,
                name: ' Project Properties',
            },
            {
                action: (e) => {
                    e.stopPropagation();
                    this.props.toggleModal('GeneralModal', null, null, {
                        title: 'Download Project',
                        closeIconAction: () => {
                            this.props.toggleModal();
                        },
                        component: [
                            {
                                content: (
                                    <div
                                        style={{
                                            width: '100%',
                                            padding: '0 30px',
                                            minWidth: '300px',
                                        }}
                                    >
                                        <Typography className={classes.message}>
                                            Are you sure you want to download{' '}
                                            <span className={classes.boldFont}>
                                                {selectedProject && selectedProject.projectName ? selectedProject.projectName : ''}
                                            </span>
                                            ?
                                        </Typography>
                                    </div>
                                ),
                                buttons: [
                                    {
                                        name: 'Download',
                                        variant: 'contained',
                                        buttonClass: '',
                                        style: { ...MODAL_SUBMIT_BUTTON },
                                        action: () => {
                                            const url = `/v1/${selectedProject.projectId}/download_project`;
                                            projectServices.downloadGradle(
                                                url,
                                                { fileName: selectedProject.projectName, id: selectedProject.projectId, type: 'project' },
                                                this.props.showSnackBar,
                                                'Unable to Export Project',
                                            );
                                            this.props.toggleModal();
                                        },
                                        isDisabled: false,
                                    },
                                    {
                                        name: 'Cancel',
                                        variant: 'outlined',
                                        style: { ...MODAL_CANCEL_BUTTON },
                                        action: () => {
                                            this.props.toggleModal();
                                        },
                                        isDisabled: false,
                                    },
                                ],
                            },
                        ],
                    });
                },
                icon: (
                    <FontAwesomeIcon
                        style={{ fontSize: THREE_DOT_MENU_CSS.faIconFontSize, padding: '1px' }}
                        icon={faDownload}
                        aria-label="faDownloadAlt"
                        id="faDownloadAlt"
                        className={classes.moreIcons}
                    />
                ),
                isDisabled: false,
                name: ' Download Project',
            },
            {
                action: (e) => this.handleOnDelete(e),
                icon: <Disabled className={classes.moreIcons} tyle={{ color: '#050a0e9e' }} aria-label="disabledIcon" id="disabledIcon" />,
                isDisabled: false,
                name: ' Disable Project',
            },
        ];
        return items;
    }

    render() {
        const { classes, callFromStepEditor, selectedProject, project ,openTaskPanel,callFromDatasheet} = this.props;
        const { deleteDialogOpen, alertMessage } = this.state;

        let created = getFormatedDate(selectedProject.creationTime, 'DD MMM YYYY', 'N/A');
        let LastUpdated = getFormatedDate(selectedProject.lastActivityDate, 'DD MMM YYYY', 'N/A');

        const renderI = `<div style={{ color: '#3B91DF' }}>
                            <p className={classes.textBold} >
                                Created:${created}
                            </p>
                            <p className={classes.textBold} >
                                Last Updated:${LastUpdated}
                            </p>
                            <p className={classes.textBold} >
                                Execution (${Number.isNaN(Math.ceil((100 * selectedProject.totalTestsPassedCount) / selectedProject.totalTestsCount))
                ? 0
                : Math.ceil((100 * selectedProject.totalTestsPassedCount) / selectedProject.totalTestsCount)}
                                %):${selectedProject.totalTestsPassedCount}(Pass)/${selectedProject.totalTestsCount}(total)
                            </p>
                          </div>`;
        let projectDetailSectionWidth = openTaskPanel ? 2 : 4;
        // let projectActionsSectionWidth = openTaskPanel ? 9 : 8;

        return (
            <div className={`${classes.headContainer} ${callFromStepEditor ? classes.stepEditorStyle : ''}`}>
                <Grid container style={{justifyContent:"space-between"}}  >

                    <Grid md = {projectDetailSectionWidth} lg = {projectDetailSectionWidth} xl={4} item>
                        <Grid style={{ padding: '12px 12px 12px 0px' }} container wrap="nowrap" spacing={1}>
                            <Grid className={classes.more} item style={{marginLeft: callFromDatasheet ? 5 : 0}}>
                                {!callFromDatasheet ? <FormattedTooltip isElement={true} data={renderI} arrow>
                                    <FontAwesomeIcon
                                        icon={faInfoCircle}
                                        style={{ color: '5F7B96', fontSize: '17px' }}
                                        className={[classes.moreIconsBtn,classes.shareButton].join(' ')}
                                        aria-label="faInfoAlt"
                                        id="faInfoAlt"
                                    />
                                </FormattedTooltip> : null}

                            </Grid>
                            <Grid item zeroMinWidth  >
                            <Typography noWrap className={classes.typography}>
                            <FormattedTooltip isElement={true} data={selectedProject.projectName} arrow>
                            {selectedProject.projectName}

                                </FormattedTooltip>

                            </Typography>


                                <Typography noWrap className={classes.url}>
                                    <a
                                        style={{ textDecoration: 'none', color: '#3B91DF' }}
                                        target="_blank"
                                        rel="noopener noreferrer"
                                        href={selectedProject.appUrl}
                                        onClick={(e) => {
                                            e.stopPropagation();
                                        }}
                                    >
                                        {selectedProject.appUrl}
                                    </a>
                                </Typography>


                            </Grid>
                            <Grid item>

                                {!callFromStepEditor && (
                                    <div className={classes.threeDotsButton}>
                                        {/* start dotted menu */}
                                        <MenuOptions items={this.renderItems(selectedProject, classes)} keepMounted />
                                        {/* end dotted menu */}
                                        {deleteDialogOpen ? (
                                            <DeleteAlertModal
                                                msg={alertMessage !== '' ? alertMessage : 'Are you sure you want to disable'}
                                                deleteButton="Danger"
                                                handleClose={this.confirmDelete}
                                                open={deleteDialogOpen}
                                                modalfor="Project"
                                                title="Project Name"
                                                name={
                                                    this.props.selectedProject && this.props.selectedProject.projectName
                                                        ? this.props.selectedProject.projectName
                                                        : ''
                                                }
                                            />
                                        ) : null}
                                    </div>
                                )}


                            </Grid>


                        </Grid>

                    </Grid>




                            {!callFromStepEditor && (
                                <div style={{display:'inline-flex',paddingRight:'12px'}}>
                                    <Grid className={classes.subtContainer} item >

                                        <NavLink className={classes.linkTo} activeStyle={{ borderBottom: '3px solid orange', display: 'inline-block', color: '#00528a', padding: '5px', fontWeight: 'bold' }} to={`/details/${project.projectId}/cases`}>
                                            <span className={classes.subtContainer}>
                                                <FileOutline
                                                    className={[classes.moreIconsBtn, classes.shareButton].join(' ')}
                                                    aria-label="TestCase"
                                                    id="TestCase"
                                                />
                                                <span className={`${classes.headerFont}`} >
                                                    {headers[0]}
                                                </span>

                                                <span style={{ marginLeft: '8px' }}>
                                                    <Chip label={getCountAvatar(project.caseCount)} />
                                                </span>


                                            </span>
                                        </NavLink>
                                    </Grid>

                                    <Grid className={classes.subtContainer} item >
                                        <NavLink className={classes.linkTo} activeStyle={{ borderBottom: '3px solid orange', display: 'inline-block', color: '#00528a', padding: '5px', fontWeight: 'bold' }} to={`/details/${project.projectId}/data`}>
                                            <span className={classes.subtContainer}>
                                                <DatabaseIcon
                                                    className={[classes.moreIconsBtn, classes.shareButton].join(' ')}
                                                    aria-label="Data"
                                                    id="Data"
                                                />
                                                <Tooltip 
                                                paperBody={classes.paperBody}
                                                tootTipLabel={classes.tootTipLabel}
                                                data="The saved data values that can be reused in test steps.">
                                                    <span className={classes.headerFont}>
                                                        {headers[1]}
                                                    </span>
                                                </Tooltip>

                                                <span style={{ marginLeft: '8px' }}>
                                                    <Chip label={getCountAvatar(project.dataCount)} />
                                                </span>
                                            </span>
                                        </NavLink>
                                    </Grid>
                                    <Grid className={classes.subtContainer} item >

                                        <NavLink className={classes.linkTo} activeStyle={{ borderBottom: '3px solid orange', display: 'inline-block', color: '#00528a', padding: '5px', fontWeight: 'bold' }} to={`/details/${project.projectId}/variables`}>
                                            <span className={classes.subtContainer}>
                                                <FontAwesomeIcon
                                                    icon={faFileCode}
                                                    className={[classes.moreIconsBtn, classes.shareButton].join(' ')}
                                                    aria-label="Variables"
                                                    id="Variables"
                                                />
                                                <Tooltip 
                                                paperBody={classes.paperBody}
                                                tootTipLabel={classes.tootTipLabel}
                                                data="Store data values into variables that can be reused across test cases.">
                                                    <span className={classes.headerFont}>
                                                        {headers[2]}
                                                    </span>
                                                </Tooltip>

                                                <span style={{ marginLeft: '8px' }}>

                                                    <Chip label={getCountAvatar(project.variablesCount)} />
                                                </span>

                                            </span>
                                        </NavLink>
                                    </Grid>
                                    <Grid className={classes.subtContainer} item >

                                        <NavLink className={classes.linkTo} activeStyle={{ borderBottom: '3px solid orange', display: 'inline-block', color: '#00528a', padding: '5px', fontWeight: 'bold' }} to={`/details/${project.projectId}/flows`}>
                                            <span className={classes.subtContainer}>
                                                <FontAwesomeIcon
                                                    icon={faLayerGroup}
                                                    className={[classes.moreIconsBtn, classes.shareButton].join(' ')}
                                                    aria-label="Flows"
                                                    id="Flows"
                                                />
                                                <Tooltip
                                                paperBody={classes.paperBody}
                                                tootTipLabel={classes.tootTipLabel} 
                                                data="Create a block of code that can be reused across test cases.">
                                                    <span className={classes.headerFont} >
                                                        {headers[3]}
                                                    </span>
                                                </Tooltip>

                                                <span style={{ marginLeft: '8px' }}>

                                                    <Chip label={getCountAvatar(project.flowsCount)} />
                                                </span>


                                            </span>
                                        </NavLink>
                                    </Grid>
                                    <Grid className={classes.subtContainer} item >

                                        <NavLink className={classes.linkTo} activeStyle={{ borderBottom: '3px solid orange', display: 'inline-block', color: '#00528a', padding: '5px', fontWeight: 'bold' }} to={`/details/${project.projectId}/suites`}>
                                            <span className={classes.subtContainer}>
                                                <FontAwesomeIcon
                                                    icon={faFolderOpen}
                                                    className={[classes.moreIconsBtn, classes.shareButton].join(' ')}
                                                    aria-label="Testsuites"
                                                    id="Testsuites"
                                                />
                                                <Tooltip
                                                paperBody={classes.paperBody}
                                                tootTipLabel={classes.tootTipLabel} 
                                                data="Group your test cases into test suites."> 
                                                    <span className={classes.headerFont} >
                                                        {headers[4]}
                                                    </span>
                                                </Tooltip>

                                                <span style={{ marginLeft: '8px' }}>

                                                    <Chip label={getCountAvatar(project.noOfEnvironments)} />
                                                </span>

                                            </span>
                                        </NavLink>
                                    </Grid>
                                </div>
                            )}
                        </Grid>





            </div>

        );
    }
}

ProjectDetailTopBar.propTypes = {
    classes: PropTypes.shape({}).isRequired,
    history: PropTypes.shape({}),
};

ProjectDetailTopBar.defaultProps = {
    history: {},
};



const mapStateToProps = (state) => {
    return {
        projectsByIdAndName: state.projectReducer.projectsByIdAndName,
        user: state.authReducer.user,
        isLoadingTestData: state.projectReducer.isLoadingTestData,
        selectedProject: state.projectReducer.selectedProject,
        openTaskPanel: state.generalReducer.openTaskPanel,

    };
};
const mapDispatchToProps = (dispatch) => {
    return {
        doDelete: (obj) => dispatch(ProjectActions.deleteProjects(obj)),
        getProjectByIdAndName: (...args) => dispatch(ProjectActions.getProjectByIdAndName(...args)),
        clearSelectedCaseDataAndProject: () => dispatch(ProjectActions.clearSelectedCaseDataAndProject()),
        toggleModal: (...args) => dispatch(ModalActions.toggleModal(...args)),
        showSnackBar: (...args) => dispatch(ModalActions.toggleSnackBar(...args)),
        clearFlows: () => dispatch(FlowActions.clearFlows()),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(ProjectDetailTopBar));