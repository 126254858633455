import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import Switch from '@material-ui/core/Switch';

export const CustomSwitch = ({
    h = 15,
    w = 26,
    trackColor = '#3B91DF',
    trackColorChk = '#3B91DF',
    trackBorderColor = '#bdbdbd',
    thumbColor = '#fff',
    thumbColorChk = '#fff',
    spaceBtwTrkThm = 1,
    margin = '13px 0 13px 0',
    ...props
}) => {
    const Sw = withStyles((theme) => ({
        root: {
            width: w,
            height: h,
            padding: 0,
            margin,
        },
        switchBase: {
            padding: `${spaceBtwTrkThm}px !important`,
            color: thumbColor,
            '&$checked': {
                transform: `translateX(${w - h}px)`,
                color: thumbColorChk,
                '& + $track': {
                    backgroundColor: trackColorChk,
                    opacity: 1,
                    border: 'none',
                },
            },
            //   '&$focusVisible $thumb': {
            //     color: trackColorChk,
            //     border: '6px solid #fff',
            //   },
        },
        thumb: {
            width: h - spaceBtwTrkThm * 2,
            height: h - spaceBtwTrkThm * 2,
        },
        track: {
            borderRadius: h / 2,
            border: `1px solid ${trackBorderColor}`,
            backgroundColor: trackColor,
            opacity: 0.38,
            transition: theme.transitions.create(['background-color', 'border']),
        },
        checked: {},
        focusVisible: {},
    }))(({ classes, ...rem }) => {
        return (
            <Switch
                focusVisibleClassName={classes.focusVisible}
                disableRipple
                classes={{
                    root: classes.root,
                    switchBase: classes.switchBase,
                    thumb: classes.thumb,
                    track: classes.track,
                    checked: classes.checked,
                }}
                {...rem}
            />
        );
    });
    return <Sw {...props} />;
};
