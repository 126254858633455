import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import isEqual from 'lodash/isEqual';
import { connect } from 'react-redux';
// custom components
import Datalist from './DataCaseTable';
import { GeneralActions, ProjectActions } from '../../../store/actions';
import CustomButton from '../../../components/Button/CustomButton';
import WSService from '../../../services/WSService';

const styles = () => ({
    tableheaderRoot: {
        padding: 15,
        backgroundColor: '#fff',
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        borderRadius: '5px 5px 0px 0px',
    },
    expansionPanelSummary: {
        minHeight: '0px !important',
        '&>:first-child': {
            position: 'relative',
            margin: '13px 0px 0px 0px',
            cursor: 'default !important',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'flex-end',
        },
    },
    expandIcon: {
        right: '10px',
    },
    expansionPanelDetails: {
        padding: '0 0px',
    },
    borders: {
        borderStyle: 'solid',
        borderWidth: '1px',
        borderColor: 'rgba(0,0,0,0.1)',
    },
});

class TestDataDetails extends Component {
    constructor(props) {
        super(props);
        this.state = {
            expanded: 'TestDataDetails',
            testData: props.testData,
            anchorElPopover: null
        };
    }

    UNSAFE_componentWillReceiveProps(nextProps) {
        if (!isEqual(nextProps.testData, this.props.testData)) {
            this.setState({ testData: nextProps.testData });
        }
    }

    getAssociatedTestCaseIds = (testDatum) => {
        const ids = [];
        testDatum.associatedTestCases.map((testCase) => testCase && ids.push(testCase.id));
        return ids;
    };

    getSelectedTestData = (testDataId) => {
        const { testData } = this.state;
        return testData && testData.find((testDatum) => `${testDatum.testDataId}` === `${testDataId}`);
    };

    handleChange = (panel) => () => {
        this.setState((state) => ({
            expanded: state.expanded !== panel ? panel : false,
        }));
    };

    handleUploadJSONFile = async (e) => {
        try{
            if (!e.target.value.length) {
                console.log("File opener cancled!");
                return null;
            }
            const fileReader = new FileReader();
            fileReader.readAsText(e.target.files[0], "UTF-8");
            fileReader.onload = async e => {
                const sessionId = WSService.getSessionId();
                const token = localStorage.getItem('token');
                let jsonData = e.target.result;
                WSService.sendMessage(
                    JSON.stringify({
                        msgType: 19,
                        authorizationToken: token,
                        sessionId: sessionId,
                        accountId: WSService.getAccountId(),
                        msgJson: JSON.stringify({
                            projectId: this.props.project?.projectId,
                        dataDef :jsonData,
                        })
                    }),
                );

            };
        }
        catch(err){
            console.log(err);
        }
    };

    toggleModal = (testDataId,modalType) => {
        const { testCases, toggleModal } = this.props;

        const testData = this.getSelectedTestData(testDataId);
        const associatedTestCaseIds = this.getAssociatedTestCaseIds(testData);
        const testDataValue = {
            associatedTestCaseIds,
            testCases,
            testDataId,
            type: testData.type,
            name: testData.testDataFileName,
            allData: this.state.testData,
        };

        // @params modalName:string, route:string, url:string, modalData:any
        toggleModal(modalType, null, null, testDataValue);
    };

    updateNewData = (data) => {
        if (data && data.data && data.data.length > 0) {
            const { testData } = this.state;
            testData.unshift({
                ...data.data[0],
                associatedTestCases: [
                    {
                        name: 'No Associated Test Case',
                        generatedAt: undefined,
                        id: '0',
                    },
                ],
            });
            this.setState({ testData });
        }
    };

    render() {
        const {
            deleteTestData,
            query,
            queryRemove,
            project,
            previewTestData,
            user: { accountId },
            history,
            location,
            classes,
            toggleModal,
            match
        } = this.props;

        const { testData } = this.state;

        const openPopover = event => {
            this.setState({anchorElPopover: event.currentTarget})
        };
        return (
            <div>
                
                <div className={classes.tableheaderRoot}>
                        <CustomButton 
                                title="Upload New Data"
                                onClick={(e) => {
                                    e.stopPropagation();
                                    //toggleModal('uploadTestCaseStep', null, null, project.projectId);
                                    toggleModal('uploadData', null, '', { projectSystemId : project.projectId, updateNewData : this.updateNewData, accountId: accountId })
                                }}
                                anchorEl={this.state.anchorElPopover}
                                open={Boolean(this.state.anchorElPopover)} 
                                onClose={() => this.setState({anchorElPopover: null})}
                                handleUploadJSONFile = {this.handleUploadJSONFile}
                />
                </div>
                <Datalist
                    handleDeleteTestData={deleteTestData}
                    accountId={accountId}
                    projectId={project.projectId}
                    testData={testData}
                    toggleModal={this.toggleModal} // open modal to attach data with testCase
                    toggleUploadDataModal={this.props.toggleModal} // open modal to upload data
                    previewTestData={previewTestData}
                    query={query}
                    match={match}
                    queryRemove={queryRemove}
                    updateNewData={this.updateNewData}
                    history={history}
                    location={location}
                />
            </div>
        );
    }
}

TestDataDetails.propTypes = {
    appUrl: PropTypes.string.isRequired,
    badgeName: PropTypes.string.isRequired,
    classes: PropTypes.shape({}).isRequired,
    date: PropTypes.instanceOf(Date).isRequired,
    deleteTestData: PropTypes.func.isRequired,
    project: PropTypes.shape({}).isRequired,
    query: PropTypes.string,
    queryRemove: PropTypes.func.isRequired,
    testData: PropTypes.array.isRequired,
    toggleModal: PropTypes.func.isRequired,
};

TestDataDetails.defaultProps = {
    query: '',
};

const mapStateToProps = (state) => {
    return {
        testData: state.projectReducer.testData,
        testCases: state.projectReducer.testCases,
        // general reducer
        query: state.generalReducer.queryValue,
        user: state.authReducer.user,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        // General Action
        queryRemove: () => dispatch(GeneralActions.queryRemove()),
        deleteTestData: (...args) => dispatch(ProjectActions.deleteTestData(...args)),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(TestDataDetails));
