// import material
import { withStyles } from '@material-ui/core/styles';
import Collapse from '@material-ui/core/Collapse';
import List from '@material-ui/core/List';
import ListItemText from '@material-ui/core/ListItemText';
import MenuItem from '@material-ui/core/MenuItem';
import Typography from '@material-ui/core/Typography';

// import libs
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';

// import icons
import { faUser } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Chart from 'mdi-material-ui/ChartLine';
import ChevronRight from '@material-ui/icons/ChevronRight';
import ExpandMore from '@material-ui/icons/ExpandMore';
import Logout from '@material-ui/icons/PowerSettingsNew';
import FileDocumentOutline from 'mdi-material-ui/FileDocumentOutline';
import Flash from 'mdi-material-ui/Flash';
import Gauge from 'mdi-material-ui/Gauge';

// import custom components

import { GeneralActions, SidebarActions, UserActions, AuthActions } from '../../../store/actions';
import './sideBarStyle.css';
import { getCountAvatar, isOnProjectPage } from '../../../utils/utils';
import { BLACK_FONT } from '../../../common/cssConstants';
import { USER_NAME, AUTONOMIQ_COPYRIGHT, AUTONOMIQ_VERSION, AUTONOMIQ_WEBSITE, AUTONOMIQ_YEAR } from '../../../common/constants';
import { SEGMENT_EVENT } from '../../../common/constants';
import { track } from '../../../services/Segment';
const styles = ({ palette, spacing, typography }) => ({
    expand: {
        display: 'flex',
        justifyContent: 'space-between',
        width: '100%',
    },
    expandIconContainer: {
        width: '20%',
        display: 'flex',
        justifyContent: 'center',
    },
    inset: {
        paddingLeft: '40px !important',
    },
    title: {
        backgroundColor: 'rgba(0, 0, 0, 0.22) !important',
        cursor: 'default',
        height: 50,
        padding: '15px 0px 0px 22px',
    },
    listItemParent: {
        color: palette.primary.main,
        height: 26,
        minHeight: 26,
        '&:hover': {
            backgroundColor: 'transparent',
            '& p': {
                color: 'white',
            },
            '& span': {
                color: 'white',
            },
            '& svg': {
                color: 'white',
            },
        },
    },
    listItem: {
        color: palette.primary.main,
        // height: 26,
        // minHeight: 26,
        height: 'auto',
        padding: '0px !important',
        '& a': {
            color: palette.primary.main,
            display: 'inline-flex',
            flex: 1,
            height: '100%',
            padding: '12px 16px',
            textDecoration: 'none',
            alignItems: 'center',
        },
        '&:hover': {
            backgroundColor: 'transparent',
            '& a': {
                color: 'white',
            },
            '& p': {
                color: 'white',
            },
            '& span': {
                color: 'white',
            },
            '& svg': {
                color: 'white',
            },
        },
    },
    secondary: {
        color: '#BAC4CE',
        paddingTop: spacing(0.5),
    },
    secondary1: {
        color: '#BAC4CE',
        paddingBottom: spacing(0.5),
    },
    textIcon: {
        width: 24,
        textAlign: 'center',
    },
    secondary_selected: {
        color: '#fff',
        fontSize: typography.caption.fontSize + 2,
        paddingTop: spacing(0.5),
    },
    selected: {
        backgroundColor: 'transparent !important',
        color: '#ffffff',
        '& > div > div p': {
            color: '#ffffff',
        },
    },
    muiMenuItem_root: {
        boxSizing: 'content-box',
        display: 'flex',
        paddingTop: 12,
        paddingBottom: 12,
        justifyContent: 'flex-start',
        paddingRight: 16,
        paddingLeft: 16,
        borderTop: '0px solid #616161',
        borderBottom: '0px solid #616161',
    },
    subSelected: {
        backgroundColor: 'rgba(0, 0, 0, 0.22)',
        borderLeft: '5px #F0B637 solid',
        '& > div p': {
            color: '#fff',
        },
    },
    subSelectedDark: {
        backgroundColor: 'black !important',
        borderLeft: '5px #F0B637 solid',
        paddingLeft: '11px !important',
        '& > div p': {
            color: '#fff',
        },
    },
    avatar: {
        minWidth: '35px',
        height: '20px',
        borderRadius: '20px',
        backgroundColor: '#b0c8fa',
        color: `${BLACK_FONT} !important`,
        '& span': {
            color: `${BLACK_FONT} !important`,
        },
        textAlign: 'center',
        marginRight: '2px',
        fontWeight: 500,
        fontSize: '13px',
        lineHeight: 1.7,
        padding: '0 4px',
        display: 'inline-block',
        overflow: 'hidden',
        marginLeft: '10px',
        marginTop: '4px',
        boxSizing: 'border-box',
    },
    listItemText: {
        marginTop: 0,
        marginBottom: 0,
        padding: '0 16px 0 25px',
    },
    container: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
    },
    copyVer: {
        margin: '20px 10px 10px',
        textAlign: 'center',
    },
    copyright: {
        marginBottom: 10,
        color: '#fff',
        fontSize: 11,
    },
    website: {
        lineHeight: 1,
        color: '#b5cdf2',
        textDecoration: 'none',
        fontSize: 11,
    },
    version: {
        color: '#fff',
        fontSize: 10,
    },
});

class SideBarContent extends Component {
    constructor() {
        super();
        this.state = {
            selectedItem: '/dashboard',
        };
    }

    componentDidMount() {
        this.highlightLocationOnSideBar();
        this.props.getSideBarDataCount();
    }

    UNSAFE_componentWillReceiveProps(nextProps) {
        if (nextProps.isUpdate !== this.props.isUpdate) {
            this.highlightLocationOnSideBar();
        }
    }

    // Highlight side bar as location changes
    highlightLocationOnSideBar = () => {
        // expand -> expandable as per selectedItem
        //  selectedItemId = 3 -> Admin
        //  selectedItemId = 4 -> Plan
        //  selectedItemId =  -> Analyze
        const { selectedItem } = this.state;
        const { location } = this.props.history;
        let url = selectedItem;
        let newUrl = location && location.pathname;
        // Check specially on refresh if selectedItem not equal to '/dashboard'
        if (newUrl && newUrl.includes('/details')) {
            newUrl = '/plan/projects';
        }
        if (newUrl && newUrl !== url) {
            url = newUrl;
            this.setState({ selectedItem: url });
        }
        this.expandOrCollaps(url);
    };

    // SideBar Parent Expand/Collapse ends
    expandOrCollaps = (url) => {
        //  use toggleSideBarItemExpansionFlag withour param to collapse all
        if (url.includes('/admin')) {
            this.toggleSideBarItemExpansion('admin');
        } else if (url.includes('/plan')) {
            this.toggleSideBarItemExpansion('plan');
        } else if (url.includes('/analyze')) {
            this.toggleSideBarItemExpansion('analyze');
        } else if (url.includes('/executions')) {
            this.toggleSideBarItemExpansion('executions');
        } else {
            this.toggleSideBarItemExpansion();
        }
    };

    toggleSideBarItemExpansion = (name = null) => {
        // Close all collapsables then open wrt to name
        this.props.toggleSideBarItemExpansionFlag();
        if (name) {
            this.props.toggleSideBarItemExpansionFlag(name);
        }
    };

    // Redirections
    routeToView = (name,page) => {



        // if(page) {
        //     page = page.toUpperCase();
        //     const eventName = SEGMENT_EVENT.MAIN_NAVIGATION_EVENT[page];
        //     track(eventName,{});
        // }

        const { history, swipable, toggleSwipableDrawer } = this.props;
        const { location } = history;

        if (swipable) toggleSwipableDrawer(false);
        if (location && location.pathname && location.pathname !== name) {
            this.setState({ selectedItem: name }, () => {
                this.expandOrCollaps(name);
            });
        }
    };

    render() {
        const {
            classes,
            expandAdmin,
            expandExecutions,
            expandPlan,
            user,
            expandAnalyze,
            sideBarCount,
            swipable,
            showDisabledProjects,
            isSideBarOpened,
        } = this.props;
        const { selectedItem } = this.state;
        let AdminFlag = false; // 'false' is indicate user has SDET role
        let AppuserFlag = false; // 'false' is indicate user is not an appuser
        if (user && user.role === 'ADMIN') {
            // change the flag value if user has ADMIN role
            AdminFlag = true;
            if (user.name === USER_NAME) {
                AppuserFlag = true;
            }
        }
        const subSelected = isSideBarOpened ? classes.subSelectedDark : classes.subSelected;
        const isAdminScreen = selectedItem === '/admin/accounts' || selectedItem === '/admin/users' || selectedItem === '/admin/groups';
        const content = (
            <div className={`container ${classes.container}`} style={{ maxHeight: `calc(100vh - ${swipable ? '120px' : '100px'})` }}>
                <div>
                    <MenuItem
                        button
                        onClick={() => this.routeToView('/dashboard','Dashboard')}
                        selected={selectedItem.includes('dashboard')}
                        className={classes.listItem}
                        classes={{
                            selected: subSelected,
                            root: classes.muiMenuItem_root,
                        }}
                        style={{ borderTop: '1px solid #616161', borderBottom: '1px solid #616161' }}
                    >
                        <Link to="/dashboard" style={selectedItem.includes('dashboard') ? { paddingLeft: 11 } : {}}>
                            <div style={{ display: 'inline-flex' }}>
                                <Gauge />
                                <ListItemText className={classes.listItemText} classes={{ secondary: classes.secondary }} secondary="Dashboard" />
                            </div>
                        </Link>
                    </MenuItem>
                    <MenuItem
                        button
                        selected={selectedItem.includes('/plan')}
                        className={classes.listItemParent}
                        classes={{
                            selected: classes.selected,
                            root: classes.muiMenuItem_root,
                        }}
                        onClick={() => this.props.toggleSideBarItemExpansionFlag('plan')}
                        style={{ borderTop: '1px solid #616161', borderBottom: expandPlan ? '' : '1px solid #616161' }}
                    >
                        <div className={classes.expand}>
                            <div style={{ display: 'inline-flex', width: '80%' }}>
                                <FileDocumentOutline />
                                <ListItemText className={classes.listItemText} classes={{ secondary: classes.secondary }} secondary="Plan" />
                            </div>
                            <div className={classes.expandIconContainer}>
                                {expandPlan ? (
                                    <ExpandMore aria-label="empandMoreIcon" id="expandMoreIcon" />
                                ) : (
                                    <ChevronRight aria-label="chevronRightIcon" id="chevronRightIcon" />
                                )}
                            </div>
                        </div>
                    </MenuItem>
                    <Collapse in={expandPlan} timeout="auto" unmountOnExit>
                        <List disablePadding style={{ borderBottom: '1px solid #616161' }}>
                            <MenuItem
                                button
                                className={classes.listItem}
                                onClick={() => this.routeToView('/plan/projects','Projects')}
                                classes={{
                                    selected: subSelected,
                                    root: classes.muiMenuItem_root,
                                }}
                                selected={isOnProjectPage()}
                            >
                                <Link to="/plan/projects" style={isOnProjectPage() ? { paddingLeft: 11 } : {}}>
                                    <div className={classes.expand}>
                                        <div style={{ display: 'inline-flex', width: '80%' }}>
                                            <span>
                                                <Typography
                                                    style={isOnProjectPage() ? { color: '#fff' } : {}}
                                                    className={[classes.secondary, classes.textIcon].join(' ')}
                                                >
                                                    P
                                                </Typography>
                                            </span>
                                            <ListItemText
                                                className={classes.listItemText}
                                                classes={{ secondary: classes.secondary1 }}
                                                secondary={
                                                    <span style={{ display: 'flex' }}>
                                                        <span style={isOnProjectPage() ? { color: '#fff' } : {}} className={classes.secondary}>
                                                            Projects
                                                        </span>
                                                        <span
                                                            className={classes.avatar}
                                                            style={isOnProjectPage() ? { backgroundColor: '#f0b637' } : {}}
                                                        >
                                                            {getCountAvatar(
                                                                showDisabledProjects
                                                                    ? sideBarCount.num_of_disable_projects
                                                                    : sideBarCount.num_of_projects,
                                                            )}
                                                        </span>
                                                    </span>
                                                }
                                            />
                                        </div>
                                    </div>
                                </Link>
                            </MenuItem>
                            <MenuItem
                                button
                                className={classes.listItem}
                                onClick={() => this.routeToView('/plan/testsuites','Test_Suites')}
                                classes={{
                                    selected: subSelected,
                                    root: classes.muiMenuItem_root,
                                }}
                                selected={selectedItem === '/plan/testsuites'}
                            >
                                <Link to="/plan/testsuites" style={selectedItem === '/plan/testsuites' ? { paddingLeft: 11 } : {}}>
                                    <div className={classes.expand}>
                                        <div style={{ display: 'inline-flex', width: '80%' }}>
                                            <span>
                                                <Typography
                                                    style={selectedItem === '/plan/testsuites' ? { color: '#fff' } : {}}
                                                    className={[classes.secondary, classes.textIcon].join(' ')}
                                                >
                                                    TS
                                                </Typography>
                                            </span>
                                            <ListItemText
                                                className={classes.listItemText}
                                                classes={{ secondary: classes.secondary1 }}
                                                secondary={
                                                    <span style={{ display: 'flex' }}>
                                                        <span
                                                            style={selectedItem === '/plan/testsuites' ? { color: '#fff' } : {}}
                                                            className={classes.secondary}
                                                        >
                                                            Test Suites
                                                        </span>
                                                        <span
                                                            className={classes.avatar}
                                                            style={selectedItem === '/plan/testsuites' ? { backgroundColor: '#f0b637' } : {}}
                                                        >
                                                            {getCountAvatar(sideBarCount.num_of_test_suits)}
                                                        </span>
                                                    </span>
                                                }
                                            />
                                        </div>
                                    </div>
                                </Link>
                            </MenuItem>
                            <MenuItem
                                button
                                className={classes.listItem}
                                onClick={() => this.routeToView('/plan/testcases','Test_Cases')}
                                classes={{
                                    selected: subSelected,
                                    root: classes.muiMenuItem_root,
                                }}
                                selected={selectedItem === '/plan/testcases'}
                                style={{ borderBottom: expandPlan ? '1px solid #616161' : '' }}
                            >
                                <Link to="/plan/testcases" style={selectedItem === '/plan/testcases' ? { paddingLeft: 11 } : {}}>
                                    <div className={classes.expand}>
                                        <div style={{ display: 'inline-flex', width: '80%' }}>
                                            <span>
                                                <Typography
                                                    style={selectedItem === '/plan/testcases' ? { color: '#fff' } : {}}
                                                    className={[classes.secondary, classes.textIcon].join(' ')}
                                                >
                                                    TC
                                                </Typography>
                                            </span>
                                            <ListItemText
                                                className={classes.listItemText}
                                                classes={{ secondary: classes.secondary1 }}
                                                secondary={
                                                    <span style={{ display: 'flex' }}>
                                                        <span
                                                            style={selectedItem === '/plan/testcases' ? { color: '#fff' } : {}}
                                                            className={classes.secondary}
                                                        >
                                                            Test Cases
                                                        </span>
                                                        <span
                                                            className={classes.avatar}
                                                            style={selectedItem === '/plan/testcases' ? { backgroundColor: '#f0b637' } : {}}
                                                        >
                                                            {getCountAvatar(sideBarCount.num_of_test_cases)}
                                                        </span>
                                                    </span>
                                                }
                                            />
                                        </div>
                                    </div>
                                </Link>
                            </MenuItem>
                        </List>
                    </Collapse>
                    {/* Executions Tab Start */}
                    <MenuItem
                        button
                        selected={selectedItem.includes('/executions')}
                        className={classes.listItemParent}
                        classes={{
                            selected: classes.selected,
                            root: classes.muiMenuItem_root,
                        }}
                        onClick={() => this.props.toggleSideBarItemExpansionFlag('executions')}
                        style={{ borderTop: '1px solid #616161', borderBottom: expandExecutions ? '' : '1px solid #616161' }}
                    >
                        <div className={classes.expand}>
                            <div style={{ display: 'inline-flex', width: '80%' }}>
                                <Flash />
                                <ListItemText className={classes.listItemText} classes={{ secondary: classes.secondary }} secondary="Executions" />
                            </div>
                            <div className={classes.expandIconContainer}>
                                {expandExecutions ? (
                                    <ExpandMore aria-label="expandMoreIcon" id="expandMoreIcon" />
                                ) : (
                                    <ChevronRight aria-label="chevronRightIcon" id="chevronRightIcon" />
                                )}
                            </div>
                        </div>
                    </MenuItem>
                    <Collapse in={expandExecutions} timeout="auto" unmountOnExit>
                        <List disablePadding style={{ borderBottom: '1px solid #616161' }}>
                            <MenuItem
                                button
                                className={classes.listItem}
                                onClick={() => this.routeToView('/executions/reports','Reports')}
                                classes={{
                                    selected: subSelected,
                                    root: classes.muiMenuItem_root,
                                }}
                                selected={selectedItem.includes('/executions/reports')}
                            >
                                <Link to="/executions/reports" style={selectedItem === '/executions/reports' ? { paddingLeft: 11 } : {}}>
                                    <div className={classes.expand}>
                                        <div style={{ display: 'inline-flex', width: '80%' }}>
                                            <span>
                                                <Typography
                                                    style={selectedItem === '/executions/reports' ? { color: '#fff' } : {}}
                                                    className={[classes.secondary, classes.textIcon].join(' ')}
                                                >
                                                    R
                                                </Typography>
                                            </span>
                                            <ListItemText
                                                className={classes.listItemText}
                                                classes={{ secondary: classes.secondary1 }}
                                                secondary={
                                                    <span style={{ display: 'flex' }}>
                                                        <span
                                                            style={selectedItem === '/executions/reports' ? { color: '#fff' } : {}}
                                                            className={classes.secondary}
                                                        >
                                                            Reports
                                                        </span>
                                                    </span>
                                                }
                                            />
                                        </div>
                                    </div>
                                </Link>
                            </MenuItem>
                            <MenuItem
                                button
                                className={classes.listItem}
                                onClick={() => this.routeToView('/executions/schedule','Schedules')}
                                classes={{
                                    selected: subSelected,
                                    root: classes.muiMenuItem_root,
                                }}
                                selected={selectedItem.includes('/executions/schedule')}
                                style={{ borderBottom: expandExecutions ? '1px solid #616161' : '' }}
                            >
                                <Link to="/executions/schedule" style={selectedItem === '/executions/schedule' ? { paddingLeft: 11 } : {}}>
                                    <div className={classes.expand}>
                                        <div style={{ display: 'inline-flex', width: '80%' }}>
                                            <span>
                                                <Typography
                                                    style={selectedItem === '/executions/schedule' ? { color: '#fff' } : {}}
                                                    className={[classes.secondary, classes.textIcon].join(' ')}
                                                >
                                                    S
                                                </Typography>
                                            </span>
                                            <ListItemText
                                                className={classes.listItemText}
                                                classes={{ secondary: classes.secondary1 }}
                                                secondary={
                                                    <span style={{ display: 'flex' }}>
                                                        <span
                                                            style={selectedItem === '/executions/schedule' ? { color: '#fff' } : {}}
                                                            className={classes.secondary}
                                                        >
                                                            Schedule
                                                        </span>
                                                    </span>
                                                }
                                            />
                                        </div>
                                    </div>
                                </Link>
                            </MenuItem>
                        </List>
                    </Collapse>
                    {/* Executions Tab End */}
                    <MenuItem
                        button
                        selected={selectedItem.includes('/analyze')}
                        className={classes.listItemParent}
                        classes={{
                            selected: classes.selected,
                            root: classes.muiMenuItem_root,
                        }}
                        onClick={() => this.props.toggleSideBarItemExpansionFlag('analyze')}
                        style={{ borderTop: '1px solid #616161', borderBottom: expandAnalyze ? '' : '1px solid #616161' }}
                    >
                        <div className={classes.expand}>
                            <div style={{ display: 'inline-flex', width: '80%' }}>
                                <Chart />
                                <ListItemText className={classes.listItemText} classes={{ secondary: classes.secondary }} secondary="Analyze" />
                            </div>
                            <div className={classes.expandIconContainer}>
                                {expandAnalyze ? (
                                    <ExpandMore aria-label="expandMoreIcon" id="expandMoreIcon" />
                                ) : (
                                    <ChevronRight aria-label="chevronRightIcon" id="chevronRightIcon" />
                                )}
                            </div>
                        </div>
                    </MenuItem>
                    <Collapse in={expandAnalyze} timeout="auto" unmountOnExit>
                        <List disablePadding style={{ borderBottom: '1px solid #616161' }}>
                            <MenuItem
                                button
                                className={classes.listItem}
                                onClick={() => this.routeToView('/analyze/alertdetails','Alert_Details')}
                                classes={{ selected: subSelected }}
                                selected={selectedItem === '/analyze/alertdetails'}
                            >
                                <Link to="/analyze/alertdetails" style={selectedItem === '/analyze/alertdetails' ? { paddingLeft: 11 } : {}}>
                                    <div className={classes.expand}>
                                        <div style={{ display: 'inline-flex', width: '80%' }}>
                                            <span>
                                                <Typography style={selectedItem === '/analyze/alertdetails' ? { color: '#fff' } : {}} className={[classes.secondary, classes.textIcon].join(' ')}>AD</Typography>
                                            </span>
                                            <ListItemText
                                                style={{ padding: '0 16px 0 25px' }}
                                                classes={{ secondary: classes.secondary1 }}
                                                secondary={<span style={{ display: 'flex' }}>
                                                    <span style={selectedItem === '/analyze/alertdetails' ? { color: '#fff' } : {}} className={classes.secondary}>Alert Details</span></span>}
                                            />
                                        </div>
                                    </div>
                                </Link>
                            </MenuItem>
                            

                            <MenuItem
                                button
                                className={classes.listItem}
                                onClick={() => this.routeToView('/analyze/smartmaintenance','Smart_Maintenance')}
                                classes={{
                                    selected: subSelected,
                                    root: classes.muiMenuItem_root,
                                }}
                                selected={selectedItem === '/analyze/smartmaintenance'}
                                style={{ borderBottom: expandAnalyze ? '1px solid #616161' : '' }}
                            >
                                <Link to="/analyze/smartmaintenance" style={selectedItem === '/analyze/smartmaintenance' ? { paddingLeft: 11 } : {}}>
                                    <div className={classes.expand}>
                                        <div style={{ display: 'inline-flex', width: '80%' }}>
                                            <span>
                                                <Typography
                                                    style={selectedItem === '/analyze/smartmaintenance' ? { color: '#fff' } : {}}
                                                    className={[classes.secondary, classes.textIcon].join(' ')}
                                                >
                                                    SM
                                                </Typography>
                                            </span>
                                            <ListItemText
                                                className={classes.listItemText}
                                                classes={{ secondary: classes.secondary1 }}
                                                secondary={
                                                    <span style={{ display: 'flex' }}>
                                                        <span
                                                            style={selectedItem === '/analyze/smartmaintenance' ? { color: '#fff' } : {}}
                                                            className={classes.secondary}
                                                        >
                                                            Smart Maintainance
                                                        </span>
                                                    </span>
                                                }
                                            />
                                        </div>
                                    </div>
                                </Link>
                            </MenuItem>
                        </List>
                    </Collapse>
                    {!AdminFlag ? (
                        <MenuItem
                            button
                            className={classes.listItem}
                            onClick={() => this.routeToView('/profile','Profile')}
                            classes={{
                                selected: classes.selected,
                                root: classes.muiMenuItem_root,
                            }}
                            selected={selectedItem === '/profile'}
                            style={{ borderTop: '1px solid #616161', borderBottom: expandAdmin ? '' : '1px solid #616161' }}
                        >
                            <Link to="/profile" style={selectedItem === '/profile' ? { paddingLeft: 11 } : {}}>
                                <div style={{ display: 'inline-flex' }}>
                                    <FontAwesomeIcon icon={faUser} aria-label="faUser" id="faUser" style={{ margin: '5px 5px 0px 7px' }} />
                                    <ListItemText className={classes.listItemText} classes={{ secondary: classes.secondary }} secondary="Profile" />
                                </div>
                            </Link>
                        </MenuItem>
                    ) : null}
                    {AdminFlag ? (
                        <MenuItem
                            button
                            selected={selectedItem.includes('/admin')}
                            className={classes.listItemParent}
                            classes={{
                                selected: classes.selected,
                                root: classes.muiMenuItem_root,
                            }}
                            onClick={() => this.props.toggleSideBarItemExpansionFlag('admin')}
                            style={{ borderTop: '1px solid #616161', borderBottom: expandAdmin ? '' : '1px solid #616161' }}
                        >
                            <div className={classes.expand}>
                                <div style={{ display: 'inline-flex', width: '80%' }}>
                                    <FontAwesomeIcon icon={faUser} aria-label="faUser" id="faUser" style={{ margin: '5px 5px 0px 7px' }} />
                                    <ListItemText className={classes.listItemText} classes={{ secondary: classes.secondary }} secondary="Admin" />
                                </div>
                                <div className={classes.expandIconContainer}>
                                    {expandAdmin ? (
                                        <ExpandMore aria-label="expandMoreIcon" id="expandMoreIcon" />
                                    ) : (
                                        <ChevronRight aria-label="chevronRightIcon" id="chevronRightIcon" />
                                    )}
                                </div>
                            </div>
                        </MenuItem>
                    ) : null}
                    {AdminFlag ? (
                        <Collapse in={expandAdmin} timeout="auto" unmountOnExit>
                            <List disablePadding style={{ borderBottom: '1px solid #616161' }}>
                                <MenuItem
                                    button
                                    className={classes.listItem}
                                    onClick={() => this.routeToView('/admin','Console')}
                                    classes={{
                                        selected: subSelected,
                                        root: classes.muiMenuItem_root,
                                    }}
                                    selected={selectedItem === '/admin'}
                                >
                                    <Link to="/admin" style={selectedItem === '/admin' ? { paddingLeft: 11 } : {}}>
                                        <div style={{ display: 'inline-flex', width: '80%' }}>
                                            <span>
                                                <Typography
                                                    style={selectedItem === '/admin' ? { color: '#fff' } : {}}
                                                    className={[classes.secondary, classes.textIcon].join(' ')}
                                                >
                                                    C
                                                </Typography>
                                            </span>
                                            <ListItemText
                                                className={classes.listItemText}
                                                classes={{ secondary: classes.secondary1 }}
                                                secondary={
                                                    <span style={{ display: 'flex' }}>
                                                        <span
                                                            style={selectedItem === '/admin' ? { color: '#fff' } : {}}
                                                            className={classes.secondary}
                                                        >
                                                            Console
                                                        </span>
                                                    </span>
                                                }
                                            />
                                        </div>
                                    </Link>
                                </MenuItem>
                                <MenuItem
                                    button
                                    className={classes.listItem}
                                    onClick={() => this.routeToView('/admin/profile','Profile')}
                                    classes={{
                                        selected: subSelected,
                                        root: classes.muiMenuItem_root,
                                    }}
                                    selected={selectedItem === '/admin/profile'}
                                >
                                    <Link to="/admin/profile" style={selectedItem === '/admin/profile' ? { paddingLeft: 11 } : {}}>
                                        <div style={{ display: 'inline-flex', width: '80%' }}>
                                            <span>
                                                <Typography
                                                    style={selectedItem === '/admin/profile' ? { color: '#fff' } : {}}
                                                    className={[classes.secondary, classes.textIcon].join(' ')}
                                                >
                                                    PR
                                                </Typography>
                                            </span>
                                            <ListItemText
                                                className={classes.listItemText}
                                                classes={{ secondary: classes.secondary1 }}
                                                secondary={
                                                    <span style={{ display: 'flex' }}>
                                                        <span
                                                            style={selectedItem === '/admin/profile' ? { color: '#fff' } : {}}
                                                            className={classes.secondary}
                                                        >
                                                            Profile
                                                        </span>
                                                    </span>
                                                }
                                            />
                                        </div>
                                    </Link>
                                </MenuItem>
                                <MenuItem
                                    button
                                    className={classes.listItem}
                                    onClick={() => this.routeToView('/admin/accounts',AppuserFlag ? 'Accounts' : 'Users')}
                                    classes={{
                                        selected: subSelected,
                                        root: classes.muiMenuItem_root,
                                    }}
                                    selected={isAdminScreen}
                                >
                                    <Link to={`/admin/${AppuserFlag ? 'accounts' : 'users'}`} style={isAdminScreen ? { paddingLeft: 11 } : {}}>
                                        <div className={classes.expand}>
                                            <div style={{ display: 'inline-flex', width: '80%' }}>
                                                <span>
                                                    <Typography
                                                        style={isAdminScreen ? { color: '#fff' } : {}}
                                                        className={[classes.secondary, classes.textIcon].join(' ')}
                                                    >
                                                        {AppuserFlag ? 'A' : 'U'}
                                                    </Typography>
                                                </span>
                                                <ListItemText
                                                    className={classes.listItemText}
                                                    classes={{ secondary: classes.secondary1 }}
                                                    secondary={
                                                        <span style={{ display: 'flex' }}>
                                                            <span style={isAdminScreen ? { color: '#fff' } : {}} className={classes.secondary}>
                                                                {AppuserFlag ? 'Accounts' : 'Users'}
                                                            </span>
                                                            <span
                                                                className={classes.avatar}
                                                                style={isAdminScreen ? { backgroundColor: '#f0b637' } : {}}
                                                            >
                                                                {getCountAvatar(
                                                                    AppuserFlag ? sideBarCount.num_of_accounts : sideBarCount.num_of_users,
                                                                )}
                                                            </span>
                                                        </span>
                                                    }
                                                />
                                            </div>
                                        </div>
                                    </Link>
                                </MenuItem>
                                <MenuItem
                                    button
                                    className={classes.listItem}
                                    onClick={() => this.routeToView('/admin/preferences','Preferences')}
                                    classes={{
                                        selected: subSelected,
                                        root: classes.muiMenuItem_root,
                                    }}
                                    selected={selectedItem === '/admin/preferences'}
                                    style={{ borderBottom: '1px solid #616161' }}
                                >
                                    <Link to="/admin/preferences" style={selectedItem === '/admin/preferences' ? { paddingLeft: 11 } : {}}>
                                        <div style={{ display: 'inline-flex', width: '80%' }}>
                                            <span>
                                                <Typography
                                                    style={selectedItem === '/admin/preferences' ? { color: '#fff' } : {}}
                                                    className={[classes.secondary, classes.textIcon].join(' ')}
                                                >
                                                    PF
                                                </Typography>
                                            </span>
                                            <ListItemText
                                                className={classes.listItemText}
                                                classes={{ secondary: classes.secondary1 }}
                                                secondary={
                                                    <span style={{ display: 'flex' }}>
                                                        <span
                                                            style={selectedItem === '/admin/preferences' ? { color: '#fff' } : {}}
                                                            className={classes.secondary}
                                                        >
                                                            Preferences*
                                                        </span>
                                                    </span>
                                                }
                                            />
                                        </div>
                                    </Link>
                                </MenuItem>
                            </List>
                        </Collapse>
                    ) : null}
                    {swipable && (
                        <MenuItem
                            button
                            onClick={() => {
                                this.props.logout();
                            }}
                            // selected={selectedItem === '/dashboard'}
                            className={classes.listItemParent}
                            classes={{ selected: classes.selected, root: classes.muiMenuItem_root }}
                        >
                            <div style={{ display: 'inline-flex' }}>
                                <Logout aria-label="logoutIcon" id="logoutIcon" />
                                <ListItemText className={classes.listItemText} classes={{ secondary: classes.secondary }} secondary="Log out" />
                            </div>
                        </MenuItem>
                    )}
                </div>
                {isSideBarOpened ? (
                    <div className={classes.copyVer}>
                        <div className={classes.copyright}>
                            {AUTONOMIQ_YEAR}{' '}
                            <a className={classes.website} href={`${AUTONOMIQ_WEBSITE}`}>
                                AutonomIQ.io
                            </a>
                            {AUTONOMIQ_COPYRIGHT}
                            {/* <div className={classes.website}><a href={`${AUTONOMIQ_WEBSITE}`}>{AUTONOMIQ_WEBSITE.replace(/https?:[/]{2}/, '')}</a></div> */}
                            {/* <div className={classes.copyright}>{AUTONOMIQ_COPYRIGHT}</div> */}
                        </div>
                        <div className={classes.version}>{AUTONOMIQ_VERSION}</div>
                    </div>
                ) : null}
            </div>
        );
        return content;
    }
}

SideBarContent.propTypes = {
    classes: PropTypes.shape({}).isRequired,
};

function mapStateToProps(state) {
    return {
        user: state.authReducer.user,
        expandAdmin: state.userReducer.expandAdmin,
        expandPlan: state.userReducer.expandPlan,
        expandExecutions: state.userReducer.expandExecutions,
        expandAnalyze: state.userReducer.expandAnalyze,
        sideBarCount: state.sidebarReducer.sideBarCount,
        showDisabledProjects: state.projectsReducer.showDisabledProjects,
    };
}

function mapDispatchToProps(dispatch) {
    return {
        toggleSideBarItemExpansionFlag: (...args) => dispatch(UserActions.toggleSideBarItemExpansion(...args)),
        getSideBarDataCount: () => dispatch(SidebarActions.getSideBarDataCount()),
        toggleSwipableDrawer: (...args) => dispatch(GeneralActions.toggleSwipableDrawer(...args)),
        logout: () => dispatch(AuthActions.logout()),
    };
}
export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(SideBarContent));
