export const BLACK_FONT = '#000000';
export const WHITE_FONT = '#fff';
export const EXECUTION_BUTTON = '#019D9D';

export const LOGIN_PAGE_CSS = {
    normalFontSize: '14px',
    normalFontColor: '#4D4D4D',
    darkFontColor: WHITE_FONT,
    errorFontColor: '#fff !important',
    successFontColor: '#2FBF71 !important',
    logoHeight: '27px',
    logoWidth: '150px',
    textFieldHeight: '40px',
    submitButtonHeight: '40px',
    submitButtonColor: WHITE_FONT,
    submitButtonBackgroundColor: '#0F5286',
    submitButtonDisableColor: '#fff !important',
    submitButtonDisableBackgroundColor: '#999 !important',
};

export const SIGNUP_PAGE_CSS = {
    normalFontSize: '14px',
    normalFontColor: '#4D4D4D',
    darkFontColor: WHITE_FONT,
    errorFontColor: '#f00 !important',
    successFontColor: '#2FBF71 !important',
    logoHeight: '27px',
    logoWidth: '150px',
    textFieldHeight: '30px',
    submitButtonHeight: '40px',
    submitButtonColor: WHITE_FONT,
    submitButtonBackgroundColor: '#1e69cc',
    submitButtonDisableColor: '#fff !important',
    submitButtonDisableBackgroundColor: '#999 !important',
};

export const TABLES_CSS = {
    normalFontSize: '12px',
    maximumFontSize: '30px',
    headingFontSize: '12px',
    headingFontWeight: '500',
    headingFontColor: BLACK_FONT,
    disableFontColor: 'rgb(186, 196, 206)',
    normalFontColor: BLACK_FONT,
    rowColor: '#f4f6f7',
    focusRowColor:'#fff',
    subHeaderRowColor: '#fffff',
};

export const THREE_DOT_MENU_CSS = {
    fontSize: '12px',
    iconFontSize: '20px',
    faIconFontSize: '17px',
    faDeleteIconHeight: '18px',
    faDeleteIconWidth: '18px',
    iconColor: '#5F7B96',
    iconDisableColor: '#bbbbbb',
    iconMarginRight: 5,
    iconMarginLeft: 10,
    hover: {
        backgroundColor: 'rgba(0, 0, 0, 0.1)',
        borderRadius: '12px',
    },
};

export const TABLE_PAGE_HEADING = {
    fontSize: '25px',
    fontWeight: 'normal',
    fontColor: BLACK_FONT,
};

export const TOOLBAR_COUNT_AVATAR = {
    backgroundColor: '#b8c4cf',
    borderRadius: 20,
    boxSizing: 'border-box',
    color: BLACK_FONT,
    display: 'inline-block',
    fontWeight: '500',
    fontSize: 13,
    height: 20,
    lineHeight: 1.7,
    marginLeft: 10,
    marginRight: 8,
    marginTop: 5,
    minWidth: 20,
    overflow: 'hidden',
    padding: '0 4px',
    textAlign: 'center',
};
export const DIALOG_ROOT = {
    '&>:first-child': {
    backgroundColor: 'rgba(0,0,0,0.2) !important',
       // backgroundColor: 'transparent !important',
    },
    '&>:nth-child(3)': {
        '&>:first-child': {
            boxShadow: '0 0 45px 0 rgba(0,0,0,0.8)',
            borderRadius: '15px',
            overflow: 'hidden',
        },
    },
};

export const MODAL_FOOTER = {
    display: 'flex',
    justifyContent: 'flex-start',
    margin: '0px',
    padding: '30px',
    paddingTop: '0px',
    marginTop: '30px',
};

export const MODAL_CANCEL_BUTTON = {
    padding: '8px 18px',
    minWidth: '75px',
    minHeight: 0,
    boxShadow: 'none',
    fontSize: '12px',
    color: BLACK_FONT,
    border: '1px solid #C4C4C4',
    borderRadius: '6px',
    marginRight: '10px',
    marginLeft: '0',
    textTransform: 'capitalize',
    '&>:nthChild(1)': {
        minWidth: '50px',
        fontSize: '12px',
    },
};

export const MODAL_OUTLINE_BUTTON = (({ color, borderColor, ...rest }) => rest)(MODAL_CANCEL_BUTTON);

export const MODAL_DELETE_BUTTON = {
    // this const can not be used in inline style
    backgroundColor: '#fff !important',
    color: '#ff0000 !important',
    border: '1px solid #ff0000 !important',
    '&:hover': {
        backgroundColor: '#ff0000 !important',
        color: '#fff !important',
    },
};

export const MODAL_SUBMIT_BUTTON = {
    padding: '9px 18px',
    minWidth: '75px',
    minHeight: 0,
    borderColor: '#C4C4C4',
    borderRadius: '6px',
    fontSize: '12px',
    boxShadow: 'none',
    textTransform: 'capitalize',
    margin: '0px',
    marginRight: '10px',
    '&>:nthChild(1)': {
        minWidth: '50px',
        fontSize: '12px',
    },
};

export const TITLE = {
    fontSize: 18,
    color: BLACK_FONT,
    fontWeight: 'bold',
    lineHeight: 1,
};

export const MODAL_HEADER_TITLE = {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    padding: '30px 45px 30px 30px',
    '& > h2': {
        fontSize: 18,
        color: BLACK_FONT,
        overflow: 'hidden',
        whiteSpace: 'nowrap',
        textOverflow: 'ellipsis',
        maxWidth: '100%',
    },
};

export const MODAL_EXECUTION_TITLE = {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    padding: '30px 45px 0px 18px',
    '& > h2': {
        fontSize: 18,
        color: BLACK_FONT,
        overflow: 'hidden',
        whiteSpace: 'nowrap',
        textOverflow: 'ellipsis',
        maxWidth: '100%',
    },
}

export const MODAL_HEADER_CLOSE_ICON = {
    color: '#9B9A9A',
    fontSize: 20,
    cursor: 'pointer',
};

export const MODAL_HEADER_CLOSE_ICON_CONT = {
    position: 'absolute',
    right: '16px',
    top: '16px',
};

export const MUI_ACTION_MENU_ICON = {
    color: THREE_DOT_MENU_CSS.iconColor,
    marginRight: THREE_DOT_MENU_CSS.iconMarginRight,
    marginLeft: THREE_DOT_MENU_CSS.iconMarginLeft,
    fontSize: THREE_DOT_MENU_CSS.iconFontSize,
};

export const NO_WRAP = {
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
    maxWidth: '100%',
};

export const NO_DECORATION = {
    textDecoration: 'none !important',
};
export const LINK = {
    cursor: 'pointer',
};
export const DISPLAY_BLOCK = {
    display: 'block',
};
export const DISPLAY_INLINE_BLOCK = {
    display: 'inline-block',
};
export const DISPLAY_INLINE = {
    display: 'inline',
};
export const MAX_WIDTH_100_PER = {
    maxWidth: '100%',
};
export const MAX_WIDTH_50_PER = {
    maxWidth: '50%',
};
export const WIDTH_100_PER = {
    width: '100%',
};
export const WIDTH_50_PER = {
    width: '50%',
};
export const FLEX_CENTRE = {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
};

export const TEXT_AREA = {
    padding: 10,
    resize: 'none',
    outline: 'none',
    width: '100%',
    border: '1px solid #979797',
    '&::placeholder': {
        color: '#b6b2b2',
        fontWeight: '400',
    },
};
