class ActionTypes {
    // Authentication
    static SIGNUP = 'SIGNUP';

    static SIGNUP_SUCCESS = 'SIGNUP_LANDLORD_SUCCESS';

    static SIGNUP_FAILURE = 'SIGNUP_FAILURE';

    static CONFIRM = 'CONFIRM';

    static CONFIRM_SUCCESS = 'CONFIRM_SUCCESS';

    static CONFIRM_FAILURE = 'CONFIRM_FAILURE';

    static SIGNIN = 'SIGNIN';

    static SIGNIN_SUCCESS = 'SIGNIN_SUCCESS';

    static SIGNIN_FAILURE = 'SIGNIN_FAILURE';

    static LOGOUT = 'LOGOUT';

    static LOGOUT_SUCCESS = 'LOGOUT_SUCCESS';

    static LOGOUT_SUCCESS_VOID = 'LOGOUT_SUCCESS_VOID';

    static LOGOUT_FAILURE = 'LOGOUT_FAILURE';

    static FORGOT_PASSWORD = 'FORGOT_PASSWORD';

    static FORGOT_PASSWORD_SUCCESS = 'FORGOT_PASSWORD_SUCCESS';

    static FORGOT_PASSWORD_FAILURE = 'FORGOT_PASSWORD_FAILURE';

    static RESET_PASSWORD = 'RESET_PASSWORD';

    static RESET_PASSWORD_SUCCESS = 'RESET_PASSWORD_SUCCESS';

    static RESET_PASSWORD_FAILURE = 'RESET_PASSWORD_FAILURE';

    // Projects
    static GET_ALL_PROJECTS = 'GET_ALL_PROJECTS';

    static GET_ALL_PROJECTS_SUCCESS = 'GET_ALL_PROJECTS_SUCCESS';

    static GET_ALL_PROJECTS_FAILURE = 'GET_ALL_PROJECTS_FAILURE';

    static GET_ALL_PROJECTS_BY_ID_AND_NAMES = 'GET_ALL_PROJECTS_BY_ID_AND_NAMES';

    static GET_ALL_PROJECTS_BY_ID_AND_NAMES_SUCCESS = 'GET_ALL_PROJECTS_BY_ID_AND_NAMES_SUCCESS';

    static GET_ALL_PROJECTS_BY_ID_AND_NAMES_FAILURE = 'GET_ALL_PROJECTS_BY_ID_AND_NAMES_FAILURE';

    static GET_PROJECT_BY_NAME = 'GET_PROJECT_BY_NAME';

    static GET_PROJECT_BY_NAME_SUCCESS = 'GET_PROJECT_BY_NAME_SUCCESS';

    static GET_PROJECT_BY_NAME_FAILURE = 'GET_PROJECT_BY_NAME_FAILURE';

    static SHOW_ONLY_DISABLES_PROJECTS = 'SHOW_ONLY_DISABLES_PROJECTS';

    static ATTACH_DATA = 'ATTACH_DATA';

    static ATTACH_DATA_SUCCESS = 'ATTACH_DATA_SUCCESS';

    static ATTACH_DATA_FAILURE = 'ATTACH_DATA_FAILURE';

    static ON_DELETE_PROJECTS = 'ON_DELETE_PROJECTS';

    static ON_DELETE_PROJECTS_SUCCESS = 'ON_DELETE_PROJECTS_SUCCESS';

    static ON_DELETE_PROJECTS_FAILURE = 'ON_DELETE_PROJECTS_FAILURE';

    static CREATE_PROJECT = 'CREATE_PROJECTS';

    static CREATE_PROJECT_SUCCESS = 'CREATE_PROJECTS_SUCCESS';

    static CREATE_PROJECT_FAILURE = 'CREATE_PROJECTS_FAILURE';

    static UPDATE_PROJECT = 'UPDATE_PROJECTS';

    static UPDATE_PROJECT_SUCCESS = 'UPDATE_PROJECTS_SUCCESS';

    static UPDATE_PROJECT_FAILURE = 'UPDATE_PROJECTS_FAILURE';

    static GET_PROJECTS_INFO = 'GET_PROJECTS_INFO';

    static GET_PROJECTS_INFO_SUCCESS = 'GET_PROJECTS_INFO_SUCCESS';

    static GET_PROJECTS_INFO_FAILURE = 'GET_PROJECTS_INFO_FAILURE';

    static GET_ADD_PROJECTS = 'GET_ADD_PROJECTS';

    static GET_ADD_PROJECTS_SUCCESS = 'GET_ADD_PROJECTS_SUCCESS';

    static GET_ADD_PROJECTS_FAILURE = 'GET_ADD_PROJECTS_FAILURE';

    static GET_TESTCASES = 'GET_TESTCASES';

    static GET_TESTCASES_SUCCESS = 'GET_TESTCASES_SUCCESS';

    static GET_TESTCASES_FAILURE = 'GET_TESTCASES_FAILURE';

    static SAVE_NEW_TEST_CASE = 'SAVE_NEW_TEST_CASE';

    static SAVE_NEW_TEST_CASE_SUCCESS = 'SAVE_NEW_TEST_CASE_SUCCESS';

    static SAVE_NEW_TEST_CASE_FAILURE = 'SAVE_NEW_TEST_CASE_FAILURE';

    static CREATE_TEST_CASE = 'CREATE_TEST_CASE';

    static CREATE_TEST_CASE_SUCCESS = 'CREATE_TEST_CASE_SUCCESS';

    static CREATE_TEST_CASE_FAILURE = 'CREATE_TEST_CASE_FAILURE';

    static UPDATE_LIVE_AND_RECOVER_STEPS = 'UPDATE_LIVE_AND_RECOVER_STEPS';

    static UPDATE_LIVE_AND_RECOVER_STEPS_SUCCESS = 'UPDATE_LIVE_AND_RECOVER_STEPS_SUCCESS';

    static UPDATE_LIVE_AND_RECOVER_STEPS_FAILURE = 'UPDATE_LIVE_AND_RECOVER_STEPS_FAILURE';

    static UPDATE_RECOVER_STEPS = 'UPDATE_RECOVER_STEPS';

    static UPDATE_STEPS = 'UPDATE_STEPS';

    static UPDATE_STEPS_SUCCESS = 'UPDATE_STEPS_SUCCESS';

    static UPDATE_STEPS_FAILURE = 'UPDATE_STEPS_FAILURE';

    static GET_TESTCASE_SINGLE = 'GET_TESTCASE_SINGLE';

    static GET_TESTCASE_SINGLE_SUCCESS = 'GET_TESTCASE_SINGLE_SUCCESS';

    static GET_TESTCASE_SINGLE_FAILURE = 'GET_TESTCASE_SINGLE_FAILURE';

    static GET_TESTDATA_SINGLE = 'GET_TESTDATA_SINGLE';

    static GET_TESTDATA_SINGLE_SUCCESS = 'GET_TESTDATA_SINGLE_SUCCESS';

    static GET_TESTDATA_SINGLE_FAILURE = 'GET_TESTDATA_SINGLE_FAILURE';

    static GET_TESTSTEPS = 'GET_TESTSTEPS';

    static GET_TESTSTEPS_SUCCESS = 'GET_TESTSTEPS_SUCCESS';

    static GET_TESTSTEPS_FAILURE = 'GET_TESTSTEPS_FAILURE';

    static CLEAR_TEST_CASE_SUCCESS = 'CLEAR_TEST_CASE_SUCCESS';

    static GET_TESTCASES_WITH_ACCOUNT = 'GET_TESTCASES_WITH_ACCOUNT';

    static GET_TESTCASES_WITH_ACCOUNT_SUCCESS = 'GET_TESTCASES_WITH_ACCOUNT_SUCCESS';

    static GET_TESTCASES_WITH_ACCOUNT_FAILURE = 'GET_TESTCASES_WITH_ACCOUNT_FAILURE';

    static DELETE_TESTCASE_WITH_ACCOUNT = 'DELETE_TESTCASE_WITH_ACCOUNT';

    static DELETE_TESTCASE_WITH_ACCOUNT_SUCCESS = 'DELETE_TESTCASE_WITH_ACCOUNT_SUCCESS';

    static DELETE_TESTCASE_WITH_ACCOUNT_FAILURE = 'DELETE_TESTCASE_WITH_ACCOUNT_FAILURE';

    static EDIT_TESTCASE_WITH_ACCOUNT = 'EDIT_TESTCASE_WITH_ACCOUNT';

    static EDIT_TESTCASE_WITH_ACCOUNT_SUCCESS = 'EDIT_TESTCASE_WITH_ACCOUNT_SUCCESS';

    static EDIT_TESTCASE_WITH_ACCOUNT_FAILURE = 'EDIT_TESTCASE_WITH_ACCOUNT_FAILURE';

    static CLONE_TESTCASE = 'CLONE_TESTCASE';

    static CLONE_TESTCASE_SUCCESS = 'CLONE_TESTCASE_SUCCESS';

    static CLONE_TESTCASE_WITH_ACCOUNT_SUCCESS = 'CLONE_TESTCASE_WITH_ACCOUNT_SUCCESS';

    static CLONE_TESTCASE_WITH_ACCOUNT_FAILURE = 'CLONE_TESTCASE_WITH_ACCOUNT_FAILURE';

    static CLONE_TESTCASES_WITH_ACCOUNT = 'CLONE_TESTCASES_WITH_ACCOUNT';

    static GET_TESTSUITS_WITH_ACCOUNT = 'GET_TESTSUITS_WITH_ACCOUNT';

    static GET_TESTSUITS_WITH_ACCOUNT_SUCCESS = 'GET_TESTSUITS_WITH_ACCOUNT_SUCCESS';

    static GET_TESTSUITS_WITH_ACCOUNT_FAILURE = 'GET_TESTSUITS_WITH_ACCOUNT_FAILURE';

    static GET_REPORTS = 'GET_REPORTS';

    static GET_REPORTS_SUCCESS = 'GET_REPORTS_SUCCESS';

    static GET_REPORTS_FAILURE = 'GET_REPORTS_FAILURE';

    static GET_ALERT_TESTCASES_WITH_ACCOUNT = 'GET_ALERT_TESTCASES_WITH_ACCOUNT';

    static GET_ALERT_TESTCASES_WITH_ACCOUNT_SUCCESS = 'GET_ALERT_TESTCASES_WITH_ACCOUNT_SUCCESS';

    static GET_ALERT_TESTCASES_WITH_ACCOUNT_FAILURE = 'GET_ALERT_TESTCASES_WITH_ACCOUNT_FAILURE';

    static DELETE_TESTSUITES = 'DELETE_TESTSUITES';

    static DELETE_TESTSUITES_SUCCESS = 'DELETE_TESTSUITES_SUCCESS';

    static DELETE_TESTSUITES_FAILURE = 'DELETE_TESTSUITES_FAILURE';

    static STOP_TESTSUITES = 'STOP_TESTSUITES';
    static STOP_TESTSUITES_SUCCESS = 'STOP_TESTSUITES_SUCCESS';
    static STOP_TESTSUITES_FAILURE = 'STOP_TESTSUITES_FAILURE';

    static DISABLE_TESTCASES = 'DISABLE_TESTCASES';

    static DISABLE_TESTCASES_SUCCESS = 'DISABLE_TESTCASES_SUCCESS';

    static DISABLE_TESTCASES_FAILURE = 'DISABLE_TESTCASES_FAILURE';

    static DISABLE_TESTCASES_WITH_ACCOUNT = 'DISABLE_TESTCASES_WITH_ACCOUNT';

    static DISABLE_TESTCASES_WITH_ACCOUNT_SUCCESS = 'DISABLE_TESTCASES_WITH_ACCOUNT_SUCCESS';

    static DISABLE_TESTCASES_WITH_ACCOUNT_FAILURE = 'DISABLE_TESTCASES_WITH_ACCOUNT_FAILURE';

    static DISABLE_TESTSUITES = 'DISABLE_TESTSUITES';

    static DISABLE_TESTSUITES_SUCCESS = 'DISABLE_TESTSUITES_SUCCESS';

    static DISABLE_TESTSUITES_FAILURE = 'DISABLE_TESTSUITES_FAILURE';

    static CLONE_TESTSUITE = 'CLONE_TESTSUITE';

    static CLONE_TESTSUITE_SUCCESS = 'CLONE_TESTSUITE_SUCCESS';

    static CLONE_TESTSUITE_FAILURE = 'CLONE_TESTSUITE_FAILURE';

    static GET_TESTSUITS = 'GET_TESTSUITS';

    static GET_TESTSUITS_SUCCESS = 'GET_TESTSUITS_SUCCESS';

    static GET_TESTSUITS_FAILURE = 'GET_TESTSUITS_FAILURE';

    static ADD_TESTSUITE = 'ADD_TESTSUITE';

    static ADD_TESTSUITE_SUCCESS = 'ADD_TESTSUITE_SUCCESS';

    static ADD_TESTSUITS_FAILURE = 'ADD_TESTSUITS_FAILURE';

    static EDIT_TESTSUITS = 'EDIT_TESTSUITS';

    static EDIT_TESTSUITS_SUCCESS = 'EDIT_TESTSUITS_SUCCESS';

    static EDIT_TESTSUITS_FAILURE = 'EDIT_TESTSUITS_FAILURE';

    static ASSOCIATE_TESTSUITS = 'ASSOCIATE_TESTSUITS';

    static ASSOCIATE_TESTSUITS_SUCCESS = 'ASSOCIATE_TESTSUITS_SUCCESS';

    static ASSOCIATE_TESTSUITS_FAILURE = 'ASSOCIATE_TESTSUITS_FAILURE';

    static GET_TESTDATA = 'GET_TESTDATA';

    static GET_TESTDATA_SUCCESS = 'GET_TESTDATA_SUCCESS';

    static GET_TESTDATA_FAILURE = 'GET_TESTDATA_FAILURE';

    static DELETE_TESTDATA = 'DELETE_TESTDATA';

    static DELETE_TESTDATA_SUCCESS = 'DELETE_TESTDATA_SUCCESS';

    static DELETE_TESTDATA_FAILURE = 'DELETE_TESTDATA_FAILURE';

    static DELETE_TESTCASE = 'DELETE_TESTCASE';

    static DELETE_TESTCASE_SUCCESS = 'DELETE_TESTCASE_SUCCESS';

    static DELETE_TESTCASE_FAILURE = 'DELETE_TESTCASE_FAILURE';

    static EXECUTE_SCRIPT = 'DELETE_TESTCASE';

    static EXECUTE_SCRIPT_SUCCESS = 'EXECUTE_SCRIPT_SUCCESS';

    static EXECUTE_SCRIPT_FAILURE = 'EXECUTE_SCRIPT_FAILURE';

    static REJECT_APPROVE_ALERT_TESTCASE = 'REJECT_APPROVE_ALERT_TESTCASE';

    static REJECT_APPROVE_ALERT_TESTCASE_SUCCESS = 'REJECT_APPROVE_ALERT_TESTCASE_SUCCESS';

    static REJECT_APPROVE_ALERT_TESTCASE_FAILURE = 'REJECT_APPROVE_ALERT_TESTCASE_FAILURE';

    static DELETE_TESTSCRIPT = 'DELETE_TESTSCRIPT';

    static DELETE_TESTSCRIPT_SUCCESS = 'DELETE_TESTSCRIPT_SUCCESS';

    static DELETE_TESTSCRIPT_FAILURE = 'DELETE_TESTSCRIPT_FAILURE';

    static GET_ACTIONABLE_ELEMENTS = 'GET_ACTIONABLE_ELEMENTS';

    static GET_ACTIONABLE_ELEMENTS_SUCCESS = 'GET_ACTIONABLE_ELEMENTS_SUCCESS';

    static GET_ACTIONABLE_ELEMENTS_FAILURE = 'GET_ACTIONABLE_ELEMENTS_FAILURE';

    static GET_EXECUTE_TASK = 'GET_EXECUTE_TASK';

    static GET_EXECUTE_TASK_SUCCESS = 'GET_EXECUTE_TASK_SUCCESS';

    static GET_EXECUTE_TASK_FAILURE = 'GET_EXECUTE_TASK_FAILURE';

    static GET_EXECUTE_TEST_SCRIPT = 'GET_EXECUTE_TEST_SCRIPT';

    static GET_EXECUTE_TEST_SCRIPT_SUCCESS = 'GET_EXECUTE_TEST_SCRIPT_SUCCESS';

    static GET_EXECUTE_TEST_SCRIPT_FAILURE = 'GET_EXECUTE_TEST_SCRIPT_FAILURE';

    static CREATE_EXECUTE_TEST_SUITE = 'CREATE_EXECUTE_TEST_SUITE';

    static CREATE_EXECUTE_TEST_SUITE_SUCCESS = 'CREATE_EXECUTE_TEST_SUITE_SUCCESS';

    static CREATE_EXECUTE_TEST_SUITE_FAILURE = 'CREATE_EXECUTE_TEST_SUITE_FAILURE';

    static GET_TESTEXECUTESCRIPT = 'GET_TESTEXECUTESCRIPT';

    static GET_GENERATE_TEST_SCRIPT = 'GET_GENERATE_TEST_SCRIPT';

    static GET_GENERATE_TEST_SCRIPT_SUCCESS = 'GET_GENERATE_TEST_SCRIPT_SUCCESS';

    static GET_GENERATE_TEST_SCRIPT_FAILURE = 'GET_GENERATE_TEST_SCRIPT_FAILURE';

    static UPDATE_EXECUTION_TASKS_STATUS = 'UPDATE_EXECUTION_TASKS_STATUS';

    static UPDATE_EXECUTION_TASKS_STATUS_PAGE = 'UPDATE_EXECUTION_TASKS_STATUS_PAGE';

    static GET_USER_VARIABLES = 'GET_USER_VARIABLES';

    static GET_USER_VARIABLES_SUCCESS = 'GET_USER_VARIABLES_SUCCESS';

    static GET_USER_VARIABLES_FAILURE = 'GET_USER_VARIABLES_FAILURE';

    static SAVE_USER_VARIABLES = 'SAVE_USER_VARIABLES';

    static SAVE_USER_VARIABLES_SUCCESS = 'SAVE_USER_VARIABLES_SUCCESS';

    static SAVE_USER_VARIABLES_FAILURE = 'SAVE_USER_VARIABLES_FAILURE';

    static DELETE_USER_VARIABLES = 'DELETE_USER_VARIABLES';

    static DELETE_USER_VARIABLES_SUCCESS = 'DELETE_USER_VARIABLES_SUCCESS';

    static DELETE_USER_VARIABLES_FAILURE = 'DELETE_USER_VARIABLES_FAILURE';

    static DELETE_ALL_USER_VARIABLES = 'DELETE_ALL_USER_VARIABLES';

    static DELETE_ALL_USER_VARIABLES_SUCCESS = 'DELETE_ALL_USER_VARIABLES_SUCCESS';

    static DELETE_ALL_USER_VARIABLES_FAILURE = 'DELETE_ALL_USER_VARIABLES_FAILURE';

    static DELETE_USER_VARIABLES_CLEAR = 'DELETE_USER_VARIABLES_CLEAR';

    static TASK_PANEL_TOGGLE = 'TASK_PANEL_TOGGLE';

    static TASK_PANEL_SHOW_HIDE = 'TASK_PANEL_SHOW_HIDE';

    static UPDATE_QUERY = 'UPDATE_QUERY';

    static UPDATE_PROJECT_ID = 'UPDATE_PROJECT_ID';

    static TOGGLE_DISABLE_QUERY = 'TOGGLE_DISABLE_QUERY';

    static PROJECT_IS_CHANGED = 'PROJECT_IS_CHANGED';

    // favourite
    static SET_ADD_FAVOURITE = 'SET_ADD_FAVOURITE';

    static SET_ADD_FAVOURITE_SUCCESS = 'SET_ADD_FAVOURITE_SUCCESS';

    static SET_ADD_FAVOURITE_FAILURE = 'SET_ADD_FAVOURITE_FAILURE';

    static SET_REMOVE_FAVOURITE = 'SET_REMOVE_FAVOURITE';

    static SET_REMOVE_FAVOURITE_SUCCESS = 'SET_REMOVE_FAVOURITE_SUCCESS';

    static SET_REMOVE_FAVOURITE_FAILURE = 'SET_REMOVE_FAVOURITE_FAILURE';

    static GET_ALL_FAVOURITE_PROJECTS = 'GET_ALL_FAVOURITE_PROJECTS';

    static GET_ALL_FAVOURITE_PROJECTS_SUCCESS = 'GET_ALL_FAVOURITE_PROJECTS_SUCCESS';

    static GET_ALL_FAVOURITE_PROJECTS_FAILURE = 'GET_ALL_FAVOURITE_PROJECTS_FAILURE';

    // error
    static SHOW_ERROR = 'SHOW_ERROR';

    static CLEAR_ERROR = 'CLEAR_ERROR';

    static CLEAR_SUCCESS = 'CLEAR_SUCCESS';

    static SET_PROJECT_ERROR = 'SET_PROJECT_ERROR';

    static SET_AUTH_ERROR = 'SET_AUTH_ERROR';

    static UPADTE_RELOAD_STATUS = 'UPADTE_RELOAD_STATUS';

    // Preview
    static PREVIEW_TEST_CASE = 'PREVIEW_TEST_CASE';

    static PREVIEW_TEST_CASE_SUCCESS = 'PREVIEW_TEST_CASE_SUCCESS';

    static PREVIEW_TEST_CASE_FAILURE = 'PREVIEW_TEST_CASE_FAILURE';

    static PREVIEW_TEST_DATA = 'PREVIEW_TEST_DATA';

    static PREVIEW_TEST_DATA_SUCCESS = 'PREVIEW_TEST_DATA_SUCCESS';

    static PREVIEW_TEST_DATA_CLEAR = 'PREVIEW_TEST_DATA_CLEAR';

    static PREVIEW_TEST_DATA_FAILURE = 'PREVIEW_TEST_DATA_FAILURE';

    static PREVIEW_TEST_SCRIPT = 'PREVIEW_TEST_SCRIPT';

    static PREVIEW_TEST_SCRIPT_SUCCESS = 'PREVIEW_TEST_SCRIPT_SUCCESS';

    static PREVIEW_TEST_SCRIPT_FAILURE = 'PREVIEW_TEST_SCRIPT_FAILURE';

    static GET_EXECUTION_VIDEO_URL = 'GET_EXECUTION_VIDEO_URL';

    static GET_EXECUTION_VIDEO_URL_SUCCESS = 'GET_EXECUTION_VIDEO_URL_SUCCESS';

    static GET_EXECUTION_VIDEO_URL_FAIL = 'GET_EXECUTION_VIDEO_URL_FAIL';

    static RECORD_TEST_CASE = 'RECORD_TEST_CASE';

    static RECORD_TEST_CASE_SUCCESS = 'RECORD_TEST_CASE_SUCCESS';

    static RECORD_TEST_CASE_FAILURE = 'RECORD_TEST_CASE_FAILURE';

    static YOUR_BROSWER_NOT_SPORTED = 'YOUR_BROSWER_NOT_SPORTED';

    static PLUGIN_NOT_FOUND = 'PLUGIN_NOT_FOUND';

    static TEMP_CHECKED_CASE_IDS = 'TEMP_CHECKED_CASE_IDS';

    // USERS
    static GET_USERS = 'GET_USERS';

    static GET_USERS_SUCCESS = 'GET_USERS_SUCCESS';

    static GET_USERS_FAILURE = 'GET_USERS_FAILURE';

    static GET_USER = 'GET_USER';

    static GET_USER_SUCCESS = 'GET_USER_SUCCESS';

    static GET_USER_FAILURE = 'GET_USER_FAILURE';

    static ADMIN_RESET_PASSWORD_EMAIL = 'ADMIN_RESET_PASSWORD_EMAIL';

    static ADMIN_RESET_PASSWORD_EMAIL_SUCCESS = 'ADMIN_RESET_PASSWORD_EMAIL_SUCCESS';

    static ADMIN_RESET_PASSWORD_EMAIL_FAILURE = 'ADMIN_RESET_PASSWORD_EMAIL_FAILURE';

    static CREATE_USER = 'CREATE_USER';

    static CREATE_USER_SUCCESS = 'CREATE_USER_SUCCESS';

    static CREATE_USER_FAILURE = 'CREATE_USER_FAILURE';

    static UPDATE_USER = 'UPDATE_USER';

    static UPDATE_USER_SUCCESS = 'UPDATE_USER_SUCCESS';

    static UPDATE_USER_FAILURE = 'UPDATE_USER_FAILURE';

    static DELETE_USER = 'DELETE_USER';

    static DELETE_USER_SUCCESS = 'DELETE_USER_SUCCESS';

    static DELETE_USER_FAILURE = 'DELETE_USER_FAILURE';

    static CHANGE_USER_STATUS = 'CHANGE_USER_STATUS';

    static CHANGE_USER_STATUS_SUCCESS = 'CHANGE_USER_STATUS_SUCCESS';

    static CHANGE_USER_STATUS_FAILURE = 'CHANGE_USER_STATUS_FAILURE';

    static HANDLE_TABLE_COL = 'HANDLE_TABLE_COL';

    static GET_USERS_BY_ID = 'GET_USERS_BY_ID';

    static GET_USERS_BY_ID_SUCCESS = 'GET_USERS_BY_ID_SUCCESS';

    static GET_USERS_BY_ID_FAILURE = 'GET_USERS_BY_ID_FAILURE';

    static GET_USERS_BY_EMAIL = 'GET_USERS_BY_EMAIL';

    static GET_USERS_BY_EMAIL_SUCCESS = 'GET_USERS_BY_EMAIL_SUCCESS';

    static GET_USERS_BY_EMAIL_FAILURE = 'GET_USERS_BY_EMAIL_FAILURE';

    // Accounts
    static CREATE_ACCOUNT = 'CREATE_ACCOUNT';

    static CREATE_ACCOUNT_SUCCESS = 'CREATE_ACCOUNT_SUCCESS';

    static CREATE_ACCOUNT_FAILURE = 'CREATE_ACCOUNT_FAILURE';

    static GET_ACCOUNTS = 'GET_ACCOUNTS';

    static GET_ACCOUNTS_SUCCESS = 'GET_ACCOUNTS_SUCCESS';

    static GET_ACCOUNTS_FAILURE = 'GET_ACCOUNTS_FAILURE';

    static GET_MARKET = 'GET_MARKET';

    static GET_MARKET_SUCCESS = 'GET_MARKET_SUCCESS';

    static GET_MARKET_FAILURE = 'GET_MARKET_FAILURE';

    static GET_ACCOUNT_DETAIL = 'GET_ACCOUNT_DETAIL';

    static GET_ACCOUNT_DETAIL_SUCCESS = 'GET_ACCOUNT_DETAIL_SUCCESS';

    static GET_ACCOUNT_DETAIL_FAILURE = 'GET_ACCOUNT_DETAIL_FAILURE';

    static CLEAR_SINGLE_ACCOUNT = 'CLEAR_SINGLE_ACCOUNT';

    static UPDATE_ACCOUNT_DETAIL = 'UPDATE_ACCOUNT_DETAIL';

    static UPDATE_ACCOUNT_DETAIL_SUCCESS = 'UPDATE_ACCOUNT_DETAIL_SUCCESS';

    static UPDATE_ACCOUNT_DETAIL_FAILURE = 'UPDATE_ACCOUNT_DETAIL_FAILURE';

    static UPLOAD_YAML = 'UPLOAD_YAML';

    static UPLOAD_YAML_SUCCESS = 'UPLOAD_YAML_SUCCESS';

    static UPLOAD_YAML_FAILURE = 'UPLOAD_YAML_FAILURE';

    static DELETE_BULK_OBJECTS = 'DELETE_BULK_OBJECTS';

    static DELETE_BULK_OBJECTS_SUCCESS = 'DELETE_BULK_OBJECTS_SUCCESS';

    static DELETE_BULK_OBJECTS_FAILURE = 'DELETE_BULK_OBJECTS_FAILURE';

    static DELETE_BULK_VIDEOS = 'DELETE_BULK_VIDEOS';

    static DELETE_BULK_VIDEOS_SUCCESS = 'DELETE_BULK_VIDEOS_SUCCESS';

    static DELETE_BULK_VIDEOS_FAILURE = 'DELETE_BULK_VIDEOS_FAILURE';

    // Ulpload Progress
    static UPLOAD_CASE_FILE = 'UPLOAD_CASE_FILE';

    static UPLOAD_CASE_FILE_FAILURE = 'UPLOAD_CASE_FILE_FAILURE';

    static UPLOAD_CASE_FILE_SUCCESS = 'UPLOAD_CASE_FILE_SUCCESS';

    static UPLOAD_DATA_FILE = 'UPLOAD_DATA_FILE';

    static UPLOAD_DATA_FILE_FAILURE = 'UPLOAD_DATA_FILE_FAILURE';

    static UPLOAD_DATA_FILE_SUCCESS = 'UPLOAD_DATA_FILE_SUCCESS';

    static UPDATE_TEST_CASE_STEPS = 'UPDATE_TEST_CASE_STEPS';

    static UPDATE_TEST_CASE_STEPS_STATUS = 'UPDATE_TEST_CASE_STEPS_STATUS';

    static UPADTE_WS_STATUS = 'UPADTE_WS_STATUS';

    static DELETE_WS_STATUS = 'DELETE_WS_STATUS';

    static RENAME_PROJECT_NAME = 'RENAME_PROJECT_NAME';

    static RENAME_PROJECT_NAME_SUCCESS = 'RENAME_PROJECT_NAME_SUCCESS';

    static RENAME_PROJECT_NAME_FAILURE = 'RENAME_PROJECT_NAME_FAILURE';

    static UPDATE_TESTCASE_NAME = 'UPDATE_TESTCASE_NAME';

    static UPDATE_TESTCASE_NAME_SUCCESS = 'UPDATE_TESTCASE_NAME_SUCCESS';

    static UPDATE_TESTCASE_NAME_IN_ACCOUNT_SUCCESS = 'UPDATE_TESTCASE_NAME_IN_ACCOUNT_SUCCESS';

    static UPDATE_TESTCASE_NAME_FAILURE = 'UPDATE_TESTCASE_NAME_FAILURE';

    // GROUPS
    static GET_GROUPS = 'GET_GROUPS';

    static GET_GROUPS_SUCCESS = 'GET_GROUPS_SUCCESS';

    static GET_GROUPS_FAILURE = 'GET_GROUPS_FAILURE';

    static EXPAND_SIDEBAR_ITEM = 'EXPAND_SIDEBAR_ITEM';

    static CREATE_GROUP_SUCCESS = 'CREATE_GROUP_SUCCESS';

    static CREATE_GROUP_FAILURE = 'CREATE_GROUP_FAILURE';

    static UPDATE_GROUP_SUCCESS = 'UPDATE_GROUP_SUCCESS';

    static UPDATE_GROUP_FAILURE = 'UPDATE_GROUP_FAILURE';

    static DELETE_GROUPS_SUCCESS = 'DELETE_GROUPS_SUCCESS';

    static DELETE_GROUPS_FAILURE = 'DELETE_GROUPS_FAILURE';

    static ENABLE_DISABLE_GROUPS_SUCCESS = 'ENABLE_DISABLE_GROUPS_SUCCESS';

    static ENABLE_DISABLE_GROUPS_FAILURE = 'ENABLE_DISABLE_GROUPS_FAILURE';

    // for expand/collapse sidebar Items
    static EXPAND_ADMIN_SIDEBAR = 'EXPAND_ADMIN_SIDEBAR';

    static EXPAND_PLAN_SIDEBAR = 'EXPAND_PLAN_SIDEBAR';

    static EXPAND_EXECUTIONS_SIDEBAR = 'EXPAND_EXECUTIONS_SIDEBAR';

    static EXPAND_ANALYZE_SIDEBAR = 'EXPAND_ANALYZE_SIDEBAR';

    static COLLAPE_ALL_ITEMS_SIDEBAR = 'COLLAPE_ALL_ITEMS_SIDEBAR';

    // Active Inactive headers from grouplist table
    static MANAGE_GROUP_HEADERS = 'MANAGE_GROUP_HEADERS';

    static EXECUTE_TESTSUITE = 'EXECUTE_TESTSUITE';

    static EXECUTE_TESTSUITE_SUCCESS = 'EXECUTE_TESTSUITE_SUCCESS';

    static EXECUTE_TESTSUITE_FAILURE = 'EXECUTE_TESTSUITE_FAILURE';

    static HANDLE_UPDATE_TESTCASE_IN_TESTSUITE_DIRECTION = 'HANDLE_UPDATE_TESTCASE_IN_TESTSUITE_DIRECTION';

    static HANDLE_UPDATE_TESTCASE_IN_TESTSUITE_DIRECTION_PROJECT = 'HANDLE_UPDATE_TESTCASE_IN_TESTSUITE_DIRECTION_PROJECT';

    static UPDATE_TEST_SUITE_DATA_PROP_PROJECT = 'UPDATE_TEST_SUITE_DATA_PROP_PROJECT';

    static UPDATE_TEST_SUITE_DATA_PROP_PLAN = 'UPDATE_TEST_SUITE_DATA_PROP_PLAN';

    static SAVE_TEST_SUITE_CASES_ORDER = 'SAVE_TEST_SUITE_CASES_ORDER';

    static SAVE_TEST_SUITE_CASES_ORDER_SUCCESS = 'SAVE_TEST_SUITE_CASES_ORDER_SUCCESS';

    static SAVE_TEST_SUITE_CASES_ORDER_FAILURE = 'SAVE_TEST_SUITE_CASES_ORDER_FAILURE';

    // associate user to group
    static ASSOCIATE_USER_TO_GROUP_SUCCESS = 'ASSOCIATE_USER_TO_GROUP_SUCCESS';

    static ASSOCIATE_USER_TO_GROUP_FAILURE = 'ASSOCIATE_USER_TO_GROUP_FAILURE';

    static DISSOCIATE_USER_TO_GROUP_SUCCESS = 'DISSOCIATE_USER_TO_GROUP_SUCCESS';

    static DISSOCIATE_USER_TO_GROUP_FAILURE = 'DISSOCIATE_USER_TO_GROUP_FAILURE';

    // notification side

    static DELETE_NOTIFICATIONS = 'DELETE_NOTIFICATIONS';

    static NON_DELETE_NOTIFICATIONS = 'NON_DELETE_NOTIFICATIONS';

    static MARK_DELETED_NOTIFICATIONS = 'MARK_DELETED_NOTIFICATIONS';

    static MARK_READ_NOTIFICATIONS = 'MARK_READ_NOTIFICATIONS';

    static MARK_READ_NOTIFICATIONS_DELETED = 'MARK_READ_NOTIFICATIONS_DELETED';

    static GET_NOTIFICATIONS_FAILED = 'GET_NOTIFICATIONS_FAILED';

    static CLEAR_NOTIFICATIONS_SUCCESS = 'CLEAR_NOTIFICATIONS_SUCCESS';

    static CLEAR_NOTIFICATIONS_FAILED = 'CLEAR_NOTIFICATIONS_FAILED';

    static APPROVE_DATA = 'APPROVE_DATA';

    static APPROVE_DATA_SUCCESS = 'APPROVE_DATA_SUCCESS';

    static APPROVE_DATA_FAILED = 'APPROVE_DATA_FAILED';

    // Dashboard
    static GET_ALERTS = 'GET_ALERTS';

    static GET_ALERTS_SUCCESS = 'GET_ALERTS_SUCCESS';

    static GET_ALERTS_FAILED = 'GET_ALERTS_FAILED';

    static GET_ALERT_DETAIL = 'GET_ALERT_DETAIL';
    static GET_ALERT_DETAIL_SUCCESS = 'GET_ALERT_DETAIL_SUCCESS';
    static GET_ALERT_DETAIL_FAILED = 'GET_ALERT_DETAIL_FAILED';

    static CLEAR_ALERT_DETAIL = 'CLEAR_ALERT_DETAIL';

    static REMOVE_ALERTS = 'REMOVE_ALERTS';

    static REMOVE_ALERTS_SUCCESS = 'REMOVE_ALERTS_SUCCESS';

    static REMOVE_ALERTS_FAILED = 'REMOVE_ALERTS_FAILED';

    static GET_DASHBOARD_INFO = 'GET_DASHBOARD_INFO';

    static GET_DASHBOARD_INFO_SUCCESS = 'GET_DASHBOARD_INFO_SUCCESS';

    static GET_DASHBOARD_INFO_FAILED = 'GET_DASHBOARD_INFO_FAILED';

    static GET_PROJECT_DETAILS = 'GET_PROJECT_DETAILS';

    static GET_PROJECT_DETAILS_FAILURE = 'GET_PROJECT_DETAILS_FAILURE';

    static GET_PROJECT_DETAILS_SUCCESS = 'GET_PROJECT_DETAILS_SUCCESS';

    static CLEAR_SELECTED_CASE_DATA_PROJECT = 'CLEAR_SELECTED_CASE_DATA_PROJECT';

    static SET_SELECTED_TASK = 'SET_SELECTED_TASK';

    static CALL_RETRY_FUNC = 'CALL_RETRY_FUNC';

    static SET_WS_STATUS = 'SET_WS_STATUS';

    // Changed Apis
    static GET_CHANGED_APIS = 'GET_CHANGED_APIS';

    static GET_CHANGED_APIS_SUCCESS = 'GET_CHANGED_APIS_SUCCESS';

    static GET_CHANGED_APIS_FAILURE = 'GET_CHANGED_APIS_FAILURE';

    // Smart Run
    static SMART_RUN = 'SMART_RUN';

    static SMART_RUN_SUCCESS = 'SMART_RUN_SUCCESS';

    static SMART_RUN_FAILURE = 'SMART_RUN_FAILURE';

    // Copy URL
    static COPIED_SUCCESS = 'COPIED_SUCCESS';

    static COPYING_FAILED = 'COPYING_FAILED';

    // Modal
    static TOGGLE_MODAL = 'TOGGLE_MODAL';

    static TOGGLE_SNACKBAR = 'TOGGLE_SNACKBAR';

    static CLEAR_SNACKBAR = 'CLEAR_SNACKBAR';

    static RUNUPDATESCRIPTJSON = 'RUNUPDATESCRIPTJSON';

    static SET_NEW_PROJECT_ID = 'SET_NEW_PROJECT_ID';

    static SET_TESTCASE_ID = 'SET_TESTCASE_ID';

    // Test Case Name in TestStep screen
    static CHANGE_TESTCASE_NAME_FLAG = 'CHANGE_TESTCASE_NAME_FLAG';

    // SHIFT TYPE
    static CALL_ORIGINAL_STEP = 'CALL_ORIGINAL_STEP';

    // Flows
    static CREATE_FLOW_DATA = 'CREATE_FLOW_DATA';

    static CREATE_FLOW_DATA_SUCCESS = 'CREATE_FLOW_DATA_SUCCESS';

    static CREATE_FLOW_DATA_FAILURE = 'CREATE_FLOW_DATA_FAILURE';

    static CLONE_FLOW = 'CLONE_FLOW';

    static CLONE_FLOW_SUCCESS = 'CLONE_FLOW_SUCCESS';

    static CLONE_FLOW_FAILURE = 'CLONE_FLOW_FAILURE';

    static UPDATE_FLOW_DATA = 'UPDATE_FLOW_DATA';

    static UPDATE_FLOW_DATA_SUCCESS = 'UPDATE_FLOW_DATA_SUCCESS';

    static UPDATE_FLOW_DATA_FAILURE = 'UPDATE_FLOW_DATA_FAILURE';

    static DELETE_FLOW = 'DELETE_FLOW';

    static DELETE_FLOW_SUCCESS = 'DELETE_FLOW_SUCCESS';

    static DELETE_FLOW_FAILURE = 'DELETE_FLOW_FAILURE';

    static GET_FLOW_DATA = 'GET_FLOW_DATA';

    static GET_FLOW_DATA_SUCCESS = 'GET_FLOW_DATA_SUCCESS';

    static GET_FLOW_DATA_FAILURE = 'GET_FLOW_DATA_FAILURE';

    static CLEAR_FLOWS = 'CLEAR_FLOWS';

    // SIDE BAR
    static SIDE_BAR_TOGGLE = 'SIDE_BAR_TOGGLE';

    static SEND_EMAIL_LOGS = 'SEND_EMAIL_LOGS';

    static SEND_EMAIL_LOGS_FAILED = 'SEND_EMAIL_LOGS_FAILED';

    static SEND_EMAIL_LOGS_SUCCESS = 'SEND_EMAIL_LOGS_SUCCESS';

    static SEND_EMAIL_LOGS_SUCCESS_DOWNLOAD = 'SEND_EMAIL_LOGS_SUCCESS_DOWNLOAD';

    static CHANGE_PASSWORD = 'CHANGE_PASSWORD';

    static CHANGE_PASSWORD_SUCCESS = 'CHANGE_PASSWORD_SUCCESS';

    static CHANGE_PASSWORD_FAILURE = 'CHANGE_PASSWORD_FAILURE';

    static SHOW_TOAST = 'SHOW_TOAST';

    // SIDEBAR COUNT

    static SIDEBAR_COUNT_SUCCESS = 'SIDEBAR_COUNT_SUCCESS';

    static SIDEBAR_COUNT_FAILURE = 'SIDEBAR_COUNT_FAILURE';

    static DECREASE_SIDEBAR_COUNT = 'DECREASE_SIDEBAR_COUNT';

    static INCREASE_SIDEBAR_COUNT = 'INCREASE_SIDEBAR_COUNT';

    // SESSION IDS
    static SAVE_SESSION_ID = 'SAVE_SESSION_ID';

    static REMOVE_MSGTYPE2_DATA = 'REMOVE_MSGTYPE2_DATA';

    // FAILED STEPS
    static SAVE_FAILED_STEP = 'SAVE_FAILED_STEP';

    static RESET_FAILED_STEPS = 'RESET_FAILED_STEPS';

    // DEBUG STEPS
    static SAVE_DEBUG_STEP = 'SAVE_DEBUG_STEP';

    static RESET_DEBUG_STEPS = 'RESET_DEBUG_STEPS';

    static UPDATE_DEBUG_POINT_LIST = 'UPDATE_DEBUG_POINT_LIST';

    // SCHEDULE
    static GET_ALL_SCHEDULES = 'GET_ALL_SCHEDULES';

    static GET_ALL_SCHEDULES_SUCCESS = 'GET_ALL_SCHEDULES_SUCCESS';

    static GET_ALL_SCHEDULES_FAILURE = 'GET_ALL_SCHEDULES_FAILURE';

    static DELETE_SCHEDULES = 'DELETE_SCHEDULES';

    static DELETE_SCHEDULES_SUCCESS = 'DELETE_SCHEDULES_SUCCESS';

    static DELETE_SCHEDULES_FAILURE = 'DELETE_SCHEDULES_FAILURE';

    static POST_USER_PROFILE_DATA_SUCCESS = 'POST_USER_PROFILE_DATA_SUCCESS';

    static POST_USER_PROFILE_DATE_FAILURE = 'POST_USER_PROFILE_DATE_FAILURE';

    static MSGTYPE_15_FLAG_TOOGLE = 'MSGTYPE_15_FLAG_TOOGLE';

    static MSGTYPE_18_FLAG_TOOGLE = 'MSGTYPE_18_FLAG_TOOGLE';

    // Test Cases By Job Id

    static GET_TESTCASES_BY_JOBID = 'GET_TESTCASES_BY_JOBID'
    static GET_TESTCASES_BY_JOBID_SUCCESS = 'GET_TESTCASES_BY_JOBID_SUCCESS';
    static GET_TESTCASES_BY_JOBID_FAILURE = 'GET_TESTCASES_BY_JOBID_FAILURE';
    


    // TAGS
    static CREATE_TAG = 'CREATE_TAG';

    static CREATE_TAG_SUCCESS = 'CREATE_TAG_SUCCESS';

    static CREATE_TAG_FAILURE = 'CREATE_TAG_FAILURE';

    static GET_TAGS = 'GET_TAGS';

    static GET_TAGS_SUCCESS = 'GET_TAGS_SUCCESS';

    static GET_TAGS_FAILURE = 'GET_TAGS_FAILURE';

    static EXECUTE_FAILED_TESTCASES = 'EXECUTE_FAILED_TESTCASES';

    static EXECUTE_FAILED_TESTCASES_SUCCESS = 'EXECUTE_FAILED_TESTCASES_SUCCESS';

    static EXECUTE_FAILED_TESTCASES_FAILURE = 'EXECUTE_FAILED_TESTCASES_FAILURE';

    static UPDATE_TAG = 'UPDATE_TAG';

    static UPDATE_TAG_SUCCESS = 'UPDATE_TAG_SUCCESS';

    static UPDATE_TAG_FAILURE = 'UPDATE_TAG_FAILURE';

    static ADD_REMOVE_CASES_TAG = 'ADD_REMOVE_CASES_TAG';

    static TAGGED_TESTCASE = 'TAGGED_TESTCASE';

    static TAGGED_TESTCASE_SUCCESS = 'TAGGED_TESTCASE_SUCCESS';

    static TAGGED_TESTCASE_FAILURE = 'TAGGED_TESTCASE_FAILURE';

    static UN_TAGGED_TESTCASE = 'UN_TAGGED_TESTCASE';

    static UN_TAGGED_TESTCASE_SUCCESS = 'UN_TAGGED_TESTCASE_SUCCESS';

    static UN_TAGGED_TESTCASE_FAILURE = 'UN_TAGGED_TESTCASE_FAILURE';

    // EXECUTE
    static EXECUTE_TAG = 'EXECUTE_TAG';

    static EXECUTE_TAG_SUCCESS = 'EXECUTE_TAG_SUCCESS';

    static EXECUTE_TAG_FAILURE = 'EXECUTE_TAG_FAILURE';

    // ------------------
    static EMPTY_VALID_URL_VALIDATION = 'EMPTY_VALID_URL_VALIDATION';

    static UPDATE_USER_PERMISSIONS_SUCCESS = 'UPDATE_USER_PERMISSIONS_SUCCESS';

    static UPDATE_USER_PERMISSIONS_FAILED = 'UPDATE_USER_PERMISSIONS_FAILED';

    // TUNNEL
    static TOGGLE_TUNNEL = 'TOGGLE_TUNNEL';

    static TOGGLE_TUNNEL_SUCCESS = 'TOGGLE_TUNNEL_SUCCESS';

    static TOGGLE_TUNNEL_FAILURE = 'TOGGLE_TUNNEL_FAILURE';

    static CLONE_ALL_VARIABLES = 'CLONE_ALL_VARIABLES';

    static CLONE_ALL_VARIABLES_SUCCESS = 'CLONE_ALL_VARIABLES_SUCCESS';

    static CLONE_ALL_VARIABLES_FAILED = 'CLONE_ALL_VARIABLES_FAILED';

    static SEND_GENERATION_DATA_SUCCESS = 'SEND_GENERATION_DATA_SUCCESS';

    static SEND_GENERATION_DATA_FAILED = 'SEND_GENERATION_DATA_FAILED';

    static CLONE_ALL_VARIABLES = 'CLONE_ALL_VARIABLES';

    static CLONE_ALL_VARIABLES_SUCCESS = 'CLONE_ALL_VARIABLES_SUCCESS';

    static CLONE_ALL_VARIABLES_FAILED = 'CLONE_ALL_VARIABLES_FAILED';

    // SUITE REPORT
    static GET_SUITE_REPORT_EXECUTIONS = 'GET_SUITE_REPORT_EXECUTIONS';

    static GET_SUITE_REPORT_EXECUTIONS_SUCCESS = 'GET_SUITE_REPORT_EXECUTIONS_SUCCESS';

    static GET_SUITE_REPORT_EXECUTIONS_FAILURE = 'GET_SUITE_REPORT_EXECUTIONS_FAILURE';

    static GET_SINGLE_TESTSUITE = 'GET_SINGLE_TESTSUITE';

    static GET_SINGLE_TESTSUITE_SUCCESS = 'GET_SINGLE_TESTSUITE_SUCCESS';

    static GET_SINGLE_TESTSUITE_FAILURE = 'GET_SINGLE_TESTSUITE_FAILURE';

    static UPDATE_DATA = 'UPDATE_DATA';

    static UPDATE_DATA_SUCCESS = 'UPDATE_DATA_SUCCESS';

    static UPDATE_DATA_FAILURE = 'UPDATE_DATA_FAILURE';

    // msgtype = 8
    static REMOVE_MESSAGE_TYPE_8_RECEIVED = 'REMOVE_MESSAGE_TYPE_8_RECEIVED';

    static CLONE_VARIABLE = 'CLONE_VARIABLE';

    static CLONE_VARIABLE_SUCCESS = 'CLONE_VARIABLE_SUCCESS';

    static CLONE_VARIABLE_FAILED = 'CLONE_VARIABLE_FAILED';

    static ADD_JOB = 'ADD_JOB';

    static ADD_JOB_SCCUESS = 'ADD_JOB_SCCUESS';

    static ADD_JOB_FAILED = 'ADD_JOB_FAILED';

    static UPDATE_VARIABLE = 'UPDATE_VARIABLE';

    static UPDATE_VARIABLE_SUCCESS = 'UPDATE_VARIABLE_SUCCESS';

    static UPDATE_VARIABLE_FAILURE = 'UPDATE_VARIABLE_FAILURE';

    static GET_JOB = 'GET_JOB';

    static GET_JOB_SUCCESS = 'GET_JOB_SUCCESS';

    static GET_JOB_FAILED = 'GET_JOB_FAILED';

    static UPDATE_JOB = 'UPDATE_JOB';

    static UPDATE_JOB_SCCUESS = 'UPDATE_JOB_SCCUESS';

    static UPDATE_JOB_FAILED = 'UPDATE_JOB_FAILED';

    static EXECUTE_SCHEDULE = 'EXECUTE_SCHEDULE';

    static EXECUTE_SCHEDULE_SUCCESS = 'EXECUTE_SCHEDULE_SUCCESS';

    static EXECUTE_SCHEDULE_FAILED = 'EXECUTE_SCHEDULE_FAILED';

    static GET_SUITE_SCHEDULES = 'GET_SUITE_SCHEDULES';

    static GET_SUITE_SCHEDULES_SUCCESS = 'GET_SUITE_SCHEDULES_SUCCESS';

    static GET_SUITE_SCHEDULES_FAILURE = 'GET_SUITE_SCHEDULES_FAILURE';

    static CLEAR_WS_DATA = 'CLEAR_WS_DATA';

    static CLEAR_CLONE_ERR = 'CLEAR_CLONE_ERR';

    static GET_ACCOUNTS_PREFENCES = 'GET_ACCOUNTS_PREFENCES';

    static GET_ACCOUNTS_PREFENCES_SUCCESS = 'GET_ACCOUNTS_PREFENCES_SUCCESS';

    static GET_ACCOUNTS_PREFENCES_FAILED = 'GET_ACCOUNTS_PREFENCES_FAILED';

    static GET_ACCOUNT_PERMISSION = 'GET_ACCOUNT_PERMISSION';

    static GET_ACCOUNT_PERMISSION_SUCCESS = 'GET_ACCOUNT_PERMISSION_SUCCESS';

    static GET_ACCOUNT_PERMISSION_FAILED = 'GET_ACCOUNT_PERMISSION_FAILED';

    static UPDATE_ACCOUNT_PREFENCES = 'UPDATE_ACCOUNT_PREFENCES';

    static UPDATE_ACCOUNT_PREFENCES_SUCCESS = 'UPDATE_ACCOUNT_PREFENCES_SUCCESS';

    static UPDATE_ACCOUNT_PREFENCES_FAILED = 'UPDATE_ACCOUNT_PREFENCES_FAILED';

    static UPDATE_DASHBOARDS = 'UPDATE_DASHBOARDS';

    static UPDATE_DASHBOARDS_SUCCESS = 'UPDATE_DASHBOARDS_SUCCESS';

    static UPDATE_DASHBOARDS_FAILED = 'UPDATE_DASHBOARDS_FAILED';

    static TOGGLE_SWIPABLE_DRAWER = 'TOGGLE_SWIPABLE_DRAWER';

    static TOGGLE_SWIPABLE_TASK_PANEL = 'TOGGLE_SWIPABLE_TASK_PANEL';

    static STORE_SUITE_STATE = 'STORE_SUITE_STATE';

    static CLEAR_SUITE_STATE = 'CLEAR_SUITE_STATE';

    static STORE_PROJECT_DETAILS_STATE = 'STORE_PROJECT_DETAILS_STATE';

    static CLEAR_PROJECT_DETAILS_STATE = 'CLEAR_PROJECT_DETAILS_STATE';

    static DECREASE_PROJECT_RELATED_COUNTS = 'DECREASE_PROJECT_RELATED_COUNTS';

    static INCREASE_PROJECT_RELATED_COUNTS = 'INCREASE_PROJECT_RELATED_COUNTS';

    /* Selected Test Case Reducer start */
    static CLEAR_SELECTED_TESTCASE_REDUCER = 'CLEAR_SELECTED_TESTCASE_REDUCER';

    static SET_SELECTED_TESTCASE_DATA = 'SET_SELECTED_TESTCASE_DATA';

    static ADD_DUMMY_STEP_IN_STEPS_ARRAY = 'ADD_DUMMY_STEP_IN_STEPS_ARRAY';

    static REMOVE_DUMMY_STEP_IN_STEPS_ARRAY = 'REMOVE_DUMMY_STEP_IN_STEPS_ARRAY';

    static ADD_STEP_IN_STEPS_ARRAY = 'ADD_STEP_IN_STEPS_ARRAY';

    static OPEN_EDIT_MODE_FOR_STEP = 'OPEN_EDIT_MODE_FOR_STEP';

    static CLOSE_EDIT_MODE_FOR_STEP = 'CLOSE_EDIT_MODE_FOR_STEP';

    static DELETE_STEP_IN_STEPS_ARRAY = 'DELETE_STEP_IN_STEPS_ARRAY';

    static EDIT_STEP_IN_STEPS_ARRAY = 'EDIT_STEP_IN_STEPS_ARRAY';

    static UPDATE_SINGLE_STEP_STATUS = 'UPDATE_SINGLE_STEP_STATUS';

    static SET_TEST_CASE_GENERATION_STATUS = 'SET_TEST_CASE_GENERATION_STATUS';

    static DELETE_STEPS_FROM_STEPS_ARRAY = 'DELETE_STEPS_FROM_STEPS_ARRAY';

    static SAVE_TEMP_STEPS_ARRAY = 'SAVE_TEMP_STEPS_ARRAY';

    static UNDO_STEPS_ARRAY_ACTION = 'UNDO_STEPS_ARRAY_ACTION';

    static REDO_STEPS_ARRAY_ACTION = 'REDO_STEPS_ARRAY_ACTION';

    static EMPTY_UNDO_REDO_ARRAY = 'EMPTY_UNDO_REDO_ARRAY';

    static SET_ACTIONTYPE_FOR_STEPS_ARRAY = 'SET_ACTIONTYPE_FOR_STEPS_ARRAY';

    static TOGGLE_STOP_ICON = 'TOGGLE_STOP_ICON';

    static TOGGLE_SAVE_ICON = 'TOGGLE_SAVE_ICON';

    static TOGGLE_STEP_TYPE = 'TOGGLE_STEP_TYPE';

    static SET_ALL_CACHE_XPATHS = 'SET_ALL_CACHE_XPATHS';

    static TOGGLE_STEP_XPATH = 'TOGGLE_STEP_XPATH';

    static SELECT_STEP = 'SELECT_STEP';

    static EMPTY_SELECT_STEP = 'EMPTY_SELECT_STEP';

    static TOGGLE_SELECT_ALL_STEP = 'TOGGLE_SELECT_ALL_STEP';

    static SELECT_MULTIPLE_STEPS = 'SELECT_MULTIPLE_STEPS';

    static TOGGLE_AUTO_SCROLL = 'TOGGLE_AUTO_SCROLL';

    static TOGGLE_BLOCK_MODAL = 'TOGGLE_BLOCK_MODAL';

    static TOGGLE_TAG_MODAL = 'TOGGLE_TAG_MODAL';

    static TOGGLE_SCREENSHOT_MODAL = 'TOGGLE_SCREENSHOT_MODAL';

    static SET_TEST_STEPS_OBJECT = 'SET_TEST_STEPS_OBJECT';

    static TOGGLE_LIST_VIEW = 'TOGGLE_LIST_VIEW';

    static SMART_RETRY_LOADER = 'SMART_RETRY_LOADER';

    static DEPUG_POINT_LOADER = 'DEPUG_POINT_LOADER';

    static TOGGLE_CREATION_MODE = 'TOGGLE_CREATION_MODE';

    static SET_EXPANDED_INSTR_NO = 'SET_EXPANDED_INSTR_NO';
    /* Selected Test Case Reducer end */

    static TOGGLE_DASHBOARD_SETTING_GEAR = 'TOGGLE_DASHBOARD_SETTING_GEAR';

    static DASHBOARD_TICKER_TIMEINDICATOR = 'DASHBOARD_TICKER_TIMEINDICATOR';

    static TESTCASE_MERGE = 'TESTCASE_MERGE';

    static TESTCASE_MERGE_SUCCESS = 'TESTCASE_MERGE_SUCCESS';

    static TESTCASE_MERGE_FAILURE = 'TESTCASE_MERGE_FAILURE';

    static UPLOAD_PROJECT_FILE = 'UPLOAD_PROJECT_FILE';

    static UPLOAD_PROJECT_FILE_SUCCESS = 'UPLOAD_PROJECT_FILE_SUCCESS';

    static UPLOAD_PROJECT_FILE_FAILURE = 'UPLOAD_PROJECT_FILE_FAILURE';

    static GET_DISABLED_PROJECTS = 'GET_DISABLED_PROJECTS';

    static GET_DISABLED_PROJECTS_SUCCESS = 'GET_DISABLED_PROJECTS_SUCCESS';

    static GET_DISABLED_PROJECTS_FAILURE = 'GET_DISABLED_PROJECTS_FAILURE';

    static ENABLE_PROJECTS = 'ENABLE_PROJECTS';

    static ENABLE_PROJECTS_SUCCESS = 'ENABLE_PROJECTS_SUCCESS';

    static ENABLE_PROJECTS_FAILURE = 'ENABLE_PROJECTS_FAILURE';

    static SHOW_ENABLE_PROJECTS = 'SHOW_ENABLE_PROJECTS';

    static CHANGE_TAB = 'CHANGE_TAB';

    static UPDATE_TAB_DATA = 'UPDATE_TAB_DATA';

    static CLEAR_GENERAL_MODAL = 'CLEAR_GENERAL_MODAL';

    static ON_SAVE_RETRY_API_FAILED = 'ON_SAVE_RETRY_API_FAILED';

    static UPDATE_TITLE = 'UPDATE_TITLE';

    static SET_NEW_STEP_POSITION = 'SET_NEW_STEP_POSITION';

    static UPDATE_CASES = 'UPDATE_CASES';

    // EXECUTION SCREEN START
    static GET_EXECUTION_DETAILS = 'GET_EXECUTION_DETAILS';

    static GET_EXECUTION_DETAILS_SUCCESS = 'GET_EXECUTION_DETAILS_SUCCESS';

    static SET_EXECUTION_DATA = 'SET_EXECUTION_DATA';

    static SET_EXECUTION_STEPS_BY_ID = 'SET_EXECUTION_STEPS_BY_ID';

    static REMOVE_EXECUTION_STEPS_BY_ID = 'REMOVE_EXECUTION_STEPS_BY_ID';

    static GET_EXECUTION_DETAILS_FAILURE = 'GET_EXECUTION_DETAILS_FAILURE';

    static UPDATE_EXECUTION_VIEW_TYPE = 'UPDATE_EXECUTION_VIEW_TYPE';

    static UPDATE_SINGLE_EXECUTION_STEP_STATUS = 'UPDATE_SINGLE_EXECUTION_STEP_STATUS';

    static CLEAR_EXECUTION_REDUCER = 'CLEAR_EXECUTION_REDUCER';

    static UPDATE_EXECUITON_STATUS = 'UPDATE_EXECUITON_STATUS';

    static SET_EXECUTION_MSGTYPE_DATA = 'SET_EXECUTION_MSGTYPE_DATA';

    static UPDATE_EXECUTION_STEPS_IN_PROJECT_REDUCER = 'UPDATE_EXECUTION_STEPS_IN_PROJECT_REDUCER';
    
    static SELECTED_EXECUTION = 'SELECTED_EXECUTION';
    
    static SORT_SELECTED_EXECUTION = 'SORT_SELECTED_EXECUTION';


    // EXECUTION SCREEN END

    static DASHBOARD_SET_SELECTED_PROJECTS = 'DASHBOARD_SET_SELECTED_PROJECTS';

    static UPDATE_IS_RELATIVE_DATE = 'UPDATE_IS_RELATIVE_DATE';

    static STORE_REMOVE_AUTO_SUGGEST_COMPONENT_STATE = 'STORE_REMOVE_AUTO_SUGGEST_COMPONENT_STATE';

    // Download Logs
    static DOWNLOAD_LOGS = 'DOWNLOAD_LOGS';

    static DOWNLOAD_LOGS_SUCCESS = 'DOWNLOAD_LOGS_SUSSESS';

    static DOWNLOAD_LOGS_FAILURE = 'DOWNLOAD_LOGS_FAILURE';

    // Download test step files
    static DOWNLOAD_TESTSTEP_FILE = 'DOWNLOAD_TESTSTEP_FILE';

    static DOWNLOAD_TESTSTEP_FILE_SUCCESS = 'DOWNLOAD_TESTSTEP_FILE_SUSSESS';

    static DOWNLOAD_TESTSTEP_FILE_FAILURE = 'DOWNLOAD_TESTSTEP_FILE_FAILURE';

    static UPDATE_CASE_ORDER = 'UPDATE_CASE_ORDER';

    static DELETE_TESTCASE_FROM_SUITE_SUCCESS = 'DELETE_TESTCASE_FROM_SUITE_SUCCESS';

    static UPDATE_AUTO_SUGGEST_COUNT = 'UPDATE_AUTO_SUGGEST_COUNT';

    static UPDATE_PROJECT_DOWNLOAD_PROGRESS = 'UPDATE_PROJECT_DOWNLOAD_PROGRESS';

    // data testCase association
    static UPDATE_TESTCASE_DATA_ASSOCIATION = 'UPDATE_TESTCASE_DATA_ASSOCIATION';

    static WEBSOCKET_ERROR = 'WEBSOCKET_ERROR';

    static CLEAR_IMAGE_REDUCER = 'CLEAR_IMAGE_REDUCER';

    static LOAD_IMAGE = 'LOAD_IMAGE';

    static LOAD_IMAGE_SUCCESS = 'LOAD_IMAGE_SUCCESS';

    static LOAD_IMAGE_FAILURE = 'LOAD_IMAGE_FAILURE';

    static UPDATE_SHOW_PASTE_BUTTON = 'UPDATE_SHOW_PASTE_BUTTON';

    // get Project and testSuite

    static GET_PROJECTS_AND_TESTSUITES = 'GET_PROJECTS_AND_TESTSUITES';

    static GET_PROJECTS_AND_TESTSUITES_SUCCESS = 'GET_PROJECTS_AND_TESTSUITES_SUCCESS';

    static GET_PROJECTS_AND_TESTSUITES_FAILURE = 'GET_PROJECTS_AND_TESTSUITES_FAILURE';

    static CLOSE_INSTALLATION_MODAL = 'CLOSE_INSTALLATION_MODAL';

    static TOGGLE_ID_MODAL = 'TOGGLE_ID_MODAL';

    static TOGGLE_RECORDER_ID_MODAL = 'TOGGLE_RECORDER_ID_MODAL';

    // saml
    static GET_AUTH_DETAILS = 'GET_AUTH_DETAILS';

    static GET_AUTH_DETAILS_SUCCESS = 'GET_AUTH_DETAILS_SUCCESS';

    static GET_AUTH_DETAILS_FAILURE = 'GET_AUTH_DETAILS_FAILURE';

    static GET_SAML_CONFIG = 'GET_SAML_CONFIG';

    static GET_SAML_CONFIG_SUCCESS = 'GET_SAML_CONFIG_SUCCESS';

    static GET_SAML_CONFIG_FAILURE = 'GET_SAML_CONFIG_FAILURE';

    static UPDATE_SAML_CONFIG = 'UPDATE_SAML_CONFIG';

    static UPDATE_SAML_CONFIG_SUCESS = 'UPDATE_SAML_CONFIG_SUCESS';

    static UPDATE_SAML_CONFIG_FAILURE = 'UPDATE_SAML_CONFIG_FAILURE';

    //execution environment
    static GET_EXECUTION_ENVIRONMENT_CONFIG = 'GET_EXECUTION_ENVIRONMENT_CONFIG';

    static GET_EXECUTION_ENVIRONMENT_CONFIG_SUCCESS = 'GET_EXECUTION_ENVIRONMENT_CONFIG_SUCCESS';

    static GET_EXECUTION_ENVIRONMENT_CONFIG_FAILURE = 'GET_EXECUTION_ENVIRONMENT_CONFIG_FAILURE';

    static POST_EXECUTION_ENVIRONMENT_CONFIG = 'POST_EXECUTION_ENVIRONMENT_CONFIG';

    static POST_EXECUTION_ENVIRONMENT_CONFIG_SUCCESS = 'POST_EXECUTION_ENVIRONMENT_CONFIG_SUCCESS';

    static POST_EXECUTION_ENVIRONMENT_CONFIG_FAILURE = 'POST_EXECUTION_ENVIRONMENT_CONFIG_FAILURE';

    static GET_ENVIRONMENT_FOR_LOCAL_EXECUTION_SUCCESS = 'GET_ENVIRONMENT_FOR_LOCAL_EXECUTION_SUCCESS';

    static GET_ENVIRONMENT_FOR_LOCAL_EXECUTION_FAILURE = 'GET_ENVIRONMENT_FOR_LOCAL_EXECUTION_FAILURE';

    static GET_TUNNEL_ID_FOR_SAUCELABS = 'GET_TUNNEL_ID_FOR_SAUCELABS';

    static GET_TUNNEL_ID_FOR_SAUCELABS_FAILURE = 'GET_TUNNEL_ID_FOR_SAUCELABS_FAILURE';

    static GET_SUPPORTED_ENVIRONMENT = 'GET_SUPPORTED_ENVIRONMENT';

    static GET_SUPPORTED_ENVIRONMENT_SUCCESS = 'GET_SUPPORTED_ENVIRONMENT_SUCCESS';

    static GET_SUPPORTED_ENVIRONMENT_FAILURE = 'GET_SUPPORTED_ENVIRONMENT_FAILURE';

    static GET_SAUCE_DETAILS = 'GET_SAUCE_DETAILS';

    static GET_SAUCE_DETAILS_SUCCESS = 'GET_SAUCE_DETAILS_SUCCESS';

    static GET_SAUCE_DETAILS_FAILURE = 'GET_SAUCE_DETAILS_FAILURE';

    static POST_SAUCE_DETAILS = 'POST_SAUCE_DETAILS';

    static POST_SAUCE_DETAILS_SUCCESS = 'POST_SAUCE_DETAILS_SUCCESS';

    static POST_SAUCE_DETAILS_FAILURE = 'POST_SAUCE_DETAILS_FAILURE';

    static GET_EXECUTION_DETAILS_BY_SCHEDULE_ID = 'GET_EXECUTION_DETAILS_BY_SCHEDULE_ID';

    static GET_EXECUTION_DETAILS_BY_SCHEDULE_ID_SUCCESS = 'GET_EXECUTION_DETAILS_BY_SCHEDULE_ID_SUCCESS';

    static GET_EXECUTION_DETAILS_BY_SCHEDULE_ID_FAILURE = 'GET_EXECUTION_DETAILS_BY_SCHEDULE_ID_FAILURE';

    

    //ALERTBAR ACTIONS
    static TOGGLE_ALERTBAR = 'TOGGLE_ALERTBAR';

    static IS_GENERATE_BUTTON_CLICKED = 'IS_GENERATE_BUTTON_CLICKED';

    static LOADER_FOR_GENERATE = 'LOADER_FOR_GENERATE';
    
}

export { ActionTypes };
