import React from "react";
import PropTypes from 'prop-types';
import { withStyles } from "@material-ui/styles";
import { Box } from "@material-ui/core";
import styles from './Styles';
import BrowserIcon from "./BrowserIcon/";
import DeviceTypeIcon from "./DeviceTypeIcon";


const DeviceDetailsContainer = ({ deviceDetails, selected, onChange, classes }) => {

    return (
      <Box className={classes.device_details_root}>
      <Box className={classes.device_list_root}>
          {
            deviceDetails.map((device,index)=>{
                return (
                    <Box key={index} className={classes.device_list_container}>
                        <Box onClick={(e)=>{e.preventDefault(); onChange(device)}}  className={device.id===selected.id?classes.selected_device_list_name:classes.device_list_name}>
                            <Box>
                                <DeviceTypeIcon deviceType={device.logoName}/>
                            </Box>
                            <Box>
                                <span style={{margin: 0, marginLeft: '12px'}}>{device.name}</span>
                            </Box>
                         </Box>

                     </Box>
                        )
                    })
                }
            </Box>
        </Box>
    )

}

DeviceDetailsContainer.propTypes = {
    deviceDetails: PropTypes.object.isRequired,
    onChange: PropTypes.func
}

export default withStyles(styles)(DeviceDetailsContainer)

