import { withStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import { lighten } from '@material-ui/core/styles/colorManipulator';
import Hidden from '@material-ui/core/Hidden';
import Close from '@material-ui/icons/Close';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes } from "@fortawesome/free-solid-svg-icons";
import Gavel from '@material-ui/icons/Gavel';
import Eye from '@material-ui/icons/Visibility';
import Info from '@material-ui/icons/InfoOutlined';
import Settings from '@material-ui/icons/Settings';
import Checkbox from '@material-ui/core/Checkbox';

import CircularProgress from '@material-ui/core/CircularProgress';

import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import ReactDOM from 'react-dom';
import React from 'react';

import WSService from '../../../services/WSService';
import { MUI_ACTION_MENU_ICON, THREE_DOT_MENU_CSS, TABLES_CSS, TABLE_PAGE_HEADING, BLACK_FONT } from '../../../common/cssConstants';
import {
    handleSearchFromArray,
    checkArrayLength,
    checkKeyInObject,
    getCountAvatar,
    getFormatedDate,
} from './../../../utils/utils';
import { GeneralActions, ProjectActions } from '../../../store/actions';
import ChangedApiSelectionDialog from './ChangedApiSelectionDialog';
import { EnhancedTableHead } from '../../../components/EnhancedTable/EnhancedTableHeadTable';
import Tooltip from '../../../components/Tooltip';
import RejectApproveTestCase from './rejectApproveTestCase';
import ReviewTestCase from './reviewTestCase';
import SettingTestCase from './settingTestCase';
import TableFooter from '../../../common/TableFooter';
import { MenuOptions } from '../../../components/Button/MenuOptions'
import ExpandBtn from '../../../components/Button/ExpandBtn'

const toolbarStyles = (theme) => ({
    root: {
        paddingRight: theme.spacing.unit,
    },
    highlight:
        theme.palette.type === 'light'
            ? {
                color: theme.palette.secondary.main,
                backgroundColor: lighten(theme.palette.secondary.light, 0.85),
            }
            : {
                color: theme.palette.text.primary,
                backgroundColor: theme.palette.secondary.dark,
            },
    actions: {
        color: theme.palette.text.secondary,
    },
    title: {
        flex: '0 0 auto',
    },
    subTitle: {
        fontWeight: 'normal',
    },
    titleText: {
        marginLeft: '20px',
    },
    AddIcon: {
        fontSize: '29px',
    },
});

let EnhancedTableToolbar = (props) => {
    const { classes } = props;

    return (
        <Toolbar
            style={{
                minHeight: '68px',
            }}
        >
            <div className={classes.title}>
                <Typography
                    className={classes.titleText}
                    variant="title"
                    id="tableTitle"
                >
                    Smart Maintenance
                </Typography>
            </div>
        </Toolbar>
    );
};

EnhancedTableToolbar.propTypes = {
    classes: PropTypes.object.isRequired,
};

EnhancedTableToolbar = withStyles(toolbarStyles)(EnhancedTableToolbar);

const styles = (theme) => ({
    associationButton: {
        background: '#fff',
        color: 'rgb(96, 148, 255)',
        border: '2px solid rgb(96, 148, 255)',
        borderRadius: '100%',
        minWidth: 0,
        width: 'auto',
        padding: '7px',
        marginLeft: '25px',
    },
    root: {
        borderBottom: '1px #dddddd solid',
        borderRadius: 0,
        flexGrow: 1,
        width: 'calc(100% - 40px)',
        margin: '0px 20px',
        marginTop: '20px',
        boxShadow: 'none',
    },
    filterBtn: {
        border: '1px dashed gray',
        borderRadius: '50px',
        padding: '2px 15px',
        minWidth: 'auto',
        minHeight: 'auto',
        color: 'rgba(0, 0, 0, 0.87)',
        marginLeft: '20px',
    },
    TableFilterBar: {
        borderBottom: '1px solid rgba(224, 224, 224, 1)',
        borderTop: '1px solid rgba(224, 224, 224, 1)',
    },
    table: {},
    tableWrapper: {
        overflowX: 'hidden',
        overflowY: 'auto',
        backgroundColor: '#fff',
        [theme.breakpoints.only('xs')]: {
            overflowX: 'auto !important',
        },
    },
    cellWidth: {
        fontSize: TABLES_CSS.normalFontSize,
        color: TABLES_CSS.normalFontColor,
    },
    cell: {
        textAlign: 'left',
        flexDirection: 'row',
        padding: 0,
        width: '20%',
        minWidth: '20%',
    },
    currentCell: {
        textAlign: 'left',
        flexDirection: 'row',
        padding: 0,
        width: '20%',
        minWidth: '20%',
        paddingLeft: '0px',
    },
    iconsButtonContainer: {
        width: '40px',
        height: '40px'
    },
    moreIconsBtn: {
        color: THREE_DOT_MENU_CSS.iconColor,
        fontSize: THREE_DOT_MENU_CSS.iconFontSize,
    },
    moreIcons: MUI_ACTION_MENU_ICON,
    menuButton: {
        color: 'white',
        fontSize: 33,
    },
    expandIconContainer: {
        width: '20%',
        display: 'flex',
        justifyContent: 'center',
    },
    cellMaxWidth: {
        paddingRight: 5,
    },
    cellMaxWidthBig: {
        width: 155,
        minWidth: 155,
        maxWidth: 155,
        paddingRight: 5,
    },
    searchInputIcon: {
        color: '#003d9d',
    },
    noDataText: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        height: '88px',
    },
    buildIcon: {
        paddingLeft: '36px',
        paddingRight: '20px',
        '& svg': {
            transform: 'rotate(90deg)'
        },
    },
    apiButtonSubContainers: {
        border: '1px solid #adadad',
        borderRadius: 5,
        padding: '7px 15px 3px',
        cursor: 'pointer',
        backgroundColor: '#ffffff',
        '& > p': {
            color: '#0042a3',
            fontSize: 12,
            fontWeight: 'bold',
            textAlign: 'center',
        },
    },
    apiButtonSubContainersDisabled: {
        border: '1px solid #adadad',
        borderRadius: 5,
        padding: '7px 15px 3px',
        cursor: 'default',
        backgroundColor: '#ffffff',
        '& > p': {
            color: '#adadad',
            fontSize: 12,
            fontWeight: 'bold',
            textAlign: 'center',
        },
    },
    maxMessageSize: {
        maxWidth: 'calc(100% - 60px)',
        overflow: 'hidden',
        whiteSpace: 'nowrap',
        textOverflow: 'ellipsis',
    },
    heading: {
        marginTop: '5px',
        marginLeft: '20px',
        color: TABLE_PAGE_HEADING.fontColor,
        fontSize: TABLE_PAGE_HEADING.fontSize,
        fontWeight: TABLE_PAGE_HEADING.fontWeight,
    },
    rowHeight: {
        height: "40px",
        backgroundColor: "rgba(0, 0, 0, 0.04)"
    },
    avatar: {
        minWidth: '20px',
        height: '20px',
        borderRadius: '20px',
        backgroundColor: '#F39B31',
        color: '#fff',
        textAlign: 'center',
        marginRight: '8px',
        fontSize: '13px',
        lineHeight: 1.7,
        padding: '0 4px',
        display: 'inline-block',
    },
    selectedText: {
        color: BLACK_FONT,
        fontSize: '12px',
        fontWeight: 'bold',
        marginTop: '3px',
    },
    multiCloseIcon: {
        color: '#4a4a4a',
        cursor: 'pointer',
        fontSize: 22,
        marginLeft: 10,
    },
    multiIcon: {
        color: '#3b91df',
        fontSize: 22,
        cursor: 'pointer',
        marginRight: 5,
    },
    noWrap: {
        overflow: 'hidden',
        whiteSpace: 'nowrap',
        textOverflow: 'ellipsis',
        maxWidth: '100%',
    },
    headerContainer: {
        display: 'flex',
        justifyContent: 'flex-end',
        alignItems: 'center',
        [theme.breakpoints.only('xs')]: {
            display: 'block'
        }
    },
    headerButtonContainer: {
        display: 'flex',
        marginRight: '5px',
        [theme.breakpoints.only('xs')]: {
            marginRight: 15,
            marginLeft: 20,
            marginTop: 7,
            justifyContent: 'space-between'
        }
    }
});

class SmartMaintenance extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            isAnyCheck: false,
            changedApis: [],
            tempChangedApis: [],
            changedApisDialogOpen: false,
            filteredChangedApis: [],
            selectAllApis: 'unCheck',
            searchedApiValue: '',
            order: 'desc',
            orderBy: 'alertTime',
            rowExpendStatus: [0, false],
            data: [],
            selected: [],
            rejectApproveTestCaseData: null,
            rejectApproveDialogOpen: false,
            reviewTestCaseData: null,
            reviewDialogOpen: false,
            settingDialogOpen: false,
            isReject: true,
            isRejectApproveAll: false,
            isRejectApproveSelected: false,
            page: 0,
            rowsPerPage: 20,
            filterArray: [],
            isFiltering: false,
            headers: [
                {
                    id: 'testcaseName',
                    sortable: true,
                    numeric: false,
                    disablePadding: true,
                    label: 'Name',
                    status: 'Lock',
                },
                {
                    id: 'projectName',
                    sortable: true,
                    numeric: false,
                    disablePadding: true,
                    label: 'Project',
                    status: 'Active',
                },
                {
                    id: 'alertTime',
                    sortable: true,
                    numeric: false,
                    disablePadding: true,
                    label: 'Alert Date',
                    status: 'Active',
                },
                {
                    id: 'source',
                    sortable: true,
                    numeric: false,
                    disablePadding: true,
                    label: 'Source',
                    status: 'Active',
                },
                {
                    id: 'funcs',
                    sortable: false,
                    numeric: false,
                    disablePadding: true,
                    label: 'Actions',
                    status: 'NoCol',
                },],
            selectedTestCaseNames: [],
        };
        props.updateTitle('Smart Maintenance');

    }


    componentDidMount() {
        const { getAlertTestCasesWithAccount, user } = this.props;
        getAlertTestCasesWithAccount(user.accountId);
    };

    UNSAFE_componentWillReceiveProps(nextProps) {
        let changedApis = [...this.state.changedApis];
        let newChangeApi = JSON.parse(JSON.stringify(nextProps.changedApis))
        if (changedApis && Array.isArray(changedApis) && changedApis.length > 0 && newChangeApi && newChangeApi.length) {
            changedApis = newChangeApi.map((api) => {
                const matched = changedApis.some((preApi) => (api.api === preApi.api && preApi.checked === true));
                if (matched) {
                    api.checked = true;
                }
                return api;
            });
            this.setState({ changedApis, filteredChangedApis: changedApis, tempChangedApis: changedApis },
                () => {
                    this.handleSearchCheckedApis(this.state.searchedApiValue);
                });

        } else if (newChangeApi && newChangeApi.length) {
            changedApis = newChangeApi
            this.setState({ changedApis, filteredChangedApis: changedApis, tempChangedApis: changedApis },
                () => {
                    this.handleSearchCheckedApis(this.state.searchedApiValue);
                });
        }

    }

    componentWillUnmount() {
        this.props.queryRemove();
    }

    handleRequestSort = (property) => {
        const orderBy = property;
        let order = 'desc';

        if (this.state.orderBy === property && this.state.order === 'desc') {
            order = 'asc';
        }

        this.setState({ order, orderBy });
    };

    handleChangePage = (event, page) => {
        this.setState({ page });
    };

    handleChangeRowsPerPage = (event) => {
        this.setState({ rowsPerPage: event.target.value });
    };

    handleSearch = (ev) => {
        // const { value } = ev.target;
        // if (value && this.props.alertTestCasesWithAccount) {
        //     let filterArray = this.props.alertTestCasesWithAccount.filter(
        //         (testCase) => {
        //             const { testcaseName = '', projectName = '' } = testCase;
        //             let _value = value.toLowerCase();
        //             return (
        //                 testcaseName.toLowerCase().includes(_value) ||
        //                 projectName.toLowerCase().includes(_value)
        //             );
        //         },
        //     );
        //     this.setState({
        //         isFiltering: true,
        //         filterArray,
        //     });
        // } else {
        //     this.setState({
        //         isFiltering: false,
        //         filterArray: [],
        //     });
        // }
    };

    getTestCases = () => {
        const { filterArray, isFiltering } = this.state;
        let testCaseArray = filterArray.length || isFiltering
            ? filterArray
            : this.props.alertTestCasesWithAccount;
        return testCaseArray;
    };

    handleClick = (event, id) => {
        let selected = [...this.state.selected];
        const selectedIndex = selected.indexOf(id);

        if (selectedIndex === -1) {
            selected.push(id);
        } else if (selectedIndex >= 0) {
            selected = selected.slice(0, selectedIndex).concat(selected.slice(selectedIndex + 1));
        }

        this.setState({ selected });
    };

    isSelected = (id) => this.state.selected.indexOf(id) !== -1;

    handleSelectAllClick = (event, currentPageData) => {
        const checked = event.target.checked;
        this.selectDeselectAll(checked, currentPageData);
    };

    selectDeselectAll = (checked, currentPageData) => {
        let selected = [...this.state.selected];
        currentPageData.forEach(cases => {
            cases.childs.forEach(subC => {
                const id = `${cases.id}_${subC.alert_id}_${subC.testcaseId}_${subC.projectId}`;
                const index = selected.indexOf(id);
                if (checked && index === -1) {
                    selected.push(id);
                } else if (!checked && index >= 0) {
                    selected = selected.slice(0, index).concat(selected.slice(index + 1));
                }
            });
        });
        this.setState({ selected });
    };

    handleCloseRejectApproveDialog = async (flag) => {
        const { rejectApproveTestCaseData, isReject, isRejectApproveAll, isRejectApproveSelected } = this.state;
        if (flag && rejectApproveTestCaseData) {
            const { getAlertTestCasesWithAccount, rejectApproveAlertTestCase, rejectApproveAllAlertTestCase, user } = this.props;
            let onComplete = () => {
                this.setState({
                    rejectApproveTestCaseData: null,
                    rejectApproveDialogOpen: false,
                    selectedTestCaseNames: []
                });
                getAlertTestCasesWithAccount(user.accountId);
            };
            if (isRejectApproveAll) {
                let info = [];
                if (isReject) {
                    for (let i = 0; i < rejectApproveTestCaseData.childs.length; i++) {
                        info.push(rejectApproveTestCaseData.childs[i].alert_id);
                    }
                } else {
                    for (let i = 0; i < rejectApproveTestCaseData.childs.length; i++) {
                    	let sessionId = await WSService.getNewSessionId(user.accountId);
                        info.push({
                            "sessionId": sessionId,
                            "alertId": rejectApproveTestCaseData.childs[i].alert_id
                        })
                    }
                }
                rejectApproveAllAlertTestCase(
                    info,
                    rejectApproveTestCaseData.source,
                    isReject,
                    onComplete);
            } else if (isRejectApproveSelected) {
                let info = [];
                let source = '';
                for (let l = 0; l < rejectApproveTestCaseData.length; l++) {
                    let data = rejectApproveTestCaseData[l];
                    if (isReject) {
                        for (let i = 0; i < data.childs.length; i++) {
                            info.push(data.childs[i].alert_id);
                        }
                    } else {
                        for (let i = 0; i < data.childs.length; i++) {
                        	let sessionId = await WSService.getNewSessionId(user.accountId);
                            info.push({
                                "sessionId": sessionId,
                                "alertId": data.childs[i].alert_id
                            })
                        }
                    }
                    source = data.source;
                }
                rejectApproveAllAlertTestCase(
                    info,
                    source,
                    isReject,
                    onComplete);
            } else {
                let sessionId = "";
                if (!isReject) {
                	sessionId = await WSService.getNewSessionId(user.accountId);
                }
                rejectApproveAlertTestCase(
                    rejectApproveTestCaseData.alert_id,
                    rejectApproveTestCaseData.source,
                    sessionId,
                    isReject,
                    onComplete);
            }
        } else {
            this.setState({
                rejectApproveTestCaseData: null,
                rejectApproveDialogOpen: false,
                selectedTestCaseNames: []
            });
        }
    };

    changeHeaderStatus = (headers) => {
        this.setState({ headers })
    };
    handleCloseSettingDialog = () => {
        this.setState({
            settingDialogOpen: false
        });
    };
    onSettinTestCase = () => {
        this.setState({
            settingDialogOpen: true
        });
    };

    handleCloseReviewDialog = () => {
        this.setState({
            reviewDialogOpen: false,
            reviewTestCaseData: null
        });
    };
    onReviewTestCase = (data) => {
        this.setState({
            reviewDialogOpen: true,
            reviewTestCaseData: data
        });
    };

    onRejectApproveTestCase = (data, isReject, isRejectApproveAll = false, isRejectApproveSelected = false) => {
        let temp = [];
        if (checkArrayLength(data)) {
            data.forEach((value) => {
                value.childs.forEach((child) => {
                    temp.push(child.testcaseName)
                })
            })
        }
        this.setState({
            rejectApproveTestCaseData: data,
            rejectApproveDialogOpen: true,
            isReject,
            isRejectApproveAll,
            isRejectApproveSelected,
            selectedTestCaseNames: temp,
        });
    };

    renderItems(cases, classes) {
        let items = [
            {
                action: () => this.goToCaseDetail(cases),
                icon: <Eye className={classes.moreIcons} aria-label="eyeIcon" id="eyeIcon" />,
                isDisabled: false,
                name: ' View ',
            },
        ];
        return items;
    }
    sortObject = (obj, prop, order = 'asc') => {
        let tempObj = JSON.parse(JSON.stringify(obj));
        for (let i = 0; i < tempObj.length; i++) {
            for (let j = i + 1; j < tempObj.length; j++) {
                let cond = tempObj[i][prop] < tempObj[j][prop];
                if (order === 'desc')
                    cond = tempObj[i][prop] > tempObj[j][prop];
                if (cond) {
                    const temp = tempObj[i];
                    tempObj[i] = tempObj[j];
                    tempObj[j] = temp;
                }
            }
        }
        return tempObj;
    }
    handleExpend = (id) => {
        const { rowExpendStatus } = this.state;
        this.setState((state) => ({
            rowExpendStatus: [
                id,
                id === rowExpendStatus[0] ? !rowExpendStatus[1] : true,
            ],
        }));
    }

    /* Change Api Functions start */
    toggleChangedApiDialog = (flag = false) => {
        const { user: { accountId } } = this.props
        if (!this.state.changedApisDialogOpen) {
            this.props.getChangedApis(accountId);
        }
        // if(flag) {
        // this.setState((state) => {
        //     return { changedApis: state.tempChangedApis }
        // });    
        // }
        this.setState((state) => {
            return { changedApisDialogOpen: !state.changedApisDialogOpen, changedApis: state.tempChangedApis }
        }, () => {
            this.updateSelectOrClearAllStatus(this.state.changedApis);
        });
    };

    updateSelectOrClearAllStatus = (array) => {
        const allChecked = !array.some((changedApi) => !changedApi.checked);
        const isAnyCheck = array.some((changedApi) => changedApi.checked);
        this.setState({
            selectAllApis: !isAnyCheck ? 'unCheck' : allChecked ? 'check' : '', isAnyCheck
        });
    }

    handleSingleCheck = (index, ischecked) => {
        const filteredChangedApis = [...this.state.filteredChangedApis];
        const tempChangedApis = [...this.state.tempChangedApis];
        filteredChangedApis[index]["checked"] = ischecked;
        this.updateSelectOrClearAllStatus(filteredChangedApis);

        const changedApiIndex = tempChangedApis.map((item) => item.api).indexOf(filteredChangedApis[index].api)
        tempChangedApis[changedApiIndex]["checked"] = ischecked;

        this.setState({ filteredChangedApis: filteredChangedApis, tempChangedApis: tempChangedApis });
    }

    toggleAllCheck = (flag) => {
        const filteredChangedApis = [...this.state.filteredChangedApis];
        let tempChangedApis = [...this.state.tempChangedApis];

        if (filteredChangedApis && Array.isArray(filteredChangedApis) && filteredChangedApis.length > 0) {
            let newChangedApis = [];
            if (flag === 'check') {
                newChangedApis = filteredChangedApis.map(item => {
                    return { ...item, checked: true };
                });
            } else {
                newChangedApis = filteredChangedApis.map(item => {
                    return { ...item, checked: false };
                });
            }
            newChangedApis.forEach(api => {
                const changedApiIndex = tempChangedApis.map((item) => item.api).indexOf(api.api)
                if (changedApiIndex >= 0)
                    tempChangedApis[changedApiIndex] = { ...tempChangedApis[changedApiIndex], checked: api.checked };
            });
            // set flag selectAllApis to enable/disable select all/clear all buttons 
            this.setState({ filteredChangedApis: newChangedApis, tempChangedApis: tempChangedApis, selectAllApis: flag });
        }
    }

    handleSearchCheckedApis = (value = '') => {
        // const { filteredChangedApis } = this.state;
        const { tempChangedApis } = this.state;
        const filteredArray = handleSearchFromArray(value, tempChangedApis, ['api']);
        this.updateSelectOrClearAllStatus(filteredArray);
        this.setState({ filteredChangedApis: filteredArray, searchedApiValue: value });
    };

    handleSmartRun = () => {
        const { filteredChangedApis } = this.state;
        const { initiateSmartRun } = this.props;
        const data = [];

        filteredChangedApis.forEach(item => {
            if (item.checked) data.push(item.api);
        });
        this.setState({
            isAnyCheck: false,
            changedApis: [],
            tempChangedApis: [],
            filteredChangedApis: [],
            selectAllApis: 'unCheck',
            searchedApiValue: '',
        }, () => {
            initiateSmartRun(data);
        })
    }
    /* Change Api Functions end */

    currentRowIndicator = () => {
        return (<span style={{
            display: 'inline-block',
            width: '4px',
            marginRight: '6px',
            height: '40px',
            backgroundColor: '#ff9700',
        }}></span>);
    }

    tableBodyRender = () => {
        const { classes, isLoading, query = '' } = this.props;
        const {
            filteredChangedApis,
            changedApisDialogOpen,
            selectAllApis,
            order,
            orderBy,
            rowsPerPage,
            rejectApproveTestCaseData,
            rejectApproveDialogOpen,
            settingDialogOpen,
            isReject,
            isRejectApproveAll,
            isRejectApproveSelected,
            page,
            isFiltering,
            selected,
            headers,
            reviewDialogOpen,
            reviewTestCaseData,
            rowExpendStatus,
            searchedApiValue
        } = this.state;
        let testSuiteArray = this.getTestCases();
        if (query && checkArrayLength(testSuiteArray)) {
            const _value = query.toLowerCase();
            testSuiteArray = testSuiteArray.filter((suite) =>
                (checkKeyInObject(suite, 'testcaseName') && suite.testcaseName.toLowerCase().includes(_value) ||
                    checkKeyInObject(suite, 'projectName') && suite.projectName.toLowerCase().includes(_value))
            );
        }

        let testSuiteArrayMerged = [];
        let selectedSuites = [];
        if (testSuiteArray && testSuiteArray.length) {
            let tempList = {};
            let tempSelected = {};
            testSuiteArray = this.sortObject(testSuiteArray, orderBy, order);
            for (let i = 0; i < testSuiteArray.length; i++) {
                const id = `${testSuiteArray[i].stepId}_${testSuiteArray[i].alert_id}_${testSuiteArray[i].testcaseId}_${testSuiteArray[i].projectId}`;
                const index = selected.indexOf(id);
                if (index >= 0) {
                    if (!tempSelected[testSuiteArray[i].stepId]) {
                        tempSelected[testSuiteArray[i].stepId] = { id: testSuiteArray[i].stepId, message: testSuiteArray[i].message, "childs": [], source: -1 };
                    }
                    tempSelected[testSuiteArray[i].stepId]["childs"].push(testSuiteArray[i]);
                    tempSelected[testSuiteArray[i].stepId]["source"] = testSuiteArray[i].source;
                }
                if (!tempList[testSuiteArray[i].stepId]) {
                    tempList[testSuiteArray[i].stepId] = { id: testSuiteArray[i].stepId, message: testSuiteArray[i].message, "childs": [], source: -1 };
                }
                tempList[testSuiteArray[i].stepId]["childs"].push(testSuiteArray[i]);
                tempList[testSuiteArray[i].stepId]["source"] = testSuiteArray[i].source;
            }
            for (let el in tempSelected) {
                selectedSuites.push(tempSelected[el]);
            }
            for (let el in tempList) {
                testSuiteArrayMerged.push(tempList[el]);
            }
        }

        const currentPageData = testSuiteArrayMerged.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage);

        let _selected = 0;
        let _total = 0;

        currentPageData.forEach(cases => {
            cases.childs.forEach(subC => {
                const id = `${cases.id}_${subC.alert_id}_${subC.testcaseId}_${subC.projectId}`;
                const index = selected.indexOf(id);
                _total++;
                if (index >= 0) {
                    _selected++;
                }
            });
        });

        let ActiveCount = 0;
        for (let i = 0; i < headers.length; i++) {
            if (headers[i].status === "Lock" || headers[i].status === "Active") {
                ActiveCount++;
            }
        }
        const containerStyle = { width: 40, height: 40, display: 'inline-block' };
        return (
            <div className={classes.root}>
                {selected.length > 0 ?
                    <div style={{ display: 'flex', alignItems: 'center', backgroundColor: '#f0f7ea', padding: '0 15px', height: 45, justifyContent: 'space-between', boxShadow: '0px 1px 5px 0px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 3px 1px -2px rgba(0, 0, 0, 0.12)' }}>
                        <div style={{ display: 'flex', alignItems: 'center', }}>
                            <span className={classes.avatar} style={{ backgroundColor: '#3b91df', }}> {selected.length} </span>
                            <Typography className={classes.selectedText}>
                                {selected.length === 1 ? 'Project is Selected' : 'Projects are Selected'}
                            </Typography>
                            <Tooltip data={"Uncheck all"}>
                                <Close
                                    className={classes.multiCloseIcon}
                                    onClick={() => this.selectDeselectAll(false, testSuiteArrayMerged)}
                                    aria-label="closeIcon"
                                    id="closeIcon"
                                />
                            </Tooltip>
                        </div>
                        <span>
                            <Tooltip data={"Approve"}>
                                <Gavel
                                    className={classes.multiIcon}
                                    onClick={() => { this.onRejectApproveTestCase(selectedSuites, false, false, true) }}
                                    aria-label="gavelIcon"
                                    id="gavelIcon"
                                />
                            </Tooltip>
                            <Tooltip data={"Reject"}>
                                <FontAwesomeIcon
                                    className={classes.multiIcon}
                                    icon={faTimes}
                                    aria-label="faTimes"
                                    id="faTimes"
                                    onClick={() => { this.onRejectApproveTestCase(selectedSuites, true, false, true) }}
                                />
                            </Tooltip>
                        </span>
                    </div> : null
                }
                <div style={{ minHeight: 'calc(100vh - 192px)' }} >
                    <div className={classes.tableWrapper} style={{ maxHeight: `calc(100vh - ${(selected.length > 0 ? 45 : 0) + 224}px)`, }}>
                        {testSuiteArrayMerged && Array.isArray(testSuiteArrayMerged) && testSuiteArrayMerged.length > 0 ? (
                            <Table className={classes.table} aria-labelledby="" id="myTbl">
                                <Hidden only={['xs']}>
                                    <EnhancedTableHead
                                        numSelected={_selected}
                                        order={order}
                                        orderBy={orderBy}
                                        onSelectAllClick={(e) => { this.handleSelectAllClick(e, currentPageData) }}
                                        onRequestSort={this.handleRequestSort}
                                        currentPageData={currentPageData}
                                        rowCount={_total}
                                        headers={headers}
                                        ActiveCount={ActiveCount}
                                        showSelectBtn={true}
                                        getTable={() => { return ReactDOM.findDOMNode(this).querySelector(`#myTbl`); }}
                                    />
                                </Hidden>
                                <Hidden smUp>
                                    <EnhancedTableHead
                                        numSelected={_selected}
                                        order={order}
                                        orderBy={orderBy}
                                        onSelectAllClick={(e) => { this.handleSelectAllClick(e, currentPageData) }}
                                        onRequestSort={this.handleRequestSort}
                                        currentPageData={currentPageData}
                                        rowCount={_total}
                                        headers={headers}
                                        ActiveCount={ActiveCount}
                                        showSelectBtn={true}
                                    />
                                </Hidden>
                                <TableBody>
                                    {currentPageData.map((cases) => {
                                        let _row = [];
                                        let isAnySelected = false;
                                        for (let i = 0; i < cases.childs.length; i++) {
                                            const subC = cases.childs[i];
                                            const uniqueKey = `${cases.id}_${subC.alert_id}_${subC.testcaseId}_${subC.projectId}`;
                                            const isSelected = this.isSelected(uniqueKey);
                                            if (isSelected) {
                                                isAnySelected = true;
                                                break;
                                            }
                                        }
                                        _row.push(
                                            <TableRow
                                                hover
                                                tabIndex={-1}
                                                key={cases.id}
                                                className={classes.rowHeight}
                                            >
                                                <TableCell
                                                    component="th"
                                                    scope="row"
                                                    padding="none"
                                                    className={rowExpendStatus[0] === cases.id && rowExpendStatus[1] ? classes.currentCell : classes.cell}
                                                    colSpan={ActiveCount}
                                                >
                                                    <div style={{ paddingLeft: rowExpendStatus[0] === cases.id && rowExpendStatus[1] ? '0px' : '10px' }}>
                                                        <div style={{ display: 'flex', alignItems: 'center', fontSize: TABLES_CSS.normalFontSize, fontWeight: 'bold', color: '#F39B31', }}>
                                                            {rowExpendStatus[0] === cases.id && rowExpendStatus[1] ? this.currentRowIndicator() : null}
                                                            <span className={classes.avatar}>{getCountAvatar(cases.childs.length)}</span>
                                                            <span className={classes.maxMessageSize}>
                                                                <Tooltip data={cases.message}>
                                                                    {cases.message}
                                                                </Tooltip>
                                                            </span>
                                                        </div>
                                                    </div>
                                                </TableCell>
                                                <TableCell
                                                    className={classes.cell}
                                                >
                                                    <div style={{ display: 'flex' }}>
                                                        <Tooltip data={"Info"}>
                                                            <IconButton
                                                                className={classes.iconsButtonContainer}
                                                                aria-label="Info"
                                                                onClick={() => this.onReviewTestCase(cases)}
                                                            >
                                                                <Info className={classes.moreIconsBtn} aria-label="infoicon" id="infoIcon" />
                                                            </IconButton>
                                                        </Tooltip>
                                                        <Tooltip data={"Approve"}>
                                                            <IconButton
                                                                className={classes.iconsButtonContainer}
                                                                aria-label="Approve"
                                                                onClick={() => { this.onRejectApproveTestCase(cases, false, true) }}
                                                                disabled={isAnySelected}
                                                            >
                                                                <Gavel className={classes.moreIconsBtn} style={{ color: isAnySelected ? '#BAC4CE' : '#1babab' }} aria-label="gavelIcon" id="gavelIcon" />
                                                            </IconButton>
                                                        </Tooltip>
                                                        <Tooltip data={"Reject"}>
                                                            <IconButton
                                                                className={classes.iconsButtonContainer}
                                                                aria-label="Reject"
                                                                onClick={() => { this.onRejectApproveTestCase(cases, true, true) }}
                                                                disabled={isAnySelected}
                                                            >
                                                                <FontAwesomeIcon
                                                                    className={classes.moreIconsBtn}
                                                                    icon={faTimes}
                                                                    aria-label="faTimes"
                                                                    id="faTimes"
                                                                    style={isAnySelected ? { color: '#BAC4CE' } : {}}
                                                                />
                                                            </IconButton>
                                                        </Tooltip>
                                                        <ExpandBtn
                                                            flag={rowExpendStatus[0] === cases.id && rowExpendStatus[1]}
                                                            action={() => this.handleExpend(cases.id)}
                                                        />
                                                    </div>
                                                </TableCell>
                                            </TableRow>
                                        );
                                        /* eslint-disable no-unused-expressions */
                                        {
                                            rowExpendStatus[0] === cases.id &&
                                                rowExpendStatus[1]
                                                && _row.push(
                                                    cases.childs.map(
                                                        (subC, index) => {
                                                            const uniqueKey = `${cases.id}_${subC.alert_id}_${subC.testcaseId}_${subC.projectId}`;
                                                            const isSelected = this.isSelected(uniqueKey);
                                                            return (
                                                                <TableRow
                                                                    hover
                                                                    aria-checked={isSelected}
                                                                    selected={isSelected}
                                                                    tabIndex={-1}
                                                                    key={subC.alert_id}
                                                                    className={classes.rowHeight}
                                                                >
                                                                    <TableCell className={classes.cell}>
                                                                        <div style={{ paddingLeft: '10px' }}>
                                                                            <Typography
                                                                                noWrap
                                                                                className={`${classes.cellWidth} ${classes.noWrap}`}
                                                                            >
                                                                                <Checkbox onClick={(event) => this.handleClick(event, uniqueKey)} checked={isSelected || false} style={{ height: '40px', width: '20px', marginRight: '10px', color: isSelected ? '#3b91df' : '#bac4ce', }} />
                                                                                <Tooltip data={subC.testcaseName}>
                                                                                    {subC.testcaseName}
                                                                                </Tooltip>
                                                                            </Typography>
                                                                        </div>
                                                                    </TableCell>
                                                                    {
                                                                        headers[1].status !== 'InActive' && (<TableCell
                                                                            className={classes.cell}
                                                                        >
                                                                            <div style={{ paddingLeft: '10px' }}>
                                                                                <Typography
                                                                                    noWrap
                                                                                    className={`${classes.cellMaxWidth} ${classes.noWrap}`}
                                                                                    style={{ fontSize: TABLES_CSS.normalFontSize, color: TABLES_CSS.normalFontColor }}
                                                                                >
                                                                                    <Tooltip data={subC.projectName}>
                                                                                        {subC.projectName}
                                                                                    </Tooltip>
                                                                                </Typography>
                                                                            </div>
                                                                        </TableCell>)
                                                                    }
                                                                    {headers[2].status !== 'InActive' && (<TableCell
                                                                        className={classes.cell}
                                                                    >
                                                                        <div style={{ paddingLeft: '10px' }}>
                                                                            <Typography
                                                                                noWrap
                                                                                className={`${classes.cellMaxWidth} ${classes.noWrap}`}
                                                                                style={{ fontSize: TABLES_CSS.normalFontSize, color: TABLES_CSS.normalFontColor }}
                                                                            >
                                                                                <Tooltip data={getFormatedDate(subC.alertTime, 'HH:mm:ss', null)}>
                                                                                    {getFormatedDate(subC.alertTime, 'MMM D, YYYY', 'N/A')}
                                                                                </Tooltip>
                                                                            </Typography>
                                                                        </div>
                                                                    </TableCell>)}
                                                                    {headers[3].status !== 'InActive' && (<TableCell
                                                                        className={classes.cell}
                                                                    >
                                                                        <div style={{ paddingLeft: '10px' }}>
                                                                            <Typography
                                                                                noWrap
                                                                                style={{ fontSize: TABLES_CSS.normalFontSize, color: TABLES_CSS.normalFontColor }}
                                                                                className={classes.noWrap}
                                                                            >
                                                                                {subC.source === 1 ? 'Manual' : subC.source === 2 ? 'CDCI' : subC.source === 3 ? 'Api changed' : 'N/A'}
                                                                            </Typography>
                                                                        </div>
                                                                    </TableCell>)}
                                                                    <TableCell
                                                                        className={classes.cell}
                                                                    >
                                                                        <div>
                                                                            <Tooltip data={"Info"}>
                                                                                <IconButton
                                                                                    className={classes.iconsButtonContainer}
                                                                                    aria-label="Info"
                                                                                    onClick={() => this.onReviewTestCase(subC)}
                                                                                >
                                                                                    <Info className={classes.moreIconsBtn} aria-label="infoIcon" id="infoIcon" />
                                                                                </IconButton>
                                                                            </Tooltip>
                                                                            <Tooltip data={"Approve"}>
                                                                                <IconButton
                                                                                    className={classes.iconsButtonContainer}
                                                                                    aria-label="Approve"
                                                                                    onClick={() => { this.onRejectApproveTestCase(subC, false) }}
                                                                                    disabled={isSelected || false}
                                                                                >
                                                                                    <Gavel className={classes.moreIconsBtn} style={{ color: isSelected ? '#BAC4CE' : '#1babab' }} aria-label="gavelIcon" id="gavelIcon" />
                                                                                </IconButton>
                                                                            </Tooltip>
                                                                            <Tooltip data={"Reject"}>
                                                                                <IconButton
                                                                                    className={classes.iconsButtonContainer}
                                                                                    aria-label="Reject"
                                                                                    onClick={() => { this.onRejectApproveTestCase(subC, true) }}
                                                                                    disabled={isSelected || false}
                                                                                >
                                                                                    <FontAwesomeIcon
                                                                                        className={classes.moreIconsBtn}
                                                                                        icon={faTimes}
                                                                                        aria-label="faTimes"
                                                                                        id="faTimes"
                                                                                        style={isSelected ? { color: '#BAC4CE' } : {}}
                                                                                    />
                                                                                </IconButton>
                                                                            </Tooltip>
                                                                            <MenuOptions
                                                                                items={this.renderItems(subC, classes)}
                                                                                keepMounted={true}
                                                                                iconButtonDisabled={isSelected || false}
                                                                                containerStyle={containerStyle}
                                                                                MoreVertStyle={isSelected ? { color: '#BAC4CE' } : {}}
                                                                            />
                                                                        </div>
                                                                    </TableCell>
                                                                </TableRow>
                                                            );
                                                        },
                                                    ),
                                                );
                                        }
                                        /* eslint-disable no-unused-expressions */
                                        return [..._row];
                                    })}
                                </TableBody>
                            </Table>
                        ) : (
                                <Typography
                                    className={classes.noDataText}
                                >
                                    {
                                        isFiltering || testSuiteArrayMerged && !this.props.isError ? 'No Test cases available' : this.props.isError ? 'Faild to load test cases' : <CircularProgress style={{ position: 'absolute', color: '#4885ed' }} size={50} thickness={3} />
                                    }
                                </Typography>
                            )}
                    </div>
                </div>
                <TableFooter
                    count={testSuiteArrayMerged.length}
                    rowsPerPage={rowsPerPage}
                    rowsPerPageOptions={[5, 10, 20, 50]}
                    page={page}
                    handleChangePage={this.handleChangePage}
                    handleChangeRowsPerPage={this.handleChangeRowsPerPage}
                    applyCustomClasses={true}
                    labelRowsPerPage={"Rows per page:"}
                    labelForCounter={'Smart Maintenance'}
                    countAvatar={getCountAvatar(testSuiteArrayMerged.length)}
                    containerStyle={{ marginLeft: '-20px', marginRight: '-20px' }}
                />
                {
                    rejectApproveDialogOpen && isRejectApproveSelected ? (
                        <RejectApproveTestCase
                            handleClose={this.handleCloseRejectApproveDialog}
                            open={isRejectApproveSelected}
                            rejectApproveTestCaseData={rejectApproveTestCaseData}
                            isReject={isReject}
                            isRejectApproveAll={isRejectApproveAll}
                            isMultiple={true}
                            list={this.state.selectedTestCaseNames}
                        />
                    ) : null
                }
                {
                    rejectApproveDialogOpen && !isRejectApproveSelected ? (
                        <RejectApproveTestCase
                            deleteButton={'Danger'}
                            handleClose={this.handleCloseRejectApproveDialog}
                            open={rejectApproveDialogOpen}
                            rejectApproveTestCaseData={rejectApproveTestCaseData}
                            isReject={isReject}
                            isRejectApproveAll={isRejectApproveAll}
                            isMultiple={false}
                        />
                    ) : null
                }
                {
                    settingDialogOpen ? (
                        <SettingTestCase
                            handleClose={this.handleCloseSettingDialog}
                            changeHeaderStatus={this.changeHeaderStatus}
                            open={settingDialogOpen}
                            headers={headers}
                        />
                    ) : null
                }
                {
                    reviewDialogOpen ? (
                        <ReviewTestCase
                            handleClose={this.handleCloseReviewDialog}
                            open={reviewDialogOpen}
                            reviewTestCaseData={reviewTestCaseData}
                        />
                    ) : null
                }
                {
                    changedApisDialogOpen ? (
                        <ChangedApiSelectionDialog
                            filteredChangedApis={filteredChangedApis}
                            handleClose={this.toggleChangedApiDialog}
                            handleSearch={this.handleSearchCheckedApis}
                            handleSmartRun={this.handleSmartRun}
                            handleSingleCheck={this.handleSingleCheck}
                            isLoading={isLoading}
                            open={changedApisDialogOpen}
                            selectAllApis={selectAllApis}
                            toggleAllCheck={this.toggleAllCheck}
                            searchedApiValue={searchedApiValue}
                        />
                    ) : null
                }
            </div>
        );
    };

    goToCaseDetail = (testCase) => {
        // Prevously we were fecthing from satte but now we are fetching from url in modal
        this.props.history.push({
            pathname: `/details/${testCase.projectId}/${testCase.testcaseId}`
        })
    };

    render() {
        const { classes } = this.props;
        // const {
        // } = this.state;
        // const icon = <People_icon className={classes.menuButton} />;
        return (
            <div>
                <div className={classes.headerContainer}>
                    <div className={classes.headerButtonContainer}>
                        <div style={{ display: 'flex' }}>
                            <div
                                style={{ marginRight: '10px' }}
                                className={classes['apiButtonSubContainers']}
                                onClick={() => this.toggleChangedApiDialog()}
                            >
                                <Typography>Select Change API</Typography>
                            </div>
                            <div
                                style={{ marginRight: '10px' }}
                                className={classes[this.state.isAnyCheck ?
                                    'apiButtonSubContainers' : 'apiButtonSubContainersDisabled'
                                ]}
                                onClick={() => {
                                    if (this.state.isAnyCheck)
                                        this.handleSmartRun();
                                }}
                            >
                                <Typography >Trigger Smart Run</Typography>
                            </div>
                        </div>
                        <IconButton
                            aria-label="Setting"
                            onClick={this.onSettinTestCase}
                            style={{
                                color: '#003d9d',
                                height: "30px",
                                width: "30px",
                            }}
                        >
                            <Settings aria-label="settingsIcon" id="settingsIcon" />
                        </IconButton>
                    </div>
                </div>
                {this.tableBodyRender()}
            </div>
        );
    }
}
const mapStateToProps = (state) => {
    return {
        isLoading: state.projectReducer.isLoading,
        isError: state.projectReducer.isError,
        alertTestCasesWithAccount: state.projectReducer.alertTestCasesWithAccount,
        changedApis: state.projectReducer.changedApis,
        user: state.authReducer.user,
        query: state.generalReducer.queryValue,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        getAlertTestCasesWithAccount: (accountId) =>
            dispatch(ProjectActions.getAlertTestCasesWithAccount(accountId)),
        getChangedApis: (accountId) =>
            dispatch(ProjectActions.getChangedApis(accountId)),
        rejectApproveAlertTestCase: (...args) =>
            dispatch(ProjectActions.rejectApproveAlertTestCase(...args)),
        rejectApproveAllAlertTestCase: (...args) =>
            dispatch(ProjectActions.rejectApproveAllAlertTestCase(...args)),
        initiateSmartRun: (...args) =>
            dispatch(ProjectActions.initiateSmartRun(...args)),
        // General Action
        queryRemove: () => dispatch(GeneralActions.queryRemove()),
        updateTitle: (...args) => dispatch(GeneralActions.updateTitle(...args)),

    };
};

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(withStyles(styles)(SmartMaintenance));
