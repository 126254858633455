import React from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import { withStyles } from '@material-ui/core/styles';
import FormControl from '@material-ui/core/FormControl';
import Typography from '@material-ui/core/Typography';
import Avatar from '@material-ui/core/Avatar';
import Approve from 'mdi-material-ui/Gavel';
import { DIALOG_ROOT, MODAL_HEADER_CLOSE_ICON_CONT, MODAL_HEADER_CLOSE_ICON, MODAL_HEADER_TITLE, MODAL_FOOTER, MODAL_CANCEL_BUTTON } from '../../../common/cssConstants';
import Close from '@material-ui/icons/Close';
import CloseOnEscape from 'react-close-on-escape';

const styles = (theme) => ({
    dialogTitleStyle: MODAL_HEADER_TITLE,
    dialog: {
        textAlign: 'center',
        backgroundColor: '#e9effd',
        borderBottom: '1px solid #dcdcdc',
    },
    actionBtn: {
        padding: '2px 8px',
        minHeight: 0,
        borderColor: '#1067ce',
        textTransform: 'capitalize',
        borderRadius: '6px',
        minWidth: '75px',
    },
    formControl: {
        width: '100%',
        flexDirection: 'row',
        padding: '0 30px',
        borderBottom: '1px solid #dcdcdc',
        minHeight: '100px',
        display: 'flex',
        alignItems: 'center',
    },
    dialogContent: {
        overflow: 'hidden',
        padding: '0',
        [theme.breakpoints.up('sm')]: {
            width: 500,
        }
    },
    dialogModal: {
        "&>div": {
            borderRadius: '0px',
        },
        ...DIALOG_ROOT,
    },
    moreIconsBtn: {
        color: '#003d9d',
        fontSize: '20px',
        marginBottom: '-6px'
    },
});

const ReviewTestCase = ({
    classes,
    handleClose,
    open,
    reviewTestCaseData,
}) => {
    return (
        <div>
            <Dialog
                disableRestoreFocus
                open={open}
                aria-labelledby="form-dialog-title"
                className={classes.dialogModal}
            >
                <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                    <DialogTitle id="form-dialog-title" classes={{ root: classes.dialogTitleStyle }}>
                        Review Smart Maintenance Proposal
                    </DialogTitle>
                    <div style={MODAL_HEADER_CLOSE_ICON_CONT}>
                        <Close aria-label="closeIcon" id="closeIcon" style={MODAL_HEADER_CLOSE_ICON} onClick={() => handleClose()} />
                    </div>
                </div>
                <DialogContent className={classes.dialogContent}>
                    <FormControl className={classes.formControl}>
                        <Avatar
                            style={{
                                margin: '0',
                                color: '#fff',
                                backgroundColor: '#6094ff',
                                marginRight: '20px',
                            }}
                        >1</Avatar>
                        <Typography style={{ color: 'rgba(0, 0, 0, 0.87)', wordBreak: 'break-all' }}>
                            <strong>{reviewTestCaseData.message}</strong>
                        </Typography>
                    </FormControl>
                    <FormControl className={classes.formControl}>
                        <Avatar
                            style={{
                                margin: '0',
                                color: '#fff',
                                backgroundColor: '#6094ff',
                                marginRight: '20px',
                            }}
                        >2</Avatar>
                        <Typography style={{ color: 'rgba(0, 0, 0, 0.87)' }}>
                            Do you approve changes to be made to those test cases?<br /> click on the gavel ( <Approve className={classes.moreIconsBtn} /> ) after closing this modal if you do.
                            </Typography>
                    </FormControl>
                </DialogContent>
                <DialogActions style={MODAL_FOOTER}>
                    <CloseOnEscape onEscape={() => handleClose()}>
                        <Button
                            variant="outlined"
                            color="secondary"
                            onClick={() => handleClose()}
                            style={MODAL_CANCEL_BUTTON}
                        >
                            Close
                        </Button>
                    </CloseOnEscape>
                </DialogActions>
            </Dialog>
        </div>
    );
};
export default withStyles(styles)(ReviewTestCase);
