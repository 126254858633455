import React from 'react';

// import materials
import { withStyles } from '@material-ui/core/styles';
import Check from '@material-ui/icons/Check';
import Close from '@material-ui/icons/Close';
import ContentLoader from '../ContentLoader';

const ChartFooter = ({ classes, successCount, failCount, isLoading }) => {
    return (
        <div className={classes.footerCont}>
            <div>
                {isLoading ? (
                    <ContentLoader height={65} width={65} />
                ) : (
                    <span className={classes.countCont}>
                        <Check style={{ fontSize: '36px', color: '#00B330' }} aria-label="checkIcon" id="checkIcon" />
                        <p className={classes.countStyle} style={{ color: '#00B330' }}>
                            {successCount}
                        </p>
                    </span>
                )}
            </div>
            <div>
                {isLoading ? (
                    <ContentLoader height={65} width={65} />
                ) : (
                    <span className={classes.countCont}>
                        <Close style={{ fontSize: '36px', color: '#E8001C' }} aria-label="closeIcon" id="closeIcon" />
                        <p className={classes.countStyle} style={{ color: '#E8001C' }}>
                            {failCount}
                        </p>
                    </span>
                )}
            </div>
        </div>
    );
};

const styles = () => ({
    countStyle: {
        fontSize: '28px',
        lineHeight: '37px',
        marginTop: '-2px',
        marginBottom: '0px',
    },
    footerCont: {
        display: 'flex',
        justifyContent: 'space-between',
        width: '70%',
        margin: 'auto',
        marginTop: 12,
    },
    countCont: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
    },
});

export default withStyles(styles)(ChartFooter);
