import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import withBreadcrumbs from 'react-router-breadcrumbs-hoc';
import { faArrowCircleLeft } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
// import { ProjectName, TestCaseName } from './CustomBreadCrumb'; // Removed project/testCase name requested in (WebUIRedux/issues/1763)
import { Link } from 'react-router-dom';
import { checkArrayLength, checkKeyInObject, toTitleCase } from '../../utils';

const styles = () => ({
    links: {
        cursor: 'pointer',
        color: '#9b9b9b',
        textDecoration: 'none',
    },
    disableLink: {
        cursor: ' not-allowed',
        color: '#9b9b9b',
        textDecoration: 'none',
    },
    activeLink: {
        cursor: 'pointer',
        color: '#9b9b9b',
        fontWeight: 'bold',
        textDecoration: 'none',
    },
    breadcrumbSeperator: {
        color: '#9b9b9b',
        margin: '0px 5px',
    },
    breadcrumbsMainDiv: {
        position: 'absolute',
        top: '18px',
    },
    backMainDiv: {
        display: 'inline-block',
        fontSize: '10px',
        paddingLeft: '14px',
        paddingTop: '10px',
    },
    backIcon: {
        color: '#4A4A4A',
        fontSize: '2em',
        cursor: 'pointer',
    },
    noSelect: {
        '-webkit-touch-callout': 'none',
        '-webkit-user-select': 'none',
        '-khtml-user-select': 'none',
        '-moz-user-select': 'none',
        '-ms-user-select': 'none',
        userSelect: 'none',
    },
    breadcrumbText: {
        fontSize: '0.8em',
    },
    transform: {
        fontSize: '0.8em',
        textTransform: 'uppercase',
    },
});

const routes = [
    { path: '/analyze/smartmaintenance', breadcrumb: 'Smart Maintenance' },
    { path: '/analyze/alertdetails', breadcrumb: 'Alert Details' },
    { path: '/analyze/alerts/detail/:alertId', breadcrumb: 'Detail' },
    { path: '/admin/accounts', breadcrumb: 'Accounts' },
    { path: '/plan/testcases', breadcrumb: 'Test Cases' },
    { path: '/plan/testsuites', breadcrumb: 'Test Suites' },
    { path: '/executions/schedule', breadcrumb: 'Schedules' },
    { path: '/details', breadcrumb: 'Plan%sep%Projects' },
    /* { path: '/details/:projectId', breadcrumb: <ProjectName /> }, // Removed project name requested in (WebUIRedux/issues/1763) */
    { path: '/details/:projectId', breadcrumb: 'Project Details' },
    { path: '/details/:projectId/cases', breadcrumb: 'Cases' },
    { path: '/details/:projectId/suites', breadcrumb: 'Suites' },
    { path: '/details/:projectId/data', breadcrumb: 'Data' },
    { path: '/details/:projectId/scripts', breadcrumb: 'Scripts' },
    { path: '/details/:projectId/variables', breadcrumb: 'Variables' },
    { path: '/details/:projectId/flows', breadcrumb: 'Flows' },
    { path: '/details/:projectId/data', breadcrumb: 'Data' },
    { path: '/details/:projectId/data/datasheet/:testDataId', breadcrumb: 'DataSheet' },
    { path: '/details/:projectId/:testCaseId/executions', breadcrumb: 'Executions' },
    /* { path: '/details/:projectId/:testCaseId', breadcrumb: <TestCaseName /> }, // Removed test case name requested in (WebUIRedux/issues/1763) */
    { path: '/details/:projectId/:testCaseId', breadcrumb: 'Step Editor' },
    { path: '/details/:projectId/:testCaseId/executions/:executionId', breadcrumb: 'Execution Steps' },
    { path: '/executions/reports/suite/:suiteId', breadcrumb: 'Execution History' },
];
const options = {
    // this excludePaths is for hide specific paths
    excludePaths: ['/', '/executions/reports/suite'],
    disableDefaults: false,
};
// this excludePaths is for disable specific paths
const excludePaths = ['/plan', '/analyze', '/executions', '/details/:projectId/:testCaseId/executions'];

// this hidePaths is for hide specific paths if they contain variables
const hidePaths = [
    '/details/:projectId/suites/:modalObjectId/:modalName',
    '/details/:projectId/data/:modalObjectId/:modalName',
    '/details/:projectId/scripts/:modalObjectId/:modalName',
    '/details/:projectId/suites/:modalObjectId',
    '/details/:projectId/data/:modalObjectId',
    '/details/:projectId/scripts/:modalObjectId',
    '/analyze/alerts/detail',
];

const hidePathByCondition = [
    [
        '/details/:projectId/:testCaseId',
        (next) => {
            return !(next && next.match.path === '/details/:projectId/:testCaseId/executions');
        },
    ],
];

// This function finds "details/4/660/executions" in "excludePaths" array
const includeRoute = (arrayOfString, find) => {
    if (checkArrayLength(arrayOfString) && typeof find === 'string') {
        let _arrayOfString = [...arrayOfString]
        if(find.includes("/details/:projectId/data/datasheet/:testDataId")){
            _arrayOfString = _arrayOfString.filter((elem) => !elem.includes('/details/:projectId/data/:modalObjectId/:modalName'))
        }
        return _arrayOfString.some((string) => {
            let isMatch = false;
            if (string !== '' && find !== '') {
                const stringArr = string.split('/');
                const findArr = find.split('/');
                if (stringArr.length === findArr.length) {
                    isMatch = !stringArr.some((_string, i) => {
                        return _string !== findArr[i] && !(_string.includes(':') && _string.indexOf(':') === 0);
                    });
                }
            } else {
                isMatch = true;
            }
            return isMatch;
        });
    }
    return false;
};

let historyPosition = -1;
let historyStart = -1;
let historyLength = -1;
let isFirstPush = true;
let isBackBtnCall = false;
const goBack = (history) => {
    if (historyPosition > historyStart) {
        history.goBack();
        historyPosition -= 1;
        isBackBtnCall = true;
    }
};

const Breadcrumbs = ({ breadcrumbs, history, forceUpdateBackButton, update_ForceUpdateBackButtonFlag, classes }) => {
    if (historyStart === -1 || forceUpdateBackButton || historyLength < historyStart) {
        update_ForceUpdateBackButtonFlag(false);
        historyStart = history.length;
        historyPosition = history.length;
        historyLength = history.length;
        isFirstPush = true;
    } else if (!isBackBtnCall && history.action === 'POP') {
        historyPosition -= 1;
        isBackBtnCall = true;
    }
    if (historyLength !== history.length || history.action === 'PUSH' || history.action === 'REPLACE') {
        historyLength = history.length;
        historyPosition = history.length;
        if (isFirstPush && history.action === 'PUSH' && historyLength === historyStart && historyPosition === historyStart) {
            historyStart--;
            isFirstPush = false;
        }
    }
    return (
        <div>
            {historyPosition > historyStart ? (
                <div onClick={() => goBack(history)} className={classes.backMainDiv} aria-hidden>
                    <FontAwesomeIcon icon={faArrowCircleLeft} aria-label="faArrowCircleLeft" id="faArrowCircleLeft" className={classes.backIcon} />
                </div>
            ) : null}
            <div className={classes.breadcrumbsMainDiv} style={{ marginLeft: historyPosition > historyStart ? '45px' : '13px' }}>
                {breadcrumbs.map((breadcrumb, index) => {
                    if (
                        checkKeyInObject(breadcrumb, 'breadcrumb.props.children') &&
                        breadcrumb.breadcrumb.props.children.includes &&
                        breadcrumb.breadcrumb.props.children.includes('%sep%')
                    ) {
                        const noRouteName = breadcrumb.breadcrumb.props.children.split('%sep%');
                        return noRouteName.map((name, ind) => {
                            return (
                                <span key={`${breadcrumb.key}_${ind}`} className={classes.transform}>
                                    {(index > 0 || ind > 0) && <span className={`${classes.breadcrumbSeperator}`}> | </span>}
                                    {ind < noRouteName.length - 1 ? (
                                        <span className={`${classes.disableLink}`}>{name ? toTitleCase(name) : ''}</span>
                                    ) : (
                                        <Link
                                            className={`${index === breadcrumbs.length - 1 ? `${classes.activeLink}` : `${classes.links}`}`}
                                            to={breadcrumb.match.url}
                                        >
                                            {name ? toTitleCase(name) : ''}
                                        </Link>
                                    )}
                                </span>
                            );
                        });
                    }
                    return !includeRoute(hidePaths, breadcrumb.match.path) &&
                        hidePathByCondition.filter((path) => path[0] !== breadcrumb.match.path || path[1](breadcrumbs[index + 1])).length > 0 ? (
                        <span key={breadcrumb.breadcrumb.key} className={classes.transform}>
                            {/* includeRoute(excludePaths, breadcrumb.match.url, breadcrumb.match) */}
                            {index > 0 && <span className={`${classes.breadcrumbSeperator}`}> | </span>}
                            {excludePaths.includes(breadcrumb.match.path) ? (
                                <span className={`${classes.disableLink}`}>{breadcrumb.breadcrumb}</span>
                            ) : (
                                <Link
                                    className={`${index === breadcrumbs.length - 1 ? `${classes.activeLink}` : `${classes.links}`}`}
                                    to={`${breadcrumb.match.url}${
                                        checkKeyInObject(breadcrumb, 'match.path') === '/details/:projectId' ||
                                        checkKeyInObject(breadcrumb, 'props.children') === 'Project Details'
                                            ? '/cases'
                                            : ''
                                    }`}
                                >
                                    {breadcrumb.breadcrumb}
                                </Link>
                            )}
                        </span>
                    ) : null;
                })}
            </div>
        </div>
    );
};

export default withStyles(styles)(withBreadcrumbs(routes, options)(Breadcrumbs));
