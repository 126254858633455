// import materials
import { withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import CheckBox from '@material-ui/icons/CheckBox';
import CheckBoxBlank from '@material-ui/icons/CheckBoxOutlineBlank';
import CircularProgress from '@material-ui/core/CircularProgress';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import Typography from '@material-ui/core/Typography';
import SearchInput from '../../modal/GeneralModal/SearchInput';
import { DialogTop } from './../../../components/Modal/ModalTop'
import GeneralModalFooter from './../../modal/GeneralModal/GeneralModalFooter'
// import packages
import React from 'react';
// Keyboard input comprehension node modules
import CloseOnEscape from 'react-close-on-escape';

// import icons
import { DIALOG_ROOT, MODAL_FOOTER, MODAL_SUBMIT_BUTTON, MODAL_CANCEL_BUTTON, TABLES_CSS } from '../../../common/cssConstants';


const styles = (theme) => ({
    actionBtn: {
        padding: '2px 8px',
        minHeight: 0,
        borderColor: '#1067ce',
        textTransform: 'capitalize',
        borderRadius: '6px',
        minWidth: '75px',
        fontSize: 12,
    },
    listContainerr: {
        borderRadius: 5,
        overflow: 'hidden',
        boxShadow: '0 2px 7px 0 rgba(0,0,0,0.2)',
    },
    noWrap: {
        overflow: 'hidden',
        whiteSpace: 'nowrap',
        textOverflow: 'ellipsis',
        maxWidth: '100%',
    },
    circularProgress: {
        height: 'calc(100vh - 312px)',
        width: 'inherit',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
    },
    dialogContent: {
        overflow: 'hidden',
        overflowY: 'auto',
        padding: '0',
        [theme.breakpoints.up('sm')]: {
            width: 500,
        }
    },
    dialogModal: {
        "&>div": {
            borderRadius: '0px',
        },
        ...DIALOG_ROOT,
    },
    emptyMessageStyle: {
        color: '#3c3c3c',
        textAlign: 'center',
        fontSize: 12,
    },
    listContainer: {
        maxHeight: 'calc(100vh - 313px)',
        overflow: 'hidden',
        overflowY: 'scroll',
        marginTop: 10,
        padding: '10px 30px 5px 30px',
    },
    row: {
        cursor: 'default',
        display: 'inline-flex',
        width: '100%',
        borderCollapse: 'collapse',
        borderBottom: '1px solid rgb(224, 224, 224)',
        outline: 'none',
        '&:nth-of-type(odd)': {
            backgroundColor: '#fafafa',
        }
    },
    subHeadingContainer: {
        alignItems: 'flex-end',
        display: 'flex',
        justifyContent: 'space-between',
        padding: '0px 30px 0px 30px;',
    },
    checkBoxIcon: {
        color: '#0092e5',
        fontSize: '20px',
    },
    checkBoxSpan: {
        cursor: 'pointer',
        height: '40px',
        width: '40px',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        paddingLeft: 20,
    },
    circleIndeterminate: {
        animation: 'none',
    },
    inputStyle: {
        width: '40%',
        [theme.breakpoints.only('xs')]: {
            width: `calc(100vw - 280px)`
        },
        '&>div': {
            '&:before': {
                borderColor: '#979797 !important'
            },
            '&:after': {
                borderColor: '#979797 !important'
            }
        }
    },
});

const lazyLoadingStep = 20;

class ChangedApiSelectionDialog extends React.Component {
    state = {
        isFirstLoad: true,
        rowsPerPage: lazyLoadingStep,
    }

    renderApis = (filteredChangedApis) => {
        const { classes, handleSingleCheck } = this.props;
        const data = filteredChangedApis.map((item, index) => (
            <div className={classes.row} key={index}>
                <span onClick={() => { handleSingleCheck(index, !item.checked) }} className={classes.checkBoxSpan}>
                    {item.checked ?
                        <CheckBox
                            className={classes.checkBoxIcon} style={item.checked ? { color: '#36aebd' } : {}}
                            aria-label="checkBoxIcon"
                            id="checkBoxIcon"
                        /> :
                        <CheckBoxBlank
                            className={classes.checkBoxIcon}
                            style={{ color: '#bac5d0' }}
                            aria-label="checkBoxBlankIcon"
                            id="checkBoxBlankIcon"
                        />
                    }
                </span>
                <p
                    style={{
                        fontSize: TABLES_CSS.normalFontSize,
                        display: 'inline-block',
                        padding: '0 10px',
                    }}
                    className={classes.noWrap}
                >
                    {item.api}
                </p>
            </div>
        ));
        return data;
    }

    changeScroll = (e) => {
        const { rowsPerPage } = this.state;
        const { filteredChangedApis } = this.props;
        if (e.target.scrollHeight - e.target.scrollTop <= e.target.clientHeight && rowsPerPage < filteredChangedApis.length) {
            this.setState({
                rowsPerPage: rowsPerPage + lazyLoadingStep,
            });
        }
    };

    render() {
        const {
            classes,
            filteredChangedApis,
            handleClose,
            handleSearch,
            isLoading,
            open,
            selectAllApis,
            toggleAllCheck,
            searchedApiValue,
        } = this.props;
        const {
            isFirstLoad,
            rowsPerPage,
        } = this.state;
        let isApisAvailable = filteredChangedApis && Array.isArray(filteredChangedApis) && filteredChangedApis.length > 0;
        const _filteredChangedApis = filteredChangedApis.slice(0, rowsPerPage);
        if (isFirstLoad) {
            setTimeout(() => {
                this.setState({
                    isFirstLoad: false
                });
            }, 100);
            isApisAvailable = false;
        }
        return (
            <div>
                <Dialog
                    disableRestoreFocus
                    open={open}
                    aria-labelledby="form-dialog-title"
                    className={classes.dialogModal}
                >
                    <DialogTop
                        title='Select Change API'
                        closeIconAction={handleClose}
                    />
                    <DialogContent className={classes.dialogContent}>
                        <div className={classes.subHeadingContainer}>
                            <div>
                                <Button
                                    color="secondary"
                                    className={classes.actionBtn}
                                    onClick={() => toggleAllCheck('check')}
                                    disabled={selectAllApis === 'check' || !isApisAvailable}
                                >Select All</Button>
                                <Typography style={{ display: 'inline', color: '#1168cd' }}>|</Typography>
                                <Button
                                    color="secondary"
                                    className={classes.actionBtn}
                                    onClick={() => toggleAllCheck('unCheck')}
                                    disabled={selectAllApis === 'unCheck'}
                                >Clear All</Button>
                            </div>
                            <SearchInput
                                id="ChangedApiSelectionDialog"
                                placeholder="Search"
                                onChange={(e) => handleSearch(e.target.value)}
                                value={searchedApiValue}
                                clearSearch={() => { handleSearch("") }}
                                classStyle={classes.inputStyle}
                            />

                        </div>
                        {!isLoading || isApisAvailable ? (
                            <div className={classes.listContainer} onScroll={this.changeScroll}>
                                <div className={classes.listContainerr}>
                                    {isApisAvailable ?
                                        this.renderApis(_filteredChangedApis) :
                                        (
                                            <div className={classes.row} style={{ justifyContent: 'center' }}>
                                                <p className={classes.emptyMessageStyle}>
                                                    No Api to show
                                        </p>
                                            </div>
                                        )}
                                </div>
                            </div>
                        ) : (
                                <div className={classes.circularProgress}>
                                    <CircularProgress
                                        style={{ color: '#4885ed' }}
                                        size={60}
                                        classes={{ circleIndeterminate: classes.circleIndeterminate }}
                                    />
                                </div>
                            )}
                    </DialogContent>
                    <DialogActions style={MODAL_FOOTER}>
                        <CloseOnEscape onEscape={() => { toggleAllCheck('unCheck'); handleClose() }}>
                            <GeneralModalFooter
                                buttons={[{
                                    name: 'OK',
                                    // variant: "raised",
                                    style: { ...MODAL_SUBMIT_BUTTON },
                                    action: () => { handleClose(true) },
                                    isDisabled: false
                                }, {
                                    name: 'Close',
                                    variant: "outlined",
                                    style: { ...MODAL_CANCEL_BUTTON },
                                    action: () => { toggleAllCheck('unCheck'); handleClose() },
                                    isDisabled: false
                                }]}
                            />
                        </CloseOnEscape>
                    </DialogActions>
                </Dialog>
            </div>
        );
    }
}

export default withStyles(styles)(ChangedApiSelectionDialog);