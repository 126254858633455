// import material
import { withStyles } from '@material-ui/core/styles';
import { connect } from 'react-redux';
import Button from '@material-ui/core/Button';
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import TextField from '@material-ui/core/TextField';
import { IconButton,  CircularProgress } from '@material-ui/core';
import Radio from '@material-ui/core/Radio';
import AddIcon from '@material-ui/icons/Add';
import CloseIcon from '@material-ui/icons/Close';
// import packages
import React from 'react';
import PropTypes from 'prop-types';

// import icon
import { CustomSwitch } from '../../common/miniComponents';
import { SEGMENT_EVENT, USER_NAME } from '../../common/constants';
import { ActionTypes } from '../../store/constants';
import { AccountActions, ModalActions } from '../../store/actions';
import PlatformBrowserSelectionContainer from '../../features/projectDetails/Script/PlatformBrowserSelectionContainer';
import { getSelectedDevice, getDevicesSupported } from '../../utils/ExecuteScriptModalUtils';
import { ExecuteScriptModalConstant } from '../../features/projectDetails/Script/constant';
import { TestScriptUtils, checkIsArrayHasDuplicateElements } from '../../utils';
import {
    checkArrayLength,
    checkKeyInObject,
    removeIndexFromArray,
    pushInMidArray,
    checkIsArray,
} from '../../utils/utils';
import defaultConfig from '../../config';
import { track } from '../../services/Segment';

import styles from './ExecutionSettingStyles';
import SaucelabsExecutionEnvironmentSettings from './SaucelabsExecutionEnvironmentSettings';
const {  PlatformBasedBrowsers } = ExecuteScriptModalConstant;
let { Platform } = ExecuteScriptModalConstant;
let _localPlatform = Platform;
const { Browser, MappedOSX, MappedOSXVersion } = ExecuteScriptModalConstant;

class ExecutionSetting extends React.Component {
    constructor(props) {
        super(props);
        this.PlatformBasedBrowsers = JSON.parse(JSON.stringify(PlatformBasedBrowsers));
        this.ModalType =  checkKeyInObject(checkKeyInObject(props.prefences,'accounts'),'executionEngine') || localStorage.getItem('executionEngine');
        this.state = {
            anchorEl: [null, null],
            browserDialog: [false, false],
            browserDropDownData: [Browser, Browser],
            platformDialog: [false, false],
            selectedBrowsers: ['Chrome(headful)'],
            selectedExecutionMode: 'Parallel',
            selectedPlatform: ['Linux'],
            selectedBrowsersForSauce:[],
            selectedDevicesForSauce:[],
            selectedTab: [0, 0],
            showSelectedExecutionModeDialog: false,
            tag: '',
            use_remote_driver: false,
            url: '',
            expanded:'',
            saucelabsExpand:false,
            localExpand:false,
            externalGridExpand:false,
            edited:false,
            saucelabsUsed:false,
            sauceDetails:{},
            externalGrid: [{
                platform: '',
                platformVersion: '',
                browser: '',
                browserVersion: ''
            }],
            defaultSelect:'0_saucelabsExpanded',
            externalGridUrl:'',
            saucelabs_capabliites:{}
        };
        
    }

    async componentDidMount() {

        const { getEnvironmentConfig, user, getsupportedEnvironments } = this.props;

        let supportedEnv = await getsupportedEnvironments();

        if(supportedEnv && supportedEnv.payload && supportedEnv.payload.environments) {
            let sauce_env = supportedEnv.payload.environments.find((se)=>{
                return se.envType === 'Saucelabs'
            })

            if(sauce_env) {
                this.setState({
                    saucelabs_capabliites:sauce_env.capabilities_v1
                })

                if(sauce_env.capabilities_v1.devices_capabilities_list) {
                    const default_selectedDevicesForSauce = [];
                    default_selectedDevicesForSauce.push(sauce_env.capabilities_v1.devices_capabilities_list[0])
                    this.setState({
                        selectedDevicesForSauce:default_selectedDevicesForSauce
                    })
                }
                if(sauce_env.capabilities_v1.browsers_capabilities_list) {
                    const default_selectedBrowsersForSauce = [];
                    default_selectedBrowsersForSauce.push(sauce_env.capabilities_v1.browsers_capabilities_list[0])
                    this.setState({
                        selectedBrowsersForSauce:default_selectedBrowsersForSauce
                    })
                }
            }
        }

        getEnvironmentConfig(user.accountId).then((res)=>{
            if(res && res.payload) {
                this.setupData(res.payload)
            }
        });
    }

    setupData = (environmentConfig) => {
        const { selectedBrowsers, selectedPlatform,  defaultSelect} = this.state;
        let _selectedBrowser =[];
        let _selectedPlatform = [];

        let _defaultSelect = '';
        let _saucelabsExpand = false;
        let _localExpand = false;
        let _externalGridExpand = false;
        let _externalGridUrl = '';
        const browserDropDownData = this.makeStateCopy('browserDropDownData');
        let _externalGrid =[]
        const externalGrid = this.makeStateCopy('externalGrid');
        const _selectedBrowserForSauce = []
        const _selectedDevicesForSauce = [];

        
        if(environmentConfig && Array.isArray(environmentConfig) && environmentConfig.length && checkKeyInObject(environmentConfig[0],'environments')){
            environmentConfig[0].environments.forEach((item,index)=>{
                if(item.environmentType && item.environmentType.toLowerCase() === 'zalenium' ){
                    if(checkKeyInObject(item,'environment')){
                        Object.keys(item.environment).forEach((data,i)=>{

                            if(data === "platformDetails"){
                                item.environment[data].forEach((details,ind)=>{
                                    let browser = details.browser ? details.browser.toLowerCase() :''
                                    _selectedBrowser.push(browser === 'chrome' ? 'Chrome(headful)': 'Firefox(headful)');
                                    let platform = details.platform ? details.platform.charAt(0).toUpperCase() + details.platform.slice(1) :''
                                    _selectedPlatform.push(platform);
                                    if(details.isDefault === true ){
                                        _defaultSelect = `${_selectedPlatform.length -1}_headlessExpanded`
                                    } 
                                })
                            }
                        })
                    }
                }
                if(item.environmentType && item.environmentType.toLowerCase() === 'local'){
                    _localExpand = true;
                    if(checkKeyInObject(item,'environment')){
                        Object.keys(item.environment).forEach((data,i)=>{
                            if(data === "platformDetails"){
                                item.environment[data].forEach((details,ind)=>{
                                    let browser = details.browser ? details.browser.toLowerCase() :''
                                    _selectedBrowser.push(browser === 'chrome' ? 'Chrome(headless)': 'Firefox(headless)');
                                    let platform = details.platform ? details.platform.charAt(0).toUpperCase() + details.platform.slice(1) :''
                                    _selectedPlatform.push(platform);
                                    if(details.isDefault === true){
                                        _defaultSelect = `${_selectedPlatform.length -1}_headlessExpanded`
                                    } 
                                })
                            }
                        })
                    }
                }
                
                if(item.environmentType && item.environmentType.toLowerCase() === 'remote'){
                    _externalGridExpand = true;
                    if(checkKeyInObject(item,'environment')){
                        Object.keys(item.environment).forEach((data,i)=>{
                            if(data === 'platformDetails'){
                                item.environment[data].forEach((details,ind)=>{
                                    if(details.isDefault === true){
                                        _defaultSelect = `${ind}_externalGrid`
                                    } 
                                    let data = {
                                        platform:details.platform || '',
                                        browser:details.browser || '',
                                        browserVersion:details.browserVersion || '',
                                        platformVersion:details.platformVersion || '',
                                        _defaultSelect : _defaultSelect,
                                    }
                                    _externalGrid.push(data);
                                })
                            } else if(data === 'gridUrl') {
                                _externalGridUrl = item.environment[data]
                            }
                        })
                    }
                }

                //setup selected browsers for saucelabs
                if(item.environmentType && item.environmentType.toLowerCase() === 'saucelabs'){
                    _saucelabsExpand = true;
                    if(checkKeyInObject(item,'environment')){
                        Object.keys(item.environment).forEach((data,i)=>{
                            if(data === "platformDetails"){
                                item.environment[data].forEach((details,ind)=>{
                                    if(!details.id) {
                                        details.id = `${details.platform}_${details.platformVersion}_${details.browser}_${details.browserVersion}`;
                                    }
                                    _selectedBrowserForSauce.push(details)
                                    if(details.isDefault === true){
                                        _defaultSelect = `${_selectedBrowserForSauce.length -1}_saucelabsExpanded`
                                    } 
                                })
                            }
                        })
                    }
                }

                //setup selected devices for saucelabs
                if(item.environmentType && item.environmentType.toLowerCase() === 'saucelab_devices'){
                    _saucelabsExpand = true;
                    if(checkKeyInObject(item,'environment')){
                        Object.keys(item.environment).forEach((data,i)=>{

                            if(data === "platformDetails"){
                                item.environment[data].forEach((details,ind)=>{
                                    if(!details.id) {
                                        details.id = `${details.platform}_${details.platformVersion}_${details.browser}_${details.browserVersion}`;
                                    }
                                    if(!details.deviceName) {
                                        details.deviceName = details.device;
                                    }
                                    _selectedDevicesForSauce.push(details)
                                    if(details.isDefault === true){
                                        _defaultSelect = `${_selectedDevicesForSauce.length -1}_saucelabsDeviceExpanded`
                                    } 
                                })
                            }
                        })
                    }
                }
            })

            if(_selectedPlatform.length > 1){
                for (let key = 0; key < _selectedPlatform.length; key++) {
                    browserDropDownData[key] = this.PlatformBasedBrowsers[_selectedPlatform[key]];
                }
            }

            

            this.updateState({ 
                selectedBrowsersForSauce:_selectedBrowserForSauce.length?_selectedBrowserForSauce:this.state.selectedBrowsersForSauce,
                selectedDevicesForSauce:_selectedDevicesForSauce.length ? _selectedDevicesForSauce : this.state.selectedDevicesForSauce,
                selectedPlatform: _selectedPlatform.length >= 1 ? _selectedPlatform: selectedPlatform , 
                selectedBrowsers:_selectedBrowser.length >= 1 ? _selectedBrowser : selectedBrowsers, 
                externalGrid:_externalGrid.length >= 1 ? _externalGrid : externalGrid, 
                defaultSelect : _defaultSelect !== '' ? _defaultSelect : defaultSelect,
                browserDropDownData,
                externalGridUrl:_externalGridUrl,
            },()=>{
                if(_saucelabsExpand) {
                    this.handleExpand('saucelabsAssets')
                }
                if(_localExpand) {
                    this.handleExpand('headlessAssets')
                }

                if(_externalGridExpand) {
                    this.handleExpand('externalGridAssets')
                }
                

                this.setState({
                    dataReady:true,
                    saucelabsExpand:_saucelabsExpand || false,
                    externalGridExpand:_externalGridExpand || false,
                    localExpand:_localExpand || false
                });
                
            });

        } else if(environmentConfig && Array.isArray(environmentConfig) && environmentConfig.length === 0){

            this.handleExpand('headlessAssets')
            this.setState({
                dataReady:true,
                defaultSelect:'0_headlessExpanded'
            });    

        }

    }

    
    onClickPlatformBrowserContainer = (key, e, callingFrom = 'browser') => {
        const { browserDialog, platformDialog, selectedTab } = this.state;
        const obj = {
            anchorEl: this.makeStateCopy('anchorEl', e && e.currentTarget, key),
            showSelectedExecutionModeDialog: false,
            selectedTab: this.makeStateCopy('selectedTab', this.ModalType === defaultConfig.executionEngine ? selectedTab[key] : 0, key),
        };

        if (callingFrom === 'Platform') {
            obj.platformDialog = this.makeStateCopy('platformDialog', !platformDialog[key], key);
            obj.browserDialog = this.makeStateCopy('browserDialog', false, key);
        } else {
            // calling from browser
            obj.platformDialog = this.makeStateCopy('platformDialog', false, key);
            obj.browserDialog = this.makeStateCopy('browserDialog', !browserDialog[key], key);
        }
        

        this.setState(obj);
    };



    handleExpand = (panel,userClicked=false) => {
        const { selectedPlatform, saucelabsExpand, localExpand, externalGridExpand } = this.state;
        const saucelabsExpanded = panel === 'saucelabsAssets';
        const localExpanded = panel === 'headlessAssets';
        const externalExpanded = panel === 'externalGridAssets';

        if (panel !== 'saucelabsAssets') {

                if (checkArrayLength(_localPlatform)) {

                    _localPlatform = _localPlatform.filter((pl) => pl === 'Linux').sort();
                    const browserDropDownData = this.makeStateCopy('browserDropDownData');

                    
                        for (let key = 0; key < selectedPlatform.length; key++) {
                            browserDropDownData[key] = this.PlatformBasedBrowsers[selectedPlatform[key]];
                        }
                        
                        this.updateState({ browserDropDownData, 
                            //expanded: this.state.expanded === panel ? '' : panel, 
                            saucelabsUsed:false });
                }

                

        }

        if(userClicked) {
            this.setState({ 
                expanded:this.state.expanded === panel ? '' : panel,
                saucelabsExpand :  saucelabsExpanded ? !saucelabsExpand : saucelabsExpand,
                externalGridExpand : externalExpanded ?  !externalGridExpand : externalGridExpand,
                localExpand : localExpanded ?  !localExpand : localExpand,
                edited:userClicked ? true: false

            },()=>{
            })

        }
        
    }

    getSelectedBrowserString = (key,callingFrom) => {
        const { selectedBrowsers  } = this.state;
        let _newSelectedBrowser = '';
        if (checkIsArray(selectedBrowsers[key])) {
          
                selectedBrowsers[key].forEach((sb, index) => {
                    _newSelectedBrowser = `${_newSelectedBrowser}${index !== 0 ? ', ' : ''}${sb}`;
                });
            

        } else {
            _newSelectedBrowser =  selectedBrowsers[key];
        }

        return _newSelectedBrowser;
    };

    getBrowserDropDownData = (browserDropDownData, _selectedBrowser, _selectedPlatform, selectedPlatforms, selectedBrowsers) => {
        const allBrowsers = JSON.parse(JSON.stringify(browserDropDownData));
        let uniqueBrowsers = [];
        if (checkArrayLength(allBrowsers)) {
            allBrowsers.forEach((browser) => {
                if (uniqueBrowsers.indexOf(browser) === -1) uniqueBrowsers.push(browser);
            });
            uniqueBrowsers = uniqueBrowsers.filter((browser) => {
                return !selectedBrowsers
                    .slice(0, selectedBrowsers.length)
                    .some((b, j) => _selectedPlatform === selectedPlatforms[j + 1] && browser === b);
            });
            if (
                _selectedBrowser &&
                uniqueBrowsers.indexOf(_selectedBrowser) === -1 &&
                checkKeyInObject(this.PlatformBasedBrowsers, _selectedPlatform, 'value', []).includes(_selectedBrowser)
            ) {
               
                uniqueBrowsers.push(_selectedBrowser);
            }
        }
        return uniqueBrowsers;
    };

    getPlatformDropDownData = () => {
        const { selectedBrowsers, selectedPlatform} = this.state;
        const availablePlatform = [];


        _localPlatform.forEach((platform) => { 
                if (
                    this.PlatformBasedBrowsers[platform] &&
                    checkArrayLength(this.getBrowserDropDownData(this.PlatformBasedBrowsers[platform], '', platform, selectedPlatform, selectedBrowsers))
                ) {
                    availablePlatform.push(platform);
                }
            });
            return availablePlatform;
        
       
    };



    updateState = (obj,cb) => {
        this.setState(obj,()=>{
            if(cb) {
                cb();
            }
        });
    };



    handleTabChange = (event, value, key) => {
        this.setState({ selectedTab: this.makeStateCopy('selectedTab', value, key) });
    };

    closePopper = (selectBox, key) => {
        const obj = { anchorEl: this.makeStateCopy('anchorEl', null, key) };
        if (selectBox === 'Platform') {
            obj.platformDialog = this.makeStateCopy('platformDialog', false, key);
        } else if (selectBox === 'Execution') {
            obj.showSelectedExecutionModeDialog = false;
        } else {
            // calling from browser
            obj.browserDialog = this.makeStateCopy('browserDialog', false, key);
        }
        this.setState(obj);
    };

    handleBrowserChange = (selectedBrowsers, selectedTab, key) => {
        var obj;
      
            obj = {
                edited:true,

                selectedTab: this.makeStateCopy('selectedTab', selectedTab, key),
                selectedBrowsers: this.makeStateCopy('selectedBrowsers', selectedBrowsers, key),
            };
            const selectedDevice = getSelectedDevice(this.state.selectedPlatform);
            if (selectedDevice === 'Android' || selectedDevice === 'iOS') {
                obj.browserDialog = this.makeStateCopy('browserDialog', false, key);
                obj.anchorEl = this.makeStateCopy('anchorEl', null, key);
            }
            var emptyBrowser = obj.selectedBrowsers.filter((item)=> item.length === 0)
            if(emptyBrowser.length > 0){
                obj.selectedBrowsers = this.state.selectedBrowsers;
            }
        
        this.setState(obj);
    };

    handlePlatformChange = (params, key) => {
        const { selectedPlatform, selectedTab, callback = () => {} } = params;
        let obj;
             obj = {
                platformDialog: this.makeStateCopy('platformDialog', false, key),
                selectedPlatform: this.makeStateCopy('selectedPlatform', selectedPlatform, key),
                selectedTab: this.makeStateCopy('selectedTab', selectedTab, key),
                edited:true,
            };
        

            obj.anchorEl = this.makeStateCopy('anchorEl', null, key);
            if (selectedPlatform !== this.state.selectedPlatform[key]) {
                obj.browserDropDownData = this.makeStateCopy('browserDropDownData', this.PlatformBasedBrowsers[selectedPlatform], key);
                obj.selectedBrowsers = this.makeStateCopy(
                    'selectedBrowsers',
                    this.getBrowserDropDownData(
                        this.PlatformBasedBrowsers[selectedPlatform],
                        '',
                        selectedPlatform,
                        obj.selectedPlatform,
                        this.state.selectedBrowsers,
                    )[0] || '',
                    key,
                );
            }
        
        this.setState(obj, callback);
    };

    makeStateCopy = (state, _newVal, key) => {
        let copy = this.state[state];
        
        if (_newVal !== undefined && (key || key === 0)) {
            copy[key] = _newVal;
        }
        return copy;
    };

    validateExternalGridTab = () => {
        const { externalGrid } = this.state
        return externalGrid.some((elem) => !elem.platform.length || !elem.platformVersion.length || !elem.browser.length || !elem.browserVersion.length)
    }

    isDefaultSelected = () => {

        const {   saucelabsExpand, localExpand, externalGridExpand } = this.state;

        let expandedTabs = [];

        let isDefaultSelected = false;

        if(saucelabsExpand) {
            expandedTabs.push('saucelabsExpanded');
            expandedTabs.push('saucelabsDeviceExpanded');

        }
        if(localExpand) {
            expandedTabs.push('headlessExpanded');
        }
        if(externalGridExpand) {
            expandedTabs.push('externalGrid');
        }

        expandedTabs.forEach((expTab)=>{
            let _defaultSelected = this.state.defaultSelect
            _defaultSelected = _defaultSelected.split('_')[1]
            if(expandedTabs.indexOf(_defaultSelected) !== -1) {
                isDefaultSelected = true
            }

        })
        return isDefaultSelected;
    }

    checkIfDuplicatePlatformsOpted = (browserDetails) => {
        let uniqueSelected = [];
        let duplicate =[];

        if(browserDetails) {

        browserDetails.forEach((selected)=>{
            let combination = `${selected.platform}:${selected.browser}`;
            if(uniqueSelected.indexOf(combination)>=0) {
                duplicate.push(combination)
            } else {
                uniqueSelected.push(combination)
            }
        })
        }

        

        if(duplicate.length) {
            const errorMessage = `${duplicate.join(',')}`
            return [true,errorMessage];            
        } else {
            return [false,'']
        }
    }

    checkIfDuplicateFoundInSauce = (selectedBrowsersForSauce) => {
        if(selectedBrowsersForSauce) {
            let flag = checkIsArrayHasDuplicateElements(selectedBrowsersForSauce,"id");
            return [flag,'']

        }
    }

    handleSave = async () => {
        const { selectedPlatform,  selectedBrowsers } = this.state;
        const { toggleSnackBar, data } = this.props;
        const browserDetails = [];

        if(!this.isDefaultSelected()) {
            toggleSnackBar('Please select default platform', '', false, 2000);
            return;
        }

        for (let key = 0; key < selectedPlatform.length; key++) {
            if (selectedBrowsers[key]) {
                const platform = selectedPlatform[key];
                const getDetail = (browserDetail) => {
                    const details = {
                        browser: TestScriptUtils.getBrowserValueForSend(browserDetail[0],this.ModalType),
                        browserVersion: browserDetail[1] ? browserDetail[1] : '0.0',
                        platform: platform === 'Windows 10' ? 'WINDOWS' : platform,
                        platformVersion: platform === 'Windows 10' ? '10' : '',
                    };
                    if (browserDetail[2]) {
                        details.appiumVersion = browserDetail[2];
                    }
                    return details;
                };
                if (checkArrayLength(selectedBrowsers[key])) {
                    selectedBrowsers[key].forEach((brwoser) => {
                        const details = getDetail(brwoser.split(' '));
                        browserDetails.push(details);
                    });
                } else {
                    const details = getDetail(selectedBrowsers[key].split(' '));
                    browserDetails.push(details);
                }
            }
        }

        const [isDuplicateCombinationOpted] = this.checkIfDuplicatePlatformsOpted(this.state.localExpand?browserDetails :null);

        if(isDuplicateCombinationOpted) {
            toggleSnackBar(`Please remove duplicate platform from local execution settings`, '', false, 10000);
            return;
        }

        if(this.state.saucelabsExpand) {
            const [isDuplicateFoundInSauce] = this.checkIfDuplicateFoundInSauce([...this.state.selectedBrowsersForSauce,...this.state.selectedDevicesForSauce]);
            if(isDuplicateFoundInSauce) {
                toggleSnackBar(`Please remove duplicate platform from saucelabs execution settings`, '', false, 10000);
                return;
            }
        }


        

        this.setState({ edited: false });
        if (checkArrayLength(browserDetails) || checkArrayLength(this.state.selectedBrowsersForSauce) ) {
            const dataObj = {
                "accountId": data.activeUser.accountId,
                "environments":[],
            };

           this.handlePostEnvConfig({ dataObj, browserDetails });
            
        }
       // return null;
    };

    handlePostEnvConfig = async (params) => {
        
        const { dataObj, browserDetails } = params;
        const { toggleSnackBar } = this.props;
        const { externalGrid, defaultSelect ,externalGridUrl } = this.state;
        const __browserDetails = [...browserDetails];
        let environmentDetails = [];

        __browserDetails.forEach((browserDetail, i) => {
            
                if ( (i === JSON.parse(defaultSelect.split('_')[0])) && defaultSelect.split('_')[1] === "headlessExpanded"){
                        __browserDetails[i].isDefault = true;
                }
                environmentDetails.splice(i, 0, __browserDetails[i].browser.split('(')[1] === "headful)" ? 'Zalenium': 'Local');
                __browserDetails[i].browser =  TestScriptUtils.getBrowserValueForSend(__browserDetails[i].browser.split('(')[0].trim());
                __browserDetails[i].appiumVersion = '';
                __browserDetails[i].deviceName = '';
                __browserDetails[i].deviceOrientation = '';
                        
        });

        if(checkArrayLength(externalGrid)){
            externalGrid.forEach((browser,index)=>{
                if ( (index === JSON.parse(defaultSelect.split('_')[0])) && (defaultSelect.split('_')[1] === "externalGrid")){
                    externalGrid[index].isDefault = true;
                }
            })

            let environmentDetail = {
                environmentType:'remote',
                "environment":{
                    "platformDetails": externalGrid,
                    "gridUrl":externalGridUrl,

                }
            };

            dataObj.environments.push(environmentDetail);

        }

        if(checkArrayLength(__browserDetails)){

            let environmentDetail = {
                environmentType:'Local',
                "environment":{
                    "platformDetails": __browserDetails.filter((bd,index)=>{
                        if(environmentDetails[index]==='Local') {
                            return true;
                        } else {
                            return false
                        }
                    })
                }

            }

            dataObj.environments.push(environmentDetail);
        }

        if(checkArrayLength(__browserDetails)){

            
            let environmentDetail = {
                environmentType:'Zalenium',
                "environment":{
                    "platformDetails":  __browserDetails.filter((bd,index)=>{
                        if(environmentDetails[index]==='Zalenium') {
                            return true;
                        } else {
                            return false
                        }
                })
            }

            }

            dataObj.environments.push(environmentDetail);

        }

        if(checkArrayLength(this.state.selectedBrowsersForSauce)) {

            //Set default selected row
            let _selectedBrowserForSauce = [...this.state.selectedBrowsersForSauce];
            const defaultSelect = this.state.defaultSelect;

            _selectedBrowserForSauce = _selectedBrowserForSauce.map((browser,index)=>{

                if(defaultSelect && (defaultSelect.split('_')[1] === "saucelabsExpanded") && (defaultSelect.split('_')[0]==index)) {
                    browser.isDefault = true;
                } else {
                    browser.isDefault = false
                }
                return browser

            });
            let environmentDetail = {
                environmentType:'Saucelabs',
                "environment":{
                    "sauceDataCentreName":'',
                    "saucePassword":'',
                    "sauceUsername":'',
                    "platformDetails": _selectedBrowserForSauce
                }

            }

            dataObj.environments.push(environmentDetail);

        }
        if(checkArrayLength(this.state.selectedDevicesForSauce)) {

            //Set default selected row for devices
            let _selectedDevicesForSauce = [...this.state.selectedDevicesForSauce];
            const defaultSelect = this.state.defaultSelect;

            _selectedDevicesForSauce = _selectedDevicesForSauce.map((device,index)=>{

                if(defaultSelect && (defaultSelect.split('_')[1] === "saucelabsDeviceExpanded") && (defaultSelect.split('_')[0]==index)) {
                    device.isDefault = true;
                }  else {
                    device.isDefault = false
                }
                return device

            });
            let environmentDetail = {
                environmentType:'saucelab_devices',
                "environment":{
                    "platformDetails": _selectedDevicesForSauce
                }

            }

            dataObj.environments.push(environmentDetail);

        }

         const requestData = { ...dataObj }; // use browser details in this api as Avishkar said on 17 Feb, 2020

         const {saucelabsExpand,localExpand,externalGridExpand} = this.state

        let tabsOpened = [];
        if(saucelabsExpand) {
            tabsOpened.push("Saucelabs")
            tabsOpened.push("saucelab_devices")

        }
        if(localExpand) {
            tabsOpened.push("Local")
            tabsOpened.push("Zalenium")

        }

        if(externalGridExpand) {
            tabsOpened.push("remote")

        }



        if(requestData && requestData.environments && Array.isArray(requestData.environments)) {
            requestData.environments = requestData.environments.filter((reqData)=>{
                return tabsOpened.indexOf(reqData.environmentType) !== -1

            }) 
        }



       try {
        
        const response = await this.props.postEnvironmentConfig(requestData)

        if (response) {

            toggleSnackBar('Saved  Environment config successfully', '', true, 2000);
           
        } 
        else{

            toggleSnackBar('Saving failed', '', false, 2000);

        }
        

       } catch(err) {
        toggleSnackBar('Saving failed', '', false, 2000);

       }

       
    };

    handleDefault = ( index, event, environmentType) => {
        let _defaultSelect = `${index}_${environmentType}`;
        
        this.setState({
            edited:true,
            defaultSelect: _defaultSelect,
           
        })
    }

    addRowForExtGrid = () => {
        let newRow = {
            platform: '',
            platformVersion: '',
            browser: '',
            browserVersion: ''
        }
        this.setState({ 
            externalGrid: [...this.state.externalGrid, newRow],
            edited: true,
        })
    }
    handleChangeForExtGrid = (e, ind) => {
        const { externalGrid } = this.state;
        let _externalGrid = externalGrid;
        _externalGrid[ind][e.target.name] = e.target.value;
        this.setState({ 
            externalGrid: _externalGrid, 
            edited: true,
        })
    }
    deleteRowForExtGrid = (ind) => {
        const { externalGrid } = this.state;
        let _externalGrid = externalGrid;
        _externalGrid = _externalGrid.filter((_, i) => i !== ind)
        this.setState({ 
            externalGrid: _externalGrid,
            edited: true, 
        })
    }

    addRow = (i) => {
        const { anchorEl, browserDialog, browserDropDownData,  platformDialog, selectedBrowsers,  selectedPlatform,  selectedTab } = this.state;

        const _selectedPlatform =  this.getPlatformDropDownData()[0] || '';
        pushInMidArray(anchorEl, i, null);
        pushInMidArray(browserDialog, i, false);
        
        pushInMidArray(browserDropDownData, i, this.PlatformBasedBrowsers[_selectedPlatform]);
        
        
        pushInMidArray(platformDialog, i, false);
    
            pushInMidArray(selectedPlatform, i, _selectedPlatform);
            pushInMidArray(
                selectedBrowsers,
                i,
                this.getBrowserDropDownData(
                    this.PlatformBasedBrowsers[_selectedPlatform],
                    '',
                    _selectedPlatform,
                    selectedPlatform,
                    this.state.selectedBrowsers,
                )[0] || '',
            );
        

       

        // Don't change the order of previous  2 lines
        pushInMidArray(selectedTab, i, 0);
        this.setState({
            anchorEl,
            browserDialog,
            browserDropDownData,
            platformDialog,
            selectedBrowsers,
            selectedPlatform,
            selectedTab,
            edited:true,
        });
    };

    addRowSaucelabs = () => {

        let newSelectedBrowsersForSauce = [...this.state.selectedBrowsersForSauce];
        newSelectedBrowsersForSauce.push(this.state.saucelabs_capabliites.browsers_capabilities_list[0])
        
        this.setState({
            edited:true,
            selectedBrowsersForSauce:newSelectedBrowsersForSauce
        })
    };

    addRowForSaucelabsDevice = () => {

        let newSelectedDevicesForSauce = [...this.state.selectedDevicesForSauce];
        newSelectedDevicesForSauce.push(this.state.saucelabs_capabliites.devices_capabilities_list[0])
        
        this.setState({
            edited:true,
            selectedDevicesForSauce:newSelectedDevicesForSauce
        })
    }

    deleteRow = (i) => {
        const { anchorEl, browserDialog, browserDropDownData, platformDialog, selectedBrowsers, selectedPlatform, selectedTab , defaultSelect} = this.state;

        
            let _defaultSelectedIndex = defaultSelect ? defaultSelect.split('_')[0] : null
            let _defaultSelectEnv = defaultSelect ? defaultSelect.split('_')[1] : null

            let  __defaultSelected = defaultSelect

            if(_defaultSelectEnv && _defaultSelectedIndex && _defaultSelectEnv === 'headlessExpanded') {
                
                if(Number(_defaultSelectedIndex)>=selectedPlatform.length-1) {
                    __defaultSelected = `${selectedPlatform.length-2}_${_defaultSelectEnv}`
                }
            }

        
            removeIndexFromArray(anchorEl, i);
            removeIndexFromArray(browserDialog, i);
            removeIndexFromArray(browserDropDownData, i);
            removeIndexFromArray(platformDialog, i);
            removeIndexFromArray(selectedBrowsers, i);
            removeIndexFromArray(selectedPlatform, i);
            removeIndexFromArray(selectedTab, i);

        
        

        this.setState({
            anchorEl,
            browserDialog,
            browserDropDownData,
            platformDialog,
            selectedBrowsers,
            selectedPlatform,
            selectedTab,
            defaultSelect:__defaultSelected,
            edited: true,
        },()=>{
        });
    };


    deleteRowSaucelabs = (id,index) => {
        
        let newSelectedBrowsersForSauce = [...this.state.selectedBrowsersForSauce];
        newSelectedBrowsersForSauce = newSelectedBrowsersForSauce.filter((selectedBrowser,i)=>{
            return i!==index
        });

        //set default another if removed one default

        const {defaultSelect} = this.state;

        let _defaultSelectedIndex = defaultSelect ? defaultSelect.split('_')[0] : null
        let _defaultSelectEnv = defaultSelect ? defaultSelect.split('_')[1] : null

        let  __defaultSelected = defaultSelect

        if(_defaultSelectEnv && _defaultSelectedIndex && _defaultSelectEnv === 'saucelabsExpanded') {

            
            if(Number(_defaultSelectedIndex)>=newSelectedBrowsersForSauce.length-1) {
                __defaultSelected = `${newSelectedBrowsersForSauce.length-1}_saucelabsExpanded`
            }
        }


        this.setState({
            defaultSelect:__defaultSelected,
            edited:true,
            selectedBrowsersForSauce:newSelectedBrowsersForSauce
        })
    }

    deleteRowForSaucelabsDevice = (id,index) => {

        let newSelectedDeviceForSauce = [...this.state.selectedDevicesForSauce];
        newSelectedDeviceForSauce = newSelectedDeviceForSauce.filter((selectedDevice,i)=>{
            return i!==index
        });


        const {defaultSelect} = this.state;

        let _defaultSelectedIndex = defaultSelect ? defaultSelect.split('_')[0] : null
        let _defaultSelectEnv = defaultSelect ? defaultSelect.split('_')[1] : null

        let  __defaultSelected = defaultSelect

        if(_defaultSelectEnv && _defaultSelectedIndex && _defaultSelectEnv === 'saucelabsDeviceExpanded') {

            
            if(Number(_defaultSelectedIndex)>=newSelectedDeviceForSauce.length-1) {
                __defaultSelected = `${newSelectedDeviceForSauce.length-1}_saucelabsDeviceExpanded`
            }
        }

        this.setState({
            edited:true,
            selectedDevicesForSauce:newSelectedDeviceForSauce,
            defaultSelect:__defaultSelected
        })

    }

    getSaveDisabled = () => {

        let edited = this.state.edited;
        const { saucelabsExpand, localExpand, externalGridExpand } = this.state;

        let expandedTabs = [];
        if(saucelabsExpand) {
            expandedTabs.push('saucelabsExpanded');
        }
        if(localExpand) {
            expandedTabs.push('headlessExpanded');
        }
        if(externalGridExpand) {
            expandedTabs.push('externalGrid');
        }


        //text fileds validation
        let validated = true;

        let { externalGridUrl,externalGrid} = this.state
        externalGridUrl = externalGridUrl ? externalGridUrl.trim() : ''


        expandedTabs.forEach((expTab)=>{

           if(expTab === 'externalGrid') {

            if(externalGrid && Array.isArray(externalGrid)) {
                externalGrid.forEach((exGrid)=>{
                    let browser = exGrid.browser ? exGrid.browser.trim() :''
                    let platform = exGrid.platform ? exGrid.platform.trim() :''
                    let platformVersion = exGrid.platformVersion ? exGrid.platformVersion.trim() :''
                    if(!browser || !platform || !platformVersion) {
                        validated = false;
                    }


                })
            }

            if(!externalGridUrl) {
                validated = false
            }

           }

        })

        if(!edited  || !validated) {
            return true
        } else {
            return false
        }
    }

    onSelectPlatformBrowser = (index,selectedDevice) => {
        
        let newSelectedBrowsersForSauce = [...this.state.selectedBrowsersForSauce];
        let newlySelectedPlatformBrowser = this.state.saucelabs_capabliites.browsers_capabilities_list.find((dc)=>{
            return dc.id==selectedDevice.id
        });


        if(newlySelectedPlatformBrowser) {

            newSelectedBrowsersForSauce = newSelectedBrowsersForSauce.map((selectedBrowser,i)=>{

                if(i==index) {
                    selectedBrowser = newlySelectedPlatformBrowser  
                }
                return selectedBrowser
            })
            
            this.setState({
                selectedBrowsersForSauce:newSelectedBrowsersForSauce,
                edited:true
            })
        }

    }

    onSelectSauceDevice = (index,selectedDevice) => {

        let newSelectedDeviceForSauce = [...this.state.selectedDevicesForSauce];

        let newlySelectedSauceDevice = this.state.saucelabs_capabliites.devices_capabilities_list.find((dc)=>{
            return dc.id==selectedDevice.id
        });

        if(newlySelectedSauceDevice) {

            newSelectedDeviceForSauce = newSelectedDeviceForSauce.map((selectedDevice,i)=>{

                if(i==index) {
                    selectedDevice =   newlySelectedSauceDevice
                }
                return selectedDevice
            })
            
            this.setState({
                selectedDevicesForSauce:newSelectedDeviceForSauce,
                edited:true
            })

        }
    }

    render() {

    const { classes, type } = this.props;
    const {
        anchorEl,
        browserDialog,
        platformDialog,
        selectedBrowsers,
        selectedPlatform,
        browserDropDownData,
        selectedTab,
        saucelabsExpand,
        localExpand,
        externalGrid,
        selectedBrowsersForSauce,
        //defaultPlatformBrowser,
        defaultSelect,
        externalGridExpand
    } = this.state;




    const saucelabsExpanded = saucelabsExpand;
    const headlessExpanded = localExpand;

    const PlatformBrowserSelectionContainerProps = (key) => {

        return {
            anchorEl: anchorEl[key],
            handleTabChange: (...args) => {
                this.handleTabChange(...args, key);
            },
            closePopper: (...args) => {
                this.closePopper(...args, key);
            },
            onClickPlatformBrowserContainer: (...args) => {
                this.onClickPlatformBrowserContainer(key, ...args);
            },
            type,
            // : localExpand ? 'Local' : 'saucelabs',
            //localExpand:localExpand,
            //modalType: this.ModalType,
            //saucelabsExpanded: saucelabsExpanded,
            selectedTab:selectedTab[key],
        };
    };


    const rows = [];
    const rowsExternalGrid = [];
    const isSauceLab = saucelabsExpanded ? true : false

    const rowCount =  selectedPlatform.length
    
    rowsExternalGrid.push(
        <div style={{paddingLeft:'6px',paddingTop:'12px'}} className={classes.extGridContainer} key={1}>
            {externalGrid.length ? externalGrid.map((elem, ind) => (
            <div
                key={ind}
                style={{ paddingTop:8,paddingBottom:8, backgroundColor: ind % 2 === 0 ? '#fafafa' : 'inherit' }}
                //onMouseEnter={() => setHover(ind)}
                //onMouseLeave={() => setHover(null)}
            >

                <div style={{ display: 'flex', alignItems: 'center', padding: 5 }} >
                <div className={classes.radioButtonGroupContainer}>
                <Radio
                        checked={defaultSelect === `${ind}_externalGrid`}
                        onChange={(e) => this.handleDefault(ind,e,'externalGrid')}
                        value= {`${ind}_externalGrid`}
                        name="radio-button-demo"
                        inputProps={{ 'aria-label': 'A' }}
                    />
                    </div>

                    <div style={{ width: '50%' }}>
                        <Typography variant="subtitle1"  gutterBottom className={classes.headingDetails}>
                            Platform
                        </Typography>
                        <TextField
                            //  helperText={!elem.platform.length && isFieldEmpty ? '*Required' : ''} 
                            fullWidth
                            spellCheck ={false}
                            //disabled={configurationSetting === 'lastRunConfiguration'}
                            placeholder={'Enter Platform'}
                            // style={configurationSetting === 'lastRunConfiguration' ? {borderBottomColor: '#979797',opacity: '0.8'} : {borderBottomColor: '#979797'}}
                            InputProps={{ autoComplete: 'off' }}
                            className={classes.input}
                            value={elem.platform}
                            onChange={(e) => this.handleChangeForExtGrid(e, ind)}
                            name="platform"
                        />
                    </div>
                    
                    <div style={{ width: '50%', marginLeft: 50,marginRight:'12px' }}>
                        <Typography variant="subtitle1"  gutterBottom className={classes.headingDetails}>
                            Platform Version
                        </Typography>
                        <TextField
                            //helperText={!elem.platformVersion.length && isFieldEmpty ? '*Required' : ''}
                            fullWidth
                            spellCheck = {false}
                            //disabled={configurationSetting === 'lastRunConfiguration'}
                            placeholder={'Enter Platform Version'}
                            //style={configurationSetting === 'lastRunConfiguration' ? {borderBottomColor: '#979797',opacity: '0.8'} : {borderBottomColor: '#979797'}}
                            InputProps={{ autoComplete: 'off' }}
                            className={classes.input}
                                value={elem.platformVersion}
                                onChange={(e) => this.handleChangeForExtGrid(e, ind)}
                            name="platformVersion"
                        />
                    </div>
                    
                </div>

                <div style={{ display: 'flex' }} >
                <div style={{ marginLeft:36,width: '50%' }}>
                    
                <div   className={classes.error}>
                            {
                                !elem.platform.trim() &&
                                'Platform must not be empty'

                            }

                    </div>     
                    </div>
                    <div style={{ width: '50%', marginLeft: 50, marginRight:'16px' }}>
                    <div   className={classes.error}>
                            {
                                !elem.platformVersion.trim() &&
                                'Platform version must not be empty'

                            }

                    </div>     

                    </div>


                    
                </div>

                

                <div style={{ display: 'flex', alignItems: 'center', padding: 5 }} >
                

                    <div style={{ marginLeft:36,width: '50%' }}>
                        <Typography variant="subtitle1" gutterBottom className={classes.headingDetails}>
                            Browser
                        </Typography>
                        <TextField
                            //helperText={!elem.browser.length && isFieldEmpty ? '*Required' : ''}
                            fullWidth
                            spellCheck = {false}
                            //disabled={configurationSetting === 'lastRunConfiguration'}
                            placeholder={'Enter Browser'}
                            //style={configurationSetting === 'lastRunConfiguration' ? {borderBottomColor: '#979797',opacity: '0.8'} : {borderBottomColor: '#979797'}}
                            InputProps={{ autoComplete: 'off' }}
                            className={classes.input}
                                value={elem.browser}
                                onChange={(e) => this.handleChangeForExtGrid(e, ind, 'browser')}
                            name="browser"
                        />
                    </div>
                    
                    
                    <div style={{ width: '50%', marginLeft: 50, marginRight:'12px' }}>
                        <Typography variant="subtitle1" gutterBottom className={classes.headingDetails}>
                            Browser Version
                        </Typography>
                        <TextField
                            // helperText={!elem.browserVersion.length && isFieldEmpty ? '*Required' : ''}
                            fullWidth
                            spellCheck = {false}
                            //disabled={configurationSetting === 'lastRunConfiguration'}
                            placeholder={'Enter Browser Version'}
                            //style={configurationSetting === 'lastRunConfiguration' ? {borderBottomColor: '#979797',opacity: '0.8'} : {borderBottomColor: '#979797'}}
                            InputProps={{ autoComplete: 'off' }}
                            className={classes.input}
                            value={elem.browserVersion}
                                onChange={(e) => this.handleChangeForExtGrid(e, ind)}
                            name="browserVersion"
                        />
                    </div>
                    
                            <div>
                            {/* <Tooltip data='Delete'>
                                {externalGrid.length > 1 && (
                                    <IconButton 
                                    //disabled={configurationSetting === 'lastRunConfiguration'} className={classes.iconButtonRoot}
                                    >
                                        <CloseIcon 
                                        //className={`${configurationSetting === 'lastRunConfiguration' ? classes.disableAddBtn : classes.addIcon}`} 
                                        onClick={() => this.deleteRowForExtGrid(ind)} 
                                        />
                                    </IconButton>
                                )}
                            </Tooltip> */}
                        </div> 
                </div>



                <div style={{ display: 'flex' }} >
                <div style={{ marginLeft:36,width: '50%' }}>
                    
                <div   className={classes.error}>
                            {
                                !elem.browser.trim() &&
                                'Browser must not be empty'

                            }

                    </div>     
                    </div>
                    <div style={{ width: '50%', marginLeft: 50, marginRight:'16px' }}>
                    <div   className={classes.error}>
                            {/* {
                                !elem.browserVersion.trim() &&
                                'Browser version must not be empty'

                            } */}

                    </div>     

                    </div>


                    
                </div>
                
                
            </div>)):(
                <div></div>
            )}
{/*             
        <div style={{ textAlign: "end",  paddingRight: `26px`}}>
            <Tooltip data='Add'>
                <IconButton
                    className={classes.iconButtonRoot}
                    onClick={() => this.addRowForExtGrid()}
                    //disabled={configurationSetting === 'lastRunConfiguration' || validateExternalGridTab() ? true : false}
                >
                    <AddIcon 
                    //className={`${configurationSetting === 'lastRunConfiguration' || validateExternalGridTab() ? classes.disableAddBtn : classes.addIcon}`} 
                    />
                </IconButton>
            </Tooltip>

        </div> */}
    </div>,
    );


    for (let i = 0; i < rowCount; i++) {
        if(headlessExpanded){
            rows.push(
                <div style={{paddingLeft:'6px'}} className={classes.selectionContainer} key={i}>
                    <div className={classes.radioButtonGroupContainer}>
                        <Radio
                            checked={defaultSelect === `${i}_headlessExpanded`}
                            onChange={(e) => this.handleDefault(i,e,'headlessExpanded')}
                            value= {`${i}_headlessExpanded`}
                            name="radio-button-demo"
                            inputProps={{ 'aria-label': 'A' }}
                        />
                    </div>
                    <PlatformBrowserSelectionContainer
                        {...PlatformBrowserSelectionContainerProps(i)}
                        // eslint-disable-next-line no-loop-func
                        dropDownData={(() => {
                            
                            const platforms = this.getPlatformDropDownData();
                            if (platforms.indexOf(selectedPlatform[i]) === -1) {
                                platforms.push(selectedPlatform[i]);
                            }
                            
                            return platforms;
                        })()}
                        localExpand={localExpand}
                        saucelabsExpanded = {false}
                        handlePlatformChange={(...args) => {
                            this.handlePlatformChange(...args, i);
                        }}
                        isOpenDialog={platformDialog[i]}
                        title="Platform"
                        selectedDropDownValue={selectedPlatform[i]}
                        //versionsArray={PlatformSpecificVersionsArray}
                    />
                    <PlatformBrowserSelectionContainer
                        {...PlatformBrowserSelectionContainerProps(i)}
                        dropDownData={browserDropDownData[0]}
                        handleBrowserChange={(item, index) => {
                           
                            this.handleBrowserChange(item, index, i);
                            
                        }}
                        localExpand={localExpand}
                        saucelabsExpanded = {false}

                        isOpenDialog={browserDialog[i]}
                        title="Browser"
                        selectedBrowserArray={isSauceLab ? selectedBrowsers[i] : []}
                        selectedDropDownValue={this.getSelectedBrowserString(i,'headlessExpanded')}
                        //versionsArray={BrowserSpecificVersionsArray}
                    />
                        <>
                        <div  className={classes.actionButtonsContainer}>
                            {rowCount > 1 ? (
                                <IconButton className={classes.iconButtonRoot} onClick={() => this.deleteRow(i)}>
                                    <CloseIcon className={classes.actionIcons} />
                                </IconButton>
                            ) : null}
                        </div>

                        <div className={classes.actionButtonsContainer}>
                           
                        { (rowCount < (_localPlatform.length*browserDropDownData[0].length)) && i === rowCount - 1 ? (
                        <IconButton className={classes.iconButtonRoot} onClick={() => this.addRow(i + 1)}>
                        <AddIcon className={`${classes.actionIcons}`} />
                        </IconButton>
                        ) : (
                            <div className={classes.iconButtonRoot} >
                            </div>
    
                        )} 
                        </div>
                        </>
                    
                </div>,
            );
        }


    }


    return (
        <React.Fragment>
          { this.state.dataReady && 
            <div className={classes.bodyWrapper}>
            <Grid item xs={1} sm={1} md={2} lg={2} />
            <Grid item xs={10} sm={10} md={8} lg={8}>
            {(
                    <React.Fragment>
                        <div
                            className={classes.itemMainWrapper}
                            style={{  backgroundColor: saucelabsExpanded ? 'white' : '' }}
                        >

                            <Grid  item xs={12} sm={12} md={12} lg={12}>
                                <Accordion expanded={saucelabsExpanded} className={classes.noBoxShadow}>
                                    <AccordionSummary
                                        expandIcon={null}
                                        className={classes.expansionsummary}
                                        classes={{ content: classes.expansionContent }}
                                    >
                                        <Typography gutterBottom className={classes.heading}>
                                        Sauce Labs
                                        </Typography>
                                        <div className={classes.daysContainer}>
                                            <Typography className={classes.baseTextStyle}>&nbsp;</Typography>
                                            <span aria-hidden 
                                                className={classes.expandIconContainer}>
                                                <CustomSwitch
                                                    id="sauceToggle"
                                                    checked={saucelabsExpanded}
                                                    onChange={(e) => {
                                                    // this.setState({ saucelabsExpand: !this.state.saucelabsExpand })
                                                        this.handleExpand('saucelabsAssets',true);
                                                    }}
                                                    value="isAccountActive"
                                                    w={34}
                                                    h={16}
                                                />
                                            </span>
                                        </div>
                                    </AccordionSummary>
                                    <AccordionDetails className={classes.expensionDetail}> 
                                        <SaucelabsExecutionEnvironmentSettings selectedDevicesForSauce ={this.state.selectedDevicesForSauce} data={this.state.saucelabs_capabliites} selectedBrowsersForSauce = {selectedBrowsersForSauce} defaultSelect={defaultSelect} handleDefault={this.handleDefault} deleteRowSaucelabs={this.deleteRowSaucelabs} addRowSaucelabs={this.addRowSaucelabs}  onSelectPlatformBrowser={this.onSelectPlatformBrowser} onSelectSauceDevice = {this.onSelectSauceDevice} deleteRowSaucelabs={this.deleteRowSaucelabs} addRowForSaucelabsDevice={this.addRowForSaucelabsDevice} deleteRowForSaucelabsDevice={this.deleteRowForSaucelabsDevice} />
                                    </AccordionDetails>
                                </Accordion>
                            </Grid>
                        </div>
                        <div
                            className={classes.itemMainWrapper}
                            style={{ borderBottom:   '1px solid #BAC4CE', backgroundColor: headlessExpanded ? 'white' : '' }}
                        >
                            <Grid  item xs={12} sm={12} md={12} lg={12}>
                                <Accordion expanded={headlessExpanded} className={classes.noBoxShadow}>
                                    <AccordionSummary
                                        expandIcon={null}
                                        className={classes.expansionsummary}
                                        classes={{ content: classes.expansionContent }}
                                    >
                                    <Typography gutterBottom className={classes.heading}>
                                Local 
                                </Typography>

                                        <div className={classes.daysContainer}>
                                            <Typography className={classes.baseTextStyle}>&nbsp;</Typography>
                                            <span aria-hidden 
                                            className={classes.expandIconContainer}>
                                                <CustomSwitch
                                                    checked={headlessExpanded}
                                                    onChange={(e) => {
                                                        this.handleExpand('headlessAssets',true);
                                                    }}
                                                    value="isAccountActive"
                                                    w={34}
                                                    h={16}
                                                />
                                            </span>
                                        </div>
                                    </AccordionSummary>
                                    <AccordionDetails className={classes.expensionDetail}>
                                        <div style={{paddingLeft:'3%',paddingRight:'3%'}}>
                                        <Typography gutterBottom className={classes.headingDet}>
                                            Add/Remove Platforms
                                        </Typography>
                                        {rows}
                                        </div>
                                    </AccordionDetails>
                                    </Accordion>
                            </Grid>
                        </div>
                        <div
                            className={classes.itemMainWrapper}
                            style={{  backgroundColor: externalGridExpand ? 'white' : '' }}
                        >
                            <Grid  item xs={12} sm={12} md={12} lg={12}>
                                <Accordion expanded={externalGridExpand} className={classes.noBoxShadow}>
                                    <AccordionSummary
                                        expandIcon={null}
                                        className={classes.expansionsummary}
                                        classes={{ content: classes.expansionContent }}
                                    >
                                    <Typography gutterBottom className={classes.heading}>
                                        Remote
                                    </Typography>

                                        <div className={classes.daysContainer}>
                                            <Typography className={classes.baseTextStyle}> &nbsp;</Typography>
                                            <span aria-hidden  className={classes.expandIconContainer}>
                                                <CustomSwitch
                                                    checked={externalGridExpand}
                                                    onChange={(e) => {
                                                        this.handleExpand('externalGridAssets',true);
                                                    }}
                                                    value="isAccountActive"
                                                    w={34}
                                                    h={16}
                                                />
                                            </span>
                                        </div>
                                    </AccordionSummary>
                                    <AccordionDetails className={classes.expensionDetail}>
                                        <div style={{paddingLeft:'3%',paddingRight:'3%'}}>
                                        <div className={classes.sidebarItem} style={{ borderBottom: '1px solid #c5ced8',  paddingTop: 20 }}>
                                            <Grid item xs={4} sm={4} md={3} lg={3} style={{ display: 'flex', alignItems: 'flex-end', paddingBottom: '2px' }}>
                                                <Typography variant="subtitle1" gutterBottom className={classes.headingDetails}>
                                                    Remote URL
                                                </Typography>
                                            </Grid>
                                            <Grid item xs={8} sm={8} md={8} lg={8} style={{ paddingLeft: 6, display: 'flex' }}>
                                                <TextField
                                                   spellCheck = {false}
                                                    fullWidth
                                                    InputProps={{
                                                        disableUnderline: true,
                                                        autoComplete: 'off',
                                                        inputProps: {
                                                            min: 0,
                                                            max: 1000000,
                                                            },
                                                        }}
                                                        className={classes.input}
                                                        value={this.state.externalGridUrl}
                                                        placeholder="External Grid Url"
                                                        aria-label="URL"
                                                        id="url"
                                                        onChange={(e) => {
                                                         this.setState({ externalGridUrl: e.target.value });
                                                         this.setState({ edited: true });
                                                         }}
                                                        name="url"
                    
                                                    
                                    
                                                />
                                            </Grid>
                                        </div>
                                        {
                                            !this.state.externalGridUrl.trim() &&
                                            <div className={classes.error}>
                                                {'remote url must not be empty'}
                                             </div>   

                                        }
                                        {rowsExternalGrid}
                                        </div>
                                    </AccordionDetails>
                                    </Accordion>
                            </Grid>
                        </div>
                    </React.Fragment>
                )}
                <div className={classes.buttonContainer}>
                    <Button
                        variant="contained"
                        color="secondary"
                        className={classes.button}
                        disabled={this.getSaveDisabled()}
                        onClick={() => {
                            this.handleSave();
                        }}
                    >
                        Save
                    </Button>
                </div>
            </Grid>
            <Grid item xs={1} sm={1} md={2} lg={2} />
        </div>
          }
          {
              !this.state.dataReady &&
              <div>
                <CircularProgress style={{ margin: '100px 50% 0px', color: '#4885ed' }} size={60} />
              </div>

          }
        </React.Fragment>
    );
}
}

ExecutionSetting.propTypes = {
    classes: PropTypes.shape({}).isRequired,
    toggleSnackBar: PropTypes.func.isRequired,
    actions: PropTypes.shape({
        handleExpand: PropTypes.func,
        handleSave: PropTypes.func,
    }),
};

ExecutionSetting.defaultProps = {
    actions: {
        handleExpand: () => {},
        handleSave: () => {},
    },
    data: {
        expanded: '',
        execsPerTestcase: 10,
        account: {},
    },
};

const mapStateToProps = (state) => {
    return {
        user: state.authReducer.user,
        environmentConfig:state.accountReducer.environmentConfig,
        supportedEnvironments:state.accountReducer.supportedEnvironments,

    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        toggleSnackBar: (...args) => dispatch(ModalActions.toggleSnackBar(...args)),
        getEnvironmentConfig: (accountId) => dispatch(AccountActions.getEnvironmentConfig(accountId)),
        getsupportedEnvironments: () => dispatch(AccountActions.getsupportedEnvironments()),
        postEnvironmentConfig: (data,callback) => dispatch(AccountActions.postEnvironmentConfig(data,callback)),
        setEnvironmentConfig: (form) => dispatch({ type: ActionTypes.GET_SAML_CONFIG_SUCCESS, payload: form }),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(ExecutionSetting));
