import AppBar from '@material-ui/core/AppBar';
import Button from '@material-ui/core/Button';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import { withStyles } from '@material-ui/core/styles';
import AccountCircle from '@material-ui/icons/AccountCircle';
import { Search } from '@material-ui/icons';
import Badge from '@material-ui/core/Badge';
import NotificationsIcon from '@material-ui/icons/Notifications';
import { Route } from 'react-router-dom';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import Select, { components } from 'react-select';
import _, { isArray } from 'lodash';
import { Typography } from '@material-ui/core';
import WalkMe from './walkme.svg';
import SelectCustom from '../../modal/GeneralModal/Select';

import { AuthActions, GeneralActions, ModalActions, ProjectActions } from '../../../store/actions';
import UserMenu from '../../../components/UserMenu/UserMenu';
import UserNotification from '../../../components/UserMenu/Notifications';
import ErrorSnackBar from '../../../components/ErrorSnackBar';
import SearchInput from '../../modal/GeneralModal/SearchInput';
import { checkArrayLength, checkKeyInObject, isOnProjectPage, initializeWalkMe } from '../../../utils/utils';
import { Modal } from '../../modal';
import config from '../../../config';
import Tooltip from '../../../components/Tooltip';
import { BLACK_FONT } from '../../../common/cssConstants';
import { track } from '../../../services/Segment';
import { SEGMENT_EVENT } from '../../../common/constants';
import AlertBar from '../../../components/AlertBar';
import store from '../../../store';
import { ActionTypes } from '../../../store/constants';

const { companyName, dashboardImageUrl, logoLayout } = config;
const styles = (theme) => ({
    appBar: {
        zIndex: theme.zIndex.drawer + 1,
        height: '46px',
        flexGrow: 1,
        backgroundColor: '#FFFFFF',
        boxShadow: `0px 2px 4px 0px rgba(0, 0, 0, 0.02),
                 0px 0px 2px 0px rgba(0, 0, 0, 0.14),
                 0px 1px 10px 0px rgba(0, 0, 0, 0.01)`,
    },
    appBarXS: {
        flexGrow: 1,
        boxShadow: `0px 2px 4px 0px rgba(0, 0, 0, 0.02),
                 0px 0px 2px 0px rgba(0, 0, 0, 0.14),
                 0px 1px 10px 0px rgba(0, 0, 0, 0.01)`,
    },
    rightContainer: {
        alignItems: 'center',
        // justifyContent: 'space-around',
        display: 'flex',
        [theme.breakpoints.only('sm')]: {
            display: 'contents',
        },
    },
    notifay: {
        color: '#fff',
        margin: '-6px 0px 0px 272px',
        backgroundColor: '#FFC107',
        width: 20,
        height: 20,
        position: 'fixed',
        float: 'left',
        zIndex: 1,
        borderRadius: '100%',
    },
    toolbar: {
        minHeight: '48px',
    },
    imageContainer: {
        flex: 4,
        display: 'flex',
        alignItems: 'center',
    },
    medIcon: {
        color: '#4A4A4A',
    },
    notificationButton: {
        marginRight: '10px',
    },
    userButton: {
        marginRight: 10,
        marginLeft: 10,
        width: 35,
        height: 35,
    },
    searchInput: {
        marginRight: '40px',
        width: 400,
        '&>div>input': {
            '&::placeholder': {
                color: '#9B9B9B',
                fontWeight: 400,
                fontSize: '14px',
                opacity: 1,
            },
        },
        '&>div:first-of-type': {
            height: '38px',
        },
    },
    headerlogoRectangle: {
        width: '130px',
        cursor: 'pointer',
    },
    headerlogoSquare: {
        width: '80px',
        height: '44px',
        cursor: 'pointer',
    },
    userNameText: {
        marginLeft: 7,
    },
    userNameBtn: {
        padding: '8px 16px',
        minWidth: '64px',
    },
    selectCustom: {
        width: '100%',
        marginTop: '16px',
        marginBottom: '8px',
    },
    selectCustomWrapper: {
        marginRight: 40,
        width: 400,
    },
    select: {
        marginRight: '40px',
        width: 400,
        fontSize: '12px',
        alignItems: 'center',
        [theme.breakpoints.only('sm')]: {
            marginLeft: '40px',
        },
        '&>div:first-of-type': {
            backgroundColor: 'white !important',
            borderWidth: 0,
            borderBottomWidth: 1,
            borderBottomColor: 'rgba(0, 0, 0, 0.42)',
            borderRadius: 0,
            outline: 'none',
            '&>div:first-of-type': {
                padding: '2px 8px',
                position: 'relative',
                boxSizing: 'border-box',
                display: '-webkit-inline-box',
                maxHeight: '38px',
                maxWidth: 'calc(100% - 73px)',
                overflow: 'hidden',
                overflowX: 'visible',
                flexDirection: 'column',
                '&::-webkit-scrollbar': {
                    display: 'none',
                },
                '&>div:first-of-type': {
                    '&>div:first-of-type': {
                        color: '#9B9B9B',
                        fontWeight: 400,
                        fontSize: '14px',
                    },
                },
            },
        },
    },
    selectXS: {
        alignItems: 'center',
        '&>div:first-of-type': {
            backgroundColor: 'white !important',
            borderWidth: 0,
            borderBottomWidth: 1,
            borderBottomColor: 'rgba(0, 0, 0, 0.42)',
            borderRadius: 0,
            outline: 'none',
            height: '42px',
            '&>div:first-of-type': {
                padding: '8px 20px',
                position: 'relative',
                boxSizing: 'border-box',
                display: '-webkit-inline-box',
                maxHeight: '38px',
                maxWidth: 'calc(100% - 73px)',
                overflow: 'hidden',
                overflowX: 'visible',
                flexDirection: 'column',
                '&::-webkit-scrollbar': {
                    display: 'none',
                },
            },
        },
    },
    searchScroll: {
        boxSizing: 'border-box',
        display: '-webkit-inline-box',
    },
    childOptionsHolder: {
        paddingLeft: 23,
        boxSizing: 'border-box',
        display: '-webkit-inline-box',
        '&>div:first-of-type': {
            color: '#a2a2a2',
        },
    },
    positionFixed: {
        top: '0',
        left: 'auto',
        right: '0',
        position: 'inherit',
    },
    positionSet: {
        top: '0',
        left: 'auto',
        right: '0',
        position: 'fixed',
    },
    need: {
        margin: 0,
        fontSize: 12,
        color: BLACK_FONT,
    },
    click: {
        margin: 0,
        fontSize: 12,
        color: '#1168CD',
        cursor: 'pointer',
    },
    title: {
        color: BLACK_FONT,
        fontSize: 15,
        marginLeft: 20,
        fontWeight: 400,
        textTransform: 'uppercase',
    },
    clearSearch: {
        marginRight: 8,
        marginTop: 3,
        '&>svg': {
            cursor: 'pointer',
            color: '#9B9B9B',
            fontSize: 16,
        },
    },
    productTourContainer: {
        display: 'flex',
        marginRight: 40,
        width: '205px',
    },
});

const selectStyle = {
    control: (base) => ({
        ...base,
        border: 0,
        borderBottom: 'solid black 1px',
        // This line disable the blue border
        boxShadow: 0,
        '&:hover': {
            border: 0,
            borderBottom: 'solid black 3px',
        },
    }),
};

const ValueContainer = ({ children, ...props }) => (
    <components.ValueContainer {...props}>
        {!!children && <Search style={{ position: 'fixed', marginLeft: -10 }} />}
        {!!children && (
            <div
                style={{
                    paddingLeft: 23,
                    boxSizing: 'border-box',
                    display: '-webkit-inline-box',
                }}
                id="childOptionsHolder"
            >
                {children}
            </div>
        )}
    </components.ValueContainer>
);

let wsData_forTesting = {};

class AppBarBase extends Component {
    state = {
        anchorEl: null,
        isSuccess: false,
        loader: false,
        modalData: null,
        modalName: '',
        snackBarMessage: '',
        snackbarLink: '',
        videoUrl: '',
        query: '',
        selectedValues: [],
    };

    componentDidMount() {

        this.trackSegmentSearch = _.debounce(this.trackSegmentSearch, 1000);

       
        window.testingFunction_wsData = () => {
            this.testingFunction_wsData();
        };
    }

    UNSAFE_componentWillReceiveProps = (nextProps) => {
        const { clearError, clearSuccess, showSnackBar } = this.props;

        const { wsRunningTestSteps, wsRunningCaseData, wsKeys } = nextProps;
        wsData_forTesting = JSON.parse(JSON.stringify({ wsRunningTestSteps, wsRunningCaseData, wsKeys }));

        const error =
            nextProps.accountError ||
            nextProps.tagError ||
            nextProps.testStepError ||
            nextProps.userError ||
            nextProps.flowError ||
            nextProps.error ||
            nextProps.scheduleError ||
            nextProps.projectsError;
        const success =
            nextProps.accountSuccess ||
            nextProps.tagSuccess ||
            nextProps.testStepSuccess ||
            nextProps.userSuccess ||
            nextProps.flowSuccess ||
            nextProps.success ||
            nextProps.schduleSuccess ||
            nextProps.projectsSuccess;

        if (error) {
            showSnackBar(error, nextProps.snackbarLink, false, 4000, false, true);
            clearError();
        } else if (success) {
            showSnackBar(success, '', true, 4000, false, true);
            clearSuccess();
        }
        if (nextProps.forceClearQuery) {
            this.clearSearch();
        }
    };

    componentDidUpdate() {
        initializeWalkMe();
    }


    handleMenuOpen = (event) => {
        this.setState({ anchorEl: event.currentTarget });
    };

    handleNotificationOpen = (event) => {
        //track Segment event
        const { name,role,userId,email } = this.props.user;
        this.setState({ notify: event.currentTarget });
    };

    handleMenuClose = () => {
        this.setState({ anchorEl: null, notify: null });
    };

    routeToSignIn = () => {
        this.props.routeHistory.history.push('/signin');
        // CookieStorage.remove('aut_remember')
        // window.location.reload()
    };

    handleChange = (ev) => {
        const { value } = ev.target;
        
        this.setState({ query: value }, () => {
            this.props.querySave(value);
        });

        this.trackSegmentSearch(value);


        
    };

    trackSegmentSearch = (query) => {

        if(!query) {
            return;
        }

        const eventName = SEGMENT_EVENT.SEARCH;
        const segmentData = {
            query:query
        }
        track(eventName,segmentData);

    }


    clearSearch = () => {
        this.setState({ query: '', selectedValues: [] }, () => {
            this.props.querySave('');
        });
    };

    changeSelection = ($event) => {
        const { disabledProjects, projects, projectsByIdAndName, showDisabledProjects } = this.props;
        const localProjectsCopy = showDisabledProjects ? disabledProjects : projectsByIdAndName;

        localProjectsCopy.forEach((project) => {
            project.availableOnPage = false;
            projects.forEach((item) => {
                if (item.projectName === project.projectName) {
                    project.availableOnPage = true;
                }
            });
        });

        let value = '';
        let selectedValues = $event;
        if (!checkArrayLength(selectedValues) || !checkArrayLength(localProjectsCopy)) {
            selectedValues = [];
        } else if (checkArrayLength(localProjectsCopy)) {
            selectedValues.reverse();
            selectedValues = selectedValues.filter((selectedValue) => {
                let isAdded = false;
                localProjectsCopy.forEach((project) => {
                    if (checkKeyInObject(project, 'projectName') && project.projectName === selectedValue.label) {
                        isAdded = true;
                        value = value !== '' ? `${value} ` : value;
                        value = `${value}${project.projectName ? project.projectName : ''}`;
                        if (project.availableOnPage === false && !showDisabledProjects) {
                            // remove '&& !showDisabledProjects' from this condition whenever we have pagination supported by backend
                            this.props.getProjectByName(selectedValue.label);
                        }
                    }
                });
                return isAdded;
            });
        }

        this.setState({ selectedValues, query: value }, () => {
            this.props.querySave(value);
        });
        this.trackSegmentSearch(value)
    };

    testingFunction_wsData = () => {
        // eslint-disable-next-line no-console
        console.log(wsData_forTesting);
    };

    convertToSelectValues = (inputArray = []) => {
        let outputArray = [];
        outputArray = inputArray.map((element) => {
            return {
                value: element.projectId,
                label: element.projectName,
            };
        });
        return outputArray;
    };

    render() {
        const {
            classes,
            location: { pathname },
            history,
            user = {},
            logout,
            logoutSameTab,
            notifications,
            isSnackBarOpen,
            isModalVisible,
            requiredFor,
            showRightPanel,
            disabledProjects,
            showDisabledProjects,
            projectsByIdAndName,
            title,
            isFavourite,
            favouriteProjects,
            alertBarReducerData,
            recorderInstallationModalOpened
        } = this.props;
        const { notify, query, selectedValues } = this.state;

        let isShowModal = false;

        Object.keys(isModalVisible).some((key) => {
            if (isModalVisible.hasOwnProperty(key) && isModalVisible[key]) {
                isShowModal = true;
                return true;
            }
            return false;
        });

        const count =
            notifications && notifications.data && isArray(notifications.data) && notifications.data.length
                ? notifications.data.filter((check) => check.isRead === false).length
                : 0;
        const toGoHome = () => {
            if (pathname && pathname !== '/dashboard') {
                this.props.history.push('/dashboard', { fromLogo: true });
            }
        };
        const localProjectsCopy = showDisabledProjects ? disabledProjects : isFavourite ? favouriteProjects : projectsByIdAndName;
        const convertedItems = this.convertToSelectValues(localProjectsCopy);

        const userNotification = notify ? (
            <UserNotification anchorEl={notify} history={this.props.history} handleMenuClose={this.handleMenuClose} />
        ) : null;
        return (
            <AppBar
                classes={{
                    positionFixed: requiredFor === 'xs' ? classes.positionFixed : classes.positionSet,
                }}
                className={requiredFor === 'xs' ? classes.appBarXS : classes.appBar}
            >
                {requiredFor !== 'xs' ? (
                    <Toolbar className={classes.toolbar}>
                        <div className={classes.imageContainer}>
                            <Route routeName="home">
                                <img
                                    aria-hidden
                                    src={dashboardImageUrl}
                                    onClick={toGoHome}
                                    className={
                                        companyName.toLocaleLowerCase() === 'autonomiq' || logoLayout === 'rectangle'
                                            ? classes.headerlogoRectangle
                                            : classes.headerlogoSquare
                                    }
                                    alt={`${companyName} logo`}
                                />
                            </Route>
                            <span className={classes.title}>{title}</span>
                        </div>
                        <div className={classes.rightContainer}>
                            {pathname &&
                            pathname !== '/dashboard' &&
                            pathname !== '/admin' &&
                            pathname !== '/admin/profile' &&
                            pathname !== '/admin/preferences' &&
                            !isOnProjectPage() ? (
                                <SearchInput
                                    id="contextual_search_AppBar"
                                    placeholder="Search"
                                    onChange={this.handleChange}
                                    value={query || ''}
                                    classStyle={classes.searchInput}
                                    clearSearch={this.clearSearch}
                                />
                            ) : isOnProjectPage() ? (
                                <div className={classes.selectCustomWrapper}>
                                    <SelectCustom
                                        value={selectedValues}
                                        onChange={(data) => {
                                            this.changeSelection(data);
                                        }}
                                        className={classes.selectCustom}
                                        isBorder
                                        options={convertedItems}
                                        isMultiSelect
                                        readOnly={false}
                                        placeholder={!checkArrayLength(selectedValues) ? 'Search' : ''}
                                        styleWithIcon={{ display: 'flex', width: 'calc(100% - 30px)', alignItems: 'center', marginLeft: 30 }}
                                        startAdornment={<Search style={{ position: 'absolute' }} />}
                                    />
                                </div>
                            ) : pathname === '/dashboard' ? (
                                <div className={classes.productTourContainer}>
                                    <p className={classes.need}>Need Help?</p>
                                    <p
                                        className={classes.click}
                                        aria-hidden
                                        onClick={() => {
                                            this.props.toggleModal('homeTour');
                                        }}
                                    >
                                        &nbsp;Click to start product tour
                                    </p>
                                </div>
                            ) : (
                                <div className={classes.searchInput} />
                            )}
                            {/* Add Notification Icon for enableing to show the status of newly generated video */}
                            <div className={classes.rightContainer}>
                                <Tooltip data="WalkMe">
                                    <IconButton className={classes.userButton} color="inherit" style={{ marginLeft: -4, visibility: 'hidden' }}>
                                        <img src={WalkMe} alt="" />
                                    </IconButton>
                                </Tooltip>
                                <Tooltip data="Notification">
                                    <IconButton className={classes.userButton} color="inherit" onClick={this.handleNotificationOpen}>
                                        {count ? (
                                            <Badge badgeContent={count} color="secondary">
                                                <NotificationsIcon
                                                    style={{ color: '#4A4A4A' }}
                                                    aria-label="notificationsIcon"
                                                    id="notificationsicon"
                                                />
                                            </Badge>
                                        ) : (
                                            <NotificationsIcon style={{ color: '#4A4A4A' }} aria-label="notificationsIcon" id="notificationsIcon" />
                                        )}
                                    </IconButton>
                                </Tooltip>

                                <Tooltip
                                    data={
                                        <UserMenu
                                            history={history}
                                            user={user}
                                            logout={() => {
                                                logoutSameTab();
                                                logout();
                                            }}
                                        />
                                    }
                                    isOnClick={true}
                                    isElement={true}
                                    isPointerEvents = {false} 
                                    closeOnElementClick={true}
                                    position={'bottom-start'}
                                    paperBodyStyle={{ padding: 0 }}
                                >
                                    <Button onClick={this.handleMenuOpen} className={classes.userNameBtn}>
                                        <AccountCircle className={classes.medIcon} aria-label="accountCircleIcon" id="accountCircleIcon" />
                                        <Typography className={classes.userNameText}>
                                            {user.givenName && user.familyName ? user.givenName.concat(' ').concat(user.familyName) : user.name}
                                        </Typography>
                                    </Button>
                                </Tooltip>
                            </div>
                        </div>
                        {userNotification}
                    </Toolbar>
                ) : (
                    <div>
                        <Select
                            closeMenuOnSelect={false}
                            value={selectedValues}
                            id="projectDropdownXS"
                            isMulti
                            options={convertedItems}
                            className={classes.selectXS}
                            styles={selectStyle}
                            onChange={this.changeSelection}
                            placeholder="Contextual Search"
                            components={{ ValueContainer }}
                            style={{
                                width: showRightPanel ? 'calc(100vw - 100px)' : 'calc(100vw - 50px)',
                            }}
                        />
                    </div>
                )}
                {isSnackBarOpen &&(!recorderInstallationModalOpened) ? <ErrorSnackBar /> : null}
                {
                    alertBarReducerData?.open ?
                    <AlertBar
                     setOpen = {(open)=>{
                        store.dispatch({
                            type:ActionTypes.TOGGLE_ALERTBAR,
                            payload:{
                                open:false
                            }
                        })
                    }} message ={alertBarReducerData.message} type={alertBarReducerData.type} open={true} autoHideDuration={alertBarReducerData.autoHideDuration} isCloseOption={alertBarReducerData.isCloseOption} style={alertBarReducerData.alertBarStyle}/>
                    :null

                }
                {isShowModal ? <Modal history={history} /> : null}
            </AppBar>
        );
    }
}

AppBarBase.propTypes = {
    classes: PropTypes.shape({}).isRequired,
    querySave: PropTypes.func.isRequired,
    notifications: PropTypes.shape({}).isRequired,
    showRightPanel: PropTypes.bool,
};

AppBarBase.defaultProps = {
    showRightPanel: false,
};

const mapStateToProps = (state) => {
    return {
        // account reducer
        accountError: state.accountReducer.error,
        accountSuccess: state.accountReducer.success,
        // auth reducer
        user: state.authReducer.user,
        // block reducer
        flowError: state.flowReducer.error,
        flowSuccess: state.flowReducer.success,
        // general reducer
        forceClearQuery: state.generalReducer.forceClearQuery,
        disabledQuery: state.generalReducer.disabledQuery,
        // modal reducer
        isSnackBarOpen: state.modalReducer.isSnackBarOpen,
        isModalVisible: state.modalReducer.isModalVisible,
        // project reducer
        error: state.projectReducer.error,
        success: state.projectReducer.success,
        isLoadingGAP: state.projectReducer.isLoadingGAP,
        isLoadingGAPBN: state.projectReducer.isLoadingGAPBN,
        isLoadingDp: state.projectsReducer.isLoadingDp,
        snackbarLink: state.projectReducer.snackbarLink,
        projects: state.projectReducer.projects,
        projectsByIdAndName: state.projectReducer.projectsByIdAndName,
        notifications: state.projectReducer.nonDeletedNotify,
        wsRunningTestSteps: state.projectReducer.wsRunningTestSteps,
        wsRunningCaseData: state.projectReducer.wsRunningCaseData,
        wsKeys: state.projectReducer.wsKeys,
        isFavourite: state.projectReducer.isFavourite,
        favouriteProjects: state.projectReducer.favouriteProjects,
        recorderInstallationModalOpened:state.projectReducer.recorderInstallationModalOpened,
        // tag reducer
        tagError: state.tagReducer.error,
        tagSuccess: state.tagReducer.success,
        // testStep reducer
        testStepError: state.testStepReducer.error,
        testStepSuccess: state.testStepReducer.success,
        // user reducer
        userError: state.userReducer.error,
        userSuccess: state.userReducer.success,
        // Schedule reducer
        schduleSuccess: state.scheduleReducer.success,
        scheduleError: state.scheduleReducer.error,
        // Projects reducer
        disabledProjects: state.projectsReducer.disabledProjects,
        showDisabledProjects: state.projectsReducer.showDisabledProjects,
        projectsSuccess: state.projectsReducer.success,
        projectsError: state.projectsReducer.error,
        title: state.generalReducer.title,

        // AlertBar Reducer
        alertBarReducerData:{...state.alertBarReducer}
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        querySave: (data) => dispatch(GeneralActions.querySave(data)),
        clearError: () => dispatch(ProjectActions.clearError()),
        clearSuccess: () => dispatch(ProjectActions.clearSuccess()),
        logout: () => dispatch(AuthActions.logout()),
        showSnackBar: (...args) => dispatch(ModalActions.toggleSnackBar(...args)),
        toggleModal: (...args) => dispatch(ModalActions.toggleModal(...args)),
        getProjectByName: (...args) => dispatch(ProjectActions.getProjectByName(...args)),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(withStyles(styles)(AppBarBase)));
