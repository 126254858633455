import React, { Component } from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import { withStyles } from '@material-ui/core/styles';
import FormControl from '@material-ui/core/FormControl';
import Typography from '@material-ui/core/Typography';
import { DIALOG_ROOT, MODAL_HEADER_TITLE, MODAL_HEADER_CLOSE_ICON_CONT, MODAL_HEADER_CLOSE_ICON, MODAL_FOOTER, MODAL_SUBMIT_BUTTON, MODAL_CANCEL_BUTTON } from '../../../common/cssConstants';
import Close from '@material-ui/icons/Close';
import CloseOnEscape from 'react-close-on-escape';

const styles = (theme) => ({
    dialogTitleStyle: MODAL_HEADER_TITLE,
    dialog: {
        textAlign: 'center',
        backgroundColor: '#e9effd',
        borderBottom: '1px solid #dcdcdc',
    },
    actionBtn: {
        padding: '2px 8px',
        minHeight: 0,
        borderColor: '#1067ce',
        textTransform: 'capitalize',
        borderRadius: '6px',
        minWidth: '75px',
    },
    formControl: {
        width: '100%',
        flexDirection: 'row',
        padding: '0 30px',
    },
    dialogModal: {
        "&>div": {
            borderRadius: '0px',
        },
        ...DIALOG_ROOT,
    },
    dialogContent: {
        overflow: 'hidden',
        width: 500,
        padding: '0',
        maxHeight: 'calc(100vh - 328px)',
        overflowY: 'auto',
        [theme.breakpoints.only('xs')]: {
            width: `calc(100vw - 100px)`,
        },
    },
    dataName: {
        width: 140,
        float: 'left',
        fontSize: 14,
        fontWeight: 'bold',
    },
    dataValue: {
        float: 'left',
        fontSize: 12,
        fontWeight: 'normal',
        wordBreak: 'break-all'
    },
    _dataValue: {
        float: 'left',
        fontSize: 12,
        fontWeight: 'normal',
    },
    deleteButtonDanger: {
        backgroundColor: '#fff !important',
        color: '#ff0000 !important',
        border: '1px solid #ff0000 !important',
        '&:hover': {
            backgroundColor: '#ff0000 !important',
            color: '#fff !important',
        }
    }
});


class RejectApproveTestCase extends Component {
    state = {
        isDisabled: false
    }
    render() {
        const {
            classes,
            handleClose,
            open,
            rejectApproveTestCaseData,
            isReject,
            isRejectApproveAll,
            isMultiple,
            list,
        } = this.props;

        const { isDisabled } = this.state;
        return (
            <div>
                <Dialog
                    disableRestoreFocus
                    open={open}
                    aria-labelledby="form-dialog-title"
                    className={classes.dialogModal}
                >
                    <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                        <DialogTitle id="form-dialog-title" classes={{ root: classes.dialogTitleStyle }}>
                            {isReject ? "Reject " : "Approve "} Selected Test Case
                            </DialogTitle>
                        <div style={MODAL_HEADER_CLOSE_ICON_CONT}>
                            <Close style={MODAL_HEADER_CLOSE_ICON} onClick={() => handleClose(false)} aria-label="closeIcon" id="closeIcon" />
                        </div>
                    </div>
                    {isMultiple ?
                        <div>
                            <DialogContent className={classes.dialogContent}>
                                <Typography style={{ paddingBottom: '10px', paddingTop: '0px' }} className={classes.formControl}>
                                    Are you sure want to <span style={{ fontWeight: 'bold', color: '#1B2469' }} > {isReject ? "Reject " : "Approve "} Selected Test Cases </span> ?
                                </Typography>
                                <FormControl className={classes.formControl}>
                                    <div style={{ width: '100%' }}>
                                        <Typography className={classes.dataName}>Test case name</Typography>
                                    </div>
                                </FormControl>

                                {list.map((value, index) => (
                                    <FormControl className={classes.formControl} key={index}>
                                        <div style={{ width: '100%' }}>
                                            <Typography className={classes._dataValue}>{value}</Typography>
                                        </div>
                                    </FormControl>
                                ))}
                            </DialogContent>
                        </div> :
                        <DialogContent className={classes.dialogContent}>
                            <FormControl className={classes.formControl}>
                                <div style={{ width: '100%' }}>
                                    <Typography style={{ marginBottom: '10px' }}>
                                        Are you sure want to {isReject ? 'reject' : 'approve'} <span style={{ fontWeight: 'bold', color: '#1B2469' }} >{isRejectApproveAll ? "All Test Cases" : `${rejectApproveTestCaseData ? rejectApproveTestCaseData.testcaseName : ''}`}</span>?
                                        </Typography>
                                    <Typography
                                        className={classes.dataName}
                                    >
                                        {isRejectApproveAll ? "Test cases message" : 'Test case name'}
                                    </Typography>
                                    <Typography
                                        className={classes.dataValue}
                                    >
                                        {isRejectApproveAll ? `${rejectApproveTestCaseData ? rejectApproveTestCaseData.message : ''}` : `${rejectApproveTestCaseData ? rejectApproveTestCaseData.testcaseName : ''}`}
                                    </Typography>
                                </div>
                            </FormControl>
                        </DialogContent>}
                    <DialogActions style={MODAL_FOOTER}>
                        <Button
                            color="secondary"
                            onClick={() => { this.setState({ isDisabled: true }); handleClose(true) }}
                            style={MODAL_SUBMIT_BUTTON}
                            disabled={isDisabled}
                            className={isReject ? classes.deleteButtonDanger : ''}
                        >
                            {isReject ? "Reject " : "Approve "}
                        </Button>
                        <CloseOnEscape onEscape={() => handleClose(false)}>
                            <Button
                                variant="outlined"
                                color="secondary"
                                onClick={() => handleClose(false)}
                                style={MODAL_CANCEL_BUTTON}
                            >
                                Cancel
                                </Button>
                        </CloseOnEscape>
                    </DialogActions>
                </Dialog>
            </div>
        );
    }
}

export default withStyles(styles)(RejectApproveTestCase);
