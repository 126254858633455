import React from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Close from 'mdi-material-ui/Close';
import Lock from '@material-ui/icons/Lock';
import { DIALOG_ROOT, MODAL_FOOTER, MODAL_SUBMIT_BUTTON, MODAL_CANCEL_BUTTON, MODAL_HEADER_CLOSE_ICON_CONT, MODAL_HEADER_CLOSE_ICON, MODAL_HEADER_TITLE, BLACK_FONT } from '../../../common/cssConstants';
import { FormControlLabel, Switch } from '@material-ui/core';
import CloseOnEscape from 'react-close-on-escape';

const styles = (theme) => ({
    dialogTitleStyle: MODAL_HEADER_TITLE,
    actionBtn: {
        padding: '2px 8px',
        minHeight: 0,
        borderColor: '#1067ce',
        textTransform: 'capitalize',
        borderRadius: '6px',
        minWidth: '75px',
    },
    dialogModal: {
        "&>div": {
            borderRadius: '0px',
        },
        ...DIALOG_ROOT,
    },
    dialogContent: {
        overflow: 'hidden',
        padding: '0 !important',
        marginLeft: '30px',
        marginRight: '30px',
        [theme.breakpoints.only('xs')]: {
            width: `calc(100vw - 100px)`,
        },
    },
    lockIcon: {
        fontSize: 20,
        color: '#979797'
    },
    root: {
        width: '100%',
        marginTop: 0,
    },
    row: {
        display: 'flex',
        width: '100%',
        alignItems: 'center',
        height: 40,
    },
    col: {
        width: 'inherit',
    },
    label: {
        alignSelf: 'center',
        '& > p': {
            fontSize: 12,
            color: BLACK_FONT,
        }
    },
    value: {
        display: 'flex',
        minWidth: '35px',
        maxWidth: '35px',
        justifyContent: 'flex-start',
    },
    iconButton: {
        position: 'relative',
        left: 9,
    },
    iOSSwitchBase: {
        '&$iOSChecked': {
            color: theme.palette.common.white,
            '& + $iOSBar': {
                backgroundColor: '#3B91DF',
            },
        },
        transition: theme.transitions.create('transform', {
            duration: theme.transitions.duration.shortest,
            easing: theme.transitions.easing.sharp,
        }),
    },
    iOSBar: {
        borderRadius: 13,
        width: 25,
        height: 14.5,
        border: 'solid 1px',
        borderColor: theme.palette.grey[400],
        backgroundColor: theme.palette.grey[50],
        opacity: 1,
        transition: theme.transitions.create(['background-color', 'border']),
    },
    iOSIcon: {
        width: 12,
        height: 12,
        marginTop: 1,
        marginRight: 3,
    },
    iOSIconChecked: {
        boxShadow: theme.shadows[1],
        marginTop: 1,
        marginRight: 14,
    },
    iOSChecked: {
        transform: 'translateX(15px)',
        '& + $iOSBar': {
            opacity: 1,
            border: 'none',
        },
    },
});
class settingTestCase extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            getCloneRowData: JSON.parse(JSON.stringify(props.headers)),
        }
    }

    statusChange = (status, index) => {
        let updatedState = [...this.state.getCloneRowData];
        updatedState[index].status = status === 'Active' ? 'InActive' : 'Active';
        this.setState({ getCloneRowData: updatedState });
    };

    selectStatus = (itemStatus, itemIndex) => {
        const { classes } = this.props;
        if (itemStatus === 'Lock')
            return (
                <Lock className={classes.lockIcon} aria-label="lockIcon" id="lockIcon" />
            );
        else
            return (
                <FormControlLabel
                    control={
                        <Switch
                            checked={itemStatus === 'Active'}
                            onClick={() => this.statusChange(itemStatus, itemIndex)}
                            color={'secondary'}
                            inputProps={{ 'aria-label': 'secondary checkbox' }}
                            classes={{
                                switchBase: classes.iOSSwitchBase,
                                checked: classes.iOSChecked,
                            }}
                        />
                    }
                />
            );


    }
    saveHeaders = () => {
        this.props.changeHeaderStatus(this.state.getCloneRowData)
        this.props.handleClose(true)
    }
    render() {
        const { classes, handleClose, open } = this.props;
        const { getCloneRowData } = this.state;
        return (
            <div>
                <Dialog
                    disableRestoreFocus
                    open={open}
                    aria-labelledby="form-dialog-title"
                    className={classes.dialogModal}
                >
                    <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                        <DialogTitle id="form-dialog-title" classes={{ root: classes.dialogTitleStyle }}>
                            Manage Columns
                        </DialogTitle>
                        <div style={MODAL_HEADER_CLOSE_ICON_CONT}>
                            <Close style={MODAL_HEADER_CLOSE_ICON} onClick={() => handleClose(false)} aria-label="closeIcon" id="closeIcon" />
                        </div>
                    </div>
                    <DialogContent className={classes.dialogContent}>
                        <div className={classes.root}>
                            {getCloneRowData.map((item, i) => {
                                return item.status === 'NoCol' ? null :
                                    (<div key={i} >
                                        <div className={classes.row}>
                                            <div className={[classes.col, classes.value].join(' ')}>
                                                {this.selectStatus(item.status, i)}
                                            </div>
                                            <div className={[classes.col, classes.label].join(' ')}>
                                                <Typography>
                                                    {`${item.label}`}
                                                </Typography>
                                            </div>
                                        </div>
                                    </div>
                                    )
                            })}
                        </div>
                    </DialogContent>
                    <DialogActions style={MODAL_FOOTER}>
                        <Button
                            color="secondary"
                            onClick={this.saveHeaders}
                            style={MODAL_SUBMIT_BUTTON}
                        >
                            Save
                            </Button>
                        <CloseOnEscape onEscape={() => handleClose(false)}>
                            <Button
                                variant="outlined"
                                color="secondary"
                                onClick={() => handleClose(false)}
                                style={MODAL_CANCEL_BUTTON}
                            >
                                Cancel
                        </Button>
                        </CloseOnEscape>
                    </DialogActions>
                </Dialog>
            </div>
        );
    }
}
export default withStyles(styles)(settingTestCase);
