import {  THREE_DOT_MENU_CSS } from '../../common/cssConstants';

const styles = () => ({
    button: {
        borderRadius: '3px',
        // minHeight: 0,
        minWidth: '75px',
        padding: '2px 8px',
        textTransform: 'capitalize',
        height: '34px',
        width: '69px',
        border: '1px solid #3B91DF',
        backgroundColor: '#3B91DF',
    },
    iconsButtonContainer: {
        margin:'0 0 5px 5px',
    },
    moreIconsBtn: {
        color: THREE_DOT_MENU_CSS.iconColor,
        fontSize: THREE_DOT_MENU_CSS.iconFontSize,
    },
    
    iconButtonRoot: {
        fontSize: '1.0rem',
        height: 25,
        width: 25,
    },
    actionIcons: {
        fontSize: 16,
        color: '#4a4a4a',
        cursor: 'pointer',
    },
    actionButtonsContainer : {
        display: 'flex',
        alignItems: 'flex-end',
        justifyContent: 'flex-end',
    },
    baseTextStyle: {
        paddingLeft: '12px',
        color: '#494949',
        fontSize: '12px',
        fontWeight:'Bold',
    },
    bodyWrapper: {
        display: 'flex',
        justifyContent: 'center',
    },
    buttonContainer: {
        padding: '10px',
        display: 'flex',
        justifyContent: 'flex-end',
    },
    buttonSave: {
        background: '#0092e6',
        color: '#fff',
        textTransform: 'capitalize',
    },
    expansionContent: {
        margin: '0 !important',
        cursor: 'default',
        paddingBottom: '10px',
    },
    expandIconContainer: {
        margin: '-3px 15px 0px 0px',
        padding: 0,
        position: 'absolute',
        right: 0,
        cursor: 'pointer',
        top: 0,
    },
    expansionsummary: {
        height: 'auto !important',
        minHeight: 'auto !important',
        paddingLeft: '0px',
    },
    heading: {
        paddingLeft: '20px',
        fontWeight: 'bold',
        color: 'rgb(0 0 0 / 87%)',
        fontSize: '12px', 
    },
    headingDet:{
       // paddingLeft: '6px',
        fontWeight: 'bold',
        color: '#4a4a4a',
        fontSize: '12px',
        marginTop:'14px',

    },
    headingDetails:{
        fontWeight: 'bold',
        color: '#494949',
        fontSize: '12px',
    },
    headingContainer: {
        display: 'flex',
        alignItems: 'flex-start',
    },
    // input: {
    //     //border: '1px solid #8898A8',
    //     height: 'auto',
    //     width: 100,
    //     borderRadius: 2,
    //     backgroundColor: '#FFFFFF',
    //     marginLeft: 10,
    //     marginRight: 10,
    //     '&>div>input': {
    //         textAlign: 'right',
    //         fontSize: 12,
    //         padding: '5px 10px 5px 0px',
    //     },
    // },
    itemMainWrapper: {
        display: 'flex',
        flexDirection: 'row',
        marginLeft: 5,
        borderBottom: '1px solid #c5ced8',
        paddingTop: 20,
    },
    noBoxShadow: {
        backgroundColor: 'transparent',
        boxShadow: '0px 0px 0px 0px rgba(0, 0, 0, 0.2), 0px 0px 0px 0px rgba(0, 0, 0, 0.14), 0px 0px 0px 0px rgba(0, 0, 0, 0.12)',
    },
    select: {
        borderRadius: 3,
        width: '100%',
        height: '100%',
        '&>div': {
            borderBottom: 'none !important',
            transition: 'none !important',
        },
        '& input': {
            //textAlign: 'center',
            height: '100%',
        },
    },
    flexStart: { display: 'flex', alignItems: 'flex-start' },
    rightSpace: {
        marginRight: 10,
    },
    expensionDetail: {
        padding: '20px 0px',
        display: 'block',
    },
    root: {
        width: '100%',
        maxWidth: '36ch',
        backgroundColor: '#fff',
      },
    inline: {
        display: 'inline',
        fontWeight:'400',
    },
    inputDetails: {
        '&>div>input': {
            padding: '2px 0px 7px',
            width: '50%',
            fontSize: '12px',
            color:'#616161',
        },
    },
    selectionContainer: {
        display: 'flex',
        marginTop: '15px',
        width: '100%',
    },
    sidebarItem: {
        display: 'flex',
        flexDirection: 'row',
        marginLeft: 6,
    },
    input: {
        '&>div>input': {
            padding: '2px 0px 11px',
            //width: '50%',
            fontSize: '12px',
        },
    },
    radioButtonGroupContainer: {
        display: 'flex',
        //width: '50%',
        paddingRight:'12px'
    },
    error: {
        color:'red',
        fontSize:'11px',
        marginTop:'8px',
        display: "flex",
        justifyContent:"flex-end"        
    },
    stepper: {
     //   padding: '16px 0px 0 !important',
     //   overflow: 'hidden',
        marginBottom: 30,
        marginTop:30,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-start',
        width: '100%',
        // marginLeft: -27,
        
    },
    step:{
        marginRight:50,
        cursor:'pointer',

    },
    stepVal: {
        display: 'flex',
        alignSelf: 'center',
        justifyContent: 'center',
        paddingTop: 13,
        fontSize: 12,
        color: '#9B9B9B',
        pointerEvents: 'all',
        cursor: 'pointer',
    },
    disableStep: {
        display: 'flex',
        alignSelf: 'center',
        justifyContent: 'center',
        paddingTop: 13,
        fontSize: 12,
        color: '#9B9B9B',
        pointerEvents: 'none'
    },
    activeStepVal: {
        display: 'flex',
        justifyContent: 'center',
        paddingTop: 13,
        fontSize: 12,
        cursor: 'pointer',

        borderTop: '3px solid #1168CD',
        paddingTop: 10,
        cursor: 'pointer',
        color: '#1168CD',
    },
});

export default styles;