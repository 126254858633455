// imports
import React from 'react';
import store from '../store';
import { ModalActions, generalModalActions, ProjectActions } from '../store/actions';
import ProjectNameAndInfo from '../components/dashboard/ProjectNameAndInfo';
import FirstProjectHeaderInfo from '../components/dashboard/FirstProjectHeaderInfo';
import ProjectProperties from '../components/dashboard/ProjectProperties';
import ProjectDataSheetSettings from '../components/dashboard/ProjectDataSheetSettings';
import { CANCEL_BUTTON, NEXT_SUBMIT_BUTTON, SAVE_OUTLINE_BUTTON ,SAVE_PROJECT_BUTTON } from '../components/Button/Btn';
import { checkKeyInObject, getValidUrl } from './utils';
import { SEGMENT_EVENT } from '../common/constants';
import { track } from '../services/Segment';
import { successAlertBar } from '../services/AlertBarService';

class ProjectFunctions {
    closeProjectModal = () => {
        this.props.toggleModal();
        setTimeout(() => {
            this.props.clearGeneralModal();
        }, 500);
    };

    showProjectedCreatedInfo = (projectName) => {

        const title = `You’ve successfully created the project: ${projectName}!`
        const description = <span>You can now start adding your test cases by clicking <span style={{fontWeight:'700'}}>Add Test Case.</span></span>

        const alertData = {
            message:{title,description},
            duration:14000
        }

        successAlertBar(alertData);
    }



    submitProject = ({ isUpdateProject, projectId, project, history }) => {
        const { tabsData } = this.props;
        const projectAdvanceValues = checkKeyInObject(
            tabsData,
            'projectInfo.state.projectAdvanceValues',
            'value',
            isUpdateProject && project ? project : {},
        );
        let isChange = false;

        // projectInfo Tab Keys
        let projectName = isUpdateProject
            ? checkKeyInObject(projectAdvanceValues, 'name', 'value', '') || checkKeyInObject(projectAdvanceValues, 'projectName', 'value', '')
            : '';
        let projectURL = isUpdateProject ? checkKeyInObject(projectAdvanceValues, 'appUrl', 'value', '') : '';
        let projectDescription = isUpdateProject ? checkKeyInObject(projectAdvanceValues, 'description', 'value', '') : '';
        const _projectXPATH = [];
        if (isUpdateProject && checkKeyInObject(projectAdvanceValues, 'xpathAttributes', 'bool')) {
            JSON.parse(projectAdvanceValues.xpathAttributes).forEach((value, index) => {
                _projectXPATH.push({ name: `xpath-${index}`, value: `${value}` });
            });
        }
        let projectXPATH = _projectXPATH && _projectXPATH.length > 0 ? _projectXPATH : [{ name: 'xpath-0', value: '' }];

        // projectProperties Tab Keys
        let isAutoExecute = isUpdateProject ? checkKeyInObject(projectAdvanceValues, 'isAutoExecute', 'value', false) : false;
        let aiqExecution = isUpdateProject ? checkKeyInObject(projectAdvanceValues, 'aiqExecution', 'value', true) : true;
        let isNoExecutionScreenshot = isUpdateProject ? !checkKeyInObject(projectAdvanceValues, 'isNoExecutionScreenshot', 'value', true) : false;
        let projectStatus = isUpdateProject ? !checkKeyInObject(projectAdvanceValues, 'cacheXpaths', 'value', true) : false;
        let isFullScreenShot = isUpdateProject ? checkKeyInObject(projectAdvanceValues, 'isFullScreenshot', 'value', false) : false;
        let showTimer = isUpdateProject ? checkKeyInObject(projectAdvanceValues, 'showTimer', 'value', false) : false;
        let smartRetry = isUpdateProject ? checkKeyInObject(projectAdvanceValues, 'smartRetry', 'value', false) : false;
        let isInteractiveDebug = isUpdateProject ? checkKeyInObject(projectAdvanceValues, 'isInteractiveDebug', 'value', false) : false;

        // ProjectDateSetting Tab Keys
        let argument = isUpdateProject ? checkKeyInObject(projectAdvanceValues, 'customParserArgs', 'value', '') : '';
        let uploadedFiles = [];
        let columnCaseSteps = isUpdateProject ? checkKeyInObject(projectAdvanceValues, 'stepsColNum', 'value', 0) : 0;
        let columnCaseData = isUpdateProject ? checkKeyInObject(projectAdvanceValues, 'dataColNum', 'value', 0) : 0;
        let expectedResult = isUpdateProject ? checkKeyInObject(projectAdvanceValues, 'expectedResultsColNum', 'value', 0) : 0;

        if (checkKeyInObject(tabsData, 'projectInfo.state.didChange', 'bool')) {
            const projectInfo = tabsData.projectInfo.state;
            projectName = checkKeyInObject(projectInfo, 'projectName', 'value', projectName);
            projectDescription = checkKeyInObject(projectInfo, 'description', 'value', projectDescription);
            projectURL = checkKeyInObject(projectInfo, 'projectURL', 'value', projectURL);
            projectXPATH = checkKeyInObject(projectInfo, 'projectXPATH', 'value', projectXPATH);
            isChange = true;
        }
        if (checkKeyInObject(tabsData, 'projectProperties.state.didChange', 'bool')) {
            const projectProperties = tabsData.projectProperties.state;
            isAutoExecute = checkKeyInObject(projectProperties, 'isAutoExecute', 'value', isAutoExecute);
            aiqExecution = checkKeyInObject(projectProperties, 'aiqExecution', 'value', aiqExecution);
            isNoExecutionScreenshot = checkKeyInObject(projectProperties, 'isNoExecutionScreenshot', 'value', isNoExecutionScreenshot);
            projectStatus = checkKeyInObject(projectProperties, 'projectStatus', 'value', projectStatus);
            isFullScreenShot = checkKeyInObject(projectProperties, 'isFullScreenShot', 'value', isFullScreenShot);
            showTimer = checkKeyInObject(projectProperties, 'showTimer', 'value', showTimer);
            smartRetry = checkKeyInObject(projectProperties, 'smartRetry', 'value', smartRetry);
            isInteractiveDebug = checkKeyInObject(projectProperties, 'isInteractiveDebug', 'value', isInteractiveDebug);
            isChange = true;
        }
        if (checkKeyInObject(tabsData, 'ProjectDateSetting.state.didChange', 'bool')) {
            const ProjectDateSetting = tabsData.ProjectDateSetting.state;
            argument = checkKeyInObject(ProjectDateSetting, 'argument', 'value', argument);
            columnCaseSteps = checkKeyInObject(ProjectDateSetting, 'columnCaseSteps', 'value', columnCaseSteps);
            columnCaseData = checkKeyInObject(ProjectDateSetting, 'columnCaseData', 'value', columnCaseData);
            expectedResult = checkKeyInObject(ProjectDateSetting, 'expectedResult', 'value', expectedResult);
            uploadedFiles = checkKeyInObject(ProjectDateSetting, 'uploadedFiles', 'value', uploadedFiles);
            isChange = true;
        }

        if (isChange) {
            const validProjectURL = getValidUrl(projectURL);
            this.handleUpdateProject({
                isAutoExecute,
                projectName,
                projectDescription,
                validProjectURL,
                projectXPATH,
                projectStatus,
                aiqExecution,
                isNoExecutionScreenshot,
                argument,
                columnCaseSteps,
                columnCaseData,
                expectedResult,
                projectId,
                uploadedFiles,
                isFullScreenShot,
                showTimer,
                smartRetry,
                isUpdateProject,
                history,
                isInteractiveDebug,
            });
        } else {
            this.closeProjectModal();
        }
    };

    handleUpdateProject = async ({
        isAutoExecute,
        projectName,
        projectDescription,
        validProjectURL,
        projectXPATH,
        projectStatus,
        aiqExecution,
        isNoExecutionScreenshot,
        argument,
        columnCaseSteps,
        columnCaseData,
        expectedResult,
        projectId,
        uploadedFiles,
        isFullScreenShot,
        showTimer,
        smartRetry,
        isUpdateProject,
        history,
        isInteractiveDebug,
    }) => {
        const { user, clearError } = this.props;
        const authData = user;
        const { accountId } = authData;
        const userId = authData.data ? authData.data.userId : authData.userId;

        // let redirectWithId = false;
        const xpathAttr = [];
        if (projectXPATH) {
            projectXPATH.forEach((xpath) => {
                if (xpath.value) {
                    xpathAttr.push(xpath.value);
                }
            });
        }
        const userVars = [];
        const projectObj = {
            projectName,
            appUrl: validProjectURL,
            description: projectDescription.trim(),
            xpathAttr,
            userVars,
            stepsColNum: columnCaseSteps || 0,
            dataColNum: columnCaseData || 0,
            expectedResultsColNum: expectedResult || 0,
            parserArgs: argument,
            cacheXpaths: !projectStatus,
            isFullScreenshot: isFullScreenShot,
            isAutoExecute,
            showTimer,
            smartRetry,
            aiqExecution,
            isNoExecutionScreenshot: !isNoExecutionScreenshot,
            isInteractiveDebug,
        };

        const formData = new FormData();
        if (uploadedFiles) {
            formData.append('parserFile', uploadedFiles[0]);
        }
        let response;

        if (isUpdateProject) {
            formData.append('reqParams', JSON.stringify(projectObj));
            const projectNameUpdateData = { accountId, projectId, formData };
            response = await this.props.updateProject(projectNameUpdateData);
            const eventName = SEGMENT_EVENT.PROJECT_UPDATED;
            const segmentData = {
                project_id: projectId,
                project_name : projectObj.projectName
            }
            track(eventName,segmentData)
        } else {
            formData.append('params', JSON.stringify(projectObj));
            const projectNameUpdateData = { accountId, userId, formData };
            response = await this.props.createNewProject(projectNameUpdateData);

            if (response && response.payload && response.payload.data && response.payload.data.id) {
                // redirectWithId = true;
                const eventName = SEGMENT_EVENT.PROJECT_CREATED;
                const segmentData = {
                    project_id : response.payload.data.id,
                    project_name : projectObj.projectName
                }
                track(eventName,segmentData)
    
                history.push(`/details/${response.payload.data.id}/cases`);
                this.showProjectedCreatedInfo(projectObj.projectName)        
            }
        }
        if (response) {
            this.closeProjectModal();
        }
        clearError();
    };

    buttons = [
        NEXT_SUBMIT_BUTTON({ action: () => this.props.changeTab(this.props.currentTab + 1) }),
        CANCEL_BUTTON({ action: () => this.closeProjectModal() }),
    ];



    toggleUpdateModal = (isUpdateProject, projectId, project, history) => {
        const modalData = { isUpdateProject, projectId, project };
        const disableSubmit = (tabsData) => {
            return checkKeyInObject(tabsData, 'projectInfo.state.isSubmitDisabled', 'value', false);
        };

        let zeroProject = false;

        let globalState = store.getState();
        if(globalState && globalState.sidebarReducer && globalState.sidebarReducer.sideBarCount && globalState.sidebarReducer.sideBarCount.num_of_projects ===0  ) {
            zeroProject = true;
        }



        let components = []

        if(zeroProject) {

            components = [{
                content: <React.Fragment><FirstProjectHeaderInfo/><ProjectNameAndInfo modalData={modalData} /></React.Fragment>,
                modalFooterStyle:{justifyContent:'center'},
                buttons: [
   
                    SAVE_PROJECT_BUTTON({
                        action: () => {
                            this.submitProject({ ...modalData, history });
                        },
                        isDisabled: (tabsData) => {
                            return disableSubmit(tabsData);
                        },
                    }),
                ]
            }
        ]

        } else {
            components =[{
                name: 'Name and Info',
                content: <ProjectNameAndInfo modalData={modalData} />,
                buttons: [
                    ...this.buttons,
                    SAVE_OUTLINE_BUTTON({
                        action: () => {
                            this.submitProject({ ...modalData, history });
                        },
                        isDisabled: (tabsData) => {
                            return disableSubmit(tabsData);
                        },
                    }),
                ],
            },
            {
                name: 'Properties',
                content: <ProjectProperties modalData={modalData} />,
                buttons: [
                    ...this.buttons,
                    SAVE_OUTLINE_BUTTON({
                        action: () => {
                            this.submitProject({ ...modalData, history });
                        },
                        isDisabled: (tabsData) => {
                            return disableSubmit(tabsData);
                        },
                    }),
                ],
            },
            {
                name: 'Custom Settings',
                content: <ProjectDataSheetSettings modalData={modalData} />,
                buttons: [
                    NEXT_SUBMIT_BUTTON({
                        name: isUpdateProject ? 'Save' : 'Finish',
                        action: () => {
                            this.submitProject({ ...modalData, history });
                        },
                        isDisabled: (tabsData) => {
                            return disableSubmit(tabsData);
                        },
                    }),
                    CANCEL_BUTTON({ action: this.closeProjectModal }),
                ],
            }
        ]
        }


        this.props.toggleModal('GeneralModal', null, null, {
            closeIconAction: () => {
                this.closeProjectModal();
            },
            title:zeroProject?null:'Project',
            component: components,
        });
    };

    props = {
        toggleModal: (...args) => {
            store.dispatch(ModalActions.toggleModal(...args));
        },
        clearError: () => {
            store.dispatch(ProjectActions.clearError());
        },
        updateProject: async (data) => {
            const response = await store.dispatch(ProjectActions.updateProject(data));
            return response;
        },
        createNewProject: async (data) => {
            const response = await store.dispatch(ProjectActions.createNewProject(data));
            return response;
        },
        changeTab: (...args) => {
            store.dispatch(generalModalActions.changeTab(...args));
        },
        clearGeneralModal: (...args) => {
            store.dispatch(generalModalActions.clearGeneralModal(...args));
        },
        get currentTab() {
            return store.getState().generalModalReducer.currentTab;
        },
        get tabsData() {
            return store.getState().generalModalReducer.tabsData;
        },
        get user() {
            return store.getState().authReducer.user;
        },

    };
}

export const ProjectUtils = new ProjectFunctions();
